import React from "react";

import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import ReactHtmlParse from "html-react-parser";

import { useGenerateAssigneeOptionsMutation } from "../../rtkStore/services/studyOptionApi";

import FSWLogo from "../../assets/fsw-logo.png";
import placeholder from "../../assets/fsw-placeholder.png";
import { Alert } from "@mui/material";

const StudyOptionScreen = () => {
  const { assignee: assigneeId } = useParams();

  const [searchParams] = useSearchParams();

  const [
    generateAssigneeOptions,
    {
      data: studyOptions,
      isLoading: loadingStudyOptions,
      isFetching: fetchingStudyOptions,
      error,
    },
  ] = useGenerateAssigneeOptionsMutation();

  const assigneeName = React.useMemo(() => {
    return `${studyOptions?.firstName} ${studyOptions?.lastName}`;
  }, [studyOptions]);

  const assigneeStudyOptions = React.useMemo(() => {
    return studyOptions?.favouriteCourses;
  }, [studyOptions]);

  const responsibleUser = React.useMemo(() => {
    return studyOptions?.responsibleUser;
  }, [studyOptions]);

  React.useEffect(() => {
    generateAssigneeOptions({
      assigneeId,
      assigneeType: searchParams.get("type"),
      responsibleAgent: searchParams.get("agent"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeId, searchParams]);

  return (
    <div className="min-h-screen w-screen bg-gray-100">
      <div className="container mx-auto  h-full max-w-4xl bg-white p-6 pb-10">
        {!loadingStudyOptions && !fetchingStudyOptions ? (
          !error ? (
            <div className="flex flex-col space-y-4">
              <div>
                <div className="flex items-center justify-between">
                  <img src={FSWLogo} alt="Logo" className="-ml-3 w-1/3" />
                  <div className="mb-3 font-medium">
                    {moment(
                      new Date(searchParams.get("generationDate"))
                    ).format("MMM DD, YYYY")}
                  </div>
                </div>

                <br />

                <div className="-mt-2 mb-3 text-xl font-semibold">
                  Study Options for{" "}
                  <span className="text-xl  text-red-600 ">{assigneeName}</span>
                </div>

                <div className="font-medium italic">
                  Congratulations on your decision for a bright future. Below
                  are your personalized study options.
                </div>
              </div>

              <br />

              <hr />

              <br />

              <div className="flex flex-col space-y-4">
                {assigneeStudyOptions?.map((course, index) => (
                  <React.Fragment key={course?._id}>
                    <>
                      <div className="flex items-start space-x-6">
                        <img
                          src={course?.university?.logo ?? placeholder}
                          alt={course?.university?.title}
                          className="mt-2 aspect-square h-full w-40 flex-shrink-0 rounded border object-contain"
                        />

                        <div className="mt-2 flex flex-grow flex-col space-y-2.5">
                          <div className="text-lg font-semibold">
                            {course?.university?.title}
                          </div>

                          <div className="flex items-baseline space-x-2">
                            <div className="font-medium">
                              Course Name: {course?.title}
                              {!!course?.agentPromotions?.length && (
                                <span className="flex space-x-2 text-sm font-medium text-green-500">
                                  (
                                  {course?.agentPromotions
                                    ?.map((el) => (
                                      <span key={el?._id}> {el?.name} </span>
                                    ))
                                    ?.reduce((prev, curr) => [
                                      prev,
                                      ", ",
                                      curr,
                                    ])}
                                  )
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <div>
                              <span className="text-sm">Duration: </span>{" "}
                              <span>{course?.duration?.name}</span>
                            </div>

                            <div>
                              <span className="text-sm"> Course Fee: </span>{" "}
                              <span className="text-sm font-medium">
                                {course?.tuitionFee
                                  ? `${course?.currency?.symbol} ${course?.tuitionFee} (${course?.currency?.code}) Per ${course?.feeType}`
                                  : "N/A"}
                              </span>
                            </div>
                          </div>

                          <div>
                            <div className="text-sm">
                              Locations:{" "}
                              {course?.subLocations?.length
                                ? course?.subLocations
                                    ?.map((el) => el?.name)
                                    ?.join(", ")
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-sm">
                        <span className="text-sm">Course Link:</span>{" "}
                        <span className="text-sm text-blue-600 underline">
                          {course?.courseLink}
                        </span>{" "}
                      </div>

                      {/* <br /> */}

                      <div>
                        <div className="text-sm text-gray-500 underline">
                          Course Summary
                        </div>
                        {ReactHtmlParse(course?.summary)}
                      </div>

                      {/* <br /> */}

                      {course?.addmissionRequirement?.englishRequirement && (
                        <div>
                          <div className="text-sm text-gray-500 underline">
                            English Requirement
                          </div>
                          {ReactHtmlParse(
                            course?.addmissionRequirement?.englishRequirement
                          )}
                        </div>
                      )}
                    </>

                    {index !== assigneeStudyOptions?.length - 1 && (
                      <>
                        <br />

                        <hr />

                        <br />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <br />
              <br />

              <div className="">
                <p className="font-medium italic">
                  Choose a bright future for you and your family. This is one of
                  the biggest and most important decision you will need to make.
                  I will help you at every step of the process.
                </p>

                <br />
                <br />
                <br />

                <p className="font-medium">
                  <span className="font-semibold text-red-600">
                    {responsibleUser?.name}{" "}
                  </span>
                  {responsibleUser?.designation?.length && (
                    <>| {responsibleUser?.designation}</>
                  )}
                </p>

                <p className="text-lg font-semibold">
                  First Source World
                  <span className="text-2xl text-red-600">.</span>
                </p>

                <p>
                  {responsibleUser?.phone?.length && (
                    <>
                      <span>{responsibleUser?.phone}</span> |{" "}
                    </>
                  )}
                  {responsibleUser?.email}
                </p>

                <p className="font-medium">
                  Education & Visa Services like never before.
                </p>
              </div>
            </div>
          ) : (
            <Alert variant="outlined" severity="error">
              {error?.data?.message ?? "Something went wrong!"}
            </Alert>
          )
        ) : (
          <div className="grid h-screen place-content-center">
            Please wait till the data loads...
          </div>
        )}
      </div>
    </div>
  );
};

export default StudyOptionScreen;
