export const GLOBAL_LIST_CONSTANTS = {
  COURSE_FIELDS: "Course Fields",
  ENGLISH_REQUIREMENTS: "English Requirements",
  ACADEMIC_REQUIREMENTS: "Academic Requirements",
  COURSE_DURATIONS: "Course Durations",
  COURSE_LEVELS: "Course Levels",
  INTAKES: "Intakes",
  LOCATIONS: "Locations",
  COURSE_REPUTATIONS: "Course Reputations",
  TAGS: "Tags",
};

const GLOBAL_LIST_LEVEL_MAP = {
  COURSE_FIELDS: 2,
  LOCATIONS: 2,
};

export const globalListOptions = Object.keys(GLOBAL_LIST_CONSTANTS).map(
  (key) => ({
    name: GLOBAL_LIST_CONSTANTS[key],
    value: GLOBAL_LIST_CONSTANTS[key],
    maxLevel: GLOBAL_LIST_LEVEL_MAP[key] ?? 1,
    allowGrouping: false,
  })
);
