import React from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import WithHeader from "../../../layouts/WithHeader";

import { useAuth } from "../../../hooks/useAuth";
import {
  useDetailEntityQuery,
  useEditEntityMutation,
} from "../../../rtkStore/services/entityApi";

const EditEntityPlanScreen = () => {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const entityId = loggedInUser?.viewEntity?.entity?._id;

  const breadcrumbs = [
    { title: "Entity Info", path: "/app/entity/detail" },
    { title: "Edit Entity Plan" },
  ];

  const INITIAL_STATE = {
    plan: "Free",
    licenses: 10,
    frequency: "Monthly",
  };

  const planTypes = ["Free", "Enterprise"];
  const frequencyTypes = ["Monthly", "Quaterly", "Annually"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: { ...INITIAL_STATE },
  });

  const selectedPlan = React.useRef({});
  selectedPlan.current = watch("plan");
  selectedPlan?.current === "Free" && setValue("licenses", 10);

  //   RTK Query
  const {
    data: entityDetail,
    isLoading: loadingEntityDetail,
    error,
  } = useDetailEntityQuery(entityId);

  const [updateEntity, { isLoading: updatingEntity }] = useEditEntityMutation();

  //   methods
  const handleUpdateEntity = async (data) => {
    try {
      await updateEntity({
        ...data,
        id: entityId,
      }).unwrap();
      toast.success("Entity Plan successfully updated!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update entity plan!");
    }
  };

  const activePlan = React.useMemo(() => {
    return entityDetail?.entityPlans.find((entity) => entity?.isActive);
  }, [entityDetail]);

  React.useEffect(() => {
    if (!activePlan) return;

    reset({
      plan: activePlan?.plan,
      licenses: activePlan?.quantity,
      frequency: activePlan?.frequency,
    });
  }, [activePlan, reset]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingEntityDetail || updatingEntity}
      error={error}
    >
      <Box padding={3}>
        <Grid container spacing={5}>
          <Grid item md={6}>
            <Controller
              name="plan"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="select-plan">Plan</InputLabel>
                  <Select
                    labelId="select-plan"
                    id="select-plan"
                    size="small"
                    label="Plan"
                    {...field}
                  >
                    {planTypes.map((plan) => (
                      <MenuItem key={plan} value={plan}>
                        {plan}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={6}>
            <Controller
              name="frequency"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="select-frequency">Plan frequency</InputLabel>
                  <Select
                    labelId="select-frequency"
                    id="select-frequency"
                    size="small"
                    label="Plan Frequency"
                    {...field}
                  >
                    {frequencyTypes.map((frequency) => (
                      <MenuItem key={frequency} value={frequency}>
                        {frequency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={6}>
            <Controller
              name="licenses"
              control={control}
              rules={{
                required: "Plan is required",
                min: { value: 1, message: "License must be atleast 1" },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  placeholder="Enter license quantity"
                  label="License Quantity"
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={selectedPlan?.current === "Free"}
                  {...field}
                />
              )}
            />
            {errors.licenses && (
              <p className="error">{errors.licenses.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button
          disabled={loadingEntityDetail || updatingEntity}
          onClick={() => navigate(breadcrumbs[0]?.path)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loadingEntityDetail || updatingEntity}
          onClick={handleSubmit((data) => handleUpdateEntity(data))}
        >
          Update
        </Button>
      </Box>
    </WithHeader>
  );
};

export default EditEntityPlanScreen;
