import React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCommentOnKnowledgebaseMutation, useLazyListKnowledgebaseCommentsQuery, useUpdateCommentOnKnowledgebaseMutation } from "../../../rtkStore/services/knowledgebaseApi";
import { LoadingButton } from "./KBComponents/LoadingButton";
import { KnowledgebaseComment } from "./KBComponents/KnowledgebaseComment";
import { useAuth } from "../../../hooks/useAuth";
import { useKnowledgebaseContext } from "../../../context/KBContext";

export const KBCommentScreen = () => {
  const [replyingTo, setReplyingTo] = React.useState("");
  const [replyComment, setReplyComment] = React.useState("");
  const [commentToUpdateId, setCommentToUpdateId] = React.useState("");

  // hooks
  const { user } = useAuth();
  const params = useParams();
  const { state: articleState } = useKnowledgebaseContext();

  //   rtk queries
  const [commentOnKnowledgebase, { isLoading: isCommenting }] =
    useCommentOnKnowledgebaseMutation();
  const [updateCommentOnKnowledgebase, { isLoading: isUpdatingComment }] =
    useUpdateCommentOnKnowledgebaseMutation();
  const [
    fetchComments,
    {
      isLoading: isLoadingComments,
      isFetching: isFetchingComments,
      data: comments = [],
    },
  ] = useLazyListKnowledgebaseCommentsQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: "",
      replyingTo: "",
    },
  });

  //   methods

  const onUpdateComment = async () => {
    try {
      if (isUpdatingComment || isCommenting) return;
      const payload = {
        comment: replyComment,
        _id: params.articleId,
        commentId: commentToUpdateId,
      };

      await updateCommentOnKnowledgebase(payload).unwrap();
      toast.success("Comment updated successfully");
      setReplyComment("");
      setCommentToUpdateId("");
    } catch (err) {
      const errorMessage = err?.data?.message || "Couldn't update comment";
      toast.error(errorMessage);
    }
  };

  const onSubmitComment = async (
    data,
    options = { unflagArticle: false, isReply: true }
  ) => {
    if (isCommenting || isUpdatingComment) return;

    if (!options?.isReply) {
      setReplyingTo("");
    }

    const payload = {
      article: params.articleId,
      _id: params.articleId,
      ...data,
      comment: data?.comment || replyComment,
      ...(options?.unflagArticle && {
        unflagArticle: options?.unflagArticle,
      }),
    };

    if (options?.isReply && replyingTo) {
      if (!replyingTo?.trim()) {
        toast.error("Please provide a valid, non-empty reply");
        return;
      }
      payload.replyingTo = replyingTo;
    } else {
      if (!data?.comment?.trim()) {
        toast.error("Please provide a valid, non-empty comment");
        return;
      }
    }

    try {
      await commentOnKnowledgebase(payload).unwrap();
      reset({
        comment: "",
        replyingTo: "",
      });
      setReplyingTo("");
      setReplyComment("");
      toast.success(
        replyingTo
          ? "Successfully replied to the comment"
          : "Comment posted successfully on the article"
      );
    } catch (err) {
      const errorMessage =
        err?.data?.message || replyingTo
          ? "Couldn't reply to the comment"
          : "Couldn't post your comment on this article";
      toast.error(errorMessage);
    }
  };

  React.useEffect(() => {
    fetchComments(
      {
        _id: params.articleId,
      },
      true
    );
  }, [fetchComments, params.articleId]);

  return (
    <Box minHeight="calc(100vh - 200px)">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
          <Avatar
            style={{ width: 36, height: 36 }}
            src={`https://ui-avatars.com/api/?rounded=true&name=${user?.name}&background=3F51B5&color=fff`}
            alt={`${user.name}`}
          />

          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {user.name}
            </Typography>
            <Typography variant="body2" className="label-color">
              {user.email}
            </Typography>
          </Box>
        </Box>

        {isFetchingComments && <CircularProgress size={24} />}
      </Box>

      <Box marginBottom={3}>
        <textarea
          rows="4"
          name={`comment`}
          placeholder="Enter your comment"
          className="form-control form-font"
          rules={{
            required: "Comment is required",
          }}
          {...register(`comment`)}
        />
        {errors.comment && <p className="error">{errors.comment.message}</p>}

        <Box
          marginTop={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <LoadingButton
            loadingText="Commenting..."
            variant="contained"
            color="primary"
            loading={!replyingTo && isCommenting}
            onClick={handleSubmit((d) =>
              onSubmitComment(d, { isReply: false })
            )}
          >
            Comment
          </LoadingButton>
        </Box>
      </Box>

      {/* comments list */}

      {isLoadingComments && (
        <Box marginTop={3} marginBottom={7}>
          {Array.from(Array(3).keys()).map((x) => (
            <Box key={`comment-skeleton-${x}`} marginBottom={7}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom={2}
                sx={{ gap: "1rem" }}
              >
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="rect" width={"100%"} height={40} />
              </Box>
              <Skeleton variant="rect" width={"100%"} height={100} />
            </Box>
          ))}
        </Box>
      )}

      <Box
        paddingX={2}
        paddingY={2}
        sx={
          {
            // backgroundColor: "#f1f1f180",
          }
        }
      >
        {!isLoadingComments && comments?.length === 0 && (
          <Box
            width="100%"
            sx={{ textAlign: "center" }}
            paddingY={2}
            className="label-color"
          >
            <i className="fa-solid fa-comments" style={{ fontSize: 18 }}></i>
            <Typography variant="h6">No Comments Available</Typography>
            <Typography variant="variant1" className="label-color">
              Consider adding a comment from above
            </Typography>
          </Box>
        )}

        {!isLoadingComments &&
          comments.map((comment) => (
            <Box key={comment._id} marginBottom={6}>
              <KnowledgebaseComment
                comment={comment}
                hasReply={comment?.replies?.length > 0}
                commentToUpdateId={commentToUpdateId}
                isUpdateCommenTextActive={commentToUpdateId === comment?._id}
                isAuthor={
                  articleState?.detailedArticleInformation?.author?._id ===
                  comment?.author?._id
                }
                onCancelUpdateComment={() => {
                  if (isCommenting || isUpdatingComment) return;
                  setCommentToUpdateId("");
                  setReplyComment("");
                }}
                onClickEditComment={(_comment) => {
                  setCommentToUpdateId(_comment._id);
                  setReplyComment(_comment.comment);
                }}
                onCancelReply={() => {
                  if (isCommenting || isUpdatingComment) return;
                  setReplyComment("");
                  setReplyingTo("");
                }}
                onClickReply={(replyComment) => {
                  setReplyingTo(replyComment);
                }}
                onUpdateComment={onUpdateComment}
                isCommenting={isCommenting}
                isUpdatingComment={isUpdatingComment}
                onSubmitComment={onSubmitComment}
                replyComment={replyComment}
                isReplyTextActive={replyingTo === comment?._id}
                onChangeReplyComment={(c) => setReplyComment(c)}
              />
              {comment?.replies?.length > 0 && (
                <Box marginLeft={4} marginTop={1}>
                  <KnowledgebaseComment
                    isReply={true}
                    comment={comment?.replies[0]}
                    isUpdateCommenTextActive={
                      commentToUpdateId === comment?.replies[0]?._id
                    }
                    isAuthor={
                      articleState?.detailedArticleInformation?.author?._id ===
                      comment?.replies[0]?.author?._id
                    }
                    onCancelUpdateComment={() => {
                      if (isCommenting || isUpdatingComment) return;
                      setCommentToUpdateId("");
                      setReplyComment("");
                    }}
                    onClickEditComment={(_comment) => {
                      setCommentToUpdateId(_comment._id);
                      setReplyComment(_comment.comment);
                    }}
                    onCancelReply={() => {
                      if (isCommenting || isUpdatingComment) return;
                      setReplyComment("");
                      setReplyingTo("");
                    }}
                    onClickReply={(replyComment) => {
                      setReplyingTo(replyComment);
                    }}
                    onUpdateComment={onUpdateComment}
                    isCommenting={isCommenting}
                    isUpdatingComment={isUpdatingComment}
                    onSubmitComment={onSubmitComment}
                    replyComment={replyComment}
                    isReplyTextActive={replyingTo === comment?._id}
                    onChangeReplyComment={(c) => setReplyComment(c)}
                  />
                </Box>
              )}
            </Box>
            //   <Box
            //     key={`comment-${comment._id}`}
            //     marginY={3}
            //     borderRadius="5px"
            //     sx={{
            //       backgroundColor: "#fff",
            //       cursor: "pointer",
            //       padding: "1rem",
            //       border: "1px solid #f1f1f1",
            //       "&:hover": {
            //         boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
            //       },
            //     }}
            //   >
            //     <Box>
            //       <Box
            //         display="flex"
            //         alignItems="flex-start"
            //         justifyContent="space-between"
            //       >
            //         <Box display="flex" alignItems="center" sx={{ gap: "1rem" }}>
            //           <Avatar
            //             style={{ width: 36, height: 36 }}
            //             src={`https://ui-avatars.com/api/?rounded=true&name=${comment?.author?.name}&background=3F51B5&color=fff`}
            //             alt={comment?.author?.name}
            //           />

            //           <Box>
            //             <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
            //               <Typography variant="subtitle1">
            //                 {comment?.author?.name}
            //               </Typography>

            //               {articleState?.detailedArticleInformation?.author?._id ===
            //                 comment?.author?._id && (
            //                 <Box
            //                   paddingX={1}
            //                   paddingY={0.2}
            //                   borderRadius="5px"
            //                   marginLeft={1}
            //                   sx={{
            //                     color: "#0a58ca",
            //                     backgroundColor: "rgba(6,100,239,0.2)",
            //                     fontSize: 12,
            //                   }}
            //                 >
            //                   Author
            //                 </Box>
            //               )}
            //             </Box>
            //             <Typography
            //               variant="body2"
            //               color="textSecondary"
            //               style={{ fontSize: 14 }}
            //             >
            //               {formatRelativeTime(comment?.createdAt)}
            //             </Typography>
            //           </Box>
            //         </Box>

            //         <Box>
            //           <Button
            //             size="small"
            //             variant="text"
            //             className="label-color"
            //             style={{ textTransform: "none" }}
            //             onClick={() => setReplyingTo(comment._id)}
            //           >
            //             Reply
            //           </Button>
            //         </Box>
            //       </Box>
            //       <Box marginTop={2}>
            //         <Typography
            //           variant="subtitle1"
            //           className="label-color"
            //           style={{ fontSize: 15 }}
            //         >
            //           {comment?.comment}
            //         </Typography>
            //       </Box>
            //     </Box>

            //     {replyingTo === comment?._id && (
            //       <Box marginTop={3}>
            //         <textarea
            //           rows="2"
            //           name={`comment`}
            //           placeholder="Enter your reply"
            //           className="form-control form-font"
            //           value={replyComment}
            //           onChange={(e) => setReplyComment(e.target.value)}
            //         />

            //         <Box
            //           marginTop={2}
            //           display="flex"
            //           alignItems="center"
            //           justifyContent="flex-end"
            //           sx={{ gap: "1rem" }}
            //         >
            //           <Button
            //             size="small"
            //             className="label-color"
            //             onClick={() => {
            //               setReplyComment("");
            //               setReplyingTo("");
            //             }}
            //           >
            //             Cancel
            //           </Button>
            //           <LoadingButton
            //             loadingText="Replying..."
            //             variant="outlined"
            //             color="primary"
            //             size="small"
            //             loading={isCommenting}
            //             onClick={handleSubmit((d) => onSubmitComment(d))}
            //           >
            //             Reply
            //           </LoadingButton>
            //         </Box>
            //       </Box>
            //     )}
            //   </Box>
          ))}
      </Box>
    </Box>
  );
};
