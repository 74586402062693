import { Box, Button } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAddListMutation, useListListQuery } from "../../rtkStore/services/listService";
import { LoadingButton } from "./LoadingButton";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "./CustomModal";

export const AddTagModal = ({ type = "Tag", isOpen, onCloseModal = () => { } }) => {
  // rtk queries
  const { isLoading: isLoadingTags, data: tags = [] } = useListListQuery({
    type,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });
  const [addList, { isLoading: isAddingList }] = useAddListMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (isAddingList) return;
      if (isLoadingTags) return;
      if (!data?.name?.trim()) {
        toast.error("Please enter valid tag name");
        return;
      }

      const isTagAlreadyPresent = tags.find(
        (t) => t?.name?.trim() === data?.name?.trim()
      );

      if (isTagAlreadyPresent) {
        toast.error("Tag with that name already exists");
        return;
      }

      const payload = {
        name: data?.name,
        isSubtype: true,
        level: 1,
        maxLevel: 1,
        type,
        parentName: type,
      };

      await addList(payload).unwrap();
      toast.success("Tag added successfully");
      reset({ name: "" });
      onCloseModal();
    } catch (err) {
      const errorMessage = err?.data?.message || "Couldn't add tag to the list";
      toast.error(errorMessage);
    }
  };

  return (
    <CustomModal
      classNames="auto-height"
      open={isOpen} onClose={() => onCloseModal()}>
      <ModalHeader>
        Add Tag
      </ModalHeader>

      <ModalBody >
        <Box>
          <label htmlFor={`name`} className="form-label">
            Tag*
          </label>
          <input
            type="text"
            name={`name`}
            className="form-control form-font py-2 mt-2"
            {...register(`name`, {
              required: "Tag name is required",
            })}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </Box>
      </ModalBody>

      <ModalFooter>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isAddingList}
          loadingText={"Adding Tag..."}
          onClick={handleSubmit((d) => onSubmit(d))}
        >
          Add Tag
        </LoadingButton>
        <Button style={{ marginLeft: "1rem" }} onClick={onCloseModal}>
          Cancel
        </Button>
      </ModalFooter>
    </CustomModal>
  );
};
