import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ApplicationForm from "./applicationComponents/ApplicationForm";
import WithHeader from "../../../layouts/WithHeader";

import { useAddApplicationMutation } from "../../../rtkStore/services/applicationApi";

const ApplicationCreateScreen = () => {
  const breadcrumbs = [
    { title: "Applications", path: "/app/application/list" },
    { title: "New Application" },
  ];

  const navigate = useNavigate();

  // RTK Query
  const [createApplication, { isLoading: creatingApplication, error }] =
    useAddApplicationMutation();

  // methods
  const handleCreateApplication = async (data) => {
    try {
      const { _id } = await createApplication({ ...data }).unwrap();
      navigate(`/app/application/${_id}/detail`);
      toast.success("Application successfully added!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add application!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      isBusy={creatingApplication}
    >
      <ApplicationForm
        isBusy={creatingApplication}
        onSubmit={handleCreateApplication}
      />
    </WithHeader>
  );
};

export default ApplicationCreateScreen;
