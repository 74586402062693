import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import WorkflowForm from "./workflowComponents/WorkflowForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateWorkflowMutation } from "../../../rtkStore/services/workflowApi";

const WorkflowCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Workflows",
      path: "/app/workflow/list",
    },
    { title: "New Workflow" },
  ];

  // RTK Query
  const [createWorkflow, { isLoading: creatingWorkflow, error }] =
    useCreateWorkflowMutation();

  // methods
  const handleCreateWorkflow = (data) => {
    createWorkflow(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path);
        toast.success("Workflow successfully created!");
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={creatingWorkflow}
      error={error}
    >
      <WorkflowForm isBusy={creatingWorkflow} onSubmit={handleCreateWorkflow} />
    </WithHeader>
  );
};

export default WorkflowCreateScreen;
