import React from "react";

import { Box, Grid, Skeleton } from "@mui/material";

const GridSkeleton = () => {
  return (
    <Box padding={3}>
      <Grid container columnSpacing={6} rowSpacing={4}>
        {new Array(4).fill(null).map((_, index) => (
          <Grid key={index} item md={4}>
            <Skeleton variant="rect" height={60} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridSkeleton;
