import React from "react";
import { Box, Rating } from "@mui/material";

import StarIcon from "@mui/icons-material/Star";

const AppRating = ({ value, onChange, hideLabel = false, ...otherProps }) => {
  const [hover, setHover] = React.useState(-1);

  const _labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  return (
    <Box
      sx={{
        width: 200,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        precision={0.5}
        value={value}
        onChange={onChange}
        onChangeActive={(_, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        {...otherProps}
      />

      {value !== null && !hideLabel && (
        <Box sx={{ ml: 2 }}>{_labels[hover !== -1 ? hover : value]}</Box>
      )}
    </Box>
  );
};

export default AppRating;
