import { baseApi } from "./baseApi";

const PUBLIC_BASE_URL = "/public";

export const publicApi = baseApi.injectEndpoints({
  tagTypes: ["Public"],

  endpoints: (builder) => ({
    getCourseCatalogue: builder.query({
      query: (queryParams) => ({
        url: `${PUBLIC_BASE_URL}/course-catalogues`,
        params: queryParams,
      }),
    }),

    getCourseCatalogueDetail: builder.query({
      query: (courseId) => `${PUBLIC_BASE_URL}/course-catalogues/${courseId}`,
    }),

    getPublicAccountList: builder.query({
      query: () => `${PUBLIC_BASE_URL}/accounts/list`,
    }),

    getPublicAccountEntities: builder.query({
      query: (accountId) =>
        `${PUBLIC_BASE_URL}/accounts/${accountId}/entities/list`,
    }),

    getPublicLocationUniversities: builder.query({
      query: (locationId) =>
        `${PUBLIC_BASE_URL}/locations/${locationId}/universities/list`,
    }),

    applyForCourse: builder.mutation({
      query: (payload) => ({
        url: `${PUBLIC_BASE_URL}/course-catalogues/apply`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCourseCatalogueQuery,
  useLazyGetCourseCatalogueQuery,
  useGetCourseCatalogueDetailQuery,
  useApplyForCourseMutation,

  useGetPublicAccountListQuery,
  useLazyGetPublicAccountEntitiesQuery,

  useLazyGetPublicLocationUniversitiesQuery,
} = publicApi;
