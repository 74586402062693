import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import moment from "moment";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import AppTable from "../../../../../components/Shared/AppTable";
import RemainingDays from "../../../../../components/Shared/RemainingDays";
import TableFilters from "../../../../../components/Shared/AppTable/TableFilters";

import { selectLoggedInUser } from "../../../../../rtkStore/authSlice";
import { useGetClientsByNextActionQuery } from "../../../../../rtkStore/services/clientApi";
import { getDateDifference } from "../../../../../utils/getDateDifference";

const ClientNextActions = () => {
  const [showMyClients, setShowMyClients] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const loggedInUser = useSelector(selectLoggedInUser);

  const columns = [
    {
      name: "Client",
      selector: (row) => (
        <Link
          to={`/app/client/${row?._id}/detail`}
          className="hover:text-blue-600 hover:underline"
        >
          {`${row?.firstName} ${row?.lastName}`}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Next Action",
      selector: (row) => row?.nextAction?.name ?? "N/A",
    },
    {
      name: "Next Action Date",
      selector: (row) =>
        row?.nextActionDate ? (
          moment(row?.nextActionDate)?.format("MMM DD, YYYY")
        ) : (
          <div className="bg-red-400 px-2 py-0.5 text-sm text-red-50">
            Invalid Date
          </div>
        ),
      sortable: true,
    },
    {
      name: "Responsible",
      selector: (row) => row?.responsible?.name ?? "N/A",
    },
    {
      name: "Overdue Days",
      selector: (row) => <RemainingDays date={row?.nextActionDate} />,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link to={`/app/client/${row?._id}/detail`}>
          <IconButton size="small" aria-label="Client">
            <InfoOutlined color="primary" />
          </IconButton>
        </Link>
      ),
      ignoreRowClick: true,
      right: true,
      button: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => getDateDifference(row?.nextActionDate) < 0,
      classNames: ["due-next-action"],
    },
    {
      when: (row) =>
        row?.nextActionDate &&
        getDateDifference(row?.nextActionDate) > -1 &&
        getDateDifference(row?.nextActionDate) < 1,
      classNames: ["due-today"],
    },
  ];

  //   RTK Query
  const { data: nextActionClients, isLoading: loadingNextActionClients } =
    useGetClientsByNextActionQuery();

  const filteredClients = React.useMemo(() => {
    return nextActionClients?.filter((client) =>
      showMyClients ? client?.responsible?._id === loggedInUser?._id : client
    );
  }, [nextActionClients, showMyClients, loggedInUser]);

  return (
    <>
      <TableFilters
        filterText={filterText}
        helperText="Name/Next Action"
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyClients}
        onShowMineChange={(e) => setShowMyClients(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={
          filteredClients?.filter(
            (item) =>
              item &&
              (item.firstName
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
                item.lastName
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item?.nextAction?.name
                  .toLowerCase()
                  .includes(filterText.toLowerCase()))
          ) ?? []
        }
        progressPending={loadingNextActionClients}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
};

export default ClientNextActions;
