import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import {
  AccountCircleOutlined,
  AttachFile,
  PriorityHighOutlined,
  StairsOutlined,
  HistoryEduOutlined,
  PunchClockOutlined,
} from "@mui/icons-material";

import ExtensionIcon from "../../../../components/Shared/ExtensionIcon";
import InformationTile from "../../../../components/Shared/InformationTile";

const FeedbackBasicDetail = ({ feedbackDetail }) => {
  return (
    <Box padding={3}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <Typography variant="h6">{feedbackDetail?.title}</Typography>
        </Grid>
        <Grid item md={4}>
          <InformationTile
            icon={() => <PriorityHighOutlined color="primary" />}
            title="Priority"
            subtitle={feedbackDetail?.priority}
          />
        </Grid>
        <Grid item md={4}>
          <InformationTile
            icon={() => <StairsOutlined color="primary" />}
            title="Stage"
            subtitle={feedbackDetail?.stage}
          />
        </Grid>
        <Grid item md={4}>
          <InformationTile
            icon={() => <HistoryEduOutlined color="primary" />}
            title="Requested By"
            subtitle={feedbackDetail?.createdBy?.name}
          />
        </Grid>
        <Grid item md={4}>
          <InformationTile
            icon={() => <PunchClockOutlined color="primary" />}
            title="Created At"
            subtitle={new Date(feedbackDetail?.createdAt).toUTCString()}
          />
        </Grid>
        <Grid item md={4}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Responsible"
            subtitle={feedbackDetail?.responsible?.name}
          />
        </Grid>
        {feedbackDetail?.updatedBy && (
          <Grid item md={4}>
            <InformationTile
              icon={() => <HistoryEduOutlined color="primary" />}
              title="Recently Updated By"
            >
              <span>{feedbackDetail?.updatedBy?.name} </span>
              <span className="text-gray-400">
                on {new Date(feedbackDetail?.updatedAt).toDateString()}
              </span>
            </InformationTile>
          </Grid>
        )}
        <Grid item md={8}>
          <InformationTile
            icon={() => <AttachFile color="primary" />}
            title="Attachment Url"
          >
            {feedbackDetail?.attachmentUrl ? (
              <a
                href={feedbackDetail?.attachmentUrl}
                target="_blank"
                rel="noreferrer"
                style={{ color: "var(--primary)" }}
              >
                <ExtensionIcon fileUrl={feedbackDetail?.attachmentUrl} />
              </a>
            ) : (
              "N/A"
            )}
          </InformationTile>
        </Grid>
      </Grid>
      <br />
      <Box> {feedbackDetail?.description} </Box>
    </Box>
  );
};

export default FeedbackBasicDetail;
