import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { Container, Typography, Grid } from "@mui/material"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import SchoolIcon from "@mui/icons-material/School"
import DesignServicesIcon from "@mui/icons-material/DesignServices"
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety"
import PublicIcon from "@mui/icons-material/Public"
import NightShelterIcon from "@mui/icons-material/NightShelter"
import aboutUsImage from "../assets/images/ourServicesImage.png"
import Banner from "../../components/Banner"
// import "./AboutScreen.css";

const ServiceScreen = () => {
  return (
    <>
      <Banner title={"Our Services"} image={aboutUsImage} />
      <Container maxWidth='xl' mt={3} className='WhiteContainer'>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <SupervisorAccountIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Career Counselling
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  We guide you through your study options and assist with
                  application.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-down'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <DesignServicesIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Visa Services
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  Applying for an Australian visa can be stressful and
                  confusing, we make it easier for you.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <SchoolIcon sx={{ fontSize: 50 }} className='ServicesIcons' />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Scholarship Processing
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  FSW's will help you to see if you are eligible for Australian
                  Government’s $200M scholarship program.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <HealthAndSafetyIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Health Cover
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  We’re here to help you choose the right and affordable health
                  cover.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <PublicIcon sx={{ fontSize: 50 }} className='ServicesIcons' />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Destination Services
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  With FSW's pro-destination assist pack, we help you start your
                  new life abroad.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Card className='OurServicesCardContent'>
              <CardContent>
                <Typography align='center'>
                  <NightShelterIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                  mt={3}
                >
                  Student Accomodation
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                >
                  New to the town and nowhere to go? The Can Brand is there to
                  help you out.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <Container maxWidth='xl' mt={3} className='WhiteContainer'>
        <Typography mt={5} align='center'>
          <h1> Our Partners </h1>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <SupervisorAccountIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <DesignServicesIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <SchoolIcon sx={{ fontSize: 50 }} className='ServicesIcons' />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <HealthAndSafetyIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <PublicIcon sx={{ fontSize: 50 }} className='ServicesIcons' />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={2} sm={4}>
            <Card className='OurPartnerCardContent'>
              <CardContent>
                <Typography align='center'>
                  <NightShelterIcon
                    sx={{ fontSize: 50 }}
                    className='ServicesIcons'
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container> */}
    </>
  )
}

export default ServiceScreen
