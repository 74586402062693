import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add,
  EditOutlined,
  SchoolOutlined,
  FiberManualRecord,
  EventOutlined,
} from "@mui/icons-material";
import moment from "moment";

import CourseScholarshipForm from "./CourseScholarshipForm";
import InformationTile from "../../../../components/Shared/InformationTile";

import {
  useAddCourseScholarshipMutation,
  useUpdateCourseScholarshipMutation,
} from "../../../../rtkStore/services/courseApi";

const CourseScholarshipsTab = ({ courseScholarships = [] }) => {
  const [showScholarshipForm, setShowScholarshipForm] = React.useState(false);
  const [selectedScholarship, setSelectedScholarship] = React.useState(null);

  const { id: courseId } = useParams();

  // RTK Query
  const [addCourseScholarship, { isLoading: addingScholarship }] =
    useAddCourseScholarshipMutation();

  const [updateCourseScholarship, { isLoading: updatingScholarship }] =
    useUpdateCourseScholarshipMutation();

  //   methods
  const handleCancel = () => {
    setShowScholarshipForm(false);
    setSelectedScholarship(null);
  };

  const handleAddCourseDates = async (data) => {
    setSelectedScholarship({ ...data });
    try {
      await addCourseScholarship({ ...data, courseId }).unwrap();
      toast.success("Course Scholarship successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add scholarship!");
    }
  };

  const handleCourseScholarshipEdit = (date) => {
    setShowScholarshipForm(true);
    setSelectedScholarship(date);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateCourseDates = async (data) => {
    try {
      await updateCourseScholarship({ ...data, courseId }).unwrap();
      toast.success("Course Scholarship successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update scholarship!");
    }
  };

  return (
    <>
      <Box
        padding={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="inline-flex" alignItems="center" gap={2}>
          <SchoolOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>
            Course Scholarships
          </Typography>
        </Box>

        {!showScholarshipForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowScholarshipForm(true)}
          >
            Scholarship
          </Button>
        )}
      </Box>

      <Divider />

      <Collapse in={showScholarshipForm}>
        <CourseScholarshipForm
          scholarshipDetail={selectedScholarship}
          isBusy={addingScholarship || updatingScholarship}
          onSubmit={
            !selectedScholarship
              ? handleAddCourseDates
              : handleUpdateCourseDates
          }
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <Box paddingX={3}>
        {courseScholarships.length ? (
          courseScholarships?.map((scholarship, index) => (
            <React.Fragment key={scholarship._id}>
              <Box paddingY={3}>
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                  gap={2}
                >
                  <SchoolOutlined color="primary" style={{ marginTop: 8 }} />

                  <Box flexGrow={1}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography> {scholarship?.title} </Typography>

                        <IconButton
                          onClick={() =>
                            handleCourseScholarshipEdit(scholarship)
                          }
                        >
                          <EditOutlined />
                        </IconButton>
                      </Box>

                      <Box display="flex" alignItems="center" gap={3}>
                        <InformationTile
                          icon={() => (
                            <EventOutlined color="success" fontSize="small" />
                          )}
                          title="Open Date"
                          subtitle={moment(scholarship?.openDate)?.format(
                            "DD/MM/YYYY"
                          )}
                        />

                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 10 }}
                        />

                        <InformationTile
                          icon={() => (
                            <EventOutlined color="error" fontSize="small" />
                          )}
                          title="Deadline"
                          subtitle={moment(scholarship?.finalApplyDate)?.format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </Box>

                      <div className="rounded bg-blue-50 p-4">
                        <InformationTile
                          title="Value"
                          subtitle={scholarship?.value}
                        />
                      </div>

                      <div className="rounded bg-blue-50 p-4">
                        <InformationTile
                          title="Eligibility"
                          subtitle={scholarship?.eligibility}
                        />
                      </div>

                      <div className="rounded bg-blue-50 p-4">
                        <InformationTile
                          title="Description"
                          subtitle={scholarship?.description}
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {index !== courseScholarships.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>
            <Typography>No Scholarships.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CourseScholarshipsTab;
