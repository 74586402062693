import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { DriveFileRenameOutlineRounded } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const ApplicationResult = ({ applications = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Applications</div>

      {applications.map((application, index) => (
        <Link
          to={`/app/application/${application?._id}/detail`}
          key={application?._id}
        >
          <ResultItem
            title={application?.title}
            subtitle={application.createdAt}
            icon={() => <DriveFileRenameOutlineRounded color="primary" />}
          />

          {index !== applications.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default ApplicationResult;
