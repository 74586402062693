import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";

import { useListListQuery } from "../../../../../rtkStore/services/listService";

const _INITIAL_STATE = {
  institutionName: "",
  level: "",
  major: "",
  remark: "",
  startDate: "",
  endDate: "",
};

const EducationForm = ({
  educationDetail,
  isBusy,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  // RTK Query
  const { data: clientEducationLevels } = useListListQuery({
    type: "Student Education Levels",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ..._INITIAL_STATE,
      institutionName: educationDetail?.institutionName ?? "",
      level: educationDetail?.level?._id ?? "",
      major: educationDetail?.major ?? "",
      remark: educationDetail?.remark ?? "",
      startDate: educationDetail?.startDate ?? "",
      endDate: educationDetail?.endDate ?? "",
    },
  });

  React.useEffect(() => {
    if (!educationDetail) return reset({ ..._INITIAL_STATE });

    reset({ ...educationDetail, level: educationDetail?.level?._id ?? "" });
  }, [educationDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="institutionName"
              control={control}
              rules={{ required: "Institution Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Institution Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.institutionName && (
              <p className="error">{errors.institutionName.message}</p>
            )}
          </Grid>
          {/* <Grid item md={4}>
            <Controller
              name="level"
              control={control}
              rules={{ required: "Level Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Level Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.level && <p className="error">{errors.level.message}</p>}
          </Grid> */}
          <Grid item md={4}>
            <Controller
              name="level"
              control={control}
              rules={{ required: "Please select level" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-level">Education Level</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-level"
                    label="Education Level"
                    placeholder="Select Level"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {clientEducationLevels?.map((level) => (
                      <MenuItem key={level._id} value={level._id}>
                        {level?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.level && <p className="error">{errors.level.message}</p>}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="major"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Major"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: "Start Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                />
              )}
            />
            {errors.startDate && (
              <p className="error">{errors.startDate.message}</p>
            )}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: "End Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="End Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  value={moment(field?.value).format("YYYY-MM-DD")}
                />
              )}
            />
            {errors.endDate && (
              <p className="error">{errors.endDate.message}</p>
            )}
          </Grid>
          <Grid item md={8}>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Remarks"
                  type="text"
                  size="small"
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {educationDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default EducationForm;
