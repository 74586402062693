import React from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, MenuItem, TextField } from "@mui/material";

import {
  useApplyForCourseMutation,
  useGetPublicAccountListQuery,
  useLazyGetPublicAccountEntitiesQuery,
} from "../../../../rtkStore/services/publicApi";

const ApplyCourseForm = ({ courseDetail = null }) => {
  const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    account: "",
    entity: "",
    message: `I am interested in applying for ${courseDetail?.title}, located at ${courseDetail?.location?.name}.`,
  };

  // RTK Query
  const { data: accountList = [], isLoading: loadingAccounts } =
    useGetPublicAccountListQuery();

  const [
    getAccountEntities,
    { data: accountEntities, isFetching: fetchingEntities },
  ] = useLazyGetPublicAccountEntitiesQuery();

  const [applyCourse, { isLoading: applyingCourse }] =
    useApplyForCourseMutation();

  const _mappedAccountList = accountList?.map((el) => ({
    label: el?.name,
    value: el?._id,
  }));

  const _mappedAccountEntities = accountEntities?.map((el) => ({
    label: el?.name,
    value: el?._id,
  }));

  // form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedAccount = watch("account");

  //   methods
  const handleApplyForCourse = async (data) => {
    const payload = {
      ...data,
      account: data?.account?.value,
      entity: data?.entity?.value,
    };

    try {
      await applyCourse(payload).unwrap();
      toast.success("Successfully applied for the course!");
      reset({ ...INITIAL_STATE });
    } catch (err) {
      toast.error(err?.data?.message ?? "Something went wrong!");
    }
  };

  React.useEffect(() => {
    if (!selectedAccount) return;

    const handleGetAccountEntities = async (account) => {
      try {
        await getAccountEntities(account).unwrap();
      } catch (err) {
        toast.error(err?.data?.message ?? "Something went wrong!");
      }
    };

    handleGetAccountEntities(selectedAccount?.value);
  }, [getAccountEntities, selectedAccount]);

  return (
    <div className="rounded border px-6 pt-4 pb-6 shadow">
      <div className="mb-6 text-xl font-medium">Apply for Course</div>

      <div className="flex flex-col space-y-6">
        <Controller
          name="firstName"
          control={control}
          rules={{ required: "First Name is required." }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="First Name"
              type="text"
              size="small"
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message ?? ""}
              {...field}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          rules={{ required: "Last Name is required." }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Last Name"
              type="text"
              size="small"
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message ?? ""}
              {...field}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{ required: "Email Address is required." }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              size="small"
              error={!!errors?.email}
              helperText={errors?.email?.message ?? ""}
              {...field}
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          rules={{ required: "Phone Number is required." }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Phone"
              type="tel"
              size="small"
              error={!!errors?.phone}
              helperText={errors?.phone?.message ?? ""}
              {...field}
            />
          )}
        />

        <Controller
          name="account"
          control={control}
          rules={{ required: "Agency is required." }}
          render={({ field, onBlur }) => (
            <Autocomplete
              onBlur={onBlur}
              loading={loadingAccounts}
              loadingText="Loading Agencies..."
              options={_mappedAccountList ?? []}
              value={field?.value}
              size="small"
              onChange={(e, data) => {
                setValue("account", data);
                setValue("entity", "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agency"
                  error={!!errors?.account}
                  helperText={errors?.account?.message ?? ""}
                />
              )}
              renderOption={(props, option) => {
                return (
                  option && (
                    <MenuItem {...props} key={option?.value}>
                      {option?.label}
                    </MenuItem>
                  )
                );
              }}
            />
          )}
        />

        {selectedAccount && (
          <Controller
            name="entity"
            control={control}
            rules={{ required: "Agency's Entity is required." }}
            render={({ field, onBlur }) => (
              <Autocomplete
                onBlur={onBlur}
                loading={fetchingEntities}
                loadingText="Loading Entities..."
                options={_mappedAccountEntities ?? []}
                value={field?.value}
                size="small"
                onChange={(e, data) => setValue("entity", data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Entity"
                    error={!!errors?.entity}
                    helperText={errors?.entity?.message ?? ""}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    option && (
                      <MenuItem {...props} key={option?.value}>
                        {option?.label}
                      </MenuItem>
                    )
                  );
                }}
              />
            )}
          />
        )}

        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Message"
              type="tel"
              size="small"
              multiline
              rows={3}
              {...field}
            />
          )}
        />

        <button
          className="rounded border border-red-200 bg-red-100 p-2 font-medium uppercase tracking-wider text-red-600 transition-colors duration-150 ease-linear hover:bg-red-600 hover:text-white"
          disabled={fetchingEntities || loadingAccounts || applyingCourse}
          onClick={handleSubmit(handleApplyForCourse)}
        >
          Apply Course
        </button>
      </div>
    </div>
  );
};

export default ApplyCourseForm;
