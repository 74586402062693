import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  IconButton,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import MenuIcon from "@mui/icons-material/Menu";

import AppQuickAction from "../../../components/Navbar/AppQuickAction";
import AppSearchbar from "../../../components/Navbar/search/AppSearchbar";
import AccountMenu from "./AccountMenu";
import AppLogo from "./AppLogo";

import { useSidebarContext } from "../SidebarContextProvider";
import { useIsSidebarVisible } from "../../../hooks/useSidebarVisible";

const NavBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  backgroundColor: "#fff",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none !important",
  borderBottom: "1px solid lightgray",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = () => {
  const { isSidebarVisible } = useIsSidebarVisible();

  const { showSidebar, setShowSidebar, drawerWidth, validEntity } =
    useSidebarContext();

  React.useEffect(() => {
    setShowSidebar(isSidebarVisible);
  }, [isSidebarVisible, setShowSidebar]);

  return (
    <NavBar position="fixed" open={showSidebar} drawerWidth={drawerWidth}>
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={() => setShowSidebar(true)}
          edge="start"
          sx={{ mr: 2, ...(showSidebar && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>

        <div>{!showSidebar && <AppLogo />}</div>

        <Box
          display="flex"
          flexGrow={1}
          alignItems="center"
          justifyContent="space-between"
        >
          {validEntity && (
            <div className="flex flex-grow items-center justify-center space-x-2">
              <AppQuickAction />

              <AppSearchbar />

              <Tooltip title="New Feedback" arrow>
                <Link to="/app/feedback/create">
                  <IconButton>
                    <AddCommentOutlinedIcon color="primary" />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          )}
          <AccountMenu />
        </Box>
      </Toolbar>
    </NavBar>
  );
};

export default AppBar;
