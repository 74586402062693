import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import UniversityForm from "./universityComponents/UniversityForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateUniversityMutation } from "../../../rtkStore/services/universityApi";

const UniversityCreateScreen = () => {
  const breadcrumbs = [
    {
      title: "Universities",
      path: "/app/university/list",
    },
    { title: "New University" },
  ];

  let navigate = useNavigate();

  // RTK Query
  const [createUniversity, { isLoading: creatingUniversity, error }] =
    useCreateUniversityMutation();

  // methods
  const handleCreateUniversity = async (data) => {
    try {
      const { _id } = await createUniversity(data).unwrap();
      navigate(`/app/university/${_id}/detail`);
      toast.success("University successfully created!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create university!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={creatingUniversity}
      error={error}
    >
      <UniversityForm
        isBusy={creatingUniversity}
        onSubmit={handleCreateUniversity}
      />
    </WithHeader>
  );
};

export default UniversityCreateScreen;
