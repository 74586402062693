import React, { useState } from "react"
import { Container, Typography } from "@mui/material"
import Slider from "react-slick"

import image31 from "../screens/assets/images/colleges/ACA.jpg"
import image32 from "../screens/assets/images/colleges/ACCHS.png"
import image33 from "../screens/assets/images/colleges/ACE.png"
// import image34 from "../screens/assets/images/colleges/ACMI.png"
import image35 from "../screens/assets/images/colleges/ACVET.png"
import image36 from "../screens/assets/images/colleges/AHIC.png"
import image37 from "../screens/assets/images/colleges/AIBT.png"
import image38 from "../screens/assets/images/colleges/AVI.png"
import image39 from "../screens/assets/images/colleges/AVILA.png"
import image40 from "../screens/assets/images/colleges/AVTA.png"
import image41 from "../screens/assets/images/colleges/BLUE.png"
import image42 from "../screens/assets/images/colleges/BRIS.png"
import image43 from "../screens/assets/images/colleges/CHOICE.png"
import image44 from "../screens/assets/images/colleges/CHUR.png"
import image45 from "../screens/assets/images/colleges/CIHE.png"

import image46 from "../screens/assets/images/colleges/ENTE.png"
import image47 from "../screens/assets/images/colleges/ETEA.png"
import image48 from "../screens/assets/images/colleges/IIE.png"
import image49 from "../screens/assets/images/colleges/IMAGE.png"
import image50 from "../screens/assets/images/colleges/IST.png"
import image51 from "../screens/assets/images/colleges/KCBT.png"
import image52 from "../screens/assets/images/colleges/KING.png"
import image53 from "../screens/assets/images/colleges/KINGINT.png"
import image54 from "../screens/assets/images/colleges/LEAD.png"
import image55 from "../screens/assets/images/colleges/MACA.png"

// import image56 from "../screens/assets/images/colleges/MELB.png"
import image57 from "../screens/assets/images/colleges/NPOA.png"
import image58 from "../screens/assets/images/colleges/OBAN.png"
import image59 from "../screens/assets/images/colleges/REACH.png"
import image60 from "../screens/assets/images/colleges/RHODES.png"
import image61 from "../screens/assets/images/colleges/SALFORD.png"
// import image62 from "../screens/assets/images/colleges/SHER.png"
import image63 from "../screens/assets/images/colleges/SOUTH.png"
import image64 from "../screens/assets/images/colleges/SSBT.png"
import image65 from "../screens/assets/images/colleges/STAN.jpg"

import image66 from "../screens/assets/images/colleges/SUN.png"
import image67 from "../screens/assets/images/colleges/SYDNEY.png"
import image68 from "../screens/assets/images/colleges/TIIS.png"
import image69 from "../screens/assets/images/colleges/TKL.png"
import image70 from "../screens/assets/images/colleges/VICT.png"
import image71 from "../screens/assets/images/colleges/VIGIL.png"

const Universities = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,

    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    rows: 2,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 2,
        },
      },
    ],
  }

  const [items, setItems] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
    // 38,
    // 39,
    // 40,
  ])

  const imgList = [
    image68,
    image48,
    image45,
    image54,
    image63,
    image59,
    image52,
    image57,
    image55,
    image65,
    image31,
    image32,
    image33,
    // image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image46,
    image47,
    image49,
    image50,
    image51,
    image53,
    // image56,
    image58,
    image60,
    image61,
    // image62,
    image64,
    image66,
    image67,
    image69,
    image70,
    image71,
  ]
  return (
    <Container maxWidth='xl' sx={{ paddingTop: "3rem" }}>
      <Typography align='center'>
        <h1 className='StudyAbroadH1'> College and Institutions </h1>
      </Typography>
      <div className='carousel-wrapper'>
        <Slider {...settings}>
          {items.map((item) => (
            <div key={item}>
              <img
                src={imgList[item]}
                className='UniversityLogoes'
                alt='universitieslogoes'
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  )
}

export default Universities
