import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  Add,
  AssignmentOutlined,
  EventOutlined,
  FiberManualRecord,
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";

import AppDialog from "../../../../../components/Shared/AppDialog";
import TestForm from "./TestForm";

import {
  useAddTestDetailMutation,
  useRemoveTestDetailMutation,
  useUpdateTestDetailMutation,
} from "../../../../../rtkStore/services/clientApi";

import { useClientDetail } from "../ClientBasicInformationTab";

const TestDetailTab = () => {
  const [showAddTest, setShowAddEduction] = React.useState(false);
  const [selectedTest, setSelectedTest] = React.useState(null);
  const [showAppDialog, setShowAppDialog] = React.useState(false);

  const { id: clientId } = useParams();

  const {
    clientDetail: { tests },
  } = useClientDetail();

  // RTK Query
  const [addTestDetail, { isLoading: addingTestDetail }] =
    useAddTestDetailMutation();

  const [updateTestDetail, { isLoading: updatingTestDetail }] =
    useUpdateTestDetailMutation();

  const [removeTestDetail, { isLoading: removingTestDetail }] =
    useRemoveTestDetailMutation();

  // methods
  const handleCancelTest = () => {
    setSelectedTest(null);
    setShowAddEduction(false);
  };

  const handleTestDetailEdit = (testDetail) => {
    setSelectedTest(testDetail);
    setShowAddEduction(true);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAddTestDetail = async (data) => {
    setSelectedTest({ ...data });
    try {
      await addTestDetail({ ...data, clientId }).unwrap();
      toast.success("Test detail successfully added!");
      handleCancelTest();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add test detail!");
    }
  };

  const handleUpdateTestDetail = async (data) => {
    try {
      await updateTestDetail({ ...data, clientId }).unwrap();
      toast.success("Test detail successfully updated!");
      handleCancelTest();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update test detail!");
    }
  };

  const handleRemoveTestDetail = () => {
    if (!selectedTest) return;

    removeTestDetail({ ...selectedTest, clientId })
      .unwrap()
      .then(() => {
        toast.info("Test Information removed!");
        setShowAppDialog(false);
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <AssignmentOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Test Taken</Typography>
        </Box>

        {!showAddTest && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => {
              setSelectedTest(null);
              setShowAddEduction(true);
            }}
          >
            Test Detail
          </Button>
        )}
      </Box>

      <Collapse in={showAddTest}>
        <TestForm
          testDetail={selectedTest}
          isBusy={addingTestDetail || updatingTestDetail}
          onCancel={handleCancelTest}
          onSubmit={
            !selectedTest ? handleAddTestDetail : handleUpdateTestDetail
          }
        />
      </Collapse>

      <AppDialog
        title="Confirm Removal!"
        open={showAppDialog}
        isBusy={removingTestDetail}
        onConfirm={handleRemoveTestDetail}
        onClose={() => setShowAppDialog(false)}
      >
        Are you sure you want to remove {selectedTest?.name}?
      </AppDialog>

      <Divider />

      <Box paddingX={3}>
        {!!tests.length ? (
          tests.map((testDetail, index) => (
            <React.Fragment key={testDetail._id}>
              <Box paddingY={3} display="flex" gap={2}>
                <AssignmentOutlined
                  color="primary"
                  fontSize="small"
                  style={{ marginTop: 2 }}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography>{testDetail.name}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <div
                        style={{
                          backgroundColor: "var(--primary)",
                          padding: "2px 10px",
                          borderRadius: ".25rem",
                          color: "white",
                          fontSize: 14,
                        }}
                      >
                        Score: {testDetail.testScore}
                      </div>
                    </Box>

                    <Box display="flex" alignItems="center" gap={4}>
                      <Tooltip title="Exam Date" arrow placement="top">
                        <Box display="flex" gap={1}>
                          <EventOutlined fontSize="small" color="success" />
                          <Typography style={{ fontSize: 14 }}>
                            {moment(testDetail.examDate).format("YYYY/MM/DD")}
                          </Typography>
                        </Box>
                      </Tooltip>

                      <Tooltip title="Valid Till" arrow placement="top">
                        <Box display="flex" gap={1}>
                          <EventOutlined fontSize="small" color="error" />
                          <Typography style={{ fontSize: 14 }}>
                            {moment(testDetail.validTill).format("YYYY/MM/DD")}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box style={{ color: "gray" }}>{testDetail.remark}</Box>
                  </Box>

                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedTest(testDetail);
                      setShowAppDialog(true);
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>

                  <IconButton onClick={() => handleTestDetailEdit(testDetail)}>
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>

              {index !== tests.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Test Taken</Box>
        )}
      </Box>
    </>
  );
};

export default TestDetailTab;
