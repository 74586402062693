import React from "react";

import { toast } from "react-toastify";
import axios from "axios";

const useCloudinaryUpload = () => {
  const [isUploading, setIsUploading] = React.useState(false);

  const uploadToCloudinary = async (uploadFile, uploadFolder = "image") => {
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("upload_preset", "firstsource"); //cloudinary set up
    formData.append("folder", "fsw-crm"); //to add to defined folder

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let result = null;

    try {
      setIsUploading(true);
      result = await axios.post(
        "https://api.cloudinary.com/v1_1/firstsourceworld-com/raw/upload",
        formData,
        config
      );
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't upload file!");
    } finally {
      setIsUploading(false);
    }

    return result;
  };

  return { uploadToCloudinary, isUploading };
};

export default useCloudinaryUpload;
