import React from "react";

import { Box, Divider, IconButton } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import NoteListItem from "./NoteListItem";

const NoteList = ({ notes = [], onNoteEdit = () => {} }) => {
  const _sortedNoteList = [...notes]?.sort((a, b) =>
    a?.createdAt > b?.createdAt ? -1 : 1
  );

  return (
    <Box paddingX={3}>
      {_sortedNoteList.length ? (
        _sortedNoteList.map((note, index) => (
          <React.Fragment key={index}>
            <NoteListItem
              note={note}
              hasActions
              noteActions={() => (
                <IconButton onClick={() => onNoteEdit(note)}>
                  <EditOutlined />
                </IconButton>
              )}
            />

            {index !== notes.length - 1 && <Divider />}
          </React.Fragment>
        ))
      ) : (
        <Box paddingY={3}>No Notes available.</Box>
      )}
    </Box>
  );
};

export default NoteList;
