import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Link,
  // List,
  // ListItem,
} from "@mui/material";

// import Subscribe from "./Subscribe"
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import Box from "@mui/material/Box";
// import qrcode from "../screens/assets/images/qr.png"
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
// import MusicNoteSharpIcon from "@mui/icons-material/MusicNoteSharp"
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
// import TikTok from "../assets/icons/tiktok.svg"
// import TwitterIcon from "@mui/icons-material/Twitter"
import whiteLogo from "../assets/fsw-logoWhite.png";
const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www-cdn.icef.com/scripts/iasbadgeid.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup script on unmount
    };
  }, []);

  return (
    <>
      <Grid className="background background-filter" container>
        <Container maxWidth="xl" mt={3}>
          <span>
            <Box sx={{ flexGrow: 1 }} className="FooterBox">
              <Grid container spacing={2}>
                <Grid item sm={12} md={4} className="z-20">
                  <Typography sx={{ mt: 6, mb: 3 }} className="redColor">
                    <img
                      // className='whiteLogo'
                      style={{ width: "100px" }}
                      src={whiteLogo}
                      alt="logoImage"
                    ></img>
                  </Typography>
                  <Typography className="FooterSmallText">
                    First Source World (FSW) is a trusted Australian-based
                    education and migration consultancy firm serving the diverse
                    needs of clients across Southeast Asia since 2015.
                  </Typography>
                  <Box
                    display="flex"
                    sx={{ marginBottom: "15px" }}
                    mt={2}
                    color="white"
                  >
                    <Box textAlign="center" sx={{ marginRight: "10px" }}>
                      <Link
                        href="https://www.facebook.com/fsw.philippines/"
                        target="_blank"
                      >
                        <FacebookIcon className="SocialIcons" />
                      </Link>
                      <Typography className="FooterSmallText">
                        Facebook
                      </Typography>
                    </Box>

                    <Box textAlign="center" sx={{ marginRight: "10px" }}>
                      <Link
                        href="https://www.instagram.com/fsw.global/"
                        target="_blank"
                      >
                        <InstagramIcon className="SocialIcons" />
                      </Link>
                      <Typography className="FooterSmallText">
                        Instagram
                      </Typography>
                    </Box>

                    <Box textAlign="center" sx={{ marginRight: "10px" }}>
                      <Link
                        href="https://www.linkedin.com/company/first-source-world/"
                        target="_blank"
                      >
                        <LinkedInIcon className="SocialIcons" />
                      </Link>
                      <Typography className="FooterSmallText">
                        LinkedIn
                      </Typography>
                    </Box>
                    <Box textAlign="center" sx={{ marginRight: "10px" }}>
                      <Link
                        href="https://www.tiktok.com/@fsw.philippines"
                        target="_blank"
                      >
                        <div className="SocialIcons p-2.5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                          >
                            <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                          </svg>
                        </div>
                      </Link>
                      <Typography
                        className="FooterSmallText"
                        textAlign="center"
                      >
                        TikTok
                      </Typography>
                    </Box>
                    <Box textAlign="center" sx={{ marginRight: "10px" }}>
                      <Link
                        href="https://www.youtube.com/@FSWGlobalEducationVisaServices"
                        target="_blank"
                      >
                        <YouTubeIcon className="SocialIcons" />
                      </Link>
                      <Typography className="FooterSmallText">
                        Youtube
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item sm={12} md={2} className="z-20"></Grid>
                <Grid item sm={12} md={4} className="z-20">
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: 22,
                      fontWeight: 700,
                      mt: 6,
                      mb: 1,
                      color: "#fff",
                    }}
                  >
                    Australia Head Office
                  </Typography>
                  <Grid container color="white" sx={{ mb: 1 }}>
                    <Grid item xs={1}>
                      <LocationOnIcon sx={{ color: "#eb2428" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className="FooterSmallText">
                        27/2 O’Connell Street Parammatta Sydney, NSW, 2150
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container color="white" sx={{ mb: 1 }}>
                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <PhoneInTalkIcon sx={{ color: "#eb2428" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography className="FooterSmallText">
                          +61 (0)2 9639 4415
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container color="white" mb={2}>
                    <Grid item xs={1}>
                      <EmailIcon sx={{ color: "#eb2428" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography>
                        <a
                          href="mailto:fsw.contact@firstsourceworld.com"
                          className="FooterSmallText"
                        >
                          fsw.marketing@firstsourceworld.com
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      
                    </Grid>
                    {/* <Grid container color="white" mb={2} mt={2}>
                      <span id="iasBadge" data-account-id="5591"></span>
                      <script
                        async
                        defer
                        crossorigin="anonymous"
                        src="https://www-cdn.icef.com/scripts/iasbadgeid.js"
                      ></script>
                    </Grid> */}
                  </Grid>

                  <Grid container color="white" mb={2}>
                    <Grid item md={2} xs={3}></Grid>
                    {/* <img src={qrcode} alt='Qr Code' className='Qrscan'></img> */}

                    <Grid item md={10} xs={9}>
                      {/* <Typography>
                        <p
                          style={{
                            margin: "0",
                            fontWeight: "600",
                            lineHeight: "20px",
                          }}
                        >
                          Scan
                          <br /> For <br />
                          More
                        </p>
                      </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={12} md={2} className="z-20">
                <span
                        id="iasBadge"
                        data-account-id="5591"
                        style={{
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                          // backgroundColor: "blue",
                          borderRadius: "50%",
                          marginTop: "40px",
                        }}
                      ></span>
                </Grid>
              </Grid>
            </Box>
          </span>
        </Container>
      </Grid>
      <Box sx={12} className="BottomFooter">
        <Container maxWidth="xl">
          <Typography className="BottomFooterText">
            © {getCurrentYear()} First Source World (FSW). All rights reserved |
            Designed by{" "}
            <span>
              <Link
                href="https://gatewayx.tech/"
                className="footernepalcan"
                target="_blank"
              >
                GatewayX
              </Link>
            </span>
          </Typography>
        </Container>
      </Box>
    </>
  );
};
export default Footer;
