import React from "react";

import { Route, Routes } from "react-router-dom";
import TaskCreateScreen from "../screens/Admin/Task/TaskCreateScreen";
import TaskDetailScreen from "../screens/Admin/Task/TaskDetailScreen";
import TaskEditScreen from "../screens/Admin/Task/TaskEditScreen";

import TaskListScreen from "../screens/Admin/Task/TaskListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const TaskRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<TaskCreateScreen />} />
      <Route path=":id/edit" element={<TaskEditScreen />} />
      <Route path=":id/detail" element={<TaskDetailScreen />} />
      <Route path="list" element={<TaskListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default TaskRouter;
