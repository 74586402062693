import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  FiberManualRecord,
  AccountTreeOutlined,
  DescriptionOutlined,
} from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import AppDraggableList from "../Shared/AppDraggableList";
import CustomTabPanel from "../Shared/CustomTabPanel";
import ManageWorkflowStepTransactions from "./ManageWorkflowStepTransactions";
import WorkflowStepForm from "./WorkflowStepForm";

import { useReOrderDraggableList } from "../../hooks/useReOrderDraggableList";
import { useGetWorkflowTransactionsByAssigneeQuery } from "../../rtkStore/services/pathwayTransaction";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tab-panel-${index}`,
  };
}

const WorkflowStepUpdateTracker = ({
  workflow,
  assigneeSource,
  isBusy = false,
  onAddStepMutation = () => {},
  onReOrderSteps = () => {},
}) => {
  const [activeTab, setActiveTab] = React.useState("steps");
  const [selectedStep, setSelectedStep] = React.useState(null);
  const [showAddStepForm, setShowAddStepForm] = React.useState(false);

  const isFirstRender = React.useRef(true);

  const { id: documentId } = useParams();

  const activeWorkflowSteps = React.useMemo(() => {
    return workflow?.steps?.filter((step) => step.isActive) ?? [];
  }, [workflow]);

  const { orderedList: transactionWorkflowSteps, handleOnDragEnd } =
    useReOrderDraggableList({
      listItems: activeWorkflowSteps,
    });

  const { data: workflowTransactions = [] } =
    useGetWorkflowTransactionsByAssigneeQuery({
      assignee: documentId,
      workflowId: workflow?._id,
    });

  // methods
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleResetStepForm = () => {
    setShowAddStepForm(false);
    setSelectedStep(null);
    setActiveTab("steps");
  };

  const handleAddStepToWorkflow = async (data) => {
    setSelectedStep({ ...data });

    try {
      await onAddStepMutation({
        documentId,
        workflow: workflow?._id,
        newStep: { ...data, serialNumber: "", isActive: true },
      }).unwrap();
      toast.success("Step successfully added to workflow!");
      handleResetStepForm();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add step to workflow!");
    }
  };

  const getLatestWorkflowUpdate = (step) => {
    const stepTransactions = workflowTransactions.filter(
      (transaction) => transaction.step === step?._id
    );

    return stepTransactions.at(0);
  };

  const getStepStatus = (step) =>
    getLatestWorkflowUpdate(step)?.status ?? "Open";

  const getStatusChipColor = (step) => {
    const statusColors = {
      Completed: "bg-green-400",
      "In Progress": "bg-orange-400",
      Open: "bg-blue-400",
      "Not Applicable": "bg-gray-400",
      Withdrawn: "bg-red-400",
    };

    const stepStatus = getStepStatus(step);

    return statusColors[stepStatus] ?? statusColors["Open"];
  };

  const getStepStatusIndication = (step) => {
    const indications = {
      Completed: "bg-green-50 border border-green-200",
      "In Progress": "bg-orange-50 border border-orange-200",
      Open: "bg-blue-50 border border-blue-200",
      "Not Applicable": "bg-gray-50 border border-gray-200",
      Withdrawn: "bg-red-50 border border-red-200",
    };

    const stepStatus = getStepStatus(step);

    return stepStatus ? indications[stepStatus] : indications["Open"];
  };

  const handleAddStepTransaction = (step) => {
    if (!step) return;
    const currentStepStatus = getStepStatus(step);
    setSelectedStep({ ...step, currentStepStatus });
    setActiveTab("updates");
  };

  const completedStepCount = React.useMemo(() => {
    return workflowTransactions.reduce((acc, transaction) => {
      if (transaction.status === "Completed") acc++;
      return acc;
    }, 0);
  }, [workflowTransactions]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!transactionWorkflowSteps?.length || isFirstRender.current) return;

    const handleReOrderSteps = async (reOrderedSteps) => {
      try {
        await onReOrderSteps({
          documentId,
          workflow: workflow?._id,
          reOrderedSteps,
        }).unwrap();
      } catch (err) {
        toast.error(err?.data?.message ?? "Couldn't Re-Order Steps");
      }
    };

    const reOrderedSteps = transactionWorkflowSteps?.map(
      ({ _id, serialNumber }) => ({
        _id,
        serialNumber,
      })
    );

    if (reOrderedSteps?.length) handleReOrderSteps(reOrderedSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionWorkflowSteps]);

  return (
    <>
      <Box paddingY={3} display="flex" alignItems="start" gap={2}>
        <AccountTreeOutlined
          color="primary"
          fontSize="small"
          style={{ marginTop: 2 }}
        />

        <Box display="flex" flexGrow={1} flexDirection="column" gap={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Typography>{workflow.title}</Typography>

              <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

              <Typography>
                <span>
                  {`${completedStepCount} / ${workflow?.steps?.length}`}
                </span>
                <span style={{ color: "gray", marginLeft: 10 }}>
                  step completed
                </span>
              </Typography>
            </Box>
          </Box>

          <Box style={{ color: "gray" }}>
            {ReactHtmlParser(workflow.content)}
          </Box>

          {/* Workflow's Tabs: Steps & Pathway Transactions  */}
          <Paper elevation={0} variant="outlined">
            <Tabs value={activeTab} onChange={handleChange}>
              <Tab label="Steps" value="steps" {...a11yProps(0)} />

              <Tab
                label="Workflow Updates"
                value="updates"
                disabled={!workflow?.steps?.length}
                {...a11yProps(1)}
              />
            </Tabs>

            <Divider />

            {/*Workflow associated steps  */}
            <CustomTabPanel activeTab={activeTab} value="steps">
              {/* <Box padding={2}>
                <div className="flex items-center justify-between">
                  <div>
                    <div className="text-sm font-medium text-gray-600">
                      Add Step to Workflow.
                    </div>

                    <div className="text-xs text-gray-500">
                      *Disclaimer: The step added will not reflect on the
                      workflow itself but to the associated document only*
                    </div>
                  </div>

                  {!showAddStepForm && (
                    <Button
                      variant="outlined"
                      onClick={() => setShowAddStepForm(true)}
                    >
                      Add Step
                    </Button>
                  )}
                </div>
              </Box> */}

              {/* <Divider style={{ marginTop: -1 }} /> */}

              <Collapse in={showAddStepForm}>
                <WorkflowStepForm
                  isBusy={isBusy}
                  stepDetail={selectedStep}
                  onSubmit={handleAddStepToWorkflow}
                  onCancel={handleResetStepForm}
                  className="mt-4"
                />
              </Collapse>

              <AppDraggableList
                isBusy={isBusy}
                onDragEnd={handleOnDragEnd}
                listItems={transactionWorkflowSteps}
                draggableItem={({ data: step }) => (
                  <Box
                    key={step._id}
                    marginY={2}
                    paddingX={2}
                    paddingY={1}
                    className={`flex flex-col space-y-2 rounded border-2 border-dashed ${getStepStatusIndication(
                      step
                    )}`}
                  >
                    <section className="flex items-center justify-between">
                      <div className="font-medium">{step?.title}</div>

                      <div className="flex items-center space-x-4">
                        <div
                          className={`rounded px-2 py-0.5 text-sm text-white ${getStatusChipColor(
                            step
                          )}`}
                        >
                          {getStepStatus(step)}
                        </div>

                        <Button
                          size="small"
                          variant="text"
                          onClick={() => handleAddStepTransaction(step)}
                        >
                          Add Update
                        </Button>
                      </div>
                    </section>

                    <div className="text-gray-500">
                      {ReactHtmlParser(step?.content)}
                    </div>

                    {getLatestWorkflowUpdate(step) && (
                      <div
                        title="Latest Note"
                        className="flex items-center space-x-2 py-1"
                      >
                        <DescriptionOutlined
                          fontSize="small"
                          className="-mt-1 text-gray-500"
                        />
                        <span className="text-sm">
                          {getLatestWorkflowUpdate(step)?.note}
                        </span>
                        <span className="text-sm text-gray-500">
                          -{" "}
                          {new Date(
                            getLatestWorkflowUpdate(step)?.createdAt
                          ).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </Box>
                )}
              />
            </CustomTabPanel>

            {/* Transactions  */}
            <CustomTabPanel activeTab={activeTab} value="updates">
              <ManageWorkflowStepTransactions
                assigneeSource={assigneeSource}
                workflow={workflow}
                workflowTransactions={workflowTransactions}
                step={selectedStep}
                onAddStepSuccess={handleResetStepForm}
              />
            </CustomTabPanel>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default WorkflowStepUpdateTracker;
