import React from "react"
import { Container, Typography, Card, CardContent, Avatar } from "@mui/material"
import Slider from "react-slick"
import Chriscyry from "../screens/assets/images/testimonials/Chriscyry.png"
import Gianne from "../screens/assets/images/testimonials/Gianne.png"
import Jenny from "../screens/assets/images/testimonials/Jenny.png"

import Thristan from "../screens/assets/images/testimonials/Thristan.png"
import Kristine from "../screens/assets/images/testimonials/Kristine.png"
import Patricia from "../screens/assets/images/testimonials/Patricia.png"
import Omar from "../screens/assets/images/testimonials/Omar.png"
import Jessiebel from "../screens/assets/images/testimonials/Jessiebel.png"
import Marilou from "../screens/assets/images/testimonials/Marilou.png"

// import Jean from "../screens/assets/images/testimonials/Jean.png"
import Joaquin from "../screens/assets/images/testimonials/Joaquin.png"
// import Rony from "../screens/assets/images/testimonials/Rony.png"
// import Jossiemar from "../screens/assets/images/testimonials/Jossiemar.png"

import Joshwell from "../screens/assets/images/testimonials/Joshwell.png"
// import King from "../screens/assets/images/testimonials/King.png"

const testimonials = [
  {
    name: "Thristan Selma",
    content:
      "“For those who wants to go to Australia and apply for a student visa, go with FSW Education for a hassle-free process experience.”",
    image: Thristan,
  },
  {
    name: "Gianne Acleta",
    content:
      "“I inquired last April 28, 2023, then got my visa approved last June 20, 2023. So, the process took about 1 month and a half, it was super-fast. With FSW, the process was super smooth and fast. They really know what they are doing, especially with the GTE, and the course and school recommendation.”",
    image: Gianne,
  },
  {
    name: "Kristine Marie Arellano",
    content:
      "“Even though my application was complicated they (FSW Education) never gave up on my application. To those aspiring students out there that wants to study in Australia, don't lose hope. Just like with my complicated situation, FSW managed to help me, and I thank God for that.”",
    image: Kristine,
  },
  {
    name: "Patricia Francine Pineda",
    content:
      "“I tried asking inquiring to different agencies, but I am just not satisfied with their answers unlike with FSW Education. Preparing for the GTE made me super nervous because it was the longest part of the application process. I was happy with the team because they did well with the GTE and made sure the right information was there, they also made me double check it. It was the hardest part of the process that the team did.”",
    image: Patricia,
  },
  {
    name: "Omar Toquero",
    content:
      "“When it comes to the processing of my documents for the student visa application, it was very smooth, and the agents were very approachable. My advice for those students who wants to apply for a student visa in Australia is to try FSW Education, because you won’t regret it, they will help you in every step of the way.”",
    image: Omar,
  },
  {
    name: "Jenny Gomez",
    content:
      "I highly recommend, if you really want to go to Australia go with FSW Education. Knowing how busy my schedule is since I’m a working mom, with FSW you don’t have to worry about anything, even the Biometrics and Medical bookings. I’m lucky to have found FSW Education.",
    image: Jenny,
  },
  {
    name: " Jessiebel Biogan",
    content:
      "“My agent was super approachable, and responsive. I would like to say thank you to FSW Education, because you have taken good care of us since the beginning, the processes were all so fast.”",
    image: Jessiebel,
  },
  {
    name: "Marilou Ignacio",
    content:
      "“I would like to say thank you to FSW Education for helping me go to Australia, they were very helpful, hands-on, and a good Agency. If you’re going to choose them as your Agency, you won’t regret anything, because with FSW Education you are in good hands.”",
    image: Marilou,
  },
  // {
  //   name: "Jean Ira Garcia",
  //   content:
  //     "Any time, any day the agent would reply. If I have questions, she responds immediately or coordinates with the team, especially with my case since every day we encounter problems with my application. The agent gave me a lot of options, and she also listed down the information that I needed. She’s a really good agent.",
  //   image: Jean,
  // },
  {
    name: "Joaquin Arcangel",
    content:
      "“I really feel that it was Divine Providence that led me to find FSW, among several sites and sources that I searched. The agent’s helpful advice made it very easy for me to prepare the documentary and logistical requirements, my student visa was approved a lot sooner than expected. FSW gave me an inspiring head start. My family and I will forever be grateful to FSW.”",
    image: Joaquin,
  },
  {
    name: "Chriscyry Duquilla",
    content:
      "“I tried applying to different agencies before, but they are slow to respond. With FSW Education it was so smooth, the response was very fast. My agent was super kind, and she would always follow up on the documents that I lack.”",
    image: Chriscyry,
  },
  // {
  //   name: "Rony Atienza",
  //   content:
  //     "“I lodge my Visa on February 2023 thanks to the help of FSW (First Source World) My visa got granted in just 14 days. I'm very lucky to have them (FSW Education) as my consultant and I thank them for their understanding and for being patient with me and my questions. They are always there for me and ready to help. I had the best experience with FSW Education.”",
  //   image: Rony,
  // },
  // {
  //   name: "Jossiemar & Danielle",
  //   content:
  //     "“We would like to thank FSW Education for assisting us to go to Sydney. And for all aspiring Filipinos who wants to study, and travel in Sydney, don’t hesitate to contact FSW Education.”",
  //   image: Jossiemar,
  // },
  {
    name: "Joshwell Cahuyong",
    content:
      "“I wouldn’t be here (Australia) if it weren’t for FSW Education’s help. They were so efficient, I get updated almost every day, and I don’t have to worry about anything because they supply me with the knowledge that I need.”",
    image: Joshwell,
  },
  // {
  //   name: "King Joshua Dangionan",
  //   content:
  //     "“FSW has helped me pursue my dream of studying in Australia. I thank them for helping me every step of the way.”",
  //   image: King,
  // },
]

const TestimonialsCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    speed: 0,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <Container maxWidth='xl' mt={3}>
      <div className='carousel-wrapper'>
        <Slider {...settings}>
          {testimonials.map((item) => (
            <div key={item} style={{ position: "relative" }}>
              <Card align='center' className='TesimonialsCard'>
                <Typography mt={2}>
                  <Avatar
                    alt='Remy Sharp'
                    src={item.image}
                    sx={{ width: 56, height: 56 }}
                    align='center'
                  />
                </Typography>

                <CardContent>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    className='testimonials-text'
                  >
                    {item.content}
                  </Typography>

                  <Typography
                    gutterBottom
                    component='div'
                    style={{ fontWeight: "600" }}
                  >
                    - {item.name} -
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  )
}

export default TestimonialsCard
