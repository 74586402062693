import React from "react";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const ExtensionIcon = ({ fileUrl }) => {
  const fileExtension = React.useMemo(() => {
    return fileUrl?.split(".")?.pop();
  }, [fileUrl]);

  const fileColorClass = React.useMemo(() => {
    switch (fileExtension.toUpperCase()) {
      case "PDF":
        return "text-red-400";

      case "JPG":
      case "JPEG":
      case "PNG":
        return "text-yellow-400";

      default:
        return "text-slate-400";
    }
  }, [fileExtension]);

  return (
    <div
      className="relative -mx-4 my-2 inline-block overflow-hidden"
      title="View File"
    >
      <InsertDriveFileOutlinedIcon
        className={` ${fileColorClass}`}
        style={{ fontSize: 60 }}
      />

      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform text-[8px] font-bold uppercase tracking-wider text-slate-600">
        {fileExtension}
      </div>
    </div>
  );
};

export default ExtensionIcon;
