import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import getMuiIcon from "../../utils/getMuiIcon";

const NestedMenu = ({ menuList = [] }) => {
  const [open, setOpen] = React.useState({});

  const navigate = useNavigate();

  const location = useLocation();

  // methods
  const handleClick = (menu) => {
    menu?.subMenus?.length > 0
      ? setOpen({ [menu.title]: !open[menu.title] })
      : navigate(menu?.path);
  };

  const locationSubpath = location.pathname
    .split("/")
    .filter((el) => el.length)
    .splice(0, 2)
    .join("/");

  const isActiveRoute = (path) => {
    // return path && path.includes(locationSubpath);
    return path === location.pathname;
  };

  const activeTextStyles = (path) => {
    return {
      color: !!isActiveRoute(path) && `var(--primary)`,
    };
  };

  React.useEffect(() => {
    if (!menuList.length) return;

    // expand nested menu on matched path
    const activeMenu = menuList
      ?.filter((m) => m?.subMenus?.length)
      ?.find((menu) => {
        return !!menu.subMenus.find((submenu) =>
          submenu.path.includes(locationSubpath)
        )
          ? menu
          : null;
      });

    activeMenu
      ? setOpen({ [activeMenu.title]: !open[activeMenu.title] })
      : setOpen({ Services: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List className="no-scrollbar overflow-auto" disablePadding>
      {menuList.map((menu, index) => (
        <React.Fragment key={index}>
          <div className="relative">
            {!!isActiveRoute(menu.path) && (
              <div className="absolute left-0 h-full w-1 bg-blue-500" />
            )}
            <ListItemButton
              key={`${menu.title}-${index}`}
              selected={!!isActiveRoute(menu.path)}
              onClick={() => handleClick(menu)}
            >
              <ListItemIcon>
                {getMuiIcon(menu.icon, {
                  color: !!isActiveRoute(menu.path) ? "primary" : "",
                  fontSize: "small",
                })}
              </ListItemIcon>

              <ListItemText
                primary={menu.title}
                sx={{
                  ...activeTextStyles(menu.path),
                }}
              />
              {menu?.subMenus?.length
                ? open[menu.title]
                  ? getMuiIcon("KeyboardArrowUp")
                  : getMuiIcon("KeyboardArrowDown")
                : null}
            </ListItemButton>
          </div>

          {menu?.subMenus?.length && (
            <Collapse in={open[menu.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menu.subMenus.map((submenu, i) => (
                  <div key={i} className="relative">
                    {!!isActiveRoute(submenu.path) && (
                      <div className="absolute left-0 h-full w-1 bg-blue-500" />
                    )}
                    <ListItemButton
                      key={`${submenu.title}-${index}-${i}`}
                      selected={!!isActiveRoute(submenu.path)}
                      sx={{ pl: 4 }}
                      onClick={() => navigate(submenu.path)}
                    >
                      <ListItemIcon>
                        {getMuiIcon(submenu.icon, {
                          color: !!isActiveRoute(submenu.path) ? "primary" : "",
                          fontSize: "small",
                        })}
                      </ListItemIcon>

                      <ListItemText
                        primary={submenu.title}
                        style={activeTextStyles(submenu.path)}
                      />
                    </ListItemButton>
                  </div>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default NestedMenu;
