import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useKnowledgebaseContext } from "../../../context/KBContext";
import { formatDate } from "../../../utils/handleFormatDate";

export const KBDocumentScreen = () => {
  // hooks
  const navigate = useNavigate();
  const { state: articleState } = useKnowledgebaseContext();

  const { detailedArticleInformation } = articleState;

  if (!detailedArticleInformation) return <></>;

  return (
    <Box
      sx={{ backgroundColor: "#fff" }}
      paddingX={2}
      paddingY={2}
      minHeight="calc(100vh - 200px)"
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              style={{ width: 36, height: 36, marginRight: "1rem" }}
              src={`https://ui-avatars.com/api/?rounded=true&name=${detailedArticleInformation?.author?.name}&background=3F51B5&color=fff`}
            />

            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 16,
                  marginRight: "6px",
                }}
              >
                {detailedArticleInformation?.author?.name}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#435883",
                }}
              >
                {formatDate(detailedArticleInformation?.publishedDate)}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(
                  `/app/knowledgebase/detail/${detailedArticleInformation._id}/document-version`
                )
              }
              startIcon={<i className="fa-solid fa-clock-rotate-left"></i>}
            >
              View Document Versions
            </Button>
          </Box>
        </Box>

        {/* <Box
          display="flex"
          alignItems="center"
          marginBottom={1}
          sx={{ gap: "8px" }}
        >
          {detailedArticleInformation?.tags?.map((tag) => (
            <Box
              key={`tag-${tag}`}
              paddingX={1}
              borderRadius="5px"
              border="1px solid #435883"
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#435883",
                  cursor: "pointer",
                }}
              >
                {tag}
              </Typography>
            </Box>
          ))}
        </Box> */}
      </Box>

      <Box marginTop={4}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: "#435883",
          }}
        >
          {detailedArticleInformation?.title}
        </Typography>

        <Typography
          variant="subtitle1"
          style={{
            fontWeight: "400",
            fontSize: 16,
            color: "#435883",
            marginTop: "2rem",
          }}
        >
          {detailedArticleInformation?.subtitle}
        </Typography>
      </Box>

      <Box
        marginTop={6}
        sx={{ color: "#435883" }}
        dangerouslySetInnerHTML={{
          __html: detailedArticleInformation?.description || <p></p>,
        }}
      ></Box>
    </Box>
  );
};
