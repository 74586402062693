import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import TransactionForm from "./transactionComponents/TransactionForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetTransactionDetailQuery,
  useUpdateTransactionMutation,
} from "../../../rtkStore/services/transactionApi";

const TransactionEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Transactions", path: "/app/transaction/list" },
    { title: "Edit Transaction" },
  ];

  // RTK Query
  const {
    data: transactionDetail,
    isLoading: loadingTransactionDetail,
    error,
  } = useGetTransactionDetailQuery(id);

  const [updateTransaction, { isLoading: updatingTransaction }] =
    useUpdateTransactionMutation();

  // methods
  const handleUpdateTransaction = async (data) => {
    try {
      await updateTransaction(data).unwrap();
      navigate(`/app/transaction/${id}/detail`);
      toast.success("Transaction successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update transaction!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={updatingTransaction || loadingTransactionDetail}
      error={error}
    >
      <TransactionForm
        transactionDetail={transactionDetail}
        isBusy={updatingTransaction || loadingTransactionDetail}
        onSubmit={handleUpdateTransaction}
      />
    </WithHeader>
  );
};

export default TransactionEditScreen;
