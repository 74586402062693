import React from "react";
import { useSelector } from "react-redux";
import { selectIsValidEntity } from "../../rtkStore/authSlice";

const SidebarContext = React.createContext(null);

export const useSidebarContext = () => React.useContext(SidebarContext);

const drawerWidth = 250;

const SidebarContextProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [party, setParty] = React.useState(false);

  const validEntity = useSelector(selectIsValidEntity);

  React.useEffect(() => {
    if (validEntity) setShowSidebar(true);
  }, [setShowSidebar, validEntity]);

  return (
    <SidebarContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        party,
        setParty,
        drawerWidth,
        validEntity,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
