import React from "react";

import { Box, Grid, Skeleton } from "@mui/material";

const InfoGridSkeleton = ({ items = 6 }) => {
  return (
    <Box padding={3}>
      <Grid container spacing={4}>
        {new Array(items).fill(null).map((_, index) => (
          <Grid key={index} item md={3}>
            <Skeleton variant="rect" height={60} width={"90%"} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InfoGridSkeleton;
