import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Badge, Box, Button, Divider, Typography } from "@mui/material";
import {
  Add,
  PlaylistAddCheckOutlined,
  FiberManualRecord,
  InfoOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import RowsSkeleton from "../../../../../components/Shared/Skeleton/RowsSkeleton";

import { useGetTaskByAssigneeQuery } from "../../../../../rtkStore/services/taskApi";

const TaskTab = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  // RTK Query
  const { data: taskList = [], isLoading: loadingTasks } =
    useGetTaskByAssigneeQuery(id);

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge badgeContent={taskList?.length} color="primary" showZero>
              <PlaylistAddCheckOutlined style={{ color: "gray" }} />
            </Badge>

            <Typography style={{ fontWeight: 500 }}>Tasks</Typography>
          </Box>

          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => navigate(`/app/task/create?selectedClient=${id}`)}
          >
            Task
          </Button>
        </Box>
      </Box>

      <Divider />

      {!loadingTasks ? (
        <Box paddingX={3}>
          {taskList?.length ? (
            taskList.map((task, index) => (
              <React.Fragment key={index}>
                <Box paddingY={3} display="flex" gap={2}>
                  <PlaylistAddCheckOutlined
                    color="primary"
                    fontSize="small"
                    style={{ marginTop: 2 }}
                  />

                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box
                      display="flex"
                      flexGrow={1}
                      flexDirection="column"
                      gap={1}
                    >
                      <Typography>{task.title}</Typography>

                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography>{task.priority}</Typography>

                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 10 }}
                        />

                        <Typography>{task.status}</Typography>

                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 10 }}
                        />

                        <Typography>
                          {moment(task.dueDate).format("DD MMM, YYYY")}
                        </Typography>
                      </Box>

                      <Box style={{ color: "gray" }}>
                        {ReactHtmlParser(task?.description)}
                      </Box>
                    </Box>

                    <Link to={`/app/task/${task._id}/detail`}>
                      <InfoOutlined color="primary" />
                    </Link>
                  </Box>
                </Box>
                {index !== taskList.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <Box paddingY={3}>No Tasks</Box>
          )}
        </Box>
      ) : (
        <RowsSkeleton />
      )}
    </>
  );
};

export default TaskTab;
