import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../../../utils/handleFormatDate";

export const KnowledgebaseModalBlog = ({ article, showAuthorInfo = true }) => {
  return (
    <Box
      key={`article-${article._id}`}
      sx={{
        maxWidth: "100%",
        marginBottom: "12px",
        cursor: "pointer",
      }}
    >
      {showAuthorInfo && (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{ width: 36, height: 36, marginRight: "1rem" }}
            // src="https://images6.alphacoders.com/771/thumb-1920-771840.jpg"
            src={`https://ui-avatars.com/api/?rounded=true&name=${article?.author?.name}&background=3F51B5&color=fff`}
          />
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 16,
              marginRight: "6px",
            }}
          >
            {article?.author?.name}
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "#435883",
            }}
          >
            updated the article on &nbsp;
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
              color: "#435883",
            }}
          >
            {formatDate(article?.publishedDate)}
          </Typography>
        </Box>
      )}

      <Box marginTop={2}>
        <Box>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: "#435883",
            }}
          >
            {article?.title}
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            marginTop={2}
            marginBottom={2}
            sx={{ gap: "8px" }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 16 }}>
              {article.category?.name}
            </Typography>

            <Typography
              variant="h6"
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: "gray",
              }}
            >
              &bull;
            </Typography>

            <Typography
              variant="subtitle1"
              style={{
                fontSize: 14,
                color:
                  article?.status === "Active"
                    ? // ? "#3F51B5"
                    "#ff5555"
                    : article?.status === "Inactive"
                      ? "red"
                      : "#E0E0E0",
                fontWeight: 400,
              }}
            >
              Active
            </Typography>

            <Typography
              variant="h6"
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: "gray",
              }}
            >
              &bull;
            </Typography>

            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: "#3F51B5",
              }}
            >
              V{article.version}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ gap: "2rem" }}
          >
            <Box
              marginBottom={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "8px", color: "#435883" }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                Author
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {article?.author?.name || "_"}
              </Typography>
            </Box>

            <Box
              marginBottom={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "8px", color: "#435883" }}
            >
              {/* <i className="fa-solid fa-user-graduate"></i> */}

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                Responsible User
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {article?.responsibleInfo?.name || "_"}
              </Typography>
            </Box>

            <Box
              marginBottom={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "8px", color: "#435883" }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                Approved by
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  color: "#e33333",
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {article?.approver?.name || "<Awaiting Approval>"}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            marginBottom={1}
            sx={{ gap: "8px" }}
          >
            {article?.tags?.map((tag) => (
              <Box
                key={`tag-${tag?._id}`}
                paddingX={1}
                borderRadius="5px"
                border="1px solid #435883"
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "#435883",
                    cursor: "pointer",
                  }}
                >
                  {tag?.name}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            padding={2}
            borderRadius="5px"
            border="1px solid #dedede70"
            sx={{
              backgroundColor: "#f1f1f170",
              marginTop: "3rem",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: 16,
                color: "#435883",
              }}
            >
              {article?.subtitle}
            </Typography>
          </Box>
        </Box>

        <Box
          marginTop={4}
          sx={{ color: "#435883" }}
          dangerouslySetInnerHTML={{
            __html: article?.description || <p></p>,
          }}
        ></Box>
      </Box>
    </Box>
  );
};
