import { Route, Routes } from 'react-router-dom'
import AOS from 'aos'

import 'aos/dist/aos.css'

// middlewares
import PrivateRoute from './routes/middlewares/PrivateRoute'
// routers
import AppRouter from './routers/AppRouter'
import HomeRouter from './routers/HomeRouter'
// screens
import PageScreen404 from './screens/ErrorPages/404PageScreen'
import SignInScreen from './screens/SignInScreen'
import RegisterScreen from './screens/RegisterScreen'
import StudyOptionScreen from './screens/Admin/StudyOptionScreen'
import GuestRouter from './routers/GuestRouter'
import ClientStudyOptionsScreen from './screens/Admin/Client/ClientStudyOptionsScreen'
import LeadStudyOptionsScreen from './screens/Admin/Lead/LeadStudyOptionsScreen'

function App() {
  AOS.init()

  return (
    <Routes>
      <Route path='/*' element={<HomeRouter />} />
      <Route path='/app/*' element={<AppRouter />} />
      {/* <Route path="/sign-in" element={<SignInScreen />} />
      <Route path="/register" element={<RegisterScreen />} /> */}
      <Route
        path='/study-option/:assignee'
        element={<PrivateRoute component={StudyOptionScreen} />}
      />
      <Route path='/study-options'>
        <Route
          path=':id/clients/:clientId'
          element={<PrivateRoute component={ClientStudyOptionsScreen} />}
        />
        <Route
          path=':id/leads/:leadId'
          element={<PrivateRoute component={LeadStudyOptionsScreen} />}
        />
      </Route>
      <Route path='/v2/*' element={<GuestRouter />} />
      <Route path='*' element={<PageScreen404 />} />
    </Routes>
  )
}
export default App
