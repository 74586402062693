import React, { useState } from "react"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import EmailField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import contactUsImage from "../assets/images/contactUsBanner.png"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk"
import ListItemText from "@mui/material/ListItemText"
import MenuList from "@mui/material/MenuList"
import ListItemIcon from "@mui/material/ListItemIcon"
import OurLocation from "../../components/OurLocation"
import Alert from "@mui/material/Alert"
import axios from "axios"
import Banner from "../../components/Banner"

const FSW_STATIC_LOCATIONS = [
  {
    country: "Australia",
    locations: [
      {
        name: "Sydney Head Office",
        address: "27/2 O'Connell St, Parramatta NSW 2150",
        phone: "+61 (0)2 9639 4415",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106129.99447658859!2d150.8632624!3d-33.7719741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3fe17c14ed5%3A0xbb77abc560bb8c46!2sFSW%20Global%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1712275455407!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      // {
      //   name: " Rooty Hill NSW",
      //   address: "39 Nicolaidis Cr, Rooty Hill NSW",
      //   phone: "+61 426 992 880",
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.360097713145!2d150.83519291580166!3d-33.777198021743025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129a6b34405cf1%3A0xef71fe5d4620290f!2s39%20Nicolaidis%20Cres%2C%20Rooty%20Hill%20NSW%202766%2C%20Australia!5e0!3m2!1sen!2snp!4v1677050044966!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: 'Belrose NSW',
      //   address: '4 Oates Place, Belrose NSW',
      //   phone: '+61 432 657 233',
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.391997771298!2d151.21745851580044!3d-33.72467831902582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d561cf1d194a9%3A0x3df82deb22678126!2s4%20Oates%20Pl%2C%20Belrose%20NSW%202085%2C%20Australia!5e0!3m2!1sen!2snp!4v1677050574947!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1657.4087492997246!2d150.9980756129162!3d-33.81702246224117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a2df98447663%3A0xc8bdf35e67b92de5!2s2%20O&#39;Connell%20St%2C%20Parramatta%20NSW%202150%2C%20Australia!5e0!3m2!1sen!2snp!4v1677044906922!5m2!1sen!2snp",
  },
  {
    country: "Philippines",
    locations: [
      {
        name: "GMA, Cavite",
        address: "San Lorenzo St., San Gabriel, GMA, Cavite 4117",
        phone: "+63 936 990 4560",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30928.190259097846!2d121.00017590507483!3d14.310050993329957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d65be8392e2b%3A0xdadd291f12b09cb4!2sGeneral%20Mariano%20Alvarez%2C%20Cavite%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063785437!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Baguio City",
        address: "Pinsao Proper, Baguio City 2600",
        phone: "+63 961 831 1990",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30619.983930964143!2d120.57126560593441!3d16.399515745065035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3391a16879def13f%3A0x8edef534be3a75c0!2sBaguio%2C%20Benguet%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063872948!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Balanga City, Bataan",
        address: "676 Maluya St., Central, Balanga City, Bataan 2100",
        phone: "+63 998 443 1681",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61757.849049416836!2d120.46175074084!3d14.663569615470808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33964009381ef663%3A0xe135d5b451871d25!2sCity%20of%20Balanga%2C%20Bataan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063912904!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Mariveles, Batan",
        address: "268 Tibag St., Balon Anito Mariveles, Bataan 2105",
        phone: "+63 968 579 3107",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123631.04710331548!2d120.42994954672989!3d14.4576673309243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x339618ec08e4d1f1%3A0x287c14acd0973948!2sMariveles%2C%20Bataan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063952810!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Legazpi City, Albay",
        address: "0327 Purok 3, Cruzada, Legazpi City, Albay 4500",
        phone: "+63 947 373 0208",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124340.9396537795!2d123.70313423888933!3d13.121068970550278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a101687e9bf8a7%3A0x305252e78d14537a!2sLegazpi%20City%2C%20Albay%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063984529!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Guinobatan, Albay",
        address: "Inamnan Pequeño, Guinobatan, Albay 4503",
        phone: "+63 995 254 6869",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124313.14259590059!2d123.51104513918696!3d13.175907246441557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a108974cf38a9f%3A0x43adeba52c590d5!2sGuinobatan%2C%20Albay%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064026795!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Tandag City, Surigao Del Sur",
        address:
          "Globle Meadows Subd., Rosario, Tandag City, Surigao Del Sur 8300",
        phone: "+63 946 101 0834",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126069.77589318984!2d126.06206202267772!3d9.092842229203159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3302373d1fdee1bf%3A0x9f84ea6460e30dd3!2sTandag%2C%20Surigao%20del%20Sur%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064059900!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Gozanga, Cagayan",
        address: "Purok Saranay, Cabiraoan, Gonzaga, Cagayan 3513",
        phone: "+63 961 831 1990",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121235.63704910535!2d122.03482177579761!3d18.273524013466233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x338672cb198139f5%3A0x3fe9c9c3b9026523!2sGonzaga%2C%20Cagayan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064092490!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      {
        name: "Valenzuela City, Metro Manila",
        address: "La Huerta Subd., Marulas, Valenzuela City, NCR 1440",
        phone: "+63 927 829 1425",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61744.43805215936!2d120.94011214091427!3d14.711043839711492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b3f2c352666b%3A0x9af8d2dfa797d58!2sValenzuela%2C%20Metro%20Manila%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064124608!5m2!1sen!2snp'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
  },
  // {
  //   country: "Nepal",
  //   locations: [
  //     {
  //       name: "Kathmandu",
  //       address: "51, Muni Bhairab Marg Tinkune, Kathmandu 44600",
  //       phone: "+977 1-5199596",
  //       embeddedMap: () => (
  //         <iframe
  //           src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.0717877982233!2d85.34638481547772!3d27.684176033115754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fea8d04eaf%3A0xd5ff8fbe8d604727!2sFirst%20Source%20World%20-%20Educational%20Consultancy!5e0!3m2!1sen!2snp!4v1677064182692!5m2!1sen!2snp'
  //           width='100%'
  //           height='100%'
  //           allowFullScreen=''
  //           loading='lazy'
  //           referrerPolicy='no-referrer-when-downgrade'
  //         />
  //       ),
  //     },
  //   ],
  //   contactEmail: "fsw.contact@firstsourceworld.com",
  //   mapLink:
  //     "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  // },
  {
    country: "Indonesia",
    locations: [
      {
        name: "Surabaya",
        address: "Surabaya, Indonesia",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150607.44675506576!2d112.60174173342074!3d-7.278968845288966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf93cf2de6b%3A0x3b952696d53fda55!2sSurabaya%2C%20East%20Java%2C%20Indonesia!5e0!3m2!1sen!2sau!4v1689490313186!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Vietnam ",
    locations: [
      {
        name: "Da Nang",
        address: "Da Nang, Vietnam",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d245367.875568645!2d107.91331695908276!3d16.072075926199183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219c792252a13%3A0x1df0cb4b86727e06!2sDa%20Nang%2C%20Vietnam!5e0!3m2!1sen!2sau!4v1689490673179!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Malaysia ",
    locations: [
      {
        name: "Kula Lampur",
        address: "Kula Lampur, Malaysia",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127482.667337236!2d101.6045883294755!3d3.1386740722767126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc362abd08e7d3%3A0x232e1ff540d86c99!2sKuala%20Lumpur%2C%20Federal%20Territory%20of%20Kuala%20Lumpur%2C%20Malaysia!5e0!3m2!1sen!2sau!4v1689491125563!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Cambodia",
    locations: [
      {
        name: "Phnom Penh",
        address: "Phnom Penh, Cambodia",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127482.667337236!2d101.6045883294755!3d3.1386740722767126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc362abd08e7d3%3A0x232e1ff540d86c99!2sKuala%20Lumpur%2C%20Federal%20Territory%20of%20Kuala%20Lumpur%2C%20Malaysia!5e0!3m2!1sen!2sau!4v1689491125563!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Laos",
    locations: [
      {
        name: "Vientiane",
        address: "Vientiane, Laos",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121452.47861155038!2d102.52336454822229!3d17.96058552219468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3124688606ed7b21%3A0x1f93b18618c1eedf!2sVientiane%2C%20Laos!5e0!3m2!1sen!2sau!4v1689491267644!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Qatar",
    locations: [
      {
        name: "Doha",
        address: "Doha, Qatar",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115443.1014605415!2d51.42959574926199!3d25.28414143986878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x44d9319f78cfd4b1!2sDoha%2C%20Qatar!5e0!3m2!1sen!2sau!4v1689491327501!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "UAE",
    locations: [
      {
        name: "Abu Dhabi",
        address: "Abu Dhabi, UAE",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d232565.41897018426!2d54.39381162558035!3d24.387054129048508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e440f723ef2b9%3A0xc7cc2e9341971108!2sAbu%20Dhabi%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491355208!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },

      {
        name: "Dubai",
        address: "Dubai, UAE",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81714.1828726183!2d55.19438959190715!3d25.15965690516927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491388761!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },

      {
        name: "Sarjah",
        address: "Sarjah, UAE",
        phone: "+61 450 468 622",
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115409.13273028829!2d55.46303810014148!3d25.319809170782897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5f5fede7964b%3A0x2a830aa19c1f6d89!2sSharjah%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491415841!5m2!1sen!2sau%22'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],

    contactEmail: "fsw.contact@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },
]

const initialState = {
  fname: "",
  lname: "",
  message: "",
  email: "",
  services: "",
}

export default function ContactScreen() {
  // const [fname, setName] = useState("")
  // const [lname, setLastName] = useState("")
  // const [message, setMessage] = useState("")
  // const [email, setEmail] = useState("")
  // const [services, setServices] = useState("")

  ///end  email fns
  const [data, setData] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const [state, dispatch] = React.useReducer((next, prev) => {
    return { ...next, ...prev }
  }, initialState)

  const contactSubmit = async (event) => {
    event.preventDefault()
    setShowMessage(true)
    return axios
      .post(`/api/send-mail/contact-us`, {
        ...state,
      })
      .then((res) => {
        setData(res.data)
        dispatch(initialState)
      })
  }

  return (
    <>
      <Banner image={contactUsImage} title={"Contact Us"} />

      <Container
        sx={{
          marginBottom: 5,
        }}
      >
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          {data?.message === "success" && (
            <Alert severity='success'>Message send successfully.</Alert>
          )}
        </Box>
        <CssBaseline />
        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                boxShadow: "2px 4px 29px rgba(0, 0, 0, 0.25)",
                p: 2,
                marginBottom: 3,
                borderRadius: "10px",
              }}
            >
              <Typography component='p' variant='p'>
                Contact Us
              </Typography>
              <Typography component='h1' variant='h5'>
                Get in touch with FSW
              </Typography>
              <Box sx={{ mt: 3 }}>
                <form component='form' onSubmit={contactSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete='given-name'
                        name='fname'
                        required
                        fullWidth
                        id='fname'
                        label='First Name'
                        value={state.fname}
                        onChange={(e) => dispatch({ fname: e.target.value })}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id='lname'
                        label='Last Name'
                        name='lname'
                        value={state.lname}
                        onChange={(e) => dispatch({ lname: e.target.value })}
                        autoComplete='family-name'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <EmailField
                        required
                        fullWidth
                        type='email'
                        id='email'
                        label='Email Address'
                        name='email'
                        value={state.email}
                        onChange={(e) => dispatch({ email: e.target.value })}
                        autoComplete='email'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Services
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          name='service'
                          value={state.services}
                          label='Services'
                          onChange={(e) =>
                            dispatch({ services: e.target.value })
                          }
                          // onChange={(e) => setServices(e.target.value)}
                        >
                          <MenuItem key='Study Abroad Consultation' value='Study Abroad Consultation'>
                            Study Abroad Consultation
                          </MenuItem>
                          <MenuItem value='College Partnership'>
                            College Partnership
                          </MenuItem>
                          <MenuItem
                            key='Visa Processing'
                            value='Visa Processing'
                          >
                            Visa Processing
                          </MenuItem>
                          <MenuItem key='Others' value='Others'>
                            Others
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name='message'
                        label='Message'
                        type='message'
                        id='message'
                        value={state.message}
                        onChange={(e) => dispatch({ message: e.target.value })}
                        autoComplete='message'
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type='submit'
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    className='ContactSubmitBtn'
                  >
                    Send message
                  </Button>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            mt={2}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 2,
                marginLeft: 2,
                marginBottom: 3,
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d931.24846676216!2d85.34707741583493!3d27.685173034062295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fea8d04eaf%3A0xd5ff8fbe8d604727!2sFirst%20Source%20World%20-%20Educational%20Consultancy!5e0!3m2!1sen!2snp!4v1644996470606!5m2!1sen!2snp"
                frameBorder="0"
                title="googlemap"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                height="400px"
              />

              <MenuList>
                <MenuItem>
                  <ListItemIcon>
                    <LocationOnIcon className="redColor" />
                  </ListItemIcon>
                  <ListItemText>Munibhairab Marg, Tinkune KMC-32</ListItemText>
                </MenuItem>

                <MenuItem>
                  <ListItemIcon>
                    <MailOutlineIcon className="redColor" />
                  </ListItemIcon>
                  <ListItemText>
                    <a href="mailto:contact@fristsourceworld.com">
                      contact@firstsourceworld.com
                    </a>
                  </ListItemText>
                </MenuItem>

                <MenuItem>
                  <ListItemIcon>
                    <PhoneInTalkIcon className="redColor" />
                  </ListItemIcon>
                  <ListItemText>+977 1-5199596</ListItemText>
                </MenuItem>
              </MenuList>
            </Box> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 2,
                marginLeft: 2,
                marginBottom: 3,
              }}
            >
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106129.99447658859!2d150.8632624!3d-33.7719741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3fe17c14ed5%3A0xbb77abc560bb8c46!2sFSW%20Global%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1712275507271!5m2!1sen!2sau'
                width='600'
                height='450'
                style={{ border: 0 }}
                allowFullScreen=''
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              ></iframe>
            </Box>

            <AddressCard {...FSW_STATIC_LOCATIONS[0]} />
          </Grid>
        </Grid>
      </Container>

      {/* <Container style={{ marginBottom: "50px", marginTop: "20px" }}>
        <Typography align='center'>
          <h1> Our Locations </h1>
        </Typography>
        <OurLocation addresses={FSW_STATIC_LOCATIONS} />
      </Container> */}
    </>
  )
}

const AddressCard = ({ locations, contactEmail }) => {
  return (
    <>
      <MenuList>
        <MenuItem>
          <ListItemIcon>
            <LocationOnIcon className='redColor' />
          </ListItemIcon>
          <ListItemText>{locations[0]?.address}</ListItemText>
        </MenuItem>

        <MenuItem>
          <ListItemIcon>
            <MailOutlineIcon className='redColor' />
          </ListItemIcon>
          <ListItemText>
            <a href={`mailTo:${contactEmail}`}>{contactEmail}</a>
          </ListItemText>
        </MenuItem>

        <MenuItem>
          <ListItemIcon>
            <PhoneInTalkIcon className='redColor' />
          </ListItemIcon>
          <ListItemText>{locations[0]?.phone}</ListItemText>
        </MenuItem>
      </MenuList>
    </>
  )
}
