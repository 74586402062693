import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  FiberManualRecord,
  LibraryAddCheckOutlined,
  EditOutlined,
} from "@mui/icons-material";

import { useUpdateLeadQualificationCriteriaMutation } from "../../../../rtkStore/services/leadApi";

import { useLeadDetail } from "../LeadDetailScreen";

const LeadQualificationCriteria = () => {
  const {
    leadDetail: { criteria },
  } = useLeadDetail();

  const qualifiedCriteria =
    criteria?.filter((q) => q?.status === "Met")?.length ?? 0;

  const notApplicableCriteria =
    criteria?.filter((q) => q?.status === "Not Applicable")?.length ?? 0;

  const allCriteriaQualified =
    criteria?.length > 0 && qualifiedCriteria === criteria?.length;

  const sortedCriteria = [...criteria]?.sort((a, b) => {
    const serialNumberA = a?.question?.serialNumber;
    const serialNumberB = b?.question?.serialNumber;

    return serialNumberA.localeCompare(serialNumberB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });

  return (
    <>
      <Box padding={3} display="flex" alignItems="center" gap={1.5}>
        <LibraryAddCheckOutlined
          className={allCriteriaQualified ? "text-green-600" : "text-gray-500"}
        />

        <Typography style={{ fontWeight: 500 }}>
          {!allCriteriaQualified ? (
            <div className="flex items-center space-x-4">
              <div>
                <span className="mr-1">
                  {`${qualifiedCriteria} / ${criteria.length} `}{" "}
                </span>
                <span className="text-gray-500">Qualified Criteria</span>
              </div>

              {notApplicableCriteria > 0 && (
                <>
                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <div>
                    <span className="mr-1">{notApplicableCriteria} </span>
                    <span className="text-gray-500"> Not Applicable </span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <span className="text-green-600">All Criteria Qualified!</span>
          )}
        </Typography>
      </Box>

      <Divider style={{ marginTop: -1 }} />

      <Box paddingX={3}>
        {sortedCriteria.length ? (
          sortedCriteria.map((leadQuestion, index) => (
            <React.Fragment key={index}>
              <LeadQuestionItem leadQuestion={leadQuestion} />
              {index !== sortedCriteria.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Lead Questions</Box>
        )}
      </Box>
    </>
  );
};

export default LeadQualificationCriteria;

const LeadQuestionItem = ({ leadQuestion }) => {
  const [editMode, setEditMode] = React.useState(!leadQuestion?.status);

  const { id: leadId } = useParams();

  const _criteriaStatuses = ["Met", "Not Met", "Not Applicable"];

  const _statusesIndicators = [
    "text-green-500",
    "text-red-500",
    "text-gray-500",
  ];

  const _criteriaStatusesIndicatorMap = _criteriaStatuses.reduce(
    (obj, key, index) => ({ ...obj, [key]: _statusesIndicators[index] }),
    {}
  );

  // react form hook
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      response: leadQuestion?.response ?? "",
      status: !leadQuestion?.status ? "Met" : leadQuestion?.status,
    },
  });

  // RTK Query
  const [
    updateLeadQualificationCriteria,
    { isLoading: updatingLeadQualificationCriteria },
  ] = useUpdateLeadQualificationCriteriaMutation();

  // methods
  const handleQualificationUpdate = async (data) => {
    try {
      await updateLeadQualificationCriteria({
        ...leadQuestion,
        ...data,
        leadId,
      }).unwrap();
      toast.success("Criteria successfully updated!");
      setEditMode(false);
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't update qualification criteria!"
      );
    }
  };

  return (
    <>
      <Box display="flex" alignItems="start" gap={2} paddingY={3}>
        <LibraryAddCheckOutlined
          color="primary"
          fontSize="small"
          style={{ marginTop: 6 }}
        />

        <Box display="flex" flexGrow={1} flexDirection="column" gap={1}>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography>{leadQuestion?.question?.name}</Typography>

              {!editMode && leadQuestion?.status && (
                <>
                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <div
                    className={`text-sm font-medium ${
                      _criteriaStatusesIndicatorMap[leadQuestion?.status]
                    }`}
                  >
                    {`Requirement ${leadQuestion?.status}`}
                  </div>
                </>
              )}
            </Box>

            {!editMode && (
              <IconButton size="small" onClick={() => setEditMode(true)}>
                <EditOutlined />
              </IconButton>
            )}
          </Box>

          {!editMode &&
            (leadQuestion?.response ? (
              <Box>{leadQuestion?.response}</Box>
            ) : (
              <div className="text-gray-500">No Response</div>
            ))}
        </Box>
      </Box>

      <Collapse in={editMode}>
        <Box padding={2} className="expanded-form">
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TextField
                {...register("response", {
                  maxLength: { value: 100, message: "Max 100 characters." },
                })}
                fullWidth
                label="Response"
                type="text"
                size="small"
                placeholder="Enter response"
                multiline
                rows={2}
              />
            </Grid>

            <Grid item md={3}>
              <Controller
                name="status"
                control={control}
                render={({ field, value }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      fullWidth
                      labelId="select-status"
                      label="Status"
                      placeholder="Select Criteria Status"
                      id="role"
                      {...field}
                    >
                      {_criteriaStatuses?.map((status) => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          className="flex"
          padding={1}
          gap={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#fafafa",
          }}
        >
          <Button
            size="small"
            disabled={updatingLeadQualificationCriteria}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>

          <Button
            size="small"
            variant="outlined"
            disabled={updatingLeadQualificationCriteria}
            onClick={handleSubmit((data) => handleQualificationUpdate(data))}
          >
            Update
          </Button>
        </Box>
      </Collapse>
    </>
  );
};
