import React from "react";

import { Box, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import AppRating from "../Shared/AppRating";

const INITIAL_STATE = {
  content: "",
  rating: 0,
};

const ReviewForm = ({
  reviewDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  React.useEffect(() => {
    if (!reviewDetail) return;

    reset({ ...reviewDetail });

    return () => {
      reset({ ...INITIAL_STATE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewDetail]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Controller
              name="content"
              control={control}
              rules={{ required: "Review is required" }}
              render={({ field }) => (
                <TextField
                  placeholder="Leave a review"
                  fullWidth
                  label="Review"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
            {errors.content && (
              <p className="error">{errors.content.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="rating"
              control={control}
              // rules={{ required: "Rating is required" }}
              render={({ field }) => (
                <AppRating
                  size="large"
                  value={field?.value}
                  onChange={(_, value) => setValue("rating", value)}
                />
              )}
            />
            {/* {errors.rating && <p className="error">{errors.rating.message}</p>} */}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {reviewDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default ReviewForm;
