import React from "react";

import { Route, Routes } from "react-router-dom";

import FeedbackCreateScreen from "../screens/Admin/Feedback/FeedbackCreateScreen";
import FeedbackDetailScreen from "../screens/Admin/Feedback/FeedbackDetailScreen";
import FeedbackEditScreen from "../screens/Admin/Feedback/FeedbackEditScreen";
import FeedbackListScreen from "../screens/Admin/Feedback/FeedbackListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const FeedbackRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<FeedbackCreateScreen />} />
      <Route path=":id/edit" element={<FeedbackEditScreen />} />
      <Route path=":id/detail" element={<FeedbackDetailScreen />} />
      <Route path="list" element={<FeedbackListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default FeedbackRouter;
