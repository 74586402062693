import React from "react";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { getDateDifference } from "../../utils/getDateDifference";
import { getPluralWord } from "../../utils/getFormattedString";

const NextActionDetail = ({ nextAction = null, nextActionDate = "" }) => {
  return nextAction ? (
    <div
      title={`${nextAction?.name} in ${getPluralWord(
        "day",
        getDateDifference(nextActionDate),
        "s"
      )}`}
      className="inline-flex flex-shrink-0 flex-col"
    >
      <div className="text-sm font-medium uppercase tracking-wide text-blue-500 line-clamp-1">
        {nextAction?.name}
      </div>

      <div className="mt-0.5  line-clamp-1">
        {nextActionDate ? (
          <div className="flex space-x-1.5 text-gray-600">
            <div className="text-xs font-medium">
              {moment(nextActionDate)?.format("MMM DD, YYYY")}
            </div>
            {getDateDifference(nextActionDate) > 0 && (
              <>
                <span className="text-xs text-gray-400">|</span>
                <Tooltip
                  title={`${getPluralWord(
                    "day",
                    getDateDifference(nextActionDate),
                    "s"
                  )} remaining`}
                  arrow
                  placement="top"
                >
                  <div className="text-xs font-medium hover:text-blue-600">
                    {getPluralWord(
                      "day",
                      getDateDifference(nextActionDate),
                      "s"
                    )}
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        ) : (
          "N/A"
        )}
      </div>
    </div>
  ) : (
    "N/A"
  );
};

export default NextActionDetail;
