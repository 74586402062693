import React from "react";

import { Route, Routes } from "react-router-dom";

import { KBListScreen } from "../screens/Admin/KB/KBListScreen";
import { KBDashboard } from "../screens/Admin/KB/KBDashboard";
import { ManageKBScreen } from "../screens/Admin/KB/ManageKBScreen";
import { KBDetailHomeScreen } from "../screens/Admin/KB/KBDetailHomeScreen";
import { KBDocumentScreen } from "../screens/Admin/KB/KBDocumentScreen";
import { KBVersionScreen } from "../screens/Admin/KB/KBVersionScreen";
import { KBAttachmentsScreen } from "../screens/Admin/KB/KBAttachmentsScreen";
import { KBAttachmentVersionScreen } from "../screens/Admin/KB/KBAttachmentVersionScreen";
import { KBCommentScreen } from "../screens/Admin/KB/KBCommentScreen";
import { KBTaskScreen } from "../screens/Admin/KB/KBTaskScreen";

export const KnowledgebaseRouter = () => {
  return (
    <Routes>
      {/* <Route path="" element={<KBListScreen />}></Route> */}
      <Route path="dashboard" element={<KBDashboard />}></Route>
      <Route path="list" element={<KBListScreen />} />

      <Route path="add" element={<ManageKBScreen />} />
      <Route path="edit/:id" element={<ManageKBScreen />} />

      <Route path="detail/:articleId" element={<KBDetailHomeScreen />}>
        <Route path="" element={<KBDocumentScreen />}></Route>

        <Route
          path="document-version"
          element={<KBVersionScreen />}
        ></Route>

        <Route
          path="document-version"
          element={<KBVersionScreen />}
        ></Route>

        <Route
          path="attachments"
          element={<KBAttachmentsScreen />}
        ></Route>

        <Route
          path="attachment-version"
          element={<KBAttachmentVersionScreen />}
        ></Route>

        <Route path="comments" element={<KBCommentScreen />}></Route>
        <Route path="tasks" element={<KBTaskScreen />}></Route>
      </Route>
    </Routes>
  );
};