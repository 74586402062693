import { baseApi } from "./baseApi";

const TRANSACTION_BASE_URL = "/transactions";

export const transactionApi = baseApi.injectEndpoints({
  tagTypes: ["Transaction"],

  endpoints: (builder) => ({
    getTransactionList: builder.query({
      query: (params) => ({ url: `${TRANSACTION_BASE_URL}/list`, params }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Transaction", _id })),
              { type: "Transaction", id: "LIST" },
            ]
          : [{ type: "Transaction", id: "LIST" }],
    }),

    getTransactionDetail: builder.query({
      query: (id) => `${TRANSACTION_BASE_URL}/${id}/detail`,
      providesTags: (result, error, id) => [
        { type: "Transaction", id },
        { type: "Transaction", id: "LIST" },
      ],
    }),

    createTransaction: builder.mutation({
      query: (payload) => ({
        url: `${TRANSACTION_BASE_URL}/add`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", id: "LIST" },
      ],
    }),

    updateTransaction: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${_id}/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
        { type: "Report", id: "TRANSACTION" },
      ],
    }),

    // transaction notes
    addTransactionNote: builder.mutation({
      query: (payload) => {
        const { transactionId, ...body } = payload;
        return {
          url: `${TRANSACTION_BASE_URL}/${transactionId}/notes/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
      ],
    }),

    updateTransactionNote: builder.mutation({
      query: (payload) => {
        const { transactionId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${transactionId}/notes/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
      ],
    }),

    // transaction workflows
    attachWorkflow: builder.mutation({
      query: (payload) => {
        const { transactionId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${transactionId}/workflows/attach`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Task", id: "LIST" },
      ],
    }),

    addStepToWorkflowTransaction: builder.mutation({
      query: (payload) => {
        const { documentId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${documentId}/workflows/step/add`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        // { type: "Client", _id },
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
        { type: "Task", id: "LIST" },
      ],
    }),

    reOrderTransactionWorkflowSteps: builder.mutation({
      query: (payload) => {
        const { documentId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${documentId}/workflow/steps/re-order`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", id: "LIST" },
        { type: "Task", id: "LIST" },
      ],
    }),

    // transaction documents
    addTransactionDocument: builder.mutation({
      query: (payload) => {
        const { transactionId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${transactionId}/documents/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
      ],
    }),

    updateTransactionDocument: builder.mutation({
      query: (payload) => {
        const { transactionId, ...body } = payload;

        return {
          url: `${TRANSACTION_BASE_URL}/${transactionId}/documents/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Transaction", _id },
        { type: "Transaction", id: "LIST" },
      ],
    }),

    // client's transactions
    getTransactionsByClient: builder.query({
      query: (clientId) => `${TRANSACTION_BASE_URL}/client/${clientId}`,
      providesTags: (result, error, id) => [
        { type: "Transaction", id },
        { type: "Transaction", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetTransactionListQuery,
  useLazyGetTransactionListQuery,
  useGetTransactionDetailQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,

  useAddTransactionNoteMutation,
  useUpdateTransactionNoteMutation,

  useAttachWorkflowMutation,
  useAddStepToWorkflowTransactionMutation,
  useReOrderTransactionWorkflowStepsMutation,

  useAddTransactionDocumentMutation,
  useUpdateTransactionDocumentMutation,

  useGetTransactionsByClientQuery,
  useLazyGetTransactionsByClientQuery,
} = transactionApi;
