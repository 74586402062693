import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import AppTable from "../../../../components/Shared/AppTable";
import WithHeader from "../../../../layouts/WithHeader";

import { useListWorkflowApplicationsQuery } from "../../../../rtkStore/services/reportApi";

const WorkflowApplicationListScreen = () => {
  const breadcrumbs = [{ title: "Workflow Applications Report" }];

  // RTK Query
  const {
    data: workflowApplications,
    isLoading: loadingWorkflowApplications,
    error,
  } = useListWorkflowApplicationsQuery();

  const columns = [
    {
      name: "Workflow",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/report/workflow-application/${row?._id}?workflow=${row?.title}`}
          className="hover:text-blue-600 hover:underline"
        >
          {row?.title ?? "N/A"}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Steps Included",
      selector: (row) => row?.stepsIncluded?.length ?? 0,
      sortable: true,
    },
    {
      name: "Applications",
      selector: (row) => row?.applicationCount ?? 0,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link
          to={`/app/report/workflow-application/${row?._id}?workflow=${row?.title}`}
        >
          <IconButton color="primary" aria-label="Edit Task">
            <InfoIcon />
          </IconButton>
        </Link>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingWorkflowApplications}
      error={error}
    >
      <AppTable
        columns={columns}
        data={workflowApplications}
        progressPending={loadingWorkflowApplications}
      />
    </WithHeader>
  );
};

export default WorkflowApplicationListScreen;
