import { baseApi } from "./baseApi";

const SEARCH_BASE_URL = "/search";

export const searchApi = baseApi.injectEndpoints({
  tagTypes: ["Search"],

  endpoints: (builder) => ({
    getSearchResult: builder.query({
      query: (query) => ({
        url: `${SEARCH_BASE_URL}`,
        params: query,
      }),
    }),

    searchCourse: builder.mutation({
      query: (query) => ({
        method: "POST",
        url: `${SEARCH_BASE_URL}/course`,
        body: query,
      }),
    }),
  }),
});

export const { useLazyGetSearchResultQuery, useSearchCourseMutation } =
  searchApi;
