import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { AccountBalanceOutlined } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const UniversityResult = ({ universities = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Universities</div>

      {universities.map((university, index) => (
        <Link
          to={`/app/university/${university?._id}/detail`}
          key={university?._id}
        >
          <ResultItem
            title={university?.title}
            subtitle={university.createdAt}
            icon={() => <AccountBalanceOutlined color="primary" />}
          />

          {index !== universities.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default UniversityResult;
