import React from "react";

import { Route, Routes } from "react-router-dom";

import WorkflowCreateScreen from "../screens/Admin/Workflow/WorkflowCreateScreen";
import WorkflowDetailScreen from "../screens/Admin/Workflow/WorkflowDetailScreen";
import WorkflowEditScreen from "../screens/Admin/Workflow/WorkflowEditScreen";
import WorkflowListScreen from "../screens/Admin/Workflow/WorkflowListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const StreamRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<WorkflowCreateScreen />} />
      <Route path=":id/detail" element={<WorkflowDetailScreen />} />
      <Route path=":id/edit" element={<WorkflowEditScreen />} />
      <Route path="list" element={<WorkflowListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default StreamRouter;
