import React from "react";

import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, TextField } from "@mui/material";
import moment from "moment";

const _INITIAL_STATE = {
  name: "",
  testScore: "",
  remark: "",
  examDate: "",
  validTill: "",
};

const TestForm = ({
  testDetail,
  isBusy,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ..._INITIAL_STATE,
      name: testDetail?.name ?? "",
      testScore: testDetail?.testScore ?? "",
      remark: testDetail?.remark ?? "",
      examDate: testDetail?.examDate ?? "",
      validTill: testDetail?.validTill ?? "",
    },
  });

  React.useEffect(() => {
    if (!testDetail) return reset({ ..._INITIAL_STATE });

    reset({ ...testDetail });
  }, [testDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Test Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Test Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="testScore"
              control={control}
              rules={{ required: "Test Score is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Test Score"
                  type="number"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.testScore && (
              <p className="error">{errors.testScore.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="examDate"
              control={control}
              rules={{ required: "Exam Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Exam Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                />
              )}
            />
            {errors.examDate && (
              <p className="error">{errors.examDate.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="validTill"
              control={control}
              rules={{ required: "Valid Till is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Valid Till"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  value={moment(field?.value).format("YYYY-MM-DD")}
                />
              )}
            />
            {errors.validTill && (
              <p className="error">{errors.validTill.message}</p>
            )}
          </Grid>

          <Grid item md={8}>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Remarks"
                  type="text"
                  size="small"
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {testDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default TestForm;
