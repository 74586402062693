import React from "react";

import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";

import InfoGridSkeleton from "../../../components/Shared/Skeleton/InfoGridSkeleton";
import TeamBasicDetail from "./teamComponents/TeamBasicDetail";
import WithHeader from "../../../layouts/WithHeader";

import { useGetTeamDetailQuery } from "../../../rtkStore/services/teamApi";

const TeamDetailScreen = () => {
  const { id } = useParams();

  const breadcrumbs = [
    { title: "Teams", path: "/app/team/list" },
    { title: "Team Detail" },
  ];

  // RTK Query
  const {
    data: teamDetail,
    isLoading: loadingTeamDetail,
    error,
  } = useGetTeamDetailQuery(id);

  const PageActions = () => (
    <Link to={`/app/team/${id}/edit`}>
      <Button variant="contained" startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingTeamDetail}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      {!loadingTeamDetail ? (
        <TeamBasicDetail teamDetail={teamDetail} />
      ) : (
        <InfoGridSkeleton items={5} />
      )}
    </WithHeader>
  );
};

export default TeamDetailScreen;
