import React from "react";

import WithHeader from "../../../../layouts/WithHeader";
import UserForm from "../userComponents/UserForm";

import { useAddAccountUserMutation } from "../../../../rtkStore/services/userApi";
import { toast } from "react-toastify";
import { useAuth } from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const AccountUserCreateScreen = () => {
  const navigate = useNavigate();

  const { viewEntity } = useAuth();

  const breadcrumbs = [
    { title: "Users", path: "/app/user/account/list" },
    { title: "New User" },
  ];

  //   RTK Query
  const [addAccountUser, { isLoading: addingAccountUser, error }] =
    useAddAccountUserMutation();

  //   methods
  const handleAddUser = async (data) => {
    const payload = {
      entity: viewEntity?.entity,
      name: data.name,
      email: data.email,
      password: data.password,
      userGroup: "None", //revised once this has set-up
      authorizations: data.authorizations?.map((auth) => auth.value),
      defaultAuthorization: data.defaultAuthorization.value,
    };
    try {
      await addAccountUser(payload).unwrap();
      toast.success("User successfully added!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add user");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={addingAccountUser}
      error={error}
    >
      <UserForm onSubmit={handleAddUser} />
    </WithHeader>
  );
};

export default AccountUserCreateScreen;
