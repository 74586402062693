import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Button, Divider, Paper } from "@mui/material";
import { toast } from "react-toastify";
import EditOutlined from "@mui/icons-material/EditOutlined";

import AppDialog from "../../../components/Shared/AppDialog";
import CustomTabs from "../../../components/Shared/CustomTabs";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import LeadBasicDetail from "./leadComponents/LeadBasicDetail";
import LeadNotes from "./leadComponents/LeadNotes";
import LeadFavouriteCourses from "./leadComponents/LeadFavouriteCourses";
import LeadQualificationCriteria from "./leadComponents/LeadQualificationCriteria";
import RowsSkeleton from "../../../components/Shared/Skeleton/RowsSkeleton";
import StagePathwayBar from "../../../components/Shared/StagePathwayBar";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetLeadDetailQuery,
  useUpdateLeadMutation,
} from "../../../rtkStore/services/leadApi";
import { useListListQuery } from "../../../rtkStore/services/listService";
import LeadStudyOptionsTab from "./leadComponents/LeadStudyOptionsTab";
import ConvertToClient from "../Client/clientComponents/ConvertToClient";

const LeadDetailContext = React.createContext(null);

export const useLeadDetail = () => React.useContext(LeadDetailContext);

const LeadDetailScreen = () => {
  const [activeTab, setActiveTab] = React.useState("notes");
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const breadcrumbs = [
    { title: "Leads", path: "/app/lead/list" },
    { title: "Lead Detail" },
  ];

  // RTK Query
  const {
    data: leadDetail,
    isLoading: loadingLead,
    error,
  } = useGetLeadDetailQuery(id);

  const { data: leadStages = [] } = useListListQuery({
    type: "Lead Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const [updateLead, { isLoading: updatingLead }] = useUpdateLeadMutation();

  // methods
  const handleLeadStageChange = async ({ _id: stage }) => {
    const selectedStage = leadStages?.find((el) => el?._id === stage);
    const closedAt = selectedStage?.group === "Closed" ? new Date() : null;

    try {
      await updateLead({ _id: id, stage, closedAt }).unwrap();
      toast.info("Lead's stage updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update lead's stage!");
    }
  };

  const handleClearLeadNextAction = async () => {
    try {
      await updateLead({
        _id: id,
        nextAction: null,
        nextActionDate: null,
      }).unwrap();
      toast.info("Lead's next action cleared!");
      setShowConfirmDialog(false);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't clear lead's next action!");
    }
  };

  const handleUpdateLeadStatus = async () => {
    try {
      await updateLead({
        _id: id,
        isActive: !leadDetail?.isActive,
      }).unwrap();
      toast.info(
        `Lead successfully ${leadDetail?.isActive ? "Disabled" : "Enabled"}!`
      );
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update lead status!");
    }
  };

  const leadDetailTabs = [
    {
      label: "Notes",
      value: "notes",
      badgeCount: leadDetail?.notes?.length,
    },
    {
      label: "Qualification Criteria",
      value: "criteria",
    },
    // {
    //   label: "Favourite Courses",
    //   value: "favouriteCourses",
    //   badgeCount: leadDetail?.favouriteCourses?.length,
    // },
    // {
    //   label: "Study Options",
    //   value: "studyOptions",
    //   badgeCount: leadDetail?.studyOptions?.length,
    // },
  ];

  const PageActions = () => (
    <div className="flex space-x-4">
      <Button
        variant="outlined"
        color={leadDetail?.isActive ? "error" : "success"}
        onClick={handleUpdateLeadStatus}
      >
        {leadDetail?.isActive ? "Disable Lead" : "Enable Lead"}
      </Button>

      {!leadDetail?.converted && (
        <ConvertToClient lead={leadDetail} showAsButton />
      )}

      {(leadDetail?.nextAction || leadDetail?.nextActionDate) && (
        <Button variant="outlined" onClick={() => setShowConfirmDialog(true)}>
          Clear Next Action
        </Button>
      )}

      <Link to={`/app/lead/${id}/edit`}>
        <Button startIcon={<EditOutlined />} variant="contained">
          Edit
        </Button>
      </Link>
    </div>
  );

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "notes");
  }, [searchParams]);

  return (
    <LeadDetailContext.Provider value={{ loadingLead, leadDetail }}>
      <WithHeader
        breadcrumbs={breadcrumbs}
        isBusy={loadingLead || updatingLead}
        error={error}
        toggleKey="LEAD_DETAIL"
        hasActions
        headerActions={<PageActions />}
        disablePaper
      >
        {!!leadDetail?.stage && (
          <>
            <StagePathwayBar
              stages={leadStages}
              currentStage={leadDetail?.stage?.name}
              onStageChange={handleLeadStageChange}
            />
            <br />
          </>
        )}

        <Paper elevation={0} variant="outlined">
          <LeadBasicDetail />

          <Divider />

          <CustomTabs tabList={leadDetailTabs} activeTab={activeTab} />

          {!loadingLead ? (
            <>
              <CustomTabPanel activeTab={activeTab} value="notes">
                <LeadNotes />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="criteria">
                <LeadQualificationCriteria />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="favouriteCourses">
                <LeadFavouriteCourses />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="studyOptions">
                <LeadStudyOptionsTab />
              </CustomTabPanel>
            </>
          ) : (
            <RowsSkeleton />
          )}
        </Paper>
      </WithHeader>

      <AppDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleClearLeadNextAction}
        isBusy={updatingLead}
        title="Clear Next Action?"
      >
        Are you sure you want to clear <strong>{leadDetail?.fullName}</strong>'s
        Next Action?
      </AppDialog>
    </LeadDetailContext.Provider>
  );
};

export default LeadDetailScreen;
