import React from "react";

import { Checkbox, Divider, FormControlLabel } from "@mui/material";

import FilterByText from "./FilterByText";

const TableFilters = ({
  showMine = false,
  onShowMineChange = () => {},
  showTeam = false,
  onShowTeamChange = () => {},
  filterText = "",
  filterTextPlaceholder = "Filter Table",
  helperText = "",
  onFilterTextChange = () => {},
  onClearFilterText = () => {},
}) => {
  return (
    <>
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-xs text-gray-500">Filter by:</div>

            <div className="flex space-x-1">
              <FormControlLabel
                control={
                  <Checkbox
                    name="currentlyEmployed"
                    color="primary"
                    size="small"
                    checked={showMine}
                    value={showMine}
                    onChange={onShowMineChange}
                  />
                }
                label="Show Mine"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="currentlyEmployed"
                    color="primary"
                    size="small"
                    checked={showTeam}
                    value={showTeam}
                    onChange={onShowTeamChange}
                    disabled
                  />
                }
                label="Show Team's"
              />
            </div>
          </div>

          <FilterByText
            placeholder={filterTextPlaceholder}
            value={filterText}
            helperText={helperText}
            onChange={onFilterTextChange}
            handleClear={onClearFilterText}
          />
        </div>
      </div>

      <Divider />
    </>
  );
};

export default TableFilters;
