import React from "react";
import DataTable from "react-data-table-component";

import TableLoader from "./TableLoader";

const AppTable = ({ ...props }) => {
  const customStyles = {
    rows: {
      style: {
        "&:hover": {
          backgroundColor: "var(--primary-accent)",
        },
      },
    },
  };

  return (
    <DataTable
      progressComponent={<TableLoader />}
      persistTableHead={true}
      pagination
      customStyles={customStyles}
      {...props}
    />
  );
};

export default AppTable;
