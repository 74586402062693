import React from "react";
import { Box, Button, Modal } from "@mui/material";
import Select from "react-select";
import { toast } from "react-toastify";
import { useKnowledgebaseContext } from "../../../../context/KBContext";
import { useListAssignmentUsersQuery } from "../../../../rtkStore/services/userApi";
import { useEditQMSMentionsMutation } from "../../../../rtkStore/services/knowledgebaseApi";
import { LoadingButton } from "../../../../components/Shared/LoadingButton";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../../components/Shared/CustomModal";


export const KBDocumentMentionModal = ({
  isOpen,
  onCloseModal = () => { },
}) => {
  const [selectedDocumentMentions, setSelectedDocumentMentions] =
    React.useState([]);

  // hooks
  const { state: qmsState, prepareArticleDataForLocalUpdate } =
    useKnowledgebaseContext();

  const { selectedArticle } = qmsState;

  // rtk queries
  const { data: users = [], isLoading: isLoadingUsers } =
    useListAssignmentUsersQuery({});

  const [editQMSMention, { isLoading: isEditingQMSMentions }] =
    useEditQMSMentionsMutation();

  const onSubmit = async () => {
    const documentMentions = selectedDocumentMentions.map((c) => c.value);

    try {
      if (isEditingQMSMentions) return;
      if (isLoadingUsers) return;

      const payload = {
        id: selectedArticle._id,
        mentions: documentMentions,
      };

      await editQMSMention(payload).unwrap();
      toast.success("Document mentions updated successfully");

      const localUpdatePayload = {
        ...selectedArticle,
        mentions: selectedDocumentMentions,
      };

      prepareArticleDataForLocalUpdate(localUpdatePayload);
      onCloseModal();
    } catch (err) {
      const errorMessage =
        err?.data?.message || "Couldn't update document mentions";
      toast.error(errorMessage);
    }
  };

  React.useEffect(() => {
    if (!selectedArticle) return;
    const _documentMentions = selectedArticle?.mentions || [];

    setSelectedDocumentMentions(
      _documentMentions.map((g) => ({
        label: g.name,
        value: g._id,
        ...g,
      }))
    );
  }, [selectedArticle]);

  return (
    <CustomModal classNames="auto-height" open={isOpen} onClose={() => onCloseModal()}>
      <ModalHeader closeButton>
        Select Document Mentions
      </ModalHeader>

      <ModalBody>
        <Box paddingX={1}>
          <Select
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 999999 }),
            }}
            className="form-font"
            placeholder="Select document mentions..."
            isLoading={isLoadingUsers}
            isMulti
            value={selectedDocumentMentions}
            onChange={(data) => {
              setSelectedDocumentMentions(data);
            }}
            options={users.map((t) => ({
              label: t.name,
              value: t._id,
              ...t,
            }))}
          />
        </Box>
      </ModalBody>

      <ModalFooter>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isEditingQMSMentions}
          loadingText={"Adding Document Mentions..."}
          onClick={() => onSubmit()}
        >
          Add Document Mention
        </LoadingButton>
        <Button style={{ marginLeft: "1rem" }} onClick={onCloseModal}>
          Cancel
        </Button>
      </ModalFooter>
    </CustomModal>
  );
};
