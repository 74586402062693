import React from "react";
import { Route, Routes } from "react-router-dom";

import GuestLayoutV2 from "../layouts/GuestLayoutV2";

import CourseCatalogueScreen from "../screens/Guest/CourseCatalogue/CourseCatalogueScreen";
import CourseCatalogueDetailScreen from "../screens/Guest/CourseCatalogue/CourseCatalogueDetailScreen";

const GuestRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<GuestLayoutV2 />}>
        <Route
          path="/courses-catalogue/search"
          element={<CourseCatalogueScreen />}
        />
        <Route
          path="/courses-catalogue/:id"
          element={<CourseCatalogueDetailScreen />}
        />
      </Route>
    </Routes>
  );
};

export default GuestRouter;
