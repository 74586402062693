import React from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

const AttachWorkflowForm = ({
  onSubmit = () => {},
  onCancel = () => {},
  workflowList = [],
  isBusy = false,
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      workflow: "",
    },
  });

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="workflow"
              control={control}
              rules={{ required: "Please select a workflow" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-workflow">Workflow</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-workflow"
                    label="Workflow"
                    placeholder="Select Level"
                    size="small"
                    id="role"
                    disabled={isBusy || workflowList?.length === 0}
                    value={value ? value : ""}
                    {...field}
                  >
                    {workflowList?.map((workflow) => (
                      <MenuItem key={workflow._id} value={workflow._id}>
                        {workflow?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.workflow && (
              <p className="error">{errors.workflow.message}</p>
            )}

            {workflowList?.length === 0 && (
              <div className="mx-2 mt-2  text-sm text-green-500">
                All workflows have been attached!
              </div>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button
          disabled={isBusy}
          onClick={() => {
            reset({ workflow: "" });
            onCancel();
          }}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => {
            reset({ workflow: "" });
            onSubmit(data);
          })}
        >
          Attach
        </Button>
      </Box>
    </>
  );
};

export default AttachWorkflowForm;
