import React from "react";
import { Routes, Route } from "react-router-dom";

// middleware
import AuthenticatedRoute from "../routes/middlewares/AuthenticatedRoute";

// routers
import AccountRouter from "./AccountRouter";
import ApplicationRouter from "./ApplicationRouter";
import AuthorizationRouter from "./AuthorizationRouter";
import BlogRouter from "./BlogRouter";
import ClientRouter from "./ClientRouter";
import CourseRouter from "./CourseRouter";
import CourseSearchScreen from "../screens/Admin/Course/CourseSearchScreen";
import DashboardRouter from "./DashboardRouter";
import EntityRouter from "./EntityRouter";
import FeedbackRouter from "./FeedbackRouter";
import GlobalListRouter from "./GlobalListRouter";
import LeadRouter from "./LeadRouter";
import ListRouter from "./ListRouter";
import ReportRouter from "./ReportRouter";
import TaskRouter from "./TaskRouter";
import TeamRouter from "./TeamRouter";
import TransactionRouter from "./TransactionRouter";
import UserRouter from "./UserRouter";
import UniversityRouter from "./UniversityRouter";
import WorkflowRouter from "./WorkflowRouter";

// pages & components
import PageScreen404 from "../screens/ErrorPages/404PageScreen";
import SidebarLayout from "../layouts/SidebarLayout";
import { KnowledgebaseRouter } from "./KBRouter";

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<AuthenticatedRoute />}>
        <Route element={<SidebarLayout />}>
          <Route path="account/*" element={<AccountRouter />} />
          <Route path="application/*" element={<ApplicationRouter />} />
          <Route path="authorization/*" element={<AuthorizationRouter />} />
          <Route path="blog/*" element={<BlogRouter />} />
          <Route path="client/*" element={<ClientRouter />} />
          <Route path="course/*" element={<CourseRouter />} />
          <Route path="dashboard" element={<DashboardRouter />} />
          <Route path="entity/*" element={<EntityRouter />} />
          <Route path="feedback/*" element={<FeedbackRouter />} />
          <Route path="global-list/*" element={<GlobalListRouter />} />
          <Route path="lead/*" element={<LeadRouter />} />
          <Route path="list/*" element={<ListRouter />} />
          <Route path="report/*" element={<ReportRouter />} />
          <Route path="search-course" element={<CourseSearchScreen />} />
          <Route path="task/*" element={<TaskRouter />} />
          <Route path="team/*" element={<TeamRouter />} />
          <Route path="transaction/*" element={<TransactionRouter />} />
          <Route path="university/*" element={<UniversityRouter />} />
          <Route path="user/*" element={<UserRouter />} />
          <Route path="workflow/*" element={<WorkflowRouter />} />
          <Route path="knowledgebase/*" element={<KnowledgebaseRouter />} />
          <Route path="*" element={<PageScreen404 />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
