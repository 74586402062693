import React from "react";

import { Box, Divider, IconButton, Typography } from "@mui/material";

import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import { EditOutlined, FiberManualRecord } from "@mui/icons-material";
import moment from "moment";
import AppRating from "../Shared/AppRating";

const ReviewList = ({ reviews = [], onReviewSelect = () => {} }) => {
  return (
    <Box paddingX={3}>
      {!!reviews?.length > 0 ? (
        reviews.map((review, index) => (
          <React.Fragment key={review._id}>
            <Box display="flex" alignItems="start" gap={2} paddingY={3}>
              <ReviewsOutlinedIcon
                fontSize="small"
                color="primary"
                style={{ marginTop: 2 }}
              />

              <Box display="flex" flexGrow={1} flexDirection="column" gap={1}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography style={{ color: "var(--primary)" }}>
                    {review?.reviewedBy?.name}
                  </Typography>

                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <Typography>
                    {`${moment(review?.createdAt).format(
                      "MMM D, YYYY"
                    )} at ${moment(review?.createdAt).format("HH:MM:ss a")}`}
                  </Typography>

                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <AppRating value={review?.rating} readOnly hideLabel />
                </Box>

                <Box>{review?.content}</Box>
              </Box>

              <IconButton onClick={() => onReviewSelect(review)}>
                <EditOutlined />
              </IconButton>
            </Box>

            {index !== reviews.length - 1 && <Divider />}
          </React.Fragment>
        ))
      ) : (
        <Box paddingY={3}>No Reviews.</Box>
      )}
    </Box>
  );
};

export default ReviewList;
