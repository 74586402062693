import React from "react";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { AttachmentHookIntendedForOptions } from "../../../constants/kbConstants";
import { useCloudinary } from "../../../hooks/useCloudinary";
import { useManageAttachment } from "../../../hooks/useManageAttachment";
import { useKnowledgebaseContext } from "../../../context/KBContext";
import { useAuth } from "../../../hooks/useAuth";
import { useAddKnowledgebaseAttachmentMutation } from "../../../rtkStore/services/knowledgebaseApi";
import { ManageAttachment } from "./KBComponents/ManageAttachment";
import { LoadingButton } from "./KBComponents/LoadingButton";

export const KBAttachmentsScreen = () => {
  const [isUploadingAttachments, setIsUploadingAttachments] =
    React.useState(false);

  const maxAttachmentsNumber = parseInt(
    process.env.REACT_MAX_ATTACHMENTS_NUMBER ||
    // AppConstants.MAX_ATTACHMENTS_NUMBER
    7
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      body: "",
    },
  });

  // hooks
  const { user } = useAuth();
  const { state: articleState, prepareArticleDataForLocalUpdate } =
    useKnowledgebaseContext();

  const { detailedArticleInformation } = articleState;

  const { handleUploadFiles } = useCloudinary();
  const attachmentHookProps = useManageAttachment({
    intendedFor: AttachmentHookIntendedForOptions.KNOWLEDGEBASE,
    modelId: detailedArticleInformation?._id || "",
    modelName: "id",
    existingAttachments: detailedArticleInformation?.attachments || null,
  });

  const { attachments, setAttachments } = attachmentHookProps;

  // rtk queries
  const [addKnowledgebaseAttachment, { isLoading: isAddingAttachent }] =
    useAddKnowledgebaseAttachmentMutation();

  const handleUploadKBAttachments = async (_attachments) => {
    if (_attachments.length === 0) return [];
    if (_attachments.length > maxAttachmentsNumber) {
      toast.error(
        `Only a maximum of ${maxAttachmentsNumber} attachments is allowed`
      );
      return;
    }

    const loadingId = toast.loading("Uploading knowledgebase attachments...", {
      type: "success",
    });

    const { uploadedFiles } = await handleUploadFiles(_attachments, {
      folderName: "medxprovider/attachments",
    });

    return {
      uploadedFiles,
      loadingId,
    };
  };

  const handleSaveAttachments = async (data) => {
    if (isAddingAttachent) return;

    try {
      setIsUploadingAttachments(true);

      let uploadedAttachments = [];
      let loadingId = "";
      const nonUploadedAttachments = attachments.filter(
        (a) => a?._id === undefined
      );
      const alreadyUploadedAttachments = attachments.filter(
        (a) => a?._id !== undefined
      );

      if (nonUploadedAttachments.length > 0) {
        const { uploadedFiles, loadingId: _loadingId } =
          await handleUploadKBAttachments(nonUploadedAttachments);
        uploadedAttachments = [...alreadyUploadedAttachments, ...uploadedFiles];
        loadingId = _loadingId;
        if (!uploadedFiles || !Array.isArray(uploadedFiles)) {
          toast.error(
            "Something went wrong whlie uploading knowledgebase attachments."
          );
          setIsUploadingAttachments(false);
          return;
        }
      } else {
        uploadedAttachments = [...alreadyUploadedAttachments];
      }
      setIsUploadingAttachments(false);

      const newUploadedAttachments = uploadedAttachments.filter(att => att.secure_url);
      const oldUploadedAttachments = uploadedAttachments.filter(att => att.knowledgebaseId);

      console.log(uploadedAttachments, newUploadedAttachments, oldUploadedAttachments);

      const apiPayload = {
        id: detailedArticleInformation?._id,
        _id: detailedArticleInformation?._id,
        attachment: {
          ...data,
          files: newUploadedAttachments.map((f) => ({
            bytes: f?.bytes,
            attachmentUrl: f?.secure_url || f?.attachmentUrl,
            cPublicId: f?.public_id || f?.cPublicId,
            isImage: f?.isImage === undefined ? false : f?.image,
            ...f,
          }))
        },
      };

      const localUpdatePayload = {
        id: detailedArticleInformation?._id,
        ...detailedArticleInformation,
        attachments: [
          ...detailedArticleInformation.attachments.map((a) => ({
            ...a,
            status: "Inactive",
          })),
          {
            ...apiPayload.attachment,
            owner: user,
            status: "Active",
            knowledgebaseId: detailedArticleInformation?._id,
            createdAt: new Date().toISOString(),
            version:
              detailedArticleInformation?.attachments
                ?.map((x) => ({ ...x }))
                ?.sort((a, b) => b.version - a.version)[0]?.version + 1 || 2,
          },
        ],
      };

      toast.update(loadingId, {
        render: "Updating knowledgebase article...",
        transition: null,
      });
      await addKnowledgebaseAttachment(apiPayload).unwrap();

      toast.dismiss(loadingId);
      // prepareArticleDataForLocalUpdate(localUpdatePayload);
      toast.success("Knowledgebase attachments saved successfully");
      reset();
      setAttachments(null);
    } catch (error) {
      console.log(error);
      const message =
        error?.data?.message || "Couldn't save knowledgebase attachments";
      toast.error(message);
    }

  };

  if (!detailedArticleInformation) return <></>;

  return (
    <Box
      paddingX={2}
      paddingY={2}
      minHeight="calc(100vh - 200px)"
      sx={{ backgroundColor: "#fff" }}
    >
      <Box>
        <Box marginBottom={2}>
          <label htmlFor={`title`} className="form-label">
            Title*
          </label>
          <input
            type="text"
            name={`title`}
            className="form-control form-font py-2"
            {...register(`title`, {
              required: "Document title is required",
            })}
          />
          {errors.title && <p className="error">{errors.title.message}</p>}
        </Box>

        <Box marginY={3}>
          <label htmlFor={`subtitle`} className="form-label">
            Body
          </label>
          <Controller
            name="body"
            control={control}
            rules={{
              required: "Document body is required",
            }}
            render={({ field: { onChange, value } }) => (
              <Editor
                apiKey="zqrsvtmeatld5mag3wlr175d0r1zdo1u8wcr0wvs3re77ow2"
                onEditorChange={onChange}
                // initialValue={
                //   knowledgebase ? knowledgebase.body : ""
                // }
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    'body { font-family:"Segoe UI","Montserrat",Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            )}
          />
          {errors.body && <p className="error">{errors.body.message}</p>}
        </Box>

        {/* <Box marginBottom={3}>
          <label htmlFor="" className="form-font">
            Owner*
          </label>

          <Controller
            name="author"
            control={control}
            rules={{
              required: "Article owner is required",
            }}
            render={({ field }) => (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 10 }),
                }}
                className="form-font"
                placeholder="Select document owner"
                isClearable
                isLoading={isLoadingUsers}
                options={users.map((u) => ({
                  label: u.name,
                  value: u._id,
                }))}
                {...field}
              />
            )}
          />
          {errors.author && <p className="error">{errors.author.message}</p>}
        </Box> */}

        <Box marginTop={4}>
          {detailedArticleInformation?._id && (
            <>
              <ManageAttachment
                {...attachmentHookProps}
                showEditDescriptionButton={false}
              />
            </>
          )}
        </Box>

        <Box marginTop={4}>
          <LoadingButton
            onClick={handleSubmit((d) => handleSaveAttachments(d))}
            variant="contained"
            color="primary"
            loading={isAddingAttachent || isUploadingAttachments}
            loadingText={`Adding Attachment...`}
            startIcon={<i className="fa-solid fa-plus"></i>}
          >
            Add Attachment
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
