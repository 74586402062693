import React from "react"
import { useParams } from "react-router-dom"
import Banner from "../../components/Banner"
import { Container, Grid, Typography } from "@mui/material"
import PopularCourses from "../../components/PopularCourses"
import ConsultExpert from "../../components/ConsultExpert"
import sydneyImage from "../assets/images/cities/sydney.png"
import { PopularCities } from "../../utils/staticData/destinationList"
import ReactHtmlParser from "html-react-parser"

const City = () => {
  const { city } = useParams()

  // Function to filter cities by name
  function filterCity(cityName) {
    return PopularCities.filter(
      (city) => city.city.toLowerCase() === cityName.toLowerCase()
    )
  }

  const cityNameToFind = city

  function cityName(cityNameToFind) {
    // Check if the inputString contains underscores
    if (cityNameToFind.includes("_")) {
      // Replace underscores with spaces using the replace() method
      const modifiedString = cityNameToFind.replace(/_/g, " ")
      return modifiedString
    } else {
      // If no underscores are found, return the input string as it is
      return cityNameToFind
    }
  }

  const filteredCities = filterCity(cityName(cityNameToFind))

  React.useEffect(() => {
    window && window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <>
      {filteredCities && filteredCities.length > 0 ? (
        <>
          <Banner
            image={filteredCities[0].image}
            title={`Study in ${cityName(city)}`}
          />
          <Container maxWidth='xl'>
            <Grid
              container
              spacing={5}
              direction='row'
              justifyContent='center'
              alignItems='center'
              className='GlobalBranchContainer'
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                data-aos='fade-right'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  mt={3}
                  sx={{ fontSize: 22, fontWeight: 700 }}
                >
                  Introduction to {cityName(city)}
                </Typography>

                <Typography>
                  {/* {filteredCities[0].content} */}
                  {filteredCities[0].content &&
                    ReactHtmlParser(filteredCities[0].content)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                data-aos='fade-left'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                <img
                  src={filteredCities[0].cityMap}
                  alt={filteredCities[0].city}
                  className='WorldMapImage'
                ></img>
              </Grid>
            </Grid>
          </Container>
          <PopularCourses
            city={filteredCities[0].city}
            cityId={filteredCities[0].cityId}
            countryId={filteredCities[0].countryId}
          />

          <ConsultExpert />
        </>
      ) : (
        <>
          <Banner image={sydneyImage} title={"Not Found"} />
        </>
      )}
    </>
  )
}

export default City
