import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { MenuBookOutlined } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const CourseResult = ({ courses = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Courses</div>

      {courses.map((course, index) => (
        <Link to={`/app/course/${course?._id}/detail`} key={course?._id}>
          <ResultItem
            title={course?.title}
            subtitle={course.createdAt}
            icon={() => <MenuBookOutlined color="primary" />}
          />

          {index !== courses.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default CourseResult;
