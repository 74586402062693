import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import WithHeader from "../../../layouts/WithHeader";

import TeamForm from "./teamComponents/TeamForm";

import {
  useGetTeamDetailQuery,
  useUpdateTeamMutation,
} from "../../../rtkStore/services/teamApi";

const TeamEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Teams", path: "/app/team/list" },
    { title: "Edit Team" },
  ];

  // RTK Query
  const {
    data: teamDetail,
    isLoading: loadingTeamDetail,
    error,
  } = useGetTeamDetailQuery(id);

  const [updateTeam, { isLoading: updatingTeam }] = useUpdateTeamMutation();

  // methods
  const handleUpdateTeam = async (data) => {
    try {
      await updateTeam(data).unwrap();
      toast.success("Team successfully updated!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Team!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingTeamDetail || updatingTeam}
      error={error}
    >
      <TeamForm
        isBusy={loadingTeamDetail || updatingTeam}
        teamDetail={teamDetail}
        onSubmit={handleUpdateTeam}
      />
    </WithHeader>
  );
};

export default TeamEditScreen;
