import React from "react";

import { Box } from "@mui/material";

const ExpandedRow = ({ children }) => {
  return (
    <Box
      style={{
        background: "#fafafa",
        border: "1px solid lightgray",
        borderTop: "none",
        borderRadius: "0 0 .5rem .5rem",
        padding: ".25rem 1rem",
        marginBottom: ".25rem",
      }}
    >
      {children}
    </Box>
  );
};

export default ExpandedRow;
