import React from "react";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  EditOutlined,
  ExpandMore,
  FiberManualRecord,
  PlaylistAddCheckOutlined,
  SubjectOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import AppDraggableList from "../../../../components/Shared/AppDraggableList";
import WorkflowStepForm from "../../../../components/Workflow/WorkflowStepForm";

import {
  useAddStepToWorkflowMutation,
  useReOrderWorkflowStepsMutation,
  useUpdateWorkflowStepMutation,
} from "../../../../rtkStore/services/workflowApi";

import { useReOrderDraggableList } from "../../../../hooks/useReOrderDraggableList";

const ManageWorkflowSteps = ({ workflowDetail }) => {
  const [showStepForm, setShowStepForm] = React.useState(false);
  const [selectedStep, setSelectedStep] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const firstRender = React.useRef(true);

  const { id: workflowId } = useParams();

  const [searchParams] = useSearchParams();

  const workflowSteps = React.useMemo(
    () => workflowDetail?.steps ?? [],
    [workflowDetail]
  );

  const { orderedList, handleOnDragEnd } = useReOrderDraggableList({
    listItems: workflowSteps,
  });

  // RTK Query
  const [addStep, { isLoading: addingStep }] = useAddStepToWorkflowMutation();

  const [updateStep, { isLoading: updatingStep }] =
    useUpdateWorkflowStepMutation();

  const [reOrderWorkflowSteps, { isLoading: reOrderingSteps }] =
    useReOrderWorkflowStepsMutation();

  //   methods
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCancel = () => {
    setShowStepForm(false);
    setSelectedStep(null);
  };

  const handleAddStep = async (data) => {
    setSelectedStep({ ...data });
    try {
      await addStep({ ...data, workflowId }).unwrap();
      toast.success("Step successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add step to stream.");
    }
  };

  const handleStepEdit = (stepDetail) => {
    setSelectedStep(stepDetail);
    setShowStepForm(true);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateStep = async (data) => {
    try {
      await updateStep({ ...data, workflowId }).unwrap();
      toast.success("Step successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update step.");
    }
  };

  React.useEffect(() => {
    if (!searchParams) return;

    if (searchParams.get("addStep")) setShowStepForm(true);
  }, [searchParams]);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (!orderedList?.length || firstRender.current) return;

    const handleReOrderSteps = async (reOrderedSteps) => {
      try {
        await reOrderWorkflowSteps({ workflowId, reOrderedSteps }).unwrap();
      } catch (err) {
        toast.error(err?.data?.message ?? "Couldn't Re-Order Steps");
      }
    };

    const reOrderedSteps = orderedList?.map(({ _id, serialNumber }) => ({
      _id,
      serialNumber,
    }));

    if (reOrderedSteps?.length) handleReOrderSteps(reOrderedSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedList]);

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge badgeContent={workflowSteps.length} color="primary" showZero>
              <SubjectOutlined style={{ color: "gray" }} />
            </Badge>

            <Typography style={{ fontWeight: 500 }}>Steps Involved</Typography>
          </Box>

          {!showStepForm && (
            <Button
              disabled={reOrderingSteps}
              variant="outlined"
              onClick={() => setShowStepForm(true)}
            >
              {reOrderingSteps ? "Re-Ordering Steps..." : "Add Step"}
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Collapse in={showStepForm}>
        <WorkflowStepForm
          workflowType={workflowDetail?.workflowType}
          stepDetail={selectedStep}
          isBusy={addingStep || updatingStep}
          onSubmit={selectedStep ? handleUpdateStep : handleAddStep}
          onCancel={handleCancel}
        />
      </Collapse>

      <AppDraggableList
        isBusy={reOrderingSteps}
        onDragEnd={handleOnDragEnd}
        listItems={orderedList}
        draggableItem={({ data }) => (
          <WorkflowStepItem
            workflowStep={data}
            onStepEdit={handleStepEdit}
            expanded={expanded}
            onExpandStep={handleAccordionChange}
          />
        )}
      />
    </>
  );
};

export default ManageWorkflowSteps;

const WorkflowStepItem = ({
  workflowStep,
  onStepEdit,
  expanded,
  onExpandStep,
}) => {
  return (
    <Box paddingY={2}>
      <Accordion
        disableGutters
        expanded={expanded === workflowStep?._id}
        onChange={onExpandStep(workflowStep?._id)}
        style={{
          boxShadow: "none",
          margin: "-12px 0",
          backgroundColor: "transparent",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="step-content"
          style={{ padding: "10px 0" }}
        >
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flexGrow={1} alignItems="center" gap={2}>
              <Typography>{workflowStep?.title}</Typography>

              {!!workflowStep?.requiredDocuments?.length && (
                <>
                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <Typography>
                    {workflowStep?.requiredDocuments?.length} Documents
                  </Typography>
                </>
              )}

              {!!workflowStep?.tasks?.length && (
                <>
                  <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

                  <Typography>{workflowStep?.tasks?.length} Tasks</Typography>
                </>
              )}

              <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

              <Typography color={workflowStep?.isActive ? "green" : "red"}>
                {workflowStep?.isActive ? "Active" : "Inactive"}
              </Typography>
            </Box>

            <IconButton
              style={{ marginTop: -3 }}
              onClick={() => onStepEdit(workflowStep)}
            >
              <EditOutlined fontSize="small" />
            </IconButton>
          </Box>
        </AccordionSummary>

        <AccordionDetails style={{ padding: 0, paddingBottom: 10 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            {!!workflowStep?.requiredDocuments?.length && (
              <Box display="flex" alignItems="center" gap={2}>
                {workflowStep?.requiredDocuments?.map((doc, index) => (
                  <React.Fragment key={doc._id}>
                    <Box display="flex" alignItems="center">
                      <Typography className="text-sm text-blue-500">
                        {doc.name}
                      </Typography>
                    </Box>

                    {index !== workflowStep?.requiredDocuments?.length - 1 && (
                      <span className="text-gray-400">|</span>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            )}

            <Box>{ReactHtmlParser(workflowStep?.content)}</Box>

            {!!workflowStep?.tasks.length && (
              <div className="rounded border-2 border-transparent bg-blue-50 px-4 py-3 group-hover:border-white">
                <div className="flex  space-x-3 ">
                  <PlaylistAddCheckOutlined className="text-blue-500" />

                  <div className="flex flex-col space-y-2 ">
                    <div className="flex space-x-2 text-gray-500">
                      <div className="font-medium ">Step Related Tasks</div>

                      <div className="flex items-center space-x-2 ">
                        <span> ( </span> <div className="text-sm">Title</div>
                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 8 }}
                        />
                        <div className="text-sm">Category</div>
                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 8 }}
                        />
                        <div className="text-sm">Due Date</div> <span> ) </span>
                      </div>
                    </div>

                    <ol className="flex flex-col space-y-2">
                      {workflowStep?.tasks?.map((task) => (
                        <li key={task?._id} className="list-decimal">
                          <div className="flex items-center space-x-3">
                            <div>{task?.title}</div>
                            <FiberManualRecord
                              style={{ color: "gray", fontSize: 8 }}
                            />

                            <div> {task?.category} </div>

                            {task?.dueDate && (
                              <>
                                <FiberManualRecord
                                  style={{
                                    color: "gray",
                                    fontSize: 8,
                                  }}
                                />

                                <div>
                                  {moment(task?.dueDate)?.format(
                                    "MMM DD, YYYY"
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
