import React from "react";

import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

const UserChangePasswordForm = ({
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = React.useRef({});
  password.current = watch("password", "");

  return (
    <>
      <Box padding={3}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <TextField
              {...register("password", {
                required: "New Password is required",
              })}
              fullWidth
              label="New Password"
              placeholder="Enter new password"
              size="small"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <TextField
              {...register("confirmPassword", {
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
              fullWidth
              label="Confirm New Password"
              placeholder="Confirm new password"
              size="small"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          Change Password
        </Button>
      </Box>
    </>
  );
};

export default UserChangePasswordForm;
