import React from "react";
import { Route, Routes } from "react-router-dom";

import AgentLeadStatusReport from "../screens/Admin/Report/AgentLeadStatusReport";
import WorkflowApplicationListScreen from "../screens/Admin/Report/workflowApplication/WorkflowApplicationListScreen";
import WorkflowApplicationReportScreen from "../screens/Admin/Report/workflowApplication/WorkflowApplicationReportScreen";
import WorkflowTransactionListScreen from "../screens/Admin/Report/workflowTransaction/WorkflowTransactionListScreen";
import WorkflowTransactionReportScreen from "../screens/Admin/Report/workflowTransaction/WorkflowTransactionReportScreen";

import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const ReportRouter = () => {
  return (
    <Routes>
      <Route path="lead-status" element={<AgentLeadStatusReport />} />
      <Route path="workflow-transaction">
        <Route path="list" element={<WorkflowTransactionListScreen />} />
        <Route
          path=":workflowId"
          element={<WorkflowTransactionReportScreen />}
        />
      </Route>
      <Route path="workflow-application">
        <Route path="list" element={<WorkflowApplicationListScreen />} />
        <Route
          path=":workflowId"
          element={<WorkflowApplicationReportScreen />}
        />
      </Route>
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default ReportRouter;
