import React from "react";

import { Navigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

const AccountAdminRoute = ({ component: Component, ...otherProps }) => {
  const { isAccountAdmin } = useAuth();

  if (isAccountAdmin) return <Component {...otherProps} />;

  return <Navigate to="/app/dashboard" />;
};

export default AccountAdminRoute;
