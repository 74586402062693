import React from "react"

import { Routes, Route } from "react-router-dom"

import HomeScreen from "../screens/Home/HomeScreen"
import ServiceScreen from "../screens/Home/ServiceScreen"
// import StudyAbroadScreen from "../screens/Home/StudyAbroadScreen"
import TestPreparationScreen from "../screens/Home/TestPreparationScreen"
import AboutScreen from "../screens/Home/AboutScreen"
// import BlogScreen from "../screens/Home/BlogScreen";
import ContactScreen from "../screens/Home/ContactScreen"
import CountryScreen from "../screens/Home/CountryScreen"
// import BlogDetailScreen from "../screens/Home/BlogDetailScreen";
// import EducationBlogScreen from "../screens/Home/EducationBlogScreen";
import GuestLayout from "../layouts/GuestLayout"
import PageScreen404 from "../screens/ErrorPages/404PageScreen"
import StudyInAus from "../screens/Home/StudyInAus"
import Locations from "../screens/Home/Locations"
import MeetOurTeam from "../screens/Home/MeetOurTeam"
import City from "../screens/Home/City"

const HomeRouter = () => {
  return (
    <Routes>
      <Route element={<GuestLayout />}>
        <Route index element={<HomeScreen />} />
        <Route path='services' element={<ServiceScreen />} />
        <Route path='study-in-aus' element={<StudyInAus />} />
        <Route path='locations' element={<Locations />} />
        {/* <Route path="study-abroad" element={<StudyAbroadScreen />} /> */}
        <Route path='test-preaparation' element={<TestPreparationScreen />} />
        <Route path='about' element={<AboutScreen />} />
        {/* <Route path="blogs/:id" element={<BlogDetailScreen />} /> */}
        {/* <Route path="blogs" element={<BlogScreen />} /> */}
        {/* <Route path="blogs/:category" element={<EducationBlogScreen />} /> */}
        <Route path='australia' element={<CountryScreen />} />
        <Route path='contact' element={<ContactScreen />} />
        <Route path='meet-our-team' element={<MeetOurTeam />} />
        <Route
          path='/study-abroad/popular-destinations/:country'
          element={<CountryScreen />}
        />
        <Route
          path='/study-abroad/popular-destinations/:country/:city'
          element={<City />}
        />

        <Route path='*' element={<PageScreen404 />} />
      </Route>
    </Routes>
  )
}

export default HomeRouter
