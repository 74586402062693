import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import TransactionForm from "./transactionComponents/TransactionForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateTransactionMutation } from "../../../rtkStore/services/transactionApi";
const TransactionCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Transactions", path: "/app/transaction/list" },
    { title: "New Transaction" },
  ];

  // RTK Query
  const [createTransaction, { isLoading: creatingTransaction }] =
    useCreateTransactionMutation();

  // methods
  const handleAddTransaction = async (data) => {
    try {
      const { _id } = await createTransaction({ ...data }).unwrap();
      navigate(`/app/transaction/${_id}/detail`);
      toast.success("Transaction successfully created!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create transaction!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingTransaction}>
      <TransactionForm
        isBusy={creatingTransaction}
        onSubmit={handleAddTransaction}
      />
    </WithHeader>
  );
};

export default TransactionCreateScreen;
