export const useCloudinary = () => {
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  async function handleUploadFiles(files, options = { folderName: "" }) {
    const uploadedFiles = [];

    const upload_preset =
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "medxgateway_preset";
    const cloud_name =
      process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || "medxgateway";

    for (const file of files) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folder", options.folderName);
        formData.append(
          "upload_preset",
          upload_preset
          // process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
        );
        const res = await fetch(
          `https://api.cloudinary.com/v1_1/${cloud_name}/raw/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const fileResponse = await res.json();
        const data = {
          ...fileResponse,
          description: file.description,
          fileName: file.name,
          isImage: validImageTypes.includes(file["type"]),
        };
        uploadedFiles.push(data);
      } catch (err) {
        throw err;
      }
    }

    return {
      uploadedFiles,
    };
  }

  return {
    handleUploadFiles,
  };
};
