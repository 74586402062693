import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { AccountTreeOutlined } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const WorkflowResult = ({ workflows = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Workflows</div>

      {workflows.map((workflow, index) => (
        <Link to={`/app/workflow/${workflow?._id}/detail`} key={workflow?._id}>
          <ResultItem
            title={workflow?.title}
            subtitle={workflow.createdAt}
            icon={() => <AccountTreeOutlined color="primary" />}
          />

          {index !== workflows.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default WorkflowResult;
