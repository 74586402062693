import React from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { Add, BookmarksOutlined, SaveAsOutlined } from "@mui/icons-material";

import AppDialog from "../../../../../components/Shared/AppDialog";
import FavouriteCourseCard from "../../../../../components/Course/FavouriteCourseCard";
import GenerateStudyOptionsForm from "../../../../../components/StudyOption/GenerateStudyOptionsForm";

import {
  useAddStudyOptionToClientMutation,
  useReOrderClientFavouriteCoursesMutation,
  useRemoveClientFavouriteCourseMutation,
} from "../../../../../rtkStore/services/clientApi";
import AppDraggableList from "../../../../../components/Shared/AppDraggableList";

import { useClientDetail } from "../ClientBasicInformationTab";
import { useReOrderDraggableList } from "../../../../../hooks/useReOrderDraggableList";
import { arrayIsEqual } from "../../../../../utils/arrayHelpers";

const ClientFavCoursesTab = () => {
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [showGenerateOption, setShowGenerateOption] = React.useState(false);
  const [studyOptionCourses, setStudyOptionCourses] = React.useState([]);

  const { id: clientId } = useParams();

  const {
    clientDetail: { favCourses },
    clientDetail,
  } = useClientDetail();

  const { orderedList: clientFavouriteCourses, handleOnDragEnd } =
    useReOrderDraggableList({
      listItems: favCourses,
      disableSN: true,
    });

  const allCourseSelected = React.useMemo(
    () =>
      studyOptionCourses?.length &&
      studyOptionCourses.length === clientFavouriteCourses.length,
    [clientFavouriteCourses, studyOptionCourses]
  );

  // RTK Query
  const [removeFavouriteCourse, { isLoading: removingFavouriteCourse }] =
    useRemoveClientFavouriteCourseMutation();

  const [addStudyOption, { isLoading: addingStudyOption }] =
    useAddStudyOptionToClientMutation();

  const [reOrderFavouriteCourses, { isLoading: reOrderingCourses }] =
    useReOrderClientFavouriteCoursesMutation();

  // methods
  const handleAddStudyOption = async (data) => {
    const studyOptionPayload = {
      ...data,
      clientId,
      courseOptions: studyOptionCourses?.map((el) => ({
        course: el?.course?._id,
        serialNumber: el?.serialNumber,
      })),
    };

    try {
      const studyOption = await addStudyOption(studyOptionPayload).unwrap();
      toast.info("Study Option added to Client!");
      setSelectedCourse(null);
      setShowGenerateOption(false);

      window.open(`/study-options/${studyOption?._id}/clients/${clientId}`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Study option couldn't be added!");
    }
  };

  const handleSetRemovableCourse = (course) => {
    setSelectedCourse(course);
  };

  const handleRemoveCourse = async () => {
    try {
      await removeFavouriteCourse({ clientId, ...selectedCourse }).unwrap();
      toast.success("Course successfully removed from favourites!");
      setSelectedCourse(null);
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't remove course from favourites!"
      );
    }
  };

  const handleCourseSelection = (course) => {
    const alreadyExists = !!studyOptionCourses?.find(
      (el) => el?._id === course?._id
    );
    if (!alreadyExists) setStudyOptionCourses([...studyOptionCourses, course]);
    else
      setStudyOptionCourses((prev) =>
        [...prev].filter((el) => el?._id !== course?._id)
      );
  };

  const handleMassSelectAction = () => {
    allCourseSelected
      ? setStudyOptionCourses([])
      : setStudyOptionCourses([...clientFavouriteCourses]);
  };

  React.useEffect(() => {
    setStudyOptionCourses([...favCourses]);
    return () => {
      setStudyOptionCourses([]);
    };
  }, [favCourses]);

  React.useEffect(() => {
    if (
      !clientFavouriteCourses?.length ||
      !favCourses?.length ||
      reOrderingCourses
    )
      return;

    if (arrayIsEqual(clientFavouriteCourses, favCourses)) return;

    const handleReOrderFavouriteCourses = async () => {
      const reOrderedCourses = clientFavouriteCourses?.map(
        ({ _id, serialNumber }) => ({
          _id,
          serialNumber,
        })
      );

      try {
        await reOrderFavouriteCourses({
          clientId,
          reOrderedCourses,
        }).unwrap();
      } catch (err) {
        toast.error(err?.data?.message ?? "Couldn't Re-Order Courses");
      }
    };

    handleReOrderFavouriteCourses(clientFavouriteCourses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFavouriteCourses]);

  return (
    <>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <IconButton
            style={{ marginLeft: -10 }}
            onClick={handleMassSelectAction}
          >
            <BookmarksOutlined
              style={{ color: allCourseSelected ? "var(--primary)" : "gray" }}
            />
          </IconButton>

          <Typography style={{ fontWeight: "500" }}>
            Favourite Courses
          </Typography>
        </Box>

        <div className="flex space-x-4">
          <Link
            to={`/app/search-course?name=${clientDetail?.fullName}&ref=${clientId}&type=Client`}
            target="_blank"
          >
            <Button startIcon={<Add />} variant="outlined">
              Favourite Course
            </Button>
          </Link>

          {!!clientFavouriteCourses?.length && !!studyOptionCourses?.length && (
            <>
              <Button
                startIcon={<SaveAsOutlined />}
                variant="contained"
                onClick={() => setShowGenerateOption(true)}
              >
                Study Options
              </Button>

              <GenerateStudyOptionsForm
                isBusy={addingStudyOption}
                show={showGenerateOption}
                onClose={() => setShowGenerateOption(false)}
                studyOptionCourses={studyOptionCourses}
                onAddStudyOption={handleAddStudyOption}
              />
            </>
          )}
        </div>
      </Box>

      <Divider />

      <Box paddingX={3}>
        {!!clientFavouriteCourses.length ? (
          <AppDraggableList
            isBusy={reOrderingCourses}
            listItems={clientFavouriteCourses}
            onDragEnd={handleOnDragEnd}
            draggableItem={({ data }) => (
              <FavouriteCourseCard
                favCourse={data}
                checked={
                  !!studyOptionCourses?.find(
                    (course) => course?._id === data?._id
                  )
                }
                onRemove={handleSetRemovableCourse}
                onSelection={handleCourseSelection}
              />
            )}
          />
        ) : (
          <Box paddingY={3}>No Favourite Course</Box>
        )}
      </Box>

      <AppDialog
        isBusy={removingFavouriteCourse}
        title="Confirm Remove from Favourite!"
        open={!!selectedCourse}
        onClose={() => setSelectedCourse(null)}
        onConfirm={handleRemoveCourse}
      >
        <Typography>
          Are you sure you want to remove{" "}
          <span className="text-blue-600">{selectedCourse?.title}</span> from
          favourites?
        </Typography>
      </AppDialog>
    </>
  );
};

export default ClientFavCoursesTab;
