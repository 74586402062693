import React from "react";
import ReactHtmlParse from "html-react-parser";
import moment from "moment";

import FSWLogo from "../../assets/fsw-logo.png";
import placeholder from "../../assets/fsw-placeholder.png";

const GeneratedStudyOptions = ({ assignee }) => {
  if (!assignee) return null;

  const studyOptionCourses = assignee?.studyOptions?.courseOptions ?? [];
  const responsibleUser = assignee?.studyOptions?.responsible;
  const generatedDate = assignee?.studyOptions?.generatedDate;

  const sortedCourseOptions = [...studyOptionCourses].sort((a, b) =>
    a?.serialNumber > b?.serialNumber ? 1 : -1
  );

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="flex items-center justify-between">
          <img src={FSWLogo} alt="Logo" className="-ml-3 w-1/3" />
          <div className="mb-3 font-medium">
            {moment(new Date(generatedDate)).format("MMM DD, YYYY")}
          </div>
        </div>

        <br />

        <div className="-mt-2 mb-3 text-xl font-semibold">
          Study Options for{" "}
          {/* <span className='text-xl  text-red-600 '>{assignee?.fullName}</span> */}
          <span className="text-xl  text-red-600 ">{`${assignee?.firstName} ${assignee?.lastName}`}</span>
        </div>

        <div className="font-medium italic">
          Congratulations on your decision for a bright future. Below are your
          personalized study options.
        </div>
      </div>

      <br />

      <hr />

      <br />

      <div className="flex flex-col space-y-4">
        {sortedCourseOptions?.map((courseOption, index) => (
          <React.Fragment key={courseOption?.course?._id}>
            <>
              <div className="flex items-start space-x-6">
                <img
                  src={courseOption?.course?.university?.logo ?? placeholder}
                  alt={courseOption?.course?.university?.title}
                  className="mt-2 aspect-square h-full w-40 flex-shrink-0 rounded border object-contain"
                />

                <div className="mt-2 flex flex-grow flex-col space-y-2.5">
                  <div className="text-lg font-semibold">
                    {courseOption?.course?.university?.title}
                  </div>

                  <div className="font-medium">
                    Course Name: {courseOption?.course?.title}
                  </div>

                  {!!courseOption?.course?.agentPromotions?.length && (
                    <div className="flex space-x-2 text-sm font-medium text-green-500">
                      (
                      {courseOption?.course?.agentPromotions
                        ?.map((el) => <span key={el?._id}> {el?.name} </span>)
                        ?.reduce((prev, curr) => [prev, ", ", curr])}
                      )
                    </div>
                  )}

                  <div className="flex justify-between">
                    <div>
                      <span className="text-sm">Duration: </span>{" "}
                      <span>{courseOption?.course?.duration?.name}</span>
                    </div>

                    {courseOption?.course?.tuitionFee > 0 && (
                      <div>
                        <span className="text-sm"> Course Fee: </span>{" "}
                        <span className="text-sm font-medium">
                          {courseOption?.course?.tuitionFee
                            ? `${courseOption?.course?.currency?.symbol} ${courseOption?.course?.tuitionFee} (${courseOption?.course?.currency?.code}) Per ${courseOption?.course?.feeType}`
                            : "N/A"}
                        </span>
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="text-sm">
                      Locations:{" "}
                      {courseOption?.course?.subLocations?.length
                        ? courseOption?.course?.subLocations
                            ?.map((el) => el?.name)
                            ?.join(", ")
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-sm">
                <span className="text-sm">Course Link:</span>{" "}
                <span className="text-sm text-blue-600 underline">
                  {courseOption?.course?.courseLink}
                </span>{" "}
              </div>

              {courseOption?.course?.summary && (
                <div>
                  <div className="text-sm text-gray-500 underline">
                    Course Summary
                  </div>
                  {ReactHtmlParse(courseOption?.course?.summary)}
                </div>
              )}

              {courseOption?.course?.addmissionRequirement
                ?.englishRequirement && (
                <div>
                  <div className="text-sm text-gray-500 underline">
                    English Requirement
                  </div>
                  {ReactHtmlParse(
                    courseOption?.course?.addmissionRequirement
                      ?.englishRequirement
                  )}
                </div>
              )}
            </>

            {index !== sortedCourseOptions?.length - 1 && (
              <>
                <br />

                <hr />

                <br />
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      <br />
      <br />

      <div className="">
        <p className="font-medium italic">
          Choose a bright future for you and your family. This is one of the
          biggest and most important decision you will need to make. I will help
          you at every step of the process.
        </p>

        <br />
        <br />
        <br />

        <p className="font-medium">
          <span className="font-semibold text-red-600">
            {responsibleUser?.name}{" "}
          </span>

          {responsibleUser?.designation?.length && (
            <>| {responsibleUser?.designation}</>
          )}
        </p>

        <p className="text-lg font-semibold">
          First Source World
          <span className="text-2xl text-red-600">.</span>
        </p>

        <p>
          {responsibleUser?.phone?.length && (
            <>
              <span>{responsibleUser?.phone}</span> |{" "}
            </>
          )}
          {responsibleUser?.email}
        </p>

        <p className="font-medium">
          Education & Visa Services like never before.
        </p>
      </div>
    </div>
  );
};

export default GeneratedStudyOptions;
