import React from "react";

import { Route, Routes } from "react-router-dom";

import AccountLvlEntityListScreen from "../screens/Admin/Entity/AccountLvlEntityListScreen";
import EntityDetailScreen from "../screens/Admin/Entity/EntityDetailScreen";
import EditEntityPlanScreen from "../screens/Admin/Entity/EditEntityPlanScreen";
import EditEntityScreen from "../screens/Admin/Entity/EditEntityScreen";
import RegisterAccountEntityScreen from "../screens/Admin/Entity/RegisterAccountEntityScreen";

const EntityRouter = () => {
  return (
    <Routes>
      <Route path="list">
        <Route path="account" element={<AccountLvlEntityListScreen />} />
      </Route>

      <Route path="register" element={<RegisterAccountEntityScreen />} />
      <Route path="detail" element={<EntityDetailScreen />} />
      <Route path="edit" element={<EditEntityScreen />} />
      <Route path="edit-plan" element={<EditEntityPlanScreen />} />
    </Routes>
  );
};

export default EntityRouter;
