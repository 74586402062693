import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

const AppDialog = (props) => {
  const {
    title = "Confirm",
    open = false,
    onClose = () => {},
    onConfirm = () => {},
    children,
    isBusy = false,
    maxWidth = "md",
    hideIcon = false,
    ...otherProps
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      aria-labelledby="confirmation-dialog"
      {...otherProps}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Box display="flex" alignItems="center" gap={1}>
          {!hideIcon && <WarningAmberOutlinedIcon color="warning" />}

          <Typography variant="h6">{title}</Typography>
        </Box>
      </DialogTitle>

      <DialogContent dividers>{children}</DialogContent>

      <DialogActions>
        <Button autoFocus disabled={isBusy} onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" disabled={isBusy} onClick={onConfirm}>
          {!isBusy ? "Confirm" : <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppDialog;
