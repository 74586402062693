import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { KBDetailHeader } from "./KBDetailHeader";

export const KnowledgebaseDetailWrapper = () => {
  return (
    <Box
      sx={{
        overflow: "auto",
        width: "100%",
      }}
      position="relative"
      height="calc(100vh - 80px)"
      maxHeight="calc(100vh - 80px)"
    >
      <KBDetailHeader />
      <Box
        paddingX={2}
        paddingY={2}
        minHeight="calc(100vh - 200px)"
        sx={{
          // backgroundColor: "white",
          backgroundColor: "#F5F7F970",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
