import React from "react";

import LottieAnimationWrapper from "./Lottie/LottieAnimationWrapper";

import globeAnimation from "../../assets/lottie/globe.json";

const CustomLoader = () => {
  return <LottieAnimationWrapper lottieFile={globeAnimation} height={300} />;
};

export default CustomLoader;
