import React from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Skeleton } from "@mui/material";

import AppDraggableList from "../../../components/Shared/AppDraggableList";
import ListOption from "../../../components/List/ListOption";
import ListOptionForm from "../../../components/List/ListOptionForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useAddListMutation,
  useEditListMutation,
  useLazyDetailListQuery,
  useReorderListItemsMutation,
} from "../../../rtkStore/services/listService";

import { listOptions } from "../../../utils/staticData/listOptions";

const ListDetailScreen = () => {
  const [listItems, setListItems] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const { id: listId } = useParams();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const listTypeParams = React.useMemo(() => {
    return searchParams.get("type")?.split(",");
  }, [searchParams]);

  const currentListType = React.useMemo(() => {
    return listTypeParams[listTypeParams?.length - 1];
  }, [listTypeParams]);

  const currentListOption = listOptions.find(
    (lo) => lo.name === currentListType
  );

  const breadcrumbs = [
    { title: "List", path: "/app/list/all" },
    ...listTypeParams?.map((el, index) => ({
      title: el,
      path: "",
    })),
  ];

  //   RTK Query
  const [
    fetchListDetail,
    {
      isLoading: isLoadingListDetail,
      isFetching: isFetchingListDetail,
      data: listDetail,
      error,
    },
  ] = useLazyDetailListQuery();

  const [addList, { isLoading: addingList }] = useAddListMutation();

  const [editList, { isLoading: updatingList }] = useEditListMutation();

  const [reorderListItems, { isLoading: reorderingListItems }] =
    useReorderListItemsMutation();

  const isBusy =
    addingList ||
    isLoadingListDetail ||
    isFetchingListDetail ||
    updatingList ||
    reorderingListItems;

  const serialNumberInitials = React.useMemo(() => {
    if (!listDetail?.name) return;
    return listDetail?.name
      ?.split(" ")
      .map((el) => el.charAt(0)?.toUpperCase())
      ?.join("");
  }, [listDetail?.name]);

  //  methods
  const handleReorderListItem = async (listItems) => {
    try {
      await reorderListItems({ reorderedList: listItems }).unwrap();
    } catch (err) {
      toast.err(err?.data?.message ?? "Couldn't reorder list options");
    }
  };

  const handleOnDragEnd = (dragResult) => {
    const { destination, source, draggableId } = dragResult;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const draggedItem = listItems.find((el) => el._id === draggableId);

    const orderedListItems = listItems;
    orderedListItems.splice(source.index, 1);
    orderedListItems.splice(destination.index, 0, draggedItem);

    // overriding serial numbers
    const reorderedListItems = orderedListItems?.map((el, index) => ({
      ...el,
      serialNumber: `${serialNumberInitials}-${index + 1}`,
    }));

    setListItems(reorderedListItems);

    setTimeout(() => {
      const payload = [...reorderedListItems]?.map((el) => ({
        _id: el?._id,
        serialNumber: el?.serialNumber,
      }));

      handleReorderListItem(payload);
    }, 200);
  };

  const handleReset = () => {
    setSelectedOption(null);
  };

  const handleAddOption = async (data) => {
    if (!data) return;

    if (listOptions?.find((o) => o.value === data?.name))
      return toast.error(
        "Option name cannot be one of the predefined list name"
      );

    const payload = {
      ...data,
      isSubtype: true,
      level: listDetail.level ? listDetail.level + 1 : 1,
      maxLevel: listDetail?.maxLevel || 2,
      type: currentListType,
      ...(listDetail?._id && {
        parent: listDetail?._id,
      }),
      parentName: !listDetail?._id ? listDetail?.value : "",
    };

    try {
      await addList(payload).unwrap();
      toast.success("Option successfully added!");
      handleReset();
    } catch (err) {
      const errorMessage =
        err?.data?.message || "Couldn't add option to the list";
      toast.error(errorMessage);
    }
  };

  const handleToggleStatus = async (option) => {
    if (!option) return;

    const toggleOff = option.status === "Active";

    const payload = {
      ...option,
      id: option._id,
      status: toggleOff ? "Inactive" : "Active",
    };

    try {
      await editList(payload).unwrap();
      toast.success(
        `Option successfully ${toggleOff ? "deactivated" : "activated"}!`
      );
      handleReset();
    } catch (err) {
      const errorMessage =
        err?.data?.message ||
        `Couldn't ${toggleOff ? "deactivate" : "activate"} option`;
      toast.error(errorMessage);
    }
  };

  const handleOptionUpdate = async (data) => {
    if (!selectedOption) return;

    const payload = {
      ...selectedOption,
      id: selectedOption._id,
      ...data,
    };

    try {
      await editList(payload).unwrap();
      toast.success("Option successfully updated!");
      handleReset();
    } catch (err) {
      const errorMessage = err?.data?.message || "Couldn't update option";
      toast.error(errorMessage);
    }
  };

  React.useEffect(() => {
    const handleFetchListDetail = async () => {
      await fetchListDetail({ _id: listId }).unwrap();
    };

    handleFetchListDetail(listId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);

  React.useEffect(() => {
    if (!listDetail) return;
    // initializing with serial numbers
    const withSN = listDetail?.children?.map((el, index) => ({
      ...el,
      serialNumber: el?.serialNumber ?? `${serialNumberInitials}-${index + 1}`,
    }));
    setListItems(withSN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDetail?.children, serialNumberInitials]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      disablePaper
      isBusy={isBusy}
      error={error}
    >
      <ListOptionForm
        isBusy={isBusy}
        listOptionDetail={currentListOption}
        selectedOption={selectedOption}
        onSubmit={(data) =>
          selectedOption ? handleOptionUpdate(data) : handleAddOption(data)
        }
        onReset={handleReset}
      />

      <br />

      {!isLoadingListDetail ? (
        <div className="border">
          <AppDraggableList
            onDragEnd={handleOnDragEnd}
            listItems={listItems}
            draggableItem={({ data }) => (
              <ListOption
                isBusy={isBusy}
                listOption={data}
                onSelectOption={setSelectedOption}
                onToggleOptionStatus={handleToggleStatus}
                onViewSubOptions={() =>
                  navigate(
                    `/app/list/detail/${data?._id}?type=${[
                      searchParams.get("type"),
                      data?.name,
                    ]}`
                  )
                }
              />
            )}
          />
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap={0.25}>
          {new Array(4)?.fill(null)?.map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={60} />
          ))}
        </Box>
      )}
    </WithHeader>
  );
};

export default ListDetailScreen;
