import React from "react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";
import RichTextEditor from "../../../../components/Shared/RichTextEditor";

const WorkflowForm = ({
  workflowDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const navigate = useNavigate();

  const workflowTypes = ["Transaction", "Application"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: workflowDetail?.title ?? "",
      content: workflowDetail?.content ?? "",
      isActive: workflowDetail?.isActive ?? true,
      workflowType: workflowDetail?.workflowType ?? "Transaction",
    },
  });

  React.useEffect(() => {
    if (!workflowDetail) return;

    reset({ ...workflowDetail });
  }, [workflowDetail, reset]);

  return (
    <LoadingWrapper loading={isBusy}>
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="workflowType"
              control={control}
              rules={{ required: "Please select atleast one visibility" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-type">Type</InputLabel>
                  <Select
                    labelId="select-type"
                    label="Type"
                    value={field.value}
                    {...field}
                  >
                    {workflowTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.visibility && (
              <p className="error">{errors.visibility.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="isActive"
              control={control}
              render={({ field, value }) => (
                <FormControlLabel
                  control={
                    <Switch
                      name="isActive"
                      color="primary"
                      checked={field.value}
                      value={value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <div
              style={{ color: "gray", fontSize: 15, marginBottom: ".75rem" }}
            >
              Content
            </div>
            <Controller
              name="content"
              control={control}
              rules={{ required: "Content is required" }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  initialValue={
                    workflowDetail?.content
                      ? workflowDetail.content
                      : "<p>Enter Workflow Detail.</p>"
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.content && (
              <p className="error">{errors.content.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {workflowDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default WorkflowForm;
