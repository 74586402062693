const filterArrayByKeys = (array = [], comparable = "", ...keys) => {
  const itemIsIncluded = (object, key) =>
    key
      ?.split(".")
      ?.reduce((acc, el) => (acc && acc[el] ? acc[el] : null), object)
      ?.toLowerCase()
      ?.includes(comparable.toLowerCase());

  return array.filter((item) => keys.some((key) => itemIsIncluded(item, key)));
};

export default filterArrayByKeys;
