import React from "react";

import { useRef } from "react";
import { CircularProgress } from "@mui/material";

import useCloudinaryUpload from "../../hooks/useCloudinaryUpload";

const UploadImage = ({ imageUrl, onUploadSuccess = () => {} }) => {
  const ref = useRef();

  // const [uploading, setUploading] = React.useState(false);
  const [previewImageUrl, setPreviewImageUrl] = React.useState(null);

  const { uploadToCloudinary, isUploading } = useCloudinaryUpload();

  const handleDocumentUpload = async (e) => {
    const {
      data: { secure_url },
    } = await uploadToCloudinary(e.target.files[0]);

    onUploadSuccess(secure_url);

    setPreviewImageUrl(secure_url);
  };

  React.useEffect(() => {
    if (!imageUrl) return;
    setPreviewImageUrl(imageUrl);

    return () => {
      setPreviewImageUrl("");
    };
  }, [imageUrl]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        // paddingTop: "100%",
        border: "1px solid gray",
        borderRadius: ".5rem",
        borderStyle: "dashed",
        overflow: "hidden",
      }}
    >
      {isUploading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            inset: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              inset: 0,
              //   background: "#000",
              //   opacity: 0.1,
            }}
          ></div>
          <CircularProgress color="primary" size={40} />
        </div>
      ) : (
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
              zIndex: 1,
              padding: ".5rem 1rem",
              borderRadius: ".25rem",
              cursor: "pointer",
              background: "#000",
              opacity: 0.8,
              color: "white",
              fontSize: 12,
            }}
            onClick={() => {
              ref.current.click();
            }}
          >
            {previewImageUrl ? "Change" : "Upload"}
          </div>
          {previewImageUrl && (
            <img
              src={previewImageUrl}
              alt="Uploaded"
              style={{
                position: "absolute",
                inset: 0,
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}
          <input
            ref={ref}
            accept="image/*"
            style={{ display: "none" }}
            id="image-file"
            multiple
            type="file"
            onChange={handleDocumentUpload}
          />
        </div>
      )}
    </div>
  );
};

export default UploadImage;
