import { baseApi } from "./baseApi";

const WORKFLOW_BASE_URL = "/workflows";

export const workflowApi = baseApi.injectEndpoints({
  tagTypes: ["Workflow"],

  endpoints: (builder) => ({
    getWorkflowList: builder.query({
      query: (params) => ({
        url: `${WORKFLOW_BASE_URL}`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Workflow", _id })),
              { type: "Workflow", id: "LIST" },
            ]
          : [{ type: "Workflow", id: "LIST" }],
    }),

    getWorkflowDetail: builder.query({
      query: (id) => `${WORKFLOW_BASE_URL}/${id}`,
      providesTags: (result, error, id) => [
        { type: "Workflow", id },
        { type: "Workflow", id: "LIST" },
      ],
    }),

    createWorkflow: builder.mutation({
      query: (payload) => ({
        url: `${WORKFLOW_BASE_URL}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: () => [{ type: "Workflow", id: "LIST" }],
    }),

    updateWorkflow: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${WORKFLOW_BASE_URL}/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Workflow", _id },
        { type: "Workflow", id: "LIST" },
      ],
    }),

    // workflow steps api
    addStepToWorkflow: builder.mutation({
      query: (payload) => {
        const { workflowId, ...body } = payload;

        return {
          url: `${WORKFLOW_BASE_URL}/${workflowId}/steps`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Workflow", _id },
        { type: "Workflow", id: "LIST" },
      ],
    }),

    updateWorkflowStep: builder.mutation({
      query: (payload) => {
        const { workflowId, _id, ...body } = payload;

        return {
          url: `${WORKFLOW_BASE_URL}/${workflowId}/steps/${_id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Workflow", _id },
        { type: "Workflow", id: "LIST" },
      ],
    }),

    // reOrder Steps
    reOrderWorkflowSteps: builder.mutation({
      query(payload) {
        const { workflowId, ...body } = payload;
        return {
          url: `${WORKFLOW_BASE_URL}/${workflowId}/steps/re-order`,
          method: "PUT",
          body,
        };
      },

      invalidatesTags: (result, error, { _id }) => [
        { type: "Workflow", _id },
        { type: "Workflow", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetWorkflowListQuery,
  useLazyGetWorkflowListQuery,
  useGetWorkflowDetailQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,

  useAddStepToWorkflowMutation,
  useUpdateWorkflowStepMutation,
  useReOrderWorkflowStepsMutation,
} = workflowApi;
