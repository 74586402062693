import React from "react";
import { Box, Typography } from "@mui/material";
import { FiberManualRecord, StickyNote2Outlined } from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParse from "html-react-parser";

const NoteListItem = ({
  note,
  hasActions = false,
  noteActions: NoteActions,
}) => {
  return (
    <>
      <Box display="flex" alignItems="start" gap={2} paddingY={3}>
        <StickyNote2Outlined
          color="primary"
          fontSize="small"
          style={{ marginTop: 3 }}
        />
        <Box display="flex" flexGrow={1} flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography>{note?.createdBy.name}</Typography>

            <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

            <Typography style={{ color: "var(--primary)" }}>
              {note?.type}
            </Typography>

            <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

            <Typography style={{ color: "gray" }}>
              {`${moment(note?.createdAt).format("MMM D, YYYY")} at ${moment(
                note?.createdAt
              ).format("HH:MM:ss a")}`}
            </Typography>
          </Box>

          <Box>{ReactHtmlParse(note.content)}</Box>
        </Box>

        {hasActions && <NoteActions />}
      </Box>
    </>
  );
};

export default NoteListItem;
