import { baseApi } from "./baseApi";

const LEAD_BASE_URL = "/leads";

export const leadApi = baseApi.injectEndpoints({
  tagTypes: ["Lead"],

  endpoints: (builder) => ({
    getLeadList: builder.query({
      query: (params) => ({
        url: `${LEAD_BASE_URL}/list`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Lead", _id })),
              { type: "Lead", id: "LIST" },
            ]
          : [{ type: "Lead", id: "LIST" }],
    }),

    getLeadDetail: builder.query({
      query: (id) => `${LEAD_BASE_URL}/edit/${id}`,
      providesTags: (result, error, id) => [
        { type: "Lead", id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    createLead: builder.mutation({
      query: (payload) => ({
        url: `${LEAD_BASE_URL}/create`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", id: "LIST" },
      ],
    }),

    updateLead: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/edit/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    addNoteToLead: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/details/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    updateLeadNote: builder.mutation({
      query: (payload) => {
        const { leadId, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/details/${leadId}/notes`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    updateLeadQualificationCriteria: builder.mutation({
      query: (payload) => {
        const { leadId, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/details/${leadId}/criteria`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    getLeadsByNextAction: builder.query({
      query: () => `${LEAD_BASE_URL}/next-actions`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Lead", _id })),
              { type: "Lead", id: "LIST" },
            ]
          : [{ type: "Lead", id: "LIST" }],
    }),

    getLeadsByNoRecentActivity: builder.query({
      query: (queryParams) => {
        return {
          url: `${LEAD_BASE_URL}/recent-activities`,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Lead", _id })),
              { type: "Lead", id: "LIST" },
            ]
          : [{ type: "Lead", id: "LIST" }],
    }),

    addFavouriteCourseToLead: builder.mutation({
      query: (payload) => {
        const { leadId, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/${leadId}/favourite-course`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    removeLeadFavouriteCourse: builder.mutation({
      query: (payload) => {
        const { leadId, _id, ...body } = payload;

        return {
          url: `${LEAD_BASE_URL}/${leadId}/favourite-course/${_id}`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Lead", _id },
        { type: "Lead", id: "LIST" },
      ],
    }),

    addLeadStudyOption: builder.mutation({
      query: ({ leadId, ...body }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/study-options`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Lead", _id }],
    }),

    removeLeadStudyOption: builder.mutation({
      query: ({ leadId, studyOption }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/study-options/${studyOption}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Lead", _id }],
    }),

    updateLeadSelectedStudyOption: builder.mutation({
      query: ({ leadId, studyOptionId, ...body }) => ({
        url: `${LEAD_BASE_URL}/${leadId}/study-options/${studyOptionId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Lead", _id }],
    }),
  }),
});

export const {
  useGetLeadDetailQuery,
  useGetLeadListQuery,
  useLazyGetLeadListQuery,
  useCreateLeadMutation,
  useUpdateLeadMutation,

  useAddNoteToLeadMutation,
  useUpdateLeadNoteMutation,

  useUpdateLeadQualificationCriteriaMutation,

  useGetLeadsByNextActionQuery,
  useGetLeadsByNoRecentActivityQuery,

  useAddFavouriteCourseToLeadMutation,
  useRemoveLeadFavouriteCourseMutation,

  useAddLeadStudyOptionMutation,
  useRemoveLeadStudyOptionMutation,
  useUpdateLeadSelectedStudyOptionMutation,
} = leadApi;
