import React from "react";

import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Add, InfoOutlined } from "@mui/icons-material";

import AppTable from "../../../../components/Shared/AppTable";
import WithHeader from "../../../../layouts/WithHeader";

import { useListAccountEntityUserQuery } from "../../../../rtkStore/services/userApi";

import { useAuth } from "../../../../hooks/useAuth";

const AccountUserListScreen = () => {
  const { viewEntity } = useAuth();

  const breadcrumbs = [
    {
      title: "Users",
    },
  ];

  // RTK Query
  const {
    data: users,
    isLoading: loadingUsers,
    error,
  } = useListAccountEntityUserQuery({
    entityId: viewEntity?.entity?._id,
    status: "Active",
  });

  // const getUserRoles = (user) => {
  //   const filteredEntities = user?.entities
  //     ?.filter((entity) => entity?.entity?._id === viewEntity?.entity?._id)
  //     ?.map((el) => el?.authorizations)
  //     ?.map((auth) => auth);

  //   console.log(filteredEntities);
  // };

  const columns = [
    { name: "Name", selector: (row) => row?.name, sortable: true },
    { name: "Email", selector: (row) => row?.email, sortable: true },
    // {
    //   name: "Roles",
    //   selector: (row) => getUserRoles(row),
    // },
    {
      name: "Actions",
      selector: (row) => (
        <Link to={`/app/user/account/${row._id}/detail`}>
          <InfoOutlined color="primary" />
        </Link>
      ),
      right: true,
    },
  ];

  const PageActions = () => (
    <Box display="flex" gap={2}>
      <Link to="/app/user/account/add">
        <Button startIcon={<Add />} variant="contained">
          New User
        </Button>
      </Link>
    </Box>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUsers}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <AppTable columns={columns} data={users} progressPending={loadingUsers} />
    </WithHeader>
  );
};

export default AccountUserListScreen;
