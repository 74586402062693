import { Avatar, Box, Modal, Typography } from "@mui/material";
import React from "react";
import { useKnowledgebaseContext } from "../../../context/KBContext";
import { formatDate } from "../../../utils/handleFormatDate";
import { KnowledgebaseModalBlog } from './KnowledgebaseDetail/KnowledgebaseModalBlog';
import { CustomModal, ModalBody, ModalHeader } from "../../../components/Shared/CustomModal";

export const KBVersionScreen = () => {
  // states
  const [isArticleModalActive, setIsArticleModalActive] = React.useState(false);
  const [selectedArticleVersion, setSelectedArticleVersion] =
    React.useState(null);

  // hooks
  const { state: articleState } = useKnowledgebaseContext();

  const { detailedArticleInformation } = articleState;

  if (!detailedArticleInformation) return <></>;

  return (
    <div>
      {detailedArticleInformation?.versions?.length === 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            color: "#435883",
            maxWidth: "100%",
            minHeight: "200px",
            backgroundColor: "white",
            borderRadius: "5px",
            marginBottom: "12px",
            cursor: "pointer",
            padding: "1rem",
            border: "1px solid #f1f1f1",
            "&:hover": {
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
            },
          }}
        >
          <Box textAlign="center">
            <i
              className="fa-solid fa-clock-rotate-left"
              style={{ fontSize: 20 }}
            ></i>
            <Typography variant="subtitle1" style={{ fontSize: 18 }}>
              No document summary versions available
            </Typography>
          </Box>
        </Box>
      )}

      {detailedArticleInformation?.versions &&
        [...detailedArticleInformation?.versions.map((x) => ({ ...x }))]
          ?.sort((a, b) => b.version - a.version)
          ?.map((article) => (
            <Box
              key={`article-${article._id}`}
              sx={{
                maxWidth: "100%",
                backgroundColor:
                  article?.status === "Inactive" ? "#f1f1f1" : "white",
                borderRadius: "5px",
                marginBottom: "12px",
                cursor: "pointer",
                padding: "1rem",
                border:
                  article?.status === "Inactive"
                    ? "1px solid #dedede"
                    : "1px solid #f1f1f1",
                "&:hover": {
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
                },
              }}
              onClick={() => {
                setSelectedArticleVersion(article);
                setIsArticleModalActive(true);
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  style={{ width: 36, height: 36, marginRight: "1rem" }}
                  // src="https://images6.alphacoders.com/771/thumb-1920-771840.jpg"
                  src={`https://ui-avatars.com/api/?rounded=true&name=${article?.author?.name}&background=3F51B5&color=fff`}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    marginRight: "6px",
                  }}
                >
                  {article?.updatedBy?.name || article?.author?.name}
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "#435883",
                  }}
                >
                  updated the article on &nbsp;
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    color: "#435883",
                  }}
                >
                  {formatDate(article?.createdAt)}
                </Typography>
              </Box>

              <Box marginTop={2}>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginBottom={1}
                    sx={{ gap: "8px" }}
                  >
                    {article?.category?.name && (
                      <>
                        <Typography
                          className="c-label"
                          variant="subtitle1"
                          style={{ fontSize: 16 }}
                        >
                          {article?.category?.name}
                        </Typography>

                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "400",
                            fontSize: 14,
                            color: "gray",
                          }}
                        >
                          &bull;
                        </Typography>
                      </>
                    )}

                    {article?.subCategory && (
                      <>
                        <Typography
                          variant="subtitle1"
                          className="c-label"
                          style={{ fontSize: 16 }}
                        >
                          {article?.subCategory?.name}
                        </Typography>

                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "400",
                            fontSize: 14,
                            color: "gray",
                          }}
                        >
                          &bull;
                        </Typography>
                      </>
                    )}

                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: 14,
                        color:
                          article?.status === "Active"
                            ? // ? "#3F51B5"
                            "#ff5555"
                            : article?.status === "Inactive"
                              ? "red"
                              : "#E0E0E0",
                        fontWeight: 400,
                      }}
                    >
                      {article?.status}
                    </Typography>

                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "gray",
                      }}
                    >
                      &bull;
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#3F51B5",
                      }}
                    >
                      V{article.version}
                    </Typography>
                  </Box>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "400",
                      fontSize: 16,
                      color: "#435883",
                    }}
                  >
                    {article?.title}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  marginTop={2}
                  sx={{ gap: "1rem" }}
                ></Box>
              </Box>
            </Box>
          ))}

      <CustomModal
        open={isArticleModalActive}
        onClose={() => {
          setIsArticleModalActive(false);
        }}
        classNames="article-version-modal"
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
            <Avatar
              style={{ width: 36, height: 36 }}
              src={`https://ui-avatars.com/api/?rounded=true&name=${detailedArticleInformation?.versions[0]?.author?.name}&background=3F51B5&color=fff`}
            />

            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 16,
                  marginRight: "6px",
                }}
              >
                {detailedArticleInformation?.versions[0]?.author?.name}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 13,
                  color: "#435883",
                }}
              >
                {formatDate(
                  detailedArticleInformation?.versions[0]?.publishedDate
                )}
              </Typography>
            </Box>
          </Box>
        </ModalHeader>


        <ModalBody>
          <KnowledgebaseModalBlog
            article={selectedArticleVersion}
            showAuthorInfo={false}
          />
        </ModalBody>
      </CustomModal>
    </div>
  );
};
