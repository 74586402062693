import React from "react";

import DataTable from "react-data-table-component";

import CustomLoader from "../../../components/Shared/CustomLoader";
import WithHeader from "../../../layouts/WithHeader";

import { useListEntityAuthorizationQuery } from "../../../rtkStore/services/authorizationApi";

const EntityLvlAuthorizationListScreen = () => {
  const breadcrumbs = [{ title: "Authorizations" }];

  //   RTK Query
  const {
    data: authorizations,
    isLoading: loadingAuthorizations,
    error,
  } = useListEntityAuthorizationQuery("Active");

  const columns = [
    {
      name: "Level",
      selector: (row) => row?.level ?? "N/A",
    },
    {
      name: "Type",
      selector: (row) => row?.type ?? "N/A",
    },
    {
      name: "Role",
      selector: (row) => row?.role ?? "N/A",
    },
  ];

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingAuthorizations}
      error={error}
    >
      <DataTable
        columns={columns}
        data={authorizations}
        progressPending={loadingAuthorizations}
        progressComponent={<CustomLoader />}
        persistTableHead={true}
      />
    </WithHeader>
  );
};

export default EntityLvlAuthorizationListScreen;
