import { Box } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import { knowledgebaseApi } from "../../../rtkStore/services/knowledgebaseApi";
import { useInfiniteRTKQuery } from "../../../hooks/useInfiniteRTKQuery";
import { KBListScreenHeader } from "./KBComponents/KBListScreenHeader";
import { EmptyStateUI } from "./KBComponents/EmptyStateUI";
import { KBDocumentItem } from "./KnowledgebaseDetail/KBDocumentItem";
import { GenericListSkeleton } from "../../../components/Shared/GenericListSkeleton";

const perPage = 16;

export const KBListScreen = () => {
  // states

  // hooks
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = React.useState(() => ({
    queryFilters: {
      ...(searchParams.get("status") && {
        status: searchParams.get("status"),
      }),
      search: searchParams.get("search") || '',
      ...(searchParams.get("publishStatus") !== "undefined" &&
        searchParams.get("publishStatus") && {
        publishStatus: searchParams.get("publishStatus"),
      }),
    },
  }));

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    // updateSingleItemLocally,
  } = useInfiniteRTKQuery(knowledgebaseApi.endpoints.listKnowledgebase, {
    perPage,
    filters,
    isMinimal: true,
  });

  const getURLParams = React.useCallback(() => {
    const params = {};
    for (const entry of searchParams.entries()) {
      params[entry[0]] = entry[1];
    }
    return params;
  }, [searchParams]);

  // responsible for changing queryFilters object inside filter object
  const onQueryFilterChange = (data) => {
    if (data?.value !== "undefined") {
      const newFilters = { ...filters };
      newFilters.queryFilters = {
        ...newFilters.queryFilters,
        [data.filter]: data.value,
        // [data.filter]:
        //   data?.filter === "isPublished" && !data?.value ? false : data.value,
      };

      setFilters({ ...newFilters });
      setSearchParams({
        ...getURLParams(),
        [data.filter]: data.value,
      });
    } else {
      let newFilters = { ...filters };
      newFilters = {
        ...newFilters,
        queryFilters: {
          ...newFilters.queryFilters,
          [data.filter]: undefined,
        },
      };
      setFilters({ ...newFilters });

      const previousUrlParams = getURLParams();
      delete previousUrlParams[data.filter];

      setSearchParams({
        ...previousUrlParams,
      });
    }
  };

  // responsible for directly changing filters inside filters object, not inside queryFilters
  const onDirectFilterObjChange = (data) => {
    if (data?.value !== "undefined") {
      let newFilters = { ...filters };

      newFilters = {
        ...newFilters.queryFilters,
        [data.filter]: data.value,
      };

      setFilters({ ...newFilters });
    } else {
      let newFilters = { ...filters };
      newFilters = {
        ...newFilters,
        [data.filter]: undefined,
      };
      setFilters({ ...newFilters });
    }
  };

  return (
    <Box>
      <KBListScreenHeader
        onQueryFilterChange={onQueryFilterChange}
        onDirectFilterObjChange={onDirectFilterObjChange}
      />

      <Box marginTop={8}>
        <Box>
          <InfiniteScroll
            style={{ overflowY: "hidden" }}
            dataLength={data ? data.length : perPage}
            next={() => fetchNextPage({ perPage, filters })}
            hasMore={hasNextPage}
            scrollThreshold={0.9}
            endMessage={<></>}
          >

            {!isLoading && !isFetching && data && data?.length === 0 && (
              <Box>
                <EmptyStateUI
                  title="No Knowledgebase Articles Available"
                  subtitle="Consider adding an article"
                />
              </Box>
            )}

            {!isLoading &&
              data &&
              data.map((article) => (
                <KBDocumentItem
                  key={`list-${article._id}`}
                  article={article}
                />
              ))}

            {isFetching && (
              <Box marginTop="-2.5rem">
                <GenericListSkeleton number={8} />
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};
