import { baseApi } from "./baseApi";

const REPORT_BASE_URL = "/reports";

const __generateApiUrl = (path) => {
  return REPORT_BASE_URL + path;
};

export const reportApi = baseApi.injectEndpoints({
  tagTypes: ["Report"],

  endpoints: (builder) => ({
    // lead
    getAgentLeadStatusReportData: builder.query({
      query: (params) => ({ url: __generateApiUrl("/agents/leads"), params }),
      providesTags: (_) => [{ type: "Report", id: "AGENT_LEADS" }],
    }),

    // transaction
    listWorkflowTransactions: builder.query({
      query: () => __generateApiUrl("/workflow-transactions"),
      providesTags: (_) => [{ type: "Report", id: "TRANSACTION" }],
    }),

    getWorkflowTransactionReportData: builder.query({
      query: (workflowId) =>
        __generateApiUrl(`/workflow-transactions/${workflowId}`),
      providesTags: (_) => [{ type: "Report", id: "TRANSACTION" }],
    }),

    // application
    listWorkflowApplications: builder.query({
      query: () => __generateApiUrl("/workflow-applications"),
      providesTags: (_) => [{ type: "Report", id: "APPLICATION" }],
    }),

    getWorkflowApplicationReportData: builder.query({
      query: (workflowId) =>
        __generateApiUrl(`/workflow-applications/${workflowId}`),
      providesTags: (_) => [{ type: "Report", id: "APPLICATION" }],
    }),
  }),
});

export const {
  useGetAgentLeadStatusReportDataQuery,
  useLazyGetAgentLeadStatusReportDataQuery,

  useListWorkflowTransactionsQuery,
  useGetWorkflowTransactionReportDataQuery,

  useListWorkflowApplicationsQuery,
  useGetWorkflowApplicationReportDataQuery,
} = reportApi;
