import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import ExpandedRow from "../../../components/Shared/ExpandedRow";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyGetBlogListQuery } from "../../../rtkStore/services/blogApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const PageActions = () => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
      }}
    >
      <Link to="/app/blog/create">
        <Button startIcon={<AddIcon />} variant="contained">
          New Blog
        </Button>
      </Link>
    </div>
  );
};

const BlogListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Blogs",
    },
  ];

  const blogListTabs = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  // RTK Query
  const [
    listBlogs,
    {
      data: blogList,
      isLoading: loadingBlogs,
      isFetching: fetchingBlogs,
      error,
    },
  ] = useLazyGetBlogListQuery();

  const filterableBlogs = filterArrayByKeys(blogList, filterText, "title");

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <a href={row?.image} target="_blank" rel="noreferrer">
          <img
            src={`${row?.image}?w=248&h=248fit=crop&auto=format`}
            style={{
              height: 60,
              width: 60,
              borderRadius: ".25rem",
              border: "1px solid lightgray",
              objectFit: "cover",
              marginTop: ".25rem",
            }}
            alt={row?.title}
          />
        </a>
      ),
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <div title={row?.title} className="line-clamp-2">
          {row?.title}{" "}
        </div>
      ),
      minWidth: "250px",
      sortable: true,
    },
    {
      name: "Author",
      selector: (row) => row?.author,
    },
    {
      name: "Featured",
      selector: (row) => row?.featured,
      cell: (row) =>
        row.isFeatured ? (
          <DoneIcon style={{ color: "green" }} />
        ) : (
          <CloseIcon style={{ color: "red" }} />
        ),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category?.name ?? "N/A",
    },
    {
      name: "Tags",
      selector: (row) => row.tags,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/blog/${row._id}/detail`}>
            <IconButton size="small" aria-label="View Blog">
              <InfoOutlined color="primary" />
            </IconButton>
          </Link>
          <Link to={`/app/blog/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Blog">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter by Title"
          value={filterText ? filterText : ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    listBlogs({ active: activeTab !== "Inactive" });
  }, [activeTab, listBlogs]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      hasActions
      headerActions={<PageActions />}
      error={error}
    >
      <CustomTabs tabList={blogListTabs} activeTab={activeTab} />

      <AppTable
        columns={columns}
        data={filterableBlogs}
        progressPending={loadingBlogs || fetchingBlogs}
        subHeader
        subHeaderComponent={subHeaderComponent}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedRow>{ReactHtmlParser(data?.content ?? "N/A")}</ExpandedRow>
        )}
      />
    </WithHeader>
  );
};

export default BlogListScreen;
