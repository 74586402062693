import { baseApi } from "./baseApi";

const LIST_BASE_URL = "/global-list";

export const globalListSlice = baseApi.injectEndpoints({
  tagTypes: ["GLOBAL_LIST"],
  endpoints: (build) => ({
    addGlobalList: build.mutation({
      query(body) {
        return {
          url: `${LIST_BASE_URL}/add`,
          method: "POST",
          body,
        };
      },

      invalidatesTags: (result, err) => [{ type: "GLOBAL_LIST", id: "LIST" }],
    }),

    listGlobalList: build.query({
      query: (params) => {
        return {
          url: `${LIST_BASE_URL}`,
          params,
        };
      },
      providesTags: (result) =>
        // is result available?
        result && Array.isArray(result)
          ? // successful query
            [
              ...result.map(({ _id }) => ({ type: "GLOBAL_LIST", id: _id })),
              { type: "GLOBAL_LIST", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: '', id: 'LIST' }` is invalidated
            [{ type: "GLOBAL_LIST", id: "LIST" }],
    }),

    detailGlobalList: build.query({
      query: (params) => {
        return {
          url: `${LIST_BASE_URL}/detail/${params._id}`,
          params,
        };
      },
      providesTags: (result, error, id) => [
        { type: "GLOBAL_LIST", id },
        { type: "GLOBAL_LIST", id: "LIST" },
      ],
    }),

    editGlobalList: build.mutation({
      query: (params) => {
        return {
          url: `${LIST_BASE_URL}/edit/${params.id}`,
          body: params,
          method: "PUT",
        };
      },
      invalidatesTags: (result, err) => [{ type: "GLOBAL_LIST", id: "LIST" }],
    }),

    reOrderGlobalList: build.mutation({
      query(payload) {
        return {
          url: `${LIST_BASE_URL}/reorder`,
          method: "PUT",
          body: payload,
        };
      },

      invalidatesTags: (result, err) => [{ type: "GLOBAL_LIST", id: "LIST" }],
    }),
  }),
});

export const {
  useListGlobalListQuery,
  useLazyListGlobalListQuery,
  useDetailGlobalListQuery,
  useLazyDetailGlobalListQuery,
  useAddGlobalListMutation,
  useEditGlobalListMutation,

  useReOrderGlobalListMutation,
} = globalListSlice;
