import { baseApi } from "./baseApi";

export const knowledgebaseApi = baseApi.injectEndpoints({
  tagTypes: ["Knowledgebase"],
  endpoints: (build) => ({
    listQMSDashboardStats: build.query({
      query: (params) => {
        return {
          url: `/knowledgebase/dashboard-stats`,
          params,
        };
      },
      // Provides a list of `Knowledgebases` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Cases` element was added.
      providesTags: (result) => {
        // is result available?
        return [{ type: "Knowledgebase", id: "DASHBOARD_STATS" }];
      },
    }),
    listKnowledgebase: build.query({
      query: (params) => {
        return {
          url: `/knowledgebase/list`,
          params,
        };
      },
      // Provides a list of `Knowledgebases` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Cases` element was added.
      providesTags: (result) => {
        // is result available?
        return result
          ? // successful query
          [
            ...result.map(({ id }) => ({ type: "Knowledgebase", id })),
            { type: "Knowledgebase", id: "LIST" },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: '', id: 'LIST' }` is invalidated
          [{ type: "Knowledgebase", id: "LIST" }];
      },
    }),

    addKnowledgebase: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/add`,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Knowledgebase could show up in any lists.
      invalidatesTags: [{ type: "Knowledgebase", id: "LIST" }],
    }),

    detailKnowledgebase: build.query({
      query: (params) => {
        return {
          url: `/knowledgebase/detail/${params.id}`,
          params,
        };
      },
      // Provides a list of `Knowledgebases` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Cases` element was added.
      providesTags: (result, err, { id }) => {
        // is result available?
        return result
          ? // successful query
          [{ type: "Knowledgebase", id: id }]
          : // an error occurred, but we still want to refetch this query when `{ type: '', id: 'LIST' }` is invalidated
          [{ type: "Knowledgebase", id: id }];
      },
    }),

    editKnowledgebase: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/edit/${body.id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all put-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Knowledgebase could show up in any lists.
      invalidatesTags: (result) => [
        { type: "Knowledgebase", id: "LIST" },
        { type: "Knowledgebase", id: result?._id },
      ],
    }),

    basicEditKnowledgebase: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/edit/${body.id}/basic`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all put-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Knowledgebase could show up in any lists.
      invalidatesTags: (result) => [
        { type: "Knowledgebase", id: "LIST" },
        { type: "Knowledgebase", id: result?._id },
      ],
    }),
    addKnowledgebaseTags: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/${body.id}/tag`,
          method: "POST",
          body,
        };
      },
      // Invalidates all put-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created Knowledgebase could show up in any lists.
      invalidatesTags: (result) => [{ type: "Knowledgebase", id: "LIST" }],
    }),
    flagKnowledgebaseArticle: build.mutation({
      query: (body) => ({
        url: `/knowledgebase/${body.id}/flag`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result) => {
        return [
          { type: "Knowledgebase", id: "LIST" },
          { type: "Knowledgebase", id: "COMMENT" },
          { type: "Knowledgebase", id: result?._id },
        ];
      },
    }),
    deleteKnowledgebaseAttachment: build.mutation({
      query: (queryParams) => ({
        url: `/knowledgebase/${queryParams.id}/attachment`,
        method: "DELETE",
        params: queryParams,
      }),
      invalidatesTags: (result) => {
        return [
          { type: "Knowledgebase", id: "LIST" },
          { type: "Knowledgebase", id: result?._id },
        ];
      },
    }),
    listKnowledgebaseComments: build.query({
      query(params) {
        return {
          url: `/knowledgebase/${params._id}/comment`,
          method: "GET",
          params,
        };
      },
      providesTags: [{ type: "Knowledgebase", id: "COMMENT" }],
    }),
    commentOnKnowledgebase: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/${body._id}/comment`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        { type: "Knowledgebase", id: "COMMENT" },
        { type: "Knowledgebase", id: "LIST" },
      ],
    }),
    updateCommentOnKnowledgebase: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/${body._id}/comment/${body.commentId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [{ type: "Knowledgebase", id: "COMMENT" }],
    }),
    addKnowledgebaseAttachment: build.mutation({
      query(body) {
        return {
          url: `/knowledgebase/${body._id}/attachment`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (args) => {
        return [
          { type: "Knowledgebase", id: "Attachent" },
          { type: "Knowledgebase", id: "LIST" },
          { type: "Knowledgebase", id: args?._id },
        ];
      },
    }),
    editQMSMentions: build.mutation({
      query(data) {
        const { id, ...body } = data; // id is separated because we don't want to create a new object, just an update
        return {
          url: `/knowledgebase/edit/${id}/mention`,
          method: "PUT",
          body, //body without the _id property
        };
      },
      // Invalidates all queries that subscribe to this Case `id` only.
      // In this case, `detailCase` will be re-run. `listCase` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: "Knowledgebase", id },
          { type: "Knowledgebase", id: "LIST" },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useListKnowledgebaseQuery,
  useLazyListKnowledgebaseQuery,
  useDetailKnowledgebaseQuery,
  useLazyDetailKnowledgebaseQuery,
  useAddKnowledgebaseMutation,
  useAddKnowledgebaseTagsMutation,
  useEditQMSMentionsMutation,
  useFlagKnowledgebaseArticleMutation,
  useEditKnowledgebaseMutation,
  useBasicEditKnowledgebaseMutation,
  useDeleteKnowledgebaseAttachmentMutation,
  useCommentOnKnowledgebaseMutation,
  useUpdateCommentOnKnowledgebaseMutation,
  useAddKnowledgebaseAttachmentMutation,
  useListKnowledgebaseCommentsQuery,
  useLazyListKnowledgebaseCommentsQuery,
  useListQMSDashboardStatsQuery,
} = knowledgebaseApi;
