import React from "react";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { Add, NoteAltOutlined } from "@mui/icons-material";

import NextActionNoteForm from "../../../../../components/Note/NextActionNoteForm";
import NoteList from "../../../../../components/Note/NoteList";

import {
  useAddNoteMutation,
  useUpdateClientMutation,
  useUpdateNoteMutation,
} from "../../../../../rtkStore/services/clientApi";

import { useClientDetail } from "../ClientBasicInformationTab";

const ClientNotesTab = () => {
  const [showNoteForm, setShowNoteForm] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const { id: clientId } = useParams();

  const [searchParams] = useSearchParams();

  const {
    clientDetail: { notes },
  } = useClientDetail();

  //   RTK Query
  const [addNote, { isLoading: addingNote }] = useAddNoteMutation();

  const [updateNote, { isLoading: updatingNote }] = useUpdateNoteMutation();

  const [updateClientNextAction, { isLoading: updatingClientNextAction }] =
    useUpdateClientMutation();

  //   methods
  const handleCancel = () => {
    setShowNoteForm(false);
    setSelectedNote(null);
  };

  const handleAddNote = async (data) => {
    const { content, type, nextAction, nextActionDate } = data;

    setSelectedNote({ content, type });

    if (nextAction || nextActionDate) {
      const nextActionPayload = { _id: clientId, nextAction, nextActionDate };
      updateClientNextAction(nextActionPayload);
    }

    try {
      await addNote({ ...data, clientId }).unwrap();
      toast.success("Note successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add note");
    }
  };

  const handleNoteEdit = (note) => {
    setShowNoteForm(true);
    setSelectedNote(note);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateNote = async (data) => {
    try {
      await updateNote({ ...data, clientId }).unwrap();
      toast.success("Note successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update note");
    }
  };

  React.useEffect(() => {
    if (!searchParams) return;

    if (searchParams.get("addNote")) setShowNoteForm(true);
  }, [searchParams]);

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <NoteAltOutlined style={{ color: "gray" }} />

            <Typography style={{ fontWeight: 500 }}>Notes</Typography>
          </Box>

          {!showNoteForm && (
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() => setShowNoteForm(true)}
            >
              Note
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Collapse in={showNoteForm}>
        <NextActionNoteForm
          noteDetail={selectedNote}
          isBusy={addingNote || updatingNote || updatingClientNextAction}
          onSubmit={selectedNote ? handleUpdateNote : handleAddNote}
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <NoteList notes={notes ?? []} onNoteEdit={handleNoteEdit} />
    </>
  );
};

export default ClientNotesTab;
