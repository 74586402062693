import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import moment from "moment";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import AppTable from "../../../../../components/Shared/AppTable";
import TableFilters from "../../../../../components/Shared/AppTable/TableFilters";

import { selectLoggedInUser } from "../../../../../rtkStore/authSlice";
import { useGetLeadsByNoRecentActivityQuery } from "../../../../../rtkStore/services/leadApi";
import { getPluralWord } from "../../../../../utils/getFormattedString";
import { getDateDifference } from "../../../../../utils/getDateDifference";

const NoLeadsUpdates = ({ durationInDays = 7 }) => {
  const [showMyLeads, setShowMyLeads] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const loggedInUser = useSelector(selectLoggedInUser);

  const columns = [
    {
      name: "Lead",
      selector: (row) => (
        <Link
          to={`/app/lead/${row?._id}/detail`}
          className="hover:text-blue-600 hover:underline"
        >
          {`${row?.firstName} ${row?.lastName}`}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone ?? "N/A",
    },
    {
      name: "Stage",
      selector: (row) => row?.leadStage?.name ?? "N/A",
    },
    {
      name: "Responsible",
      selector: (row) => row?.responsibleUser?.name ?? "N/A",
    },
    {
      name: "Last Updated on",
      selector: (row) =>
        moment(row?.updatedAt)?.format("MMM DD, YYYY, hh:ss a"),
    },
    {
      name: "In Days",
      selector: (row) =>
        row?.updatedAt
          ? getPluralWord(
              "day",
              Math.abs(getDateDifference(row?.updatedAt)),
              "s"
            )
          : "N/A",
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link to={`/app/lead/${row?._id}/detail`}>
          <IconButton size="small" aria-label="Lead">
            <InfoOutlined color="primary" />
          </IconButton>
        </Link>
      ),
      ignoreRowClick: true,
      right: true,
      button: true,
    },
  ];

  //   RTK Query
  const { data: noUpdatesLeads, isLoading: loadingNextActionLeads } =
    useGetLeadsByNoRecentActivityQuery({ duration: durationInDays });

  const filteredLeads = React.useMemo(() => {
    return noUpdatesLeads?.filter((lead) =>
      showMyLeads ? lead?.responsible?._id === loggedInUser?._id : lead
    );
  }, [noUpdatesLeads, showMyLeads, loggedInUser]);

  return (
    <>
      <TableFilters
        filterText={filterText}
        filterTextPlaceholder="Filter by Name"
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyLeads}
        onShowMineChange={(e) => setShowMyLeads(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={
          filteredLeads?.filter(
            (item) =>
              item &&
              (item.firstName
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
                item.lastName.toLowerCase().includes(filterText.toLowerCase()))
          ) ?? []
        }
        progressPending={loadingNextActionLeads}
      />
    </>
  );
};

export default NoLeadsUpdates;
