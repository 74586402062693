import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Grid } from "@mui/material";
import {
  CategoryOutlined,
  LocationOnOutlined,
  SchoolOutlined,
  AccountCircleOutlined,
  OutlinedFlag,
  PriorityHighOutlined,
  EditOutlined,
  VerifiedOutlined,
  LanguageOutlined,
  InfoOutlined,
  AbcOutlined,
} from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import CustomTabs from "../../../components/Shared/CustomTabs";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import InformationTile from "../../../components/Shared/InformationTile";
import UniversityReviewsTab from "./universityComponents/UniversityReviewsTab";
import UniversityCoursesTab from "./UniversityCoursesTab";
import UniversityCampusAreas from "./universityComponents/UniversityCampusAreas";
import UniversityDetailSkeleton from "./universityComponents/UniversityDetailSkeleton";
import WithHeader from "../../../layouts/WithHeader";

import { useGetUniversityDetailQuery } from "../../../rtkStore/services/universityApi";

const UniversityDetailsScreen = () => {
  const [activeTab, setActiveTab] = React.useState("campusAreas");

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const breadcrumbs = [
    {
      title: "Universities",
      path: "/app/university/list",
    },
    { title: "University Detail" },
  ];

  // RTK Query
  const {
    data: universityDetail,
    isLoading: loadingUniversityDetail,
    error,
  } = useGetUniversityDetailQuery(id);

  const PageActions = () => (
    <Link to={`/app/university/${id}/edit`}>
      <Button variant="contained" startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  );

  const universityDetailTabs = [
    {
      label: "Campus Areas",
      value: "campus",
      badge: true,
      badgeCount: universityDetail?.campusAreas?.length,
    },
    {
      label: "Reviews",
      value: "reviews",
      badge: true,
      badgeCount: universityDetail?.reviews?.length,
    },
    {
      label: "Affiliated Courses",
      value: "courses",
      badge: true,
      badgeCount: universityDetail?.affiliatedCourses?.length,
    },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "campus");
  }, [searchParams]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUniversityDetail}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      {!loadingUniversityDetail ? (
        <>
          <Box padding={3}>
            <Grid container spacing={4}>
              <Grid item md={2}>
                <img
                  src={universityDetail?.logo}
                  alt="University Logo"
                  className="aspect-square w-full rounded border object-contain p-0.5"
                />
              </Grid>

              <Grid item md={10}>
                <Grid container spacing={5}>
                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <SchoolOutlined color="primary" />}
                      title="Institution Name"
                      subtitle={universityDetail?.title}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <CategoryOutlined color="primary" />}
                      title="Institution Type"
                      subtitle={universityDetail?.type?.name ?? "N/A"}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <LocationOnOutlined color="primary" />}
                      title="Head Office Address"
                      subtitle={universityDetail?.headOfficeAddress}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <AccountCircleOutlined color="primary" />}
                      title="Ownership"
                      subtitle={universityDetail?.ownership}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <OutlinedFlag color="primary" />}
                      title="Location"
                      subtitle={universityDetail?.location?.name}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <InfoOutlined color="primary" />}
                      title="Status"
                    >
                      <div
                        className={`uppercase ${
                          universityDetail?.isActive
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {universityDetail?.isActive ? "Active" : "Inactive"}
                      </div>
                    </InformationTile>
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <VerifiedOutlined color="primary" />}
                      title="Assessment Level"
                      subtitle={
                        universityDetail?.assessmentLevel?.name ?? "N/A"
                      }
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <PriorityHighOutlined color="primary" />}
                      title="Priority"
                      subtitle={universityDetail?.priority?.name ?? "N/A"}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <LanguageOutlined color="primary" />}
                      title="Website"
                    >
                      <a
                        href={universityDetail?.website}
                        target="_blank"
                        rel="noreferrer"
                        className="custom-link"
                      >
                        {universityDetail?.website ?? "N/A"}
                      </a>
                    </InformationTile>
                  </Grid>

                  <Grid item md={4}>
                    <InformationTile
                      icon={() => <AbcOutlined color="primary" />}
                      title="Provider Code"
                      subtitle={universityDetail?.providerCode ?? "N/A"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />

            <Grid container rowGap={3}>
              <Grid item md={12}>
                <InformationTile title="Overview">
                  {universityDetail?.institutionOverview
                    ? ReactHtmlParser(universityDetail?.institutionOverview)
                    : "N/A"}
                </InformationTile>
              </Grid>

              {universityDetail?.accommodation && (
                <Grid item md={12}>
                  <InformationTile title="Accommodation Detail">
                    {ReactHtmlParser(universityDetail?.accommodation)}
                  </InformationTile>
                </Grid>
              )}
            </Grid>
          </Box>

          <Divider />

          <CustomTabs tabList={universityDetailTabs} activeTab={activeTab} />

          <CustomTabPanel activeTab={activeTab} value="campus">
            <UniversityCampusAreas
              campusAreas={universityDetail?.campusAreas}
            />
          </CustomTabPanel>

          <CustomTabPanel activeTab={activeTab} value="courses">
            <UniversityCoursesTab
              universityCourses={universityDetail?.affiliatedCourses}
            />
          </CustomTabPanel>

          <CustomTabPanel activeTab={activeTab} value="reviews">
            <UniversityReviewsTab
              universityReviews={universityDetail?.reviews}
            />
          </CustomTabPanel>
        </>
      ) : (
        <UniversityDetailSkeleton />
      )}
    </WithHeader>
  );
};

export default UniversityDetailsScreen;
