
export const knowledgebaseStatusOptions = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Expired",
    value: "Expired",
  },
  {
    label: "Awaiting Approval",
    value: "Awaiting Approval",
  },
];

export const knowledgebaseVisibilityOptions = [
  // {
  //   label: "Visible to Author/Admin only",
  //   value: "Author/Admin",
  // },
  {
    label: "Visible to Teams/Users",
    value: "Team/Users",
  },
  {
    label: "Visible to Everyone",
    value: "Everyone",
  },
];

export const AttachmentHookIntendedForOptions = {
  CASE: "Case",
  KNOWLEDGEBASE: "Knowledgebase",
};

export const listOptions = [
  {
    name: "Knowledgebase Category",
    value: "Knowledgebase Category",
    maxLevel: 2,
  },
  {
    name: "Tag",
    value: "Tag",
    maxLevel: 1,
  },
  {
    name: "ELearning Category",
    value: "ELearning Category",
    maxLevel: 2,
  },
];

export const QMSModuleStatusOptions = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
];

export const QMSModuleDifficultyOptions = [
  {
    label: "Beginner",
    value: "Beginner",
  },
  {
    label: "Intermediate",
    value: "Intermediate",
  },
  {
    label: "Advanced",
    value: "Advanced",
  },
];
