import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import "./assets/css/knowledgebase.css";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";

import { store } from "./rtkStore";
import { KnowledgebaseProvider } from "./context/KBContext";
import { AppProvider } from "./context/AppContext";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <BrowserRouter>
      <Provider store={store}>
        <AppProvider>
          <KnowledgebaseProvider>
            <App />
          </KnowledgebaseProvider>
        </AppProvider>
      </Provider>
    </BrowserRouter>
    <ToastContainer hideProgressBar position="top-center" autoClose={2000} />
  </React.StrictMode >,

  document.getElementById("root")
);
