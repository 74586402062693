import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import {
  AccountCircleOutlined,
  DonutLargeOutlined,
  EmailOutlined,
  HttpOutlined,
  LocalPhoneOutlined,
} from "@mui/icons-material";

import GenericDetailSkeleton from "../../../components/Shared/Skeleton/GenericDetailSkeleton";
import InformationTile from "../../../components/Shared/InformationTile";
import WithHeader from "../../../layouts/WithHeader";

import { useAuth } from "../../../hooks/useAuth";
import { useDetailAccountQuery } from "../../../rtkStore/services/accountApi";

const AccountInfoScreen = () => {
  const { viewEntity } = useAuth();
  const accountId = viewEntity?.entity?.account?._id;

  const breadcrumbs = [
    {
      title: "Account Info",
    },
  ];

  // RTK Query
  const {
    data: accountDetail,
    isLoading: loadingAccountDetail,
    error,
  } = useDetailAccountQuery(accountId);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingAccountDetail}
      error={error}
    >
      {!loadingAccountDetail ? (
        <Box padding={3}>
          <Typography variant="h6">{accountDetail?.name}</Typography>
          <br />
          <Grid container spacing={4}>
            <Grid item md={4}>
              <InformationTile
                icon={() => <AccountCircleOutlined color="primary" />}
                title="Display Name"
                subtitle={accountDetail?.displayName}
              />
            </Grid>
            <Grid item md={4}>
              <InformationTile
                icon={() => <HttpOutlined color="primary" />}
                title="Domain"
                subtitle={accountDetail?.domain}
              />
            </Grid>
            <Grid item md={4}>
              <InformationTile
                icon={() => <EmailOutlined color="primary" />}
                title="Assistance Email"
                subtitle={accountDetail?.assistanceEmail}
              />
            </Grid>
            <Grid item md={4}>
              <InformationTile
                icon={() => <LocalPhoneOutlined color="primary" />}
                title="Assistance Phone"
                subtitle={accountDetail?.assistancePhone}
              />
            </Grid>
            <Grid item md={4}>
              <InformationTile
                icon={() => <DonutLargeOutlined color="primary" />}
                title="Status"
                subtitle={accountDetail?.status ? "Active" : "Inactive"}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <GenericDetailSkeleton />
      )}
    </WithHeader>
  );
};

export default AccountInfoScreen;
