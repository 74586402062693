import React from "react";
import { Box, Grid } from "@mui/material";
import {
  AccessTimeOutlined,
  BookmarkAddOutlined,
  InfoOutlined,
  KeyboardDoubleArrowRight,
  LooksOneOutlined,
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
  EventOutlined,
  AccountCircleOutlined,
  PersonOutlineOutlined,
  PublicOutlined,
  StairsOutlined,
  PeopleAltOutlined,
  CampaignOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import InformationTile from "../../../../../components/Shared/InformationTile";
import InfoGridSkeleton from "../../../../../components/Shared/Skeleton/InfoGridSkeleton";
import OpenInNewTab from "../../../../../components/Shared/OpenInNewTab";

import { useClientDetail } from "../ClientBasicInformationTab";

const ClientBasicDetail = () => {
  const { loadingClient, clientDetail } = useClientDetail();

  return !loadingClient && clientDetail ? (
    <Box padding={3}>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <InformationTile
            icon={() => <PersonOutlineOutlined color="primary" />}
            title="Name"
            subtitle={`${clientDetail?.firstName} ${clientDetail?.lastName}`}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EmailOutlined color="primary" />}
            title="Email"
          >
            {clientDetail?.email ? (
              <a href={`mailto:${clientDetail?.email}`} className="custom-link">
                {clientDetail?.email}
              </a>
            ) : (
              "N/A"
            )}
          </InformationTile>
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PublicOutlined color="primary" />}
            title="Nationality"
            subtitle={clientDetail?.country?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PhoneOutlined color="primary" />}
            title="Phone"
            subtitle={clientDetail.phone}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PersonOutlineOutlined color="primary" />}
            title="Alternative Name"
            subtitle={clientDetail?.alternativeName}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PeopleAltOutlined color="primary" />}
            title="Civil Status"
            subtitle={clientDetail?.civilStatus}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PhoneOutlined color="primary" />}
            title="Alternative Phone"
            subtitle={clientDetail.phone2}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <LocationOnOutlined color="primary" />}
            title="Address"
            subtitle={clientDetail.address}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EventOutlined color="primary" />}
            title="DOB"
            subtitle={
              clientDetail?.dob
                ? moment(clientDetail.dob)?.format("DD/MM/YYYY")
                : null
            }
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <KeyboardDoubleArrowRight color="primary" />}
            title="Next Action"
            subtitle={clientDetail?.nextAction?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccessTimeOutlined color="primary" />}
            title="Next Action Date"
            subtitle={
              clientDetail?.nextActionDate &&
              moment(clientDetail?.nextActionDate).format("MMM DD, YYYY")
            }
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <LooksOneOutlined color="primary" />}
            title="First Preference"
            subtitle={clientDetail?.firstPreference?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <StairsOutlined color="primary" />}
            title="Stage"
            subtitle={clientDetail?.stage?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <BookmarkAddOutlined color="primary" />}
            title="Connected Lead"
          >
            <>
              {clientDetail?.lead ? (
                <OpenInNewTab
                  label={clientDetail?.lead?.fullName}
                  link={`/app/lead/${clientDetail?.lead?._id}/detail`}
                />
              ) : (
                "N/A"
              )}
            </>
          </InformationTile>
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <CampaignOutlined color="primary" />}
            title="Agent Promotion"
            subtitle={clientDetail?.agentPromotion?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Responsible"
            subtitle={clientDetail?.responsible?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <InfoOutlined color="primary" />}
            title="Status"
          >
            <div
              className={`uppercase ${
                clientDetail?.isActive ? "text-green-600" : "text-red-600"
              }`}
            >
              {clientDetail?.isActive ? "Active" : "Inactive"}
            </div>
          </InformationTile>
        </Grid>
      </Grid>

      {clientDetail?.description && (
        <Box marginTop={2}>
          <div>{ReactHtmlParser(clientDetail.description)}</div>
        </Box>
      )}
    </Box>
  ) : (
    <InfoGridSkeleton />
  );
};

export default ClientBasicDetail;
