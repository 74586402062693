import React from "react";

import { Box, Skeleton } from "@mui/material";

const GenericDetailSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" padding={3} gap={3}>
      <Skeleton variant="rect" width="40%" height={50} />
      <Skeleton variant="rect" height={100} />
    </Box>
  );
};

export default GenericDetailSkeleton;
