import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "react-select";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { useCloudinary } from '../../../hooks/useCloudinary';
import { useAuth } from '../../../hooks/useAuth';
import { useManageAttachment } from '../../../hooks/useManageAttachment';
import { useAddKnowledgebaseMutation, useDetailKnowledgebaseQuery, useEditKnowledgebaseMutation } from "../../../rtkStore/services/knowledgebaseApi";
import { useGetTeamListQuery } from "../../../rtkStore/services/teamApi";
import { useListAssignmentUsersQuery } from "../../../rtkStore/services/userApi";
import { useLazyDetailListQuery, useListListQuery } from "../../../rtkStore/services/listService";
import { AttachmentHookIntendedForOptions, knowledgebaseStatusOptions, knowledgebaseVisibilityOptions } from "../../../constants/kbConstants";
import { convertDate } from "../../../utils/handleFormatDate";
import { LoadingButton } from "../../../components/Shared/LoadingButton";
import { AddTagModal } from "../../../components/Shared/AddTagModal";
import { ManageAttachment } from "./KBComponents/ManageAttachment";
import { ReactSelectAddMenuComponent } from "./KBComponents/ReactSelectAddOption";

// import { useListTeamQuery } from "../../state/teamSlice";

const options = ["Save as Draft", "Submit"];

const maxAttachmentsNumber = parseInt(
  process.env.REACT_MAX_ATTACHMENTS_NUMBER ||
  // AppConstants.MAX_ATTACHMENTS_NUMBER
  7
);

export const ManageKBScreen = () => {
  // states
  const [isAddTagModalActive, setIsAddTagModalActive] = React.useState(false);
  const [isBtnPopper, setIsBtnPopper] = React.useState(false);
  const btnPopperAnchorRef = React.useRef(null);
  const [selectedBtnIndex, setSelectedBtnIndex] = React.useState(1);
  const [isEditMode, setIsEditMode] = React.useState(false);
  // const [knowledgebase, setKnowledgebase] = React.useState(null);
  const [selectedTeamsVisibility, setSelectedTeamsVisibility] = React.useState(
    []
  );
  const [selectedUsersVisibility, setSelectedUsersVisibility] = React.useState(
    []
  );

  // hooks
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const { handleUploadFiles } = useCloudinary();

  //   rtk queries
  const { data: users = [], isLoading: isLoadingUsers } =
    useListAssignmentUsersQuery({});
  const [addKnowledgebase, { isLoading: isAddingKnowledgebase }] =
    useAddKnowledgebaseMutation();
  // const [fetchKnowledgebase, { isLoading: isLoadingKnowledgebase }] =
  //   useLazyDetailKnowledgebaseQuery();
  const { isLoading: isLoadingKnowledgebase, data: knowledgebase } =
    useDetailKnowledgebaseQuery({
      id: params.id,
      includeVersions: false,
    });
  const [editKnowledgebase, { isLoading: isEditingKnowledgebase }] =
    useEditKnowledgebaseMutation();
  const { isLoading: isLoadingList, data: list = [] } = useListListQuery({
    type: "Knowledgebase Category",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });
  const { isLoading: isLoadingTags, data: tags = [] } = useListListQuery({
    type: "Knowledgebase Tags",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });
  const [
    fetchSubcategories,
    { isFetching: isFetchingSubcategories, data: subCategories = [] },
  ] = useLazyDetailListQuery();
  const { data: teams = [], isLoading: isLoadingTeams } =
    useGetTeamListQuery("Active");

  const attachmentHookProps = useManageAttachment({
    modelId: params?.id || "",
    modelName: "id",
    intendedFor: AttachmentHookIntendedForOptions.KNOWLEDGEBASE,
    ...(isEditMode &&
      knowledgebase?.attachments && {
      existingAttachments: knowledgebase?.attachments || [],
    }),
  });

  const { attachments } = attachmentHookProps;

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      subtitle: "",
      description: "",
      tags: [],
      publishedDate: null,
      // publishedDate: convertDate(new Date().toISOString(), {
      //   isForEditMode: true,
      // }),
      expiryDate: null,
      category: "",
      subCategory: "",
      isPublished: true,
      isPublishedPublicly: true,
      responsibleInfo: "",
      publisher: "",
      image: "",
      author: {
        label: user?.name,
        value: user?._id,
      },
      teamsVisibility: "",
      usersVisibility: "",
      visibility: {
        label: "Everyone",
        value: "Everyone",
      },
      status: {
        label: "Active",
        value: "Active",
      },
      attachments: [],
      activeVisibilityEntity: "Team",
    },
  });

  const categoryWatch = useWatch({
    control,
    name: "category",
  });

  // methods
  const onSubmit = async (data, selectedBtnName) => {
    if (isFetchingSubcategories) return;

    let uploadedAttachments = [];
    let loadingId = "";

    const nonUploadedAttachments = attachments.filter(
      (a) => a?.attachmentUrl === undefined
    );
    const alreadyUploadedAttachments = attachments.filter(
      (a) => a?.attachmentUrl !== undefined
    );

    if (nonUploadedAttachments.length > 0 && !isEditMode) {
      const { uploadedFiles, loadingId: _loadingId } =
        await handleUploadKBAttachments(nonUploadedAttachments);

      uploadedAttachments = [...alreadyUploadedAttachments, ...uploadedFiles];
      loadingId = _loadingId;

      if (!uploadedFiles || !Array.isArray(uploadedFiles)) {
        toast.error("Something went wrong whlie uploading ticket attachments.");
        return;
      }
    } else {
      uploadedAttachments = [...alreadyUploadedAttachments];
    }

    const payload = {
      ...data,
      ...(isEditMode && {
        id: params.id
      }),
      author: data?.author?.value,
      category: data?.category?.value || null,
      status: data?.status?.value,
      subCategory: data?.subCategory?.value || null,
      visibility: data?.visibility?.value,
      responsibleInfo: data?.responsibleInfo?.value,
      approver: data?.approver?.value,
      publisher: data?.publisher?.value,
      tags: data?.tags?.map((t) => t?.value),
      teamsVisibility: !selectedTeamsVisibility
        ? []
        : selectedTeamsVisibility?.map((x) => x.value),
      usersVisibility: !selectedUsersVisibility
        ? []
        : selectedUsersVisibility?.map((x) => x.value),
      attachments: !uploadedAttachments?.length
        ? []
        : [
          {
            version: 1,
            files: uploadedAttachments
              .filter((a) => a?.attachmentUrl || a?.secure_url)
              .map((f) => ({
                bytes: f?.bytes,
                attachmentUrl: f?.secure_url || f?.attachmentUrl,
                cPublicId: f?.public_id || f?.cPublicId,
                isImage: f?.isImage === undefined ? false : f?.image,
                ...f,
              })),
          },
        ],
    };

    if (selectedBtnName === "Submit") {
      payload.publishStatus = "Submitted";
    } else if (selectedBtnName === "Save as Draft") {
      payload.publishStatus = "Draft";
    }


    if (isEditMode || params.id) {
      try {
        toast.update(loadingId, {
          render: "Editing document....",
          transition: null,
        });
        await editKnowledgebase(payload).unwrap();
        toast.dismiss(loadingId);
        toast.success("Knowledgebase Document edited successfully");
        navigate(`/app/knowledgebase/list`);
        reset();
      } catch (err) {
        const errorMessage =
          err?.data?.message ||
          "Couldn't create document. Please try again later";
        toast.error(errorMessage);
      }
    } else {
      try {
        toast.update(loadingId, {
          render: "Creating document....",
          transition: null,
        });
        await addKnowledgebase(payload).unwrap();
        toast.dismiss(loadingId);
        toast.success("Knowledgebase Document created successfully");
        navigate(`/app/knowledgebase/list`);
        reset();
      } catch (err) {
        const errorMessage =
          err?.data?.message ||
          "Couldn't create document. Please try again later";
        toast.error(errorMessage);
      }
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedBtnIndex(index);
    setIsBtnPopper(false);
  };

  const handleToggle = () => {
    setIsBtnPopper((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      btnPopperAnchorRef.current &&
      btnPopperAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setIsBtnPopper(false);
  };

  const handleUploadKBAttachments = async (_attachments) => {
    if (_attachments.length === 0) return [];
    if (_attachments.length > maxAttachmentsNumber) {
      toast.error(
        `Only a maximum of ${maxAttachmentsNumber} attachments is allowed`
      );
      return;
    }

    const loadingId = toast.loading("Uploading document attachments...", {
      type: "success",
    });

    const { uploadedFiles } = await handleUploadFiles(_attachments, {
      folderName: "medxprovider/attachments",
    });

    return {
      uploadedFiles,
      loadingId,
    };
  };

  // uncomment in production
  // React.useEffect(() => {
  //   if (user?.role !== "Admin") {
  //     navigate(`/app/knowledgebase/list`);
  //     toast.error("You are not authorized to view this page");
  //   }
  // }, [navigate, user?.role]);

  React.useEffect(() => {
    if (!knowledgebase) return;
    if (!params.id) return;

    setIsEditMode(true);

    const usersVisibility =
      knowledgebase?.usersVisibility?.map((t) => ({
        label: t.name,
        value: t._id,
      })) || "";

    const teamsVisibility =
      knowledgebase?.teamsVisibility?.map((t) => ({
        label: t.name,
        value: t._id,
      })) || "";

    setSelectedUsersVisibility(usersVisibility);
    setSelectedTeamsVisibility(teamsVisibility);

    reset({
      ...knowledgebase,
      title: knowledgebase.title,
      subtitle: knowledgebase.subtitle,
      description: knowledgebase.description,
      activeVisibilityEntity: knowledgebase?.activeVisibilityEntity || "Team",
      publishedDate: !knowledgebase?.publishedDate
        ? null
        : convertDate(knowledgebase?.publishedDate, { isForEditMode: true }),
      expiryDate: !knowledgebase?.expiryDate
        ? null
        : convertDate(knowledgebase?.expiryDate, { isForEditMode: true }),
      visibility: {
        label: knowledgebase.visibility || "Everyone",
        value: knowledgebase.visibility || "Everyone",
      },
      status: {
        label: knowledgebase.status,
        value: knowledgebase.status,
      },
      category: {
        label: knowledgebase.category?.name,
        value: knowledgebase.category?._id,
      },
      subCategory: {
        label: knowledgebase.subCategory?.name,
        value: knowledgebase.subCategory?._id,
      },
      author: {
        label: knowledgebase?.author?.name || "",
        value: knowledgebase?.author?._id || "",
      },
      approver: {
        label: knowledgebase?.approver?.name || "",
        value: knowledgebase?.approver?._id || "",
      },
      publisher: {
        label: knowledgebase?.publisher?.name || "",
        value: knowledgebase?.publisher?._id || "",
      },
      responsibleInfo: {
        label: knowledgebase?.responsibleInfo?.name || "",
        value: knowledgebase?.responsibleInfo?._id || "",
      },
      tags: knowledgebase?.tags?.map((t) => ({
        label: t?.name,
        value: t?._id,
      })),
      teamsVisibility,
      usersVisibility,
    });
  }, [knowledgebase, navigate, params.id, reset]);

  React.useEffect(() => {
    if (!categoryWatch?.value) return;

    fetchSubcategories(
      {
        _id: categoryWatch?.value,
      },
      true
    );
  }, [categoryWatch, fetchSubcategories]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderRadius="5px"
        paddingX={2}
        paddingY={2}
        height="100%"
        sx={{
          backgroundColor: "white",
          boxShadow: "0px 10px 20px 0px rgba(82, 63, 105, 0.03)",
        }}
      >
        <Box display="flex" alignItems="center" sx={{ gap: "1rem" }}>
          <Typography variant="h6">
            {params.id ? "Edit" : "Add"} Document
          </Typography>
          {params.id && isLoadingKnowledgebase && (
            <CircularProgress size={20} />
          )}
        </Box>

        <Box display="flex" alignItems="center" sx={{ gap: "1rem" }}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={btnPopperAnchorRef}
            aria-label="split button"
          >
            <LoadingButton
              onClick={handleSubmit((d) =>
                onSubmit(d, options[selectedBtnIndex])
              )}
              loadingText={
                isEditMode ? "Editing document..." : "Creating document..."
              }
              loading={isAddingKnowledgebase || isEditingKnowledgebase}
            >
              {options[selectedBtnIndex]}
            </LoadingButton>
            <Button
              color="primary"
              size="small"
              aria-controls={isBtnPopper ? "split-button-menu" : undefined}
              aria-expanded={isBtnPopper ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={isBtnPopper}
            anchorEl={btnPopperAnchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "900" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 2}
                          selected={index === selectedBtnIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>

      <Box
        marginTop={2}
        borderRadius="5px"
        paddingX={2}
        paddingY={2}
        width="100%"
        height="100%"
        sx={{
          backgroundColor: "white",
          boxShadow: "0px 10px 20px 0px rgba(82, 63, 105, 0.03)",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Box marginBottom={3}>
              <label htmlFor={`title`} className="form-label">
                Title*
              </label>
              <input
                type="text"
                name={`title`}
                className="form-control form-font py-2"
                {...register(`title`, {
                  required: "Article title is required",
                })}
              />
              {errors.title && <p className="error">{errors.title.message}</p>}
            </Box>

            <Box marginY={3}>
              <div>
                <label htmlFor={`subtitle`} className="form-label">
                  Summary*
                </label>
                <textarea
                  name={`subtitle`}
                  rows={4}
                  className="form-control form-font py-2"
                  {...register(`subtitle`, {
                    required: "Article summary is required",
                  })}
                />
                {errors.subtitle && (
                  <p className="error">{errors.subtitle.message}</p>
                )}
              </div>
            </Box>

            <Box marginY={3}>
              <label htmlFor={`subtitle`} className="form-label">
                Body*
              </label>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: "Article body is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <Editor
                    apiKey="zqrsvtmeatld5mag3wlr175d0r1zdo1u8wcr0wvs3re77ow2"
                    onEditorChange={onChange}
                    initialValue={
                      knowledgebase ? knowledgebase.description : ""
                    }
                    init={{
                      height: 450,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        'body { font-family:"Segoe UI","Montserrat",Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                )}
              />
              {errors.description && (
                <p className="error">{errors.description.message}</p>
              )}
            </Box>

            {!isEditMode && (
              <Box marginY={3}>
                <label htmlFor={`subtitle`} className="form-label">
                  Attachments
                </label>

                <ManageAttachment {...attachmentHookProps} />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Author*
              </label>

              <Controller
                name="author"
                control={control}
                rules={{
                  required: "Article author is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document author"
                    isClearable
                    isLoading={isLoadingUsers}
                    options={users.map((u) => ({
                      label: u.name,
                      value: u._id,
                    }))}
                    {...field}
                  />
                )}
              />
              {errors.author && (
                <p className="error">{errors.author.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Responsible User*
              </label>

              <Controller
                name="responsibleInfo"
                control={control}
                rules={{
                  required: "Responsible user is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document responsible user"
                    isClearable
                    isLoading={isLoadingUsers}
                    options={users.map((u) => ({
                      label: u.name,
                      value: u._id,
                    }))}
                    {...field}
                  />
                )}
              />
              {errors.responsibleInfo && (
                <p className="error">{errors.responsibleInfo.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Approver*
              </label>

              <Controller
                name="approver"
                control={control}
                rules={{
                  required: "Document approver is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document approver"
                    isClearable
                    isLoading={isLoadingUsers}
                    options={users.map((u) => ({
                      label: u.name,
                      value: u._id,
                    }))}
                    {...field}
                  />
                )}
              />
              {errors.approver && (
                <p className="error">{errors.approver.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Publisher*
              </label>

              <Controller
                name="publisher"
                control={control}
                rules={{
                  required: "Document publisher is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document publisher"
                    isClearable
                    isLoading={isLoadingUsers}
                    options={users.map((u) => ({
                      label: u.name,
                      value: u._id,
                    }))}
                    {...field}
                  />
                )}
              />
              {errors.publisher && (
                <p className="error">{errors.publisher.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Publish Date
              </label>
              <input
                type="date"
                name={`publishedDate`}
                className="form-control form-font py-2"
                placeholder="Select publish date"
                {...register(`publishedDate`, {
                  // required: "Publish date is required.",
                })}
              />
              {errors.publishedDate && (
                <p className="error">{errors.publishedDate.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Expiry Date
              </label>
              <input
                type="date"
                name={`expiryDate`}
                className="form-control form-font py-2"
                placeholder="Select publish date"
                {...register(`expiryDate`, {})}
              />
              {errors.expiryDate && (
                <p className="error">{errors.expiryDate.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Category
              </label>

              <Controller
                name="category"
                control={control}
                rules={
                  {
                    // required: "Article category is required",
                  }
                }
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document category"
                    isClearable
                    isLoading={isLoadingList}
                    options={list?.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    value={field.value}
                    onChange={(data) => {
                      field.onChange(data);
                      reset({
                        ...getValues(),
                        subCategory: "",
                      });
                    }}
                  />
                )}
              />
              {errors.category && (
                <p className="error">{errors.category.message}</p>
              )}
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Subcategory
              </label>

              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document subcategory"
                    isClearable
                    isDisabled={!Boolean(categoryWatch?.value)}
                    isLoading={isFetchingSubcategories}
                    options={
                      !subCategories?.children
                        ? []
                        : subCategories?.children?.map((c) => ({
                          label: c.name,
                          value: c._id,
                        }))
                    }
                    {...field}
                  />
                )}
              />
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Tags
              </label>

              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document tags..."
                    isClearable
                    isMulti
                    isLoading={isLoadingTags}
                    value={field.value}
                    onChange={(data) => field.onChange(data)}
                    options={tags.map((t) => ({
                      label: t.name,
                      value: t._id,
                    }))}
                    components={{ Menu: ReactSelectAddMenuComponent }}
                    buttonName="Add another tag"
                    optionAdd={() => {
                      setIsAddTagModalActive(true);
                    }}
                  />
                )}
              />
            </Box>

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Visibility*
              </label>

              <Controller
                name="visibility"
                control={control}
                rules={{
                  required: "Article visibility is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document visibility"
                    isClearable
                    options={knowledgebaseVisibilityOptions}
                    {...field}
                  />
                )}
              />
              {errors.visibility && (
                <p className="error">{errors.visibility.message}</p>
              )}
            </Box>

            {watch("visibility")?.value !== "Everyone" && (
              <Box marginBottom={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ gap: "1rem", marginBottom: "8px" }}
                >
                  <label htmlFor="" className="form-font">
                    {watch("activeVisibilityEntity")} Visibility
                  </label>

                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ gap: "8px", marginLeft: "1rem" }}
                  >
                    <Controller
                      name="activeVisibilityEntity"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          style={{ marginBottom: "-6px" }}
                          checked={field.value === "Team" ? true : false}
                          onChange={() => {
                            const nextValue =
                              field.value === "User" ? "Team" : "User";
                            field.onChange(nextValue);
                          }}
                        />

                        // <Form.Check
                        //   type="switch"
                        //   className="custom-control-input"
                        //   id="switchVisibility"
                        //   style={{ marginBottom: "-6px" }}
                        //   checked={field.value === "Team" ? true : false}
                        //   onChange={() => {
                        //     const nextValue =
                        //       field.value === "User" ? "Team" : "User";
                        //     field.onChange(nextValue);
                        //   }}
                        // />
                      )}
                    />
                    <label className="form-font" htmlFor="switchVisibility">
                      Switch Visibility to{" "}
                      {watch("activeVisibilityEntity") === "User"
                        ? "Teams"
                        : "Users"}
                    </label>
                  </Box>
                </Box>

                {watch("activeVisibilityEntity") === "User" ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select users..."
                    isLoading={isLoadingUsers}
                    isMulti
                    value={selectedUsersVisibility}
                    onChange={(data) => setSelectedUsersVisibility(data)}
                    options={users.map((t) => ({
                      label: t.name,
                      value: t._id,
                    }))}
                  />
                ) : (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select teams..."
                    isLoading={isLoadingTeams}
                    isMulti
                    value={selectedTeamsVisibility}
                    onChange={(data) => setSelectedTeamsVisibility(data)}
                    options={teams.map((t) => ({
                      label: t.name,
                      value: t._id,
                    }))}
                  />
                )}

                {/* {watch("activeVisibilityEntity") === "User" ? (
                <Controller
                  name="usersVisibility"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({ ...provided, zIndex: 10 }),
                        }}
                        className="form-font"
                        placeholder="Select users..."
                        isLoading={isLoadingUsers}
                        isMulti
                        {...field}
                        options={users.map((t) => ({
                          label: t.name,
                          value: t._id,
                        }))}
                      />
                    );
                  }}
                />
              ) : (
                <Controller
                  name="teamsVisibility"
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 10 }),
                      }}
                      className="form-font"
                      placeholder="Select teams..."
                      isLoading={isLoadingTeams}
                      isMulti
                      {...field}
                      options={teams.map((t) => ({
                        label: t.name,
                        value: t._id,
                      }))}
                    />
                  )}
                />
              )} */}
              </Box>
            )}

            <Box marginBottom={3}>
              <label htmlFor="" className="form-font">
                Status*
              </label>

              <Controller
                name="status"
                control={control}
                rules={{
                  required: "Article status is required",
                }}
                render={({ field }) => (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 10 }),
                    }}
                    className="form-font"
                    placeholder="Select document status"
                    isClearable
                    options={knowledgebaseStatusOptions}
                    {...field}
                  />
                )}
              />
              {errors.status && (
                <p className="error">{errors.status.message}</p>
              )}
            </Box>

            {/* <Box marginBottom={3}>
              <Controller
                name="isPublishedPublicly"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        color="primary"
                      />
                    }
                    label="Is Visible Publicly"
                  />
                )}
              />
            </Box> */}
          </Grid>
        </Grid>
      </Box>

      {isAddTagModalActive && (
        <AddTagModal
          isOpen={isAddTagModalActive}
          onCloseModal={() => {
            setIsAddTagModalActive(false);
          }}
        />
      )}
    </Box>
  );
};
