import React from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select as MuiSelect,
} from "@mui/material";
import Select from "react-select";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";

import { useListAccountEntityUserQuery } from "../../../../rtkStore/services/userApi";

import { useAuth } from "../../../../hooks/useAuth";

const TeamForm = ({
  teamDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState([]);

  const navigate = useNavigate();

  const { viewEntity } = useAuth();

  const teamTypes = ["Department"];

  // RTK Query
  const { data: users, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  const mappedUserList = React.useMemo(() => {
    return (
      users?.map((user) => ({
        label: user?.name,
        value: user?._id,
      })) ?? []
    );
  }, [users]);

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: teamDetail?.name ?? "",
      type: teamDetail?.type ?? "",
      members: teamDetail?.members
        ? teamDetail?.members?.map((m) => ({ label: m.name, value: m._id }))
        : [],
      manager: teamDetail?.manager ?? "",
      description: teamDetail?.description ?? "",
    },
  });

  React.useEffect(() => {
    if (!teamDetail) return;

    const teamMembers = teamDetail?.members
      ? teamDetail?.members?.map((m) => ({ label: m.name, value: m._id }))
      : [];
    const teamManager = teamMembers?.find(
      (m) => m.value === teamDetail?.manager?._id
    );

    setSelectedTeamMembers(teamMembers);

    reset({
      ...teamDetail,
      members: teamMembers,
      manager: teamManager,
    });
  }, [teamDetail, reset]);

  return (
    <LoadingWrapper loading={isBusy || loadingUsers}>
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Team Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Team Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Team type is required" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-type">Type</InputLabel>
                  <MuiSelect
                    fullWidth
                    labelId="select-type"
                    label="Coure Level"
                    placeholder="Select Type"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {teamTypes?.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              )}
            />
            {errors.type && <p className="error">{errors.type.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="members"
              control={control}
              rules={{ required: "Please select team members" }}
              render={({ field: { onChange, value } }) => (
                <Box className="-my-2">
                  <div className="pb-1 text-xs text-gray-400">Team Members</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    isMulti
                    isClearable
                    value={value}
                    options={mappedUserList}
                    onChange={(e) => {
                      //onChange will send the value to the useForm - e in isMulti'S default is empty array
                      onChange(e);
                      //for any change in authorization selection, set the value of defaultAuth to null & update defaultRoleOptions
                      setValue("manager", "");
                      setSelectedTeamMembers(e);
                    }}
                  />
                  {errors.members && (
                    <p className="error">{errors.members.message}</p>
                  )}
                </Box>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="manager"
              control={control}
              rules={{ required: "Select team manager" }}
              render={({ field }) => (
                <Box className="-my-2">
                  <div className="pb-1 text-xs text-gray-400">Team Manager</div>
                  <Select
                    {...field}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    isClearable
                    options={selectedTeamMembers}
                  />
                </Box>
              )}
            />
            {errors.manager && (
              <p className="error">{errors.manager.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  size="small"
                  multiline
                  rows={3}
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className="error">{errors.description.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              members: data?.members?.map((m) => m.value),
              manager: data?.manager?.value,
            })
          )}
        >
          {teamDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default TeamForm;
