import React from 'react'
// import AboutUs from "../../components/AboutUs"
// import Blog from "../../components/Blog";
import { CarouselPage } from '../../components/Carousel'
import ConsultExpert from '../../components/ConsultExpert'
import GlobalBranches from '../../components/GlobalBranches'
// import OurServices from "../../components/OurServices"
import StudyAbroad from '../../components/StudyAbroad'
import Universities from '../../components/Universities'
import Testimonials from '../../components/Testimonials'
import { WhyFsw } from '../../components/WhyFsw'
import { Step4 } from '../../components/Step4'
import { PartnershipVideo } from '../../components/PartnershipVideo'
import { ClientVideo } from '../../components/ClientVideo'

const HomeScreen = () => {
  return (
    <>
      <CarouselPage />
      <StudyAbroad />
      {/* <OurServices /> */}
      {/* <AboutUs /> */}
      <Step4 />
      <WhyFsw />
      <ClientVideo />
      <PartnershipVideo />
      <ConsultExpert />
      {/* <Blog /> */}
      <Testimonials />
      <Universities />
    </>
  )
}

export default HomeScreen
