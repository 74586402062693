import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  InfoOutlined,
  FiberManualRecord,
  NoteAltOutlined,
  EventOutlined,
  DriveFileRenameOutline,
  Add,
} from "@mui/icons-material";
import moment from "moment";

import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";

import { useListTransactionApplicationQuery } from "../../../../rtkStore/services/applicationApi";
import { useTransactionDetail } from "../TransactionDetailScreen";

const TransactionApplicationsTab = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { transactionDetail } = useTransactionDetail();

  const {
    data: transactionApplications,
    isLoading: loadingTransactionApplications,
  } = useListTransactionApplicationQuery(id);

  return !loadingTransactionApplications ? (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge
              badgeContent={transactionApplications.length}
              color="primary"
              showZero
            >
              <DriveFileRenameOutline style={{ color: "gray" }} />
            </Badge>

            <Typography style={{ fontWeight: 500 }}>Applications</Typography>
          </Box>

          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() =>
              navigate(
                `/app/application/create?selectedClient=${transactionDetail?.client?._id}&selectedTransaction=${id}`
              )
            }
          >
            Application
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box paddingX={3}>
        {transactionApplications.length ? (
          transactionApplications.map((application, index) => (
            <React.Fragment key={application._id}>
              <Box paddingY={3} display="flex" alignItems="start" gap={2}>
                <NoteAltOutlined
                  color="primary"
                  fontSize="small"
                  style={{ marginTop: 2 }}
                />

                <Box display="flex" flexGrow={1} flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <div className="line-clamp-1" title={application?.title}>
                      {application?.title}
                    </div>

                    <FiberManualRecord
                      style={{ color: "gray", fontSize: 10 }}
                    />

                    <div
                      title={`Stage: ${application?.stage?.name}`}
                      className="flex-shrink-0"
                      style={{ color: "var(--primary)" }}
                    >
                      {application?.stage?.name}
                    </div>

                    <FiberManualRecord
                      style={{ color: "gray", fontSize: 10 }}
                    />

                    <div
                      title={`Priority: ${application?.priority}`}
                      className="flex-shrink-0"
                    >
                      {application?.priority}
                    </div>
                  </Box>

                  <Box display="flex" alignItems="center" gap={2}>
                    <Box display="flex" gap={1}>
                      <EventOutlined color="error" fontSize="small" />

                      <Typography>
                        {moment(application?.dueDate).format("YYYY/MM/DD")}
                      </Typography>
                    </Box>

                    <FiberManualRecord
                      style={{ color: "gray", fontSize: 10 }}
                    />

                    <Typography color="gray">
                      <span className="text-blue-600">
                        {application?.workflows?.length}
                      </span>{" "}
                      <span>Workflows</span>
                    </Typography>
                  </Box>
                </Box>

                <Link to={`/app/application/${application._id}/detail`}>
                  <IconButton color="primary">
                    <InfoOutlined />
                  </IconButton>
                </Link>
              </Box>

              {index !== transactionApplications.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No applications.</Box>
        )}
      </Box>
    </>
  ) : (
    <RowsSkeleton />
  );
};

export default TransactionApplicationsTab;
