import React from "react";
import { Box, Skeleton } from "@mui/material";

export const KBDetailMetaSkeleton = ({ expanded }) => {
  return (
    <Box
      className={`case-detail-meta-pane ${expanded && "expanded"}`}
      display="flex"
      flexDirection="column"
      gap="4rem"
      borderRight="1px solid #f1f1f1"
      paddingX={2}
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Skeleton height="100px" width="100%" style={{ marginTop: "-1rem" }} />

      <Box flexGrow={1}>
        <Skeleton height="100%" width="100%" variant="rect" />
      </Box>
    </Box>
  );
};
