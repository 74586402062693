import React from "react";

import { Link } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";
import PlaylistAddCheckOutlined from "@mui/icons-material/PlaylistAddCheckOutlined";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import EventOutlined from "@mui/icons-material/EventOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import AppDialog from "../Shared/AppDialog";
import { useUpdateTaskMutation } from "../../rtkStore/services/taskApi";
import { toast } from "react-toastify";

const TaskListItem = ({ taskItem }) => {
  const [taskStatus, setTaskStatus] = React.useState("Open");
  const [showDialog, setShowDialog] = React.useState(false);

  const statusEnums = ["Open", "On Process", "Closed"];

  // RTK Query
  const [updateTaskStatus, { isLoading: updatingStatus }] =
    useUpdateTaskMutation();

  // methods
  const handleCancelStatusChange = () => {
    setTaskStatus(taskItem?.status);
    setShowDialog(false);
  };

  const handleChangeStatus = (status) => {
    setTaskStatus(status);
    setShowDialog(true);
  };

  const handleUpdateTaskStatus = async () => {
    try {
      await updateTaskStatus({ ...taskItem, status: taskStatus }).unwrap();
      toast.success("Task status successfully updated!");
      handleCancelStatusChange();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update task status!");
    }
  };

  React.useEffect(() => {
    if (!taskItem) return;

    setTaskStatus(taskItem?.status);
  }, [taskItem]);

  return (
    <>
      <Box paddingY={3} display="flex" gap={2}>
        <PlaylistAddCheckOutlined
          color="primary"
          fontSize="small"
          style={{ marginTop: 2 }}
        />

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" flexGrow={1} flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography>{taskItem?.title}</Typography>

              <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

              <Typography style={{ color: "var(--primary)" }}>
                {taskItem?.status}
              </Typography>

              <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

              <Typography>{taskItem?.priority}</Typography>
            </Box>

            <Box display="flex" gap={1}>
              <EventOutlined color="error" fontSize="small" />

              <Typography>
                {moment(taskItem?.dueDate).format("YYYY/MM/DD")}
              </Typography>
            </Box>

            <Box style={{ color: "gray" }}>
              {ReactHtmlParser(taskItem?.description)}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-status">Change Status</InputLabel>
              <Select
                labelId="select-status"
                placeholder="Select Status"
                id="role"
                size="small"
                label="Change Sta"
                disabled={updatingStatus}
                value={taskStatus}
                onChange={(e) => handleChangeStatus(e.target.value)}
                style={{ width: 150 }}
              >
                {statusEnums?.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>{" "}
            </FormControl>

            <Link to={`/app/task/${taskItem?._id}/detail`}>
              <InfoOutlined color="primary" />
            </Link>
          </Box>
        </Box>
      </Box>

      <AppDialog
        open={showDialog}
        onClose={handleCancelStatusChange}
        onConfirm={() => handleUpdateTaskStatus()}
        isBusy={updatingStatus}
        title="Update Task Status?"
      >
        Are you sure you want to update status of{" "}
        <span className="font-medium">{taskItem?.title}</span> to{" "}
        <span className="font-medium text-blue-500">{taskStatus}</span>?
      </AppDialog>
    </>
  );
};

export default TaskListItem;
