import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import ExpandedRow from "../../../components/Shared/ExpandedRow";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyGetCourseListQuery } from "../../../rtkStore/services/courseApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const CourseListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Courses",
    },
  ];

  const courseListTabs = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Global", value: "Global" },
  ];

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/course/${row?._id}/detail`}
          title={row?.title}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
        >
          {row?.title}
        </Link>
      ),
      minWidth: "250px",
      sortable: true,
    },
    {
      name: "University",
      selector: (row) => row?.university?.title,
      cell: (row) => (
        <div title={row?.university?.title ?? "N/A"} className="line-clamp-2">
          {row?.university?.title ?? "N/A"}
        </div>
      ),
      minWidth: "200px",
    },
    {
      name: "Field",
      selector: (row) => row?.field?.name,
      cell: (row) => (
        <div title={row?.field?.name} className="line-clamp-2">
          {row?.field?.name ?? "N/A"}
        </div>
      ),
    },
    {
      name: "Level",
      selector: (row) => row?.level?.name,
      cell: (row) => (
        <div title={row?.level?.name} className="line-clamp-2">
          {row?.level?.name ?? "N/A"}
        </div>
      ),
    },
    {
      name: "Fee",
      cell: (row) => (
        <div>
          {row?.tuitionFee ? (
            <>
              <div>
                <span className="text-green-700">
                  {" "}
                  {row?.currency?.symbol}{" "}
                </span>
                <span className="text-sm text-green-700">
                  {row?.tuitionFee}
                </span>{" "}
                <span className="text-xs text-gray-500">per</span>
              </div>
              <div>
                <span className="text-sm">{row?.feeType}</span>{" "}
                <span className="text-xs">({row?.currency?.code})</span>
              </div>
            </>
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      name: "Priority",
      selector: (row) => `Level ${row?.priorityLevel}`,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/course/${row._id}/detail`}>
            <IconButton color="primary" aria-label="Edit Lead">
              <InfoOutlined />
            </IconButton>
          </Link>

          <Link to={`/app/course/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Task">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  // RTK Query
  const [
    listCourses,
    {
      data: courseList,
      isLoading: loadingCourses,
      isFetching: fetchingCourses,
      error,
    },
  ] = useLazyGetCourseListQuery();

  const filterableCourses = filterArrayByKeys(
    courseList,
    filterText,
    "title",
    "university.title"
  );

  const PageActions = () => {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/app/course/create">
          <Button startIcon={<AddIcon />} variant="contained">
            New Course
          </Button>
        </Link>
      </div>
    );
  };

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter Table"
          helperText="Title/University/Location"
          value={filterText ?? ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    listCourses({
      active: activeTab !== "Inactive",
      ...(activeTab === "Global" && { scope: "Global" }),
    });
  }, [activeTab, listCourses]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      hasActions
      headerActions={<PageActions />}
      error={error}
    >
      <CustomTabs tabList={courseListTabs} activeTab={activeTab} />

      <AppTable
        columns={columns}
        data={filterableCourses}
        progressPending={loadingCourses || fetchingCourses}
        subHeader
        subHeaderComponent={subHeaderComponent}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedRow>
            <div className="flex space-x-6">
              <div className="w-1/3">
                <div className="text-sm underline">Locations</div>
                {data?.locations?.length ? (
                  <ul className="ml-5">
                    {data?.locations?.map((el) => (
                      <li className="list-decimal" key={el?._id}>
                        {el?.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  "N/A"
                )}
              </div>

              <div className="w-1/3">
                <div className="text-sm underline">Campus Areas:</div>
                {data?.campusAreas?.length ? (
                  <ul className="ml-5">
                    {data?.university?.campusAreas
                      ?.filter((ca) => data?.campusAreas?.includes(ca?._id))
                      ?.map((el) => (
                        <li className="list-decimal" key={el?._id}>
                          {el?.name}
                        </li>
                      ))}
                  </ul>
                ) : (
                  "N/A"
                )}
              </div>

              <div>
                <div className="text-sm underline">Duration: </div>
                <div> {data?.duration?.name} </div>
              </div>
            </div>

            <br />

            <div>
              <div className="text-sm underline">Summary:</div>
              {ReactHtmlParser(data?.summary ?? "N/A")}
            </div>
          </ExpandedRow>
        )}
      />
    </WithHeader>
  );
};

export default CourseListScreen;
