import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import TaskForm from "./taskComponents/TaskForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetTaskDetailQuery,
  useUpdateTaskMutation,
} from "../../../rtkStore/services/taskApi";

const TaskEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Tasks", path: "/app/task/list" },
    { title: "Edit Task" },
  ];

  // RTK Query
  const {
    data: taskDetail,
    isLoading: loadingTaskDetail,
    error,
  } = useGetTaskDetailQuery(id);

  const [updateTask, { isLoading: updatingTask }] = useUpdateTaskMutation();

  // methods
  const handleTaskUpdate = async (data) => {
    try {
      await updateTask(data).unwrap();
      navigate(`/app/task/${id}/detail`);
      toast.success("Task successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Task!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      isBusy={loadingTaskDetail || updatingTask}
    >
      <TaskForm
        taskDetail={taskDetail}
        isBusy={loadingTaskDetail || updatingTask}
        onSubmit={handleTaskUpdate}
      />
    </WithHeader>
  );
};

export default TaskEditScreen;
