import React from "react";

const PageLayout = ({ children }) => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto min-h-screen max-w-4xl flex-grow bg-white px-6 py-10">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
