import { baseApi } from "./baseApi";

const PATHWAY_TRANSACTION = "/workflow-transactions";

export const workflowTransactionApi = baseApi.injectEndpoints({
  tagTypes: ["WorkflowTransaction"],

  endpoints: (builder) => ({
    createWorkflowTransaction: builder.mutation({
      query: (payload) => ({
        url: `${PATHWAY_TRANSACTION}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "WorkflowTransaction", id: "LIST" },
      ],
    }),

    updateWorkflowTransaction: builder.mutation({
      query: ({ _id, ...body }) => ({
        url: `${PATHWAY_TRANSACTION}/${_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "WorkflowTransaction", id: "LIST" },
      ],
    }),

    getAllTransactions: builder.query({
      query: () => `${PATHWAY_TRANSACTION}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "WorkflowTransaction",
                _id,
              })),
              { type: "WorkflowTransaction", id: "LIST" },
            ]
          : [{ type: "WorkflowTransaction", id: "LIST" }],
    }),

    getTransactionsByClientWorkflow: builder.query({
      query: ({ clientId, workflowId }) =>
        `${PATHWAY_TRANSACTION}/${clientId}/stream/${workflowId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "WorkflowTransaction",
                _id,
              })),
              { type: "WorkflowTransaction", id: "LIST" },
            ]
          : [{ type: "WorkflowTransaction", id: "LIST" }],
    }),

    // getWorkflowTransactionsByClientTransaction: builder.query({
    //   query: ({ transactionId, workflowId }) =>
    //     `${PATHWAY_TRANSACTION}/${transactionId}/stream/${workflowId}`,
    //   providesTags: (result) =>
    //     result
    //       ? [
    //           ...result.map(({ _id }) => ({
    //             type: "WorkflowTransaction",
    //             _id,
    //           })),
    //           { type: "WorkflowTransaction", id: "LIST" },
    //         ]
    //       : [{ type: "WorkflowTransaction", id: "LIST" }],
    // }),

    getWorkflowTransactionsByAssignee: builder.query({
      query: ({ assignee, workflowId }) =>
        `${PATHWAY_TRANSACTION}/${assignee}/workflows/${workflowId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "WorkflowTransaction",
                _id,
              })),
              { type: "WorkflowTransaction", id: "LIST" },
            ]
          : [{ type: "WorkflowTransaction", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateWorkflowTransactionMutation,
  useUpdateWorkflowTransactionMutation,
  useGetAllTransactionsQuery,
  useGetTransactionsByClientWorkflowQuery,
  // useGetWorkflowTransactionsByClientTransactionQuery,
  useGetWorkflowTransactionsByAssigneeQuery,
} = workflowTransactionApi;
