import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";

import AttachWorkflowForm from "../../../../components/Workflow/AttachWorkflowForm";
import WorkflowStepUpdateTracker from "../../../../components/Workflow/WorkflowStepUpdateTracker";

import {
  useAttachWorkflowMutation,
  useAddStepToWorkflowTransactionMutation,
  useReOrderTransactionWorkflowStepsMutation,
} from "../../../../rtkStore/services/transactionApi";
import { useGetWorkflowListQuery } from "../../../../rtkStore/services/workflowApi";

import { useTransactionDetail } from "../TransactionDetailScreen";

const TransactionWorkflowsTab = () => {
  const [showAttachWorkflow, setShowAttachWorkflow] = React.useState(false);

  const { id: transactionId } = useParams();

  const {
    transactionDetail: { workflows },
  } = useTransactionDetail();

  // RTK Query
  const [attachWorkflowToTransaction, { isLoading: attachingWorkflow }] =
    useAttachWorkflowMutation();

  const [addStepToTransactionWorkflow, { isLoading: addingStepToWorkflow }] =
    useAddStepToWorkflowTransactionMutation();

  const [reOrderTransactionWorkflowSteps, { isLoading: reOrderingSteps }] =
    useReOrderTransactionWorkflowStepsMutation();

  const { data: activeTransactionWorkflows = [], isLoading: loadingWorkflows } =
    useGetWorkflowListQuery({
      status: "Active",
      type: "Transaction",
    });

  const transactionWorkflows = React.useMemo(() => {
    const existingWorkflows = workflows.map((w) => w?._id);

    return (
      activeTransactionWorkflows?.filter(
        (w) => !existingWorkflows.includes(w._id)
      ) ?? []
    );
  }, [activeTransactionWorkflows, workflows]);

  // methods
  const handleCancel = () => {
    setShowAttachWorkflow(false);
  };

  const handleAttachWorkflow = async (data) => {
    try {
      await attachWorkflowToTransaction({ ...data, transactionId }).unwrap();
      toast.success("Workflow successfully attached!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add workflow!");
    } finally {
      handleCancel();
    }
  };

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <AccountTreeOutlined style={{ color: "gray" }} />

            <Typography style={{ fontWeight: 500 }}>Workflows</Typography>
          </Box>

          {!showAttachWorkflow && (
            <Button
              variant="outlined"
              onClick={() => setShowAttachWorkflow(true)}
            >
              Attach Workflow
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Collapse in={showAttachWorkflow}>
        <AttachWorkflowForm
          workflowList={transactionWorkflows}
          isBusy={attachingWorkflow || loadingWorkflows || reOrderingSteps}
          onSubmit={handleAttachWorkflow}
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <Box paddingX={3}>
        {workflows.length ? (
          workflows.map((workflow, index) => (
            <React.Fragment key={workflow._id}>
              <WorkflowStepUpdateTracker
                assigneeSource="Transaction"
                workflow={workflow}
                isBusy={addingStepToWorkflow || reOrderingSteps}
                onAddStepMutation={addStepToTransactionWorkflow}
                onReOrderSteps={reOrderTransactionWorkflowSteps}
              />
              {index !== workflows.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No workflows.</Box>
        )}
      </Box>
    </>
  );
};

export default TransactionWorkflowsTab;
