import React from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Badge, Box, Button, IconButton, Tooltip } from "@mui/material";
import {
  InfoOutlined,
  ModeEditOutlineTwoTone,
  ForumOutlined,
  AttachFileOutlined,
  Add,
} from "@mui/icons-material";

import AppTable from "../../../components/Shared/AppTable";
import AppTableDateTime from "../../../components/Shared/AppTableDateTime";
import CustomTabs from "../../../components/Shared/CustomTabs";
import TableFilters from "../../../components/Shared/AppTable/TableFilters";
import WithHeader from "../../../layouts/WithHeader";

import { useGetFeedbackListQuery } from "../../../rtkStore/services/feedbackApi";

import { selectLoggedInUser } from "../../../rtkStore/authSlice";

const FeedbackListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Open");
  const [filterText, setFilterText] = React.useState("");
  const [showMyFeedbacks, setShowMyFeedbacks] = React.useState(false);

  const [searchParams] = useSearchParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  const breadcrumbs = [{ title: "Feedbacks" }];

  const priorityIndicators = {
    Low: "bg-yellow-400",
    Medium: "bg-green-400",
    High: "bg-orange-400",
    Critical: "bg-red-400",
  };

  // RTK Query
  const {
    data: feedbackList,
    isLoading: loadingFeedbacks,
    error,
  } = useGetFeedbackListQuery();

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title ?? "",
      cell: (row) => (
        <Link
          to={`/app/feedback/${row?._id}/detail`}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
          title={row?.title}
        >
          {row?.title ?? "N/A"}
        </Link>
      ),
      minWidth: "250px",
    },
    {
      name: "Priority",
      selector: (row) => row?.priority ?? "",
      cell: (row) => (
        <div
          className={`rounded px-1.5 py-0.5 text-sm text-white ${
            priorityIndicators[row?.priority]
          }`}
        >
          {row?.priority ?? "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Stage",
      selector: (row) => row?.stage ?? "N/A",
    },
    {
      name: "Responsible",
      cell: (row) => row?.responsible?.name ?? "",
      selector: (row) => (
        <div title={row?.responsible?.name} className="line-clamp-2">
          {row?.responsible?.name ?? "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row?.createdAt ?? "",
      cell: (row) => <AppTableDateTime date={row?.createdAt} />,
    },
    {
      name: "Requested By",
      selector: (row) => row?.createdBy?.name,
      cell: (row) => (
        <div title={row?.createdBy?.name} className="line-clamp-2">
          {row?.createdBy?.name ?? "N/A"}
        </div>
      ),
      minWidth: "150px",
    },
    {
      name: "Extra Info",
      cell: (row) => (
        <Box padding={2} display="flex" alignItems="center" gap={2}>
          <Tooltip title="Feedback response" arrow>
            <Badge badgeContent={row?.responses?.length} color="primary">
              <ForumOutlined fontSize="small" />
            </Badge>
          </Tooltip>

          {row?.attachmentUrl && (
            <Tooltip title="Has Attachments" arrow>
              <AttachFileOutlined />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Link to={`/app/feedback/${row?._id}/detail`}>
            <IconButton size="small" aria-label="Feedback">
              <InfoOutlined color="primary" />
            </IconButton>
          </Link>

          <Link to={`/app/feedback/${row?._id}/edit`}>
            <IconButton size="small" aria-label="Edit Feedback">
              <ModeEditOutlineTwoTone />
            </IconButton>
          </Link>
        </Box>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const PageActions = () => {
    return (
      <Link to="/app/feedback/create">
        <Button variant="contained" startIcon={<Add />}>
          New Feedback
        </Button>
      </Link>
    );
  };

  const feedbackCounts = React.useMemo(() => {
    if (!feedbackList || !feedbackList.length) return;

    const counts = {};
    for (const feedback of feedbackList) {
      counts[feedback?.stage] = counts[feedback?.stage]
        ? counts[feedback?.stage] + 1
        : 1;
    }

    return counts;
  }, [feedbackList]);

  const feedbackListTabs = [
    {
      label: "Open",
      value: "Open",
      badgeCount: feedbackCounts && (feedbackCounts["Open"] ?? 0),
    },
    {
      label: "In Progress",
      value: "In Progress",
      badgeCount: feedbackCounts && (feedbackCounts["In Progress"] ?? 0),
    },
    {
      label: "Verification",
      value: "Verification",
      badgeCount: feedbackCounts && (feedbackCounts["Verification"] ?? 0),
      enablePulse: feedbackCounts && feedbackCounts["Verification"] > 0,
    },
    {
      label: "Closed",
      value: "Closed",
      badgeCount: feedbackCounts && (feedbackCounts["Closed"] ?? 0),
    },
    {
      label: "Discarded",
      value: "Discarded",
      badgeCount: feedbackCounts && (feedbackCounts["Discarded"] ?? 0),
    },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Open");
  }, [searchParams]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingFeedbacks}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <CustomTabs tabList={feedbackListTabs} activeTab={activeTab} />

      <TableFilters
        filterText={filterText}
        filterTextPlaceholder="Filter by Title"
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyFeedbacks}
        onShowMineChange={(e) => setShowMyFeedbacks(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={
          feedbackList
            ?.filter((feedback) => feedback.stage === activeTab)
            ?.filter(
              (item) =>
                item &&
                item.title.toLowerCase().includes(filterText.toLowerCase())
            )
            ?.filter((lead) =>
              showMyFeedbacks
                ? lead?.responsible?._id === loggedInUser?._id
                : lead
            ) ?? []
        }
        progressPending={loadingFeedbacks}
      />
    </WithHeader>
  );
};

export default FeedbackListScreen;
