import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { BookmarksOutlined } from "@mui/icons-material";

import AppDialog from "../../../../components/Shared/AppDialog";
import FavouriteCourseCard from "../../../../components/Course/FavouriteCourseCard";
import GenerateStudyOptionsForm from "../../../../components/StudyOption/GenerateStudyOptionsForm";

import {
  useRemoveLeadFavouriteCourseMutation,
  useAddLeadStudyOptionMutation,
} from "../../../../rtkStore/services/leadApi";

import { useLeadDetail } from "../LeadDetailScreen";

const LeadFavouriteCourses = () => {
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showGenerateOption, setShowGenerateOption] = React.useState(false);
  const [studyOptionCourses, setStudyOptionCourses] = React.useState([]);

  const { id: leadId } = useParams();

  const {
    leadDetail: { favouriteCourses },
  } = useLeadDetail();

  const allCourseSelected = React.useMemo(
    () => studyOptionCourses?.length === favouriteCourses?.length,
    [favouriteCourses, studyOptionCourses]
  );

  // RTK Query
  const [removeFavouriteCourse, { isLoading: removingFavouriteCourse }] =
    useRemoveLeadFavouriteCourseMutation();

  const [addLeadStudyOption, { isLoading: addingStudyOption }] =
    useAddLeadStudyOptionMutation();

  // methods
  const handleReset = () => {
    setShowConfirmation(false);
    setSelectedCourse(null);
  };

  const handleAddStudyOption = async (data) => {
    try {
      const studyOption = await addLeadStudyOption({
        ...data,
        leadId,
        studyOptionCourses,
      }).unwrap();
      toast.info("Study Option added to Client!");
      setSelectedCourse(null);
      setShowGenerateOption(false);
      window.open(`/study-options/${studyOption?._id}/leads/${leadId}`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Study option couldn't be added!");
    }
  };

  const handleSetRemovableCourse = (course) => {
    setShowConfirmation(true);
    setSelectedCourse(course);
  };

  const handleRemoveCourse = async () => {
    try {
      await removeFavouriteCourse({ leadId, ...selectedCourse }).unwrap();
      toast.success("Course successfully removed from favourites!");
      handleReset();
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't remove course from favourites!"
      );
    }
  };

  const handleCourseSelection = (course) => {
    const alreadyExists = !!studyOptionCourses.includes(course);
    if (!alreadyExists) setStudyOptionCourses([...studyOptionCourses, course]);
    else
      setStudyOptionCourses((prev) => [...prev].filter((el) => el !== course));
  };

  const handleMassSelectAction = () => {
    allCourseSelected
      ? setStudyOptionCourses([])
      : setStudyOptionCourses([...favouriteCourses?.map((el) => el?._id)]);
  };

  React.useEffect(() => {
    setStudyOptionCourses([...favouriteCourses.map((el) => el?._id)]);
    return () => {
      setStudyOptionCourses([]);
    };
  }, [favouriteCourses]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <IconButton
            style={{ marginLeft: -10 }}
            onClick={handleMassSelectAction}
          >
            <BookmarksOutlined
              style={{ color: allCourseSelected ? "var(--primary)" : "gray" }}
            />
          </IconButton>

          <Typography style={{ fontWeight: 500 }}>Favourite Courses</Typography>
        </Box>

        {!!favouriteCourses?.length && !!studyOptionCourses?.length && (
          <>
            <Button
              variant="outlined"
              onClick={() => setShowGenerateOption(true)}
            >
              Generate Study Options
            </Button>

            <GenerateStudyOptionsForm
              isBusy={addingStudyOption}
              show={showGenerateOption}
              onClose={() => setShowGenerateOption(false)}
              studyOptionCourses={studyOptionCourses}
              onAddStudyOption={handleAddStudyOption}
            />
          </>
        )}
      </Box>

      <Divider />

      <Box paddingX={3}>
        {!!favouriteCourses.length ? (
          favouriteCourses?.map((course, index) => (
            <React.Fragment key={index}>
              <FavouriteCourseCard
                course={course}
                checked={!!studyOptionCourses?.includes(course?._id)}
                onRemove={handleSetRemovableCourse}
                onSelection={handleCourseSelection}
              />
              {index !== favouriteCourses.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Favourite Course</Box>
        )}
      </Box>

      <AppDialog
        isBusy={removingFavouriteCourse}
        title="Confirm Remove from Favourite!"
        open={showConfirmation}
        onClose={handleReset}
        onConfirm={handleRemoveCourse}
      >
        <Typography>
          Are you sure you want to remove{" "}
          <span className="text-blue-600">{selectedCourse?.title}</span> from
          favourites?
        </Typography>
      </AppDialog>
    </>
  );
};

export default LeadFavouriteCourses;
