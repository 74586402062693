import React from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BookmarkBorderOutlined from "@mui/icons-material/BookmarkBorderOutlined";

import AppDialog from "../../../../components/Shared/AppDialog";

import {
  useAddFavouriteCourseToClientMutation,
  useGetClientListQuery,
} from "../../../../rtkStore/services/clientApi";
import {
  useAddFavouriteCourseToLeadMutation,
  useGetLeadListQuery,
} from "../../../../rtkStore/services/leadApi";

const AddToFavourite = ({ course }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [assigneeType, setAssigneeType] = React.useState("Client");

  const { id: courseId } = useParams();

  const assigneeTypes = [
    // "Lead",
    "Client",
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      assignee: null,
    },
  });

  // RTK Query
  const { data: leadList, isLoading: loadingLeads } = useGetLeadListQuery();

  const { data: clientList, isLoading: loadingClients } =
    useGetClientListQuery();

  const [addFavouriteCourseToLead, { isLoading: addingLeadFavCourse }] =
    useAddFavouriteCourseToLeadMutation();

  const [addFavouriteCourseToClient, { isLoading: addingClientFavCourse }] =
    useAddFavouriteCourseToClientMutation();

  const mappedAssigneeList = React.useMemo(() => {
    switch (assigneeType) {
      case "Lead":
        return (
          leadList?.map((client) => ({
            label: `${client.firstName} ${client.lastName}`,
            value: client._id,
          })) ?? []
        );

      case "Client":
        return (
          clientList?.map((client) => ({
            label: `${client.firstName} ${client.lastName}`,
            value: client._id,
          })) ?? []
        );

      default:
        return [];
    }
  }, [assigneeType, clientList, leadList]);

  //   methods
  const handleReset = () => {
    setAssigneeType("Client");
    setShowDialog(false);
    reset({ assignee: null });
  };

  const handleAssigneeTypeChange = (e) => {
    setAssigneeType(e.target.value);
    reset({ assignee: null });
  };

  const handleAddFavCourseToLead = async (lead) => {
    try {
      await addFavouriteCourseToLead({
        leadId: lead?.value,
        favouriteCourse: courseId,
      }).unwrap();
      toast.success(`Favourite Course added to ${lead?.label}`);
      handleReset();
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't add course to lead's favourite"
      );
    }
  };

  const handleAddFavCourseToClient = async (client) => {
    try {
      await addFavouriteCourseToClient({
        clientId: client?.value,
        favouriteCourse: { course: course?._id },
      }).unwrap();
      toast.success(`Favourite Course added to ${client?.label}`);
      handleReset();
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't add course to client's favourite"
      );
    }
  };

  const handleAddToFavorite = (data) => {
    const { assignee } = data;

    if (assigneeType === "Lead") handleAddFavCourseToLead(assignee);
    else if (assigneeType === "Client") handleAddFavCourseToClient(assignee);
  };

  const isBusy = React.useMemo(() => {
    return (
      loadingLeads ||
      loadingClients ||
      addingLeadFavCourse ||
      addingClientFavCourse
    );
  }, [
    loadingLeads,
    loadingClients,
    addingLeadFavCourse,
    addingClientFavCourse,
  ]);

  return (
    <>
      <Button
        startIcon={<BookmarkBorderOutlined />}
        variant="outlined"
        onClick={() => setShowDialog(true)}
      >
        Add To Favourite
      </Button>

      <AppDialog
        title="Add Course to Favourite"
        open={showDialog}
        onClose={handleReset}
        onConfirm={handleSubmit((data) => handleAddToFavorite(data))}
        isBusy={isBusy}
        hideIcon
      >
        <Box width={500}>
          <Typography>
            Add{" "}
            <span className="font-medium text-blue-600">{course?.title}</span>{" "}
            to favourite.
          </Typography>

          <br />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-assginee-type">Assign Type</InputLabel>

                <Select
                  fullWidth
                  labelId="select-assginee-type"
                  label="Assign Type"
                  placeholder="Select Assignee Type"
                  id="role"
                  value={assigneeType}
                  onChange={handleAssigneeTypeChange}
                  disabled={isBusy}
                >
                  {assigneeTypes?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <Controller
                name="assignee"
                control={control}
                rules={{ required: "Assignee is required" }}
                render={({ field, onBlur }) => (
                  <Autocomplete
                    id="assignee"
                    autoHighlight
                    onBlur={onBlur}
                    loading={loadingClients || loadingLeads}
                    loadingText="Loading Assignee..."
                    options={mappedAssigneeList ?? []}
                    value={field?.value}
                    onChange={(e, data) => setValue("assignee", data)}
                    disabled={isBusy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assignee"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        option && (
                          <MenuItem {...props} key={option?.value}>
                            {option?.label}
                          </MenuItem>
                        )
                      );
                    }}
                  />
                )}
              />
              {errors.assignee && (
                <p className="error">{errors.assignee.message}</p>
              )}
            </Grid>
          </Grid>
        </Box>
      </AppDialog>
    </>
  );
};

export default AddToFavourite;
