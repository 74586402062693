import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ModeEditOutlineTwoTone } from "@mui/icons-material";
import moment from "moment";

import AppTable from "../Shared/AppTable/AppTable";
import ExpandedRow from "../Shared/ExpandedRow";

import {
  useCreateWorkflowTransactionMutation,
  useUpdateWorkflowTransactionMutation,
} from "../../rtkStore/services/pathwayTransaction";

const ManageWorkflowStepTransactions = ({
  workflow,
  workflowTransactions = [],
  assigneeSource,
  step = null,
  onAddStepSuccess = () => {},
}) => {
  const [showTransactionForm, setShowTransactionForm] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);
  const [filterStep, setFilterStep] = React.useState(null);

  const { id: documentId } = useParams();

  const stepStatusList = [
    "Open",
    "In Progress",
    "Completed",
    "Not Applicable",
    "Withdrawn",
  ];

  const activeWorkflowSteps = workflow?.steps?.filter((step) => step.isActive);

  const INITIAL_STATE = {
    step: "",
    status: "Open",
    note: "",
    entryDate: moment().format("YYYY-MM-DD"),
  };

  const transactionColumns = [
    {
      name: "Step",
      cell: (row) =>
        workflow?.steps?.find((wfs) => wfs._id === row.step)?.title ?? "N/A",
    },
    {
      name: "Status",
      cell: (row) => row?.status,
    },
    {
      name: "Entry Date",
      cell: (row) => new Date(row?.entryDate).toLocaleDateString(),
    },
    {
      name: "Updated At",
      cell: (row) => new Date(row?.updatedAt).toLocaleDateString(),
    },
    {
      name: "Created By",
      selector: (row) => row?.createdBy?.name,
    },
    {
      name: "Updated By",
      selector: (row) => row?.updatedBy?.name ?? "N/A",
    },
    {
      name: "Actions",
      cell: (row) => (
        <IconButton
          size="small"
          aria-label="Edit Transaction"
          onClick={() => setSelectedTransaction(row)}
        >
          <ModeEditOutlineTwoTone fontSize="small" />
        </IconButton>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const completedSteps = React.useMemo(() => {
    return activeWorkflowSteps.filter((step) => {
      return !!workflowTransactions.find(
        (st) => st.step === step._id && st.status === "Completed"
      );
    });
  }, [activeWorkflowSteps, workflowTransactions]);

  const unCompletedSteps = React.useMemo(() => {
    const completedStepIds = completedSteps.map((cs) => cs._id);
    return activeWorkflowSteps?.filter(
      (step) => !completedStepIds.includes(step._id)
    );
  }, [activeWorkflowSteps, completedSteps]);

  const filteredTransactions = React.useMemo(() => {
    return !filterStep
      ? workflowTransactions
      : workflowTransactions?.filter((el) => el?.step === filterStep?._id);
  }, [workflowTransactions, filterStep]);

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  // RTK Query
  const [createPathwayTransaction, { isLoading: creatingTransaction }] =
    useCreateWorkflowTransactionMutation();

  const [updateWorkflowStepTransaction, { isLoading: updatingTransaction }] =
    useUpdateWorkflowTransactionMutation();

  // methods
  const handleCancel = () => {
    setShowTransactionForm(false);
    setSelectedTransaction(null);
    reset({ ...INITIAL_STATE });
    // onAddStepSuccess();
  };

  const handleCreateTransaction = async (data) => {
    try {
      await createPathwayTransaction({
        ...data,
        workflow: workflow?._id,
        assignee: documentId,
        assigneeSource,
      }).unwrap();
      toast.success("Workflow step transaction successfully created!");
      handleCancel();
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't create workflow step transaction!"
      );
    }
  };

  const handleUpdateTransaction = async (data) => {
    try {
      await updateWorkflowStepTransaction({
        ...data,
      }).unwrap();
      toast.success("Workflow step transaction successfully updated!");
      setSelectedTransaction(null);
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't update workflow step transaction!"
      );
    }
  };

  React.useEffect(() => {
    if (!step) return;

    const currentStatusIndex = stepStatusList.findIndex(
      (status) => status === step?.currentStepStatus
    );

    const currentValues = getValues();

    reset({
      ...currentValues,
      step: step?._id,
      note: step?.title,
      status: stepStatusList[currentStatusIndex],
      entryDate: moment().format("YYYY-MM-DD"),
    });

    setShowTransactionForm(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, reset, getValues]);

  React.useEffect(() => {
    reset({
      ...selectedTransaction,
      workflow: selectedTransaction?.workflow?._id,
      entryDate: moment(selectedTransaction?.entryDate).format("YYYY-MM-DD"),
    });

    return () => {
      reset({ ...INITIAL_STATE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTransaction]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        {!showTransactionForm && !selectedTransaction && (
          <>
            <div className="w-1/4">
              <Autocomplete
                options={activeWorkflowSteps}
                getOptionLabel={(option) => option?.title}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Step"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Workflow Step"
                  />
                )}
                value={filterStep}
                onChange={(e, data) => setFilterStep(data)}
              />
            </div>

            {!!workflow.steps.length &&
              (!unCompletedSteps.length ? (
                <Typography color="green">All Steps are completed!</Typography>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setShowTransactionForm(true)}
                >
                  Create Update
                </Button>
              ))}
          </>
        )}
      </Box>

      <Divider style={{ marginTop: -1 }} />

      <Collapse in={showTransactionForm || selectedTransaction}>
        <Box
          padding={3}
          className="expanded-form"
          style={{ position: "relative" }}
        >
          <Grid container spacing={4}>
            <Grid item md={4}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Controller
                    name="step"
                    control={control}
                    rules={{ required: "Please select a step" }}
                    render={({ field, value }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="select-step">Step</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-step"
                          label="Step"
                          placeholder="Select Step"
                          size="small"
                          id="role"
                          value={value ? value : ""}
                          {...field}
                          disabled={!!selectedTransaction}
                        >
                          {activeWorkflowSteps?.map((step) => (
                            <MenuItem key={step._id} value={step._id}>
                              {step?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.step && (
                    <p className="error">{errors.step.message}</p>
                  )}
                </Grid>

                <Grid item md={12}>
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: "Please select a status" }}
                    render={({ field, value }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="select-status">Status</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-status"
                          label="Status"
                          placeholder="Select Status"
                          size="small"
                          id="role"
                          value={value ? value : "Open"}
                          {...field}
                        >
                          {stepStatusList?.map((status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.status && (
                    <p className="error">{errors.status.message}</p>
                  )}
                </Grid>

                <Grid item md={12}>
                  <Controller
                    name="entryDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Entry Date"
                        type="date"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={8}>
              <Controller
                name="note"
                control={control}
                rules={{ required: "Note is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Note"
                    placeholder="Leave a note"
                    multiline
                    rows={3}
                  />
                )}
              />
              {errors.note && <p className="error">{errors.note.message}</p>}
            </Grid>
          </Grid>
        </Box>

        <Box
          paddingX={3}
          paddingY={2}
          gap={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#fafafa",
          }}
        >
          <Button onClick={handleCancel}>Cancel</Button>

          <Button
            variant="outlined"
            disabled={creatingTransaction || updatingTransaction}
            onClick={handleSubmit((data) =>
              selectedTransaction
                ? handleUpdateTransaction(data)
                : handleCreateTransaction(data)
            )}
          >
            {selectedTransaction ? "Update" : "Create"}
          </Button>
        </Box>

        <Divider />
      </Collapse>

      <AppTable
        columns={transactionColumns}
        data={filteredTransactions}
        persistTableHead={true}
        pagination
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedRow>{data?.note}</ExpandedRow>
        )}
      />
    </>
  );
};

export default ManageWorkflowStepTransactions;
