import React from "react";

import { Box, Skeleton } from "@mui/material";

const SearchResultSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2} padding={2}>
      {new Array(3).fill(null).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={100} />
      ))}
    </Box>
  );
};

export default SearchResultSkeleton;
