import React from "react";

import { Route, Routes } from "react-router-dom";

import AccountLvlAuthorizationListScreen from "../screens/Admin/Authorization/AccountLvlAuthorizationListScreen";
import EntityLvlAuthorizationListScreen from "../screens/Admin/Authorization/EntityLvlAuthorizationListScreen";

// middlewares
import AccountAdminRoute from "../routes/middlewares/AccountAdminRoute";

const AuthorizationRouter = () => {
  return (
    <Routes>
      <Route path="list">
        <Route
          path="account"
          element={
            <AccountAdminRoute component={AccountLvlAuthorizationListScreen} />
          }
        />
        <Route path="entity" element={<EntityLvlAuthorizationListScreen />} />
      </Route>
    </Routes>
  );
};

export default AuthorizationRouter;
