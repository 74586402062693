import moment from "moment";

export const getDateDifference = (date1, date2) => {
  const _endDate = moment(date1 ?? Date.now());
  const _startDate = moment(date2 ?? Date.now());

  const _difference = Math.round(_endDate.diff(_startDate, "days", true));

  return _difference;
};
