import { baseApi } from "./baseApi";

const TASK_BASE_URL = "/tasks";

export const taskApi = baseApi.injectEndpoints({
  tagTypes: ["Task"],

  endpoints: (builder) => ({
    getTaskList: builder.query({
      query: () => `${TASK_BASE_URL}/task-list`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Task", _id })),
              { type: "Task", id: "LIST" },
            ]
          : [{ type: "Task", id: "LIST" }],
    }),

    getTaskDetail: builder.query({
      query: (id) => `${TASK_BASE_URL}/task-edit/${id}`,
      providesTags: (result, error, id) => [
        { type: "Task", id },
        { type: "Task", id: "LIST" },
      ],
    }),

    createTask: builder.mutation({
      query: (payload) => ({
        url: `${TASK_BASE_URL}/task-create`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Task", id: "LIST" },
      ],
    }),

    updateTask: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${TASK_BASE_URL}/task-edit/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Task", _id },
        { type: "Task", id: "LIST" },
      ],
    }),

    addResponseToTask: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${TASK_BASE_URL}/task-details/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Task", _id },
        { type: "Task", id: "LIST" },
      ],
    }),

    getTaskByAssignee: builder.query({
      query: (assignee) => `${TASK_BASE_URL}/students/assignee/${assignee}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Task", _id })),
              { type: "Task", id: "LIST" },
            ]
          : [{ type: "Task", id: "LIST" }],
    }),
  }),
});

export const {
  useGetTaskDetailQuery,
  useGetTaskListQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useAddResponseToTaskMutation,
  useGetTaskByAssigneeQuery,
} = taskApi;
