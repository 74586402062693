import React, { useState } from "react"
// import Carousel from "react-elastic-carousel";
import { Container, Typography } from "@mui/material"
import australia from "../screens/assets/images/sydney.png"
import canada from "../screens/assets/images/Canada2.png"
import uk from "../screens/assets/images/UK2.png"
import usa from "../screens/assets/images/USA2.png"

// import melbourne from "../screens/assets/images/melbourne.png"
// import perth from "../screens/assets/images/perth.png"
// import brisbane from "../screens/assets/images/brisbane.png"

// import adelaide from "../screens/assets/images/adelaide.png"
// import coast from "../screens/assets/images/coast.png"
// import canberra from "../screens/assets/images/canberra.png"
// import darwin from "../screens/assets/images/darwin.png"
// import hobart from "../screens/assets/images/hobart.png"

import Slider from "react-slick"
import { Link } from "react-router-dom"

export default function StudyAbroad() {
  const settings = {
    dots: false,
    slidesToShow: 4,
    arrows: false,
    // infinite: true,
    // slidesToScroll: 1,
    // autoplay: true,
    // speed: 8000,
    // autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  const [items] = useState([0, 1, 2, 3])

  const imgList = [australia, canada, uk, usa]

  const titleLisT = ["Australia", "Canada", "UK", "USA"]
  return (
    <Container maxWidth='xl' sx={{ my: "4rem" }}>
      <Typography align='center'>
        <h1 className='StudyAbroadH1'> Study Abroad</h1>
        {/* <h1 className='StudyAbroadH1'> Study in Australia </h1> */}
      </Typography>
      <Typography mb={5} align='center'>
        <h6 className='ChooseDestiation'> Choose your destination </h6>
      </Typography>
      <div className='carousel-wrapper'>
        <Slider {...settings}>
          {items.map((item) => (
            <Link to={`/study-abroad/popular-destinations/${titleLisT[item]}`}>
              <div style={{ position: "relative" }}>
                <div key={item}>
                  <img
                    src={imgList[item]}
                    alt={titleLisT[item]}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                <div className='StudyAbroadCarouselImage'>
                  <p>{titleLisT[item]}</p>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </Container>
  )
}
