import React from "react";

import { Route, Routes } from "react-router-dom";

import AccountUserListScreen from "../screens/Admin/User/account/AccountUserListScreen";
import ChangePasswordScreen from "../screens/Admin/User/profile/ChangePasswordScreen";
import EditUserProfileScreen from "../screens/Admin/User/profile/EditUserProfileScreen";
import UserCreateScreen from "../screens/Admin/User/UserCreateScreen";
import UserDetailScreen from "../screens/Admin/User/UserDetailScreen";
import UserEditScreen from "../screens/Admin/User/UserEditScreen";
import UserProfileScreen from "../screens/Admin/User/profile/UserProfileScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";
import AccountUserCreateScreen from "../screens/Admin/User/account/AccountUserCreateScreen";
import AccountUserDetailScreen from "../screens/Admin/User/account/AccountUserDetailScreen";
import AccountUserEditScreen from "../screens/Admin/User/account/AccountUserEditScreen";
import AccountUserChangePasswordScreen from "../screens/Admin/User/account/AccountUserChangePasswordScreen";

const UserRouter = () => {
  return (
    <Routes>
      <Route path="me" element={<UserProfileScreen />} />
      <Route path="me/update" element={<EditUserProfileScreen />} />
      <Route path="change-password" element={<ChangePasswordScreen />} />

      <Route path="create" element={<UserCreateScreen />} />
      <Route path=":id/detail" element={<UserDetailScreen />} />
      <Route path=":id/edit" element={<UserEditScreen />} />

      <Route path="account">
        <Route path="list" element={<AccountUserListScreen />} />
        <Route path="add" element={<AccountUserCreateScreen />} />
        <Route path=":id/detail" element={<AccountUserDetailScreen />} />
        <Route path=":id/edit" element={<AccountUserEditScreen />} />
        <Route
          path=":id/change-password"
          element={<AccountUserChangePasswordScreen />}
        />
      </Route>

      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default UserRouter;
