import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { PersonPinOutlined } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const ClientResult = ({ clients = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Clients</div>

      {clients.map((client, index) => (
        <Link to={`/app/client/${client?._id}/detail`} key={client?._id}>
          <ResultItem
            title={`${client.firstName} ${client.lastName}`}
            subtitle={client.createdAt}
            icon={() => <PersonPinOutlined color="primary" />}
          />

          {index !== clients.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default ClientResult;
