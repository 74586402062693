import React from "react";

import LottieAnimationWrapper from "../Lottie/LottieAnimationWrapper";

import globeAnimation from "../../../assets/lottie/globe.json";

const TableLoader = () => {
  return <LottieAnimationWrapper lottieFile={globeAnimation} height={300} />;
};

export default TableLoader;
