import React from "react";
import { Link, Outlet } from "react-router-dom";

import AppLogo from "../assets/fsw-logo.png";

const GuestLayoutV2 = () => {
  return (
    <>
      <div className="shadow-sm">
        <div className="container relative mx-auto max-w-7xl py-2">
          <Link to="/">
            <img src={AppLogo} alt="Logo" className="-ml-2 h-14" />
          </Link>
        </div>
      </div>

      <div className="container relative mx-auto max-w-7xl">
        <div className="py-8">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default GuestLayoutV2;
