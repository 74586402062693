import React from "react";
import { Link } from "react-router-dom";
import { Box, Checkbox, IconButton } from "@mui/material";
import { DeleteForeverOutlined } from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import AgentPromotions from "./AgentPromotionsBadge";

const FavouriteCourseCard = ({
  favCourse,
  checked,
  onRemove = () => {},
  onSelection = () => {},
}) => {
  return (
    <Box
      display="flex"
      alignItems="start"
      gap={1.5}
      paddingY={3}
      className="group"
    >
      <div className="grid flex-grow grid-cols-1 gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Checkbox
              size="small"
              checked={checked}
              onChange={(e) => onSelection(favCourse)}
              style={{ padding: 0 }}
            />

            <Link
              to={`/app/course/${favCourse?.course?._id}/detail`}
              target="_blank"
            >
              <div className="cursor-pointer font-medium text-blue-600 group-hover:underline">
                {favCourse?.course?.title}
              </div>
            </Link>
          </div>

          <AgentPromotions
            promotions={favCourse?.course?.agentPromotions?.map(
              (el) => el?.name
            )}
          />
        </div>

        {!!favCourse?.course?.location && (
          <div className="flex items-center space-x-2">
            <div>{favCourse?.course?.location?.name}</div>

            {!!favCourse?.course?.subLocations?.length &&
              favCourse?.course?.subLocations?.map((subLoc) => (
                <React.Fragment key={subLoc?._id}>
                  <span>•</span>
                  <div>{subLoc?.name}</div>
                </React.Fragment>
              ))}
          </div>
        )}

        <div className="flex space-x-2">
          {favCourse?.course?.duration && (
            <div>{favCourse?.course?.duration?.name}</div>
          )}

          {favCourse?.course?.level && (
            <div className="text-gray-600">
              {favCourse?.course?.level?.name} Level
            </div>
          )}

          <div
            onClick={() =>
              window.open(
                `/app/university/${favCourse?.course?.university?._id}/detail`,
                "_blank"
              )
            }
            className="cursor-pointer group-hover:text-blue-600 "
          >
            <span>@</span>
            <span>{favCourse?.course?.university?.title}</span>
          </div>
        </div>
        {favCourse?.course?.tuitionFee > 0 && (
          <div>
            {favCourse?.course?.currency?.symbol}{" "}
            {favCourse?.course?.tuitionFee} ({favCourse?.course?.currency?.code}
            ) Per {favCourse?.course?.feeType}
          </div>
        )}
        {favCourse?.course?.summary && (
          <div className="line-clamp-3">
            {ReactHtmlParser(favCourse?.course?.summary)}
          </div>
        )}
      </div>

      <div className="-mt-2 translate-x-full opacity-0 transition-all duration-150 ease-in group-hover:translate-x-0 group-hover:opacity-100">
        <IconButton onClick={() => onRemove(favCourse)}>
          <DeleteForeverOutlined color="error" />
        </IconButton>
      </div>
    </Box>
  );
};

export default FavouriteCourseCard;
