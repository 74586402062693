import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Divider } from "@mui/material";

import AppDialog from "../../../../../components/Shared/AppDialog";
import StudyOptionCard from "../../../../../components/StudyOption/StudyOptionCard";
import StudyOptionCourseCard from "../../../../../components/StudyOption/StudyOptionCourseCard";

import {
  useRemoveStudyOptionMutation,
  useUpdateSelectedStudyOptionMutation,
} from "../../../../../rtkStore/services/clientApi";

import { useClientDetail } from "../ClientBasicInformationTab";

const ClientStudyOptionsTab = () => {
  const [selectedStudyOption, setSelectedStudyOption] = React.useState(null);

  const { id: clientId } = useParams();

  const {
    clientDetail: { studyOptions },
  } = useClientDetail();

  const sortedStudyOptions = [...studyOptions]?.sort((a, b) =>
    a?.createdAt > b?.createdAt ? -1 : 1
  );

  // RTK Query
  const [removeStudyOption, { isLoading }] = useRemoveStudyOptionMutation();

  // methods
  const handleDeleteStudyOption = async () => {
    if (!selectedStudyOption) return;
    setSelectedStudyOption(selectedStudyOption);

    try {
      await removeStudyOption({
        clientId,
        studyOption: selectedStudyOption,
      }).unwrap();
      toast.info("Study option successfully removed!");
      setSelectedStudyOption(null);
    } catch (err) {
      toast.err(err?.data?.message ?? "Couldn't remove study option!");
    }
  };

  return (
    <>
      <Box paddingX={3}>
        {sortedStudyOptions?.length ? (
          sortedStudyOptions?.map((el, index) => (
            <React.Fragment key={index}>
              <StudyOptionCard
                studyOption={el}
                studyOptionsLink={`/study-options/${el?._id}/clients/${clientId}`}
                courseCardComponent={(props) => (
                  <ClientStudyOptionCourse {...props} />
                )}
                onDelete={(studyOption) => setSelectedStudyOption(studyOption)}
              />
              {index !== sortedStudyOptions.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Study Options Generated</Box>
        )}
      </Box>

      <AppDialog
        title="Confirm Deletion!"
        maxWidth="sm"
        open={!!selectedStudyOption}
        onClose={() => setSelectedStudyOption(null)}
        onConfirm={handleDeleteStudyOption}
        isBusy={isLoading}
      >
        Are you sure you want to delete the study option?
      </AppDialog>
    </>
  );
};

export default ClientStudyOptionsTab;

export const ClientStudyOptionCourse = ({ course, studyOption }) => {
  const { id: clientId } = useParams();

  const courseIsSelected = studyOption?.selectedCourse === course?._id;

  // RTK Query
  const [
    updateSelectedStudyOption,
    { isLoading: updatingSelectedStudyOption },
  ] = useUpdateSelectedStudyOptionMutation();

  // methods
  const handleUpdateSelectedStudyOption = async () => {
    try {
      await updateSelectedStudyOption({
        clientId,
        studyOptionId: studyOption?._id,
        selectedCourse: course?._id,
      }).unwrap();
      toast.info("Study option selected course updated!");
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't update selected study option course!"
      );
    }
  };

  return (
    <StudyOptionCourseCard
      isBusy={updatingSelectedStudyOption}
      course={course}
      isCourseSelected={courseIsSelected}
      onSelectCourse={handleUpdateSelectedStudyOption}
    />
  );
};
