import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  CampaignOutlined,
  InfoOutlined,
  AccountBalanceOutlined,
  LinkOutlined,
  PaidOutlined,
  TimelapseOutlined,
  WorkspacePremiumOutlined,
  LanguageOutlined,
  CorporateFareOutlined,
  SchoolOutlined,
  AbcOutlined,
  FileDownloadOutlined,
  MilitaryTechOutlined,
  ChevronRight,
  LayersOutlined,
  CodeOutlined,
  SpeedOutlined,
  StyleOutlined,
} from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import InformationTile from "../../../../components/Shared/InformationTile";
import OpenInNewTab from "../../../../components/Shared/OpenInNewTab";

const CourseBasicDetailTab = ({ courseDetail }) => {
  // methods
  const getCountryOrientedCurrency = (amount, currency) => {
    if (!amount) return "N/A";
    return `${currency?.symbol ?? ""} ${amount} ${
      currency?.code ? `(${currency?.code})` : ""
    }`;
  };

  return (
    <>
      <Box padding={3}>
        <Typography variant="h6">{courseDetail?.title}</Typography>

        <br />

        <Grid container columnSpacing={6} rowSpacing={4}>
          <Grid item md={4}>
            <InformationTile
              icon={() => <WorkspacePremiumOutlined color="primary" />}
              title="Level"
              subtitle={courseDetail?.level?.name}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <AccountBalanceOutlined color="primary" />}
              title="Affiliated University"
            >
              <OpenInNewTab
                label={courseDetail?.university?.title}
                link={`/app/university/${courseDetail?.university?._id}/detail`}
              />
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <LayersOutlined color="primary" />}
              title="Course Field"
            >
              {courseDetail?.field ? (
                <div className="flex flex-col">
                  <div>{courseDetail?.field?.name}</div>

                  {courseDetail?.subfield && (
                    <div className="-ml-1.5 flex items-center space-x-1">
                      <ChevronRight
                        fontSize="small"
                        style={{ color: "gray" }}
                      />

                      <div> {courseDetail?.subfield?.name}</div>
                    </div>
                  )}
                </div>
              ) : (
                "N/A"
              )}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <TimelapseOutlined color="primary" />}
              title="Duration"
              subtitle={courseDetail?.duration?.name}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <LanguageOutlined color="primary" />}
              title="Course Language"
              subtitle={courseDetail?.languageOfTuition}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <PaidOutlined color="primary" />}
              title="Tuition Fee"
            >
              <span>
                {getCountryOrientedCurrency(
                  courseDetail?.tuitionFee,
                  courseDetail?.currency
                )}
              </span>
              {courseDetail?.tuitionFee > 0 && (
                <>
                  <span className="text-gray-500"> per </span>
                  <span className="capitalize"> {courseDetail?.feeType} </span>
                </>
              )}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <PaidOutlined color="primary" />}
              title="Application Fee"
              subtitle={getCountryOrientedCurrency(
                courseDetail?.applicationFee,
                courseDetail?.currency
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <SchoolOutlined color="primary" />}
              title="Academic Requirement"
              subtitle={courseDetail?.academicRequirement?.name}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <AbcOutlined color="primary" />}
              title="English Requirement"
              subtitle={courseDetail?.englishRequirement?.name}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <MilitaryTechOutlined color="primary" />}
              title="Reputation"
              subtitle={courseDetail?.reputation?.name}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <CodeOutlined color="primary" />}
              title="Course Code"
              subtitle={courseDetail?.courseCode}
            />
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <InfoOutlined color="primary" />}
              title="Status"
            >
              <div
                className={`uppercase ${
                  courseDetail?.isActive ? "text-green-600" : "text-red-600"
                }`}
              >
                {courseDetail?.isActive ? "Active" : "Inactive"}
              </div>
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <FileDownloadOutlined color="primary" />}
              title="Intakes"
            >
              {courseDetail?.intakes?.length ? (
                <ul className="ml-3 flex list-disc flex-col space-y-1">
                  {courseDetail?.intakes?.map(({ _id, name }) => (
                    <li key={_id}>{name}</li>
                  ))}
                </ul>
              ) : (
                "N/A"
              )}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <CorporateFareOutlined color="primary" />}
              title="Campus Areas"
            >
              {courseDetail?.campusAreas?.length
                ? courseDetail?.university?.campusAreas
                    ?.filter((ca) =>
                      courseDetail?.campusAreas?.includes(ca?._id)
                    )
                    ?.map((el) => (
                      <li className="ml-4" key={el?._id}>
                        {el?.name}
                      </li>
                    ))
                : "N/A"}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <CorporateFareOutlined color="primary" />}
              title="Locations"
            >
              {courseDetail?.subLocations?.length
                ? courseDetail?.subLocations?.map((loc) => (
                    <li key={loc?._id} className="ml-3">
                      {loc?.name}
                    </li>
                  ))
                : "N/A"}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <StyleOutlined color="primary" />}
              title="Tags"
            >
              {courseDetail?.tags?.length
                ? courseDetail?.tags?.map((promotion) => (
                    <li key={promotion?._id} className="ml-3">
                      {promotion?.name}
                    </li>
                  ))
                : "N/A"}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <CampaignOutlined color="primary" />}
              title="Agent Promotions"
            >
              {courseDetail?.agentPromotions?.length
                ? courseDetail?.agentPromotions?.map((promotion) => (
                    <li key={promotion?._id} className="ml-3">
                      {promotion?.name}
                    </li>
                  ))
                : "N/A"}
            </InformationTile>
          </Grid>

          <Grid item md={4}>
            <InformationTile
              icon={() => <SpeedOutlined color="primary" />}
              title="Priority Level"
            >
              Level {courseDetail?.priorityLevel}
            </InformationTile>
          </Grid>

          <Grid item md={8}>
            <InformationTile
              icon={() => <LinkOutlined color="primary" />}
              title="Course Link"
            >
              {courseDetail?.courseLink ? (
                <a
                  href={courseDetail?.courseLink}
                  target="_blank"
                  rel="noreferrer"
                  className="custom-link line-clamp-2"
                >
                  {courseDetail?.courseLink}
                </a>
              ) : (
                "N/A"
              )}
            </InformationTile>
          </Grid>
        </Grid>

        {courseDetail?.summary && (
          <Box marginTop={4}>{ReactHtmlParser(courseDetail?.summary)}</Box>
        )}
      </Box>

      {courseDetail?.learningOutcomes && (
        <>
          <Divider />

          <Box padding={3}>
            <div className="pb-1 text-xs text-gray-500">Learning Outcomes</div>

            <Box>{ReactHtmlParser(courseDetail?.learningOutcomes)}</Box>
          </Box>
        </>
      )}

      <Divider />

      <Box padding={3}>
        <div className="pb-1 text-xs text-gray-500">Admission</div>
        {courseDetail?.addmissionRequirement?.addmissionRequirement ? (
          <Box>
            {ReactHtmlParser(
              courseDetail?.addmissionRequirement?.addmissionRequirement
            )}
          </Box>
        ) : (
          "N/A"
        )}
      </Box>

      {courseDetail?.addmissionRequirement?.englishRequirement && (
        <>
          <Divider />
          <Box padding={3}>
            <div className="pb-1 text-xs text-gray-500">Language </div>
            <Box>
              {ReactHtmlParser(
                courseDetail?.addmissionRequirement?.englishRequirement
              )}
            </Box>
          </Box>
        </>
      )}

      {courseDetail?.courseDateSummary && (
        <>
          <Divider />
          <Box padding={3}>
            <div className="pb-1 text-xs text-gray-500">
              Course Dates Summary
            </div>

            <Box>{ReactHtmlParser(courseDetail?.courseDateSummary)}</Box>
          </Box>
        </>
      )}

      {courseDetail?.scholarshipSummary && (
        <>
          <Divider />
          <Box padding={3}>
            <div className="pb-1 text-xs text-gray-500">
              Scholarship Summary
            </div>

            <Box>{ReactHtmlParser(courseDetail?.scholarshipSummary)}</Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CourseBasicDetailTab;
