import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import whiteLogo from '../assets/fsw-logoWhite.png'
import CounselingIcon from '../screens/assets/images/icons/counselingIcon.png'
import visaIcon from '../screens/assets/images/icons/visaIcon.png'
import locationIcon from '../screens/assets/images/icons/LocationIcon.png'
import noFeeIcon from '../screens/assets/images/icons/noFeeIcon.png'

// import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
// import PublicIcon from "@mui/icons-material/Public"
// import DesignServicesIcon from "@mui/icons-material/DesignServices"
// import SchoolIcon from "@mui/icons-material/School"

export const WhyFsw = () => {
  return (
    <div className='whyFswSection'>
      <Container maxWidth='xl'>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={5}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Box
              sx={{
                height: '100%',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={{
                  color: '#fff',
                  fontSize: '65px',
                }}
              >
                WHY
              </Typography>

              <img className='whiteLogo' src={whiteLogo} alt='logoImage' />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={7}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='body2'
                  color='common.white'
                  sx={{ padding: '1.5rem', fontSize: '16px' }}
                >
                  FSW Education & Visa Services has helped students from
                  Southeast Asia for the past 7 years in making their dreams a
                  reality. With our extensive experience, personalized guidance,
                  global opportunities, and commitment to changing our students’
                  lives, you can expect professional support and a strong
                  foundation for a successful educational journey.
                </Typography>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Box className='whyFSWIconBox'>
                  <Box className='whyFSWIcons'>
                    <img
                      src={CounselingIcon}
                      alt='logoImage'
                      style={{
                        width: '50%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ fontSize: 18, fontWeight: 600 }}
                    color='common.white'
                  >
                    Personalized counseling
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Box className='whyFSWIconBox'>
                  <Box className='whyFSWIcons'>
                    <img
                      src={locationIcon}
                      alt='logoImage'
                      style={{
                        width: '50%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: 600 }}
                    color='common.white'
                  >
                    Serving 8 countries
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Box className='whyFSWIconBox'>
                  <Box className='whyFSWIcons'>
                    <img
                      src={noFeeIcon}
                      alt='logoImage'
                      style={{
                        width: '60%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: 600 }}
                    color='common.white'
                  >
                    No processing <br />
                    fee
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Box className='whyFSWIconBox'>
                  <Box className='whyFSWIcons'>
                    <img
                      src={visaIcon}
                      alt='logoImage'
                      style={{
                        width: '50%',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{ fontSize: 18, fontWeight: 600 }}
                    color='common.white'
                  >
                    High visa success rate
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
