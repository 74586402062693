import React from "react";

import { arrayIsEqual } from "../utils/arrayHelpers";

export const useReOrderDraggableList = ({
  listItems,
  serialNumberInitials = "SN",
  disableSN = false,
}) => {
  const [orderedList, setOrderedList] = React.useState([]);

  const currentListRef = React.useRef([...listItems]); //creating reference of the listItem to track the change of list-items

  // methods
  const handleOnDragEnd = (dragResult) => {
    const { destination, source, draggableId } = dragResult;

    if (!destination) return;
    if (
      destination.droppableId === source?.droppableId &&
      destination.index === source.index
    )
      return;

    const draggedItem = listItems.find((el) => el._id === draggableId);

    const _tempListItems = [...orderedList];
    _tempListItems.splice(source.index, 1);
    _tempListItems.splice(destination.index, 0, draggedItem);

    // overriding serial numbers
    const reOrderedList = _tempListItems?.map((el, index) => ({
      ...el,
      serialNumber: !disableSN
        ? `${serialNumberInitials}-${index + 1}`
        : index + 1,
    }));

    setOrderedList(reOrderedList);
  };

  React.useEffect(() => {
    if (!listItems?.length) return;

    // to avoid re-rendering when no new item is added
    // if (currentListRef && currentListRef.current.length === orderedList?.length)
    //   return;

    if (arrayIsEqual(listItems, orderedList)) return;

    const _collatorInstance = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    const _alphaNumericSorted =
      [...listItems].sort(({ serialNumber: sna }, { serialNumber: snb }) => {
        return _collatorInstance.compare(sna, snb) > 0 ? 1 : -1;
      }) ?? [];

    setOrderedList([..._alphaNumericSorted]);

    if (currentListRef) currentListRef.current = [..._alphaNumericSorted];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems]);

  return { orderedList, handleOnDragEnd };
};
