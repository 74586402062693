import { baseApi } from "./baseApi";

const CLIENT_BASE_URL = "/clients";

export const clientApi = baseApi.injectEndpoints({
  tagTypes: ["Client"],

  endpoints: (builder) => ({
    getClientList: builder.query({
      query: (params) => ({
        url: `${CLIENT_BASE_URL}`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Client", _id })),
              { type: "Client", id: "LIST" },
            ]
          : [{ type: "Client", id: "LIST" }],
    }),

    getClientDetail: builder.query({
      query: (id) => `${CLIENT_BASE_URL}/${id}`,
      providesTags: (result, error, id) => [
        { type: "Client", id },
        { type: "Client", id: "LIST" },
      ],
    }),

    createClient: builder.mutation({
      query: (payload) => ({
        url: `${CLIENT_BASE_URL}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", id: "LIST" },
      ],
    }),

    updateClient: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // client education detail
    addEducationDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/educations/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    updateEducationDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/educations`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    removeEducationDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/educations`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // client employment detail
    addEmploymentDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/employments/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    updateEmploymentDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/employments`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    removeEmploymentDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/employments`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // client test detail
    addTestDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/tests/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    updateTestDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/tests`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    removeTestDetail: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/tests`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // client notes
    addNote: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/notes/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    updateNote: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/notes`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    listClientAccumulatedNotes: builder.query({
      query: (clientId) => `${CLIENT_BASE_URL}/${clientId}/notes/accumulated`,
    }),

    // client workflows
    addWorkflowToClient: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/workflow/add`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Workflow", id: "LIST" },
        { type: "Task", id: "LIST" },
      ],
    }),

    addStepToClientWorkflow: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/workflow/step`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // client's documents
    addDocument: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/documents/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    updateDocument: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/documents`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    // favourite course
    addFavouriteCourseToClient: builder.mutation({
      query: (payload) => {
        const { clientId, ...body } = payload;

        return {
          url: `${CLIENT_BASE_URL}/${clientId}/favourite-courses`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    removeClientFavouriteCourse: builder.mutation({
      query: ({ clientId, _id }) => ({
        url: `${CLIENT_BASE_URL}/${clientId}/favourite-courses/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Client", _id },
        { type: "Client", id: "LIST" },
      ],
    }),

    reOrderClientFavouriteCourses: builder.mutation({
      query: ({ clientId, ...body }) => ({
        url: `${CLIENT_BASE_URL}/${clientId}/favourite-courses/re-order`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Client", _id }],
    }),

    // next action
    getClientsByNextAction: builder.query({
      query: () => `${CLIENT_BASE_URL}/next-actions`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Client", _id })),
              { type: "Client", id: "LIST" },
            ]
          : [{ type: "Client", id: "LIST" }],
    }),

    getClientsByNoRecentActivity: builder.query({
      query: (queryParams) => {
        return {
          url: `${CLIENT_BASE_URL}/recent-activities`,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Client", _id })),
              { type: "Client", id: "LIST" },
            ]
          : [{ type: "Client", id: "LIST" }],
    }),

    // study options
    addStudyOptionToClient: builder.mutation({
      query: ({ clientId, ...body }) => ({
        url: `${CLIENT_BASE_URL}/${clientId}/study-options`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Client", _id }],
    }),

    removeStudyOption: builder.mutation({
      query: ({ clientId, studyOption }) => ({
        url: `${CLIENT_BASE_URL}/${clientId}/study-options/${studyOption}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Client", _id }],
    }),

    listClientStudyOption: builder.query({
      query: ({ clientId, id }) =>
        `${CLIENT_BASE_URL}/${clientId}/study-options/${id}`,
      providesTags: (result, error, id) => [
        { type: "Client", id },
        { type: "Client", id: "STUDY_OPTIONS" },
      ],
    }),

    updateSelectedStudyOption: builder.mutation({
      query: ({ clientId, studyOptionId, ...body }) => ({
        url: `${CLIENT_BASE_URL}/${clientId}/study-options/${studyOptionId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Client", _id }],
    }),
  }),
});

export const {
  useGetClientDetailQuery,
  useGetClientListQuery,
  useLazyGetClientListQuery,
  useCreateClientMutation,
  useUpdateClientMutation,

  useAddEducationDetailMutation,
  useUpdateEducationDetailMutation,
  useRemoveEducationDetailMutation,

  useAddEmploymentDetailMutation,
  useUpdateEmploymentDetailMutation,
  useRemoveEmploymentDetailMutation,

  useAddTestDetailMutation,
  useUpdateTestDetailMutation,
  useRemoveTestDetailMutation,

  useAddNoteMutation,
  useUpdateNoteMutation,
  useListClientAccumulatedNotesQuery,

  useAddWorkflowToClientMutation,
  useAddStepToClientWorkflowMutation,

  useAddDocumentMutation,
  useUpdateDocumentMutation,

  useAddFavouriteCourseToClientMutation,
  useRemoveClientFavouriteCourseMutation,
  useReOrderClientFavouriteCoursesMutation,

  useGetClientsByNextActionQuery,
  useGetClientsByNoRecentActivityQuery,

  useAddStudyOptionToClientMutation,
  useRemoveStudyOptionMutation,
  useListClientStudyOptionQuery,
  useUpdateSelectedStudyOptionMutation,
} = clientApi;
