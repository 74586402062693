import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyGetWorkflowListQuery } from "../../../rtkStore/services/workflowApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const WorkflowListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Workflows",
    },
  ];

  const workflowListTabs = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  // RTK Query
  const [
    listWorkflows,
    {
      data: workflowList,
      isLoading: loadingWorkflows,
      isFetching: fetchingWorkflows,
      error,
    },
  ] = useLazyGetWorkflowListQuery();

  const filterableWorkflows = filterArrayByKeys(
    workflowList,
    filterText,
    "title",
    "workflowType"
  );

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/workflow/${row?._id}/detail`}
          title={row?.title}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
        >
          {row?.title}
        </Link>
      ),
      minWidth: "300px",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.workflowType ?? "N/A",
    },
    {
      name: "Steps Involved",
      cell: (row) => (
        <>
          {row.steps.length ? (
            <Typography>
              <span style={{ color: "var(--primary)", marginRight: 6 }}>
                {row?.steps?.length}
              </span>
              <span style={{ color: "gray" }}>steps</span>
            </Typography>
          ) : (
            <Link
              to={`/app/workflow/${row._id}/detail?addStep=true`}
              style={{ color: "var(--primary)", fontSize: 14 }}
            >
              Add Steps
            </Link>
          )}
        </>
      ),
    },
    {
      name: "Created By",
      selector: (row) => row?.createdBy?.name,
      cell: (row) => (
        <div title={row?.createdBy?.name} className="line-clamp-2">
          {row?.createdBy?.name ?? "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/workflow/${row._id}/detail`}>
            <IconButton size="small" aria-label="View Stream">
              <InfoOutlined color="primary" />
            </IconButton>
          </Link>

          <Link to={`/app/workflow/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Stream">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter Table"
          helperText="Title/Type"
          value={filterText ?? ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const PageActions = () => {
    return (
      <Link to="/app/workflow/create">
        <Button startIcon={<AddIcon />} variant="contained">
          New Workflow
        </Button>
      </Link>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    listWorkflows({ active: activeTab !== "Inactive" });
  }, [activeTab, listWorkflows]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      hasActions
      headerActions={<PageActions />}
      error={error}
    >
      <CustomTabs tabList={workflowListTabs} activeTab={activeTab} />

      <AppTable
        columns={columns}
        data={filterableWorkflows}
        progressPending={(loadingWorkflows, fetchingWorkflows)}
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
    </WithHeader>
  );
};

export default WorkflowListScreen;
