import React from "react";

import { Route, Routes } from "react-router-dom";

import BlogCreateScreen from "../screens/Admin/Blog/BlogCreateScreen";
import BlogDetailScreen from "../screens/Admin/Blog/BlogDetailScreen";
import BlogEditScreen from "../screens/Admin/Blog/BlogEditScreen";
import BlogListScreen from "../screens/Admin/Blog/BlogListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const BlogRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<BlogCreateScreen />} />
      <Route path=":id/detail" element={<BlogDetailScreen />} />
      <Route path=":id/edit" element={<BlogEditScreen />} />
      <Route path="list" element={<BlogListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default BlogRouter;
