import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import RichTextEditor from "../Shared/RichTextEditor";

const INITIAL_STATE = {
  content: "",
  type: "Note",
};

const BasicNoteForm = ({
  noteDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const noteTypes = ["Note", "Call", "Meeting", "Email", "Other"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      content: noteDetail?.content ?? "",
      type: noteDetail?.type ?? "Note",
    },
  });

  React.useEffect(() => {
    if (!noteDetail) return reset({ ...INITIAL_STATE });

    reset({ ...noteDetail });
  }, [noteDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Controller
              name="content"
              control={control}
              rules={{ required: "Note content is required" }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  initialValue={
                    noteDetail?.content
                      ? noteDetail.content
                      : "<p>Enter Note Content.</p>"
                  }
                  onChange={onChange}
                  height={150}
                />
              )}
            />
            {errors.content && (
              <p className="error">{errors.content.message}</p>
            )}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="type"
              control={control}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-note">Note Type</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-note"
                    label="Coure Level"
                    placeholder="Select Type"
                    size="small"
                    id="role"
                    value={value ? value : "Note"}
                    {...field}
                  >
                    {noteTypes?.map((note) => (
                      <MenuItem key={note} value={note}>
                        {note}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {noteDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default BasicNoteForm;
