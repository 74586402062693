import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import HowToRegOutlined from "@mui/icons-material/HowToRegOutlined";

import AppTable from "../../../components/Shared/AppTable";
import AppTableDateTime from "../../../components/Shared/AppTableDateTime";
import ConvertToClient from "../Client/clientComponents/ConvertToClient";
import CustomTabs from "../../../components/Shared/CustomTabs";
import NextActionDetail from "../../../components/Shared/NextActionDetail";
import TableFilters from "../../../components/Shared/AppTable/TableFilters";
import WithHeader from "../../../layouts/WithHeader";

import { selectLoggedInUser } from "../../../rtkStore/authSlice";
import { useLazyGetLeadListQuery } from "../../../rtkStore/services/leadApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const LeadListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Open");
  const [showMyLeads, setShowMyLeads] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const [searchParams] = useSearchParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  const breadcrumbs = [
    {
      title: "Leads",
    },
  ];

  const leadListTabs = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "Closed",
      value: "Closed",
    },
    {
      label: "All",
      value: "All",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];

  // RTK Query
  const [
    getLeadList,
    {
      data: leadList,
      isLoading: loadingLeadList,
      isFetching: fetchingLeads,
      error,
    },
  ] = useLazyGetLeadListQuery();

  const filterableLeads = filterArrayByKeys(
    leadList,
    filterText,
    "fullName",
    "phone",
    "alternativeName",
    "firstPreference.name",
    "responsible.name"
  );

  const filteredLeadList = React.useMemo(() => {
    if (activeTab === "All" || activeTab === "Inactive")
      return filterableLeads?.filter((lead) =>
        showMyLeads ? lead?.responsible?._id === loggedInUser?._id : lead
      );

    return filterableLeads
      ?.filter((lead) => lead?.stage?.group === activeTab)
      ?.filter((lead) =>
        showMyLeads ? lead?.responsible?._id === loggedInUser?._id : lead
      );
  }, [activeTab, filterableLeads, loggedInUser?._id, showMyLeads]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      cell: (row) => (
        <Link
          to={`/app/lead/${row?._id}/detail`}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
          title={row?.fullName}
        >
          {row?.fullName ?? "N/A"}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Alt Name",
      selector: (row) => row?.alternativeName,
      cell: (row) => (
        <div title={row?.alternativeName} className="line-clamp-2">
          {row?.alternativeName ?? "N/A"}
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row?.phone ?? "N/A",
    },
    {
      name: "1st Preference",
      selector: (row) => row?.firstPreference?.name,
      cell: (row) =>
        row?.firstPreference ? (
          <div title={row?.firstPreference?.name} className="line-clamp-1">
            {row?.firstPreference?.name}
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      name: "Stage",
      selector: (row) => row?.stage?.name ?? "N/A",
    },
    {
      name: "Created At",
      selector: (row) => row?.createdAt,
      cell: (row) => <AppTableDateTime date={row?.createdAt} />,
      sortable: true,
    },
    {
      name: "Responsible",
      selector: (row) => row?.responsible?.name ?? "",
      cell: (row) => (
        <div title={row?.responsible?.name} className="line-clamp-2">
          {row?.responsible?.name ?? "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Next Action",
      cell: (row) => (
        <NextActionDetail
          nextAction={row?.nextAction}
          nextActionDate={row?.nextActionDate}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Box display="flex" alignItems="center">
          {!row?.converted ? (
            <ConvertToClient lead={row} />
          ) : (
            <Tooltip title="Converted to Client" arrow placement="top">
              <HowToRegOutlined className="mx-2" color="success" />
            </Tooltip>
          )}

          <Link to={`/app/lead/${row?._id}/detail`}>
            <IconButton size="small" aria-label="Lead">
              <InfoIcon color="primary" />
            </IconButton>
          </Link>

          <Link to={`/app/lead/${row?._id}/edit`}>
            <IconButton size="small" aria-label="Edit Lead">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </Box>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const PageActions = () => {
    return (
      <div className="flex space-x-4">
        <Link to="/app/lead/tracker">
          <Button variant="outlined">Leads Tracker</Button>
        </Link>

        <Link to="/app/lead/create">
          <Button startIcon={<AddIcon />} variant="contained">
            New Lead
          </Button>
        </Link>
      </div>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Open");
  }, [searchParams]);

  React.useEffect(() => {
    getLeadList({ active: activeTab !== "Inactive" });
  }, [activeTab, getLeadList]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      hasActions
      headerActions={<PageActions />}
      error={error}
    >
      <CustomTabs tabList={leadListTabs} activeTab={activeTab} />

      <TableFilters
        filterText={filterText}
        helperText={"Name/Alt Name/Preference/Phone/Responsible"}
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyLeads}
        onShowMineChange={(e) => setShowMyLeads(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={filteredLeadList}
        progressPending={loadingLeadList || fetchingLeads}
      />
    </WithHeader>
  );
};

export default LeadListScreen;
