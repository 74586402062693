import React from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton, Tooltip } from "@mui/material";
import Add from "@mui/icons-material/Add";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoTone from "@mui/icons-material/ModeEditOutlineTwoTone";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import TableFilters from "../../../components/Shared/AppTable/TableFilters";
import WithHeader from "../../../layouts/WithHeader";

import { selectLoggedInUser } from "../../../rtkStore/authSlice";
import { useLazyGetTransactionListQuery } from "../../../rtkStore/services/transactionApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const TransactionListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [showMyTransactions, setShowMyTransactions] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const [searchParams] = useSearchParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  const breadcrumbs = [{ title: "Transactions" }];

  const transactionListTabs = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];

  // RTK Query
  const [
    getTransactionList,
    {
      data: transactionList,
      isLoading: loadingTransactions,
      isFetching: fetchingTransactions,
      error,
    },
  ] = useLazyGetTransactionListQuery();

  const filterableTransactions = filterArrayByKeys(
    transactionList,
    filterText,
    "title",
    "client.fullName"
  );

  const filteredLeadList = React.useMemo(() => {
    return filterableTransactions?.filter((lead) =>
      showMyTransactions ? lead?.responsible?._id === loggedInUser?._id : lead
    );
  }, [filterableTransactions, loggedInUser?._id, showMyTransactions]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/transaction/${row?._id}/detail`}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
          title={row?.title}
        >
          {row?.title ?? "N/A"}
        </Link>
      ),
      minWidth: "250px",
    },
    {
      name: "Client",
      selector: (row) => row?.client?.fullName,
      cell: (row) => (
        <Link
          to={`/app/client/${row?.client?._id}/detail`}
          className="text-blue-600 line-clamp-2 hover:underline"
          title={row?.client?.fullName}
        >
          {row?.client?.fullName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type?.name ?? "N/A",
    },
    {
      name: "Stage",
      selector: (row) => row?.stage?.name ?? "N/A",
    },
    {
      name: "Workflows",
      selector: (row) => row?.workflows ?? [],
      cell: (row) => (
        <>
          {row?.workflows?.length ? (
            <div className="flex flex-col items-center">
              <Tooltip title={row.workflows[0].title} arrow placement="top">
                <div className="mr-1 w-28 rounded border-2 border-dashed border-white bg-blue-100 px-2 py-0.5 text-center text-xs line-clamp-1">
                  {row.workflows[0].title}
                </div>
              </Tooltip>
              <div className="text-xs text-gray-500">
                {!!(row?.workflows?.length > 1) &&
                  `+ ${row.workflows.length - 1} others`}
              </div>
            </div>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      name: "Responsible",
      selector: (row) => row?.responsible?.name ?? "",
      cell: (row) => (
        <div title={row?.responsible?.name} className="line-clamp-2">
          {row?.responsible?.name ?? "N/A"}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/transaction/${row._id}/detail`}>
            <IconButton
              size="small"
              aria-label="Transaction Detail"
              color="primary"
            >
              <InfoOutlined />
            </IconButton>
          </Link>

          <Link to={`/app/transaction/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Transaction">
              <ModeEditOutlineTwoTone />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const PageActions = () => {
    return (
      <Link to="/app/transaction/create">
        <Button startIcon={<Add />} variant="contained">
          New Transaction
        </Button>
      </Link>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    getTransactionList({ active: activeTab !== "Inactive" });
  }, [activeTab, getTransactionList]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingTransactions}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <CustomTabs tabList={transactionListTabs} activeTab={activeTab} />

      <TableFilters
        filterText={filterText}
        helperText={"Title/Client Name"}
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyTransactions}
        onShowMineChange={(e) => setShowMyTransactions(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={filteredLeadList}
        progressPending={loadingTransactions || fetchingTransactions}
      />
    </WithHeader>
  );
};

export default TransactionListScreen;
