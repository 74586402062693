import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
} from "@mui/material";
import {
  DoNotDisturbOutlined,
  DoneAllOutlined,
  DownloadingOutlined,
  LogoutOutlined,
  NotStartedOutlined,
} from "@mui/icons-material";

import InformationTile from "../../../../components/Shared/InformationTile";
import TableLoader from "../../../../components/Shared/AppTable/TableLoader";
import WithHeader from "../../../../layouts/WithHeader";

import { useListListQuery } from "../../../../rtkStore/services/listService";
import { useGetWorkflowTransactionReportDataQuery } from "../../../../rtkStore/services/reportApi";
import { getDateDifference } from "../../../../utils/getDateDifference";
import { getPluralWord } from "../../../../utils/getFormattedString";
import sortListAlphaNumerically from "../../../../utils/getAlphaNumericSortedList";

const STATUS_COLOR_MAP = {
  Open: {
    background: "bg-blue-50",
    text: "text-blue-500",
    border: "border-blue-200",
    hover: "hover:bg-blue-100",
    icon: <NotStartedOutlined />,
  },
  "In Progress": {
    background: "bg-orange-50",
    text: "text-orange-500",
    border: "border-orange-200",
    hover: "hover:bg-orange-100",
    icon: <DownloadingOutlined />,
  },
  Completed: {
    background: "bg-green-50",
    text: "text-green-500",
    border: "border-green-200",
    hover: "hover:bg-green-100",
    icon: <DoneAllOutlined />,
  },
  "Not Applicable": {
    background: "bg-gray-50",
    text: "text-gray-500",
    border: "border-gray-200",
    hover: "hover:bg-gray-100",
    icon: <DoNotDisturbOutlined />,
  },
  Withdrawn: {
    background: "bg-red-50",
    text: "text-red-500",
    border: "border-red-200",
    hover: "hover:border-red-100",
    icon: <LogoutOutlined />,
  },
};

const WorkflowTransactionReportScreen = () => {
  const [filterStages, setFilterStages] = React.useState([]);

  const { workflowId } = useParams();

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Workflow Transactions Report",
      path: "/app/report/workflow-transaction/list",
    },
    ...(searchParams?.get("workflow")
      ? [
          {
            title: searchParams.get("workflow"),
          },
        ]
      : []),
  ];

  // RTK Query
  const {
    data: workflowTransactionRes,
    isLoading: loadingReportData,
    error,
  } = useGetWorkflowTransactionReportDataQuery(workflowId);

  const { data: transactionStages, isLoading: loadingTransactionStages } =
    useListListQuery({
      type: "General Stages",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const filteredWorkflowTransactions = React.useMemo(
    () =>
      !!filterStages?.length
        ? workflowTransactionRes?.workflowTransactions?.filter((el) =>
            filterStages.includes(el?.transactionStage?._id)
          )
        : workflowTransactionRes?.workflowTransactions,
    [workflowTransactionRes, filterStages]
  );

  const workflowDetail = workflowTransactionRes?.workflow;

  const workflowSteps = React.useMemo(() => {
    const activeSteps = workflowDetail?.steps?.filter((el) => el?.isActive);

    const sortedSteps = sortListAlphaNumerically({
      sortableList: activeSteps,
      sortableKey: "serialNumber",
    });

    return sortedSteps ?? [];
  }, [workflowDetail]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingReportData || loadingTransactionStages}
      error={error}
      toggleKey="WORKFLOW_TRANSACTION_REPORT"
      hasActions
      headerActions={
        <div className="flex items-center space-x-8">
          <div className="relative  rounded border border-gray-300 px-3 py-1.5">
            <div className="absolute -top-3 bg-white px-1 text-[12px] text-gray-500">
              Legend
            </div>

            <div className="flex space-x-3">
              {Object.keys(STATUS_COLOR_MAP)?.map((key) => (
                <div
                  key={key}
                  title={key}
                  className={`${STATUS_COLOR_MAP[key]?.background} ${STATUS_COLOR_MAP[key]?.border} ${STATUS_COLOR_MAP[key]?.text} rounded p-0.5`}
                >
                  {STATUS_COLOR_MAP[key].icon}
                </div>
              ))}{" "}
            </div>
          </div>

          <TransactionStageFilter
            filterStages={filterStages}
            onChange={(value) =>
              setFilterStages(
                typeof value === "string" ? value.split(",") : value
              )
            }
            transactionStages={transactionStages}
          />
        </div>
      }
    >
      {!loadingReportData ? (
        <div className="block h-screen flex-grow overflow-auto rounded bg-white">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr>
                <td className="sticky top-0 w-10 border bg-gray-100 p-2 text-xs font-medium"></td>
                <td className="sticky top-0 border bg-gray-100 p-2 text-xs font-medium">
                  Client
                </td>
                <td className="sticky top-0 border bg-gray-100 p-2 text-xs font-medium">
                  Latest Comment
                </td>
                {workflowSteps?.map((step) => (
                  <td
                    title={step?.title}
                    className="sticky top-0 border bg-gray-100 p-2 py-2"
                    key={step?._id}
                  >
                    <div className="w-20 text-xs font-medium line-clamp-1">
                      {step?.title?.substring(0, 9)}
                      <span>{step?.title?.length > 9 && "..."}</span>
                    </div>
                  </td>
                ))}
              </tr>
            </thead>

            <tbody className="border border-gray-200">
              {filteredWorkflowTransactions?.length ? (
                filteredWorkflowTransactions
                  // .slice(0, 1)
                  .map((row, index) => (
                    <tr key={row?._id}>
                      <td className="border p-2 text-center text-sm">
                        {`${index + 1}.`}
                      </td>

                      <WorkflowTransactionClient workflowTransaction={row} />

                      <WorkflowTransactionStepNote
                        workflowUpdate={row?.workflowUpdates?.at(-1)}
                      />

                      {workflowSteps
                        //.slice(0, 1)
                        .map((step) => (
                          <WorkflowTransactionStepStatusCell
                            key={step?._id}
                            step={step}
                            workflowTransaction={row}
                          />
                        ))}
                    </tr>
                  ))
              ) : (
                <tr>
                  <td
                    colSpan={workflowSteps?.length + 3}
                    className="border p-4 text-center  text-sm"
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <TableLoader />
      )}
    </WithHeader>
  );
};

export default WorkflowTransactionReportScreen;

const WorkflowTransactionClient = ({ workflowTransaction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const _transactionStage = workflowTransaction?.transactionStage?.name;
  const _transactionStageGroup = workflowTransaction?.transactionStage?.group;

  const transactionStageIndicator = {
    Open: "border-blue-200 text-blue-500",
    Closed: "border-green-200 text-green-500",
  };

  // methods
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // returns duration that the step took/is in for a status
  const __getTransactionStageDuration = () => {
    const _transactionCreatedAt = workflowTransaction?.createdAt;
    if (_transactionStageGroup !== "Closed")
      return getDateDifference(new Date(), _transactionCreatedAt);

    const _lastCompletedStatusUpdate = workflowTransaction?.workflowUpdates
      ?.filter((el) => el?.status === "Completed")
      ?.sort((a, b) => (a?.updatedAt < b?.updatedAt ? -1 : 1))
      .at(-1);

    return getDateDifference(
      _lastCompletedStatusUpdate?.updatedAt,
      _transactionCreatedAt
    );
  };

  if (!workflowTransaction) return <td className="border text-center">--</td>;

  return (
    <td
      className="group border p-2 hover:bg-blue-50"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Link
        to={`/app/transaction/${workflowTransaction?._id}/detail?tab=workflows`}
        target="_blank"
        className="flex min-w-max flex-col space-y-2"
        title={`View ${workflowTransaction?.transactionClient?.firstName} ${workflowTransaction?.transactionClient?.lastName}'s transaction`}
      >
        <div className="flex-grow text-sm line-clamp-1 group-hover:text-blue-600 group-hover:underline">
          {`${workflowTransaction?.transactionClient?.firstName} ${workflowTransaction?.transactionClient?.lastName}`}
        </div>

        <div className="mt-1 flex items-center space-x-2">
          <div
            className="flex-shrink-0 rounded-sm text-[10px] text-blue-500"
            title={`Stage: ${_transactionStage}`}
          >
            {_transactionStage}
          </div>

          {
            <>
              <span className="text-xs text-gray-300">|</span>

              <div
                className={`grid h-5 w-5 flex-shrink-0 place-content-center rounded-full border text-[10px] ${transactionStageIndicator[_transactionStageGroup]}`}
              >
                {__getTransactionStageDuration() ?? 0}
              </div>
            </>
          }

          <span className="text-xs text-gray-300">|</span>

          <div className="grid h-5 w-5 place-content-center rounded-full bg-red-50 text-[10px] text-red-500">
            {workflowTransaction?.transactionClient?.responsible?.name
              ?.split(" ")
              ?.map((el) => el?.charAt(0))
              ?.slice(0, 2)
              ?.join("") ?? "N/A"}
          </div>

          <span className="text-xs text-gray-300">|</span>

          <div className="grid h-5 w-5 place-content-center rounded-full bg-blue-50 text-[10px] text-blue-500">
            {workflowTransaction?.transactionResponsible?.name
              ?.split(" ")
              ?.map((el) => el?.charAt(0))
              ?.slice(0, 2)
              ?.join("") ?? "N/A"}
          </div>
        </div>
      </Link>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className=" w-72 ">
          <div className="flex items-center justify-between px-4 py-2">
            <div className="flex flex-grow flex-col space-y-1 text-gray-500">
              <div className="text-xs line-clamp-1">
                {workflowTransaction?.transaction}
              </div>

              <div className="text-sm font-medium text-gray-600">
                {`${workflowTransaction?.transactionClient?.firstName} ${workflowTransaction?.transactionClient?.lastName}`}
              </div>

              <div className="text-xs">
                Created on:{" "}
                {new Date(workflowTransaction?.createdAt).toLocaleDateString()}
              </div>
            </div>

            <div className="flex flex-shrink-0 flex-col items-center space-y-0.5">
              <div
                className={`flex h-12 w-12 flex-col items-center justify-center rounded-full border-2 ${transactionStageIndicator[_transactionStageGroup]}`}
              >
                <div className="text-sm font-medium tracking-wider">
                  {__getTransactionStageDuration()}
                </div>

                <div className="text-[10px]">
                  {getPluralWord(
                    "Day",
                    __getTransactionStageDuration(),
                    "s",
                    false
                  )}
                </div>
              </div>

              <div
                className={`text-xs ${transactionStageIndicator[_transactionStageGroup]}`}
              >
                {_transactionStage}
              </div>
            </div>
          </div>

          <hr />

          <div className="grid grid-cols-1 gap-2 px-4 py-2 ">
            <InformationTile title="Responsible For Client">
              <div className="text-sm text-red-500">
                {workflowTransaction?.transactionClient?.responsible?.name}
              </div>
            </InformationTile>

            <InformationTile title="Responsible For Transaction">
              <div className="text-sm text-blue-500">
                {workflowTransaction?.transactionResponsible?.name}
              </div>
            </InformationTile>
          </div>
        </div>
      </Popover>
    </td>
  );
};

const WorkflowTransactionStepStatusCell = ({ workflowTransaction, step }) => {
  const _transactionStepWorkflowUpdates =
    workflowTransaction?.workflowUpdates?.filter(
      (el) => el?.step === step?._id
    );

  const _latestWorkflowStepUpdate = _transactionStepWorkflowUpdates?.at(-1);

  const _workflowStepEntryDate =
    _latestWorkflowStepUpdate?.entryDate ??
    _latestWorkflowStepUpdate?.updatedAt;

  const _stepStatusIndicator =
    STATUS_COLOR_MAP[_latestWorkflowStepUpdate?.status] ??
    STATUS_COLOR_MAP["Open"];

  const _stepStatusDateString = new Date(
    _workflowStepEntryDate
  )?.toLocaleDateString();

  const _durationExcludedStatus = ["Not Applicable", "Withdrawn"];

  // returns the initial "In Progress" update made for the step
  const _initialInProgressUpdate = _transactionStepWorkflowUpdates
    ?.filter((el) => el?.status === "In Progress")
    ?.at(0);

  // methods
  // returns duration that the step took/is in for a status
  const __getTransactionStepStageDuration = () => {
    let duration = null;

    const _transactionCreatedAt = workflowTransaction?.createdAt;

    const _stepStatus = _latestWorkflowStepUpdate?.status;

    if (_durationExcludedStatus?.includes(_stepStatus)) return null;

    if (_stepStatus !== "Completed") {
      duration = getDateDifference(new Date(), _workflowStepEntryDate);
    } else {
      const _stepInstantiateDate =
        _initialInProgressUpdate?.entryDate ??
        _initialInProgressUpdate?.updatedAt ??
        workflowTransaction?.createdAt;

      if (!_stepInstantiateDate) {
        duration = getDateDifference(
          _workflowStepEntryDate,
          _transactionCreatedAt
        );
      } else {
        duration = getDateDifference(
          _workflowStepEntryDate,
          _stepInstantiateDate
        );
      }
    }

    return duration === 0 ? 1 : duration;
  };

  const __getStepTitle = () => {
    if (!_latestWorkflowStepUpdate) return;

    const _stepStatus = _latestWorkflowStepUpdate?.status;

    if (_durationExcludedStatus?.includes(_stepStatus))
      return `${_stepStatus} on ${_stepStatusDateString}`;

    return `${_stepStatus} ${
      _stepStatus === "Completed" ? "within" : "for"
    } ${__getTransactionStepStageDuration()} ${getPluralWord(
      "day",
      __getTransactionStepStageDuration(),
      "s",
      false
    )}`;
  };

  return (
    <td
      className={`whitespace-nowrap border px-4 py-2  ${_stepStatusIndicator?.background} ${_stepStatusIndicator?.hover}`}
      title={__getStepTitle()}
      key={step?._id}
    >
      <div className="flex flex-col space-y-2 text-center">
        {_latestWorkflowStepUpdate ? (
          <>
            <div
              className={`flex items-center justify-center space-x-4 ${_stepStatusIndicator?.text}`}
            >
              <div>{_stepStatusIndicator?.icon}</div>

              {__getTransactionStepStageDuration() !== null && (
                <div
                  className={`grid h-8 w-8 place-content-center rounded-full border font-medium ${_stepStatusIndicator?.border}`}
                >
                  {__getTransactionStepStageDuration()}
                </div>
              )}
            </div>

            <div className="text-xs tracking-wider text-gray-500">
              {_stepStatusDateString}
            </div>
          </>
        ) : (
          "--"
        )}
      </div>
    </td>
  );
};

const WorkflowTransactionStepNote = ({ workflowUpdate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!workflowUpdate) return <td className="border text-center">--</td>;

  return (
    <td
      className="group border p-2 hover:bg-blue-50 hover:text-gray-500"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <div className="w-44">
        <div className="text-sm line-clamp-1">
          {workflowUpdate?.note ?? "N/A"}
        </div>

        <div className="mt-2 text-xs text-gray-500">
          {new Date(
            workflowUpdate?.entryDate ?? workflowUpdate?.updatedAt
          ).toLocaleDateString()}
        </div>
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="w-72 p-2 ">
          <div className="text-sm">{workflowUpdate?.note ?? "N/A"}</div>
          <div className="mt-2 text-xs text-gray-500">
            {new Date(workflowUpdate?.createdAt).toLocaleString()}
          </div>
        </div>
      </Popover>
    </td>
  );
};

const TransactionStageFilter = ({
  filterStages = [],
  onChange = () => {},
  transactionStages = [],
}) => {
  React.useEffect(() => {
    if (filterStages?.length) return;

    const activeStages = transactionStages
      ?.filter((el) => el?.name === "Open" || el?.name === "In Progress")
      ?.map((el) => el?._id);

    onChange([...activeStages]);
  }, [filterStages, onChange, transactionStages]);

  return (
    <div className="w-60">
      <FormControl fullWidth>
        <InputLabel>Transaction Stages</InputLabel>

        <Select
          fullWidth
          size="small"
          multiple
          value={[...filterStages]}
          onChange={(e) => onChange(e.target.value)}
          input={<OutlinedInput label="Transaction St" />}
        >
          {transactionStages?.map((stage) => (
            <MenuItem key={stage._id} value={stage._id}>
              {stage?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
