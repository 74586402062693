import { baseApi } from "./baseApi";

const TEAM_BASE_URL = "/teams";

export const teamApi = baseApi.injectEndpoints({
  tagTypes: ["Team"],

  endpoints: (builder) => ({
    getTeamList: builder.query({
      query: () => `${TEAM_BASE_URL}/list`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Team", _id })),
              { type: "Team", id: "LIST" },
            ]
          : [{ type: "Team", id: "LIST" }],
    }),

    getTeamDetail: builder.query({
      query: (id) => `${TEAM_BASE_URL}/${id}/detail`,
      providesTags: (result, error, id) => [
        { type: "Team", id },
        { type: "Team", id: "LIST" },
      ],
    }),

    createTeam: builder.mutation({
      query: (payload) => ({
        url: `${TEAM_BASE_URL}/add`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Team", id: "LIST" },
      ],
    }),

    updateTeam: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${TEAM_BASE_URL}/${_id}/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Team", _id },
        { type: "Team", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetTeamDetailQuery,
  useGetTeamListQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
} = teamApi;
