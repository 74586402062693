import React from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Add, FiberManualRecord, FeedbackOutlined } from "@mui/icons-material";
import moment from "moment";

import { useAddResponseToTaskMutation } from "../../../../rtkStore/services/taskApi";

const TaskResponse = ({ responses = [] }) => {
  const [showCreateResponse, setShowCreateResponse] = React.useState(false);
  const [newResponse, setNewResponse] = React.useState("");

  const { id } = useParams();

  // RTK Query
  const [addNoteToTask, { isLoading: addingResponse }] =
    useAddResponseToTaskMutation();

  // methods
  const handleCancelResponse = () => {
    setShowCreateResponse(false);
    setNewResponse("");
  };

  const handleAddResponse = () => {
    const payload = {
      _id: id,
      response: newResponse,
    };

    addNoteToTask(payload)
      .unwrap()
      .then(() => {
        toast.success("Response successfully addded!");
        handleCancelResponse();
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={2}>
          <Badge badgeContent={responses.length} color="primary" showZero>
            <FeedbackOutlined />
          </Badge>
          <Typography>Responses</Typography>
        </Box>

        {!showCreateResponse && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowCreateResponse(true)}
          >
            Response
          </Button>
        )}
      </Box>

      <Collapse in={showCreateResponse}>
        <Divider />
        <Box padding={3} paddingBottom={1}>
          <TextField
            placeholder="Leave a response"
            fullWidth
            multiline
            rows={2}
            value={newResponse}
            onChange={(e) => setNewResponse(e.target.value)}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} marginTop={1}>
            <Button disable={addingResponse} onClick={handleCancelResponse}>
              Cancel
            </Button>

            <Button
              disabled={!newResponse.length || addingResponse}
              onClick={handleAddResponse}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Collapse>

      <Divider />

      <Box paddingX={3}>
        {!!responses?.length > 0 ? (
          responses.map((response, index) => (
            <React.Fragment key={response._id}>
              <Box display="flex" alignItems="start" gap={2} paddingY={3}>
                <FeedbackOutlined color="primary" style={{ marginTop: 3 }} />
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography> {response?.createdBy.name}</Typography>

                    <FiberManualRecord
                      style={{ color: "gray", fontSize: 10 }}
                    />

                    <Typography style={{ color: "gray" }}>
                      {`${moment(response?.createdAt).format(
                        "MMM D, YYYY"
                      )} at ${moment(response?.createdAt).format(
                        "HH:MM:ss a"
                      )}`}
                    </Typography>
                  </Box>

                  <Box>{response?.content}</Box>
                </Box>
              </Box>
              {index !== responses.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Response yet.</Box>
        )}
      </Box>
    </>
  );
};

export default TaskResponse;
