import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";
import RichTextEditor from "../../../../components/Shared/RichTextEditor";

import {
  useGetAdminListQuery,
  useListAccountEntityUserQuery,
} from "../../../../rtkStore/services/userApi";
import { useGetClientListQuery } from "../../../../rtkStore/services/clientApi";
import { useGetTransactionListQuery } from "../../../../rtkStore/services/transactionApi";
import { useGetTeamListQuery } from "../../../../rtkStore/services/teamApi";

import { useAuth } from "../../../../hooks/useAuth";

const TaskForm = ({
  taskDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [assigneeType, setAssgineeType] = React.useState("Client");

  const [responsibleSource, setResponsbileSource] = React.useState("User");

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // required enum list
  const categoryEnum = [
    "Reminder",
    "Call",
    "Follow Up",
    "Email",
    "Meeting",
    "Others",
  ];
  const visibilityEnums = ["Internal", "External"];
  const statusEnums = ["Open", "On Process", "Closed"];
  const priorityEnums = ["Low", "Normal", "High", "Urgent"];
  const assigneeTypes = ["Application", "Client", "Transaction"];
  const responsibleSources = ["User", "Team"];

  const { viewEntity } = useAuth();

  // rtkQuery
  const { data: adminList, isLoading: loadingAdmins } = useGetAdminListQuery();

  const { data: clientList, isLoading: loadingClients } =
    useGetClientListQuery();

  const { data: transactionList, isLoading: loadingTransactions } =
    useGetTransactionListQuery();

  const { data: userList, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  const { data: teamList, isLoading: loadingTeams } = useGetTeamListQuery();

  const getAssigneeSelectLabel = (assignee) => {
    if (!assignee) return;

    switch (taskDetail?.assigneeSource) {
      case "Client":
        return {
          label:
            assignee && taskDetail?.assigneeSource === "Client"
              ? `${assignee?.firstName} ${assignee?.lastName}`
              : assignee?.name,
          value: assignee?._id,
        };

      case "Transaction":
        return {
          label: assignee?.title,
          value: assignee?._id,
        };
      default:
        return "";
    }
  };

  const getResponsibleSelectLabel = (responsible) => {
    if (!responsible) return;

    switch (taskDetail?.responsibleSource) {
      case "User":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      case "Team":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      default:
        return "";
    }
  };

  // react form hook
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: taskDetail?.title ?? "",
      category: taskDetail?.category ?? "Reminder",
      visibility: taskDetail?.visibility ?? "External",
      assignee: taskDetail ? getAssigneeSelectLabel(taskDetail?.assignee) : "",
      assigneeSource: taskDetail?.assigneeSource ?? "Client",
      status: taskDetail?.status ?? "Open",
      priority: taskDetail?.priority ?? "Normal",
      step: taskDetail?.step?._id ?? "",
      responsible: taskDetail
        ? getResponsibleSelectLabel(taskDetail?.responsible)
        : "",
      responsibleSource: taskDetail?.responsibleSource ?? "User",
      dueDate: taskDetail?.dueDate
        ? moment(taskDetail.dueDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      description: taskDetail?.description ?? "",
    },
  });

  // returns names based on the assingee type since the names are in different format
  const mappedAssigneeList = React.useMemo(() => {
    switch (assigneeType) {
      case "Client":
        return (
          clientList?.map((client) => ({
            label: `${client.firstName} ${client.lastName}`,
            value: client._id,
          })) ?? []
        );

      case "Transaction":
        return transactionList?.map((transaction) => ({
          label: transaction?.title,
          value: transaction?._id,
        }));

      case "User":
        return (
          adminList?.map((admin) => ({
            label: admin.name,
            value: admin._id,
          })) ?? []
        );

      default:
        return [];
    }
  }, [assigneeType, adminList, clientList, transactionList]);

  const mappedResponsibleUserList = React.useMemo(() => {
    switch (responsibleSource) {
      case "User":
        return (
          userList?.map((name) => ({
            label: name.name,
            value: name._id,
          })) ?? []
        );

      case "Team":
        return teamList?.map((team) => ({
          label: team?.name,
          value: team?._id,
        }));

      default:
        return [];
    }
  }, [responsibleSource, teamList, userList]);

  const handleResposibleSourceChange = (e) => {
    setResponsbileSource(e.target.value);

    const currentValues = getValues();
    reset({ ...currentValues, responsible: null });
  };

  const handleAssigneeTypeChange = (e) => {
    setAssgineeType(e.target.value);

    const currentValues = getValues();

    reset({ ...currentValues, assignee: null });
  };

  React.useEffect(() => {
    if (!taskDetail) return;

    setAssgineeType(taskDetail?.assigneeSource ?? "Client");

    const selectableAssignee = getAssigneeSelectLabel(taskDetail?.assignee);

    setResponsbileSource(taskDetail?.responsibleSource ?? "User");

    const selectableResposible = getResponsibleSelectLabel(
      taskDetail?.responsible
    );

    reset({
      ...taskDetail,
      assignee: selectableAssignee,
      step: taskDetail?.step?._id ?? "",
      dueDate: moment(taskDetail.dueDate).format("YYYY-MM-DD"),
      responsible: selectableResposible,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetail]);

  React.useEffect(() => {
    if (!searchParams) return;

    const selectedClient = searchParams.get("selectedClient");
    if (!selectedClient) return;

    const selectedAssignee = mappedAssigneeList.find(
      (c) => c?.value === selectedClient
    );
    if (!selectedAssignee) return;

    reset({
      assignee: selectedAssignee,
    });
  }, [reset, searchParams, mappedAssigneeList]);

  console.log({ assigneeType, mappedAssigneeList });

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingAdmins ||
        loadingClients ||
        loadingTransactions ||
        loadingUsers ||
        loadingTeams
      }
    >
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="category"
              control={control}
              rules={{ required: "Please select a category" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-category">Category</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-category"
                    label="Role"
                    placeholder="Select Role"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {categoryEnum?.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.category && (
              <p className="error">{errors.category.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="visibility"
              control={control}
              rules={{ required: "Please select a visibility" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-visibility">Visible To</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-visibility"
                    label="Role"
                    placeholder="Select Role"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {visibilityEnums?.map((visibility) => (
                      <MenuItem key={visibility} value={visibility}>
                        {visibility}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.visibility && (
              <p className="error">{errors.visibility.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="priority"
              control={control}
              rules={{ required: "Please select a priority" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-priority">Priority</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-priority"
                    label="Role"
                    placeholder="Select Role"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {priorityEnums?.map((priority) => (
                      <MenuItem key={priority} value={priority}>
                        {priority}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.priority && (
              <p className="error">{errors.priority.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="status"
              control={control}
              rules={{ required: "Please select a status" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-status"
                    label="Role"
                    placeholder="Select Role"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {statusEnums?.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.status && <p className="error">{errors.status.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="dueDate"
              control={control}
              rules={{ required: "Due date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Due Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  {...field}
                />
              )}
            />
            {errors.dueDate && (
              <p className="error">{errors.dueDate.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-assginee-type">Assign Type</InputLabel>
              <Select
                fullWidth
                labelId="select-assginee-type"
                label="Role"
                placeholder="Select Assignee Type"
                id="role"
                value={assigneeType}
                onChange={handleAssigneeTypeChange}
              >
                {assigneeTypes?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={4}>
            <Controller
              name="assignee"
              control={control}
              rules={{ required: "Assignee is required" }}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="assignee"
                  autoHighlight
                  onBlur={onBlur}
                  loading={
                    loadingAdmins || loadingClients || loadingTransactions
                  }
                  loadingText="Loading Assignee..."
                  options={mappedAssigneeList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("assignee", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assignee"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.assignee && (
              <p className="error">{errors.assignee.message}</p>
            )}
          </Grid>

          <Grid item md={4} />

          <Grid item md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-assginee-type">
                Responsible Type
              </InputLabel>
              <Select
                fullWidth
                labelId="select-assginee-type"
                label="Responsible Type"
                placeholder="Select Assignee Type"
                id="role"
                value={responsibleSource}
                onChange={handleResposibleSourceChange}
              >
                {responsibleSources?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={4}>
            <Controller
              name="responsible"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="responsible"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingTeams || loadingUsers}
                  loadingText="Loading Responsbile Users..."
                  options={mappedResponsibleUserList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("responsible", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsible"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <div
              style={{ color: "gray", fontSize: 15, marginBottom: ".75rem" }}
            >
              Description
            </div>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Content is required" }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  initialValue={
                    taskDetail?.description
                      ? taskDetail.description
                      : "<p>Enter Workflow Detail.</p>"
                  }
                  onChange={onChange}
                />
              )}
            />
            {errors.description && (
              <p className="error">{errors.description.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              assignee: data?.assignee?.value,
              assigneeSource: assigneeType,
              step: data?.step?.length ? data.step : null,
              responsible: data?.responsible?.value,
              responsibleSource,
            })
          )}
        >
          {taskDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default TaskForm;
