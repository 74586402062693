import React from "react"
import { Container, Typography, Grid, Button, Box } from "@mui/material"
import { Link } from "react-router-dom"
import "./componentStyle.css"
const ConsultExpert = () => {
  return (
    <>
      <div maxWidth='xl' className='ConsultExpertContainer'>
        <Container
          maxWidth='xl'
          sx={{
            pt: 5,
            pb: 3,
          }}
        >
          <Grid container spacing={5} display='flex' alignItems='center'>
            <Grid item xs={12} sm={12} md={5}>
              <Typography className=''>
                <p> LOOKING FOR AN EXPERT ADVICE? </p>
              </Typography>
              <Typography>
                <h1 className='ConsultExpertH1'>Consult with our experts</h1>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Link to='/contact' style={{ textDecoration: "none" }}>
                <Button className='ExpertContactBtn'>Contact Us</Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default ConsultExpert
