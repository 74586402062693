import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";

import AppTable from "../../../components/Shared/AppTable";
import AppTableDateTime from "../../../components/Shared/AppTableDateTime";
import CustomTabs from "../../../components/Shared/CustomTabs";
import NextActionDetail from "../../../components/Shared/NextActionDetail";
import TableFilters from "../../../components/Shared/AppTable/TableFilters";
import WithHeader from "../../../layouts/WithHeader";

import { selectLoggedInUser } from "../../../rtkStore/authSlice";
import { useLazyGetClientListQuery } from "../../../rtkStore/services/clientApi";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const ClientListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Open");
  const [showMyClients, setShowMyClients] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const [searchParams] = useSearchParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  const breadcrumbs = [{ title: "Clients" }];

  const clientListTabs = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "Closed",
      value: "Closed",
    },
    {
      label: "All",
      value: "All",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];

  // RTK Query
  const [
    getClientList,
    {
      data: clientList,
      isLoading: loadingClients,
      isFetching: fetchingClients,
      error,
    },
  ] = useLazyGetClientListQuery();

  const filterableClients = filterArrayByKeys(
    clientList,
    filterText,
    "fullName",
    "phone",
    "alternativeName",
    "firstPreference.name",
    "responsible.name"
  );

  const filteredClientList = React.useMemo(() => {
    if (activeTab === "All" || activeTab === "Inactive")
      return filterableClients?.filter((client) =>
        showMyClients ? client?.responsible?._id === loggedInUser?._id : client
      );

    return filterableClients
      ?.filter((client) => client?.stage?.group === activeTab)
      ?.filter((client) =>
        showMyClients ? client?.responsible?._id === loggedInUser?._id : client
      );
  }, [activeTab, filterableClients, loggedInUser, showMyClients]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
      cell: (row) => (
        <Link
          to={`/app/client/${row?._id}/detail`}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
          title={row?.fullName}
        >
          {row?.fullName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Alt Name",
      selector: (row) => row?.alternativeName,
      cell: (row) => (
        <div className="line-clamp-2" title={row?.alternativeName}>
          {row?.alternativeName ?? "N/A"}
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row?.phone ?? "N/A",
    },
    {
      name: "1st Preference",
      selector: (row) => row?.firstPreference?.name ?? "",
      cell: (row) =>
        row?.firstPreference ? (
          <div title={row?.firstPreference?.name}>
            {row?.firstPreference?.name}
          </div>
        ) : (
          "N/A"
        ),
      maxWidth: "150px",
    },
    {
      name: "Stage",
      selector: (row) => row?.stage?.name,
      cell: (row) => (
        <div title={row?.stage?.name}>{row?.stage?.name ?? "N/A"}</div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => row?.createdAt,
      cell: (row) => <AppTableDateTime date={row?.createdAt} />,
      sortable: true,
    },
    {
      name: "Responsible",
      selector: (row) => row?.responsible?.name ?? "",
      cell: (row) => (
        <div title={row?.responsible?.name} className="line-clamp-2">
          {row?.responsible?.name ?? "N/A"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Next Action",
      selector: (row) => row?.nextActionDate,
      cell: (row) => (
        <NextActionDetail
          nextAction={row?.nextAction}
          nextActionDate={row?.nextActionDate}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/client/${row._id}/detail`}>
            <IconButton
              size="small"
              aria-label="Client Details"
              color="primary"
            >
              <InfoIcon />
            </IconButton>
          </Link>

          <Link to={`/app/client/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Client">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const PageActions = () => {
    return (
      <div className="flex space-x-4">
        <Link to="/app/client/tracker">
          <Button variant="outlined">Clients Tracker</Button>
        </Link>

        <Link to="/app/client/create">
          <Button startIcon={<AddIcon />} variant="contained">
            New Client
          </Button>
        </Link>
      </div>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Open");
  }, [searchParams]);

  React.useEffect(() => {
    getClientList({ active: activeTab !== "Inactive" });
  }, [activeTab, getClientList]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <CustomTabs tabList={clientListTabs} activeTab={activeTab} />

      <TableFilters
        filterText={filterText}
        helperText={"Name/Alt Name/Preference/Phone/Responsible"}
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyClients}
        onShowMineChange={(e) => setShowMyClients(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={filteredClientList}
        progressPending={loadingClients || fetchingClients}
      />
    </WithHeader>
  );
};

export default ClientListScreen;
