import React from "react";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserEditForm from "../userComponents/UserEditForm";
import WithHeader from "../../../../layouts/WithHeader";

import { useAuth } from "../../../../hooks/useAuth";

import { setUser } from "../../../../rtkStore/authSlice";

import {
  useDetailUserProfileMutation,
  useEditUserProfileMutation,
} from "../../../../rtkStore/services/userApi";

const EditUserProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const breadcrumbs = [
    { title: "My Profile", path: "/app/user/me" },
    { title: "Edit Profile" },
  ];

  //   RTK Query
  const [updateUserProfile, { isLoading: updatingUserProfile }] =
    useEditUserProfileMutation();

  const [userProfile, { isLoading: loadingUserProfile }] =
    useDetailUserProfileMutation();

  //   methods
  const handleUpdateUserProfile = async (data) => {
    try {
      await updateUserProfile({ ...data, id: loggedInUser?._id }).unwrap();

      const updatedUserDetail = await userProfile({
        id: loggedInUser._id,
      }).unwrap();

      dispatch(
        setUser({
          ...updatedUserDetail,
          viewEntity: loggedInUser.viewEntity,
          token: loggedInUser.token,
        })
      );
      toast.success("Profile successfully updated!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update profile!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={updatingUserProfile || loadingUserProfile}
    >
      <UserEditForm
        userDetail={loggedInUser}
        isBusy={updatingUserProfile || loadingUserProfile}
        onSubmit={handleUpdateUserProfile}
        onCancel={() => navigate(breadcrumbs[0]?.path)}
      />
    </WithHeader>
  );
};

export default EditUserProfileScreen;
