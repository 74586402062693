import React from "react";
import { toast } from "react-toastify";
import { Collapse } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import CourseSearchFilter from "./courseComponents/courseSearch/CourseSearchFilter";
import CourseSearchResults from "./courseComponents/courseSearch/CourseSearchResults";
import SearchingLoader from "../../../components/Shared/Lottie/SearchingLoader";
import WithHeader from "../../../layouts/WithHeader";

import { useSearchCourseMutation } from "../../../rtkStore/services/searchApi";
import { useSearchParams } from "react-router-dom";

const ExternalLinks = () => {
  const externalLinks = [
    {
      label: "Study in Australia",
      link: "https://www.studyaustralia.gov.au/english/study/find-a-course",
    },
    {
      label: "CRICOS search",
      link: "https://cricos.education.gov.au/Course/CourseSearch.aspx",
    },
    { label: "EduCanada", link: "https://w05.educanada.ca" },
    {
      label: "UK UCAS",
      link: "https://digital.ucas.com/coursedisplay/results/courses",
    },
  ];

  return (
    <div className="flex space-x-2 font-medium text-blue-600 ">
      {externalLinks.map((el) => (
        <div
          key={el?.label}
          className="flex cursor-pointer items-center space-x-2 rounded border border-blue-200 px-2 py-1 hover:bg-blue-50"
        >
          <a
            href={el?.link}
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {el?.label}
          </a>

          <LinkIcon fontSize="small" />
        </div>
      ))}
    </div>
  );
};

const CourseSearchScreen = () => {
  const [searchInitiated, setSearchInitiated] = React.useState(false);
  const [searchPayload, setSearchPayload] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit] = React.useState(20);

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Search Course",
      ...(searchParams.get("name") && {
        path: "/app/search-course",
      }),
    },
    ...(searchParams.get("name")
      ? [
          {
            title: `Add to ${searchParams.get(
              "name"
            )}'s Favourites (${searchParams.get("type")})`,
            path: `/app/client/${searchParams.get(
              "ref"
            )}/detail?tab=favouriteCourses`,
          },
        ]
      : []),
  ];

  // RTK Query
  const [
    searchCourse,
    {
      isLoading: loadingCourses,
      isFetching: searchingCourses,
      data: searchResults,
    },
  ] = useSearchCourseMutation();

  const totalPaginatedPages = React.useMemo(() => {
    if (!searchResults) return;

    return Math.ceil(searchResults?.totalItems / limit);
  }, [limit, searchResults]);

  //   methods
  const handleReset = () => {
    setSearchInitiated(false);
    setSearchPayload(null);
    setCurrentPage(1);
  };

  const handleSetPayload = (data) => {
    const payload = {
      ...data,
      field: data?.field?.value ?? "",
      subfield: data?.subfield?.value ?? "",
      universities: data?.universities?.map((el) => el?.value) ?? [],
      levels: data?.levels?.map((el) => el?.value) ?? [],
      englishRequirements:
        data?.englishRequirements?.map((el) => el?.value) ?? [],
      location: data?.location?.value ?? "",
      subLocations: data?.subLocations?.map((el) => el?.value) ?? [],
      tags: data?.tags?.map((el) => el?.value) ?? [],
      limit,
      skip: (currentPage - 1) * limit,
    };

    setSearchPayload(payload);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCourseSearch = async () => {
    try {
      setSearchInitiated(true);
      await searchCourse(searchPayload).unwrap();
    } catch (err) {
      toast.error(err?.data?.message ?? "Something went wrong!");
    }
  };

  React.useEffect(() => {
    if (!searchPayload) return;
    handleCourseSearch();

    return () => {
      setSearchInitiated(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPayload]);

  React.useEffect(() => {
    if (!searchPayload) return;

    setSearchPayload((prevState) => ({
      ...prevState,
      skip: (currentPage - 1) * limit,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      disablePaper
      hasActions
      headerActions={<ExternalLinks />}
    >
      <Collapse in={!searchInitiated}>
        <CourseSearchFilter
          isBusy={searchingCourses || loadingCourses}
          onSearch={handleSetPayload}
          onReset={handleReset}
        />

        <br />
      </Collapse>

      {searchInitiated ? (
        !searchingCourses && !loadingCourses ? (
          <CourseSearchResults
            searchRes={searchResults}
            currentPage={currentPage}
            totalPaginatedPages={totalPaginatedPages}
            onPageChange={handlePageChange}
            onResetFilter={() => setSearchInitiated(false)}
          />
        ) : (
          <SearchingLoader />
        )
      ) : null}
    </WithHeader>
  );
};

export default CourseSearchScreen;
