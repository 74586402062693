import React from "react";

import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  AccountBoxOutlined,
  CategoryOutlined,
  EmailOutlined,
  Phone,
} from "@mui/icons-material";

import GenericDetailSkeleton from "../../../../components/Shared/Skeleton/GenericDetailSkeleton";
import InformationTile from "../../../../components/Shared/InformationTile";
import WithHeader from "../../../../layouts/WithHeader";

import { useProfileUserQuery } from "../../../../rtkStore/services/userApi";

import { useAuth } from "../../../../hooks/useAuth";

const UserProfileScreen = () => {
  const { loggedInUser } = useAuth();

  const breadcrumbs = [
    {
      title: "My Profile",
    },
  ];

  // RTK Query
  const {
    data: userDetail,
    isLoading: loadingUserProfile,
    error,
  } = useProfileUserQuery(loggedInUser._id);

  const PageActions = () => (
    <Box display="flex" gap={2}>
      <Link to="/app/user/change-password">
        <Button variant="outlined"> Change Password </Button>
      </Link>

      <Link to="/app/user/me/update">
        <Button variant="contained"> Edit Profile </Button>
      </Link>
    </Box>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUserProfile}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      {!loadingUserProfile ? (
        <Box padding={3}>
          <Typography variant="h6"> {userDetail?.name} </Typography>
          <br />
          <Grid container spacing={4}>
            <Grid item md={3}>
              <InformationTile
                icon={() => <EmailOutlined color="primary" />}
                title="Email"
                subtitle={userDetail?.email}
              />
            </Grid>

            <Grid item md={3}>
              <InformationTile
                icon={() => <CategoryOutlined color="primary" />}
                title="Category"
                subtitle={userDetail?.category}
              />
            </Grid>

            <Grid item md={3}>
              <InformationTile
                icon={() => <Phone color="primary" />}
                title="Phone"
                subtitle={userDetail?.phone}
              />
            </Grid>

            <Grid item md={3}>
              <InformationTile
                icon={() => <AccountBoxOutlined color="primary" />}
                title="Designation"
                subtitle={userDetail?.designation}
              />
            </Grid>

            <Grid item md={8}>
              <InformationTile title="Entities & Access">
                <table className="w-full table-auto border-collapse rounded-lg border border-gray-200">
                  <thead>
                    <tr className="border border-gray-200 bg-gray-50">
                      <td className="p-3"></td>
                      <td className="p-3">Name</td>
                      <td className="p-3">Account</td>
                      <td className="p-3">Type</td>
                      <td className="p-3">Roles</td>
                      <td className="p-3">Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {userDetail?.entities?.map((entity, index) => (
                      <tr key={entity._id} className="border border-gray-200">
                        <td className="py-2 pl-4"> {index + 1}. </td>
                        <td className="p-3"> {entity?.entity?.name} </td>
                        <td className="p-3">
                          {" "}
                          {entity?.entity?.account?.name}{" "}
                        </td>
                        <td className="p-3"> {entity?.userType} </td>
                        <td className="p-3">
                          <ul>
                            {entity?.authorizations.map((authorization) => (
                              <li key={authorization._id} className="list-disc">
                                {authorization.role}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td className="p-3">
                          {entity?.isActive ? "Active" : "Inactive"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InformationTile>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <GenericDetailSkeleton />
      )}
    </WithHeader>
  );
};

export default UserProfileScreen;
