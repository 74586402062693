import React from "react";

import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import {
  AccountCircleOutlined,
  CategoryOutlined,
  DonutLargeOutlined,
  FilterAltOutlined,
  MonetizationOnOutlined,
} from "@mui/icons-material";

import InformationTile from "../../../components/Shared/InformationTile";
import WithHeader from "../../../layouts/WithHeader";

import { useAuth } from "../../../hooks/useAuth";
import { useDetailEntityQuery } from "../../../rtkStore/services/entityApi";

const EntityDetailScreen = () => {
  const breadcrumbs = [
    {
      title: "Entity Info",
    },
  ];

  const { loggedInUser } = useAuth();

  //   RTK Query
  const {
    data: entityDetail,
    isLoading,
    error,
  } = useDetailEntityQuery(loggedInUser?.viewEntity?.entity?._id);

  const PageActions = () => (
    <Box display="flex" gap={2}>
      <Link to="/app/entity/edit-plan">
        <Button variant="outlined">Edit Plan</Button>
      </Link>

      <Link to="/app/entity/edit">
        <Button variant="contained">Edit Entity</Button>
      </Link>
    </Box>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={isLoading}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <Box padding={3}>
        <Typography variant="h6">{entityDetail?.name}</Typography>
        <br />
        <Grid container spacing={4}>
          <Grid item md={4}>
            <InformationTile
              icon={() => <AccountCircleOutlined color="primary" />}
              title="Account"
              subtitle={entityDetail?.account?.name}
            />
          </Grid>
          <Grid item md={4}>
            <InformationTile
              icon={() => <CategoryOutlined color="primary" />}
              title="Category "
              subtitle={entityDetail?.category}
            />
          </Grid>
          <Grid item md={4}>
            <InformationTile
              icon={() => <FilterAltOutlined color="primary" />}
              title="Type "
              subtitle={entityDetail?.type}
            />
          </Grid>
          <Grid item md={4}>
            <InformationTile
              icon={() => <MonetizationOnOutlined color="primary" />}
              title="Currency "
              subtitle={entityDetail?.currency?.name}
            />
          </Grid>
          <Grid item md={4}>
            <InformationTile
              icon={() => <DonutLargeOutlined color="primary" />}
              title="Status"
              subtitle={entityDetail?.isActive ? "Active" : "Inactive  "}
            />
          </Grid>
          <Grid item md={8}>
            <InformationTile title="Entity Plans">
              <table className="w-full table-auto border-collapse rounded-lg border border-gray-200">
                <thead>
                  <tr className="border border-gray-200 bg-gray-50">
                    <td className="p-3"></td>
                    <td className="p-3">Name</td>
                    <td className="p-3">Licenses</td>
                    <td className="p-3">Frequency</td>
                    <td className="p-3">Started on</td>
                    <td className="p-3">Ended on</td>
                    <td className="p-3">Status</td>
                  </tr>
                </thead>
                <tbody>
                  {entityDetail?.entityPlans?.map((plan, index) => (
                    <tr key={plan._id} className="border border-gray-200">
                      <td className="py-2 pl-4"> {index + 1}. </td>
                      <td className="p-3"> {plan?.plan} </td>
                      <td className="p-3"> {plan?.quantity} </td>
                      <td className="p-3"> {plan?.frequency} </td>
                      <td className="p-3">
                        {moment(plan?.startDate).format("MMM DD, YYYY")}
                      </td>
                      <td className="p-3">
                        {plan.endDate
                          ? moment(plan?.endDate)?.format("MMM DD, YYYY")
                          : "N/A"}
                      </td>
                      <td className="p-3">
                        {plan?.isActive ? "Active" : "Inactive"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </InformationTile>
          </Grid>
        </Grid>

        <br />
      </Box>
    </WithHeader>
  );
};

export default EntityDetailScreen;
