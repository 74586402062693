import React from "react";
import qs from "qs";
import areObjectsEqual from "lodash/isEqual";

const perPage = 16;

export const useInfiniteRTKQuery = (
  endpoint,
  options = { perPage, page: 1, filters: {}, useCacheValue: true }
) => {
  const page = React.useRef(1);

  const [isFetching, setIsFetching] = React.useState(false);
  const [results, setResults] = React.useState(null);
  const [trigger, result] = endpoint.useLazyQuery();
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [lastFilters, setLastFilters] = React.useState();
  const [isFullReloading, setIsFullReloading] = React.useState(true);
  const [retriggerFilterOptions, setRetriggerFilterOptions] = React.useState(
    {}
  );

  const resetQuery = () => {
    page.current = 1;
    // setResults(null);

    setHasMoreData(true);
  };

  const removeItemFromLocalArray = (item) => {
    if (!results || !item) return;

    setResults((prevResults) => prevResults.filter((r) => r._id !== item?._id));
  };

  const updateSingleItemLocally = (item) => {
    if (!results) return;

    setResults((prevResults) =>
      prevResults.map((r) => (r._id === item._id ? { ...r, ...item } : r))
    );
  };

  const addItemToLocalArray = (item) => {
    const resultsCopy = [...results.map((r) => ({ ...r }))];
    resultsCopy.unshift(item);
    setResults(resultsCopy);
  };

  React.useEffect(() => {
    if (!options?.filters) {
      // setIsFullReloading(false);
      return;
    }

    // if (
    //   // options?.isDefaultInitialCall === true ||
    //   options?.isDefaultInitialCall === true
    // )
    //   return;

    if (isFetching) {
      return;
    }

    const handleTrigger = (_useCacheValues = true) => {
      let useCacheValue = true;

      if (_useCacheValues === undefined) {
        if (options?.useCacheValue !== undefined) {
          useCacheValue = options.useCacheValue;
        }
      } else {
        useCacheValue = _useCacheValues;
      }

      trigger(
        qs.stringify({
          filters: { ...options.filters },
          page: page.current,
          perPage: options?.perPage || perPage,
          ...(options?.isMinimal && {
            isMinimal: options?.isMinimal,
          }),
        }),
        true
        // false // right now, there's no caching if you use this hook
        // todo - improve the logid to implement caching
        // if set to true above, it will cache but there's an issue with isFullReloading
        // such that, this variable won't be set to false so any component using that variable will hang in loading skeleton
        // even though there is cache variable available
        // useCacheValue
      );
    };

    // let finalAreObjectsAreEqual = false;
    // if (!options?.filters) {
    //   finalAreObjectsAreEqual = areObjectsEqual(lastFilters, options?.filters);
    // } else {
    //   finalAreObjectsAreEqual = areObjectsEqual(lastFilters, options?.filters);
    // }

    if (!areObjectsEqual(lastFilters, options?.filters)) {
      // if (!finalAreObjectsAreEqual) {
      setIsFullReloading(true);
      // setResults(null); // might need to uncomment it if weird behavior pops up in the UI
      setIsFetching(true);
      resetQuery();
      handleTrigger(!options?.filters?.filter ? false : true);
      // setLastFilters(options?.filters); // don't forget to uncomment this later
    } else {
      // setIsFullReloading(false);
      // if (!results) {
      //   setIsFullReloading(false);
      // }
    }

    // probably don't add any dependence below,

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, lastFilters, options]);

  React.useEffect(() => {
    // if (!result.isSuccess) {
    //   setHasMoreData(false);
    //   // setResults(null);
    //   // setIsFullReloading(false);

    //   return;
    // }

    if (!result?.data) {
      // setLastFilters(options?.filters);
      // setIsFullReloading(false);
      return;
    }

    setLastFilters(options?.filters);

    if (isFullReloading) {
      setResults(result.data);
    } else {
      setResults((prevArr) => {
        if (prevArr && Array.isArray(prevArr)) {
          const combined = [...prevArr, ...result.data];
          const unique = [
            ...new Map(combined.map((item) => [item["_id"], item])).values(),
          ];

          return unique;
        } else {
          return result.data;
        }
      });
    }

    setIsFullReloading(false);

    if (result.data.length < (options?.perPage || perPage)) {
      setHasMoreData(false);
    } else {
      page.current = page.current + 1;
      setHasMoreData(true);
    }

    setIsFetching(false);
    // setLastFilters(options?.filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data, result.isSuccess]);

  return {
    data: results,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    hasNextPage: hasMoreData,
    page: page.current,
    updateSingleItemLocally,
    addItemToLocalArray,
    removeItemFromLocalArray,
    isFullReloading,
    retriggerFirstPage(currentOptions = {}) {
      const payload = {
        filters: currentOptions,
        page: 1,
        perPage: 16,
        isMinimal: currentOptions?.isMinimal || false,
      };

      delete payload?.filters?.isMinimal;

      trigger(
        qs.stringify({
          ...payload,
        }),
        false
      );
    },
    fetchNextPage(currentOptions = { perPage: options?.perPage || perPage }) {
      if (hasMoreData) {
        trigger(
          qs.stringify({
            ...currentOptions,
            page: page.current,
            perPage: currentOptions?.perPage || perPage,
            ...(currentOptions?.isMinimal && {
              isMinimal: options?.isMinimal,
            }),
          }),
          true
        );
      }
    },
  };
};
