import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import { useListGlobalListQuery } from "../../../../rtkStore/services/globalListApi";
import { useSearchParams } from "react-router-dom";
import { useGetUrlParams } from "../../../../hooks/useGetUrlParams";

const INITIAL_STATE = {
  feeRange: [0, 100000],
  feeType: "Semester",
  courseLevels: [],
};

const CatalogueSideFilters = ({ onFilterChange = () => {} }) => {
  const _paymentTypes = ["Semester", "Term", "Year", "Course"];

  const [searchParams, setSearchParams] = useSearchParams();

  const _catalogueSearchParams = useGetUrlParams();

  // RTK Query
  const { data: courseLevels = [], isLoading: loadingCourseLevels } =
    useListGlobalListQuery({
      type: "Course Levels",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  // react form hook
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  // methods
  const handleLevelSelect = (event, level) => {
    const currentSelectedLevels = watch("courseLevels");

    if (event.target.checked) {
      setValue("courseLevels", [...currentSelectedLevels, level?._id]);
    } else {
      setValue(
        "courseLevels",
        currentSelectedLevels.filter((el) => el !== level._id)
      );
    }
  };

  React.useEffect(() => {
    if (Object.entries(_catalogueSearchParams) <= 0) {
      reset({ ...INITIAL_STATE });
    }
  }, [searchParams]);

  React.useEffect(() => {
    const subscription = watch(
      handleSubmit((data) => {
        const _sideFilters = {
          ...data,
          feeRange: data?.feeRange?.join(","),
          courseLevels: data?.courseLevels?.join(","),
          page: 1,
          size: 10,
        };

        setSearchParams({ ..._catalogueSearchParams, ..._sideFilters });
      })
    );

    return () => subscription.unsubscribe();
  }, [watch, searchParams]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="py-4 font-medium">Course Options</div>

        {!!Object.entries(_catalogueSearchParams)?.length && (
          <Tooltip title="Reset filters" placement="left" arrow>
            <IconButton onClick={() => setSearchParams({})}>
              <FilterAltOffOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div className="flex flex-col space-y-6 ">
        <FilterSection>
          <div>
            <div className="flex justify-between">
              <FilterLabel>Tuition Fee</FilterLabel>

              <div className="text-sm">
                {`${watch("feeRange")[0]} - ${watch("feeRange")[1]}`}
              </div>
            </div>

            <div className="px-2">
              <Controller
                name="feeRange"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Slider
                    size="small"
                    getAriaLabel={() => "Tuition Fee Range"}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue)}
                    min={INITIAL_STATE?.feeRange[0]}
                    max={INITIAL_STATE?.feeRange[1]}
                    step={500}
                    valueLabelDisplay="auto"
                  />
                )}
              />
            </div>
          </div>

          <div>
            <FilterLabel>Payment Per</FilterLabel>

            <Controller
              name="feeType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                >
                  {_paymentTypes.map((el) => (
                    <FormControlLabel
                      key={el}
                      value={el}
                      control={<Radio size="small" />}
                      label={el}
                      style={{ margin: 0 }}
                      className="hover:bg-blue-50"
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </div>
        </FilterSection>

        <FilterSection>
          <FilterLabel>Course Levels</FilterLabel>

          {!loadingCourseLevels ? (
            <div className="flex flex-col space-y-1">
              {courseLevels?.map((level) => (
                <div
                  key={level?._id}
                  className="flex items-center space-x-2 py-1 px-2 hover:bg-blue-50"
                >
                  <Checkbox
                    id={level?._id}
                    size="small"
                    checked={!!watch("courseLevels")?.includes(level?._id)}
                    onChange={(event) => handleLevelSelect(event, level)}
                    style={{ padding: 0 }}
                  />
                  <label
                    htmlFor={level?._id}
                    className="cursor-pointer select-none "
                  >
                    {level?.name}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            "Loading..."
          )}
        </FilterSection>
      </div>
    </>
  );
};

export default CatalogueSideFilters;

const FilterLabel = ({ children }) => {
  return (
    <div className="mb-2 text-sm font-medium text-gray-500">{children}</div>
  );
};

const FilterSection = ({ children }) => {
  return (
    <section className="flex flex-col space-y-2 rounded border border-gray-300 p-4 hover:shadow">
      {children}
    </section>
  );
};
