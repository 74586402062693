import React from "react";

import { Link } from "react-router-dom";
import { IconButton, Button } from "@mui/material";
import moment from "moment";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";

import AppTable from "../../../components/Shared/AppTable";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useGetTaskListQuery } from "../../../rtkStore/services/taskApi";

const TaskListScreen = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const breadcrumbs = [{ title: "Tasks" }];

  // RTK Query
  const {
    data: taskList,
    isLoading: loadingTask,
    error,
  } = useGetTaskListQuery();

  // methods
  const getAssigneeName = (task) => {
    switch (task.assigneeSource) {
      case "Client":
        return `${task?.assignee?.firstName} ${task?.assignee?.lastName}`;

      case "Transaction":
        return task?.assignee?.title;

      case "User":
        return task?.assignee?.name;

      default:
        return "N/A";
    }
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/task/${row?._id}/detail`}
          className="hover:text-blue-600 hover:underline"
        >
          {row?.title ?? "N/A"}
        </Link>
      ),
    },
    {
      name: "Assignee",
      selector: (row) => getAssigneeName(row),
      cell: (row) => (
        <div title={getAssigneeName(row)} className="line-clamp-2">
          {getAssigneeName(row)}
        </div>
      ),
    },
    {
      name: "Category",
      selector: (row) => row.category,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      cell: (row) => moment(row?.dueDate).format("MMM Do YYYY"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/task/${row._id}/detail`}>
            <IconButton color="primary" aria-label="Edit Task">
              <InfoIcon />
            </IconButton>
          </Link>
          <Link to={`/app/task/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Task">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter by Assignee"
          value={filterText ? filterText : ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const PageActions = () => {
    return (
      <Link to="/app/task/create">
        <Button startIcon={<AddIcon />} variant="contained">
          New Task
        </Button>
      </Link>
    );
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <AppTable
        columns={columns}
        data={
          taskList?.filter(
            (item) =>
              item &&
              getAssigneeName(item)
                ?.toLowerCase()
                .includes(filterText.toLowerCase())
          ) ?? []
        }
        progressPending={loadingTask}
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
    </WithHeader>
  );
};

export default TaskListScreen;
