import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { FiberManualRecord, MenuBookOutlined } from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import AgentPromotions from "../../../components/Course/AgentPromotionsBadge";
import PriorityBadge from "../../../components/Course/PriorityBadge";

const UniversityCoursesTab = ({ universityCourses }) => {
  return (
    <>
      <Box
        padding={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="inline-flex" gap={1.5}>
          <MenuBookOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>
            University Affiliated Courses
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box paddingX={3}>
        {!!universityCourses?.length > 0 ? (
          universityCourses.map((course, index) => (
            <React.Fragment key={index}>
              <Box
                display="flex"
                alignItems="start"
                gap={2}
                paddingY={3}
                className="group"
              >
                <MenuBookOutlined
                  color="primary"
                  fontSize="small"
                  style={{ marginTop: 3 }}
                />

                <div className="flex flex-grow flex-col space-y-2">
                  <div className="flex items-center justify-between space-x-4">
                    <div className="flex flex-grow items-center space-x-2">
                      <div
                        className="cursor-pointer font-medium text-blue-600 line-clamp-1 group-hover:underline"
                        onClick={() =>
                          window.open(
                            `/app/course/${course?._id}/detail`,
                            "_blank"
                          )
                        }
                      >
                        {course?.title}
                      </div>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <div
                        className={
                          course?.isActive ? "text-green-600" : "text-red-600"
                        }
                      >
                        {course?.isActive ? "Active" : "Inactive"}
                      </div>
                    </div>

                    <div className="flex flex-shrink-0 space-x-2">
                      {!!course?.agentPromotions?.length && (
                        <AgentPromotions
                          promotions={course?.agentPromotions?.map(
                            (el) => el?.name
                          )}
                        />
                      )}

                      <PriorityBadge level={course?.priorityLevel} />
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <div className="flex space-x-2 ">
                      {course?.duration && <div>{course?.duration?.name}</div>}

                      {course?.level && (
                        <div className="text-gray-600">
                          {course?.level?.name} Level
                        </div>
                      )}
                    </div>

                    <FiberManualRecord
                      style={{ color: "gray", fontSize: 10 }}
                    />

                    <div className="flex space-x-2">
                      <div className=" text-green-600">
                        {course?.currency?.symbol ?? ""}
                      </div>

                      <div>{course?.tuitionFee}</div>

                      <div>
                        {" "}
                        {course?.currency ? `(${course?.currency?.code})` : ""}
                      </div>

                      <div>
                        {" "}
                        <span className="text-gray-500">per</span>{" "}
                        <span>{course?.feeType}</span>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="line-clamp-3">
                    {ReactHtmlParser(course.summary)}
                  </div>
                </div>
              </Box>

              {index !== universityCourses.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Affiliated Courses.</Box>
        )}
      </Box>{" "}
    </>
  );
};

export default UniversityCoursesTab;
