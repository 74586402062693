import React from "react";

import {
  Badge,
  Box,
  Divider,
  IconButton,
  Popover,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import { Settings } from "@mui/icons-material";

import { useLocalStorage } from "../../../hooks/useLocalStorage";

function a11yProps(index) {
  return {
    id: `search-${index}`,
    "aria-controls": `search-panel-${index}`,
  };
}

const SearchResultTabs = ({ tab, handleTabChange, searchResultCounts }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const allTabs = [
    "All",
    "Lead",
    "Client",
    "Transaction",
    "Workflow",
    "Application",
    "Course",
    "University",
    "Task",
  ];

  const [activeTabPreferences, setActiveTabPreferences] = useLocalStorage(
    "FSW_SEARCH_TAB",
    allTabs.slice(0, 6)
  );

  //   methods
  const handleChangeTabPreference = (checked, tab) => {
    if (!checked) {
      setActiveTabPreferences((prev) => prev.filter((el) => el !== tab));
    } else {
      if (activeTabPreferences.length >= 6) return;

      const alreadyExists = !!activeTabPreferences.find((el) => el === tab);

      if (alreadyExists) return;

      const originalTabIndex = allTabs.findIndex((el) => el === tab);

      activeTabPreferences.splice(originalTabIndex, 0, tab);

      setActiveTabPreferences(activeTabPreferences);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box flexGrow={1}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="search result tabs"
          >
            {activeTabPreferences.map((tab, index) => (
              <Tab
                key={tab}
                label={
                  searchResultCounts[tab] ? (
                    <Badge
                      badgeContent={searchResultCounts[tab] ?? 0}
                      color="primary"
                    >
                      <div className="mx-2 text-sm">{tab}</div>
                    </Badge>
                  ) : (
                    tab
                  )
                }
                value={tab}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>

        <Box flexShrink={0} marginRight={2}>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <Settings style={{ color: "gray" }} />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box className="w-60">
              <Box paddingX={2} paddingY={1}>
                <div className="text-sm font-semibold">
                  Select any 6 preferences
                </div>
              </Box>

              <Divider />

              {allTabs.map((tab, index) => (
                <React.Fragment key={tab}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={1}
                  >
                    <div className="text-sm font-medium"> {tab} </div>

                    <Switch
                      size="small"
                      checked={!!activeTabPreferences.find((pt) => pt === tab)}
                      disabled={
                        !activeTabPreferences.find((pt) => pt === tab) &&
                        activeTabPreferences.length >= 6
                      }
                      onChange={(e) =>
                        handleChangeTabPreference(e.target.checked, tab)
                      }
                    />
                  </Box>
                  {index !== allTabs.length && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Popover>
        </Box>
      </Box>

      <Divider style={{ marginTop: -1 }} />
    </>
  );
};

export default SearchResultTabs;
