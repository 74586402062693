import React from "react";

import LottieAnimationWrapper from "./LottieAnimationWrapper";

import emptyBoxAnimation from "../../../assets/lottie/empty-box.json";

const EmptyResult = ({ height = 250 }) => {
  return (
    <div className="flex flex-col items-center space-y-1 p-2">
      <LottieAnimationWrapper lottieFile={emptyBoxAnimation} height={height} />

      <div className="font-medium text-gray-600">No Data Found!</div>
    </div>
  );
};

export default EmptyResult;
