import React from "react";

import LottieAnimationWrapper from "./LottieAnimationWrapper";

import searchingAnimation from "../../../assets/lottie/searching.json";

const SearchingLoader = ({ height = 300 }) => {
  return (
    <LottieAnimationWrapper lottieFile={searchingAnimation} height={height} />
  );
};

export default SearchingLoader;
