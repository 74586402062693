import React from "react";

import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, TextField } from "@mui/material";
import moment from "moment";

const INITIAL_STATE = {
  openDate: "",
  deadline: "",
  termDate: "",
  censusDate: "",
};

const CourseDatesForm = ({
  datesDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  React.useEffect(() => {
    if (!datesDetail) return reset({ ...INITIAL_STATE });

    reset({ ...datesDetail });
  }, [datesDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Controller
              name="openDate"
              control={control}
              rules={{ required: "Open Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Application Open Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("openDate", e.target.value)}
                />
              )}
            />
            {errors.openDate && (
              <p className="error">{errors.openDate.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="deadline"
              control={control}
              rules={{ required: "Deadline Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Application Deadline"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("deadline", e.target.value)}
                />
              )}
            />
            {errors.deadline && (
              <p className="error">{errors.deadline.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="termDate"
              control={control}
              rules={{ required: "Term Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Term Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("termDate", e.target.value)}
                />
              )}
            />
            {errors.termDate && (
              <p className="error">{errors.termDate.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="censusDate"
              control={control}
              rules={{ required: "Census Date is required" }}
              render={({ field, onChange }) => (
                <>
                  <TextField
                    fullWidth
                    label="Cencus Date"
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(field.value).format("YYYY-MM-DD")}
                    onChange={(e) => setValue("censusDate", e.target.value)}
                  />
                </>
              )}
            />
            {errors.censusDate && (
              <p className="error">{errors.censusDate.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {datesDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default CourseDatesForm;
