import React from "react";
import { Avatar, Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/handleFormatDate";

import AttachFileIcon from '@mui/icons-material/AttachFile';

export const KBDocumentItem = ({ disableHover = false, article }) => {
  // hooks
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        maxWidth: "100%",
        // backgroundColor: !ticket?.isRead ? "#ededed" : "white",
        backgroundColor: "white",
        borderRadius: "5px",
        marginBottom: "12px",
        cursor: "pointer",
        padding: "1rem",
        // border: ticket.isRead ? "1px solid #f1f1f1" : "1px solid #b9b4b4",
        border: "1px solid #f1f1f1",
        "&:hover": {
          boxShadow: disableHover ? "none" : "rgba(0, 0, 0, 0.05) 0px 1px 4px",
        },
      }}
      className="parent-hover-visible"
      onClick={() => {
        navigate(`/app/knowledgebase/detail/${article._id}`);
      }}
    >
      <Box width="100%">
        <Box marginBottom={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" sx={{ gap: "12px" }}>
              {article?.category?.name && (
                <>
                  <Tooltip title={`Category`}>
                    <Typography className="c-label">{article?.category?.name || ""}</Typography>
                  </Tooltip>

                  <Typography variant="h6" color="textSecondary">
                    &bull;
                  </Typography>
                </>
              )}

              {article?.subCategory?.name && (
                <>
                  <Tooltip title={`Subcategory`}>
                    <Typography className="c-label">{article?.subCategory?.name || ""}</Typography>
                  </Tooltip>

                  <Typography variant="h6" color="textSecondary">
                    &bull;
                  </Typography>
                </>
              )}

              {article?.publishedDate && (
                <>
                  <Tooltip title={`${formatDate(article?.publishedDate)}`}>
                    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                      {formatDate(article?.publishedDate)}
                    </Typography>
                  </Tooltip>

                  <Typography variant="h6" color="textSecondary">
                    &bull;
                  </Typography>
                </>
              )}

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                V{article?.version}
              </Typography>
            </Box>

            <Box
              className="child-invisible"
              display="flex"
              alignItems="center"
              sx={{ gap: "8px" }}
            >
              <Tooltip title="Edit Article">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/app/knowledgebase/edit/${article?._id}`);
                  }}
                  style={{ color: "#435883" }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 500, marginTop: "4px", color: "#435883" }}
          >
            {article.title}
          </Typography>

          {/* <Typography variant="subtitle1" color="textSecondary">
            {article.subtitle}
          </Typography> */}
        </Box>

        <Box
          display="flex"
          marginTop={2}
          alignItems="center"
          sx={{ gap: "1rem" }}
        >
          <Box display="flex" alignItems="center" gap="8px">
            <Avatar
              sx={{ width: 30, height: 30 }}
              style={{ fontSize: 10 }}>{article?.author?.name?.split(" ").slice(0, 2).map(x => x[0]).join("")}</Avatar>

            <Box
              sx={{ gap: "8px" }}
            >
              <Typography className="text-primary" sx={{ fontSize: 14 }}>{article?.author?.name}</Typography>
              {/* <Typography className="text-secondary fs-14">{article?.author?.email}</Typography> */}
            </Box>

          </Box>

          <Typography
            variant="h6"
            style={{
              fontWeight: 500,
              fontSize: 14,
              color: "gray",
            }}
          >
            &bull;
          </Typography>

          <Chip
            size="small"
            label={article?.status}
            color={
              article?.status === "Active"
                ? "primary"
                : article?.status === "Inactive"
                  ? "error"
                  : "secondary"
            }
          // variant="outlined"
          />

          <Typography
            variant="h6"
            style={{
              fontWeight: 500,
              fontSize: 14,
              color: "gray",
            }}
          >
            &bull;
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: "8px",
              color: article?.isPublished ? "#3F51B5" : "#fb4646",
            }}
          >
            {/* <i className="fa-solid fa-check"></i> */}

            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, fontSize: 14 }}
            >
              {article?.publishStatus}
            </Typography>
          </Box>

          {article?.attachments?.length > 0 && (
            <Tooltip
              title={`${article?.attachments?.length} attachments available`}
            >
              <Box display="flex" alignItems="center" sx={{ gap: "12px" }}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "gray",
                  }}
                >
                  &bull;
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ gap: "8px", color: "#435883" }}
                >
                  <AttachFileIcon className="fs-14" />
                  <Typography>{article?.attachments?.length}</Typography>
                </Box>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};
