import React from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  EditOutlined,
  FiberManualRecord,
  FeedbackOutlined,
  Add,
} from "@mui/icons-material";

import {
  useAddFeedbackResponseMutation,
  useUpdateFeedbackResponseMutation,
} from "../../../../rtkStore/services/feedbackApi";

import { selectLoggedInUser } from "../../../../rtkStore/authSlice";

const TaskResponse = ({ feedbackResponses = [] }) => {
  const [showResponseForm, setShowResponseForm] = React.useState(false);
  const [response, setResponse] = React.useState("");
  const [selectedResponse, setSelectedResponse] = React.useState(null);

  const { id: feedbackId } = useParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  // RTK Query
  const [addResponse, { isLoading: addingResponse }] =
    useAddFeedbackResponseMutation();

  const [updateResponse, { isLoading: updatingResponse }] =
    useUpdateFeedbackResponseMutation();

  // methods
  const handleCancelResponse = () => {
    setShowResponseForm(false);
    setResponse("");
    setSelectedResponse(null);
  };

  const handleAddResponse = async () => {
    const payload = {
      feedbackId,
      content: response,
    };

    try {
      await addResponse(payload).unwrap();
      toast.success("Response successfully added!");
      handleCancelResponse();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add response!");
    }
  };

  const handleEditResponse = (response) => {
    setShowResponseForm(true);
    setSelectedResponse(response);
  };

  const handleUpdateResponse = async () => {
    try {
      await updateResponse({
        feedbackId,
        _id: selectedResponse?._id,
        content: response,
      }).unwrap();
      toast.success("Response successfully updated!");
      handleCancelResponse();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update response!");
    }
  };

  React.useEffect(() => {
    if (!selectedResponse) return;

    setResponse(selectedResponse?.content);
  }, [selectedResponse]);

  return (
    <>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <Badge
            badgeContent={feedbackResponses.length}
            color="primary"
            showZero
          >
            <FeedbackOutlined style={{ color: "gray" }} />
          </Badge>

          <Typography style={{ fontWeight: 500 }}>Responses</Typography>
        </Box>

        {!showResponseForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowResponseForm(true)}
          >
            Response
          </Button>
        )}
      </Box>

      <Collapse in={showResponseForm}>
        <Box padding={3} paddingBottom={1} className="expanded-form">
          <TextField
            placeholder="Leave a response"
            fullWidth
            multiline
            rows={2}
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />

          <Box display="flex" justifyContent="flex-end" gap={2} marginTop={1}>
            <Button
              disabled={addingResponse || updatingResponse}
              onClick={handleCancelResponse}
            >
              Cancel
            </Button>

            <Button
              disabled={!response.length || addingResponse || updatingResponse}
              onClick={
                !selectedResponse ? handleAddResponse : handleUpdateResponse
              }
            >
              {selectedResponse ? "Update" : "Add"}
            </Button>
          </Box>
        </Box>
      </Collapse>

      <Divider />

      <Box paddingX={3}>
        {!!feedbackResponses?.length > 0 ? (
          feedbackResponses.map((response, index) => (
            <React.Fragment key={response._id}>
              <Box display="flex" alignItems="start" gap={2} paddingY={3}>
                <FeedbackOutlined
                  fontSize="small"
                  color="primary"
                  style={{ marginTop: 10 }}
                />

                <Box display="flex" flexGrow={1} flexDirection="column" gap={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      display="flex"
                      alignItems="center"
                      flexGrow={1}
                      gap={2}
                    >
                      <Typography> {response?.createdBy.name}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography style={{ color: "gray" }}>
                        {`${moment(response?.createdAt).format(
                          "MMM D, YYYY"
                        )} at ${moment(response?.createdAt).format(
                          "HH:MM:ss a"
                        )}`}
                      </Typography>
                    </Box>

                    {loggedInUser?._id === response?.createdBy?._id && (
                      <IconButton onClick={() => handleEditResponse(response)}>
                        <EditOutlined />
                      </IconButton>
                    )}
                  </Box>

                  <Box>{response?.content}</Box>
                </Box>
              </Box>

              {index !== feedbackResponses.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Responses.</Box>
        )}
      </Box>
    </>
  );
};

export default TaskResponse;
