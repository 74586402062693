import React from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, EditOutlined, RestartAlt } from "@mui/icons-material";

const ListOptionForm = ({
  isBusy = false,
  listOptionDetail,
  selectedOption,
  onSubmit = () => {},
  onReset = () => {},
}) => {
  const [searchParams] = useSearchParams();

  const submitRef = React.useRef("");

  const optionType = React.useMemo(() => {
    const types = searchParams.get("type")?.split(",");

    if (!types?.length) return;

    return types[types.length - 1];
  }, [searchParams]);

  //   react form hook
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const optionName = watch("name");

  const allowGrouping = listOptionDetail?.allowGrouping ?? false;
  const groupOptions = listOptionDetail?.groups ?? [];

  //   methods
  const handleOnEnter = (event) => {
    if (!submitRef?.current) return;
    if (event.key === "Enter") submitRef?.current?.click();
  };

  React.useEffect(() => {
    if (!selectedOption) return;

    reset({
      name: selectedOption?.name,
      ...(allowGrouping ? { group: selectedOption?.group } : ""),
    });

    return () => {
      reset({ name: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <Paper elevation={0} variant="outlined">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
        onKeyPress={handleOnEnter}
      >
        <Typography>
          <span className="font-semibold tracking-wider">{optionType}</span>{" "}
          <span className="text-gray-500">Options</span>
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <Box>
            <TextField
              size="small"
              type="text"
              placeholder="Enter option name"
              {...register(`name`, {
                required: "Option name is required",
              })}
              style={{ width: 400 }}
            />
            {errors.name && <div className="error">{errors.name.message}</div>}
          </Box>

          {allowGrouping && (
            <Box>
              <Controller
                name="group"
                control={control}
                rules={{ required: "Please select a group" }}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-group">Group</InputLabel>

                    <Select
                      {...field}
                      fullWidth
                      labelId="select-group"
                      label="Group"
                      placeholder="Select Level"
                      size="small"
                      id="role"
                      value={field?.value ?? ""}
                      style={{ width: 200 }}
                    >
                      {groupOptions.map((option, index) => (
                        <MenuItem key={`${option}-${index}`} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {errors.group && (
                <div className="error">{errors.group.message}</div>
              )}
            </Box>
          )}

          <Button
            ref={submitRef}
            variant="contained"
            startIcon={!selectedOption ? <Add /> : <EditOutlined />}
            disabled={isBusy}
            onClick={handleSubmit((d) => onSubmit(d))}
          >
            {selectedOption ? "Update Option" : "Add Option"}
          </Button>

          {!!optionName?.length && (
            <Tooltip title="Reset" arrow placement="top">
              <IconButton
                onClick={() => {
                  reset({ name: "" });
                  onReset();
                }}
              >
                <RestartAlt color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default ListOptionForm;
