import React from "react"
import Carousel from "react-material-ui-carousel"
import "./componentStyle.css"
import image2 from "../screens/assets/images/banner0.png"
// import image3 from "../screens/assets/images/banner2.png";
// import image4 from "../screens/assets/images/banner3.png";
// import image5 from "../screens/assets/images/banner4.png";

export const CarouselPage = () => {
  const items = [
    {
      // name: "Let us help you turn your Australian dream into reality.",
      name: "Let us help you turn your dream into reality.",
      description: "Think about your bright future, make the right move today.",
      image: image2,
    },
    // {
    //   name: "EQUIP YOU WITH BEST EDUCATION",
    //   description: "Your Education is your Future, make the right move today",
    //   image: image3,
    // },
    // {
    //   name: "EQUIP YOU WITH BEST EDUCATION",
    //   description: "Your Education is your Future, make the right move today",
    //   image: image4,
    // },
    // {
    //   name: "EQUIP YOU WITH BEST EDUCATION",
    //   description: "Your Education is your Future, make the right move today",
    //   image: image5,
    // },
  ]
  function Item(props) {
    return (
      <>
        <div
          className='BannerContainer'
          data-aos='fade-right'
          data-aos-offset='200'
          data-aos-delay='50'
          data-aos-duration='2000'
        >
          <img
            src={props.item.image}
            className='BannerImage'
            alt='bannerimage'
          ></img>
          <h2 className='BannerTitle'>{props.item.name}</h2>
          <p className='BannerDescription'> {props.item.description}</p>

          <a
            href='https://fsw.firstsourceworld.com/public/courses'
            rel='noreferrer'
            target='_blank'
            className='BannerBtn'
          >
            Search Course
          </a>
        </div>
      </>
    )
  }
  return (
    <>
      <Carousel
        className='CarouselContainer'
        swipe={true}
        indicators={true}
        indicatorContainerProps={{
          style: {
            zIndex: 1,
            marginTop: "-20px",
            position: "relative",
          },
        }}
        indicatorProps={{
          style: {
            color: "#000",
            background: "#000",
            backgroundColor: "#000",
          },
        }}
        // navButtonsProps={{
        //   style: {
        //     backgroundColor: "#eb2428",
        //     opacity: 0.2,
        //   },
        // }}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </>
  )
}
