import React from "react";

const CustomTabPanel = ({ activeTab, children, value, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== activeTab}
      id={`simple-tabpanel-${activeTab}`}
      aria-labelledby={`simple-tab-${activeTab}`}
      {...other}
    >
      {children}
    </div>
  );
};

export default CustomTabPanel;
