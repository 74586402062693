import { DateTime } from "luxon";

export const formatDate = (
  date,
  format = DateTime.DATETIME_MED,
  options = { includeTime: true }
) => {
  if (!date) return "";

  if (!options?.includeTime) {
    return DateTime.fromISO(date).toLocaleString(format || DateTime.DATE_SHORT);
  }

  return DateTime.fromISO(date).toLocaleString(format);
};

export const convertDate = (date, options = { isForEditMode: false }) => {
  if (!date) return "";

  if (options.isForEditMode) {
    return DateTime.fromISO(date).toISODate();
  } else {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
  }
};

export const formatRelativeTime = (date) => {
  if (!date) return;

  return DateTime.fromISO(date).toRelative();
};

const localizeDateFull = (date) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);

const localizeDateShort = (date) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);

const localizeDateTimeMed = (date) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);

const localizeDateTimeSeconds = (date) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

const localizeDateTimeShort = (date) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);

const toISODate = (date) => DateTime.fromISO(date).toISODate();

export {
  localizeDateFull,
  localizeDateShort,
  localizeDateTimeMed,
  localizeDateTimeSeconds,
  localizeDateTimeShort,
  toISODate,
};
