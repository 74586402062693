import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ApplicationForm from "./applicationComponents/ApplicationForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetApplicationDetailQuery,
  useUpdateApplicationMutation,
} from "../../../rtkStore/services/applicationApi";

const ApplicationEditScreen = () => {
  const breadcrumbs = [
    { title: "Applications", path: "/app/application/list" },
    { title: "Edit Application" },
  ];

  const { id } = useParams();

  const navigate = useNavigate();

  // RTK Query
  const {
    data: applicationDetail,
    isLoading: loadingApplicationDetail,
    error,
  } = useGetApplicationDetailQuery(id);

  const [updateApplication, { isLoading: updatingApplication }] =
    useUpdateApplicationMutation();

  // methods
  const handleApplicationUpdate = async (data) => {
    try {
      await updateApplication(data).unwrap();
      navigate(`/app/application/${applicationDetail?._id}/detail`);
      toast.success("Application successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update application!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      isBusy={loadingApplicationDetail || updatingApplication}
    >
      <ApplicationForm
        applicationDetail={applicationDetail}
        isBusy={loadingApplicationDetail || updatingApplication}
        onSubmit={handleApplicationUpdate}
      />
    </WithHeader>
  );
};

export default ApplicationEditScreen;
