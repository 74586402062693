import React from "react";
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "../../../../hooks/useDebounce";
import { knowledgebaseStatusOptions } from "../../../../constants/kbConstants";

import { CloseRounded, SearchOutlined } from "@mui/icons-material";


const filterArticlePublishedOptions = [
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Submitted",
    value: "Submitted",
  },
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Published",
    value: "Published",
  },
];

export const KBListScreenHeader = ({
  onQueryFilterChange,
  onDirectFilterObjChange,
}) => {
  // states
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [selectedPublishStatus, setSelectedPublishStatus] =
    React.useState(null);

  const [searchTerm, setSearchTerm] = React.useState("");

  const debouncedSearch = useDebounce(searchTerm, 500);

  //   hooks
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // methods
  const getURLParams = React.useCallback(() => {
    const params = {};
    for (const entry of searchParams.entries()) {
      params[entry[0]] = entry[1];
    }
    return params;
  }, [searchParams]);

  const handleClickSearchBtn = () => {
    const allSearchParams = { ...getURLParams() };
    if (searchTerm?.trim()) {
      allSearchParams.search = searchTerm;
    } else {
      delete allSearchParams.search;
    }
    setSearchParams(allSearchParams);
    onQueryFilterChange({
      filter: "search",
      value: searchTerm
    });
  };

  // React.useEffect(() => {
  //   const filters = getURLParams();

  //   if (!debouncedSearch) {
  //     delete filters.search;
  //     setSearchParams({
  //       ...filters,
  //     });
  //     return;
  //   }

  //   setSearchParams({
  //     ...filters,
  //     search: debouncedSearch,
  //   });
  // }, [debouncedSearch, getURLParams, setSearchParams]);

  React.useEffect(() => {
    const params = { ...getURLParams() };

    if (
      params["status"] !== undefined &&
      knowledgebaseStatusOptions.find((x) => x.value === params.status)
    ) {
      setSelectedStatus({
        label: params.status,
        value: params.status,
      });
    }

    if (params["publishStatus"] !== undefined) {
      if (
        filterArticlePublishedOptions.find(
          (p) => p.value === params["publishStatus"]
        )
      ) {
        setSelectedPublishStatus({
          label: params.publishStatus,
          value: params.publishStatus,
        });
      }
    }

    if (params["search"] !== undefined) {
      setSearchTerm(params.search);
    }
  }, [getURLParams]);

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      gap={1}
      paddingX={2}
      paddingY={2}
      className="full-width-dashboard"
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        position={"relative"}
        sx={{
          gap: "1rem",
        }}
      >
        <div className="">
          <label htmlFor="" className="form-font">
            Filter by status
          </label>
          <Select
            style={{
              marginRight: "1rem",
            }}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            className="form-font"
            placeholder="Select status"
            isClearable
            options={knowledgebaseStatusOptions.filter(
              (o) => o.value !== "Awaiting Approval"
            )}
            value={selectedStatus}
            onChange={(data) => {
              if (!data) {
                setSelectedStatus(undefined);
                onQueryFilterChange({ value: "", filter: "status" });
              } else {
                setSelectedStatus({
                  label: data.value,
                  value: data.value,
                });
                onQueryFilterChange({ value: data.value, filter: "status" });
              }
            }}
          />
        </div>

        <div className="">
          <label htmlFor="" className="form-font">
            Filter by Publish Status
          </label>
          <Select
            style={{
              marginRight: "1rem",
            }}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            className="form-font"
            placeholder="Select publish status"
            isClearable
            options={filterArticlePublishedOptions}
            value={selectedPublishStatus}
            onChange={(data) => {
              if (!data) {
                setSelectedPublishStatus(undefined);
                onQueryFilterChange({ value: "", filter: "publishStatus" });
              } else {
                setSelectedPublishStatus({
                  label: data.value,
                  value: data.value,
                });
                onQueryFilterChange({
                  value: data.value,
                  filter: "publishStatus",
                });
              }
            }}
          />
        </div>

        <Box display="flex" alignItems="flex-end" gap={1}>
          <Box>
            <label htmlFor="" className="form-font">
              Search
            </label>
            <TextField
              autoFocus
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              placeholder="Search knowledbase..."
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm?.trim() && (
                      <IconButton onClick={() => setSearchTerm("")}>
                        <CloseRounded className="fs-14" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <IconButton size="small" onClick={() => {
            handleClickSearchBtn();
          }}>
            <SearchOutlined />
          </IconButton>
        </Box>

        {/* <div>
          <label className="form-font d-block" htmlFor="flexCheckDefault">
            Filter By Teams Visibility
            <Tooltip title="This filter is here just for testing purposes">
              <i className="fa-solid fa-circle-info ms-2 label-color"></i>
            </Tooltip>
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            checked={filterArticleVisibility}
            onChange={(e) => {
              setFilterArticleVisibility(e.target.checked);
              onDirectFilterObjChange({
                value: e.target.checked,
                filter: "filterByTeamVisibility",
              });
            }}
          />
        </div> */}
      </Box>
      <Box>
        <Button
          onClick={() => navigate(`/app/knowledgebase/add`)}
          variant="contained"
          color="primary"
        >
          Add Document
        </Button>
      </Box>
    </Box>
  );
};
