import React from "react";
import { useParams } from "react-router-dom";
import { Badge, Box, Divider, Paper, Typography } from "@mui/material";
import {
  FiberManualRecord,
  StickyNote2Outlined,
  NoteAltOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParse from "html-react-parser";

import { useListClientAccumulatedNotesQuery } from "../../../../rtkStore/services/clientApi";
import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";

const NoteItem = ({ note }) => {
  return (
    <Box display="flex" alignItems="start" gap={2} paddingY={3}>
      <NoteAltOutlined
        color="primary"
        fontSize="small"
        style={{ marginTop: 3 }}
      />
      <Box display="flex" flexGrow={1} flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography>{note?.createdBy[0]?.name}</Typography>

            <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

            <Typography style={{ color: "var(--primary)" }}>
              {note?.type}
            </Typography>

            <FiberManualRecord style={{ color: "gray", fontSize: 10 }} />

            <Typography style={{ color: "gray" }}>
              {`${moment(note?.createdAt).format("MMM D, YYYY")} at ${moment(
                note?.createdAt
              ).format("HH:MM:ss a")}`}
            </Typography>
          </Box>

          <div className="flex-shrink-0 rounded bg-blue-500 px-2 py-0.5 text-sm text-white">
            {note?.noteType}
          </div>
        </Box>

        <Box>{ReactHtmlParse(note.content)}</Box>
      </Box>
    </Box>
  );
};

const ClientAccumulatedNotesTab = () => {
  const { id: clientId } = useParams();

  // RTK Query
  const { data: clientNotes = [], isLoading: loadingClientNotes } =
    useListClientAccumulatedNotesQuery(clientId);

  const _sortedNoteList = React.useMemo(() => {
    return [...clientNotes]?.sort((a, b) =>
      a?.createdAt > b?.createdAt ? -1 : 1
    );
  }, [clientNotes]);

  return (
    <Paper elevation={0} variant="outlined">
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge
              badgeContent={_sortedNoteList?.length ?? 0}
              color="primary"
              className="relative"
            >
              <NoteAltOutlined style={{ color: "gray" }} />{" "}
            </Badge>

            <Typography style={{ fontWeight: 500 }}>All Notes</Typography>
          </Box>
        </Box>
      </Box>

      <Divider />
      {!loadingClientNotes ? (
        <Box paddingX={3}>
          {_sortedNoteList?.length ? (
            _sortedNoteList?.map((note, index) => (
              <React.Fragment key={note?._id}>
                <NoteItem note={note} />

                {index !== _sortedNoteList.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <Box paddingY={3}>No Notes available.</Box>
          )}
        </Box>
      ) : (
        <RowsSkeleton />
      )}
    </Paper>
  );
};

export default ClientAccumulatedNotesTab;
