import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import FlagIcon from '@mui/icons-material/Flag';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilterAltIcon from "@mui/icons-material/FilterListSharp";

import InfiniteScroll from "react-infinite-scroll-component";
import { useKnowledgebaseContext } from "../../../../context/KBContext";
import { CONTEXT_CONSTANTS } from "../../../../constants/contextConstants";
import { knowledgebaseStatusOptions } from "../../../../constants/kbConstants";
import { GenericListSkeleton } from "../../../../components/Shared/GenericListSkeleton";
import { formatDate } from "../../../../utils/handleFormatDate";
import { useGetURLParams } from "../../../../utils/getUrlQueryParams";

const perPage = 10;

export const KnowledgebaseDetailSidebar = ({
  articles,
  isFetching,
  isFullReloading,
  hasNextPage,
  activeArticleId,
  fetchNextPage,
  onResetSearchFilters,
  onApplyKBFilters,
  onKBFilterChange,
}) => {
  // states
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [kbAllSearchText, setKBAllSearchText] = React.useState("");
  const isFilterMenuOpen = Boolean(anchorEl);

  // hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dispatch: articleDispatch } = useKnowledgebaseContext();
  const allSearchParams = useGetURLParams();

  const onSelectCase = (articleItem) => {
    if (!articleItem?._id) return;
    let rawUrl = `/app/knowledgebase/detail/${articleItem._id}`;

    // if we directly add the filter query, it will again make the api call to refetch tickets list, since the "fitler" query changed
    // using this if condition, it will make sure that this api call won't be made if the user had selected any filter from the dropdown
    if (searchParams.get("status")) {
      rawUrl += `?status=${searchParams.get("status")}`;
    }

    articleDispatch({
      type: CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE,
      payload: {
        selectedArticle: articleItem,
      },
    });

    navigate(rawUrl);
  };

  const onResetCaseFilters = () => {
    setKBAllSearchText("");

    onResetSearchFilters();
  };

  React.useEffect(() => {
    if (allSearchParams.filter) {
      const optionDetail = knowledgebaseStatusOptions.find(opt => opt.value === allSearchParams.filter);
      if (optionDetail) {
        setSelectedFilter(optionDetail);
      }
    }
  }, []);

  return (
    <Box
      position="relative"
      top={0}
      left={0}
      zIndex={10}
      minWidth={"450px"}
      maxWidth="450px"
      borderRight="1px solid #f1f1f1"
      sx={{
        backgroundColor: "#fff",
        overflowY: "hidden",
      }}
    >
      {/* header */}
      <Box
        position="sticky"
        top={0}
        left={0}
        zIndex={10}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={2}
        paddingY={1}
        height={"70px"}
        sx={{
          gap: "1rem",
          backgroundColor: "#fff",
          borderBottom: "1px solid #f1f1f1",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          height={"70px"}
          width="100%"
          sx={{
            gap: "1rem",
          }}
        >
          <IconButton size="small" onClick={() => navigate("/app/knowledgebase/list")}>
            <ChevronLeftIcon />
          </IconButton>
          <Select
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            className="form-font"
            placeholder="Select a filter"
            isClearable
            value={selectedFilter}
            onChange={(data) => {
              if (!data) {
                setSelectedFilter(undefined);
                onKBFilterChange({ value: "", filter: "filter" });
              } else {
                setSelectedFilter({
                  label: data.label,
                  value: data.value,
                });
                onKBFilterChange({ value: data.value, filter: "filter" });
              }
            }}
            options={knowledgebaseStatusOptions}
          />
          {isFetching && <CircularProgress size={20} />}
        </Box>

        <ClickAwayListener
          onClickAway={() => {
            if (anchorEl) setAnchorEl(null);
          }}
        >
          <Box>
            <Tooltip title="Filter cases by more parameters">
              <IconButton
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
              >
                <FilterAltIcon />
              </IconButton>
            </Tooltip>

            <Popper
              style={{ zIndex: 20 }}
              open={isFilterMenuOpen}
              anchorEl={anchorEl}
            >
              <Box
                display="flex"
                flexDirection="column"
                minWidth="300px"
                minHeight="300px"
                borderRadius="5px"
                paddingX={2}
                paddingY={2}
                border="1px solid #f1f1f1"
                zIndex={10}
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0px 10px 20px 0px rgba(82, 63, 105, 0.03)",
                }}
              >
                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                  FIlter Options
                </Typography>

                {/* search by case number */}
                {/* <Box marginTop={2}>
                  <label htmlFor={`caseNumber`} className="form-label">
                    Case Number
                  </label>
                  <input
                    type="text"
                    name={`caseNumber`}
                    className="form-control form-font py-2"
                    placeholder="Case number"
                    value={caseNoSearch}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCaseNoSearch(value);
                    }}
                  />
                </Box> */}

                {/* search knowledgebase */}
                <Box marginTop={2}>
                  <label htmlFor={`searchKnowledgebase`} className="form-label">
                    Search Knowledgebase
                  </label>
                  <input
                    type="text"
                    name={`searchKnowledgebase`}
                    className="form-control form-font py-2"
                    placeholder="Knowledgebase title, tags, summary, author etc"
                    value={kbAllSearchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      setKBAllSearchText(value);
                    }}
                  />
                </Box>

                <Box
                  marginTop="auto"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ gap: "1rem" }}
                >
                  <Button
                    onClick={() => {
                      onApplyKBFilters({ kbAllSearchText });
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Apply Filters
                  </Button>
                  {/* <Button>Reset Filters</Button> */}
                  <Button onClick={onResetCaseFilters}>Reset Filters</Button>
                </Box>
              </Box>
            </Popper>
          </Box>
        </ClickAwayListener>
      </Box>

      {isFullReloading ? (
        <Box marginRight={1}>
          <GenericListSkeleton number={10} height={80} mb={3} />
        </Box>
      ) : (
        <Box
          sx={{
            overflowY: "hidden",
          }}
          marginTop={2}
        >
          <InfiniteScroll
            height="calc(100vh - 80px - 70px)"
            // style={{ overflowY: "hidden" }}
            style={{ paddingRight: "8px" }}
            dataLength={articles ? articles.length : perPage}
            next={() => {
              fetchNextPage({ activeArticleId, perPage });
            }}
            hasMore={hasNextPage}
            scrollThreshold={0.9}
            loader={<GenericListSkeleton height={140} mb={2} />}
            endMessage={<></>}
          >
            {!isFetching && articles && articles.length === 0 && (
              <Box paddingY={2} textAlign="center">
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 500, fontSize: 18 }}
                >
                  No Knowledgebase Articles Available
                </Typography>
              </Box>
            )}

            {articles &&
              articles.map((article, idx) => {
                return (
                  <Box
                    onClick={() => onSelectCase(article)}
                    key={`sidebar-article-${article._id}`}
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      marginBottom: "12px",
                      cursor: "pointer",
                      padding: "1rem",
                      border: `1px solid ${activeArticleId === article._id
                        ? "#66b2ff"
                        : "#dedede70"
                        }`,
                      backgroundColor: `${activeArticleId === article._id ? "#66b2ff70" : "#fff"
                        }`,
                      "&:hover": {
                        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 12px",
                      },
                    }}
                  >
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        marginBottom={1}
                        sx={{ gap: "8px" }}
                      >
                        {article?.category?.name && (
                          <>
                            <Typography
                              variant="subtitle1"
                              style={{ fontSize: 14 }}
                            >
                              {article.category?.name}
                            </Typography>
                          </>
                        )}

                        {article?.publishedDate && (
                          <>
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "400",
                                fontSize: 14,
                                color: "gray",
                              }}
                            >
                              &bull;
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontWeight: "400",
                                fontSize: 14,
                                color: "#435883",
                              }}
                            >
                              {formatDate(article?.publishedDate)}
                            </Typography>
                          </>
                        )}

                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "400",
                            fontSize: 14,
                            color: "gray",
                          }}
                        >
                          &bull;
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: article?.isPublished ? "#435883" : "#E33333",
                          }}
                        >
                          {article?.publishStatus}
                        </Typography>
                      </Box>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: "400",
                          fontSize: 14,
                          color: "#435883",
                        }}
                      >
                        {article?.title}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      marginTop={2}
                      sx={{ gap: "8px" }}
                    >
                      <Chip
                        label={article?.status}
                        color={
                          article?.status === "Active"
                            ? "primary"
                            : article?.status === "Inactive"
                              ? "default"
                              : "secondary"
                        }
                      />

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "400",
                          fontSize: 14,
                          color: "gray",
                        }}
                      >
                        &bull;
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: "400",
                          fontSize: 14,
                          color: "#435883",
                        }}
                      >
                        V{article?.version}
                      </Typography>

                      {article?.attachments?.length > 0 && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: "400",
                              fontSize: 14,
                              color: "gray",
                            }}
                          >
                            &bull;
                          </Typography>
                          <Tooltip
                            title={`${article?.attachments?.length} attachments available`}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{ color: "#435883" }}
                            >
                              <Typography>
                                {article?.attachments?.length}
                              </Typography>
                              <AttachFileIcon style={{ fontSize: 14 }} />
                            </Box>
                          </Tooltip>
                        </>
                      )}

                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "400",
                          fontSize: 14,
                          color: "gray",
                        }}
                      >
                        &bull;
                      </Typography>

                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ gap: "8px", color: "#435883" }}
                      >
                        <i className="fa-solid fa-feather"></i>

                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "400", fontSize: 14 }}
                        >
                          {article?.author?.name}
                        </Typography>
                      </Box>

                      {article?.isFlagged && (
                        <>
                          <Typography
                            variant="h6"
                            style={{
                              fontWeight: "400",
                              fontSize: 14,
                              color: "gray",
                            }}
                          >
                            &bull;
                          </Typography>

                          <Tooltip title="Article flagged for review">
                            <IconButton
                              style={{ marginLeft: "-4px", marginTop: "-2px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `${window.location.pathname}/comments`
                                );
                              }}
                            >
                              <FlagIcon
                                style={{ color: "#e33333", fontSize: 16 }}
                              ></FlagIcon>
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </InfiniteScroll>
        </Box>
      )}
    </Box>
  );
};
