import React from "react";

import { getPluralWord } from "../../utils/getFormattedString";
import { getDateDifference } from "../../utils/getDateDifference";

const RemainingDays = ({ date }) => {
  return date ? (
    getDateDifference(null, date) > -1 && getDateDifference(null, date) < 1 ? (
      <div className="bg-blue-400 px-2 py-0.5 text-sm text-blue-50">Today</div>
    ) : (
      getPluralWord("day", getDateDifference(date), "s")
    )
  ) : (
    "N/A"
  );
};

export default RemainingDays;
