import React from "react";
import { IconButton, Pagination } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import CourseSearchCard from "./CourseSearchCard";
import EmptyResult from "../../../../../components/Shared/Lottie/EmptyResult";

const CourseSearchResults = ({
  searchRes = [],
  currentPage = 0,
  totalPaginatedPages = 0,
  onPageChange = () => {},
  onResetFilter = () => {},
}) => {
  const searchResults = React.useMemo(() => {
    return searchRes?.data;
  }, [searchRes]);

  return (
    <div>
      <div className="sticky top-36 z-10 mb-4 flex items-center justify-between bg-gray-50 pb-2">
        <div className="font-medium">
          <span className="text-2xl font-semibold text-blue-600">
            {searchRes?.totalItems}
          </span>

          <span className="text-gray-600"> result(s) found! </span>
        </div>

        <div className="flex items-center space-x-4">
          {!!searchResults?.length && (
            <div className="flex items-center space-x-1 font-medium text-gray-500">
              <span>Showing {searchResults?.length} Courses - </span>
              <span className="text-blue-600">{currentPage}</span>
              <span>/</span>
              <span>{`${totalPaginatedPages} page`}</span>
            </div>
          )}
          <IconButton
            title="Toggle Filters"
            size="small"
            onClick={onResetFilter}
          >
            <FilterList color="primary" />
          </IconButton>
        </div>
      </div>

      {searchResults?.length ? (
        <>
          <div className="flex flex-col space-y-5">
            {searchResults?.map((course) => (
              <CourseSearchCard key={course?._id} course={course} />
            ))}
          </div>

          <br />

          <div className="flex justify-end">
            <Pagination
              color="primary"
              variant="outlined"
              count={totalPaginatedPages}
              page={currentPage}
              onChange={(_, value) => onPageChange(value)}
            />
          </div>
        </>
      ) : (
        <EmptyResult />
      )}
    </div>
  );
};

export default CourseSearchResults;
