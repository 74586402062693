const getNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPluralWord = (word, comparable, suffix, returnWithNum = true) => {
  const _pluralized = Math.abs(comparable) > 1 ? `${word}${suffix}` : word;

  return returnWithNum
    ? `${getNumberWithCommas(comparable)} ${_pluralized}`
    : _pluralized;
};

export { getNumberWithCommas, getPluralWord };
