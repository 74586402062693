import React from "react";

import { Route, Routes } from "react-router-dom";

import AccountInfoScreen from "../screens/Admin/Account/AccountInfoScreen";

import PageScreen404 from "../screens/ErrorPages/404PageScreen";

// middlewares
import AccountAdminRoute from "../routes/middlewares/AccountAdminRoute";

const AccountRouter = () => {
  return (
    <Routes>
      <Route
        path="info"
        element={<AccountAdminRoute component={AccountInfoScreen} />}
      />

      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default AccountRouter;
