import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import axios from "axios";

import { useAuth } from "../hooks/useAuth";
import { AttachmentHookIntendedForOptions } from "../constants/kbConstants";
import { useDeleteKnowledgebaseAttachmentMutation } from "../rtkStore/services/knowledgebaseApi";

export const useManageAttachment = ({
  modelName,
  modelId,
  existingAttachments,
  intendedFor = AttachmentHookIntendedForOptions.CASE, // where this hooks is used, like Case, Knowledgebase, etc
}) => {
  const [attachments, setAttachments] = React.useState([]);
  const [attachmentToEditId, setAttachmentToEditId] = React.useState(null);
  const [attachmentDescription, setAttachmentDescription] = React.useState("");

  const maxAttachmentsNumber = parseInt(
    process.env.REACT_MAX_ATTACHMENTS_NUMBER ||
    // AppConstants.MAX_ATTACHMENTS_NUMBER
    7
  );
  const maxAttachmentSize = parseInt(
    process.env.REACT_MAX_ATTACHMENT_FILE_SIZE_MB ||
    // AppConstants.MAX_ATTACHMENT_FILE_SIZE_MB
    5
  );

  // hooks
  const { user } = useAuth();

  const config = {
    headers: {
      Authorization: [`Bearer ${user?.token}`],
    },
  };

  //   rtk queries

  const [deleteKnowledgebaseAttachment, { isLoading: isDeletingKBAttachment }] =
    useDeleteKnowledgebaseAttachmentMutation();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxAttachmentsNumber,
    maxSize: maxAttachmentSize * 1048576, // in MB
    // accept: {
    //   // "image/*": [],
    // },
    onDrop: (acceptedFiles) => {
      const oldAttachments = attachments ? [...attachments] : [];
      const newAcceptedFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          isImage: ["image/gif", "image/jpeg", "image/png"].includes(
            file["type"]
          ),
        });
      });

      setAttachments([...oldAttachments, ...newAcceptedFiles]);
    },
    onDropRejected: (error) => {
      const code = error[0]?.errors[0]?.code;
      if (!code) {
        toast.error("Invalid attachment(s) selected");
        return;
      }

      let errorMessage =
        "Couldn't select some attachment(s). Please make sure the selected attachments are valid";

      if (code === "too-many-files") {
        errorMessage = `Only a maximum of ${maxAttachmentsNumber} files are allowed`;
      } else if (code === "file-too-large") {
        errorMessage = `File size must not be greater than ${maxAttachmentSize}MB`;
      }

      toast.error(errorMessage);
    },
  });

  const handleDeleteAttachment = async (attachment, idx) => {
    if (isDeletingKBAttachment) return;

    if (!attachment?._id) {
      // if there's no _id, that means this attachment is local only, so just remove it from the state
      setAttachments((prevAttachments) =>
        prevAttachments.filter((a, _idx) => _idx !== idx)
      );
      return;
    }

    const loadingId = toast.loading("Deleting attachment...", {
      type: "success",
    });

    try {
      if (intendedFor === AttachmentHookIntendedForOptions.CASE) {
      } else if (
        intendedFor === AttachmentHookIntendedForOptions.KNOWLEDGEBASE
      ) {
        await deleteKnowledgebaseAttachment({
          [modelName]: modelId,
          publicId: attachment.cPublicId,
        }).unwrap();
      }

      toast.dismiss(loadingId);

      await axios.delete(`/api/file/delete/${attachment.cPublicId}`, config);

      toast.success(`${intendedFor} attachment deleted successfully`);
      setAttachments((prevAttachments) =>
        prevAttachments.filter((a, _idx) => _idx !== idx)
      );
    } catch (err) {
      // console.log(err);
      const message =
        err?.data?.message || `Couldn't delete ${intendedFor} attachment`;
      toast.error(message);
    }
  };

  const onSelectAttachmentForEdit = (file, idx) => {
    setAttachmentDescription(file?.description || "");
    setAttachmentToEditId(idx);
  };

  const onCancelUpdateAttachmentInformation = () => {
    setAttachmentToEditId(null);
  };

  const onUpdateAttachmentInformation = () => {
    let attachmentsCopy = [...attachments];
    attachmentsCopy = attachmentsCopy.map((a, idx) => {
      if (idx === attachmentToEditId) {
        if (a?._id !== undefined) {
          // meaning, this attachment is already in cloudinary

          a = {
            ...a,
            description: attachmentDescription,
          };
        } else {
          Object.assign(a, {
            description: attachmentDescription,
          });
        }
      }

      return a;
    });

    setAttachments(attachmentsCopy);
    setAttachmentToEditId(null);
    setAttachmentDescription(null);
  };

  // this is causing the infinite rerendering...
  // find some way to fix it
  React.useEffect(() => {
    if (!existingAttachments) return;

    setAttachments(existingAttachments || []);
  }, [existingAttachments]);

  return {
    attachmentToEditId,
    attachments,
    setAttachments,
    attachmentDescription,
    setAttachmentDescription,
    getRootProps,
    getInputProps,
    handleDeleteAttachment,
    onSelectAttachmentForEdit,
    onCancelUpdateAttachmentInformation,
    onUpdateAttachmentInformation,
  };
};
