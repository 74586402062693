import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileMoveIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";

export const AttachmentItem = ({
  file,
  handleDeleteTicketAttachment,
  onSelectAttachmentForEdit,
  idx,
  backgroundWhite = false,
  showManageButtons = true,
  showEditDescriptionButton = true,
  canDeleteFiles = true,
}) => {
  return (
    <Box
      key={file.name + "-" + idx}
      width="100%"
      display="flex"
      alignItems="center"
      border={"1px solid #d7d7d7"}
      borderRadius="5px"
      paddingX={2}
      paddingY={2}
      marginY={1}
      sx={{
        gap: 2,
        backgroundColor: backgroundWhite ? "white" : "#fafafa",
      }}
    >
      <Box
        minWidth="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        sx={{
          width: "80px",
          height: "90px",
          border: "1px solid #ededed",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => window.open(file.attachmentUrl, "_blank")}
      >
        {file.isImage ? (
          <img
            src={file.preview || file?.attachmentUrl}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Ticket Attachment"
          />
        ) : (
          <DriveFileMoveIcon style={{ width: 36, height: 36, color: "gray" }} />
        )}
      </Box>

      <Box height="100%" padding={1}>
        <Typography variant="subtitle1" fontSize={16} noWrap fontWeight={500}>
          {file.name || file?.fileName}
        </Typography>

        <Box className="line-clamp-3">
          {file.description && (
            <Tooltip title={file?.description || ""}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "gray",
                }}
              >
                {file.description}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Box>

      {showManageButtons && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginLeft="auto"
          sx={{
            gap: 1,
          }}
        >
          {showEditDescriptionButton && (
            <Tooltip title={"Update attachment description"}>
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();

                  onSelectAttachmentForEdit(file, idx);
                }}
              >
                <EditIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          )}

          {canDeleteFiles && (
            <Tooltip title={"Delete attachment"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTicketAttachment(file, idx);
                }}
                color="secondary"
              >
                <DeleteForeverIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};
