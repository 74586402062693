import React from 'react'
import { Container, Typography, Grid } from '@mui/material'
import worldMap from '../screens/assets/images/SEAMap.png'
import logo from '../assets/fsw-logo2.png'

const GlobalBranches = () => {
  return (
    <>
      <Container maxWidth='xl'>
        <Grid
          container
          // spacing={5}
          direction='row'
          justifyContent='center'
          alignItems='center'
          className='GlobalBranchContainer'
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img
                className='branchesLogoImage'
                src={logo}
                alt='logiImage'
              ></img>
            </Typography>

            <Typography
              gutterBottom
              variant='h5'
              component='div'
              mt={3}
              sx={{ fontSize: 22, fontWeight: 700 }}
            >
              Established in 2015, we now have strong presence across Southeast
              Asia{' '}
            </Typography>

            {/* <Typography mt={2}>
              <h1 className='StudyAbroadH1'>
                Since 2016 we have 30+ branches all over the globe{" "}
              </h1>
            </Typography> */}

            <Typography>
              <p className='GlobalBranchesDescription'>
                FSW Education has been helping individuals pursue their dreams
                of studying, working, settling, and living in Australia for the
                past seven years.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <img src={worldMap} alt='worldmap' className='WorldMapImage'></img>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default GlobalBranches
