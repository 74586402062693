import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import moment from "moment";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";

import { useAuth } from "../../../../hooks/useAuth";
import { sortedCountryList } from "../../../../utils/staticData/countryList.js";

import { useListAccountEntityUserQuery } from "../../../../rtkStore/services/userApi";
import { useListListQuery } from "../../../../rtkStore/services/listService";
import { useGetTeamListQuery } from "../../../../rtkStore/services/teamApi";
import { LIST_CONSTANTS } from "../../../../utils/staticData/listOptions";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  alternativeName: "",
  email: "",
  country: null,
  phone: "",
  phone2: "",
  address: "",
  dob: "",
  civilStatus: "",
  firstPreference: "",
  stage: "",
  nextAction: "",
  nextActionDate: "",
  responsible: "",
  workflows: [],
  description: "",
  agentPromotion: "",
};

const ClientForm = ({
  clientDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [responsibleSource, setResponsibleSource] = React.useState("User");

  const navigate = useNavigate();

  const { viewEntity } = useAuth();

  const responsibleSources = ["User", "Team"];

  const civilStatuses = [
    "Single",
    "Married",
    "De Facto",
    "Separated",
    "Widowed",
  ];

  // methods
  const getResponsibleSelectLabel = (responsible) => {
    if (!responsible) return;

    switch (clientDetail?.responsibleSource) {
      case "User":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      case "Team":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      default:
        return "";
    }
  };

  const handleResponsibleSourceChange = (e) => {
    setResponsibleSource(e.target.value);

    const currentValues = getValues();
    reset({ ...currentValues, responsible: null });
  };

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  //   RTK Query
  const { data: userList, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  const { data: teamList, isLoading: loadingTeams } = useGetTeamListQuery();

  const { data: clientStages, isLoading: loadingClientStages } =
    useListListQuery({
      type: LIST_CONSTANTS.CLIENT_STAGES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: nextActionTypes, isLoading: loadingNextActionTypes } =
    useListListQuery({
      type: LIST_CONSTANTS.NEXT_ACTION_TYPES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: leadServices, isLoading: loadingLeadServices } =
    useListListQuery({
      type: LIST_CONSTANTS.LEAD_SERVICES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: agentPromotions, isLoading: loadingAgentPromotions } =
    useListListQuery({
      type: LIST_CONSTANTS.AGENT_PROMOTIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedResponsibleUserList = React.useMemo(() => {
    switch (responsibleSource) {
      case "User":
        return (
          userList?.map((name) => ({
            label: name.name,
            value: name._id,
          })) ?? []
        );

      case "Team":
        return teamList?.map((team) => ({
          label: team?.name,
          value: team?._id,
        }));

      default:
        return [];
    }
  }, [responsibleSource, teamList, userList]);

  const mappedLeadServicesList = leadServices?.map((service) => ({
    label: service?.name,
    value: service?._id,
  }));

  const mappedAgentPromotionList = agentPromotions?.map((promotion) => ({
    label: promotion?.name,
    id: promotion?._id,
  }));

  React.useEffect(() => {
    if (!clientDetail) return;

    setResponsibleSource(clientDetail?.responsibleSource ?? "User");

    const selectableResponsible = getResponsibleSelectLabel(
      clientDetail?.responsible
    );

    reset({
      ...clientDetail,
      responsible: selectableResponsible,
      dob: clientDetail?.dob
        ? moment(clientDetail?.dob)?.format("YYYY-MM-DD")
        : null,
      workflows: clientDetail?.workflows?.map((workflow) => workflow._id) ?? [],
      stage: clientDetail?.stage?._id ?? "",
      nextAction: clientDetail?.nextAction?._id ?? null,
      nextActionDate: clientDetail?.nextActionDate ?? null,
      firstPreference: clientDetail?.firstPreference
        ? {
            label: clientDetail?.firstPreference?.name,
            value: clientDetail?.firstPreference?._id,
          }
        : "",
      agentPromotion: clientDetail?.agentPromotion
        ? {
            label: clientDetail?.agentPromotion?.name,
            id: clientDetail?.agentPromotion?._id,
          }
        : "",
      isActive: clientDetail?.isActive ?? true,
    });

    return () => {
      reset({ ...INITIAL_STATE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetail, reset]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingUsers ||
        loadingTeams ||
        loadingClientStages ||
        loadingNextActionTypes ||
        loadingLeadServices ||
        loadingAgentPromotions
      }
    >
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "First Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="First Name, Middle Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.firstName && (
              <p className="error">{errors.firstName.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Last Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Last Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.lastName && (
              <p className="error">{errors.lastName.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="alternativeName"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Alternative Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Email Address"
                  type="email"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="country"
              control={control}
              rules={{ required: "Please select a country" }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={sortedCountryList ?? []}
                  getOptionLabel={(option) => `${option.cca2} - ${option.name}`}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nationality"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select country"
                    />
                  )}
                  value={field?.value}
                  onChange={(e, data) => setValue("country", data)}
                />
              )}
            />
            {errors.country && (
              <p className="error">{errors.country.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="phone"
              control={control}
              rules={{ required: "Phone number is required." }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Phone Number"
                  type="tel"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="phone2"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Alternative Phone Number "
                  type="tel"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="address"
              control={control}
              rules={{ required: "Address is required." }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Address"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.address && (
              <p className="error">{errors.address.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Date of Birth"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="civilStatus"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-civil-status">Civil Status</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-civil-status"
                    label="Civil Status"
                    placeholder="Select Civil Status"
                    id="role"
                    {...field}
                  >
                    {civilStatuses?.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="firstPreference"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingLeadServices}
                  loadingText="Loading Services..."
                  options={mappedLeadServicesList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("firstPreference", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="First Preference Service"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-assignee-type">
                Responsible Type
              </InputLabel>
              <Select
                fullWidth
                labelId="select-assignee-type"
                label="Responsible Type"
                placeholder="Select Assignee Type"
                id="role"
                value={responsibleSource}
                onChange={handleResponsibleSourceChange}
              >
                {responsibleSources?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6}>
            <Controller
              name="responsible"
              control={control}
              rules={{ required: "Responsible user is required" }}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="responsible"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingTeams || loadingUsers}
                  loadingText="Loading Responsible Users..."
                  options={mappedResponsibleUserList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("responsible", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsible"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.responsible && (
              <p className="error">{errors.responsible.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="stage"
              control={control}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-stage">Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-stage"
                    label="Course Stage"
                    placeholder="Select Stage"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {clientStages?.map((stage) => (
                      <MenuItem key={stage._id} value={stage._id}>
                        {stage?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="nextAction"
              control={control}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-next-action">Next Action</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-next-action"
                    label="Next Action"
                    placeholder="Select Preference"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {nextActionTypes?.map((action) => (
                      <MenuItem key={action._id} value={action._id}>
                        {action?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="nextActionDate"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Next Action Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("nextActionDate", e.target.value)}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="agentPromotion"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="agentPromotion"
                  autoHighlight
                  loading={loadingAgentPromotions}
                  loadingText="Loading Agent Promotions..."
                  options={mappedAgentPromotionList ?? []}
                  onChange={(e, data) => setValue("agentPromotion", data)}
                  value={field?.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agent Promotion"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option.id}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      value={field?.value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              rules={{ required: "Description is required" }}
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  size="small"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className="error">{errors.description.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              dob: data?.dob ?? null,
              responsible: data?.responsible?.value,
              responsibleSource,
              stage: data?.stage ? data?.stage : null,
              nextAction: data?.nextAction?.length ? data.nextAction : null,
              firstPreference: data?.firstPreference
                ? data.firstPreference?.value
                : null,
              civilStatus: data?.civilStatus?.length ? data?.civilStatus : null,
              agentPromotion: data?.agentPromotion?.id ?? null,
            })
          )}
        >
          {clientDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default ClientForm;
