import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  useLocation,
  useParams,
  useSearchParams,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import FlagIcon from '@mui/icons-material/Flag';

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useKnowledgebaseContext } from "../../../../context/KBContext";
import { useAppContext } from "../../../../context/AppContext";
import { DeleteDialog } from "../../../../components/Shared/DeleteDialog";
import { useBasicEditKnowledgebaseMutation, useFlagKnowledgebaseArticleMutation } from "../../../../rtkStore/services/knowledgebaseApi";
import { CONTEXT_CONSTANTS } from "../../../../constants/contextConstants";

const knowledgebaseDetailLinks = [
  {
    name: "Document Detail",
    path: "",
  },
  {
    name: "Document Versions",
    path: "document-version",
  },
  {
    name: "Attachments",
    path: "attachments",
  },
  {
    name: "Attachments Versions",
    path: "attachment-version",
  },
  {
    name: "Comments",
    path: "comments",
  },
  // {
  //   name: "Tasks",
  //   path: "tasks",
  // },
];

export const KBDetailHeader = () => {
  // states
  const [activeLink, setActiveLink] = React.useState("");
  const [isFlagArticleModalActive, setIsFlagArticleModalActive] =
    React.useState(false);
  const [isResetFlagStatusModalActive, setIsResetFlagStatusModalActive] =
    React.useState(false);

  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { state: articleState } = useKnowledgebaseContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const selectedArticle = articleState?.selectedArticle;
  const selectedArticleId = params.articleId;

  // rtk queries
  const [flagArticle, { isLoading: isFlaggingArticle }] =
    useFlagKnowledgebaseArticleMutation();
  const [basicEditKnowledgebase, { isLoading: isEditingArticle }] =
    useBasicEditKnowledgebaseMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const handleBasicEditArticle = async (data) => {
    if (isEditingArticle) return;

    try {
      const payload = {
        id: selectedArticle._id,
        ...selectedArticle,
        ...data,
      };

      await basicEditKnowledgebase(payload).unwrap();
      setIsResetFlagStatusModalActive(false);
    } catch (err) {
      const errorMessage = err?.data?.message || "Couldn't edit knowledgebase";
      toast.error(errorMessage);
    }
  };

  const getLinkPathWithFilter = (path) => {
    const tempPath = path.endsWith("/") ? path.slice(0, path.length - 1) : path;
    const phase = searchParams.get("phase");
    if (phase) {
      return `${tempPath}?phase=${phase}}`;
    } else {
      return tempPath;
    }
  };

  const closeFlagModal = () => {
    setIsFlagArticleModalActive(false);
    reset({});
  };

  const onHandleFlagArticle = async (data) => {
    if (isFlaggingArticle) return;

    if (!data?.comment?.trim()) {
      toast.error(
        "Please provide a valid reason to flag this article for review"
      );
      return;
    }

    const payload = {
      id: selectedArticle?._id,
      comment: data?.comment,
    };

    try {
      await flagArticle(payload).unwrap();
      setIsFlagArticleModalActive(false);
      reset();
      toast.success("Article successfully flagged for review");
    } catch (err) {
      const errorMessage =
        err?.data?.message || "Couldn't flag this article for review";
      toast.error(errorMessage);
    }
  };

  React.useEffect(() => {
    const pathname = location.pathname;

    const activeTabName = pathname?.split("/").slice(-1)[0];

    if (!activeTabName) {
      setActiveLink("");
    } else if (knowledgebaseDetailLinks.find((l) => l.path === activeTabName)) {
      setActiveLink(activeTabName);
    }
  }, [location.pathname]);

  if (!selectedArticle)
    return (
      <Box
        sx={{
          width: "100%",
        }}
      ></Box>
    );

  return (
    <Box
      position="sticky"
      top={"0"}
      left={0}
      zIndex={10}
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          width: "100%",
          backgroundColor: "white",
          zIndex: 1,
          padding: "8px 1rem",
          gap: "2px",
          borderBottom: "1px solid #f1f1f1",
          borderLeft: "1px solid #f1f1f1",
          height: "70px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          sx={{
            gap: 4,
            padding: "6px 12px",
            borderBottom: "1px solid #f1f1f1",
          }}
        >
          {!appState.isArticleDetailMetaPaneExpanded && (
            <IconButton
              style={{ marginRight: "1rem" }}
              onClick={() =>
                appDispatch({
                  type: CONTEXT_CONSTANTS.TOGGLE_ARTICLE_DETAIL_META_PANE,
                  payload: {
                    isArticleDetailMetaPaneExpanded: true,
                  },
                })
              }
            >
              <ChevronRightIcon />
            </IconButton>
          )}
          {knowledgebaseDetailLinks.map((l) => (
            <Link
              style={{
                display: "inline-block",
                padding: "8px 18px",
                borderRadius: "5px",
                color: activeLink === l.path ? "#1976D2" : "#333",
                backgroundColor: activeLink === l.path ? "white" : "",
                border:
                  activeLink === l.path ? "1px solid rgb(229,223,223)" : "",
              }}
              className="case-sidebar-link"
              key={`detail-link-${l.name}`}
              component={RouterLink}
              to={getLinkPathWithFilter(
                `/app/knowledgebase/detail/${selectedArticleId}/${l.path}`
              )}
              replace={true}
              onClick={() => setActiveLink(l.path)}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: activeLink === l.path ? 600 : 500,
                  fontSize: 14,
                  minWidth: "max-content"
                }}
              >
                {l.name}
              </Typography>
            </Link>
          ))}

          <Box
            display="flex"
            alignItems="center"
            sx={{ gap: "8px", marginLeft: "auto" }}
          >
            <Tooltip title="Edit Document">
              <IconButton
                onClick={() => navigate(`/app/knowledgebase/edit/${selectedArticle?._id}`)}
              >
                <EditIcon
                  style={{ color: "#3a7eed", fontSize: 20 }}
                />
              </IconButton>
            </Tooltip>

            {selectedArticle?.isFlagged && (
              <Tooltip title="Restore document to non-flagged state">
                <IconButton
                  onClick={() => setIsResetFlagStatusModalActive(true)}
                >
                  <RefreshIcon
                    style={{ color: "#435883", fontSize: 20 }}
                  ></RefreshIcon>
                </IconButton>
              </Tooltip>
            )}

            {
              !selectedArticle?.isFlagged &&
              <Tooltip title="Flag document for review">
                <IconButton onClick={() => setIsFlagArticleModalActive(true)}>
                  <FlagIcon
                    style={{ color: "#e33333", fontSize: 20 }}
                  ></FlagIcon>
                </IconButton>
              </Tooltip>
            }
          </Box>
        </Box>
      </Box>

      {/* flag article form */}
      {Boolean(isFlagArticleModalActive) && (
        <DeleteDialog
          open={Boolean(isFlagArticleModalActive)}
          primaryBtnText="Flag for review"
          title="Flag document for review"
          subtitle=" Are you sure you want to flag this document for review?"
          onDelete={handleSubmit((d) => {
            onHandleFlagArticle(d);
          })}
          isLoading={isFlaggingArticle}
          loadingText="Flagging document..."
          onCancel={() => closeFlagModal()}
        >
          <div style={{ maxWidth: "95%", marginTop: "1rem" }}>
            <div className="mb-2">
              <label htmlFor="comment" className="form-label">
                Reason to flag this document for review
              </label>
            </div>
            <div className="">
              <textarea
                rows={2}
                required
                name={`comment`}
                className="form-control form-font py-2"
                {...register(`comment`, {
                  required:
                    "Please provide a reason to flag this document for review",
                })}
              />
              {errors.comment && (
                <p className="error">{errors.comment.message}</p>
              )}
            </div>
          </div>
        </DeleteDialog>
      )}

      {/* clear flag status form */}
      {Boolean(isResetFlagStatusModalActive) && (
        <DeleteDialog
          open={Boolean(isResetFlagStatusModalActive)}
          isDeleteDialog={false}
          primaryBtnText="Clear Article"
          title="Clear article flag state"
          subtitle=" Are you sure you want to clear the flag status of this article? This will not remove the flag comments."
          onDelete={() => {
            handleBasicEditArticle({
              isFlagged: false,
              flaggedReason: null,
            });
          }}
          isLoading={isEditingArticle}
          loadingText="Clearing article..."
          onCancel={() => {
            setIsResetFlagStatusModalActive(false);
          }}
        ></DeleteDialog>
      )}
    </Box>
  );
};
