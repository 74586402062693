import React from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";

import {
  useLazyListGlobalListQuery,
  useListGlobalListQuery,
} from "../../../../rtkStore/services/globalListApi";
import { useLazyGetPublicLocationUniversitiesQuery } from "../../../../rtkStore/services/publicApi";
import { useGetUrlParams } from "../../../../hooks/useGetUrlParams";

const INITIAL_STATE = {
  keyword: "",
  location: "",
  subLocations: [],
  university: "",
};

const CatalogueTopFilters = ({ isBusy = false, onFilterChange = () => {} }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const _catalogueSearchParams = useGetUrlParams();

  //  RTK Query
  const { data: locationOptions, isLoading: loadingLocations } =
    useListGlobalListQuery({
      type: "Locations",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const [
    lazyFetchSubLocationOptions,
    { data: subLocationOptions, isFetching: fetchingSubLocations },
  ] = useLazyListGlobalListQuery();

  const [
    lazyFetchLocationUniversities,
    { data: universities, isFetching: fetchingUniversities },
  ] = useLazyGetPublicLocationUniversitiesQuery();

  const _mappedLocations = React.useMemo(() => {
    return (
      locationOptions?.map((loc) => ({
        label: loc?.name,
        value: loc?._id,
      })) ?? []
    );
  }, [locationOptions]);

  const _mappedSubLocationOptions = React.useMemo(() => {
    return (
      subLocationOptions?.map((sl) => ({
        label: sl?.name,
        value: sl?._id,
      })) ?? []
    );
  }, [subLocationOptions]);

  const _mappedUniversities = React.useMemo(() => {
    return (
      universities?.map((uni) => ({ label: uni?.title, value: uni?._id })) ?? []
    );
  }, [universities]);

  // react form hook
  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedLocation = watch("location");

  // React.useEffect(() => {
  //   if (!searchParams) return;

  //   const _currentLocation = _mappedLocations?.find(
  //     (el) => el?.value === _catalogueSearchParams?.location
  //   );

  //   reset({
  //     ..._catalogueSearchParams,
  //     keyword: _catalogueSearchParams?.keyword ?? "",
  //     location: _currentLocation ?? "",
  //   });
  // }, [searchParams, _mappedLocations]);

  React.useEffect(() => {
    if (!selectedLocation) return;

    const handleFetchSubLocations = async (location) => {
      await lazyFetchSubLocationOptions({
        type: location?.name,
        includeChildren: true,
        status: "Active",
      }).unwrap();
    };

    const handleFetchUniversities = async (location) => {
      await lazyFetchLocationUniversities(location?._id).unwrap();
    };

    const fetchLocationBasedData = (location) => {
      handleFetchSubLocations(location);
      handleFetchUniversities(location);
    };

    const location = locationOptions?.find(
      (el) => el?._id === selectedLocation?.value
    );

    if (location) fetchLocationBasedData(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, subLocationOptions]);

  // React.useEffect(() => {
  //   const subscription = watch(
  //     handleSubmit((data) =>
  //       onFilterChange({
  //         ...data,
  //         location: data?.location?.value ?? "",
  //         subLocations: data?.subLocations?.map((el) => el?.value ?? []),
  //         university: data?.university?.value ?? "",
  //         page: 1,
  //         size: 10,
  //       })
  //     )
  //   );
  //   return () => subscription.unsubscribe();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [handleSubmit, watch]);

  React.useEffect(() => {
    if (Object.entries(_catalogueSearchParams) <= 0) {
      reset({ ...INITIAL_STATE });
    }
  }, [searchParams]);

  React.useEffect(() => {
    const subscription = watch(
      handleSubmit((data) => {
        const topSearchFilter = {
          ...data,
          location: data?.location?.value ?? "",
          subLocations: data?.subLocations
            ?.map((el) => el?.value ?? [])
            .join(","),
          university: data?.university?.value ?? "",
          page: 1,
          size: 10,
        };

        setSearchParams({ ..._catalogueSearchParams, ...topSearchFilter });
      })
    );

    return () => subscription.unsubscribe();
  }, [watch, searchParams]);

  return (
    <div className="flex space-x-8">
      <div className="w-1/4 flex-shrink-0 ">
        <Controller
          name="keyword"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              size="small"
              placeholder="Search by keyword"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size="small" />
                  </InputAdornment>
                ),
              }}
              disabled={isBusy}
              {...field}
            />
          )}
        />
      </div>

      <div className="flex w-3/4 space-x-6">
        <div className="w-1/3">
          <Controller
            name="location"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                isClearable
                isLoading={loadingLocations}
                options={_mappedLocations}
                placeholder="Location"
                isDisabled={isBusy}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue("subLocations", []);
                  setValue("university", "");
                }}
              />
            )}
          />
        </div>

        <div className="w-1/3">
          <Controller
            name="subLocations"
            control={control}
            render={({ field }) => (
              <Select
                menuPortalTarget={document.body}
                isClearable
                isMulti
                isLoading={fetchingSubLocations}
                options={_mappedSubLocationOptions}
                placeholder="Sub-locations"
                isDisabled={isBusy}
                {...field}
              />
            )}
          />
        </div>

        <div className="w-1/3">
          <Controller
            name="university"
            control={control}
            render={({ field }) => (
              <Select
                menuPortalTarget={document.body}
                isClearable
                isLoading={fetchingUniversities}
                options={_mappedUniversities}
                placeholder="University"
                isDisabled={isBusy}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CatalogueTopFilters;
