import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CourseForm from "./courseComponents/CourseForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetCourseDetailQuery,
  useUpdateCourseMutation,
} from "../../../rtkStore/services/courseApi";

const CourseEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Courses",
      path: "/app/course/list",
    },
    { title: "Edit Course" },
  ];

  // RTK Query
  const {
    data: courseDetail,
    isLoading: loadingCourseDetail,
    error,
  } = useGetCourseDetailQuery(id);

  const [updateCourse, { isLoading: updatingCourse }] =
    useUpdateCourseMutation();

  // methods
  const handleCourseUpdate = (data) => {
    updateCourse(data)
      .unwrap()
      .then(() => {
        navigate(`/app/course/${id}/detail`);
        toast.success("Course successfully updated!");
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingCourseDetail || updatingCourse}
      error={error}
    >
      <CourseForm
        courseDetail={courseDetail}
        isBusy={loadingCourseDetail || updatingCourse}
        onSubmit={handleCourseUpdate}
      />
    </WithHeader>
  );
};

export default CourseEditScreen;
