import React from "react";

import { Link } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";

import AppTable from "../../../components/Shared/AppTable";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoTone from "@mui/icons-material/ModeEditOutlineTwoTone";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useGetTeamListQuery } from "../../../rtkStore/services/teamApi";

const TeamListScreen = () => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const breadcrumbs = [{ title: "Teams" }];

  const columns = [
    { name: "Team Name", selector: (row) => row?.name ?? "N/A" },
    { name: "Manager", selector: (row) => row?.manager?.name ?? "N/A" },
    {
      name: "Members",
      cell: (row) => (
        <div className="flex">
          {row?.members?.slice(0, 2)?.map((member, index) => (
            <div key={member?._id}>
              {member?.name}
              {index < 1 && <span className="mr-1">,</span>}
              {row?.members?.length > 2 &&
                `& ${row?.members?.length - 2} others`}
            </div>
          ))}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/team/${row._id}/detail`}>
            <IconButton color="primary" aria-label="Edit Task">
              <InfoOutlined />
            </IconButton>
          </Link>
          <Link to={`/app/team/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Task">
              <ModeEditOutlineTwoTone />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  // RTK Query
  const {
    data: teamList,
    isLoading: loadingTeams,
    error,
  } = useGetTeamListQuery();

  const PageActions = () => {
    return (
      <Link to="/app/team/create">
        <Button startIcon={<Add />} variant="contained">
          New Team
        </Button>
      </Link>
    );
  };

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter by Name"
          value={filterText ? filterText : ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingTeams}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <AppTable
        columns={columns}
        data={
          teamList?.filter(
            (item) =>
              item && item.name.toLowerCase().includes(filterText.toLowerCase())
          ) ?? []
        }
        progressPending={loadingTeams}
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
    </WithHeader>
  );
};

export default TeamListScreen;
