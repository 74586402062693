import { baseApi } from "./baseApi";

const UNIVERSITY_BASE_URL = "/universities";

export const universityApi = baseApi.injectEndpoints({
  tagTypes: ["University"],

  endpoints: (builder) => ({
    getUniversityList: builder.query({
      query: (params) => ({
        url: `${UNIVERSITY_BASE_URL}/university-list`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "University", _id })),
              { type: "University", id: "LIST" },
            ]
          : [{ type: "University", id: "LIST" }],
    }),

    getUniversityDetail: builder.query({
      query: (id) => `${UNIVERSITY_BASE_URL}/university-edit/${id}`,
      providesTags: (result, error, id) => [
        { type: "University", id },
        { type: "University", id: "LIST" },
      ],
    }),

    createUniversity: builder.mutation({
      query: (payload) => ({
        url: `${UNIVERSITY_BASE_URL}/university-create`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", id: "LIST" },
      ],
    }),

    updateUniversity: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${UNIVERSITY_BASE_URL}/university-edit/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", _id },
        { type: "University", id: "LIST" },
      ],
    }),

    addUniversityCampusArea: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${UNIVERSITY_BASE_URL}/${_id}/campus-areas`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", _id },
        { type: "University", id: "LIST" },
      ],
    }),

    updateUniversityCampusArea: builder.mutation({
      query: (payload) => {
        const { universityId, _id, ...body } = payload;

        return {
          url: `${UNIVERSITY_BASE_URL}/${universityId}/campus-areas/${_id}`,
          method: "PUT",
          body: { _id, ...body },
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", _id },
        { type: "University", id: "LIST" },
      ],
    }),

    // university reviews
    addUniversityReview: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${UNIVERSITY_BASE_URL}/${courseId}/reviews`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", _id },
        { type: "University", id: "LIST" },
      ],
    }),

    updateUniversityReview: builder.mutation({
      query: (payload) => {
        const { courseId, _id, ...body } = payload;

        return {
          url: `${UNIVERSITY_BASE_URL}/${courseId}/reviews/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "University", _id },
        { type: "University", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetUniversityListQuery,
  useLazyGetUniversityListQuery,
  useGetUniversityDetailQuery,
  useCreateUniversityMutation,
  useUpdateUniversityMutation,
  useAddUniversityCampusAreaMutation,
  useUpdateUniversityCampusAreaMutation,

  useAddUniversityReviewMutation,
  useUpdateUniversityReviewMutation,
} = universityApi;
