import React from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import { Link, useParams } from "react-router-dom"
import australiaBanner from "../assets/images/banners/australiaBanner.png"
import Banner from "../../components/Banner"
import ConsultExpert from "../../components/ConsultExpert"
import { PopularCountries } from "../../utils/staticData/destinationList"
import ReactHtmlParser from "html-react-parser"

const CountryScreen = () => {
  const { country } = useParams()

  // Function to filter cities by name
  function filterCountry(countryName) {
    return PopularCountries.filter(
      (country) => country.country.toLowerCase() === countryName.toLowerCase()
    )
  }

  const countryNameToFind = country
  const filteredCountries = filterCountry(countryNameToFind)

  return (
    <>
      {filteredCountries && filteredCountries.length > 0 ? (
        <>
          <Banner
            image={filteredCountries[0].image}
            title={`Study in ${country}`}
          />
          <Container maxWidth='xl'>
            <Grid
              container
              spacing={5}
              direction='row'
              justifyContent='center'
              alignItems='center'
              className='GlobalBranchContainer'
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                data-aos='fade-right'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  mt={3}
                  sx={{ fontSize: 22, fontWeight: 700 }}
                >
                  Introduction to {country}
                </Typography>

                <Typography>
                  {/* {filteredCountries[0].content} */}
                  {filteredCountries[0].content &&
                    ReactHtmlParser(filteredCountries[0].content)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                data-aos='fade-left'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                <img
                  src={filteredCountries[0].countryMap}
                  alt={filteredCountries[0].country}
                  className='WorldMapImage'
                ></img>
              </Grid>
            </Grid>
          </Container>

          {/* cities image section */}

          <Box className='step4Background' sx={{ marginTop: "4rem" }}>
            <Container maxWidth='xl' align='center' sx={{ padding: "3rem" }}>
              <Typography
                gutterBottom
                variant='h5'
                component='div'
                mb={3}
                sx={{ fontSize: 22, fontWeight: 700 }}
              >
                Here are the popular destinations in {country}
              </Typography>
              <Grid
                container
                spacing={5}
                direction='row'
                justifyContent='center'
                alignItems='center'
                data-aos='fade-right'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                {filteredCountries[0]?.cities?.map((city, index) => (
                  <Grid key={index} item xs={12} sm={12} md={4}>
                    <Card>
                      <Link
                        to={`/study-abroad/popular-destinations/${
                          filteredCountries[0].country
                        }/${city?.name2 ? city?.name2 : city?.name}`}
                        style={{ textDecoration: "none" }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component='img'
                            height='80%'
                            image={city?.image}
                            alt='green iguana'
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant='h5'
                              component='div'
                              textAlign='center'
                            >
                              {city?.name}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Link>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <ConsultExpert />
        </>
      ) : (
        <>
          <Banner image={australiaBanner} title={"Not Found"} />
        </>
      )}
    </>
  )
}

export default CountryScreen
