import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import moment from "moment";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";

import { useGetClientListQuery } from "../../../../rtkStore/services/clientApi";
import { useGetCourseListQuery } from "../../../../rtkStore/services/courseApi";
import { useLazyGetTransactionsByClientQuery } from "../../../../rtkStore/services/transactionApi";
import { useListListQuery } from "../../../../rtkStore/services/listService";

const ApplicationForm = ({
  applicationDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [clientTransactionList, setClientTransactionList] = React.useState([]);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // required enum list
  const applicationTypes = [
    "College-List",
    "College-Manual-Enter",
    "Insurance",
    "Accommodation",
    "Other",
  ];
  const priorityEnums = ["Low", "Normal", "High"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      transaction: applicationDetail
        ? {
            label: applicationDetail?.transaction?.title,
            value: applicationDetail?.transaction?._id,
          }
        : "",
      title: applicationDetail?.title ?? "",
      stage: applicationDetail?.stage?._id ?? "",
      type: applicationDetail?.type ?? applicationTypes[0],
      course: applicationDetail?.course ?? "",
      providerName: applicationDetail?.providerName ?? "",
      priority: applicationDetail?.priority ?? "Normal",
      dueDate: applicationDetail?.dueDate ?? "",
    },
  });

  const selectedApplicationType = React.useRef({});
  selectedApplicationType.current = watch("type");

  //   RTK Query
  const { data: clientList, isLoading: loadingClients } =
    useGetClientListQuery();

  const { data: courseList, isLoading: loadingCourses } =
    useGetCourseListQuery();

  const [getClientTransactions, { isLoading: loadingClientTransactions }] =
    useLazyGetTransactionsByClientQuery();

  const { data: applicationStages, isLoading: loadingApplicationStages } =
    useListListQuery({
      type: "Application Status",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedCourseList =
    courseList?.map((course) => ({
      label: `${course?.title} - ${course?.university?.title}`,
      value: course?._id,
    })) ?? [];

  const mappedClientTransactions = React.useMemo(() => {
    return clientTransactionList?.map((transaction) => ({
      label: transaction.title,
      value: transaction._id,
    }));
  }, [clientTransactionList]);

  React.useEffect(() => {
    if (!applicationDetail) return;

    setSelectedClient(applicationDetail?.client);

    reset({
      ...applicationDetail,
      transaction: applicationDetail?.transaction
        ? {
            label: applicationDetail?.transaction?.title,
            value: applicationDetail?.transaction?._id,
          }
        : "",
      course: applicationDetail?.course
        ? {
            label: `${applicationDetail?.course?.title} - ${applicationDetail?.course?.university?.title}`,
            value: applicationDetail?.course._id,
          }
        : "",
      dueDate: moment(applicationDetail?.dueDate).format("YYYY-MM-DD") ?? "",
      stage: applicationDetail?.stage?._id ?? "",
    });
  }, [applicationDetail, reset]);

  React.useEffect(() => {
    if (!searchParams) return;

    if (searchParams.get("selectedClient")) {
      const selectedClient = searchParams.get("selectedClient");
      const selectedTransaction = searchParams.get("selectedTransaction");

      const clientDetail = clientList?.find((c) => c._id === selectedClient);
      if (!clientDetail) return;
      setSelectedClient(clientDetail);

      const transactionDetail = clientTransactionList.find(
        (t) => t._id === selectedTransaction
      );
      if (!transactionDetail) return;
      reset({
        transaction: {
          label: transactionDetail?.title,
          value: transactionDetail?._id,
        },
      });
    }
  }, [clientList, clientTransactionList, reset, searchParams]);

  React.useEffect(() => {
    const handleGetClientTransactions = async (clientId) => {
      try {
        const transactions = await getClientTransactions(clientId).unwrap();
        setClientTransactionList(transactions);
      } catch (err) {
        toast.error(
          err?.data?.message ?? "Couldn't fetch client's transactions!"
        );
      }
    };

    if (!selectedClient) return;

    handleGetClientTransactions(selectedClient?._id);
  }, [selectedClient, getClientTransactions]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingCourses ||
        loadingClientTransactions ||
        loadingApplicationStages
      }
    >
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Autocomplete
              autoHighlight
              loading={loadingClients}
              loadingText="Loading Client..."
              options={clientList ?? []}
              value={selectedClient}
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.firstName}`
              }
              onChange={(e, data) => setSelectedClient(data)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="transaction"
              control={control}
              rules={{
                required: "Please select a client then it's transaction",
              }}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="transaction"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingClientTransactions}
                  loadingText="Loading Transactions..."
                  options={mappedClientTransactions ?? []}
                  value={field?.value ?? ""}
                  onChange={(e, data) => setValue("transaction", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Transaction"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.transaction && (
              <p className="error">{errors.transaction.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="stage"
              control={control}
              rules={{ required: "Application Status is required" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-stage">Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-stage"
                    label="Stage"
                    placeholder="Select Stage"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {applicationStages?.map((stage) => (
                      <MenuItem key={stage._id} value={stage._id}>
                        {stage?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.status && <p className="error">{errors.status.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Please select an application type" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-type">Application Type</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-type"
                    label="Application Type"
                    placeholder="Select Application Type"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {applicationTypes?.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.type && <p className="error">{errors.type.message}</p>}
          </Grid>

          {selectedApplicationType.current === "College-List" ? (
            <Grid item md={6}>
              <Controller
                name="course"
                control={control}
                rules={{ required: "Please select a Course" }}
                render={({ field, onBlur }) => (
                  <Autocomplete
                    id="course"
                    autoHighlight
                    onBlur={onBlur}
                    loading={loadingCourses}
                    loadingText="Loading Courses..."
                    options={mappedCourseList ?? []}
                    value={field?.value}
                    onChange={(e, data) => setValue("course", data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Course"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        option && (
                          <MenuItem {...props} key={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      );
                    }}
                  />
                )}
              />
              {errors.course && (
                <p className="error">{errors.course.message}</p>
              )}
            </Grid>
          ) : (
            <Grid item md={6}>
              <Controller
                name="providerName"
                control={control}
                rules={{
                  required:
                    selectedApplicationType !== "College-List" &&
                    `${selectedApplicationType.current} is required.`,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Provider Name"
                    type="text"
                    size="small"
                    {...field}
                  />
                )}
              />
              {errors.providerName && (
                <p className="error">{errors.providerName.message}</p>
              )}
            </Grid>
          )}

          <Grid item md={6}>
            <Controller
              name="priority"
              control={control}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-priority">Priority</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-priority"
                    label="Priority"
                    placeholder="Select Priority"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {priorityEnums?.map((priority) => (
                      <MenuItem key={priority} value={priority}>
                        {priority}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="dueDate"
              control={control}
              rules={{ required: "Due date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Due Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  {...field}
                />
              )}
            />
            {errors.dueDate && (
              <p className="error">{errors.dueDate.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      value={field?.value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              client: selectedClient?._id ?? null,
              transaction: data?.transaction?.value ?? null,
              course:
                selectedApplicationType.current !== "College-List"
                  ? null
                  : data?.course?.value ?? null,
              providerName:
                selectedApplicationType.current !== "College-List"
                  ? data?.providerName
                  : "",
            })
          )}
        >
          {applicationDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default ApplicationForm;
