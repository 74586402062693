import React from "react";
import { useSearchParams } from "react-router-dom";

import ClientNextActions from "./clientComponents/tracker/ClientNextActions";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import CustomTabs from "../../../components/Shared/CustomTabs";
import NoClientUpdates from "./clientComponents/tracker/NoClientUpdates";
import WithHeader from "../../../layouts/WithHeader";

const ClientTrackerScreen = () => {
  const [activeTab, setActiveTab] = React.useState("next-actions");

  const [searchParams] = useSearchParams();

  const _durationInDays = 3;

  const clientTrackerTabs = [
    { label: "Next Actions", value: "next-actions" },
    { label: `No Updates (Last ${_durationInDays} days)`, value: "no-updates" },
  ];

  const breadcrumbs = [
    { title: "Clients", path: "/app/client/list" },
    { title: "Clients Tracker" },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "next-actions");
  }, [searchParams]);

  return (
    <WithHeader breadcrumbs={breadcrumbs}>
      <CustomTabs tabList={clientTrackerTabs} activeTab={activeTab} />

      <CustomTabPanel activeTab={activeTab} value="next-actions">
        <ClientNextActions />
      </CustomTabPanel>

      <CustomTabPanel activeTab={activeTab} value="no-updates">
        <NoClientUpdates durationInDays={_durationInDays} />
      </CustomTabPanel>
    </WithHeader>
  );
};

export default ClientTrackerScreen;
