import { Box } from "@mui/material";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useKnowledgebaseContext } from "../../../context/KBContext";
import { useInfiniteRTKQuery } from "../../../hooks/useInfiniteRTKQuery";
import { useAppContext } from "../../../context/AppContext";
import { knowledgebaseApi, useLazyDetailKnowledgebaseQuery } from "../../../rtkStore/services/knowledgebaseApi";
import { CONTEXT_CONSTANTS } from "../../../constants/contextConstants";
import { KnowledgebaseDetailWrapper } from "./KnowledgebaseDetail/KnowledgebaseDetailWrapper";
import { KnowledgebaseDetailSidebar } from "./KnowledgebaseDetail/KnowledgebaseDetailSidebar";
import { KBDetailMeta } from "./KnowledgebaseDetail/KBDetailMeta";
import { KBDetailMetaSkeleton } from "./KBComponents/KBDetailMetaSkeleton";
import { ArticleDetailedInfoSkeleton } from "./KBComponents/ArticleDetailedInfoSkeleton";

export const KBDetailHomeScreen = () => {
  // hooks
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state: articleState, dispatch: articleDispatch } =
    useKnowledgebaseContext();
  const { state: appState } = useAppContext();

  const [filters, setFilters] = React.useState(() => ({
    activeKnowledgebaseId: params.articleId,
    filters: {
      ...(searchParams.get("tag") && {
        tag: searchParams.get("tag"),
      }),
      ...(searchParams.get("filter") && {
        filter: searchParams.get("filter"),
      }),
    }
  }));

  //   rtk queries
  const [
    fetchArticleDetails,
    { isFetching: isLoadingArticleDetails, data: articleData },
  ] = useLazyDetailKnowledgebaseQuery();

  const {
    data: articles,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFullReloading,
    removeItemFromLocalArray,
  } = useInfiniteRTKQuery(
    knowledgebaseApi.endpoints.listKnowledgebase,
    {
      perPage: 10,
      filters: filters,
    },

    false
  );

  const getURLParams = React.useCallback(() => {
    const params = {};
    for (const entry of searchParams.entries()) {
      params[entry[0]] = entry[1];
    }
    return params;
  }, [searchParams]);

  const onApplyKBFilters = ({ kbAllSearchText }) => {
    const newFilters = { ...filters };
    newFilters.queryFilters = {
      ...newFilters.queryFilters,
      search: kbAllSearchText,
    };
    setFilters(newFilters);
  };

  const onResetSearchFilters = () => {
    const newFilters = { ...filters };
    newFilters.queryFilters = {};
    delete newFilters.tag;
    setFilters(newFilters);
    const previousUrlParams = getURLParams();
    delete previousUrlParams["tag"];
    setSearchParams({
      ...previousUrlParams,
    });
  };

  const onKBFilterChange = (data) => {
    if (data?.value) {
      let newFilters = { ...filters };
      // newFilters.filters = {
      //   ...newFilters.filters,
      //   [data.filter]: data.value
      // };
      newFilters = {
        ...newFilters,
        [data.filter]: data.value
      };
      setFilters(newFilters);
      setSearchParams({
        ...getURLParams(),
        [data.filter]: data.value,
      });
    } else {
      let newFilters = { ...filters };
      // delete newFilters.filters[data.filter];
      delete newFilters[data.filter];
      setFilters(newFilters);
      const previousUrlParams = getURLParams();
      delete previousUrlParams[data.filter];
      setSearchParams({
        ...previousUrlParams,
      });
    }
  };

  React.useEffect(() => {
    if (!params.articleId) return;
    if (!articles || articles?.length === 0) {
      articleDispatch({
        type: CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE,
        payload: {
          selectedArticle: null,
        },
      });
      return;
    }

    const activeArticle = articles?.find((a) => a._id === params.articleId);

    if (!activeArticle) {
      articleDispatch({
        type: CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE,
        payload: {
          selectedArticle: articles[0],
        },
      });
      return;
    }

    articleDispatch({
      type: CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE,
      payload: {
        selectedArticle: activeArticle,
      },
    });
  }, [articleDispatch, articles, params.articleId]);

  React.useEffect(() => {
    if (!articleState.selectedArticle) {
      articleDispatch({
        type: CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE,
        payload: {
          selectedArticle: null,
        },
      });
      return;
    };

    fetchArticleDetails(
      {
        id: articleState?.selectedArticle?._id,
      },
      true
    );
  }, [articleDispatch, articleState.selectedArticle, fetchArticleDetails]);

  React.useEffect(() => {
    if (!articleData) return;

    articleDispatch({
      type: CONTEXT_CONSTANTS.SET_DETAILED_ARTICLE_INFORMATION,
      payload: {
        detailedArticleInformation: articleData,
      },
    });
  }, [articleData, articleDispatch]);

  React.useEffect(() => {
    if (!articleState?.hasUpdatedArticleInformation) return;

    removeItemFromLocalArray(articleState?.detailedArticleInformation);
    articleDispatch({
      type: CONTEXT_CONSTANTS.SET_HAS_UPDATED_ARTICLE_INFORMATION,
      payload: {
        hasUpdatedArticleInformation: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleState?.hasUpdatedArticleInformation]);

  React.useEffect(() => {
    if (
      !isLoadingArticleDetails &&
      articles?.length === 0 &&
      articleState?.detailedArticleInformation
    ) {
      articleDispatch({
        type: CONTEXT_CONSTANTS.SET_DETAILED_ARTICLE_INFORMATION,
        payload: {
          detailedArticleInformation: null,
        },
      });
    }
  }, [
    articleDispatch,
    articleState?.detailedArticleInformation,
    articles?.length,
    isLoadingArticleDetails,
  ]);



  return (
    <Box
      minHeight="calc(100vh - 75px)"
      maxHeight="calc(100vh - 75px)"
      height="calc(100vh - 75px)"
      display="flex"
      alignItems="stretch"
      marginTop="-1rem"
      sx={{ overflowY: "hidden", backgroundColor: "F5F7F970" }}
    >
      <KnowledgebaseDetailSidebar
        activeArticleId={articleState?.selectedArticle?._id || params.articleId}
        isFullReloading={isFullReloading}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        articles={articles}
        onApplyKBFilters={onApplyKBFilters}
        onResetSearchFilters={onResetSearchFilters}
        onKBFilterChange={onKBFilterChange}
      />

      {isFullReloading ? (
        <>
          <KBDetailMetaSkeleton
            expanded={appState?.isArticleDetailMetaPaneExpanded}
          />
          <ArticleDetailedInfoSkeleton />
        </>
      ) : (
        <Box width="100%">
          {/* {isFetching && <LinearProgress />} */}
          <Box width="100%" display="flex">
            <KBDetailMeta
              isFullReloading={isFullReloading}
              onKBFilterChange={onKBFilterChange}
            />
            {isLoadingArticleDetails ? (
              <Box width="100%" paddingX={2}>
                <ArticleDetailedInfoSkeleton />
              </Box>
            ) : (
              <KnowledgebaseDetailWrapper />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
