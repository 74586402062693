import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import TeamForm from "./teamComponents/TeamForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateTeamMutation } from "../../../rtkStore/services/teamApi";

const TeamCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Teams", path: "/app/team/list" },
    { title: "New Team" },
  ];

  // RTK Query
  const [createTeam, { isLoading: creatingTeam }] = useCreateTeamMutation();

  // methods
  const handleAddTeam = async (data) => {
    try {
      await createTeam(data).unwrap();
      toast.success("Team successfully created!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add team!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingTeam}>
      <TeamForm isBusy={creatingTeam} onSubmit={handleAddTeam} />
    </WithHeader>
  );
};

export default TeamCreateScreen;
