import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FeedbackForm from "./feedbackComponents/FeedbackForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetFeedbackDetailQuery,
  useUpdateFeedbackMutation,
} from "../../../rtkStore/services/feedbackApi";

const FeedbackEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Feedbacks", path: "/app/feedback/list" },
    { title: "Edit Feedback" },
  ];

  // RTK Query
  const {
    data: feedbackDetail,
    isLoading: loadingFeedbackDetail,
    error,
  } = useGetFeedbackDetailQuery(id);

  const [updateFeedback, { isLoading: updatingFeedback }] =
    useUpdateFeedbackMutation();

  // methods
  const handleUpdateFeedback = async (data) => {
    try {
      await updateFeedback(data).unwrap();
      toast.success("Feedback successfully updated!");
      navigate(`/app/feedback/${id}/detail`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Coundn't update feedback!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={updatingFeedback || loadingFeedbackDetail}
      error={error}
    >
      <FeedbackForm
        feedbackDetail={feedbackDetail}
        onSubmit={handleUpdateFeedback}
        isBusy={updatingFeedback || loadingFeedbackDetail}
      />
    </WithHeader>
  );
};

export default FeedbackEditScreen;
