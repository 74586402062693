import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";

import { useGetClientListQuery } from "../../../../rtkStore/services/clientApi";
import { useListListQuery } from "../../../../rtkStore/services/listService";
import { useListAccountUserQuery } from "../../../../rtkStore/services/userApi";
import { useGetTeamListQuery } from "../../../../rtkStore/services/teamApi";

const TransactionForm = ({
  transactionDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [responsibleSource, setResponsibleSource] = React.useState("User");

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const responsibleSources = ["User", "Team"];

  //   RTK Query
  const { data: clientList, isLoading: loadingClients } =
    useGetClientListQuery();

  const { data: userList, isLoading: loadingUsers } = useListAccountUserQuery();

  const { data: teamList, isLoading: loadingTeams } = useGetTeamListQuery();

  const { data: transactionTypes, isLoading: loadingTransactionTypes } =
    useListListQuery({
      type: "Transaction Types",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: transactionStages, isLoading: loadingTransactionStages } =
    useListListQuery({
      type: "General Stages",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedClientList = React.useMemo(() => {
    return (
      clientList?.map((client) => ({
        label: `${client?.firstName} ${client?.lastName}`,
        value: client?._id,
      })) ?? []
    );
  }, [clientList]);

  const mappedResponsibleUserList = React.useMemo(() => {
    switch (responsibleSource) {
      case "User":
        return (
          userList?.map((name) => ({
            label: name.name,
            value: name._id,
          })) ?? []
        );

      case "Team":
        return teamList?.map((team) => ({
          label: team?.name,
          value: team?._id,
        }));

      default:
        return [];
    }
  }, [responsibleSource, teamList, userList]);

  // methods
  const getResponsibleSelectLabel = (responsible) => {
    if (!responsible) return;

    switch (transactionDetail?.responsibleSource) {
      case "User":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      case "Team":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      default:
        return "";
    }
  };

  const handleResponsibleSourceChange = (e) => {
    setResponsibleSource(e.target.value);

    const currentValues = getValues();
    reset({ ...currentValues, responsible: null });
  };

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      client: transactionDetail?.client
        ? {
            label: `${transactionDetail?.client?.firstName} ${transactionDetail?.client?.lastName}`,
            value: transactionDetail?.client._id,
          }
        : "",
      title: "",
      type: "",
      stage: "",
      responsible: transactionDetail?.responsible
        ? getResponsibleSelectLabel(transactionDetail?.responsible)
        : "",
      description: "",
    },
  });

  React.useEffect(() => {
    if (!transactionDetail) return;

    setResponsibleSource(transactionDetail?.responsibleSource ?? "User");

    const selectableResponsible = getResponsibleSelectLabel(
      transactionDetail?.responsible
    );

    reset({
      ...transactionDetail,
      client: transactionDetail?.client
        ? {
            label: `${transactionDetail?.client?.firstName} ${transactionDetail?.client?.lastName}`,
            value: transactionDetail?.client._id,
          }
        : null,
      type: transactionDetail?.type?._id,
      stage: transactionDetail?.stage?._id,
      responsible: selectableResponsible,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDetail]);

  React.useEffect(() => {
    if (!searchParams) return;

    const paramClient = searchParams.get("selectedClient");
    if (!paramClient) return;

    const client = mappedClientList?.find((c) => c?.value === paramClient);
    if (!client) return;

    reset({
      client,
    });
  }, [reset, searchParams, mappedClientList]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingClients ||
        loadingUsers ||
        loadingTeams ||
        loadingTransactionTypes ||
        loadingTransactionStages
      }
    >
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Controller
              name="client"
              control={control}
              rules={{ required: "Please select a client" }}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="client"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingClients}
                  loadingText="Loading Client..."
                  options={mappedClientList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("client", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.client && <p className="error">{errors.client.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Transaction type is required" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-type">Transaction Type</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-type"
                    label="Transaction Type"
                    placeholder="Select Type"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {transactionTypes?.map((type) => (
                      <MenuItem key={type._id} value={type._id}>
                        {type?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.type && <p className="error">{errors.type.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="stage"
              control={control}
              rules={{ required: "Stage is required" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-stage">Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-stage"
                    label="Stage"
                    placeholder="Select Stage"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {transactionStages?.map((stage) => (
                      <MenuItem key={stage._id} value={stage._id}>
                        {stage?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.stage && <p className="error">{errors.stage.message}</p>}
          </Grid>

          <Grid item md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-assginee-type">
                Responsible Type
              </InputLabel>
              <Select
                fullWidth
                labelId="select-assginee-type"
                label="Responsible Type"
                placeholder="Select Assignee Type"
                id="role"
                value={responsibleSource}
                onChange={handleResponsibleSourceChange}
              >
                {responsibleSources?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6}>
            <Controller
              name="responsible"
              control={control}
              rules={{ required: "Responsible user is required" }}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="responsible"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingTeams || loadingUsers}
                  loadingText="Loading Responsible Users..."
                  options={mappedResponsibleUserList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("responsible", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsible"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.responsible && (
              <p className="error">{errors.responsible.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  size="small"
                  multiline
                  rows={3}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      value={field?.value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              client: data?.client?.value,
              responsible: data?.responsible?.value,
              responsibleSource,
            })
          )}
        >
          {transactionDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default TransactionForm;
