import React from "react";

import { Route, Routes } from "react-router-dom";

import GlobalListDetailScreen from "../screens/Admin/GlobalList/GlobalListDetailScreen";
import GlobalListScreen from "../screens/Admin/GlobalList/GlobalListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const GlobalListRouter = () => {
  return (
    <Routes>
      <Route index element={<GlobalListScreen />} />
      <Route path="detail/:id" element={<GlobalListDetailScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default GlobalListRouter;
