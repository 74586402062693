import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import InformationTile from "../../../../components/Shared/InformationTile";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";

const TaskDetail = ({ taskDetail }) => {
  const { assignee, assigneeSource } = taskDetail;

  const assigneeName = React.useMemo(() => {
    if (!assignee) return null;

    switch (assigneeSource) {
      case "Client":
        return `${assignee?.firstName} ${assignee?.lastName}`;

      case "Transaction":
        return assignee?.title;

      case "User":
        return assignee?.name;

      default:
        return "N/A";
    }
  }, [assignee, assigneeSource]);

  return (
    <Box padding={3}>
      <Typography variant="h6">{taskDetail?.title}</Typography>

      <br />

      <Grid container columnSpacing={6} rowSpacing={3}>
        <Grid item md={3}>
          <InformationTile
            icon={() => <AssignmentLateOutlinedIcon color="primary" />}
            title="Priority"
            subtitle={taskDetail?.priority}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AssignmentLateOutlinedIcon color="primary" />}
            title="Visibility"
            subtitle={taskDetail?.visibility}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <CategoryOutlinedIcon color="primary" />}
            title="Category"
            subtitle={taskDetail?.category}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <DonutLargeOutlinedIcon color="primary" />}
            title="Status"
            subtitle={taskDetail?.status}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <InsertInvitationOutlinedIcon color="primary" />}
            title="Due Date"
            subtitle={moment(taskDetail?.dueDate)?.format("YYYY-MM-DD")}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AssignmentIndOutlinedIcon color="primary" />}
            title="Assignee"
            subtitle={assigneeName}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Responsible"
            subtitle={taskDetail?.responsible?.name}
          />
        </Grid>
      </Grid>

      <Box marginTop={3}>
        <div>{ReactHtmlParser(taskDetail?.description) ?? "N/A"}</div>
      </Box>
    </Box>
  );
};

export default TaskDetail;
