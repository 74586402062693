export const LIST_CONSTANTS = {
  APPLICATION_STATUS: "Application Status",
  BLOG_CATEGORIES: "Blog Categories",
  CLIENT_STAGES: "Client Stages",
  DOCUMENT_TYPES: "Document Types",
  EMPLOYMENT_INDUSTRIES: "Employment Industries",
  GENERAL_STAGES: "General Stages",
  INSTITUTION_TYPES: "Institution Types",
  KNOWLEDGEBASE_CATEGORY: "Knowledgebase Category",
  KNOWLEDGEBASE_TAGS: "Knowledgebase Tags",
  LEAD_SERVICES: "Lead Services",
  LEAD_STAGES: "Lead Stages",
  LEAD_SOURCE_TYPES: "Lead Source Types",
  LEAD_QUESTIONS: "Lead Questions",
  NEXT_ACTION_TYPES: "Next Action Types",
  STUDENT_EDUCATION_LEVEL: "Student Education Level",
  TRANSACTION_TYPES: "Transaction Types",
  UNIVERSITY_ASSESSMENT_LEVELS: "University Assessment Levels",
  UNIVERSITY_PRIORITIES: "University Priorities",
  AGENT_PROMOTIONS: "Agent Promotions",
};

const LIST_LEVEL_MAP = {
  KNOWLEDGEBASE_CATEGORY: 2
};

// const GROUP_ABLE_LIST = ["CLIENT_STAGES", "GENERAL_STAGES", "LEAD_STAGES"];

const GROUP_ABLE_LIST = {
  CLIENT_STAGES: ["Open", "Closed"],
  GENERAL_STAGES: ["Open", "Closed"],
  LEAD_STAGES: ["Open", "Closed"],
};

export const listOptions = Object.keys(LIST_CONSTANTS).map((key) => ({
  name: LIST_CONSTANTS[key],
  value: LIST_CONSTANTS[key],
  maxLevel: LIST_LEVEL_MAP[key] ?? 1,
  allowGrouping: GROUP_ABLE_LIST[key]?.length,
  ...(!!GROUP_ABLE_LIST[key] && { groups: GROUP_ABLE_LIST[key] }),
}));

// export const listOptions = [
//   {
//     name: "Blog Categories",
//     value: "Blog Categories",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Lead Stages",
//     value: "Lead Stages",
//     maxLevel: 1,
//     allowGrouping: true,
//     groups: ["Open", "Closed"],
//   },
//   {
//     name: "Lead Source Types",
//     value: "Lead Source Types",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Lead Services",
//     value: "Lead Services",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Client Stages",
//     value: "Client Stages",
//     maxLevel: 1,
//     allowGrouping: true,
//     groups: ["Open", "Closed"],
//   },
//   {
//     name: "Institution Types",
//     value: "Institution Types",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Student Education Levels",
//     value: "Student Education Levels",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Employment Industries",
//     value: "Employment Industries",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Next Action Types",
//     value: "Next Action Types",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Lead Questions",
//     value: "Lead Questions",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Document Types",
//     value: "Document Types",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Transaction Types",
//     value: "Transaction Types",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "Application Status",
//     value: "Application Status",
//     maxLevel: 1,
//     allowGrouping: false,
//   },
//   {
//     name: "General Stages",
//     value: "General Stages",
//     maxLevel: 1,
//     allowGrouping: true,
//     groups: ["Open", "Closed"],
//   },
// ];
