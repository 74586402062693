import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Divider, Paper } from "@mui/material";

import ClientNotesTab from "./clientInformations/ClientNotesTab";
import ClientFavCoursesTab from "./clientInformations/ClientFavCoursesTab";
import ClientStudyOptionsTab from "./clientInformations/ClientStudyOptionsTab";
import CustomTabs from "../../../../components/Shared/CustomTabs";
import CustomTabPanel from "../../../../components/Shared/CustomTabPanel";
import DocumentsTab from "./clientInformations/DocumentsTab";
import EducationDetailTab from "./clientInformations/EducationDetailTab";
import EmploymentDetailTab from "./clientInformations/EmploymentDetailTab";
import TestDetailTab from "./clientInformations/TestDetailTab";
import ClientBasicDetail from "./clientInformations/ClientBasicDetail";
import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";
import TaskTab from "./clientInformations/TaskTab";

import { useGetClientDetailQuery } from "../../../../rtkStore/services/clientApi";

const ClientDetailContext = React.createContext(null);

export const useClientDetail = () => React.useContext(ClientDetailContext);

const ClientBasicInformationTab = () => {
  const [activeTab, setActiveTab] = React.useState("notes");

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  // RTK Query
  const { data: clientDetail, isLoading: loadingClient } =
    useGetClientDetailQuery(id);

  const clientDetailTabs = [
    {
      label: "Notes",
      value: "notes",
      badge: true,
      badgeCount: clientDetail?.notes?.length,
    },
    {
      label: "Education Details",
      value: "educations",
      badge: true,
      badgeCount: clientDetail?.educations?.length,
    },
    {
      label: "Employment Details",
      value: "employments",
      badge: true,
      badgeCount: clientDetail?.employments?.length,
    },
    {
      label: "Test Details",
      value: "tests",
      badge: true,
      badgeCount: clientDetail?.tests?.length,
    },
    {
      label: "Documents",
      value: "documents",
      badge: true,
      badgeCount: clientDetail?.documents?.length,
    },
    {
      label: "Tasks",
      value: "tasks",
    },
    {
      label: "Favourite Courses",
      value: "favouriteCourses",
      badge: true,
      badgeCount: clientDetail?.favCourses?.length,
    },
    {
      label: "Study Options",
      value: "studyOptions",
      badge: true,
      badgeCount: clientDetail?.studyOptions?.length,
    },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "notes");
  }, [searchParams]);

  return (
    <ClientDetailContext.Provider value={{ clientDetail, loadingClient }}>
      <Paper elevation={0} variant="outlined">
        <ClientBasicDetail />

        <Divider style={{ marginTop: -1 }} />

        <CustomTabs tabList={clientDetailTabs} activeTab={activeTab} />

        {!loadingClient ? (
          <>
            <CustomTabPanel activeTab={activeTab} value="notes">
              <ClientNotesTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="educations">
              <EducationDetailTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="employments">
              <EmploymentDetailTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="tests">
              <TestDetailTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="documents">
              <DocumentsTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="tasks">
              <TaskTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="favouriteCourses">
              <ClientFavCoursesTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="studyOptions">
              <ClientStudyOptionsTab />
            </CustomTabPanel>
          </>
        ) : (
          <>
            <RowsSkeleton />
          </>
        )}
      </Paper>
    </ClientDetailContext.Provider>
  );
};

export default ClientBasicInformationTab;
