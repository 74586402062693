import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import TableLoader from "../../../../components/Shared/AppTable/TableLoader";
import WithHeader from "../../../../layouts/WithHeader";

import { useGetWorkflowApplicationReportDataQuery } from "../../../../rtkStore/services/reportApi";
import sortListAlphaNumerically from "../../../../utils/getAlphaNumericSortedList";

const WorkflowApplicationStepStatusCell = ({ workflowUpdates, step }) => {
  const STATUS_COLOR_MAP = {
    Completed: "bg-green-100",
    "In Progress": "bg-orange-100",
    Open: "bg-blue-50",
    "Not Applicable": "bg-gray-100",
  };

  const latestWorkflowStep = workflowUpdates
    ?.filter((el) => el?.step === step?._id)
    ?.sort((a, b) => (a?.createdAt > b?.createdAt ? -1 : 1))[0];

  const applicationStepStatusIndicator =
    STATUS_COLOR_MAP[latestWorkflowStep?.status] ?? STATUS_COLOR_MAP["Open"];

  const statusUpdateDate = new Date(
    latestWorkflowStep?.createdAt
  )?.toLocaleDateString();

  return (
    <td
      className={`whitespace-nowrap border border-white p-2  ${applicationStepStatusIndicator}`}
      title={`${latestWorkflowStep?.status} on ${statusUpdateDate}`}
      key={step?._id}
    >
      <div className={`flex flex-col space-y-2 text-center`}>
        {latestWorkflowStep ? (
          <div className="flex flex-col">
            <div className="text-sm">{latestWorkflowStep?.status}</div>

            <div className="text-xs tracking-wider text-gray-500">
              {statusUpdateDate}
            </div>
          </div>
        ) : (
          "--"
        )}
      </div>
    </td>
  );
};

const WorkflowApplicationReportScreen = () => {
  const { workflowId } = useParams();

  const [searchParams] = useSearchParams();

  const breadcrumbs = [
    {
      title: "Workflow Applications Report",
      path: "/app/report/workflow-application/list",
    },
    ...(searchParams?.get("workflow")
      ? [
          {
            title: searchParams.get("workflow"),
          },
        ]
      : []),
  ];

  // RTK Query
  const {
    data: workflowApplicationRes,
    isLoading: loadingReportData,
    error,
  } = useGetWorkflowApplicationReportDataQuery(workflowId);

  const workflowDetail = workflowApplicationRes?.workflow;

  const workflowApplications = React.useMemo(() => {
    return workflowApplicationRes?.workflowApplications ?? [];
  }, [workflowApplicationRes]);

  const workflowSteps = React.useMemo(() => {
    const activeSteps = workflowDetail?.steps?.filter((el) => el?.isActive);

    const sortedSteps = sortListAlphaNumerically({
      sortableList: activeSteps,
      sortableKey: "serialNumber",
    });

    return sortedSteps ?? [];
  }, [workflowDetail]);

  const isCollegeApplication = React.useMemo(() => {
    if (!workflowApplications.length) return false;

    return workflowApplications[0]?.applicationType
      ?.toLowerCase()
      ?.includes("college");
  }, [workflowApplications]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingReportData}
      error={error}
      disablePaper
      toggleKey="WORKFLOW_APPLICATION_REPORT"
    >
      <div className="block overflow-auto rounded bg-white">
        {!loadingReportData ? (
          <table className="w-full table-auto border-collapse ">
            <thead>
              <tr className="font-medium">
                <td className="w-10 border p-2 text-sm"></td>
                <td className="w-52 border p-2 text-sm">Client</td>
                <td className="w-52 border p-2 text-sm">
                  {isCollegeApplication ? "Preferred Course" : "Provider "}
                </td>
                {workflowSteps?.map((step) => (
                  <td className="border p-2 text-sm" key={step?._id}>
                    {step?.title}
                  </td>
                ))}
                <td className="w-52 border p-2 text-sm">Latest Comment</td>
              </tr>
            </thead>

            <tbody className="border border-gray-200">
              {workflowApplications?.map((row, index) => (
                <tr key={row?._id}>
                  <td className="border p-2 text-center text-sm">
                    {`${index + 1}.`}
                  </td>
                  <td className="border p-2">
                    <Link
                      to={`/app/application/${row?._id}/detail?tab=workflows`}
                      className="text-sm hover:text-blue-600 hover:underline"
                      title="View Application"
                    >
                      {`${row?.applicationClient?.firstName} ${row?.applicationClient?.lastName}`}
                    </Link>
                  </td>
                  <td className="w-50 border p-2 text-sm">
                    {isCollegeApplication ? (
                      row?.applicantPreferredCourse ? (
                        <Link
                          to={`/app/course/${row?.applicantPreferredCourse?._id}/detail`}
                          className="text-sm line-clamp-2 hover:text-blue-600 hover:underline"
                          title="View Course"
                        >
                          {row?.applicantPreferredCourse?.title}
                        </Link>
                      ) : (
                        "N/A"
                      )
                    ) : (
                      row?.applicationProvider
                    )}
                  </td>
                  {workflowSteps?.map((step) => (
                    <WorkflowApplicationStepStatusCell
                      key={step?._id}
                      step={step}
                      workflowUpdates={row?.workflowUpdates}
                    />
                  ))}
                  <td className="border p-2 text-sm">
                    {row?.workflowUpdates?.at(-1)?.note ?? "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="overflow-hidden border">
            <TableLoader />
          </div>
        )}
      </div>
    </WithHeader>
  );
};

export default WorkflowApplicationReportScreen;
