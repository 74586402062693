import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

const LoadingWrapper = ({ children, loading = false }) => {
  return (
    <div className="relative overflow-hidden rounded">
      {loading && (
        <div className="absolute inset-0 z-10 cursor-progress bg-gray-500 bg-opacity-30">
          <div className="flex h-full flex-col items-center justify-center">
            <CircularProgress />

            <div className="mt-2 text-lg font-medium text-white">
              Loading Data...
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingWrapper;
