import * as React from "react";
import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline, Drawer, Divider, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import SidebarContextProvider, {
  useSidebarContext,
} from "./SidebarContextProvider";

import AppBar from "./AppBar";
import AppConfetti from "../../components/Shared/AppConfetti";
import ScrollToTopFAB from "../../components/ScrollToTopFAB";
import MainContent from "./MainContent";

import AppLogo from "./AppBar/AppLogo";
import AppSidebar from "../../components/Sidebar/AppSidebar";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SidebarLayout(props) {
  React.useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, []);

  return (
    <SidebarContextProvider>
      <SLayout {...props} />
    </SidebarContextProvider>
  );
}

const SLayout = () => {
  const theme = useTheme();

  const { showSidebar, setShowSidebar, drawerWidth } = useSidebarContext();

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />

      <AppBar />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={showSidebar}
      >
        <DrawerHeader>
          <AppLogo />

          <IconButton onClick={() => setShowSidebar(false)}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <AppSidebar />
      </Drawer>

      <MainContent
        open={showSidebar}
        drawerWidth={drawerWidth}
        style={{
          flexGrow: 1,
          marginTop: "-0.7rem",
          maxWidth: !showSidebar ? "100%" : `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <>
          <DrawerHeader />

          <Outlet />

          <ScrollToTopFAB />
        </>
      </MainContent>

      <AppConfetti />
    </Box>
  );
};
