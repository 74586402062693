import { Box, Skeleton } from "@mui/material";
import React from "react";

export const ArticleDetailedInfoSkeleton = () => {
  return (
    <Box
      width="100%"
      height="calc(100vh - 80px)"
      maxHeight="calc(100vh - 80px)"
    >
      <Box
        marginTop="-16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
      >
        <Box display="flex" alignItems="center" gap={4}>
          {Array.from(Array(4).keys()).map((sk) => (
            <Skeleton
              key={`sk-${sk}`}
              width="140px"
              height="80px"
              style={{
                display: "block",
                marginRight: 8,
                marginTop: 8,
              }}
            />
          ))}
        </Box>
        <Box display="flex" alignItems="center" gap={4}>
          {Array.from(Array(2).keys()).map((sk) => (
            <Skeleton
              key={`sk-${sk}`}
              width="80px"
              height="68px"
              style={{
                display: "block",
                marginRight: 8,
                marginTop: 8,
              }}
            />
          ))}
        </Box>
      </Box>

      <Box>
        <Skeleton variant="rect" width="100%" height={400} />
        <Skeleton
          variant="rect"
          width="100%"
          height={400}
          style={{ marginTop: "1rem" }}
        />
      </Box>
    </Box>
  );
};
