import {
  Box,
  // Card,
  // CardContent,
  Container,
  // Grid,
  Typography,
} from '@mui/material'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import React from 'react'
import sudeep from '../../assets/images/teams/sudeep.png'
import jenny from '../../assets/images/teams/jenny.png'
import jack from '../../assets/images/teams/jack.png'
import cresilda2 from '../../assets/images/teams/cresilda2.png'
import user from '../../assets/images/teams/user.png'

// import aboutUsImage from "../assets/images/aboutUsImage.png"
// import whyUs from "../assets/images/whyUs.png"
import Teams from '../../components/Teams'

const MeetOurTeam = () => {
  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      {/* <TabContext value={value}> */}
      <div className='meetTheTeamBanner'>
        <Box
          item
          xs={12}
          display='inline-grid'
          justifyContent='center'
          alignItems='center'
          width='100%'
        >
          <Typography className='meetTheTeamHeader' variant='h6'>
            Meet the <span className='meetTheTeamText'>Team</span>.
          </Typography>
          {/* <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label='EXECUTIVE' value='1' className='TeamsTabBtn' />
              <Tab label='sALES' value='2' className='TeamsTabBtn' />
              <Tab label='SERVICE' value='3' className='TeamsTabBtn' />
              <Tab label='PARTNERSHIP' value='4' className='TeamsTabBtn' />
              <Tab label='MARKETING' value='5' className='TeamsTabBtn' />
            </TabList> */}
        </Box>
      </div>
      <Container maxWidth='xl'>
        {/* <TabPanel value='1'> */}
        <Typography
          variant='h6'
          gutterBottom
          sx={{
            fontSize: '3rem',
            fontWeight: 600,
            color: 'red',
            fontStyle: 'italic',
            marginTop: '2rem',
          }}
        >
          Key Executives
        </Typography>

        <Teams
          name='Sudeep Acharya'
          position='CEO'
          image={sudeep}
          order='1'
          description='Sudeep Acharya is a versatile professional accountant with a robust career spanning finance, technology, operations, and general management in healthcare, logistics, technology, and education sectors. After 12 years at an Australian healthcare firm, he self-taught coding and developed significant software projects. He founded multiple companies and currently serves as CEO of Bayupayu and CTO of GatewayX, in addition to holding board positions in The Can Brand Group companies globally. Sudeep holds a Masters Degree in Accountancy from the University of London and is a member of Chartered Accountants ANZ and a Fellow at ACCA, UK.'
        />

        {/* </TabPanel> */}
        {/* <TabPanel value='2'> */}

        <Teams
          name='Jack Walker'
          position='Executive Director'
          image={jack}
          order='0'
          description='Jack Walker is from legal background and has an extensive career across various industries, including private equity, property development, and healthcare. He started as his career at Wingate Group while studying at Melbourne Law School and later became Managing Director at Cross Ridge Properties. He currently holds board positions in several companies, including AMI Expeditionary Healthcare (USA), Universal Air (Malta), AMI Global Assistance (London), Equatorial Launch Australia (Darwin), and Blackfyre Pacifika (Fiji). Additionally, he serves as Executive Director at Pinnacle Healthcare (Sydney) and CEO of GatewayX Technologies (Sydney). Jack holds a Bachelor of Arts in Philosophy from Monash University and a Juris Doctor from Melbourne Law School.'
        />

        <Teams
          name='Jennylyn Gobaton'
          position='Head of Sales'
          image={jenny}
          order='1'
          description="Jennylyn Gobaton is a experienced professional with extensive experience in accountancy, finance, general management, and sales. She began her career in the energy sector in the Philippines before moving to the healthcare sector in the UAE and later transitioning to the technology and education sectors in Australia. With a background in coding and technology development, she has excelled in various roles, including Finance Manager, Financial Controller, Software Developer, Operations Manager, and Sales Manager. Jennylyn holds a Bachelor's degree in Accountancy from Bicol University and is a member of CPA Australia, showcasing her commitment to excellence in her field. Her diverse skills and adaptability make her a valuable asset in business settings that require a combination of financial expertise, management abilities, and technological know-how."
        />

        <Teams
          name='Cresilda Yuhayco'
          position='Head of Service'
          image={cresilda2}
          order='0'
          description="Cresilda Yuhayco is a professional with diverse experiences in accountancy, finance, technology, ecommerce, and the education industry. She began her career in the Philippines' BPO industry and later moved to the UAE to work in a healthcare company. Eventually, she settled in Australia, where she excelled in the technology sector as a full-stack developer. Throughout her career, Cresilda has held various significant roles, including Accounting Manager, Project Finance Manager, Software Developer Service Manager, and Department Manager. She holds a Bachelor's degree in Accountancy and is a member of CPA Philippines."
        />

        {/* <Teams
          name='Aileen Bagtas'
          position='Head of IT and Finance'
          image={user}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Charmaine Selma'
          position='Head of Marketing'
          image={charmaine}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}

        {/* <Teams
          name='Kriszelle Gumaya'
          position='Senior Education Counsellor'
          image={user}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}

        {/* <Teams
          name='Roshelle Alvaran'
          position='Senior Education Counsellor'
          image={user}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}
        {/* </TabPanel> */}

        {/* <TabPanel value='3'> */}
        {/* <Box
          sx={{
            width: 200,
            backgroundColor: "#eb2428",
            padding: "5px 8px",
            my: 5,
            borderRadius: "0 10px 10px 0",
            color: "#fff",
          }}
        >
          <Typography
            variant='h6'
            gutterBottom
            sx={{ fontSize: "1.5rem", fontWeight: 600 }}
          >
            Service
          </Typography>
        </Box> */}
        {/* <Teams
          name='Bianca Balde'
          position='Senior Education Counsellor'
          image={bianca}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Maureene Dorado'
          position='Student Counsellor'
          image={maureene}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Toni Rose Casia'
          position='Student Counsellor'
          image={toni}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Fahmi Abdilla'
          position='Student Counsellor'
          image={fahmi}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}
        {/* </TabPanel> */}

        {/* <TabPanel value='4'> */}
        {/* <Box
          sx={{
            width: 200,
            backgroundColor: "#eb2428",
            padding: "5px 8px",
            my: 5,
            borderRadius: "0 10px 10px 0",
            color: "#fff",
          }}
        >
          <Typography
            variant='h6'
            gutterBottom
            sx={{ fontSize: "1.5rem", fontWeight: 600 }}
          >
            Partnership
          </Typography>
        </Box> */}
        {/* <Teams
          name='Rei Pratama'
          position='Student Counsellor'
          image={rai}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Duong Thi Diem My'
          position='Student Counsellor'
          image={duong}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Phan Thi My Duyen'
          position='Student Counsellor'
          image={phan}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}
        {/* </TabPanel> */}

        {/* <TabPanel value='5'> */}
        {/* <Box
          sx={{
            width: 200,
            backgroundColor: "#eb2428",
            padding: "5px 8px",
            my: 5,
            borderRadius: "0 10px 10px 0",
            color: "#fff",
          }}
        >
          <Typography
            variant='h6'
            gutterBottom
            sx={{ fontSize: "1.5rem", fontWeight: 600 }}
          >
            Marketing
          </Typography>
        </Box>
        <Teams
          name='Rei Pratama'
          position='Student Counsellor'
          image={rai}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Duong Thi Diem My'
          position='Student Counsellor'
          image={duong}
          order='0'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        />

        <Teams
          name='Phan Thi My Duyen'
          position='Student Counsellor'
          image={phan}
          order='1'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting 
          industry. Lorem Ipsum has been the industry's standard dummy text ever since 
          the 1500s, when an unknown printer took a galley of type and scrambled it to 
          make a type specimen book. It has survived not only five centuries, but also 
          the leap into electronic typesetting, remaining essentially unchanged. It was 
          popularised in the 1960s with the release of Letraset sheets containing Lorem 
          Ipsum passages, and more recently with desktop publishing software like Aldus 
          PageMaker including versions of Lorem Ipsum."
        /> */}
        {/* </TabPanel> */}
      </Container>
      {/* </TabContext> */}
    </>
  )
}

export default MeetOurTeam
