import React from "react";

import NestedMenu from "./NestedMenu";

import { useAuth } from "../../hooks/useAuth";

const AppSidebar = () => {
  const { isAccountAdmin } = useAuth();

  const ACCOUNT_LVL_ADMIN_MENUS = [
    {
      title: "Account Info",
      path: "/app/account/info",
      icon: "GroupOutlined",
    },
    {
      title: "Authorizations",
      path: "/app/authorization/list/account",
      icon: "LockOutlined",
    },
    {
      title: "Entities",
      path: "/app/entity/list/account",
      icon: "InfoOutlined",
    },
    {
      title: "Users",
      path: "/app/user/account/list",
      icon: "AdminPanelSettingsOutlined",
    },
  ];

  const SERVICES_MENUS = [
    {
      title: "Services",
      icon: "MiscellaneousServices",
      subMenus: [
        {
          title: "Leads",
          icon: "BookmarkAddOutlined",
          path: "/app/lead/list",
        },
        {
          title: "Clients",
          icon: "PersonPinOutlined",
          path: "/app/client/list",
        },
        {
          title: "Transactions",
          icon: "ReceiptLongOutlined",
          path: "/app/transaction/list",
        },

        {
          title: "Applications",
          icon: "HistoryEduOutlined",
          path: "/app/application/list",
        },
        {
          title: "Tasks",
          icon: "AddTask",
          path: "/app/task/list",
        },
      ],
    },
  ];

  const ADMINISTRATION_MENUS = [
    {
      title: "Administration",
      icon: "AdminPanelSettingsOutlined",
      subMenus: [
        {
          title: "Blogs",
          path: "/app/blog/list",
          icon: "Notes",
        },
        {
          title: "Universities",
          icon: "AccountBalanceOutlined",
          path: "/app/university/list",
        },
        {
          title: "Courses",
          icon: "MenuBookOutlined",
          path: "/app/course/list",
        },
        {
          title: "Workflows",
          path: "/app/workflow/list",
          icon: "AccountTreeOutlined",
        },
        {
          title: "Knowledgebase",
          path: "/app/knowledgebase/list",
          icon: "LibraryBooks",
        },
        {
          title: "Teams",
          path: "/app/team/list",
          icon: "GroupsOutlined",
        },
        {
          title: "List",
          path: "/app/list/all",
          icon: "List",
        },
        ...(isAccountAdmin
          ? [
              {
                title: "Global List",
                path: "/app/global-list",
                icon: "PublicOutlined",
              },
            ]
          : []),
        ...(isAccountAdmin ? ACCOUNT_LVL_ADMIN_MENUS : []),
      ],
    },
  ];

  const REPORT_MENUS = [
    {
      title: "Reports",
      icon: "AssessmentOutlined",
      subMenus: [
        {
          title: "Agent's Lead Status",
          path: "/app/report/lead-status",
          icon: "BookmarkAddOutlined",
        },
        {
          title: "Workflow Transactions",
          path: "/app/report/workflow-transaction/list",
          icon: "Notes",
        },
        {
          title: "Workflow Applications",
          path: "/app/report/workflow-application/list",
          icon: "HistoryEduOutlined",
        },
      ],
    },
  ];

  const sideMenus = [
    {
      title: "Dashboard",
      path: "/app/dashboard",
      icon: "DashboardCustomize",
    },
    {
      title: "Search Course",
      icon: "Search",
      path: "/app/search-course",
    },
    ...SERVICES_MENUS,
    ...(isAccountAdmin ? ADMINISTRATION_MENUS : []),
    ...REPORT_MENUS,
    {
      title: "Feedbacks",
      icon: "FeedbackOutlined",
      path: "/app/feedback/list",
    },
  ];

  return <NestedMenu menuList={sideMenus} />;
};

export default AppSidebar;
