import React from "react";
import { Slide } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

const ScrollToTopFAB = () => {
  const [showTopBtn, setShowTopBtn] = React.useState(false);

  //   methods
  const handleScrollToTop = () => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <div className="fixed bottom-16 right-4">
      <Slide direction="up" in={showTopBtn} mountOnEnter unmountOnExit>
        <div
          className="grid h-12 w-12 cursor-pointer place-content-center rounded-full bg-blue-600 text-white shadow-xl"
          onClick={handleScrollToTop}
        >
          <ArrowUpward />
        </div>
      </Slide>
    </div>
  );
};

export default ScrollToTopFAB;
