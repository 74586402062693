import React from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import {
  EditOutlined,
  ToggleOff,
  ToggleOn,
  VisibilityOutlined,
} from "@mui/icons-material";

const ListOption = ({
  isBusy = false,
  listOption,
  onViewSubOptions,
  onSelectOption,
  onToggleOptionStatus,
}) => {
  return (
    <div className="flex select-none items-center justify-between space-x-10 px-4 py-3">
      <div className="flex-grow">{listOption?.name}</div>

      {listOption?.group && (
        <div className=" bg-blue-50 px-2 py-0.5 text-xs font-medium uppercase tracking-widest text-blue-500">
          {listOption?.group}
        </div>
      )}

      <div
        className={`rounded px-2 py-0.5 text-sm ${
          listOption?.status === "Active"
            ? "bg-green-50 text-green-500"
            : "bg-red-50 text-red-500"
        }`}
      >
        {listOption.status}
      </div>

      <Box display="flex">
        {listOption?.level < listOption?.maxLevel && (
          <IconButton disabled={isBusy}>
            <Tooltip title="View Sub Options" arrow placement="top">
              <VisibilityOutlined
                fontSize="small"
                onClick={() => onViewSubOptions(listOption)}
              />
            </Tooltip>
          </IconButton>
        )}

        <IconButton disabled={isBusy}>
          <Tooltip title="Edit" arrow placement="top">
            <EditOutlined
              fontSize="small"
              onClick={() => onSelectOption(listOption)}
            />
          </Tooltip>
        </IconButton>

        <IconButton
          disabled={isBusy}
          onClick={() => onToggleOptionStatus(listOption)}
        >
          <Tooltip
            title={listOption.status === "Active" ? "Deactivate" : "Active"}
            arrow
            placement="top"
          >
            {listOption.status === "Active" ? (
              <ToggleOn color="success" />
            ) : (
              <ToggleOff color="error" />
            )}
          </Tooltip>
        </IconButton>
      </Box>
    </div>
  );
};

export default ListOption;
