import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EmptyIcon from "../../../../assets/icons/empty.png";
import SelectionIcon from "../../../../assets/icons/selection.png";

export const EmptyStateUI = ({
  isNotSelected = true,
  showBtn = true,
  title,
  subtitle,
  btnText,
  btnLink,
  btnFn,
}) => {
  // hooks
  const navigate = useNavigate();

  return (
    <Box
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{
        gap: 2,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="95px"
        height="90px"
        padding={1}
        marginBottom={2}
        border="1px solid #A8E7EF30"
        borderRadius="15px"
        sx={{
          backgroundColor: isNotSelected ? "#EA8E4930" : "#A8E7EF60",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={isNotSelected ? SelectionIcon : EmptyIcon}
          alt="Empty icon"
        />
      </Box>

      <Typography variant="h5" style={{ fontWeight: 500 }}>
        {title}
      </Typography>

      <Typography style={{ color: "gray", fontSize: 18 }}>
        {subtitle}
      </Typography>

      {showBtn && btnText && (
        <Button
          onClick={() => (btnLink ? navigate(btnLink) : btnFn ? btnFn() : {})}
          color="primary"
          variant="outlined"
          style={{ marginTop: 6 }}
        >
          {btnText}
        </Button>
      )}
    </Box>
  );
};
