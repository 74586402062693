import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ReactHtmlParser from "html-react-parser";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import ExpandedRow from "../../../components/Shared/ExpandedRow";
import FilterByText from "../../../components/Shared/AppTable/FilterByText";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyGetUniversityListQuery } from "../../../rtkStore/services/universityApi";
import placeholder from "../../../assets/fsw-placeholder.png";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const UniversityListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [searchParams] = useSearchParams();

  const breadcrumbs = [{ title: "Universities" }];

  const universityListTabs = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Global", value: "Global" },
  ];

  // RTK Query
  const [
    listUniversities,
    {
      data: universityList,
      isLoading: loadingUniversities,
      isFetching: fetchingUniversities,
      error,
    },
  ] = useLazyGetUniversityListQuery();

  const filterableUniversities = filterArrayByKeys(
    universityList,
    filterText,
    "title",
    "type.name",
    "headOfficeAddress",
    "assessmentLevel.name"
  );

  const columns = [
    {
      name: "Logo",
      selector: (row) => row?.logo,
      cell: (row) => (
        <img
          src={row?.logo ?? placeholder}
          alt="Logo"
          className="my-1.5 aspect-square w-full rounded border object-contain"
        />
      ),
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/university/${row._id}/detail`}
          title={row?.title}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
        >
          {row?.title}
        </Link>
      ),
    },
    {
      name: "Type",
      selector: (row) => row?.type?.name ?? "N/A",
    },
    {
      name: "Ownership",
      selector: (row) => row?.ownership,
    },
    {
      name: "Office Address",
      selector: (row) => row?.headOfficeAddress,
      cell: (row) => (
        <div title={row?.headOfficeAddress} className="line-clamp-2">
          {row?.headOfficeAddress}
        </div>
      ),
    },
    {
      name: "Assessment Level",
      selector: (row) => row?.assessmentLevel?.name ?? "N/A",
    },
    {
      name: "Created By",
      selector: (row) => row?.createdBy?.name,
      cell: (row) => (
        <div title={row?.createdBy?.name} className="line-clamp-2">
          {row?.createdBy?.name}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/university/${row._id}/detail`}>
            <IconButton size="small" aria-label="View University">
              <InfoOutlined color="primary" />
            </IconButton>
          </Link>

          <Link to={`/app/university/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit University">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  const subHeaderComponent = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="pt-3">
        <FilterByText
          placeholder="Filter Table"
          helperText="Name/Type/Address/Assessment Level"
          value={filterText ?? ""}
          onChange={(e) => setFilterText(e.target.value)}
          handleClear={handleClear}
        />{" "}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const PageActions = () => {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/app/university/create">
          <Button startIcon={<AddIcon />} variant="contained">
            New University
          </Button>
        </Link>
      </div>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    listUniversities({
      active: activeTab !== "Inactive",
      ...(activeTab === "Global" && { scope: "Global" }),
    });
  }, [activeTab, listUniversities]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      hasActions
      headerActions={<PageActions />}
      error={error}
    >
      <CustomTabs tabList={universityListTabs} activeTab={activeTab} />

      <AppTable
        columns={columns}
        data={filterableUniversities}
        progressPending={loadingUniversities || fetchingUniversities}
        subHeader
        subHeaderComponent={subHeaderComponent}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ExpandedRow>{ReactHtmlParser(data.institutionOverview)}</ExpandedRow>
        )}
      />
    </WithHeader>
  );
};

export default UniversityListScreen;
