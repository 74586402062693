import React from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import PublishedWithChangesOutlined from "@mui/icons-material/PublishedWithChangesOutlined";

import AppDialog from "../../../../components/Shared/AppDialog";

import { useCreateClientMutation } from "../../../../rtkStore/services/clientApi";
import { useCreateTransactionMutation } from "../../../../rtkStore/services/transactionApi";
import { useListListQuery } from "../../../../rtkStore/services/listService";
import {
  useAddNoteToLeadMutation,
  useUpdateLeadMutation,
} from "../../../../rtkStore/services/leadApi";
import { useSidebarContext } from "../../../../layouts/SidebarLayout/SidebarContextProvider";
import { useNavigate } from "react-router-dom";

const INITIAL_STATE = {
  leadStage: "",
  transactionStage: "",
  createTransaction: false,
};

const ConvertToClient = ({ lead, showAsButton = false }) => {
  const [showDialog, setShowDialog] = React.useState(false);

  const navigate = useNavigate();

  const { setParty } = useSidebarContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  // RTK Query
  const { data: leadStages } = useListListQuery({
    type: "Lead Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: clientStages } = useListListQuery({
    type: "Client Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const [createClient, { isLoading: creatingClient }] =
    useCreateClientMutation();

  const [addNoteToLead, { isLoading: addingNote }] = useAddNoteToLeadMutation();

  const [createClientTransaction, { isLoading: creatingClientTransaction }] =
    useCreateTransactionMutation();

  const [updatedLead, { isLoading: updatingLead }] = useUpdateLeadMutation();

  const leadCloseGroupStages = leadStages?.filter(
    (ls) => ls?.group === "Closed"
  );

  const clientOpenStages = clientStages?.filter(
    (lead) => lead?.group === "Open"
  );

  // methods
  const handleConfirmLeadQualification = () => {
    if (!lead.qualifiedForClient) {
      toast.warn(`All Qualification Criteria not responded!`);
      showAsButton && navigate("?tab=criteria");
      return;
    }
    setShowDialog(true);
  };

  const handleCancel = () => {
    setShowDialog(false);
    reset({ ...INITIAL_STATE });
  };

  const handleCreateClientTransaction = async (client, stage) => {
    const transactionPayload = {
      client: client?._id,
      title: `${client?.firstName} ${client?.lastName} | ${client?.createdAt}`,
      stage,
    };

    try {
      await createClientTransaction(transactionPayload).unwrap();
      toast.info("Client Transaction created!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create transaction!");
    }
  };

  const handleUpdateLead = async ({ stage, connectedClient }) => {
    try {
      await updatedLead({
        ...lead,
        stage,
        connectedClient,
        converted: true,
      }).unwrap();
      toast.info("Lead successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Lead!");
    }
  };

  const handleQualificationCriteriaAsNote = async () => {
    try {
      const _richNoteContent = `<ol>\n
        ${lead?.criteria?.map(
          (el) =>
            `<li>${el?.question?.name} (${el?.status}) ${
              el?.response?.length ? "- " + el?.response : ""
            }</li>`
        )}\n
      </ol>`;

      const _notePayload = {
        content: _richNoteContent?.replaceAll(",", ""),
        type: "Note",
        _id: lead?._id,
      };
      await addNoteToLead(_notePayload).unwrap();
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't add Qualification Criteria as a note!"
      );
    }
  };

  const handleConvertLeadToClient = async ({
    leadStage,
    transactionStage,
    createTransaction,
  }) => {
    setShowDialog(false);
    try {
      const client = await createClient({
        ...lead,
        lead: lead._id,
      }).unwrap();
      if (!client) throw new Error("Client couldn't be created!");

      toast.success("Lead successfully converted to Client!");
      setParty(true);

      handleUpdateLead({ leadStage, connectedClient: client?._id });

      handleQualificationCriteriaAsNote();

      if (createTransaction)
        handleCreateClientTransaction(client, transactionStage);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't covert lead!");
    }
  };

  return (
    <>
      {updatingLead ||
      creatingClient ||
      creatingClientTransaction ||
      addingNote ? (
        <CircularProgress size={18} style={{ marginRight: 10 }} />
      ) : !showAsButton ? (
        <Tooltip title="Convert to Client" arrow placement="top">
          <IconButton
            disabled={updatingLead || creatingClient}
            onClick={handleConfirmLeadQualification}
          >
            <PublishedWithChangesOutlined color="success" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="outlined"
          color="success"
          disabled={updatingLead || creatingClient}
          onClick={handleConfirmLeadQualification}
        >
          Convert to Client
        </Button>
      )}

      <AppDialog
        maxWidth="xs"
        open={showDialog}
        onClose={handleCancel}
        onConfirm={handleSubmit((data) => handleConvertLeadToClient(data))}
        isBusy={updatingLead || creatingClient || creatingClientTransaction}
        title="Convert Lead to Client?"
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div className="mb-2">
              Are you sure you want to convert{" "}
              <span style={{ fontWeight: 500 }}>
                {`${lead.firstName} ${lead.lastName}`}
              </span>{" "}
              to a<span style={{ fontWeight: 500 }}> Client?</span>
            </div>

            <div className="mb-2 text-center text-xs text-blue-500">
              *Please ensure all lead's fields are filled before this
              conversion!*
            </div>
          </Grid>

          <Grid item md={12}>
            <Controller
              name="leadStage"
              control={control}
              rules={{ required: "Please select lead stage" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-lead-stage">Lead Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-lead-stage"
                    label="Lead Stage"
                    placeholder="Select Lead Stage"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {leadCloseGroupStages?.map((stage) => (
                      <MenuItem key={stage._id} value={stage._id}>
                        {stage?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.leadStage && (
                    <FormHelperText error>
                      {errors.leadStage.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Controller
              name="createTransaction"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="currentlyEmployed"
                      color="primary"
                      size="small"
                      checked={field?.value}
                      {...field}
                    />
                  }
                  label="Create Transaction"
                />
              )}
            />
          </Grid>

          {watch("createTransaction") && (
            <Grid item md={12}>
              <Controller
                name="transactionStage"
                control={control}
                rules={{
                  required:
                    !!watch("createTransaction") &&
                    "Please select transaction stage",
                }}
                render={({ field, value }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-client-stage">
                      Transaction Stage
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="select-client-stage"
                      label="Client Stage"
                      placeholder="Select client Stage"
                      size="small"
                      id="role"
                      value={value ? value : ""}
                      {...field}
                    >
                      {clientOpenStages?.map((stage) => (
                        <MenuItem key={stage._id} value={stage._id}>
                          {stage?.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.transactionStage && (
                      <FormHelperText error>
                        {errors.transactionStage.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          )}
        </Grid>
      </AppDialog>
    </>
  );
};

export default ConvertToClient;
