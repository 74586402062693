import React from "react";
import { useLocation } from "react-router-dom";
import { PagesToDisableSidebar } from "../constants/PagesToDisableSidebar";

// useful on screens where the dashboard sidebar is disabled, and you might want to do something
// for example, it is currently used to show a brand logo in dashboard navbar
export const useIsSidebarVisible = () => {
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    setIsSidebarVisible(
      !PagesToDisableSidebar.some((p) => location.pathname.startsWith(p))
    );
  }, [location.pathname]);

  return {
    isSidebarVisible,
  };
};
