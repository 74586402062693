import React from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { currencyList } from "../../../utils/staticData/currencyList";

import { selectLoggedInUser, setUser } from "../../../rtkStore/authSlice";

import { useAddAccountMutation } from "../../../rtkStore/services/accountApi";
import { useRegisterEntityMutation } from "../../../rtkStore/services/entityApi";
import { useDetailUserProfileMutation } from "../../../rtkStore/services/userApi";
import { usePopulateListOptionsMutation } from "../../../rtkStore/services/listService";

const RegisterAccountEntityScreen = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loggedInUser = useSelector(selectLoggedInUser);

  const planTypes = ["Free", "Enterprise"];
  const frequencyTypes = ["Monthly", "Quaterly", "Annually"];

  const INITIAL_STATE = {
    name: "",
    // account related
    displayName: "",
    assistanceEmail: "",
    assistancePhone: "",
    domain: "",
    status: "Active",
    // entity related
    type: "EntityGeneral",
    isActive: true,
    licenses: 10,
    plan: "Free",
    frequency: "Monthly",
    currency: currencyList[0],
    account: "",
    category: "Organization",
  };

  // RTK Query
  const [addAccount, { isLoading: addingAccount }] = useAddAccountMutation();

  const [populateListOptions, { isLoading: populatingListOptions }] =
    usePopulateListOptionsMutation();

  const [registerEntity, { isLoading: registeringEntity }] =
    useRegisterEntityMutation();

  const [getUserDetail] = useDetailUserProfileMutation();

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: { ...INITIAL_STATE },
  });

  const selectedPlan = React.useRef({});
  selectedPlan.current = watch("plan");
  selectedPlan?.current === "Free" && setValue("licenses", 10);

  // methods
  const handleReset = () => {
    reset({ ...INITIAL_STATE });
  };

  const handlePopulateListOptions = async () => {
    try {
      await populateListOptions().unwrap();
      toast.success("List Options successfully populated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't populate list options!");
    }
  };

  const handleRegisterEntity = async (payload) => {
    try {
      await registerEntity(payload).unwrap();

      const { data: updatedUserDetail } = await getUserDetail(loggedInUser._id);

      dispatch(
        setUser({
          ...updatedUserDetail,
          viewEntity: updatedUserDetail.selectedEntity, //will replace all individual viewEntity fields
          token: loggedInUser.token,
        })
      );

      await handlePopulateListOptions();

      toast.success("Entity successfully registered!");
      navigate("/app/dashboard");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't register entity!");
    }
  };

  const handleAccountEntityRegister = async (data) => {
    const {
      name,
      displayName,
      assistanceEmail,
      assistancePhone,
      domain,
      status,
      type,
      isActive,
      licenses,
      plan,
      frequency,
      currency,
      category,
    } = data;

    const accountPayload = {
      name,
      displayName,
      assistanceEmail,
      assistancePhone,
      domain,
      status,
    };

    const entityPayload = {
      name,
      type,
      isActive,
      licenses,
      plan,
      frequency,
      currency,
      category,
    };

    try {
      const { _id } = await addAccount(accountPayload).unwrap();

      await handleRegisterEntity({
        ...entityPayload,
        accountId: _id,
        user: loggedInUser._id,
        isActive: true,
      });

      toast.success("Account/Entity successfully registered!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add account!");
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={0} variant="outlined">
        <Box paddingX={3} paddingY={2}>
          <div className="text-lg font-semibold">Register Account/Entity</div>
        </Box>

        <Divider />

        <Box padding={4}>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Orginzation Name"
                    placeholder="Enter entity name"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.name && <p className="error">{errors.name.message}</p>}
            </Grid>

            <Grid item md={6}>
              <Controller
                name="displayName"
                control={control}
                rules={{ required: "Display name is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Display Name"
                    placeholder="Enter display name"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.displayName && (
                <p className="error">{errors.displayName.message}</p>
              )}
            </Grid>

            <Grid item md={6}>
              <Controller
                name="assistanceEmail"
                control={control}
                rules={{ required: "Org Email is required." }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Organization Email"
                    placeholder="Enter organization email"
                    size="small"
                    type="email"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.assistanceEmail && (
                <p className="error">{errors.assistanceEmail.message}</p>
              )}
            </Grid>

            <Grid item md={6}>
              <Controller
                name="assistancePhone"
                control={control}
                rules={{ required: "Org Phone is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Organization Phone"
                    placeholder="Enter organization phone"
                    size="small"
                    type="tel"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.assistancePhone && (
                <p className="error">{errors.assistancePhone.message}</p>
              )}
            </Grid>

            <Grid item md={6}>
              <Controller
                name="domain"
                control={control}
                rules={{ required: "Org domain is required." }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Organization Domain"
                    placeholder="Enter organization domain"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              {errors.domain && (
                <p className="error">{errors.domain.message}</p>
              )}
            </Grid>

            <Grid item md={6}></Grid>

            <Grid item md={6}>
              <Controller
                name="currency"
                control={control}
                rules={{ required: "Currency is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="entity-currency"
                    options={currencyList ?? []}
                    getOptionLabel={(option) => `${option.code}-${option.name}`}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Currency"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select currency"
                      />
                    )}
                    value={field?.value}
                    onChange={(e, data) => setValue("currency", data)}
                  />
                )}
              />
              {errors.currency && (
                <p className="error">{errors.currency.message}</p>
              )}
            </Grid>

            <Grid item md={6}>
              <Controller
                name="plan"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-plan">Plan</InputLabel>

                    <Select
                      labelId="select-plan"
                      id="select-plan"
                      size="small"
                      label="Plan"
                      {...field}
                    >
                      {planTypes.map((plan) => (
                        <MenuItem key={plan} value={plan}>
                          {plan}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6}>
              <Controller
                name="frequency"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-frequency">
                      Plan frequency
                    </InputLabel>
                    <Select
                      labelId="select-frequency"
                      id="select-frequency"
                      size="small"
                      label="Plan Frequency"
                      {...field}
                    >
                      {frequencyTypes.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {frequency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item md={6}>
              <Controller
                name="licenses"
                control={control}
                rules={{
                  required: "Plan is required",
                  min: { value: 1, message: "License must be atleast 1" },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    placeholder="Enter license quantity"
                    label="License Quantity"
                    size="small"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    disabled={selectedPlan?.current === "Free"}
                    {...field}
                  />
                )}
              />
              {errors.licenses && (
                <p className="error">{errors.licenses.message}</p>
              )}
            </Grid>

            {/* <Grid item md={6}>
              <Controller
                name="account"
                control={control}
                rules={{ required: "Please select an account" }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="select-account">Account</InputLabel>
                    <Select
                      id="select-account"
                      labelId="select-account"
                      size="small"
                      label="Account"
                      {...field}
                    >
                      {accounts.map((account) => (
                        <MenuItem key={account._id} value={account._id}>
                          {account.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {errors.account && (
                <p className="error">{errors.account.message}</p>
              )}
            </Grid> */}
          </Grid>
        </Box>

        <Box
          paddingX={3}
          paddingY={2}
          gap={2}
          style={{
            display: "flex",
            background: "#fafafa",
          }}
        >
          <Button
            variant="contained"
            disabled={
              registeringEntity || addingAccount || populatingListOptions
            }
            onClick={handleSubmit((data) => handleAccountEntityRegister(data))}
          >
            Register
          </Button>
          <Button
            disabled={
              registeringEntity || addingAccount || populatingListOptions
            }
            onClick={handleReset}
          >
            Reset
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default RegisterAccountEntityScreen;
