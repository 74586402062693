import React from "react";
import { Divider } from "@mui/material";

import DateRangePicker from "../../../components/Shared/DateRangePicker";
import TableLoader from "../../../components/Shared/AppTable/TableLoader";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyGetAgentLeadStatusReportDataQuery } from "../../../rtkStore/services/reportApi";
import { getDateDifference } from "../../../utils/getDateDifference";

const AgentLeadStatusReport = () => {
  const [filterFrom, setFilterFrom] = React.useState(null);
  const [filterTo, setFilterTo] = React.useState(null);

  const breadcrumbs = [
    {
      title: "Agent's Lead Status Report",
    },
  ];

  // methods
  const handleSelectDateRange = (dates) => {
    const [start, end] = dates;
    setFilterFrom(start);
    setFilterTo(end);
  };

  const handleClearDateRange = () => {
    setFilterFrom(null);
    setFilterTo(null);
    fetchAgentLeadStatusReport();
  };

  // RTK Query
  const [
    fetchAgentLeadStatusReport,
    { data: agentLeadData, isLoading, isFetching, error },
  ] = useLazyGetAgentLeadStatusReportDataQuery();

  const leadStatusReportData = React.useMemo(() => {
    const reportData = agentLeadData?.reportData;

    if (!reportData) return [];

    return Object.entries(reportData)
      .map(([responsible, stage]) => ({
        responsible: Object.values(reportData[responsible])[0][0]?.responsible,
        stage,
      }))
      ?.sort((a, b) => (a?.responsible?.name > b?.responsible?.name ? 1 : -1));
  }, [agentLeadData?.reportData]);

  const leadStages = React.useMemo(
    () => agentLeadData?.leadStages ?? [],
    [agentLeadData?.leadStages]
  );

  React.useEffect(() => {
    if (!filterFrom || !filterTo) return;

    const _params = {
      ...(filterFrom &&
        filterTo && {
          filterFrom: new Date(filterFrom).toISOString(),
          filterTo: new Date(filterTo).toISOString(),
        }),
    };

    fetchAgentLeadStatusReport(_params);
  }, [filterFrom, filterTo, fetchAgentLeadStatusReport]);

  React.useEffect(() => {
    fetchAgentLeadStatusReport();
  }, [fetchAgentLeadStatusReport]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={isLoading}
      error={error}
      toggleKey="LEAD_STATUS_REPORT"
      hasActions
      headerActions={
        <DateRangePicker
          label="Lead Created At"
          selected={filterFrom}
          startDate={filterFrom}
          endDate={filterTo}
          onChange={handleSelectDateRange}
          onClear={handleClearDateRange}
          selectsRange
        />
      }
    >
      {!isLoading && !isFetching ? (
        <>
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="font-medium">
                <td className="w-10 border p-2 text-sm"></td>
                <td className="w-60 border p-2 text-sm">Responsible Agent </td>
                {leadStages.map((stage) => (
                  <td
                    key={stage?._id}
                    className="w-52 border p-2 text-center text-sm"
                  >
                    {stage?.name}
                  </td>
                ))}
              </tr>
            </thead>

            <tbody className="border border-gray-200">
              {leadStatusReportData?.length ? (
                leadStatusReportData?.map((row, index) => (
                  <tr key={row?._id}>
                    <td className="border p-2 text-center text-sm">
                      {`${index + 1}.`}
                    </td>
                    <td className="border p-2 text-sm">
                      {row?.responsible?.name}
                    </td>
                    {leadStages?.map(({ name }) => (
                      <LeadStageCell leads={row?.stage[name]} />
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={leadStages?.length + 2}
                    className="border p-4 text-center text-sm"
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <TableLoader />
      )}
    </WithHeader>
  );
};

export default AgentLeadStatusReport;

const LeadStageCell = ({ leads = [] }) => {
  const _totalLeads = leads?.length;

  // const _leadsAgedArray = leads?.map((el) => {
  //   const _leadStage = el?.stage?.name;
  //   const _leadAgedDays = getDateDifference(
  //     _leadStage === "Closed" ? el?.closedOn : new Date(),
  //     new Date(el?.createdAt)
  //   );

  //   return _leadAgedDays;
  // });

  const _leadsAgedDays = leads?.reduce((acc, el) => {
    const _leadStage = el?.stage?.name;
    const _leadAgedDays = getDateDifference(
      _leadStage === "Closed" ? el?.closedOn : new Date(),
      new Date(el?.createdAt)
    );
    return (acc += _leadAgedDays);
  }, 0);

  const _averageAgedDays = Math.round(_leadsAgedDays / _totalLeads);

  return (
    <td className="border p-2 text-center text-sm">
      {_averageAgedDays ? (
        <div className="flex items-center justify-center space-x-2">
          <span
            title={`${_totalLeads} Leads`}
            className="font-medium text-gray-500"
          >
            {" "}
            {_totalLeads}{" "}
          </span>
          <Divider orientation="vertical" flexItem />
          <span title={`${_averageAgedDays} average aged days`}>
            {_averageAgedDays}
          </span>
        </div>
      ) : (
        "--"
      )}
    </td>
  );
};
