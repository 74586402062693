import React from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Button, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";

import AppTable from "../../../components/Shared/AppTable";
import CustomTabs from "../../../components/Shared/CustomTabs";
import TableFilters from "../../../components/Shared/AppTable/TableFilters";
import WithHeader from "../../../layouts/WithHeader";

import { useLazyListApplicationQuery } from "../../../rtkStore/services/applicationApi";
import { selectLoggedInUser } from "../../../rtkStore/authSlice";
import filterArrayByKeys from "../../../utils/filterArrayByKeys";

const ApplicationListScreen = () => {
  const [activeTab, setActiveTab] = React.useState("Active");
  const [showMyApplications, setShowMyApplications] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const [searchParams] = useSearchParams();

  const loggedInUser = useSelector(selectLoggedInUser);

  const breadcrumbs = [{ title: "Applications" }];

  const applicationListTabs = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Link
          to={`/app/application/${row?._id}/detail`}
          title={row?.title}
          className="line-clamp-2 hover:text-blue-600 hover:underline"
        >
          {row?.title ?? "N/A"}
        </Link>
      ),
      minWidth: "250px",
    },
    {
      name: "Client",
      selector: (row) => row?.client?.fullName,
      cell: (row) => (
        <Link
          to={`/app/client/${row?.client?._id}/detail`}
          className="text-blue-600 line-clamp-2 hover:underline"
          title={row?.client?.fullName}
        >
          {row?.client?.fullName}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Stage",
      selector: (row) => row?.stage?.name ?? "N/A",
      sortable: true,
    },
    {
      name: "Priority",
      selector: (row) => row?.priority ?? "N/A",
    },
    {
      name: "Workflows",
      cell: (row) => (
        <>
          {row?.workflows?.length ? (
            <div className="flex flex-col items-center ">
              <Tooltip title={row.workflows[0].title} arrow placement="top">
                <div className="mr-1 w-28 rounded border-2 border-dashed border-white bg-blue-100 px-2 py-0.5 text-center text-xs line-clamp-1">
                  {row.workflows[0].title}
                </div>
              </Tooltip>
              <div className="text-xs text-gray-500">
                {!!(row?.workflows?.length > 1) &&
                  `+ ${row.workflows.length - 1} others`}
              </div>
            </div>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      cell: (row) => moment(row.dueDate).format("MMM Do, YYYY"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link to={`/app/application/${row._id}/detail`}>
            <IconButton size="small" aria-label="Edit Lead" color="primary">
              <InfoOutlinedIcon />
            </IconButton>
          </Link>
          <Link to={`/app/application/${row._id}/edit`}>
            <IconButton size="small" aria-label="Edit Account">
              <ModeEditOutlineTwoToneIcon />
            </IconButton>
          </Link>
        </>
      ),
      ignoreRowClick: true,
      right: true,
    },
  ];

  // RTK Query
  const [
    listApplications,
    {
      data: applicationList,
      isLoading: loadingApplications,
      isFetching: fetchingApplications,
      error,
    },
  ] = useLazyListApplicationQuery();

  const filterableApplications = filterArrayByKeys(
    applicationList,
    filterText,
    "title",
    "client.fullName"
  );

  const filteredApplications = React.useMemo(() => {
    return filterableApplications?.filter((lead) =>
      showMyApplications ? lead?.responsible?._id === loggedInUser?._id : lead
    );
  }, [filterableApplications, loggedInUser?._id, showMyApplications]);

  const PageActions = () => {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/app/application/create">
          <Button startIcon={<AddIcon />} variant="contained">
            New Application
          </Button>
        </Link>
      </div>
    );
  };

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "Active");
  }, [searchParams]);

  React.useEffect(() => {
    listApplications({ active: activeTab !== "Inactive" });
  }, [activeTab, listApplications]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      isBusy={loadingApplications}
      hasActions
      headerActions={<PageActions />}
    >
      <CustomTabs tabList={applicationListTabs} activeTab={activeTab} />
      <TableFilters
        filterText={filterText}
        helperText={"Title/Client Name"}
        onFilterTextChange={(e) => setFilterText(e.target.value)}
        onClearFilterText={() => setFilterText("")}
        showMine={showMyApplications}
        onShowMineChange={(e) => setShowMyApplications(e.target.checked)}
      />

      <AppTable
        columns={columns}
        data={filteredApplications}
        progressPending={loadingApplications || fetchingApplications}
      />
    </WithHeader>
  );
};

export default ApplicationListScreen;
