import React from "react";
import { Avatar, Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { useManageAttachment } from "../../../../hooks/useManageAttachment";
import { ManageAttachment } from "./ManageAttachment";
import { AttachmentHookIntendedForOptions } from "../../../../constants/kbConstants";
import { formatDate } from "../../../../utils/handleFormatDate";

import VisibilityIcon from '@mui/icons-material/Visibility';

export const KBAttachentModalBlog = ({
  article,
  showAuthorInfo = true, }) => {
  const attachmentHookProps = useManageAttachment({
    intendedFor: AttachmentHookIntendedForOptions.KNOWLEDGEBASE,
    modelId: "",
    modelName: "id",
    existingAttachments: article?.files || [],
  });

  return (
    <Box
      key={`article-${article._id}`}
      sx={{
        maxWidth: "100%",
        marginBottom: "12px",
        cursor: "pointer",
      }}
    >
      {showAuthorInfo && (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{ width: 36, height: 36, marginRight: "1rem" }}
            // src="https://images6.alphacoders.com/771/thumb-1920-771840.jpg"
            src={`https://ui-avatars.com/api/?rounded=true&name=${article?.owner?.name}&background=3F51B5&color=fff`}
          />
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 16,
              marginRight: "6px",
            }}
          >
            {article?.owner?.name}
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "#435883",
            }}
          >
            created the article on &nbsp;
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
              color: "#435883",
            }}
          >
            {formatDate(article?.publishedDate)}
          </Typography>
        </Box>
      )}

      <Box marginTop={2}>

        <Box>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 500,
              fontSize: 16,
              color: "#435883",
            }}
          >
            {article?.title}
          </Typography>
          <Typography
            variant="subtitle1"
            className="text-secondary"
            style={{
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            {formatDate(article?.createdAt)}
          </Typography>
          {/* <pre>{JSON.stringify(article, null, 2)}</pre> */}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
          sx={{ gap: "8px" }}
        >
          <Chip size="small" label={article?.status} color={article?.status === 'Active' ? 'primary' : "error"} />

          <Typography
            variant="h6"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "gray",
            }}
          >
            &bull;
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "#3F51B5",
            }}
          >
            V{article.version}
          </Typography>

          <Typography
            variant="h6"
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: "gray",
            }}
          >
            &bull;
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            sx={{ gap: "8px", color: "#435883" }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: 14,
              }}
            >
              Attachment added by
            </Typography>
            <Typography
              variant="subtitle1"
              className="c-label"
              style={{
                fontWeight: "500",
                fontSize: 14,
              }}
            >
              {article?.owner?.name || "_"}
            </Typography>
          </Box>
        </Box>

        <Box marginTop={2}>
          {article?.files?.map(file => (
            <Box sx={{
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #dedede",
              padding: "4px 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              gap: "8px",
              marginBottom: 1
            }}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#435883",
                }}
              >
                {file?.fileName}
              </Typography>

              <Box sx={{
                marginLeft: "auto"
              }}>
                <IconButton size="small" onClick={() => window.open(file?.attachmentUrl, "_blank")}>
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>


        <Box
          marginTop={4}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "400",
              fontSize: 14,
            }}
            className="text-secondary"
          >
            Description
          </Typography>
          {article?.body ? (
            <Box
              sx={{ color: "#435883" }}
              dangerouslySetInnerHTML={{
                __html: article?.body,
              }}
            ></Box>
          ) : (
            <Box
              sx={{ color: "#435883" }}
              dangerouslySetInnerHTML={{
                __html: `<p className="label-color" style="font-style: italic">
                  No body available
                </p>`,
              }}
            ></Box>
          )}
        </Box>

        <Box marginTop={4}>
          <ManageAttachment
            {...attachmentHookProps}
            showEditDescriptionButton={false}
            canUploadFiles={false}
            canDeleteFiles={false}
          />
        </Box>
      </Box>
    </Box>
  );
};
