import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import LeadForm from "./leadComponents/LeadForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetLeadDetailQuery,
  useUpdateLeadMutation,
} from "../../../rtkStore/services/leadApi";

const LeadEditScreen = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const breadcrumbs = [
    { title: "Leads", path: "/app/lead/list" },
    { title: "Edit Lead" },
  ];

  // RTK Query
  const {
    data: leadDetail,
    isLoading: loadingLeadDetail,
    error,
  } = useGetLeadDetailQuery(id);

  const [updateLead, { isLoading: updatingLead }] = useUpdateLeadMutation();

  // methods
  const handleLeadUpdate = async (data) => {
    try {
      await updateLead({ ...data }).unwrap();
      navigate(`/app/lead/${id}/detail`);
      toast.success("Lead successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Lead!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingLeadDetail || updatingLead}
      error={error}
    >
      <LeadForm
        leadDetail={leadDetail}
        isBusy={loadingLeadDetail || updatingLead}
        onSubmit={handleLeadUpdate}
      />
    </WithHeader>
  );
};

export default LeadEditScreen;
