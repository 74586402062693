import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, IconButton } from "@mui/material";
import {
  ArticleOutlined,
  DeleteForeverOutlined,
  PreviewOutlined,
} from "@mui/icons-material";

import InformationTile from "../Shared/InformationTile";

const StudyOptionCard = ({
  studyOption,
  studyOptionsLink,
  onDelete,
  courseCardComponent: CourseCard,
}) => {
  const [showMore, setShowMore] = React.useState(false);

  const visibleCourses = React.useMemo(() => {
    const courseOptions = studyOption?.courses?.length
      ? studyOption?.courses
      : studyOption?.courseOptions;

    return showMore ? courseOptions : courseOptions?.slice(0, 4);
  }, [studyOption, showMore]);

  return (
    <Box display="flex" alignItems="start" gap={2} paddingY={3}>
      <ArticleOutlined
        color="primary"
        fontSize="small"
        style={{ marginTop: 6 }}
      />

      <Box
        flexGrow={1}
        className={`group relative overflow-hidden ${
          studyOption?.courses?.length && "bg-yellow-50" //legacy study option, could be removed in future
        }`}
      >
        <div className="absolute right-5 flex space-x-1">
          <div className="translate-x-full opacity-0 transition-all duration-150 ease-in group-hover:translate-x-0 group-hover:opacity-100">
            <IconButton size="small" onClick={() => onDelete(studyOption?._id)}>
              <DeleteForeverOutlined color="error" />
            </IconButton>
          </div>

          <Link
            to={studyOptionsLink}
            target="_blank"
            title="Generate Study Option"
          >
            <IconButton size="small">
              <PreviewOutlined color="primary" />
            </IconButton>
          </Link>
        </div>

        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item xs={3}>
            <InformationTile
              title="Generation Date"
              subtitle={new Date(
                studyOption?.generatedDate
              ).toLocaleDateString()}
            />
          </Grid>

          <Grid item xs={3}>
            <InformationTile
              title="Responsible"
              subtitle={studyOption?.responsible?.name}
            />
          </Grid>

          <Grid item xs={3}>
            <InformationTile
              title="Created By"
              subtitle={studyOption?.createdBy?.name}
            />
          </Grid>

          <Grid item xs={3}>
            <InformationTile
              title="Created On"
              subtitle={new Date(studyOption?.createdAt).toLocaleDateString()}
            />
          </Grid>

          {CourseCard &&
            visibleCourses?.map((el) => (
              <Grid key={el?._id} item xs={3}>
                <CourseCard
                  course={el.course ?? el}
                  studyOption={studyOption}
                />
              </Grid>
            ))}
        </Grid>

        {CourseCard && studyOption?.courseOptions?.length > 4 && (
          <button
            className="mt-4 block cursor-pointer rounded border border-blue-200 bg-blue-50 px-2 py-1 text-xs font-medium text-blue-500"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore
              ? "Show Less"
              : `+ ${studyOption?.courseOptions?.length - 4} more`}
          </button>
        )}
      </Box>
    </Box>
  );
};

export default StudyOptionCard;
