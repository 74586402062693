import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

const ApplicationProcess = () => {
  return (
    <div>
      <Container maxWidth='xl' sx={{ padding: '1rem 0', marginBottom: '2rem' }}>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          mt={3}
          sx={{ fontSize: 22, fontWeight: 700, textAlign: 'center' }}
        >
          Four Step Student Visa Application Process
        </Typography>

        <Grid
          container
          sx={{
            marginTop: '1rem',
            display: { xs: 'none', md: 'block', lg: 'block' },
          }}
        >
          <Box className='CircleContainer'>
            <Grid item xs={12} lg={3}>
              <Box className='step4Box'>
                <Box className='ApplicationProcessIcons'>
                  <Typography align='center' sx={{ fontSize: 40 }}>
                    1
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='ApplicationProcessIcons'>
                  <Typography align='center' sx={{ fontSize: 40 }}>
                    2
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='ApplicationProcessIcons'>
                  <Typography align='center'>
                    <Typography align='center' sx={{ fontSize: 40 }}>
                      3
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Box'>
                <Box className='ApplicationProcessIcons'>
                  <Typography align='center'>
                    <Typography align='center' sx={{ fontSize: 40 }}>
                      4
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box className='CircleContainer'>
            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Counseling/Study Options
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  School Enrollment
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Visa Lodgement
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Box className='step4Text'>
                <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
                  Visa Grant
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            marginTop: '2rem',
            display: { xs: 'block', md: 'none', lg: 'none' },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                1
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 18, fontWeight: 800, marginLeft: '1rem' }}
              >
                Counseling/Study Options
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                2
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 18, fontWeight: 800, marginLeft: '1rem' }}
              >
                School Enrollment
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                3
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 18, fontWeight: 800, marginLeft: '1rem' }}
              >
                Visa Lodgement
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            display='flex'
            alignContent='center'
          >
            <Box className='stepIcons'>
              <Typography align='center' className='stepText'>
                4
              </Typography>
            </Box>
            <Box className='step4Text'>
              <Typography
                sx={{ fontSize: 18, fontWeight: 800, marginLeft: '1rem' }}
              >
                Visa Granted! Fly anytime
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ApplicationProcess
