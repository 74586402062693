import React from 'react';
import { Box, Modal } from '@mui/material';

export const ModalHeader = ({ children }) => {
  return <Box
    padding={1}
    width={"100%"}
    className="c-modal-header"
  >
    <>
      {children}
    </>
  </Box>;
};

export const ModalBody = ({ children }) => {
  return <Box className={`c-modal-body`} padding={2}>
    <>
      {children}
    </>
  </Box>;
};


export const ModalFooter = ({ children }) => {
  return <Box
    padding={1}
    width={"100%"}
    className="c-modal-footer"
  >
    <>
      {children}
    </>
  </Box>;
};

export const CustomModal = ({ open, onClose, classNames = "auto-height", height, children, ...rest }) => {
  return (
    <Modal open={open} onClose={onClose} {...rest} className={`c-modal ${classNames}`}>
      <Box className="modal-content-wrapper" style={{
        minHeight: height || undefined
      }}>
        <ModalHeader>{children[0]}</ModalHeader>
        <ModalBody>{children[1]}</ModalBody>
        <ModalFooter>{children[2]}</ModalFooter>
      </Box>
    </Modal>
  );
};
