import React from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Divider } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";

import EmptyResult from "./Lottie/EmptyResult";

const AppDraggableList = ({
  isBusy = false,
  listItems = [],
  draggableItem: DraggableItem,
  onDragEnd = () => {},
}) => {
  return listItems?.length ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list-container">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`rounded border-2 transition-colors duration-75 ease-in ${
              snapshot.isDraggingOver
                ? "border-dashed border-green-500 bg-green-50"
                : "border-transparent bg-white"
            }`}
          >
            {listItems?.map((listItem, index) => (
              <Draggable
                key={listItem?._id}
                draggableId={listItem?._id}
                index={index}
                isDragDisabled={isBusy}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`transition-colors duration-75 ease-in ${
                      snapshot.isDragging
                        ? "border-2 border-dashed border-blue-500 bg-blue-50"
                        : ""
                    }`}
                  >
                    <div className="group flex select-none items-center justify-between space-x-4 pl-2 hover:bg-blue-50">
                      <div
                        {...provided.dragHandleProps}
                        className="flex-shrink-0 rounded border border-transparent bg-blue-50 text-gray-400 group-hover:border-blue-200 group-hover:text-blue-500"
                      >
                        <DragIndicator />
                      </div>

                      <div className="flex-grow">
                        <DraggableItem data={listItem} />
                      </div>
                    </div>

                    {index !== listItems?.length - 1 && <Divider />}
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <EmptyResult />
  );
};

export default AppDraggableList;
