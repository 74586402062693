import React from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { Add, ReviewsOutlined } from "@mui/icons-material";

import ReviewForm from "../../../../components/Review/ReviewForm";
import ReviewList from "../../../../components/Review/ReviewList";

import {
  useAddUniversityReviewMutation,
  useUpdateUniversityReviewMutation,
} from "../../../../rtkStore/services/universityApi";

const UniversityReviewsTab = ({ universityReviews = [] }) => {
  const [showReviewForm, setShowReviewForm] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const { id: courseId } = useParams();

  // RTK Query
  const [addUniversityReview, { isLoading: addingUniversityReview }] =
    useAddUniversityReviewMutation();

  const [updateUniversityReview, { isLoading: updatingUniversityReview }] =
    useUpdateUniversityReviewMutation();

  // methods
  const handleCancel = () => {
    setShowReviewForm(false);
    setSelectedReview(null);
  };

  const handleEditReview = (review) => {
    setSelectedReview(review);
    setShowReviewForm(true);
  };

  const handleAddUniversityReview = async (data) => {
    try {
      await addUniversityReview({ courseId, ...data }).unwrap();
      toast.success("Review successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add review!");
    }
  };

  const handleUpdateUniversityReview = async (data) => {
    try {
      await updateUniversityReview({ courseId, ...data }).unwrap();
      toast.success("Review successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update review!");
    }
  };

  return (
    <>
      <Box
        padding={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="inline-flex" gap={1.5}>
          <ReviewsOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>
            University Reviews
          </Typography>
        </Box>

        {!showReviewForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowReviewForm(true)}
          >
            Review
          </Button>
        )}
      </Box>

      <Divider />

      <Collapse in={showReviewForm}>
        <ReviewForm
          reviewDetail={selectedReview}
          isBusy={addingUniversityReview || updatingUniversityReview}
          onSubmit={
            !selectedReview
              ? handleAddUniversityReview
              : handleUpdateUniversityReview
          }
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <ReviewList
        reviews={universityReviews}
        onReviewSelect={handleEditReview}
      />
    </>
  );
};

export default UniversityReviewsTab;
