import React from "react";

import { toast } from "react-toastify";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

import AddCampusForm from "./AddCampusForm";

import {
  useAddUniversityCampusAreaMutation,
  useUpdateUniversityCampusAreaMutation,
} from "../../../../rtkStore/services/universityApi";
import { useParams } from "react-router-dom";
import { Add, EditOutlined, FiberManualRecord } from "@mui/icons-material";

const UniversityCampusAreas = ({ campusAreas = [] }) => {
  const [showCampusForm, setShowCampusForm] = React.useState(false);
  const [selectedCampusArea, setSelectedCampusArea] = React.useState(null);

  const { id: universityId } = useParams();

  // RTK Query
  const [addUniversityCampusArea, { isLoading: addingCampusArea }] =
    useAddUniversityCampusAreaMutation();

  const [updateUniversityCampusArea, { isLoading: updatingCampusArea }] =
    useUpdateUniversityCampusAreaMutation();

  //   methods
  const handleCancel = () => {
    setSelectedCampusArea(null);
    setShowCampusForm(false);
  };

  const handleAddCampus = async (data) => {
    try {
      await addUniversityCampusArea({ _id: universityId, ...data }).unwrap();
      toast.success("Campus Area successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add campus area!");
    }
  };

  const handleEditCampusArea = (campusArea) => {
    setSelectedCampusArea(campusArea);
    setShowCampusForm(true);
  };

  const handleUpdateCampus = async (data) => {
    try {
      await updateUniversityCampusArea({ universityId, ...data }).unwrap();
      toast.success("Campus Area successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update campus area!");
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" gap={1.5}>
          <CorporateFareOutlinedIcon style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Campus Areas</Typography>
        </Box>

        {!showCampusForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowCampusForm(true)}
          >
            Campus Area
          </Button>
        )}
      </Box>

      <Collapse in={showCampusForm}>
        {showCampusForm && (
          <AddCampusForm
            isBusy={addingCampusArea || updatingCampusArea}
            campusDetail={selectedCampusArea}
            onSubmit={selectedCampusArea ? handleUpdateCampus : handleAddCampus}
            onCancel={handleCancel}
          />
        )}
      </Collapse>

      <Divider />

      <Box paddingX={3}>
        {campusAreas.length ? (
          <>
            {campusAreas?.map((ca, index) => (
              <React.Fragment key={index}>
                <Box display="flex" alignItems="center" gap={2} paddingY={3}>
                  <CorporateFareOutlinedIcon
                    color="primary"
                    fontSize="small"
                    style={{ marginTop: 3 }}
                  />

                  <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    gap={1}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography>{ca?.name}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography>{ca?.address}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <div
                        className={
                          ca?.isActive ? "text-green-600" : "text-red-500"
                        }
                      >
                        {ca?.isActive ? "Active" : "Inactive"}
                      </div>
                    </Box>
                  </Box>

                  <IconButton onClick={() => handleEditCampusArea(ca)}>
                    <EditOutlined />
                  </IconButton>
                </Box>
                {index !== campusAreas.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </>
        ) : (
          <Box paddingY={3}>
            <Typography>No Campus Areas</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default UniversityCampusAreas;
