import React from 'react'
import aboutUsImage from '../assets/images/aboutUsImage.png'
import Banner from '../../components/Banner'
import { Container, Grid, Typography } from '@mui/material'
import './AboutScreen.css'
import GlobalBranches from '../../components/GlobalBranches'
import whyUs from '../assets/images/whyUs.png'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import missionImage from '../assets/images/missionImage.jpg'
import visionImage from '../assets/images/visionImage.jpg'

const AboutScreen = () => {
  return (
    <div>
      {/* <Grid
        className='aboutbackground CountryScreenBannerContainer banner-background-filter'
        container
      >
        <Grid item xs={12}>
          <img
            src={aboutUsImage}
            alt='australia'
            className='bannerBackgroundImage'
          ></img>
        </Grid>
      </Grid>
      <Typography variant='h2' className='CountryScreenBannerTitle'>
        We make your dreams come true.
      </Typography> */}
      <Banner
        image={aboutUsImage}
        title={'Who We Are'}
        description={
          'We have been pioneers in overseas education consultation since 2008'
        }
      />
      <Container maxWidth='xl' className='aboutUsContainer'>
        <Typography
          className='ourStoryHeading'
          variant='h6'
          sx={{ marginBottom: '2rem' }}
        >
          Our <span className='ourStoryHeading'>Story</span>
        </Typography>
        {/* <Typography sx={{ marginBottom: "2rem" }}>
          <h1 className='h1Text'>  </h1>
        </Typography> */}
        {/* <Typography sx={{ marginBottom: "1rem" }}>
          <h4 className='h4Text'>
           
          </h4>
        </Typography> */}
        <Typography>
          <p>
            Welcome to our company, First Source World (FSW), a specialized
            technology-driven firm dedicated to solving problems through a blend
            of people and technology solutions.{' '}
          </p>
          <p>
            {' '}
            Our journey began with a group of seven friends from the United Arab
            Emirates (UAE), working together for nearly five years, united by a
            dream of making a positive impact on our loved ones, our community,
            and beyond.
          </p>
          <br></br>
          <p>
            Leaving our successful careers in the UAE, we returned to our
            respective home countries with a determination to study programming
            and sales for two years. As we ventured into e-commerce and IT, we
            discovered the profound impact of providing students with the
            opportunity to study abroad. It was during this time that FSW was
            born.{' '}
          </p>
          <br></br>
          <p>
            FSW's inception started with a single visa lodgment from our own
            group in the Philippines. Today, we process numerous visa
            applications across Southeast Asia. We take pride in being a trusted
            educational agency, partnering with over 100 esteemed colleges and
            institutions in the cities of Australia, Canada, United Kingdom and
            United States.
          </p>
          <br></br>
          <p>
            As our company has grown, so has our team of dedicated education
            counsellors, expanding from the Philippines to various parts of
            Southeast Asia. Our core principle remains unchanged – to provide
            personalized educational counseling, comprehensive study options
            abroad, and unwavering support throughout the school and visa
            application process, all at no additional cost to our students.
          </p>
          <br></br>
          <p>
            FSW's potential was recognized by Ageis Trinity, a private equity
            firm that has invested in our company to support our expansion into
            a multi-country, multi-market agent. With plans to go beyond
            Southeast Asia and expand into Latin America, Africa, the Middle
            East, and South Asia, FSW aims to provide personalized educational
            counseling and support to students and institutions in these
            regions.
          </p>
          <br></br>
          <p>
            Additionally, our operations are strengthened by our partnership
            with GatewayX, a technology company developing marketplace
            technology in the international recruitment space. Together, we will
            leverage innovative solutions to streamline the recruitment process
            and create a seamless platform for students and institutions
            worldwide. With these strategic collaborations, FSW is poised to
            make a global impact and empower students through educational
            opportunities
          </p>
          <br></br>
          <p>
            In our pursuit of excellence, we forge strong relationships with
            top-caliber colleges and institutions, adhering to their
            requirements and policies. By showcasing their best courses and
            promoting them as providers of high-quality education for
            international students, we empower individuals to reach their
            fullest potential.
          </p>
          <br></br>
          <p>
            At our core, we are passionate about making a difference and guiding
            students toward a brighter future through educational opportunities
            abroad. We invite you to join us in our journey of transforming
            lives and creating a positive impact across the globe. Together, we
            can build a brighter tomorrow through the power of education.
          </p>
        </Typography>
      </Container>
      <div className='GrayContainer' style={{ padding: '80px' }}>
        {/* <Container
          className='aboutus-topdiv'
          style={{ padding: '25px' }}
          sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
        >
          <Grid container></Grid>
        </Container>
        <Container
          sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}
          className='aboutus-topdiv'
        >
          <Grid item xs={12} sm={6} md={6} className='img-top-aboutus'>
            <Card
              className='WhatWeDoCard test-content-box'
              style={{
                position: 'relative',
                overflow: 'hidden',
                margin: '0 10px',
                height: 'auto',
              }}
            >
              <CardMedia>
                <img
                  src={whyUs}
                  alt='ausImage'
                  className='AboutUsStudentImage'
                ></img>
              </CardMedia>
              <CardContent>
                <Typography
                  gutterBottom
                  variant='h5'
                  component='div'
                  align='center'
                >
                  Why us?
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  <ul>
                    <li>
                      - Access to 100+ Australian colleges and institution
                      partners
                    </li>
                    <li> - Led and managed by professionals</li>
                    <li> - End to end education and visa services</li>
                    <li> - Fast, secured and reliable</li>
                    <li>
                      - Rigorous quality control in place for your visa success
                    </li>
                    <li>
                      - Pro-destination assist pack in all cities of Australia
                    </li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
        </Container> */}
        <GlobalBranches />
      </div>
      {/* <div className='whiteBackground' style={{ padding: '80px' }}>
        <Container className='aboutus-bottomdiv' style={{ padding: '25px' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='content-bottom-aboutus'
              data-aos='fade-right'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Card className='WhatWeDoCard'>
                <CardContent>
                  <Typography>
                    <h3> Our Mission </h3>
                  </Typography>
                  <Typography>
                    <p>
                      <blockquote>
                        <span className='aboutUsQuote'>
                          Our mission is to discover the best courses that are
                          affordable to you from the top ranked universities and
                          colleges around the world no matter which town you
                          live in.
                        </span>
                      </blockquote>
                    </p>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='img-bottom-aboutus'
              sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <img
                src={missionImage}
                alt='AboutusImage'
                className='AboutUsStudentImage'
              ></img>
            </Grid>
          </Grid>
        </Container>
      </div> */}
      {/* <div className='GrayContainer' style={{ padding: '80px' }}>
        <Container
          className='aboutus-topdiv'
          style={{ padding: '25px' }}
          data-aos='fade-right'
          data-aos-offset='200'
          data-aos-delay='50'
          data-aos-duration='2000'
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='img-top-aboutus'
              sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
            >
              <img
                src={visionImage}
                alt='VisionImage'
                className='VisionImage'
              ></img>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='content-bot-test'
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Card className='WhatWeDoCard'>
                <CardContent>
                  <Typography>
                    <h3> Our Vision </h3>
                  </Typography>
                  <Typography>
                    <p>
                      <blockquote>
                        <span className='aboutUsQuote'>
                          Being the leading international student recruitment
                          agency in the Asia Pacific, recognized for our
                          commitment to student centric approach, integrity, and
                          excellence in service delivery.
                        </span>
                      </blockquote>
                    </p>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div> */}
    </div>
  )
}
export default AboutScreen
