import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AdminForm from "./userComponents/UserForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateAdminMutation } from "../../../rtkStore/services/userApi";

const UserCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Users",
      path: "/app/user/list",
    },
    { title: "New User" },
  ];

  // RTK Query
  const [createAdmin, { isLoading: creatingAdmin, error }] =
    useCreateAdminMutation();

  // methods
  const handleAdminCreate = (data) => {
    createAdmin(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path);
        toast.success("Admin successfully created!");
      })
      .catch(() => toast.error("Admin successfully created!"));
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingAdmin} error={error}>
      <AdminForm isBusy={creatingAdmin} onSubmit={handleAdminCreate} />
    </WithHeader>
  );
};

export default UserCreateScreen;
