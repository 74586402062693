import { Box, Typography } from "@mui/material";
import React from "react";

export const KBTaskScreen = () => {
  return (
    <Box
      width="100%"
      minHeight="300px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
      }}
    >
      <Box textAlign="center">
        <Typography className="label-color" variant="h6">
          QMS Tasks
        </Typography>
        <Typography variant="subtitle1">Coming Soon</Typography>
      </Box>
    </Box>
  );
};
