import React from "react";
import {
  Alert,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { UnfoldMoreOutlined, UnfoldLessOutlined } from "@mui/icons-material";

import PageBreadcrumbs from "../components/Shared/PageBreadcrumbs";
import useSidebarSettings from "../hooks/useToggleSidebar";

const WithHeader = ({
  breadcrumbs = [],
  children,
  toggleKey = null,
  disablePaper = false,
  error = null,
  hasActions = false,
  isBusy = false,
  headerActions,
}) => {
  const { sidebarShown, toggleSidebarSettings } = useSidebarSettings(toggleKey);

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 65,
          zIndex: 12,
          padding: "10px 0",
          backgroundColor: "var(--background)",
          marginBottom: "1rem",
        }}
      >
        <Paper
          elevation={0}
          className="flex items-center justify-between border border-gray-300 p-3 pl-1"
        >
          <div className="flex items-center space-x-4">
            <PageBreadcrumbs breadcrumbs={breadcrumbs} />
            {!!toggleKey && (
              <Tooltip
                arrow
                size=""
                placement="top"
                title={sidebarShown ? "Sidebar Shown" : "Sidebar Hidden"}
              >
                <IconButton size="small" onClick={toggleSidebarSettings}>
                  {sidebarShown ? (
                    <UnfoldLessOutlined
                      color="primary"
                      className="rotate-90 transform"
                    />
                  ) : (
                    <UnfoldMoreOutlined
                      color="primary"
                      className="rotate-90 transform"
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </div>

          {isBusy && <CircularProgress size={24} thickness={5} />}

          {!isBusy && hasActions && headerActions}
        </Paper>
      </div>

      {error && (
        <Alert severity="error" style={{ marginBottom: "1.5rem" }}>
          <div>{error?.data?.message ?? "Something went wrong!"}</div>
        </Alert>
      )}

      <div className="relative">
        {!disablePaper ? (
          <Paper elevation={0} className="border border-gray-300">
            {children}
          </Paper>
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
};

export default WithHeader;
