import React from "react";

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { selectLoggedInUser } from "../../rtkStore/authSlice";

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const loggedInUser = useSelector(selectLoggedInUser);

  if (!loggedInUser) return <Navigate to="/sign-in" />;

  if (loggedInUser && !loggedInUser.selectedEntity)
    return <Navigate to="/app/entity/register" />;

  if (loggedInUser && !loggedInUser.selectedEntity.isActive)
    return <Navigate to="/app/entity/select" />;

  return <Component {...otherProps} />;
};

export default PrivateRoute;
