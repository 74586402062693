import { baseApi } from "./baseApi";

const COURSE_BASE_URL = "/courses";

export const courseApi = baseApi.injectEndpoints({
  tagTypes: ["Course"],

  endpoints: (builder) => ({
    getCourseList: builder.query({
      query: (params) => ({ url: `${COURSE_BASE_URL}/course-list`, params }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Course", _id })),
              { type: "Course", id: "LIST" },
            ]
          : [{ type: "Course", id: "LIST" }],
    }),

    getCourseDetail: builder.query({
      query: (id) => `${COURSE_BASE_URL}/course-edit/${id}`,
      providesTags: (result, error, id) => [
        { type: "Course", id },
        { type: "Course", id: "LIST" },
      ],
    }),

    createCourse: builder.mutation({
      query: (payload) => ({
        url: `${COURSE_BASE_URL}/course-create`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", id: "LIST" },
      ],
    }),

    updateCourse: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/course-edit/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    // course Dates
    addCourseDates: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/dates/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    updateCourseDates: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/dates/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    // course scholarships
    addCourseScholarship: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/scholarships/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    updateCourseScholarship: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/scholarships/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    // course reviews
    addCourseReview: builder.mutation({
      query: (payload) => {
        const { courseId, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/reviews`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),

    updateCourseReview: builder.mutation({
      query: (payload) => {
        const { courseId, _id, ...body } = payload;

        return {
          url: `${COURSE_BASE_URL}/${courseId}/reviews/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Course", _id },
        { type: "Course", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetCourseListQuery,
  useLazyGetCourseListQuery,
  useGetCourseDetailQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,

  useAddCourseDatesMutation,
  useUpdateCourseDatesMutation,

  useAddCourseScholarshipMutation,
  useUpdateCourseScholarshipMutation,

  useAddCourseReviewMutation,
  useUpdateCourseReviewMutation,
} = courseApi;
