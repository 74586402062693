import React from "react";
import { useSearchParams } from "react-router-dom";
import { Badge, Divider, Tab, Tabs } from "@mui/material";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tab-panel-${index}`,
  };
}

const CustomTabs = ({ activeTab, tabList }) => {
  const [_, setSearchParams] = useSearchParams();

  // methods
  const handleOnTabChange = (_, value) => {
    setSearchParams({ tab: value });
  };

  return (
    <div
      style={{ position: "sticky", top: 145, background: "white", zIndex: 2 }}
    >
      <Tabs
        value={activeTab}
        onChange={handleOnTabChange}
        aria-label="lead detail tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabList?.map((tab, index) => (
          <Tab
            key={index}
            label={
              tab?.badgeCount ? (
                <>
                  <Badge
                    badgeContent={tab?.badgeCount ?? 0}
                    color="primary"
                    className="relative"
                  >
                    <div className="mx-2 text-sm">{tab?.label}</div>
                    {tab.enablePulse && tab.badgeCount > 0 && (
                      <span className="absolute -right-2.5 -top-2.5 z-auto inline-flex h-5 w-5 animate-ping rounded-full bg-sky-600 opacity-75"></span>
                    )}
                  </Badge>
                </>
              ) : (
                tab?.label
              )
            }
            value={tab.value}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <Divider />
    </div>
  );
};

export default CustomTabs;
