import { CampaignOutlined } from "@mui/icons-material";

const AgentPromotions = ({ promotions = [] }) => {
  return (
    !!promotions?.length && (
      <div
        title="Agent Promotions"
        className="mr-2 flex flex-shrink-0 items-center space-x-2 rounded border bg-blue-50 px-2 py-0.5 text-blue-500 group-hover:bg-white"
      >
        <CampaignOutlined color="primary" fontSize="small" />

        {promotions
          ?.map((promotion) => (
            <span key={promotion} className="text-xs ">
              {promotion}
            </span>
          ))
          ?.reduce((prev, curr) => [prev, ", ", curr])}
      </div>
    )
  );
};

export default AgentPromotions;
