import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import moment from "moment";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";

import { useAuth } from "../../../../hooks/useAuth";

import { sortedCountryList } from "../../../../utils/staticData/countryList.js";

import { useListListQuery } from "../../../../rtkStore/services/listService";
import { useListAccountEntityUserQuery } from "../../../../rtkStore/services/userApi";
import { useGetTeamListQuery } from "../../../../rtkStore/services/teamApi";

const LeadForm = ({
  leadDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [responsibleSource, setResponsibleSource] = React.useState("User");

  const navigate = useNavigate();

  const selectedFirstPreference = React.useRef({});
  const selectedSecondPreference = React.useRef({});

  const { viewEntity } = useAuth();

  const responsibleSources = ["User", "Team"];

  const civilStatuses = [
    "Single",
    "Married",
    "De Facto",
    "Separated",
    "Widowed",
  ];

  // methods
  const getResponsibleSelectLabel = (responsible) => {
    if (!responsible) return;

    switch (leadDetail?.responsibleSource) {
      case "User":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      case "Team":
        return {
          label: responsible?.name,
          value: responsible?._id,
        };

      default:
        return "";
    }
  };

  const handleResponsibleSourceChange = (e) => {
    setResponsibleSource(e.target.value);

    const currentValues = getValues();
    reset({ ...currentValues, responsible: null });
  };

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: leadDetail?.firstName ?? "",
      lastName: leadDetail?.lastName ?? "",
      email: leadDetail?.email ?? "",
      dob: leadDetail?.dob ?? "",
      phone: leadDetail?.phone ?? "",
      phone2: leadDetail?.phone2 ?? "",
      address: leadDetail?.address ?? "",
      civilStatus: leadDetail?.civilStatus ?? "",
      country: leadDetail?.country ?? null,
      stage: leadDetail?.stage?._id ?? "",
      referralCode: leadDetail?.referralCode ?? "",
      source: leadDetail?.source
        ? { label: leadDetail?.source?.name, value: leadDetail?.source?._id }
        : "",
      firstPreference: leadDetail?.firstPreference
        ? {
            label: leadDetail?.firstPreference?.name,
            value: leadDetail?.firstPreference?._id,
          }
        : "",
      secondPreference: leadDetail?.secondPreference
        ? {
            label: leadDetail?.secondPreference?.name,
            value: leadDetail?.secondPreference?._id,
          }
        : "",
      nextAction: leadDetail?.nextAction?._id ?? "",
      nextActionDate: leadDetail?.nextActionDate ?? "",
      description: leadDetail?.description ?? "",
      responsible: leadDetail?.responsible
        ? getResponsibleSelectLabel(leadDetail?.responsible)
        : "",
      isActive: leadDetail?.isActive ?? true,
    },
  });

  selectedFirstPreference.current = watch("firstPreference");
  selectedSecondPreference.current = watch("secondPreference");

  // RTK Query
  const { data: userList, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  const { data: teamList, isLoading: loadingTeams } = useGetTeamListQuery();

  const { data: leadStages } = useListListQuery({
    type: "Lead Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: leadSources, isLoading: loadingSources } = useListListQuery({
    type: "Lead Source Types",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: leadServices, isLoading: loadingLeadServices } =
    useListListQuery({
      type: "Lead Services",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: nextActionTypes, isLoading: loadingNextActionTypes } =
    useListListQuery({
      type: "Next Action Types",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedLeadServicesList = leadServices?.map((service) => ({
    label: service?.name,
    value: service?._id,
  }));

  const mappedLeadSourcesList = leadSources?.map((source) => ({
    label: source?.name,
    value: source?._id,
  }));

  const secondPreferenceList = mappedLeadServicesList?.filter(
    (service) => service?.value !== selectedFirstPreference.current
  );

  const mappedResponsibleUserList = React.useMemo(() => {
    switch (responsibleSource) {
      case "User":
        return (
          userList?.map((name) => ({
            label: name.name,
            value: name._id,
          })) ?? []
        );

      case "Team":
        return teamList?.map((team) => ({
          label: team?.name,
          value: team?._id,
        }));

      default:
        return [];
    }
  }, [responsibleSource, teamList, userList]);

  // reset second preference field if matches with first preference
  React.useEffect(() => {
    if (selectedFirstPreference.current === selectedSecondPreference.current)
      setValue("secondPreference", "");
  }, [selectedFirstPreference, selectedSecondPreference, setValue]);

  React.useEffect(() => {
    if (!leadDetail) return;

    setResponsibleSource(leadDetail?.responsibleSource ?? "User");

    const selectableResponsible = getResponsibleSelectLabel(
      leadDetail?.responsible
    );

    reset({
      ...leadDetail,
      dob: leadDetail?.dob
        ? moment(leadDetail?.dob).format("YYYY-MM-DD")
        : null,
      stage: leadDetail?.stage?._id ?? null,
      source: leadDetail?.source
        ? { label: leadDetail?.source?.name, value: leadDetail?.source?._id }
        : "",
      firstPreference: leadDetail?.firstPreference
        ? {
            label: leadDetail?.firstPreference?.name,
            value: leadDetail?.firstPreference?._id,
          }
        : "",
      secondPreference: leadDetail?.secondPreference
        ? {
            label: leadDetail?.secondPreference?.name,
            value: leadDetail?.secondPreference?._id,
          }
        : "",
      nextAction: leadDetail?.nextAction?._id ?? null,
      nextActionDate: leadDetail?.nextActionDate ?? null,
      responsible: selectableResponsible,
      isActive: leadDetail?.isActive ?? true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadDetail, reset]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingUsers ||
        loadingTeams ||
        loadingSources ||
        loadingLeadServices ||
        loadingNextActionTypes
      }
    >
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "First Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="First Name, Middle Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.firstName && (
              <p className="error">{errors.firstName.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Last Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Last Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.lastName && (
              <p className="error">{errors.lastName.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="alternativeName"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Alternative Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Email Address"
                  type="email"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Phone"
                  type="tel"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="phone2"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Alternate Phone Number"
                  type="tel"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Date of Birth"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="civilStatus"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-civil-status">Civil Status</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-civil-status"
                    label="Civil Status"
                    placeholder="Select Civil Status"
                    id="role"
                    {...field}
                  >
                    {civilStatuses?.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={sortedCountryList ?? []}
                  getOptionLabel={(option) => `${option.cca2} - ${option.name}`}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country of Passport"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select country"
                    />
                  )}
                  value={field?.value}
                  onChange={(e, data) => setValue("country", data)}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Address"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="firstPreference"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingLeadServices}
                  loadingText="Loading Services..."
                  options={mappedLeadServicesList ?? []}
                  value={field?.value}
                  onChange={(_, data) => setValue("firstPreference", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="First Preference Service"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="secondPreference"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingLeadServices}
                  loadingText="Loading Services..."
                  options={secondPreferenceList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("secondPreference", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Second Preference Service"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-assignee-type">
                Responsible Type
              </InputLabel>
              <Select
                fullWidth
                labelId="select-assignee-type"
                label="Responsible Type"
                placeholder="Select Assignee Type"
                id="role"
                value={responsibleSource}
                onChange={handleResponsibleSourceChange}
              >
                {responsibleSources?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6}>
            <Controller
              name="responsible"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="responsible"
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingTeams || loadingUsers}
                  loadingText="Loading Responsible Users..."
                  options={mappedResponsibleUserList ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("responsible", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Responsible"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="nextAction"
              control={control}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-next-action">Next Action</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-next-action"
                    label="Next Action"
                    placeholder="Select Preference"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {nextActionTypes?.map((action) => (
                      <MenuItem key={action._id} value={action._id}>
                        {action?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.nextAction && (
              <p className="error">{errors.nextAction.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="nextActionDate"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Next Action Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("nextActionDate", e.target.value)}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="stage"
              control={control}
              rules={{ required: "Please select a stage" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-stage">Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-stage"
                    label="Course Level"
                    placeholder="Select Level"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {leadStages?.map((stage) => (
                      <MenuItem key={stage._id} value={stage._id}>
                        {stage?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.stage && <p className="error">{errors.stage.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="source"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingSources}
                  loadingText="Loading Sources..."
                  options={mappedLeadSourcesList ?? []}
                  value={field?.value}
                  onChange={(_, data) => setValue("source", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="referralCode"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Referral Code"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.referralCode && (
              <p className="error">{errors.referralCode.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      value={field?.value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  size="small"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => {
            const payload = { ...data };
            delete payload.criteria;

            const selectedStage = leadStages?.find(
              (el) => el?._id === data?.stage
            );
            const closedAt =
              selectedStage?.group === "Closed" ? new Date() : null;

            onSubmit({
              ...payload,
              stage: data?.stage?.length ? data.stage : null,
              source: data?.source ? data.source?.value : null,
              firstPreference: data?.firstPreference
                ? data.firstPreference?.value
                : null,
              secondPreference: data?.secondPreference
                ? data.secondPreference?.value
                : null,
              nextAction: data?.nextAction?.length ? data.nextAction : null,
              responsible: data?.responsible?.value,
              responsibleSource,
              civilStatus: data?.civilStatus?.length ? data?.civilStatus : null,
              closedAt,
            });
          })}
        >
          {leadDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default LeadForm;
