import React from "react";

import { Link, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ReceiptLongOutlined from "@mui/icons-material/ReceiptLongOutlined";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";

import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";

import { useGetTransactionsByClientQuery } from "../../../../rtkStore/services/transactionApi";

const ClientTransactionsTab = () => {
  const { id } = useParams();

  // RTK Query
  const { data: clientTransactions, isLoading: loadingClientTransactions } =
    useGetTransactionsByClientQuery(id);

  return (
    <Paper elevation={0} variant="outlined">
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge
              badgeContent={clientTransactions?.length}
              color="primary"
              showZero
            >
              <ReceiptLongOutlined style={{ color: "gray" }} />
            </Badge>

            <Typography style={{ fontWeight: 500 }}>Transactions</Typography>
          </Box>

          <Link to={`/app/transaction/create?selectedClient=${id}`}>
            <Button variant="outlined">New Transaction</Button>
          </Link>
        </Box>
      </Box>

      <Divider />

      {!loadingClientTransactions ? (
        <Box paddingX={3}>
          {clientTransactions?.length ? (
            clientTransactions?.map((transaction, index) => (
              <React.Fragment key={transaction._id}>
                <Box paddingY={3}>
                  <Box
                    display="flex"
                    alignItems="start"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <ReceiptLongOutlined
                      color="primary"
                      fontSize="small"
                      style={{ marginTop: 6 }}
                    />

                    <Box
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap={2}>
                          <Typography> {transaction?.title} </Typography>

                          <FiberManualRecord
                            style={{ color: "gray", fontSize: 10 }}
                          />

                          <Typography style={{ color: "var(--primary)" }}>
                            {transaction?.type?.name}
                          </Typography>
                        </Box>

                        <Link
                          to={`/app/transaction/${transaction?._id}/detail`}
                        >
                          <IconButton size="small">
                            <InfoOutlined color="primary" />
                          </IconButton>
                        </Link>
                      </Box>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Typography color="gray">
                          <span className="text-blue-600">
                            {transaction?.notes?.length}
                          </span>{" "}
                          <span>Notes</span>
                        </Typography>

                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 10 }}
                        />

                        <Typography color="gray">
                          <span className="text-blue-600">
                            {transaction?.documents?.length}
                          </span>{" "}
                          <span>Documents</span>
                        </Typography>

                        <FiberManualRecord
                          style={{ color: "gray", fontSize: 10 }}
                        />

                        <Typography color="gray">
                          <span className="text-blue-600">
                            {transaction?.workflows?.length}
                          </span>{" "}
                          <span>Workflows</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {index !== clientTransactions.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <Box paddingY={3}>
              <Typography>No Transactions.</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <RowsSkeleton />
      )}
    </Paper>
  );
};

export default ClientTransactionsTab;
