import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";
import RichTextEditor from "../../../../components/Shared/RichTextEditor";

import { useGetUniversityListQuery } from "../../../../rtkStore/services/universityApi";
import {
  useListGlobalListQuery,
  useLazyListGlobalListQuery,
} from "../../../../rtkStore/services/globalListApi";
import { currencyList } from "../../../../utils/staticData/currencyList";
import { useListListQuery } from "../../../../rtkStore/services/listService";
import { GLOBAL_LIST_CONSTANTS } from "../../../../utils/staticData/globalListOptions";
import { LIST_CONSTANTS } from "../../../../utils/staticData/listOptions";

const INITIAL_STATE = {
  title: "",
  university: "",
  campus: "",
  location: "",
  subLocations: [],
  level: "",
  field: "",
  subfield: "",
  currency: null,
  duration: "",
  languageOfTuition: "",
  tuitionFee: 0,
  feeType: "Semester",
  applicationFee: 0,
  academicRequirement: "",
  englishRequirement: "",
  intake: "",
  reputation: "",
  courseCode: "",
  courseLink: "",
  summary: "",
  learningOutcomes: "",
  addmissionRequirement: {},
  courseDate: {},
  agentPromotions: [],
  priorityLevel: 5,
  tags: [],
  isActive: true,
};

const CourseForm = ({
  courseDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const [expanded, setExpanded] = React.useState("basic");

  const navigate = useNavigate();

  const feeTypes = ["Semester", "Term", "Year", "Course"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedUniversity = watch("university");
  const selectedField = watch("field");
  const selectedLocation = watch("location");

  // RTK Query
  const { data: universityList, isLoading: loadingUniversities } =
    useGetUniversityListQuery();
  const _mappedUniversities = universityList?.map((el) => ({
    label: el?.title,
    value: el?._id,
  }));

  const [
    lazyFetchCourseSubFields,
    { data: courseSubFieldOptions, isFetching: fetchingCourseSubFieldOptions },
  ] = useLazyListGlobalListQuery();

  const [
    lazyFetchSubLocationOptions,
    { data: subLocationOptions, isFetching: fetchingSubLocationOptions },
  ] = useLazyListGlobalListQuery();

  const { data: courseLevels, isLoading: loadingCourseLevels } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_LEVELS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: courseDurations, isLoading: loadingCourseDurations } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_DURATIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const {
    data: academicRequirementOptions,
    isLoading: loadingAcademicRequirements,
  } = useListGlobalListQuery({
    type: GLOBAL_LIST_CONSTANTS.ACADEMIC_REQUIREMENTS,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const {
    data: englishRequirementOptions,
    isLoading: loadingEnglishRequirements,
  } = useListGlobalListQuery({
    type: GLOBAL_LIST_CONSTANTS.ENGLISH_REQUIREMENTS,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: intakeOptions, isLoading: loadingIntakeOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.INTAKES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: reputationOptions, isLoading: loadingReputationOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_REPUTATIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: fieldOptions, isLoading: loadingFieldOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_FIELDS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: locationOptions, isLoading: loadingLocationOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.LOCATIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: tagOptions, isLoading: loadingTagOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.TAGS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: agentPromotionOptions, isLoading: loadingAgentPromotions } =
    useListListQuery({
      type: LIST_CONSTANTS.AGENT_PROMOTIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedTagOptions = tagOptions?.map((el) => ({
    label: el?.name,
    id: el?._id,
  }));

  const mappedAgentPromotionOptions = agentPromotionOptions?.map((el) => ({
    label: el?.name,
    id: el?._id,
  }));

  const campusAreas = React.useMemo(() => {
    if (!universityList?.length) return;

    return universityList?.find((uni) => uni?._id === selectedUniversity?.value)
      ?.campusAreas;
  }, [selectedUniversity, universityList]);

  // methods
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    if (!courseDetail) return;

    reset({
      ...courseDetail,
      university: courseDetail?.university
        ? {
            label: courseDetail?.university?.title,
            value: courseDetail?.university?._id,
          }
        : null,
      level: courseDetail?.level?._id ?? "",
      academicRequirement: courseDetail?.academicRequirement?._id ?? "",
      englishRequirement: courseDetail?.englishRequirement?._id ?? "",
      intakes: courseDetail?.intakes?.map((el) => el?._id) ?? [],
      reputation: courseDetail?.reputation?._id ?? "",
      field: courseDetail?.field?._id ?? "",
      subfield: courseDetail?.subfield?._id ?? "",
      location: courseDetail?.location?._id ?? "",
      subLocations: courseDetail?.subLocations?.map((el) => el?._id) ?? [],
      duration: courseDetail?.duration?._id ?? "",
      agentPromotions:
        courseDetail?.agentPromotions?.map((el) => ({
          label: el?.name,
          id: el?._id,
        })) ?? [],
      tags:
        courseDetail?.tags?.map((el) => ({
          label: el?.name,
          id: el?._id,
        })) ?? [],
    });

    return () => {
      clearErrors();
      reset({ ...INITIAL_STATE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail]);

  React.useEffect(() => {
    if (!selectedField) return;

    const handleFetchSubOptions = async (field) => {
      await lazyFetchCourseSubFields({
        type: field?.name,
        includeChildren: true,
        status: "Active",
      }).unwrap();
    };

    const field = fieldOptions?.find((el) => el?._id === selectedField);
    if (field) handleFetchSubOptions(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, fieldOptions]);

  React.useEffect(() => {
    if (!selectedLocation) return;

    const handleFetchSubLocations = async (field) => {
      await lazyFetchSubLocationOptions({
        type: field?.name,
        includeChildren: true,
        status: "Active",
      }).unwrap();
    };

    const location = locationOptions?.find(
      (el) => el?._id === selectedLocation
    );

    if (location) handleFetchSubLocations(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, subLocationOptions]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingUniversities ||
        fetchingCourseSubFieldOptions ||
        fetchingSubLocationOptions ||
        loadingCourseLevels ||
        loadingCourseDurations ||
        loadingAcademicRequirements ||
        loadingEnglishRequirements ||
        loadingIntakeOptions ||
        loadingReputationOptions ||
        loadingFieldOptions ||
        loadingLocationOptions ||
        loadingAgentPromotions
      }
    >
      {/* Basic Information  */}
      <Accordion
        expanded={expanded === "basic"}
        onChange={handleAccordionChange("basic")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ background: expanded === "basic" ? "#d2e4fc" : "" }}
        >
          <Typography>Basic Course Information</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box padding={3}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Title"
                      type="text"
                      size="small"
                      {...field}
                    />
                  )}
                />
                {errors.title && (
                  <p className="error">{errors.title.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="university"
                  control={control}
                  rules={{ required: "Please select a course" }}
                  render={({ field, onBlur }) => (
                    <Autocomplete
                      autoHighlight
                      onBlur={onBlur}
                      loading={loadingUniversities}
                      loadingText="Loading Universities..."
                      options={_mappedUniversities ?? []}
                      value={field?.value}
                      onChange={(e, data) => setValue("university", data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="University"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          option && (
                            <MenuItem {...props} key={option?.value}>
                              {option?.label}
                            </MenuItem>
                          )
                        );
                      }}
                    />
                  )}
                />
                {errors.university && (
                  <p className="error">{errors.university.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="campusAreas"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-campus">Campus</InputLabel>
                      <Select
                        {...field}
                        fullWidth
                        labelId="select-campus"
                        label="University"
                        placeholder="Select Campus"
                        size="small"
                        id="role"
                        multiple
                        value={field?.value ? [...field?.value] : []}
                      >
                        {campusAreas?.length ? (
                          campusAreas?.map((uni) => (
                            <MenuItem key={uni._id} value={uni._id}>
                              {uni.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Campus found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="location"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-location">Location</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-location"
                        label="Location"
                        placeholder="Select Location"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {locationOptions?.map((loc) => (
                          <MenuItem key={loc._id} value={loc._id}>
                            {loc?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="subLocations"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-subLocations">
                        Sub-Locations
                      </InputLabel>
                      <Select
                        {...field}
                        fullWidth
                        labelId="select-subLocations"
                        label="Sub-Locations"
                        placeholder="Select Sub-Locations"
                        size="small"
                        id="role"
                        multiple
                        value={field?.value ? [...field?.value] : []}
                      >
                        {subLocationOptions?.length ? (
                          subLocationOptions?.map((sl) => (
                            <MenuItem key={sl._id} value={sl._id}>
                              {sl.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Sub-Locations</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  name="level"
                  control={control}
                  rules={{ required: "Please select a level" }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-level">Course Level</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-level"
                        label="Course Level"
                        placeholder="Select Level"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {courseLevels?.map((lvl) => (
                          <MenuItem key={lvl._id} value={lvl._id}>
                            {lvl?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.level && (
                  <p className="error">{errors.level.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="languageOfTuition"
                  control={control}
                  rules={{ required: "Language is required" }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Course Language"
                      type="text"
                      size="small"
                      {...field}
                    />
                  )}
                />
                {errors.languageOfTuition && (
                  <p className="error">{errors.languageOfTuition.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="field"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-field">Course Field</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-field"
                        label="Course Field"
                        placeholder="Select Field"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {fieldOptions?.map((field) => (
                          <MenuItem key={field._id} value={field._id}>
                            {field?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="subfield"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-field">Course Subfield</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-field"
                        label="Course Field"
                        placeholder="Select Field"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        disabled={!selectedField}
                        {...field}
                      >
                        {courseSubFieldOptions?.map((sf) => (
                          <MenuItem key={sf._id} value={sf._id}>
                            {sf?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="duration"
                  control={control}
                  rules={{ required: "Please select course duration" }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-duration">Duration</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-duration"
                        label="Duration"
                        placeholder="Select Duration"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {courseDurations?.map((duration) => (
                          <MenuItem key={duration?._id} value={duration?._id}>
                            {duration?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.duration && (
                  <p className="error">{errors.duration.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="currency"
                  control={control}
                  rules={{ required: "Please select currency" }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="entity-currency"
                      options={currencyList ?? []}
                      getOptionLabel={(option) =>
                        `${option.code}-${option.name}`
                      }
                      size="small"
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Currency"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select currency"
                        />
                      )}
                      value={field?.value}
                      onChange={(e, data) => setValue("currency", data)}
                    />
                  )}
                />
                {errors.currency && (
                  <p className="error">{errors.currency.message}</p>
                )}
              </Grid>

              <Grid item md={4}>
                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <Controller
                      name="tuitionFee"
                      control={control}
                      rules={{ required: "Tuition fee is required" }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Tuition Fee"
                          type="number"
                          size="small"
                          InputProps={{ inputProps: { min: 0, step: 500 } }}
                          {...field}
                        />
                      )}
                    />
                    {errors.tuitionFee && (
                      <p className="error">{errors.tuitionFee.message}</p>
                    )}
                  </div>

                  <div className="w-1/2">
                    <Controller
                      name="feeType"
                      control={control}
                      rules={{ required: "Please select a fee type" }}
                      render={({ field, value }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel id="select-feeType">Per</InputLabel>
                          <Select
                            fullWidth
                            labelId="select-feeType"
                            label="Per"
                            placeholder="Select Fee Type"
                            size="small"
                            id="role"
                            value={value ? value : ""}
                            {...field}
                          >
                            {feeTypes?.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors.feeType && (
                      <p className="error">{errors.feeType.message}</p>
                    )}
                  </div>
                </div>
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="applicationFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Application Fee"
                      type="number"
                      size="small"
                      InputProps={{ inputProps: { min: 0, step: 500 } }}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="academicRequirement"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-requirement">
                        Academic Requirement
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-requirement"
                        label="Academic Requirement"
                        placeholder="Select Requirement"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {academicRequirementOptions?.map((ar) => (
                          <MenuItem key={ar._id} value={ar._id}>
                            {ar?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="englishRequirement"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-requirement">
                        English Requirement
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-requirement"
                        label="English Requirement"
                        placeholder="Select Requirement"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {englishRequirementOptions?.map((ar) => (
                          <MenuItem key={ar._id} value={ar._id}>
                            {ar?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="intakes"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-intake">Intakes</InputLabel>
                      <Select
                        {...field}
                        fullWidth
                        labelId="select-intake"
                        label="Intake"
                        placeholder="Select Intake"
                        size="small"
                        id="role"
                        multiple
                        value={field?.value ? [...field?.value] : []}
                      >
                        {intakeOptions?.map((ci) => (
                          <MenuItem key={ci._id} value={ci._id}>
                            {ci?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="reputation"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-reputation">Reputation</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-reputation"
                        label="Reputation"
                        placeholder="Select reputation"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {reputationOptions?.map((ci) => (
                          <MenuItem key={ci._id} value={ci._id}>
                            {ci?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="courseCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Course Code"
                      type="text"
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="agentPromotions"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      id="agentPromotions"
                      autoHighlight
                      loading={loadingAgentPromotions}
                      loadingText="Loading Agent Promotions..."
                      options={mappedAgentPromotionOptions ?? []}
                      onChange={(e, data) => setValue("agentPromotions", data)}
                      value={field?.value ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Agent Promotion"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          option && (
                            <MenuItem {...props} key={option.id}>
                              {option?.label}
                            </MenuItem>
                          )
                        );
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="courseLink"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Course Link"
                      type="text"
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="priorityLevel"
                  control={control}
                  render={({ field, value }) => (
                    <>
                      <Typography variant="body2" className="text-gray-500">
                        Priority Level
                      </Typography>

                      <Slider
                        aria-label="Priority Level"
                        defaultValue={30}
                        getAriaValueText={value}
                        valueLabelDisplay="on"
                        step={1}
                        marks
                        min={1}
                        max={10}
                        size="small"
                        {...field}
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      id="tags"
                      autoHighlight
                      loading={loadingTagOptions}
                      loadingText="Loading Tags..."
                      options={mappedTagOptions ?? []}
                      onChange={(e, data) => setValue("tags", data)}
                      value={field?.value ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tags"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          option && (
                            <MenuItem {...props} key={option.id}>
                              {option?.label}
                            </MenuItem>
                          )
                        );
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field, value }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          name="isActive"
                          color="primary"
                          checked={field.value}
                          value={value}
                        />
                      }
                      label="Is Active?"
                    />
                  )}
                />
              </Grid>

              <Grid item md={12}>
                <div
                  style={{
                    color: "gray",
                    fontSize: 15,
                    marginBottom: ".75rem",
                  }}
                >
                  Course Summary
                </div>
                <Controller
                  name="summary"
                  control={control}
                  rules={{ required: "Summary is required" }}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.summary
                          ? courseDetail.summary
                          : "<p>Enter Course Summary.</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
                {errors.summary && (
                  <p className="error">{errors.summary.message}</p>
                )}
              </Grid>

              <Grid item md={12}>
                <div
                  style={{
                    color: "gray",
                    fontSize: 15,
                    marginBottom: ".75rem",
                  }}
                >
                  Learning Outcomes
                </div>
                <Controller
                  name="learningOutcomes"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.learningOutcomes
                          ? courseDetail.learningOutcomes
                          : "<p>Enter Learning Outcomes.</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Admission Requirement  */}
      <Accordion
        expanded={expanded === "admission"}
        onChange={handleAccordionChange("admission")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ background: expanded === "admission" ? "#d2e4fc" : "" }}
        >
          <Typography>Admission Information</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box padding={3}>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <div
                  style={{
                    color: "gray",
                    fontSize: 15,
                    marginBottom: ".75rem",
                  }}
                >
                  Admission Requirement
                </div>
                <Controller
                  name="addmissionRequirement.addmissionRequirement"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.addmissionRequirement
                          ? courseDetail?.addmissionRequirement
                              ?.addmissionRequirement
                          : "<p>Enter Addmission Requirement.</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>

              <Grid item md={12}>
                <div
                  style={{
                    color: "gray",
                    fontSize: 15,
                    marginBottom: ".75rem",
                  }}
                >
                  Language Requirement
                </div>
                <Controller
                  name="addmissionRequirement.englishRequirement"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.addmissionRequirement
                          ? courseDetail?.addmissionRequirement
                              ?.englishRequirement
                          : "<p>Enter Language Requirement.</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Course date summary  */}
      <Accordion
        expanded={expanded === "dates"}
        onChange={handleAccordionChange("dates")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ background: expanded === "dates" ? "#d2e4fc" : "" }}
        >
          <Typography>Course Dates Summary</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box padding={3}>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Controller
                  name="courseDateSummary"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.courseDateSummary
                          ? courseDetail?.courseDateSummary
                          : "<p>Enter Course Dates Summary</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Scholarship Information */}
      <Accordion
        expanded={expanded === "scholarship"}
        onChange={handleAccordionChange("scholarship")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          style={{ background: expanded === "scholarship" ? "#d2e4fc" : "" }}
        >
          <Typography>Scholarship Summary</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box padding={3}>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Controller
                  name="scholarshipSummary"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RichTextEditor
                      initialValue={
                        courseDetail?.scholarshipSummary
                          ? courseDetail?.scholarshipSummary
                          : "<p>Enter Scholarship Summary</p>"
                      }
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button
          disabled={
            isBusy ||
            fetchingCourseSubFieldOptions ||
            fetchingSubLocationOptions
          }
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              academicRequirement: data?.academicRequirement?.length
                ? data?.academicRequirement
                : null,
              englishRequirement: data?.englishRequirement?.length
                ? data?.englishRequirement
                : null,
              intake: data?.intake?.length ? data?.intake : null,
              reputation: data?.reputation?.length ? data?.reputation : null,
              location: data?.location?.length ? data?.location : null,
              field: data?.field?.length ? data?.field : null,
              subfield: data?.subfield?.length ? data?.subfield : null,
              university: data?.university?.value ?? null,
              agentPromotions: data?.agentPromotions?.map((el) => el?.id) ?? [],
              tags: data?.tags?.map((el) => el?.id) ?? [],
            })
          )}
        >
          {courseDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default CourseForm;
