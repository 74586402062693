import React from "react";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { BookmarkAddOutlined, InfoOutlined } from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";

import AgentPromotions from "../../../../../components/Course/AgentPromotionsBadge";
import PriorityBadge from "../../../../../components/Course/PriorityBadge";

import placeholderImage from "../../../../../assets/fsw-placeholder.png";

import { useAddFavouriteCourseToClientMutation } from "../../../../../rtkStore/services/clientApi";

const CourseSearchCard = ({ course }) => {
  const [searchParams] = useSearchParams();

  const clientRefId = React.useMemo(
    () => searchParams.get("ref"),
    [searchParams]
  );

  const universityHasLogo = React.useMemo(() => {
    return !!course?.university?.logo?.length;
  }, [course]);

  // RTK Query
  const [addFavouriteCourseToClient, { isLoading: addingClientFavCourse }] =
    useAddFavouriteCourseToClientMutation();

  // methods
  const handleAddToClientFavourite = async () => {
    if (!clientRefId) return;

    try {
      await addFavouriteCourseToClient({
        clientId: clientRefId,
        favouriteCourse: { course: course?._id },
      }).unwrap();
      toast.success(
        `Course added to ${searchParams.get("name")}'s favourites!`
      );
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't add course to client's favourite!"
      );
    }
  };

  return (
    <div
      variant="outlined"
      className="group flex flex-col space-y-4 rounded border bg-white p-4 hover:border-blue-300 hover:bg-blue-50"
    >
      <div className="flex w-full space-x-4">
        <div className="h-[110px] w-[110px] flex-shrink-0">
          <img
            src={
              universityHasLogo ? course?.university?.logo : placeholderImage
            }
            alt={course?.university?.title ?? "Logo"}
            className={`flex aspect-square w-full cursor-pointer items-center rounded border object-contain text-center text-sm group-hover:border-blue-300 ${
              !universityHasLogo && "grayscale invert"
            }`}
            onClick={() =>
              window.open(
                `/app/university/${course?.university?._id}/detail`,
                "_blank"
              )
            }
          />
        </div>

        <div className="flex flex-grow flex-col space-y-1">
          <div className="-mb-1 flex items-center justify-between space-x-2">
            <div className="flex-grow">
              <Link to={`/app/course/${course?._id}/detail`} target="_blank">
                <div className="cursor-pointer font-semibold text-blue-600 line-clamp-1 group-hover:underline">
                  {course?.title}
                </div>
              </Link>
            </div>

            <div className="flex-shrink-0">
              <AgentPromotions
                promotions={course?.agentPromotions?.map((el) => el?.name)}
              />
            </div>

            <PriorityBadge level={course?.priorityLevel} />

            {clientRefId &&
              (!addingClientFavCourse ? (
                <Tooltip title="Add to Favourite" arrow placement="top">
                  <IconButton size="small" onClick={handleAddToClientFavourite}>
                    <BookmarkAddOutlined color="primary" />
                  </IconButton>
                </Tooltip>
              ) : (
                <CircularProgress size={22} />
              ))}

            <IconButton
              size="small"
              onClick={() =>
                window.open(`/app/course/${course?._id}/detail`, "_blank")
              }
            >
              <InfoOutlined color="primary" />
            </IconButton>
          </div>

          <div className="flex items-center space-x-2 font-medium">
            {!!course?.location && (
              <div className="text-sm">{course?.location?.name}</div>
            )}

            {!!course?.subLocations?.length &&
              course?.subLocations?.map((subLoc) => (
                <React.Fragment key={subLoc?._id}>
                  <span className="text-sm">•</span>
                  <div className="text-sm ">{subLoc?.name}</div>
                </React.Fragment>
              ))}
          </div>

          <div className="flex items-center space-x-2 font-medium">
            {course?.duration && (
              <div className="text-sm">{course?.duration?.name}</div>
            )}

            {course?.level && (
              <div className="text-sm text-gray-600">
                {course?.level?.name} Level
              </div>
            )}

            <div
              onClick={() =>
                window.open(
                  `/app/university/${course?.university?._id}/detail`,
                  "_blank"
                )
              }
              className="cursor-pointer group-hover:text-blue-600"
            >
              <span className="text-sm">@</span>
              <span className="text-sm">{course?.university?.title}</span>
            </div>

            {course?.university?.assessmentLevel && (
              <div
                className="rounded border border-emerald-200 bg-emerald-50 px-2 py-1 text-xs tracking-wider text-emerald-500"
                title="Assessment Level"
              >
                {course?.university?.assessmentLevel?.name}
              </div>
            )}
            {course?.university?.priority && (
              <div
                className="rounded border border-orange-200 bg-orange-50 px-2 py-1 text-xs tracking-wider text-orange-500"
                title="Priority"
              >
                {course?.university?.priority?.name}
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2 font-medium">
            <div className="text-sm text-green-600">
              {course?.currency?.symbol ?? ""}
            </div>

            <div className="text-sm">{course?.tuitionFee}</div>

            <div className="text-sm">
              {course?.currency ? `(${course?.currency?.code})` : ""}{" "}
            </div>

            <div>
              <span className="text-sm text-gray-500">per</span>{" "}
              <span className="text-sm">{course?.feeType}</span>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="text-sm line-clamp-2">
        {ReactHtmlParser(course.summary)}
      </div>

      {!!course?.tags?.length && (
        <div className="flex items-center space-x-2">
          {course?.tags?.map((el) => (
            <div
              key={el?._id}
              className="rounded  bg-blue-500 px-2 py-1 text-xs font-medium text-blue-50"
            >
              {el?.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseSearchCard;
