import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import LeadForm from "./leadComponents/LeadForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateLeadMutation } from "../../../rtkStore/services/leadApi";

const LeadCreateScreen = () => {
  const breadcrumbs = [
    { title: "Leads", path: "/app/lead/list" },
    { title: "New Lead" },
  ];

  let navigate = useNavigate();

  // RTK Query
  const [createLead, { isLoading: creatingLead, error }] =
    useCreateLeadMutation();

  // methods
  const handleLeadCreate = async (data) => {
    try {
      const { _id } = await createLead({ ...data }).unwrap();
      toast.success("Lead successfully created!");
      navigate(`/app/lead/${_id}/detail`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create Lead!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingLead} error={error}>
      <LeadForm isBusy={creatingLead} onSubmit={handleLeadCreate} />
    </WithHeader>
  );
};

export default LeadCreateScreen;
