import React from "react";

import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { currencyList } from "../../../utils/staticData/currencyList";

import WithHeader from "../../../layouts/WithHeader";

import { useAuth } from "../../../hooks/useAuth";
import {
  useDetailEntityQuery,
  useEditEntityMutation,
} from "../../../rtkStore/services/entityApi";

const EditEntityScreen = () => {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const entityId = loggedInUser?.viewEntity?.entity?._id;

  const breadcrumbs = [
    { title: "Entity Info", path: "/app/entity/detail" },
    { title: "Edit Entity" },
  ];

  const INITIAL_STATE = {
    name: "",
    currency: currencyList[0],
  };

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: { ...INITIAL_STATE },
  });

  //   RTK Query
  const {
    data: entityDetail,
    isLoading: loadingEntityDetail,
    error,
  } = useDetailEntityQuery(entityId);

  const [updateEntity, { isLoading: updatingEntity }] = useEditEntityMutation();

  //   methods
  const handleUpdateEntity = async (data) => {
    try {
      await updateEntity({
        ...data,
        id: entityId,
      }).unwrap();
      toast.success("Entity Detail successfully updated!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update entity!");
    }
  };

  React.useEffect(() => {
    if (!entityDetail) return;

    reset({ name: entityDetail?.name, currency: entityDetail?.currency });
  }, [entityDetail, reset]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingEntityDetail || updatingEntity}
      error={error}
    >
      <Box padding={3}>
        <Grid container spacing={5}>
          <Grid item md={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Orginzation/Personal Name"
                  placeholder="Enter entity name"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={6}>
            <Controller
              name="currency"
              control={control}
              rules={{ required: "Currency is required" }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="entity-currency"
                  options={currencyList ?? []}
                  getOptionLabel={(option) => `${option.code}-${option.name}`}
                  size="small"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select currency"
                    />
                  )}
                  value={field?.value}
                  onChange={(e, data) => setValue("currency", data)}
                />
              )}
            />
            {errors.currency && (
              <p className="error">{errors.currency.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button
          disabled={loadingEntityDetail || updatingEntity}
          onClick={() => navigate(breadcrumbs[0]?.path)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loadingEntityDetail || updatingEntity}
          onClick={handleSubmit((data) => handleUpdateEntity(data))}
        >
          Update
        </Button>
      </Box>
    </WithHeader>
  );
};

export default EditEntityScreen;
