import React from "react";

const StagePathwayBar = ({
  stages = [],
  currentStage = null,
  onStageChange = () => {},
}) => {
  const [activeState, setActiveStage] = React.useState("");

  let sortedStages = React.useMemo(() => {
    return [...stages]?.sort((a, b) =>
      b.serialNumber > a.serialNumber ? -1 : 1
    );
  }, [stages]);

  const statusNames = sortedStages?.map((stage) => stage?.name) ?? [];

  // methods
  const getActiveStageStyles = (index) => {
    const defaultStyles = "text-sm px-1";
    const activeStyles =
      "bg-blue-500 px-2 py-1 text-sm text-blue-50 shadow tracking-widest rounded-sm";

    if (!activeState) return defaultStyles;

    return activeState === statusNames[index] ? activeStyles : defaultStyles;
  };

  const handleStageChange = (stageIndex) => {
    setActiveStage(statusNames[stageIndex]);
    onStageChange(sortedStages[stageIndex]);
  };

  React.useEffect(() => {
    if (!currentStage) return false;
    setActiveStage(currentStage);
  }, [currentStage]);

  return (
    <div className="flex h-8  w-full items-center justify-between py-2">
      {statusNames.map((stage, index) => (
        <React.Fragment key={index}>
          <div
            className="inline-block flex-shrink-0 cursor-pointer text-gray-500"
            onClick={() => handleStageChange(index)}
          >
            <div
              className={`font-medium transition-all duration-200  ease-in ${getActiveStageStyles(
                index
              )}`}
            >
              {stage}
            </div>
          </div>
          {index !== statusNames.length - 1 && (
            <div className="w-full flex-grow border-t border-gray-300" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StagePathwayBar;
