import React from "react";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
  label = "Date Range",
  placeholder = "Select Date Range",
  startDate,
  endDate,
  onChange,
  onClear,
  ...otherProps
}) => {
  return (
    <div className="relative rounded border border-gray-300 px-2 py-0.5">
      <div className="absolute -top-2.5 z-10 bg-white px-1 text-[12px] text-gray-500">
        {label}
      </div>

      <div className="flex items-center">
        <ReactDatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          selectsRange
          placeholderText={placeholder}
          customInput={<DatePickerInput />}
          {...otherProps}
        />

        <IconButton disabled={!startDate || !endDate} onClick={onClear}>
          <Clear fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default DateRangePicker;

const DatePickerInput = React.forwardRef(
  ({ value, onClick, placeholder }, ref) => {
    return (
      <div
        ref={ref}
        className="w-52 min-w-full cursor-pointer px-1 text-gray-500"
        onClick={onClick}
      >
        {value ? value : placeholder}
      </div>
    );
  }
);
