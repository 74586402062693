import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import React from "react"
import ApplicationProcess from "./ApplicationProcess"

import popularCourse1 from "../screens/assets/images/popularCourse1.png"
import popularCourse2 from "../screens/assets/images/popularCourse2.png"
import popularCourse3 from "../screens/assets/images/popularCourse3.png"
import popularCourse4 from "../screens/assets/images/popularCourse4.png"
import popularCourse5 from "../screens/assets/images/popularCourse5.png"
import popularCourse6 from "../screens/assets/images/popularCourse6.png"

const courseOptions = [
  { image: popularCourse1, title: "Construction" },
  { image: popularCourse2, title: "Hospitality" },
  { image: popularCourse3, title: "Engineering" },
  { image: popularCourse4, title: "Aged Care" },
  { image: popularCourse5, title: "IT" },
  { image: popularCourse6, title: "Teaching" },
]

const PopularCourses = ({ city, cityId, countryId }) => {
  return (
    <div>
      <Box className='step4Background' sx={{ marginTop: "4rem" }}>
        <Container maxWidth='xl' sx={{ marginTop: 3 }}>
          <Typography
            gutterBottom
            variant='h5'
            component='div'
            mb={3}
            sx={{
              fontSize: 22,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Here are the popular courses in {city}
          </Typography>

          <Grid
            container
            spacing={5}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            {courseOptions.map((item) => (
              <Grid
                key={item}
                item
                xs={12}
                sm={12}
                md={4}
                data-aos='fade-left'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='2000'
              >
                <Card>
                  <a
                    href={`https://fsw.firstsourceworld.com/public/courses/?keyword=${item.title}&location=${countryId}&subLocations=${cityId}&university=&page=1&size=10`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    <CardActionArea>
                      <CardMedia
                        component='img'
                        height='80%'
                        image={item.image}
                        alt='green iguana'
                      />

                      <CardContent
                        style={{ height: "100%", padding: "5px 5px" }}
                      >
                        <div className='py-1.5 text-center  font-medium'>
                          <Typography
                            sx={{ fontSize: 20, fontWeight: 600, my: 0.5 }}
                          >
                            {item.title} Courses
                          </Typography>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </a>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <ApplicationProcess />
    </div>
  )
}

export default PopularCourses
