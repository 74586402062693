import React from "react";

import { Route, Routes } from "react-router-dom";
import TeamCreateScreen from "../screens/Admin/Team/TeamCreateScreen";
import TeamDetailScreen from "../screens/Admin/Team/TeamDetailScreen";
import TeamEditScreen from "../screens/Admin/Team/TeamEditScreen";
import TeamListScreen from "../screens/Admin/Team/TeamListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const TeamRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<TeamCreateScreen />} />
      <Route path=":id/edit" element={<TeamEditScreen />} />
      <Route path=":id/detail" element={<TeamDetailScreen />} />
      <Route path="list" element={<TeamListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default TeamRouter;
