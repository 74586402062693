import React from "react"
import { Container, Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import testBanner from "../assets/images/testBanner.png"
import ieltsImage from "../assets/images/ieltsImage.png"
import pte from "../assets/images/pte.png"
import toefl from "../assets/images/toefl.png"

const TestPreparationScreen = () => {
  return (
    <>
      <Grid
        className='aboutbackground CountryScreenBannerContainer banner-background-filter'
        container
      >
        <Grid item xs={12}>
          <img
            src={testBanner}
            alt='testBanner'
            className='bannerBackgroundImage'
          ></img>
        </Grid>
      </Grid>
      <Typography variant='h2' className='CountryScreenBannerTitle'>
        Test Preparation
      </Typography>
      <div className='GrayContainer'>
        <Container
          className='test-topdiv'
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='img-top-test'
              data-aos='fade-right'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <img
                src={ieltsImage}
                alt='ieltsImage'
                className='AboutUsStudentImage'
              ></img>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='content-top-test'
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Card className='WhatWeDoCard test-content-box'>
                <CardContent className='test-content'>
                  <Typography
                    gutterBottom
                    variant='h4'
                    component='div'
                    align='center'
                  >
                    IELTS
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    International Language Tests (IELTS) is one of the widely
                    accepted English language tests around the globe for work,
                    migration, and study. Academic and General Training are the
                    two IELTS test types.
                  </Typography>
                  <br></br>
                  <Typography variant='body2' color='text.secondary'>
                    IELTS Academic is recommended for studying in an English
                    speaking country whereas for migration and work IELTS
                    General Training is recommended. During your IELTS test your
                    language proficiency in Listening, Reading, Writing, and
                    Speaking are evaluated. IELTS score is graded on a scale of
                    1-9.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container
          className='test-bottomdiv'
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='content-bottom-test'
              data-aos='fade-right'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Card className='WhatWeDoCard test-content-box'>
                <CardContent className='test-content'>
                  <Typography
                    gutterBottom
                    variant='h4'
                    component='div'
                    align='center'
                  >
                    PTE
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Pearson Language Tests (PTE) is a computer-based English
                    language test popular due to its convenient and fast score
                    reporting. PTE is used as a standardized test by
                    institutions over the UK, visas, and immigration categories
                    of Australia and New Zealand.
                  </Typography>
                  <br></br>
                  <Typography variant='body2' color='text.secondary'>
                    Test dates can be scheduled 24 hours in advance, 365 days a
                    year. Three parts of the test: Writing and Speaking
                    (together), Reading and Listening are assessed in 3 hours
                    test session. Test scores are published within 48 hours of
                    taking the test.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='img-bottom-test'
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <img
                src={pte}
                alt='pteImage'
                className='AboutUsStudentImage'
              ></img>
            </Grid>
          </Grid>
        </Container>
        <Container
          className='test-topdiv'
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='img-top-test'
              data-aos='fade-right'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <img
                src={toefl}
                alt='tofelImage'
                className='AboutUsStudentImage'
              ></img>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className='content-top-test'
              data-aos='fade-left'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='2000'
            >
              <Card className='WhatWeDoCard test-content-box'>
                <CardContent className='test-content'>
                  <Typography
                    gutterBottom
                    variant='h4'
                    component='div'
                    align='center'
                  >
                    TOEFL iBT
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Test of English as a Foreign Language (TOEFL iBT) is the
                    choice of 30 million people worldwide, accepted in more than
                    150 countries and 11,000 universities. It is accepted and
                    preferred by universities, for you to know real experience
                    of English required in the classroom.
                  </Typography>
                  <br></br>
                  <Typography variant='body2' color='text.secondary'>
                    6 days after the test your scores are ready in your ETS
                    Account. Scoring will be based on the 4 scaled section
                    scores and a total score ranging (0-120).
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{ display: { xs: "block", md: "none", lg: "none" } }}
          className='aboutus-topdiv'
        >
          <Grid item xs={12} sm={6} md={6} className='img-top-aboutus'>
            <Card
              className='WhatWeDoCard test-content-box'
              style={{
                position: "relative",
                overflow: "hidden",
                margin: "0 10px",
                height: "auto",
              }}
            >
              <CardMedia>
                <img
                  src={ieltsImage}
                  alt='ausImage'
                  className='AboutUsStudentImage'
                ></img>
              </CardMedia>
              <CardContent className='test-content'>
                <Typography
                  gutterBottom
                  variant='h4'
                  component='div'
                  align='center'
                >
                  IELTS
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  International Language Tests (IELTS) is one of the widely
                  accepted English language tests around the globe for work,
                  migration, and study. Academic and General Training are the
                  two IELTS test types.
                </Typography>
                <br></br>
                <Typography variant='body2' color='text.secondary'>
                  IELTS Academic is recommended for studying in an English
                  speaking country whereas for migration and work IELTS General
                  Training is recommended. During your IELTS test your language
                  proficiency in Listening, Reading, Writing, and Speaking are
                  evaluated. IELTS score is graded on a scale of 1-9.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Container>
        <Container
          sx={{ display: { xs: "block", md: "none", lg: "none" } }}
          className='aboutus-topdiv'
        >
          <Grid item xs={12} sm={6} md={6} className='img-top-aboutus'>
            <Card
              className='WhatWeDoCard test-content-box'
              style={{
                position: "relative",
                overflow: "hidden",
                margin: "0 10px",
                height: "auto",
              }}
            >
              <CardMedia>
                <img
                  src={pte}
                  alt='ausImage'
                  className='AboutUsStudentImage'
                ></img>
              </CardMedia>
              <CardContent className='test-content'>
                <Typography
                  gutterBottom
                  variant='h4'
                  component='div'
                  align='center'
                >
                  PTE
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Pearson Language Tests (PTE) is a computer-based English
                  language test popular due to its convenient and fast score
                  reporting. PTE is used as a standardized test by institutions
                  over the UK, visas, and immigration categories of Australia
                  and New Zealand.
                </Typography>
                <br></br>
                <Typography variant='body2' color='text.secondary'>
                  Test dates can be scheduled 24 hours in advance, 365 days a
                  year. Three parts of the test: Writing and Speaking
                  (together), Reading and Listening are assessed in 3 hours test
                  session. Test scores are published within 48 hours of taking
                  the test.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Container>
        <Container
          sx={{ display: { xs: "block", md: "none", lg: "none" } }}
          className='aboutus-topdiv'
        >
          <Grid item xs={12} sm={6} md={6} className='img-top-aboutus'>
            <Card
              className='WhatWeDoCard test-content-box'
              style={{
                position: "relative",
                overflow: "hidden",
                margin: "0 10px",
                height: "auto",
              }}
            >
              <CardMedia>
                <img
                  src={toefl}
                  alt='ausImage'
                  className='AboutUsStudentImage'
                ></img>
              </CardMedia>
              <CardContent className='test-content'>
                <Typography
                  gutterBottom
                  variant='h4'
                  component='div'
                  align='center'
                >
                  TOEFL iBT
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Test of English as a Foreign Language (TOEFL iBT) is the
                  choice of 30 million people worldwide, accepted in more than
                  150 countries and 11,000 universities. It is accepted and
                  preferred by universities, for you to know real experience of
                  English required in the classroom.
                </Typography>
                <br></br>
                <Typography variant='body2' color='text.secondary'>
                  6 days after the test your scores are ready in your ETS
                  Account. Scoring will be based on the 4 scaled section scores
                  and a total score ranging (0-120).
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default TestPreparationScreen
