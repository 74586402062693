import { Box } from "@mui/material";
import React from "react";

const ResultItem = ({ title, subtitle, icon: Icon }) => {
  return (
    <Box className="flex cursor-pointer items-center space-x-4 border-l-4 border-white px-4 py-3 hover:border-blue-500 hover:bg-blue-50">
      <Box className="grid h-10 w-10 place-content-center rounded bg-blue-100">
        <Icon />
      </Box>

      <Box className="flex flex-col ">
        <div className="text-base font-medium">{title}</div>

        <div className="text-sm"> {subtitle} </div>
      </Box>
    </Box>
  );
};

export default ResultItem;
