import React from "react";
import { Box, Button, Grid, Paper, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "react-select";

import { useGetUniversityListQuery } from "../../../../../rtkStore/services/universityApi";
import {
  useLazyListGlobalListQuery,
  useListGlobalListQuery,
} from "../../../../../rtkStore/services/globalListApi";
import { GLOBAL_LIST_CONSTANTS } from "../../../../../utils/staticData/globalListOptions";

const INITIAL_STATE = {
  keyword: "",
  field: "",
  subfield: "",
  universities: [],
  levels: [],
  englishRequirements: [],
  location: "",
  subLocations: [],
  tags: [],
};

const InputLabel = ({ children }) => {
  return (
    <div className="mb-1.5">
      <div className="text-sm font-medium text-gray-400">{children}</div>
    </div>
  );
};

const CourseSearchFilter = ({
  isBusy = false,
  onSearch = () => {},
  onReset = () => {},
}) => {
  const searchButtonRef = React.useRef("");

  // react form hook
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedField = watch("field");
  const selectedLocation = watch("location");

  // RTK Query
  const [
    lazyFetchCourseSubFieldOptions,
    { data: courseFieldSubOptions, isFetching: fetchingCourseSubFieldOptions },
  ] = useLazyListGlobalListQuery();

  const [
    lazyFetchSubLocationOptions,
    { data: subLocationOptions, isFetching: fetchingSubLocationOptions },
  ] = useLazyListGlobalListQuery();

  const { data: universityList, isLoading: loadingUniversities } =
    useGetUniversityListQuery();

  const { data: fieldOptions, isLoading: loadingFields } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_FIELDS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: courseLevels, isLoading: loadingLevels } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.COURSE_LEVELS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const {
    data: englishRequirementOptions,
    isLoading: loadingEnglishRequirements,
  } = useListGlobalListQuery({
    type: GLOBAL_LIST_CONSTANTS.ENGLISH_REQUIREMENTS,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: locationOptions, isLoading: loadingLocations } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.LOCATIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: tagOptions, isLoading: loadingTags } = useListGlobalListQuery({
    type: GLOBAL_LIST_CONSTANTS.TAGS,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const isFetchingData = React.useMemo(() => {
    return (
      fetchingCourseSubFieldOptions ||
      loadingUniversities ||
      loadingFields ||
      loadingLevels ||
      loadingEnglishRequirements ||
      fetchingSubLocationOptions ||
      loadingTags
    );
  }, [
    fetchingCourseSubFieldOptions,
    loadingUniversities,
    loadingFields,
    loadingLevels,
    loadingEnglishRequirements,
    fetchingSubLocationOptions,
    loadingTags,
  ]);

  const mappedFieldOptions = React.useMemo(() => {
    return fieldOptions?.map((f) => ({ label: f?.name, value: f?._id })) ?? [];
  }, [fieldOptions]);

  const mappedSubfieldOptions = React.useMemo(() => {
    return (
      courseFieldSubOptions?.map((sf) => ({
        label: sf?.name,
        value: sf?._id,
      })) ?? []
    );
  }, [courseFieldSubOptions]);

  const mappedUniversities = React.useMemo(() => {
    return (
      universityList?.map((uni) => ({ label: uni?.title, value: uni?._id })) ??
      []
    );
  }, [universityList]);

  const mappedCourseLevels = React.useMemo(() => {
    return (
      courseLevels?.map((lvl) => ({ label: lvl?.name, value: lvl?._id })) ?? []
    );
  }, [courseLevels]);

  const mappedEnglishRequirements = React.useMemo(() => {
    return (
      englishRequirementOptions?.map((eng) => ({
        label: eng?.name,
        value: eng?._id,
      })) ?? []
    );
  }, [englishRequirementOptions]);

  const mappedLocations = React.useMemo(() => {
    return (
      locationOptions?.map((loc) => ({
        label: loc?.name,
        value: loc?._id,
      })) ?? []
    );
  }, [locationOptions]);

  const mappedSubLocationOptions = React.useMemo(() => {
    return (
      subLocationOptions?.map((sl) => ({
        label: sl?.name,
        value: sl?._id,
      })) ?? []
    );
  }, [subLocationOptions]);

  const mappedTagOptions = React.useMemo(() => {
    return (
      tagOptions?.map((tag) => ({
        label: tag?.name,
        value: tag?._id,
      })) ?? []
    );
  }, [tagOptions]);

  // methods
  const handleOnEnter = (event) => {
    if (event.key !== "Enter") return;
    searchButtonRef.current && searchButtonRef.current.click();
  };

  React.useEffect(() => {
    if (!selectedField) return;

    const handleFetchSubOptions = async (field) => {
      await lazyFetchCourseSubFieldOptions({
        type: field?.name,
        includeChildren: true,
        status: "Active",
      }).unwrap();
    };

    const field = fieldOptions?.find((el) => el?._id === selectedField?.value);

    if (field) handleFetchSubOptions(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField, fieldOptions]);

  React.useEffect(() => {
    if (!selectedLocation) return;

    const handleFetchSubLocations = async (field) => {
      await lazyFetchSubLocationOptions({
        type: field?.name,
        includeChildren: true,
        status: "Active",
      }).unwrap();
    };

    const location = locationOptions?.find(
      (el) => el?._id === selectedLocation?.value
    );

    if (location) handleFetchSubLocations(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, subLocationOptions]);

  return (
    <Paper variant="outlined" onKeyPress={handleOnEnter}>
      <Box padding={2} paddingTop={1.5}>
        <Grid container columnSpacing={4} rowSpacing={2.5}>
          <Grid item md={4}>
            <InputLabel>Keyword</InputLabel>

            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter keyword to search..."
                  disabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Course Field</InputLabel>

            <Controller
              name="field"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={loadingFields}
                  options={mappedFieldOptions}
                  placeholder="Select Course field..."
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Course Subfield</InputLabel>

            <Controller
              name="subfield"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={fetchingCourseSubFieldOptions}
                  options={mappedSubfieldOptions}
                  placeholder="Select Course subfield..."
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Universities</InputLabel>

            <Controller
              name="universities"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={loadingUniversities}
                  options={mappedUniversities}
                  placeholder="Select Universities..."
                  isMulti
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Course Levels</InputLabel>

            <Controller
              name="levels"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={loadingLevels}
                  options={mappedCourseLevels}
                  placeholder="Select Levels..."
                  isMulti
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>English Requirements</InputLabel>

            <Controller
              name="englishRequirements"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={loadingEnglishRequirements}
                  options={mappedEnglishRequirements}
                  placeholder="Select English requirement..."
                  isMulti
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Locations</InputLabel>

            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={loadingLocations}
                  options={mappedLocations}
                  placeholder="Select Location..."
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Sub Locations</InputLabel>

            <Controller
              name="subLocations"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isLoading={fetchingSubLocationOptions}
                  options={mappedSubLocationOptions}
                  placeholder="Select Sub-location..."
                  isMulti
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <InputLabel>Tags</InputLabel>

            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <Select
                  menuPortalTarget={document.body}
                  isClearable
                  isMulti
                  isLoading={loadingTags}
                  options={mappedTagOptions}
                  placeholder="Select Tags..."
                  isDisabled={isBusy}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Box
              display="flex"
              alignItems="end"
              justifyContent="flex-end"
              gap={2}
              style={{ height: "100%" }}
            >
              <LoadingButton
                ref={searchButtonRef}
                variant="contained"
                loading={isBusy}
                disabled={isFetchingData}
                onClick={handleSubmit(onSearch)}
              >
                Search
              </LoadingButton>

              <Button
                variant="text"
                disabled={isFetchingData || isBusy}
                onClick={() => {
                  onReset();
                  reset({ ...INITIAL_STATE });
                }}
              >
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CourseSearchFilter;
