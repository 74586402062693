import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import TaskForm from "./taskComponents/TaskForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateTaskMutation } from "../../../rtkStore/services/taskApi";

const TaskCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Tasks", path: "/app/task/list" },
    {
      title: "New Task",
    },
  ];

  //   RTK Query
  const [createTask, { isLoading: creatingTask, error }] =
    useCreateTaskMutation();

  // methods
  const handleCreateTask = async (data) => {
    try {
      const { _id } = await createTask({ ...data }).unwrap();
      navigate(`/app/task/${_id}/detail`);
      toast.success("Task created successfully!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create Task!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} error={error} isBusy={creatingTask}>
      <TaskForm isBusy={creatingTask} onSubmit={handleCreateTask} />
    </WithHeader>
  );
};

export default TaskCreateScreen;
