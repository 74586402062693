import React from "react";

import { Link, useParams } from "react-router-dom";
import { Button, Divider, Paper } from "@mui/material";
import { toast } from "react-toastify";
import EditOutlined from "@mui/icons-material/EditOutlined";

import FeedbackBasicDetail from "./feedbackComponents/FeedbackBasicDetail";
import FeedbackResponses from "./feedbackComponents/FeedbackResponses";
import InfoGridSkeleton from "../../../components/Shared/Skeleton/InfoGridSkeleton";
import StagePathwayBar from "../../../components/Shared/StagePathwayBar";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetFeedbackDetailQuery,
  useUpdateFeedbackMutation,
} from "../../../rtkStore/services/feedbackApi";

const FeedbackDetailScreen = () => {
  const { id } = useParams();

  const breadcrumbs = [
    { title: "Feedbacks", path: "/app/feedback/list" },
    { title: "Feedback Detail" },
  ];

  const _stages = [
    "Open",
    "In Progress",
    "Verification",
    "Closed",
    "Discarded",
  ];

  const feedbackStages = _stages.map((el, index) => ({
    name: el,
    serialNumber: index + 1,
  }));

  // RTK Query
  const {
    data: feedbackDetail,
    isLoading: loadingFeedback,
    error,
  } = useGetFeedbackDetailQuery(id);

  const [updateFeedback, { isLoading: updatingFeedback }] =
    useUpdateFeedbackMutation();

  // methods
  const handleChangeFeedbackStage = async (stage) => {
    try {
      await updateFeedback({ ...feedbackDetail, stage: stage?.name }).unwrap();
      toast.info("Feedback's stage updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update feedback's stage!");
    }
  };

  const PageActions = () => (
    <Link to={`/app/feedback/${id}/edit`}>
      <Button variant="contained" startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingFeedback || updatingFeedback}
      error={error}
      hasActions
      headerActions={<PageActions />}
      disablePaper
    >
      {!!feedbackDetail?.stage && (
        <>
          <StagePathwayBar
            stages={feedbackStages}
            currentStage={feedbackDetail?.stage}
            onStageChange={handleChangeFeedbackStage}
          />
          <br />
        </>
      )}
      <Paper elevation={0} variant="outlined">
        {!loadingFeedback ? (
          <>
            <FeedbackBasicDetail feedbackDetail={feedbackDetail} />
            <Divider />
            <FeedbackResponses feedbackResponses={feedbackDetail?.responses} />
          </>
        ) : (
          <InfoGridSkeleton items={5} />
        )}
      </Paper>
    </WithHeader>
  );
};

export default FeedbackDetailScreen;
