import React from "react";
import { Route, Routes } from "react-router-dom";

import ClientBasicInformationTab from "../screens/Admin/Client/clientComponents/ClientBasicInformationTab";
import ClientTransactionsTab from "../screens/Admin/Client/clientComponents/ClientTransactionsTab";
import ClientAccumulatedNotesTab from "../screens/Admin/Client/clientComponents/ClientAccumulatedNotesTab";
import ClientCreateScreen from "../screens/Admin/Client/ClientCreateScreen";
import ClientDetailsScreen from "../screens/Admin/Client/ClientDetailsScreen";
import ClientEditScreen from "../screens/Admin/Client/ClientEditScreen";
import ClientListScreen from "../screens/Admin/Client/ClientListScreen";
import ClientTrackerScreen from "../screens/Admin/Client/ClientTrackerScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const ClientRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<ClientCreateScreen />} />
      <Route path=":id/edit" element={<ClientEditScreen />} />
      <Route path=":id/detail" element={<ClientDetailsScreen />}>
        <Route index element={<ClientBasicInformationTab />} />
        <Route path="transactions" element={<ClientTransactionsTab />} />
        <Route path="notes" element={<ClientAccumulatedNotesTab />} />
      </Route>
      <Route path="list" element={<ClientListScreen />} />
      <Route path="tracker" element={<ClientTrackerScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default ClientRouter;
