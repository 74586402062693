import React from "react";
import { Box, Grid } from "@mui/material";
import {
  InfoOutlined,
  PersonOutlineOutlined,
  EmailOutlined,
  EventOutlined,
  PhoneOutlined,
  StairsOutlined,
  ReceiptOutlined,
  SourceOutlined,
  DonutLargeOutlined,
  AccountCircleOutlined,
  LooksOneOutlined,
  LooksTwoOutlined,
  KeyboardDoubleArrowRight,
  AccessTimeOutlined,
  PublicOutlined,
  LocationOnOutlined,
  PeopleAltOutlined,
  PersonPinOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import InfoGridSkeleton from "../../../../components/Shared/Skeleton/InfoGridSkeleton";
import InformationTile from "../../../../components/Shared/InformationTile";
import OpenInNewTab from "../../../../components/Shared/OpenInNewTab";

import { useLeadDetail } from "../LeadDetailScreen";

const LeadBasicDetail = () => {
  const { loadingLead, leadDetail } = useLeadDetail();

  return !loadingLead ? (
    <Box padding={3}>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <InformationTile
            icon={() => <PersonOutlineOutlined color="primary" />}
            title="Name"
            subtitle={`${leadDetail?.firstName} ${leadDetail?.lastName}`}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EmailOutlined color="primary" />}
            title="Email"
          >
            {leadDetail?.email ? (
              <a href={`mailto:${leadDetail?.email}`} className="custom-link">
                {" "}
                {leadDetail?.email}{" "}
              </a>
            ) : (
              "N/A"
            )}
          </InformationTile>
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PhoneOutlined color="primary" />}
            title="Contact Number"
            subtitle={leadDetail?.phone}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PhoneOutlined color="primary" />}
            title="Alternative Contact Number"
            subtitle={leadDetail?.phone2}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PersonOutlineOutlined color="primary" />}
            title="Alternative Name"
            subtitle={leadDetail?.alternativeName}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PeopleAltOutlined color="primary" />}
            title="Civil Status"
            subtitle={leadDetail?.civilStatus}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PublicOutlined color="primary" />}
            title="Country"
            subtitle={leadDetail?.country?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <LocationOnOutlined color="primary" />}
            title="Address"
            subtitle={leadDetail?.address}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <SourceOutlined color="primary" />}
            title="Source"
            subtitle={leadDetail?.source?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <StairsOutlined color="primary" />}
            title="Stage"
            subtitle={leadDetail?.stage?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <LooksOneOutlined color="primary" />}
            title="First Preference"
            subtitle={leadDetail?.firstPreference?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <LooksTwoOutlined color="primary" />}
            title="Second Preference"
            subtitle={leadDetail?.secondPreference?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <DonutLargeOutlined color="primary" />}
            title="Lead Status"
            subtitle={`${
              leadDetail?.converted ? "Converted to Client" : "Lead"
            }`}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <KeyboardDoubleArrowRight color="primary" />}
            title="Next Action"
            subtitle={leadDetail?.nextAction?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccessTimeOutlined color="primary" />}
            title="Next Action Date"
            subtitle={
              leadDetail?.nextActionDate &&
              moment(leadDetail?.nextActionDate).format("MMM DD, YYYY")
            }
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Responsible"
            subtitle={leadDetail?.responsible?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <ReceiptOutlined color="primary" />}
            title="Referral Code"
            subtitle={leadDetail?.referalCode}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EventOutlined color="primary" />}
            title="Created on"
            subtitle={moment(leadDetail?.createdAt)?.format("MMM DD, YYYY")}
          />
        </Grid>

        {leadDetail?.connectedClient && (
          <Grid item md={3}>
            <InformationTile
              icon={() => <PersonPinOutlined color="primary" />}
              title="Connected Client"
            >
              <>
                {leadDetail?.connectedClient ? (
                  <OpenInNewTab
                    label={leadDetail?.connectedClient?.fullName}
                    link={`/app/client/${leadDetail?.connectedClient?._id}/detail`}
                  />
                ) : (
                  "N/A"
                )}
              </>
            </InformationTile>
          </Grid>
        )}

        <Grid item md={3}>
          <InformationTile
            icon={() => <InfoOutlined color="primary" />}
            title="Status"
          >
            <div
              className={`uppercase ${
                leadDetail?.isActive ? "text-green-600" : "text-red-600"
              }`}
            >
              {leadDetail?.isActive ? "Active" : "Inactive"}
            </div>
          </InformationTile>
        </Grid>

        {leadDetail?.closedAt && (
          <Grid item md={3}>
            <InformationTile
              icon={() => <EventOutlined color="primary" />}
              title="Closed at"
              subtitle={moment(leadDetail?.closedAt)?.format("MMM DD, YYYY")}
            />
          </Grid>
        )}
      </Grid>

      {leadDetail?.description && (
        <Box marginTop={3}>
          <div>{ReactHtmlParser(leadDetail.description)}</div>
        </Box>
      )}
    </Box>
  ) : (
    <InfoGridSkeleton />
  );
};

export default LeadBasicDetail;
