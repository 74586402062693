import React from "react";

import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, IconButton, Popover } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";

import getMuiIcon from "../../utils/getMuiIcon";

const QuickMenuItem = ({ menu }) => {
  return (
    <div className="flex cursor-pointer items-start space-x-3 px-4 py-3 hover:bg-blue-50">
      {getMuiIcon(menu.icon, { color: "primary", fontSize: "small" })}
      <div>
        <div className="text-sm font-medium text-gray-600">{menu.title}</div>
        <div className="text-xs text-gray-500">{menu.subtitle}</div>
      </div>
    </div>
  );
};

const AppQuickAction = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const quickAccessMenus = [
    {
      title: "Lead",
      subtitle: "Add a lead",
      icon: "BookmarkAddOutlined",
      path: "/app/lead/create",
    },
    {
      title: "Client",
      subtitle: "Add a client",
      icon: "PersonPinOutlined",
      path: "/app/client/create",
    },
    {
      title: "Transaction",
      subtitle: "Add a Transaction",
      icon: "ReceiptLongOutlined",
      path: "/app/transaction/create",
    },
    {
      title: "Application",
      subtitle: "Add an application",
      icon: "DriveFileRenameOutline",
      path: "/app/application/create",
    },
    {
      title: "Task",
      subtitle: "Add a Task",
      icon: "AddTask",
      path: "/app/task/create",
    },
    {
      title: "Workflow",
      subtitle: "Add a workflow",
      icon: "AccountTreeOutlined",
      path: "/app/workflow/create",
    },
    {
      title: "University",
      subtitle: "Add an univestity",
      icon: "AccountBalanceOutlined",
      path: "/app/university/create",
    },
    {
      title: "Course",
      subtitle: "Add a course",
      icon: "MenuBookOutlined",
      path: "/app/course/create",
    },
  ];

  //   methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <AddCircle
          color="primary"
          fontSize="large"
          className={`transform transition-transform duration-1000 ease-in ${
            open && "rotate-45"
          }`}
        />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box width={500}>
          <div className="px-5 pt-3 pb-2 font-medium text-gray-600">
            Quick Actions
          </div>
          <Divider />
          <Grid container>
            {quickAccessMenus.map((qm, index) => (
              <Grid key={`${qm.title}-${index}`} item md={6}>
                <div
                  onClick={() => {
                    handleClose();
                    navigate(qm.path);
                  }}
                >
                  <QuickMenuItem menu={qm} />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default AppQuickAction;
