import React from "react";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

import { useSidebarContext } from "../../layouts/SidebarLayout/SidebarContextProvider";
import confettiSound from "../../assets/audio/confetti.mp3";

const AppConfetti = ({ numberOfPieces = 500 }) => {
  const { width, height } = useWindowSize();

  const { party, setParty } = useSidebarContext();

  React.useEffect(() => {
    if (!party) return;

    const confettiAudio = new Audio(confettiSound);
    confettiAudio.play();

    return () => {
      setParty(false);
      confettiAudio.pause();
      confettiAudio.currentTime = 0;
    };
  }, [party, setParty]);

  return (
    <Confetti
      width={width - 300}
      height={height}
      recycle={false}
      numberOfPieces={party ? numberOfPieces : 0}
      onConfettiComplete={(confetti) => {
        setParty(false);
        confetti.reset();
      }}
    />
  );
};

export default AppConfetti;
