import React from "react";
import { Link } from "react-router-dom";

const OpenInNewTab = ({ label, link, className = "" }) => {
  return (
    <Link to={link} title={`View ${label}`} target="_blank">
      <div className={`text-blue-600 underline line-clamp-1 ${className}`}>
        {label}
      </div>
    </Link>
  );
};

export default OpenInNewTab;
