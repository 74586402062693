import React from "react";

import { Route, Routes } from "react-router-dom";

import LeadCreateScreen from "../screens/Admin/Lead/LeadCreateScreen";
import LeadDetailScreen from "../screens/Admin/Lead/LeadDetailScreen";
import LeadEditScreen from "../screens/Admin/Lead/LeadEditScreen";
import LeadListScreen from "../screens/Admin/Lead/LeadListScreen";
import LeadTrackerScreen from "../screens/Admin/Lead/LeadTrackerScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const LeadRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<LeadCreateScreen />} />
      <Route path=":id/edit" element={<LeadEditScreen />} />
      <Route path=":id/detail" element={<LeadDetailScreen />} />
      <Route path="list" element={<LeadListScreen />} />
      <Route path="tracker" element={<LeadTrackerScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default LeadRouter;
