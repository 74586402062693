import React from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Rating, Skeleton } from "@mui/material";
import ReactHtmlParse from "html-react-parser";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import ApplyCourseForm from "./courseCatalogueComponents/ApplyCourseForm";

import { useGetCourseCatalogueDetailQuery } from "../../../rtkStore/services/publicApi";

const CourseCatalogueDetailScreen = () => {
  const { id: courseId } = useParams();

  // RTK Query
  const { data: catalogueDetail, isLoading: loadingDetail } =
    useGetCourseCatalogueDetailQuery(courseId);

  // methods
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.info("Link Copied!");
  };

  if (loadingDetail)
    return (
      <div className="flex space-x-8">
        <div className=" flex w-2/3 flex-col space-y-6">
          <Skeleton variant="rectangular" height={50} width="70%" />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={300} />
        </div>

        <div className="w-1/3">
          <Skeleton variant="rectangular" height={500} />
        </div>
      </div>
    );

  return (
    <div className="relative flex space-x-10">
      <div className="flex w-2/3 flex-col space-y-6">
        <div className="flex items-center space-x-4 text-gray-500">
          <Link
            to="/v2/courses-catalogue/search"
            className="flex-shrink-0 text-sm font-medium"
          >
            Course Search
          </Link>

          <ChevronRightOutlinedIcon fontSize="small" />

          <div className="text-sm line-clamp-1">{catalogueDetail?.title}</div>
        </div>

        <div className="flex flex-col space-y-6">
          <div className="text-2xl font-medium">{catalogueDetail?.title}</div>

          <div className="flex flex-col space-y-4">
            <a
              href={catalogueDetail?.university?.website ?? ""}
              className="font-medium text-red-600 line-clamp-1"
              target="_blank"
              rel="noreferrer"
            >
              {catalogueDetail?.university?.title}
            </a>

            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2 rounded border-blue-200 bg-red-50 px-2 py-1">
                  <LocationOnOutlinedIcon fontSize="12" />

                  <span className="text-sm font-medium">
                    {catalogueDetail?.location?.name}
                  </span>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="font-medium">4.3</div>

                <Rating value={4} size="large" readOnly />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex space-x-10">
                <FieldItem label="Tuition Fee">
                  <div className="flex items-center space-x-2">
                    <div className=" text-green-600">
                      <span className=" text-sm">
                        {catalogueDetail?.currency?.symbol ?? ""}
                      </span>{" "}
                      <span className=" text-sm">
                        {catalogueDetail?.tuitionFee}
                      </span>
                    </div>

                    <div className="text-sm">
                      {catalogueDetail?.currency
                        ? `(${catalogueDetail?.currency?.code})`
                        : ""}
                    </div>

                    <div className="text-sm">/ {catalogueDetail?.feeType}</div>
                  </div>
                </FieldItem>

                <FieldItem label="Study Field">
                  {catalogueDetail?.field?.name}
                </FieldItem>
              </div>

              <button onClick={handleCopyLink}>
                <div className="flex items-center space-x-2 rounded bg-gray-100 p-2 font-medium transition-colors duration-150 ease-linear hover:bg-red-600 hover:text-white">
                  <ShareOutlinedIcon fontSize="small" />
                  <span className="text-sm"> Share Link </span>
                </div>
              </button>
            </div>
          </div>

          <div>
            <div className="mb-2 text-lg font-medium">Overview</div>

            {catalogueDetail?.summary
              ? ReactHtmlParse(catalogueDetail?.summary)
              : "N/A"}
          </div>
        </div>
      </div>

      <div className="w-1/3 flex-shrink-0">
        <div className="sticky top-10">
          <ApplyCourseForm courseDetail={catalogueDetail} />
        </div>
      </div>
    </div>
  );
};

export default CourseCatalogueDetailScreen;

const FieldItem = ({ label, children }) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className="text-xs font-medium text-gray-500">{label}</div>
      <div className="text-sm font-medium">{children}</div>
    </div>
  );
};
