import React from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";

import BasicNoteForm from "../../../../components/Note/BasicNoteForm";
import NoteList from "../../../../components/Note/NoteList";

import {
  useAddApplicationNoteMutation,
  useUpdateApplicationNoteMutation,
} from "../../../../rtkStore/services/applicationApi";
import { useApplicationDetail } from "../ApplicationDetailScreen";
import { Add } from "@mui/icons-material";

const ApplicationNotesTab = () => {
  const [showNoteForm, setShowNoteForm] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const { id: applicationId } = useParams();

  const {
    applicationDetail: { notes },
  } = useApplicationDetail();

  // RTK Query
  const [addApplicationNote, { isLoading: addingNote }] =
    useAddApplicationNoteMutation();

  const [updateApplicationNote, { isLoading: updatingNote }] =
    useUpdateApplicationNoteMutation();

  // methods
  const handleCancel = () => {
    setShowNoteForm(false);
    setSelectedNote(null);
  };

  const handleAddNote = async (data) => {
    setSelectedNote({ ...data });
    try {
      await addApplicationNote({ ...data, applicationId }).unwrap();
      toast.success("Note successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add note");
    }
  };

  const handleNoteEdit = (note) => {
    setShowNoteForm(true);
    setSelectedNote(note);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateNote = async (data) => {
    try {
      await updateApplicationNote({ ...data, applicationId }).unwrap();
      toast.success("Note successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update note");
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <StickyNote2OutlinedIcon style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Notes</Typography>
        </Box>

        {!showNoteForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowNoteForm(true)}
          >
            Note
          </Button>
        )}
      </Box>

      <Collapse in={showNoteForm}>
        <BasicNoteForm
          noteDetail={selectedNote}
          isBusy={addingNote || updatingNote}
          onSubmit={selectedNote ? handleUpdateNote : handleAddNote}
          onCancel={handleCancel}
        />
      </Collapse>

      <Divider />

      <NoteList notes={notes} onNoteEdit={handleNoteEdit} />
    </Box>
  );
};

export default ApplicationNotesTab;
