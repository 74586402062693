import React from "react";

import { Box, Divider, Skeleton } from "@mui/material";
import InfoGridSkeleton from "../../../../components/Shared/Skeleton/InfoGridSkeleton";

const TaskDetailSkeleton = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" padding={3} gap={4}>
        <Skeleton variant="rect" height={40} />
      </Box>
      <InfoGridSkeleton />
      <Box display="flex" flexDirection="column" padding={3} gap={4}>
        <Skeleton variant="rect" height={100} />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" padding={3} gap={3}>
        <Skeleton variant="rect" height={50} />
        <Skeleton variant="rect" height={50} />
      </Box>
    </>
  );
};

export default TaskDetailSkeleton;
