import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";

import { useListListQuery } from "../../../../../rtkStore/services/listService";

const _INITIAL_STATE = {
  employerName: "",
  jobTitle: "",
  industry: "",
  remark: "",
  startDate: "",
  endDate: "",
};

const EmploymentForm = ({
  employmentDetail,
  isBusy,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  // RTK Query
  const { data: employmentIndustries } = useListListQuery({
    type: "Employment Industries",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      ..._INITIAL_STATE,
      employerName: employmentDetail?.employerName ?? "",
      jobTitle: employmentDetail?.jobTitle ?? "",
      industry: employmentDetail?.industry?._id ?? "",
      remark: employmentDetail?.remark ?? "",
      startDate: employmentDetail?.startDate ?? "",
      endDate: employmentDetail?.endDate ?? "",
      currentlyEmployed: employmentDetail?.currentlyEmployed ?? false,
    },
  });

  const employmentEndDate = React.useRef({});
  employmentEndDate.current = watch("currentlyEmployed");

  React.useEffect(() => {
    if (!employmentDetail) return reset({ ..._INITIAL_STATE });

    reset({
      ...employmentDetail,
      industry: employmentDetail?.industry?._id ?? "",
    });
  }, [employmentDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="industry"
              control={control}
              rules={{ required: "Please select industry" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-industry">Industry</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-industry"
                    label="Industry"
                    placeholder="Select Industry"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {employmentIndustries?.map((industry) => (
                      <MenuItem key={industry._id} value={industry._id}>
                        {industry?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.industry && (
              <p className="error">{errors.industry.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="employerName"
              control={control}
              rules={{ required: "Employer Name is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Employer Name"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.employerName && (
              <p className="error">{errors.employerName.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="jobTitle"
              control={control}
              rules={{ required: "Job Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Job Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.jobTitle && (
              <p className="error">{errors.jobTitle.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: "Start Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                  value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                />
              )}
            />
            {errors.startDate && (
              <p className="error">{errors.startDate.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: "End Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="End Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!!employmentEndDate.current}
                  {...field}
                  value={moment(field?.value).format("YYYY-MM-DD")}
                />
              )}
            />
            {errors.endDate && (
              <p className="error">{errors.endDate.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="currentlyEmployed"
              control={control}
              render={({ field, value }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="currentlyEmployed"
                      color="primary"
                      checked={field.value}
                      value={value}
                      {...field}
                    />
                  }
                  label="Currenly Employed?"
                />
              )}
            />
          </Grid>

          <Grid item md={8}>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Remarks"
                  type="text"
                  size="small"
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              endDate: !!employmentEndDate?.current ? "" : data?.endDate,
            })
          )}
        >
          {employmentDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default EmploymentForm;
