import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { LayersOutlined } from "@mui/icons-material";

import WithHeader from "../../../layouts/WithHeader";

import { globalListOptions } from "../../../utils/staticData/globalListOptions";

const GlobalListScreen = () => {
  const breadcrumbs = [{ title: "Global List" }];

  const sortedList = globalListOptions.sort((a, b) => a.name > b.name);

  return (
    <WithHeader breadcrumbs={breadcrumbs}>
      {sortedList.map((list, index) => (
        <React.Fragment key={`${list.name}-${index}`}>
          <Link
            to={`/app/global-list/detail/${list?.name}?type=${list.name}`}
            style={{ color: "inherit" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              paddingX={3}
              paddingY={2}
              className="hover:bg-blue-50"
            >
              <Typography> {list.name} </Typography>

              <Box display="flex" alignItems="center" gap={4}>
                {list?.allowGrouping && (
                  <div className="rounded bg-blue-300 px-2 py-1 text-sm text-white">
                    Group-able
                  </div>
                )}

                <Tooltip title="Max nested level" placement="left" arrow>
                  <Box display="flex" alignItems="center" gap={1}>
                    <LayersOutlined />

                    <Typography>{list.maxLevel}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Link>
          {index !== globalListOptions.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </WithHeader>
  );
};

export default GlobalListScreen;
