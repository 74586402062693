import React from "react";

import { Box, Grid } from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import GroupsOutlined from "@mui/icons-material/GroupsOutlined";
import PeopleOutline from "@mui/icons-material/PeopleOutline";

import InformationTile from "../../../../components/Shared/InformationTile";

const TeamBasicDetail = ({ teamDetail }) => {
  return (
    <Box padding={3}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <InformationTile
            icon={() => <GroupsOutlined color="primary" />}
            title="Team Name"
            subtitle={teamDetail?.name}
          />
        </Grid>

        <Grid item md={4}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Manager"
            subtitle={teamDetail?.manager?.name}
          />
        </Grid>

        <Grid item md={4}>
          <InformationTile
            icon={() => <PeopleOutline color="primary" />}
            title="Members"
          >
            <ul>
              {teamDetail?.members?.map((m) => (
                <li key={m?._id}>- {m?.name}</li>
              ))}
            </ul>
          </InformationTile>{" "}
        </Grid>
      </Grid>

      <br />

      <Box>{teamDetail?.description}</Box>
    </Box>
  );
};

export default TeamBasicDetail;
