import React from "react";
import { useSearchParams } from "react-router-dom";

import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import CustomTabs from "../../../components/Shared/CustomTabs";
import LeadNextActions from "./leadComponents/tracker/LeadNextActions";
import NoLeadsUpdates from "./leadComponents/tracker/NoLeadUpdates";
import WithHeader from "../../../layouts/WithHeader";

const LeadTrackerScreen = () => {
  const [activeTab, setActiveTab] = React.useState("next-actions");

  const [searchParams] = useSearchParams();

  const _durationInDays = 3;

  const leadTrackerTabs = [
    { label: "Next Actions", value: "next-actions" },
    { label: `No Updates (Last ${_durationInDays} days)`, value: "no-updates" },
  ];

  const breadcrumbs = [
    { title: "Leads", path: "/app/lead/list" },
    { title: "Leads Tracker" },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "next-actions");
  }, [searchParams]);

  return (
    <WithHeader breadcrumbs={breadcrumbs}>
      <CustomTabs tabList={leadTrackerTabs} activeTab={activeTab} />

      <CustomTabPanel activeTab={activeTab} value="next-actions">
        <LeadNextActions />
      </CustomTabPanel>

      <CustomTabPanel activeTab={activeTab} value="no-updates">
        <NoLeadsUpdates durationInDays={_durationInDays} />
      </CustomTabPanel>
    </WithHeader>
  );
};

export default LeadTrackerScreen;
