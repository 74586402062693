import React from "react";
import { Typography, Button, Box } from "@mui/material";

import { LoadingButton } from './LoadingButton';
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "./CustomModal";

export const DeleteDialog = ({
  open,
  onCancel = () => { },
  title,
  subtitle,
  primaryBtnText,
  secondaryBtnText = "Cancel",
  onDelete = () => { },
  isLoading,
  children,
  renderSubtitle = true,
  loadingText = "Deleting..",
  isDeleteDialog = true,
}) => {
  return (
    <CustomModal
      classNames={isDeleteDialog ? "delete-modal" : "dialog-modal"}
      open={open}
      onClose={() => onCancel()}
      backdrop="static"
    >
      <ModalHeader>
        {title}
      </ModalHeader>

      <ModalBody>
        {renderSubtitle && <Typography style={{ marginBottom: "4px" }}>{subtitle}</Typography>}
        {children}


      </ModalBody>

      <ModalFooter>

        <Box padding={1}>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
            style={{ marginRight: "1rem" }}
            onClick={onDelete}
            loading={isLoading}
            loadingText={loadingText}
          >
            {primaryBtnText}
          </LoadingButton>
          <Button
            style={{ marginLeft: "4px" }}
            variant="text"
            onClick={() => onCancel()}
          >
            {secondaryBtnText}
          </Button>
        </Box>
      </ModalFooter>

    </CustomModal>
  );
};
