import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { AccountTreeOutlined } from "@mui/icons-material";

import AttachWorkflowForm from "../../../../components/Workflow/AttachWorkflowForm";
import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";
import WorkflowStepUpdateTracker from "../../../../components/Workflow/WorkflowStepUpdateTracker";

import {
  useAddApplicationWorkflowMutation,
  useAddApplicationWorkflowStepMutation,
  useReOrderApplicationWorkflowStepsMutation,
} from "../../../../rtkStore/services/applicationApi";
import { useGetWorkflowListQuery } from "../../../../rtkStore/services/workflowApi";

import { useApplicationDetail } from "../ApplicationDetailScreen";

const ApplicationWorkflowsTab = () => {
  const [showAttachWorkflow, setShowAttachWorkflow] = React.useState(false);

  const { id: applicationId } = useParams();

  const {
    applicationDetail: { workflows },
    loadingApplication,
  } = useApplicationDetail();

  // RTK Query
  const [attachWorkflowToApplication, { isLoading: attachingWorkflow }] =
    useAddApplicationWorkflowMutation();

  const [addStepToApplicationWorkflow, { isLoading: addingStepToWorkflow }] =
    useAddApplicationWorkflowStepMutation();

  const [reOrderApplicationWorkflowSteps, { isLoading: reOrderingSteps }] =
    useReOrderApplicationWorkflowStepsMutation();

  const {
    data: activeApplicationWorkflows = [],
    isLoading: loadingApplicationWorkflows,
  } = useGetWorkflowListQuery({
    status: "Active",
    type: "Application",
  });

  const notIncludedApplicationWorkflows = React.useMemo(() => {
    const existingWorkflows = workflows.map((w) => w?._id);
    return (
      activeApplicationWorkflows?.filter(
        (w) => !existingWorkflows.includes(w._id)
      ) ?? []
    );
  }, [activeApplicationWorkflows, workflows]);

  // methods
  const handleCancel = () => {
    setShowAttachWorkflow(false);
  };

  const handleAttachWorkflow = async (data) => {
    try {
      await attachWorkflowToApplication({ ...data, applicationId }).unwrap();
      toast.success("Workflow successfully attached!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add workflow!");
    }

    handleCancel();
  };

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <AccountTreeOutlined style={{ color: "gray" }} />

            <Typography style={{ fontWeight: 500 }}>Workflows</Typography>
          </Box>

          {!showAttachWorkflow && (
            <Button
              variant="outlined"
              onClick={() => setShowAttachWorkflow(true)}
            >
              Attach Workflow
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Collapse in={showAttachWorkflow}>
        <AttachWorkflowForm
          workflowList={notIncludedApplicationWorkflows}
          isBusy={attachingWorkflow || loadingApplicationWorkflows}
          onSubmit={handleAttachWorkflow}
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      {!loadingApplication ? (
        <Box paddingX={3}>
          {workflows.length ? (
            workflows.map((applicationWorkflow, index) => (
              <React.Fragment key={applicationWorkflow._id}>
                <WorkflowStepUpdateTracker
                  assigneeSource="Application"
                  isBusy={addingStepToWorkflow || reOrderingSteps}
                  workflow={applicationWorkflow}
                  onAddStepMutation={addStepToApplicationWorkflow}
                  onReOrderSteps={reOrderApplicationWorkflowSteps}
                />
                {index !== workflows.length - 1 && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <Box paddingY={3}>No Workflows.</Box>
          )}
        </Box>
      ) : (
        <RowsSkeleton />
      )}
    </>
  );
};

export default ApplicationWorkflowsTab;
