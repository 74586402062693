import React from "react";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilterByText = ({
  placeholder = "Filter",
  handleClear,
  ...otherProps
}) => {
  return (
    <TextField
      placeholder={placeholder}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      style={{ width: 350 }}
      {...otherProps}
    />
  );
};

export default FilterByText;
