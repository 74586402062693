import React from "react";

import DataTable from "react-data-table-component";

import CustomLoader from "../../../components/Shared/CustomLoader";
import WithHeader from "../../../layouts/WithHeader";

import { useListAccountEntityQuery } from "../../../rtkStore/services/entityApi";

const AccountLvlEntityListScreen = () => {
  const breadcrumbs = [
    {
      title: "Entites",
    },
  ];

  //   RTK Query
  const {
    data: accountLvlEntities,
    isLoading: loadingEntities,
    error,
  } = useListAccountEntityQuery("Active");

  const columns = [
    { name: "Name", selector: (row) => row?.name, sortable: true },
    { name: "Category", selector: (row) => row?.category, sortable: true },
    { name: "Type", selector: (row) => row?.type, sortable: true },
    {
      name: "Currency",
      selector: (row) => row?.currency?.name,
    },
    {
      name: "Plan",
      selector: (row) => row?.entityPlans?.find((p) => p.isActive)?.plan,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        "&:hover": {
          backgroundColor: "var(--primary-accent)",
        },
      },
    },
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingEntities}
      error={error}
    >
      <DataTable
        columns={columns}
        data={accountLvlEntities}
        progressPending={loadingEntities}
        progressComponent={<CustomLoader />}
        persistTableHead={true}
        customStyles={customStyles}
      />
    </WithHeader>
  );
};

export default AccountLvlEntityListScreen;
