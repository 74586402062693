import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add,
  CalendarMonthOutlined,
  FiberManualRecord,
  EditOutlined,
  EventOutlined,
} from "@mui/icons-material";
import moment from "moment";

import CourseDatesForm from "./CourseDatesForm";
import InformationTile from "../../../../components/Shared/InformationTile";

import {
  useAddCourseDatesMutation,
  useUpdateCourseDatesMutation,
} from "./../../../../rtkStore/services/courseApi";

const CourseDatesTab = ({ courseDates = [] }) => {
  const [showDatesForm, setShowDatesForm] = React.useState(false);
  const [selectedDates, setSelectedDates] = React.useState(null);

  const { id: courseId } = useParams();

  // RTK Query
  const [addCourseDates, { isLoading: addingCourseDates }] =
    useAddCourseDatesMutation();

  const [updateCourseDates, { isLoading: updatingCourseDates }] =
    useUpdateCourseDatesMutation();

  //   methods
  const handleCancel = () => {
    setShowDatesForm(false);
    setSelectedDates(null);
  };

  const handleAddCourseDates = async (data) => {
    setSelectedDates({ ...data });
    try {
      await addCourseDates({ ...data, courseId }).unwrap();
      toast.success("Course Dates successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add course dates!");
    }
  };

  const handleCourseDateEdit = (date) => {
    setShowDatesForm(true);
    setSelectedDates(date);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateCourseDates = async (data) => {
    try {
      await updateCourseDates({ ...data, courseId }).unwrap();
      toast.success("Course Dates successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update course dates!");
    }
  };

  return (
    <>
      <Box
        padding={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" gap={1.5}>
          <CalendarMonthOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Course Dates</Typography>
        </Box>

        {!showDatesForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowDatesForm(true)}
          >
            Dates
          </Button>
        )}
      </Box>

      <Divider />

      <Collapse in={showDatesForm}>
        <CourseDatesForm
          datesDetail={selectedDates}
          isBusy={addingCourseDates || updatingCourseDates}
          onSubmit={
            !selectedDates ? handleAddCourseDates : handleUpdateCourseDates
          }
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <Box paddingX={3}>
        {courseDates.length ? (
          courseDates?.map((date, index) => (
            <React.Fragment key={date._id}>
              <Box paddingY={3}>
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                  gap={2}
                >
                  <Box flexGrow={1}>
                    <Box display="flex" alignItems="center" gap={3}>
                      <InformationTile
                        icon={() => (
                          <EventOutlined color="success" fontSize="small" />
                        )}
                        title="Open Date"
                        subtitle={moment(date?.openDate)?.format("DD/MM/YYYY")}
                      />

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <InformationTile
                        icon={() => (
                          <EventOutlined color="error" fontSize="small" />
                        )}
                        title="Deadline"
                        subtitle={moment(date?.deadline)?.format("DD/MM/YYYY")}
                      />

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <InformationTile
                        icon={() => (
                          <EventOutlined color="primary" fontSize="small" />
                        )}
                        title="Term Date"
                        subtitle={moment(date?.termDate)?.format("DD/MM/YYYY")}
                      />

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <InformationTile
                        icon={() => (
                          <EventOutlined color="primary" fontSize="small" />
                        )}
                        title="Census Date"
                        subtitle={moment(date?.censusDate)?.format(
                          "DD/MM/YYYY"
                        )}
                      />
                    </Box>
                  </Box>

                  <IconButton onClick={() => handleCourseDateEdit(date)}>
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>
              {index !== courseDates.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>
            <Typography>No Course Dates.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CourseDatesTab;
