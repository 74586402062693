import React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useLazyGetCourseCatalogueQuery } from "../../../rtkStore/services/publicApi";

import { useDebounce } from "../../../hooks/useDebounce";
import { useGetUrlParams } from "../../../hooks/useGetUrlParams";

import CatalogueSearchResult from "./courseCatalogueComponents/CatalogueSearchResult";
import CatalogueSideFilters from "./courseCatalogueComponents/CatalogueSideFilters";
import CatalogueTopFilters from "./courseCatalogueComponents/CatalogueTopFilters";

const INITIAL_FILTER_STATE = {
  keyword: "",
  location: "",
  subLocations: [],
  university: "",
  feeRange: [0, 100000],
  feeType: "Semester",
  page: 1,
  size: 10,
};

const CourseCatalogueScreen = () => {
  const [courseFilters, setCourseFilters] = React.useState({
    ...INITIAL_FILTER_STATE,
  });

  const [searchParams] = useSearchParams();

  const _catalogueSearchParams = useGetUrlParams();

  const {
    debouncedValue: _debouncedCourseFilters,
    isBusy: loadingDebouncedValue,
  } = useDebounce(courseFilters, 1000);

  // RTK Query
  const [
    fetchCourses,
    {
      data: catalogueRes,
      isLoading: loadingCourses,
      isFetching: fetchingCourses,
    },
  ] = useLazyGetCourseCatalogueQuery();

  const courseResult = catalogueRes?.data ?? [];

  React.useEffect(() => {
    if (!searchParams) return;

    const subLocations = _catalogueSearchParams?.subLocations?.split(",") ?? [];
    const feeRange =
      _catalogueSearchParams?.feeRange?.split(",")?.length > 1
        ? _catalogueSearchParams?.feeRange?.split(",")
        : [0, 100000];
    const courseLevels = _catalogueSearchParams?.courseLevels?.split(",") ?? [];

    const _formattedFilters = {
      ..._catalogueSearchParams,
      feeType: _catalogueSearchParams?.feeType ?? "Semester",
      ...(subLocations?.length && { subLocations }),
      ...(feeRange?.length && { feeRange }),
      ...(courseLevels?.length && { courseLevels }),
    };

    setCourseFilters(_formattedFilters);
  }, [searchParams]);

  React.useEffect(() => {
    const handleFetchCourseCatalogue = async () => {
      try {
        await fetchCourses({ ..._debouncedCourseFilters }).unwrap();
      } catch (err) {
        toast.error(err?.data?.message ?? "Couldn't fetch courses!");
      }
    };

    handleFetchCourseCatalogue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_debouncedCourseFilters]);

  return (
    <div className="flex h-full flex-col space-y-6">
      <CatalogueTopFilters isBusy={loadingCourses || fetchingCourses} />

      <div className=" flex h-full space-x-8">
        <div className="-mt-4 w-1/4 flex-shrink-0">
          <div className="sticky top-0">
            <CatalogueSideFilters isBusy={loadingCourses || fetchingCourses} />
          </div>
        </div>

        <div className="-mt-4 flex h-full w-3/4 flex-col">
          <div className="sticky top-0 z-[1] w-full bg-white py-4">
            <div className="text-sm tracking-wider text-gray-500">
              {loadingDebouncedValue &&
                "Updating Filters, Please be patient..."}

              {(loadingCourses || fetchingCourses) && "Loading Courses..."}
            </div>

            {!loadingCourses && !fetchingCourses && !loadingDebouncedValue && (
              <div className="font-medium">
                {catalogueRes?.totalItems} results found!
              </div>
            )}
          </div>

          <CatalogueSearchResult
            isBusy={loadingCourses || fetchingCourses}
            searchResult={courseResult}
            page={courseFilters?.page}
            size={courseFilters?.size}
            totalItems={catalogueRes?.totalItems}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseCatalogueScreen;
