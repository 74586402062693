import { Grid } from "@mui/material"
import React from "react"

const Teams = ({ name, position, image, description, order }) => {
  return (
    <div>
      <Grid
        container
        spacing={3}
        sx={{ margin: "2rem 0" }}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid
          item
          xs={12}
          md={5}
          order={order}
          data-aos={order === "0" ? "fade-right" : "fade-left"}
          // 'fade-right'
          data-aos-offset='200'
          data-aos-delay='50'
          data-aos-duration='2000'
          className={order === "1" ? "rightTeamImage" : ""}
        >
          <div className={order === "1" ? "teamsImageEnd" : ""}>
            <img
              src={image}
              style={{ height: "250px" }}
              alt='VisionImage'

              // 'teamsImagePosition'
            ></img>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          data-aos={order === "0" ? "fade-left" : "fade-right"}
          // data-aos='fade-left'
          data-aos-offset='200'
          data-aos-delay='50'
          data-aos-duration='2000'
        >
          <h3 style={{ fontWeight: 600 }}> {name} </h3>
          <h6 style={{ marginBottom: "20px", color: "#eb2428" }}>{position}</h6>

          <p>
            {/* <blockquote> */}
            <span className='aboutUsQuote'>{description}</span>
            {/* </blockquote> */}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default Teams
