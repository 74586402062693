import React from "react";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { Add, StickyNote2Outlined } from "@mui/icons-material";

import NextActionNoteForm from "../../../../components/Note/NextActionNoteForm";
import NoteList from "../../../../components/Note/NoteList";

import {
  useAddNoteToLeadMutation,
  useUpdateLeadMutation,
  useUpdateLeadNoteMutation,
} from "../../../../rtkStore/services/leadApi";

import { useLeadDetail } from "../LeadDetailScreen";

const LeadNotes = () => {
  const [showNoteForm, setShowNoteForm] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const {
    leadDetail: { notes },
  } = useLeadDetail();

  // RTK Query
  const [addNoteToLead, { isLoading: addingNote }] = useAddNoteToLeadMutation();

  const [updateLeadNote, { isLoading: updatingNote }] =
    useUpdateLeadNoteMutation();

  const [updateLeadNextAction, { isLoading: updatingLeadNextAction }] =
    useUpdateLeadMutation();

  // methods
  const handleCancel = () => {
    setShowNoteForm(false);
    setSelectedNote(null);
  };

  const handleUpdateLeadNextAction = async (nextActionPayload) => {
    try {
      await updateLeadNextAction(nextActionPayload).unwrap();
      toast.success("Lead Next Action successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Next Action!");
    }
  };

  const handleAddNote = async (data) => {
    const { content, type, nextAction, nextActionDate } = data;
    const notePayload = { content, type };
    setSelectedNote({ ...notePayload });

    if (nextAction || nextActionDate) {
      const nextActionPayload = { _id: id, nextAction, nextActionDate };
      handleUpdateLeadNextAction(nextActionPayload);
    }

    try {
      await addNoteToLead({ ...notePayload, _id: id }).unwrap();
      toast.success("Note successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add note");
    }
  };

  const handleNoteEdit = (note) => {
    setShowNoteForm(true);
    setSelectedNote(note);
  };

  const handleUpdateNote = async (data) => {
    try {
      await updateLeadNote({ ...data, leadId: id }).unwrap();
      toast.success("Note successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update note");
    }
  };

  React.useEffect(() => {
    if (!searchParams) return;

    if (searchParams.get("addNote")) setShowNoteForm(true);
  }, [searchParams]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <StickyNote2Outlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Notes</Typography>
        </Box>

        {!showNoteForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowNoteForm(true)}
          >
            Note
          </Button>
        )}
      </Box>

      <Collapse in={showNoteForm}>
        <NextActionNoteForm
          isBusy={addingNote || updatingNote || updatingLeadNextAction}
          noteDetail={selectedNote}
          onSubmit={selectedNote ? handleUpdateNote : handleAddNote}
          onCancel={handleCancel}
        />
      </Collapse>

      <Divider />

      <NoteList notes={notes} onNoteEdit={handleNoteEdit} />
    </Box>
  );
};

export default LeadNotes;
