import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FeedbackForm from "./feedbackComponents/FeedbackForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateFeedbackMutation } from "../../../rtkStore/services/feedbackApi";

const FeedbackCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    { title: "Feedbacks", path: "/app/feedback/list" },
    { title: "New Feedback" },
  ];

  // RTK Query
  const [createFeedback, { isLoading: creatingFeedback }] =
    useCreateFeedbackMutation();

  // methods
  const handleAddFeedback = async (data) => {
    try {
      const { _id } = await createFeedback(data).unwrap();
      toast.success("Feedback successfully created!");
      navigate(`/app/feedback/${_id}/detail`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Coundn't create feedback!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingFeedback}>
      <FeedbackForm onSubmit={handleAddFeedback} isBusy={creatingFeedback} />
    </WithHeader>
  );
};

export default FeedbackCreateScreen;
