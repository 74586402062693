import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  Add,
  WorkOutlineOutlined,
  EventOutlined,
  FiberManualRecord,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";

import AppDialog from "../../../../../components/Shared/AppDialog";
import EmploymentForm from "./EmploymentForm";

import {
  useAddEmploymentDetailMutation,
  useRemoveEmploymentDetailMutation,
  useUpdateEmploymentDetailMutation,
} from "../../../../../rtkStore/services/clientApi";

import { useClientDetail } from "../ClientBasicInformationTab";

const EmploymentDetailTab = () => {
  const [showAddEmployment, setShowAddEduction] = React.useState(false);
  const [selectedEmployment, setSelectedEmployment] = React.useState(null);
  const [showAppDialog, setShowAppDialog] = React.useState(false);

  const { id: clientId } = useParams();

  const {
    clientDetail: { employments },
  } = useClientDetail();

  // RTK Query
  const [addEmploymentDetail, { isLoading: addingEmploymentDetail }] =
    useAddEmploymentDetailMutation();

  const [updateEmploymentDetail, { isLoading: updatingEmploymentDetail }] =
    useUpdateEmploymentDetailMutation();

  const [removeEmploymentDetail, { isLoading: removingEmploymentDetail }] =
    useRemoveEmploymentDetailMutation();

  // methods
  const handleCancelEmployment = () => {
    setSelectedEmployment(null);
    setShowAddEduction(false);
  };

  const handleEmploymentDetailEdit = (employmentDetail) => {
    setSelectedEmployment(employmentDetail);
    setShowAddEduction(true);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAddEmploymentDetail = async (data) => {
    setSelectedEmployment({ ...data });
    try {
      await addEmploymentDetail({ ...data, clientId }).unwrap();
      toast.success("Employment Information updated!");
      handleCancelEmployment();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add employment detail!");
    }
  };

  const handleUpdateEmploymentDetail = async (data) => {
    try {
      await updateEmploymentDetail({ ...data, clientId }).unwrap();
      toast.success("Employment Information updated!");
      handleCancelEmployment();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update employment detail!");
    }
  };

  const handleRemoveEmploymentDetail = () => {
    if (!selectedEmployment) return;

    removeEmploymentDetail({ ...selectedEmployment, clientId })
      .unwrap()
      .then(() => {
        toast.info("Employment Information removed!");
        setShowAppDialog(false);
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <WorkOutlineOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>
            Employment Details
          </Typography>
        </Box>

        {!showAddEmployment && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => {
              setSelectedEmployment(null);
              setShowAddEduction(true);
            }}
          >
            Employment Detail
          </Button>
        )}
      </Box>

      <Collapse in={showAddEmployment}>
        <EmploymentForm
          employmentDetail={selectedEmployment}
          isBusy={addingEmploymentDetail || updatingEmploymentDetail}
          onCancel={handleCancelEmployment}
          onSubmit={
            !selectedEmployment
              ? handleAddEmploymentDetail
              : handleUpdateEmploymentDetail
          }
        />
      </Collapse>

      <AppDialog
        title="Confirm Removal!"
        open={showAppDialog}
        isBusy={removingEmploymentDetail}
        onConfirm={handleRemoveEmploymentDetail}
        onClose={() => setShowAppDialog(false)}
      >
        Are you sure you want to remove employment detail?
      </AppDialog>

      <Divider />

      <Box paddingX={3}>
        {!!employments.length ? (
          employments.map((employmentDetail, index) => (
            <React.Fragment key={employmentDetail._id}>
              <Box paddingY={3} display="flex" gap={2}>
                <WorkOutlineOutlined
                  color="primary"
                  fontSize="small"
                  style={{ marginTop: 2 }}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography>{employmentDetail.employerName}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography style={{ color: "var(--primary)" }}>
                        {employmentDetail?.industry?.name}
                      </Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography>{employmentDetail.jobTitle}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={2}>
                      <Tooltip title="Start Date" arrow placement="top">
                        <Box display="flex" gap={1}>
                          <EventOutlined fontSize="small" color="success" />
                          <Typography style={{ fontSize: 14 }}>
                            {moment(employmentDetail.startDate).format(
                              "YYYY/MM/DD"
                            )}
                          </Typography>
                        </Box>
                      </Tooltip>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      {!employmentDetail?.currentlyEmployed ? (
                        <Tooltip title="End Date" arrow placement="top">
                          <Box display="flex" gap={1}>
                            <EventOutlined fontSize="small" color="error" />
                            <Typography style={{ fontSize: 14 }}>
                              {moment(employmentDetail.endDate).format(
                                "YYYY/MM/DD"
                              )}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ) : (
                        <div className="text-sm font-medium text-green-600">
                          Currently Employed
                        </div>
                      )}
                    </Box>

                    <Box style={{ color: "gray" }}>
                      {employmentDetail.remark}
                    </Box>
                  </Box>

                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedEmployment(employmentDetail);
                      setShowAppDialog(true);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>

                  <IconButton
                    onClick={() => handleEmploymentDetailEdit(employmentDetail)}
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>

              {index !== employments.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Employment Details</Box>
        )}
      </Box>
    </>
  );
};

export default EmploymentDetailTab;
