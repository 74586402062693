import React from "react";

import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, TextField } from "@mui/material";

const INITIAL_STATE = { name: "", email: "", phone: "", designation: "" };

const UserEditForm = ({
  userDetail = null,
  onSubmit = () => {},
  onCancel = () => {},
  isBusy = false,
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { ...INITIAL_STATE },
  });

  React.useEffect(() => {
    if (!userDetail) return;

    reset({
      name: userDetail?.name,
      email: userDetail?.email,
      phone: userDetail?.phone,
      designation: userDetail?.designation,
    });

    return () => {
      reset({ ...INITIAL_STATE });
    };
  }, [userDetail, reset]);

  return (
    <>
      <Box padding={3}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Name"
                  placeholder="Enter name"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </Grid>

          <Grid item md={6}></Grid>

          <Grid item md={4}>
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Email"
                  placeholder="Enter email"
                  size="small"
                  type="email"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </Grid>

          <Grid item md={6}></Grid>

          <Grid item md={4}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Phone"
                  placeholder="Enter Contact Number"
                  size="small"
                  type="tel"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          <Grid item md={6}></Grid>

          <Grid item md={4}>
            <Controller
              name="designation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Designation"
                  placeholder="Enter User Designation"
                  size="small"
                  type="tel"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          Update
        </Button>
      </Box>
    </>
  );
};

export default UserEditForm;
