import React from "react";
import {
  Backdrop,
  Box,
  Container,
  Paper,
  InputAdornment,
  TextField,
  IconButton,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import { CloseRounded, SearchOutlined } from "@mui/icons-material";

import ClientResult from "./resultList/ClientResult";
import CustomTabPanel from "../../Shared/CustomTabPanel";
import LeadResult from "./resultList/LeadResult";
import TransactionResult from "./resultList/TransactionResult";
import SearchResultSkeleton from "./SearchResultSkeleton";
import WorkflowResult from "./resultList/WorkflowResult";
import ApplicationResult from "./resultList/ApplicationResult";
import CourseResult from "./resultList/CourseResult";
import UniversityResult from "./resultList/UniversityResult";
import SearchResultTabs from "./SearchResultTabs";
import TaskResult from "./resultList/TaskResult";

import { useDebounce } from "../../../hooks/useDebounce";
import { useLazyGetSearchResultQuery } from "../../../rtkStore/services/searchApi";
import { useSidebarContext } from "../../../layouts/SidebarLayout/SidebarContextProvider";

const AppSearchbar = () => {
  const [showSearch, setShowSearch] = React.useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState("All");

  const { setShowSidebar } = useSidebarContext();

  const { debouncedKeyword } = useDebounce(keyword, 500);

  // RTK Query
  const [
    searchKeyword,
    {
      data,
      isLoading: loadingSearchResults,
      isFetching: fetchingSearchResults,
    },
  ] = useLazyGetSearchResultQuery();

  // methods
  const handleToggleShow = () => {
    setShowSearch((prev) => !prev);
    setShowSidebar(showSearch);
    setKeyword("");
    setSearchResults(null);
  };

  const searchResultCounts = React.useMemo(() => {
    return searchResults
      ? Object.keys(searchResults).map((key) => ({
          [key]: searchResults[key].length,
        }))
      : [];
  }, [searchResults]);

  const totalResultCount = React.useMemo(() => {
    return Object.values(Object.assign({}, ...searchResultCounts)).reduce(
      (acc, el) => (acc += el),
      0
    );
  }, [searchResultCounts]);

  React.useEffect(() => {
    if (debouncedKeyword) searchKeyword({ keyword: debouncedKeyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword]);

  React.useEffect(() => {
    if (!data) return;
    setSearchResults(data);

    return () => {
      setSearchResults([]);
    };
  }, [data]);

  return (
    <>
      <div
        className="max-w-sm flex-grow rounded border border-gray-200 bg-gray-100 px-4 py-2 text-gray-500"
        onClick={handleToggleShow}
      >
        Quick Search...
      </div>

      <Backdrop
        open={showSearch}
        sx={{
          color: "#fff",
          backdropFilter: "blur(10px)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="md" style={{ position: "absolute", top: 50 }}>
          <Paper variant="outlined">
            <Box
              display="flex"
              justifyContent="space-between"
              paddingX={3}
              paddingTop={2}
            >
              <div className="font-semibold text-gray-500">Quick Search</div>

              <Button variant="text" onClick={handleToggleShow}>
                Close
              </Button>
            </Box>

            <Box paddingX={3} paddingTop={0.5} paddingBottom={2}>
              <TextField
                autoFocus
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                fullWidth
                placeholder="Search by typing keywords..."
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!fetchingSearchResults ? (
                        <SearchOutlined />
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!!keyword.length && (
                        <IconButton onClick={() => setKeyword("")}>
                          <CloseRounded />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Divider />

            {!loadingSearchResults && !fetchingSearchResults ? (
              <>
                <SearchResultTabs
                  tab={activeTab}
                  searchResultCounts={
                    searchResultCounts &&
                    Object.assign({}, ...searchResultCounts)
                  }
                  handleTabChange={(_, tab) => setActiveTab(tab)}
                />

                <Box paddingY={2}>
                  <Box
                    className="max-h-[450px] overflow-y-scroll "
                    onClick={handleToggleShow}
                  >
                    {searchResults && Object.keys(searchResults).length ? (
                      totalResultCount > 0 ? (
                        <>
                          <CustomTabPanel activeTab={activeTab} value="All">
                            <Box display="flex" flexDirection="column" gap={1}>
                              {!!searchResults["Lead"]?.length && (
                                <LeadResult leads={searchResults["Lead"]} />
                              )}

                              {!!searchResults["Client"]?.length && (
                                <ClientResult
                                  clients={searchResults["Client"]}
                                />
                              )}

                              {!!searchResults["Transaction"]?.length && (
                                <TransactionResult
                                  transactions={searchResults["Transaction"]}
                                />
                              )}

                              {!!searchResults["Workflow"]?.length && (
                                <WorkflowResult
                                  workflows={searchResults["Workflow"]}
                                />
                              )}

                              {!!searchResults["Application"]?.length && (
                                <ApplicationResult
                                  applications={searchResults["Application"]}
                                />
                              )}

                              {!!searchResults["Course"]?.length && (
                                <CourseResult
                                  courses={searchResults["Course"]}
                                />
                              )}

                              {!!searchResults["University"]?.length && (
                                <UniversityResult
                                  universities={searchResults["University"]}
                                />
                              )}

                              {!!searchResults["Task"]?.length && (
                                <TaskResult tasks={searchResults["Task"]} />
                              )}
                            </Box>
                          </CustomTabPanel>

                          <CustomTabPanel activeTab={activeTab} value="Lead">
                            {!!searchResults["Lead"]?.length && (
                              <LeadResult leads={searchResults["Lead"]} />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel activeTab={activeTab} value="Client">
                            {!!searchResults["Client"]?.length && (
                              <ClientResult clients={searchResults["Client"]} />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel
                            activeTab={activeTab}
                            value="Transaction"
                          >
                            {!!searchResults["Transaction"]?.length && (
                              <TransactionResult
                                transactions={searchResults["Transaction"]}
                              />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel
                            activeTab={activeTab}
                            value="Workflow"
                          >
                            {!!searchResults["Workflow"]?.length && (
                              <WorkflowResult
                                workflows={searchResults["Workflow"]}
                              />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel
                            activeTab={activeTab}
                            value="Application"
                          >
                            {!!searchResults["Application"]?.length && (
                              <ApplicationResult
                                applications={searchResults["Application"]}
                              />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel activeTab={activeTab} value="Course">
                            {!!searchResults["Course"]?.length && (
                              <CourseResult courses={searchResults["Course"]} />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel
                            activeTab={activeTab}
                            value="University"
                          >
                            {!!searchResults["University"]?.length && (
                              <UniversityResult
                                universities={searchResults["University"]}
                              />
                            )}
                          </CustomTabPanel>

                          <CustomTabPanel activeTab={activeTab} value="Task">
                            {!!searchResults["Task"]?.length && (
                              <TaskResult tasks={searchResults["Task"]} />
                            )}
                          </CustomTabPanel>
                        </>
                      ) : (
                        <div className="grid h-44 place-content-center text-center font-medium text-gray-500">
                          <p>Nothing matched with the keyword!</p>
                          <p>Try using another keyword.</p>
                        </div>
                      )
                    ) : (
                      <div className="grid h-44 place-content-center  text-center font-medium text-gray-500">
                        <p>Your search results will apper here!</p>
                        <p>Type something to search!</p>
                      </div>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <SearchResultSkeleton />
            )}
          </Paper>
        </Container>
      </Backdrop>
    </>
  );
};

export default AppSearchbar;
