import React from "react";

import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { BookmarkAddOutlined } from "@mui/icons-material";

import ResultItem from "../ResultItem";

const LeadResult = ({ leads = [] }) => {
  return (
    <Box>
      <div className="mb-2 px-5 font-semibold">Leads</div>

      {leads.map((lead, index) => (
        <Link to={`/app/lead/${lead?._id}/detail`} key={lead?._id}>
          <ResultItem
            title={`${lead.firstName} ${lead.lastName}`}
            subtitle={lead.createdAt}
            icon={() => <BookmarkAddOutlined color="primary" />}
          />

          {index !== leads.length - 1 && <Divider />}
        </Link>
      ))}
    </Box>
  );
};

export default LeadResult;
