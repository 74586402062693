import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import WithHeader from "../../../../layouts/WithHeader";
import UserChangePasswordForm from "../userComponents/UserChangePasswordForm";

import {
  useDetailUserQuery,
  useEditDetailPasswordMutation,
} from "../../../../rtkStore/services/userApi";

const AccountUserChangePasswordScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  // RTK Query
  const {
    data: userDetail,
    isLoading: loadingUserDetail,
    error,
  } = useDetailUserQuery(id);

  const [updatePassword, { isLoading: updatingUserPassword }] =
    useEditDetailPasswordMutation();

  const breadcrumbs = [
    { title: "Users", path: "/app/user/account/list" },
    {
      title: userDetail ? userDetail?.name : "User Detail",
      path: `/app/user/account/${id}/detail`,
    },
    { title: "Change Password" },
  ];

  // methods
  const handleUpdateUserPassword = async (data) => {
    try {
      await updatePassword({
        id: userDetail?._id,
        password: data?.password,
      }).unwrap();
      toast.success("User's password successfully updated!");
      navigate(breadcrumbs[1]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update password!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={updatingUserPassword || loadingUserDetail}
      error={error}
    >
      <UserChangePasswordForm
        isBusy={updatingUserPassword || loadingUserDetail}
        onSubmit={handleUpdateUserPassword}
        onCancel={() => navigate(breadcrumbs[1]?.path)}
      />
    </WithHeader>
  );
};

export default AccountUserChangePasswordScreen;
