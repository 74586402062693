import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BlogForm from "./blogComponents/BlogForm";
import WithHeader from "../../../layouts/WithHeader";

import { useCreateBlogMutation } from "../../../rtkStore/services/blogApi";

const BlogCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Blogs",
      path: "/app/blog/list",
    },
    {
      title: "New Blog",
    },
  ];

  // RTK Query
  const [createBlog, { isLoading: creatingBlog, error }] =
    useCreateBlogMutation();

  // methods
  const handleBlogCreate = (data) => {
    createBlog(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path);
        toast.success("Blog created successfully");
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <>
      <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingBlog} error={error}>
        <BlogForm isBusy={creatingBlog} onSubmit={handleBlogCreate} />
      </WithHeader>
    </>
  );
};

export default BlogCreateScreen;
