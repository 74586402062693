import React from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";

import { useGetUrlParams } from "../../../../hooks/useGetUrlParams";

import CatalogueCourseCard from "./CatalogueCourseCard";
import EmptyResult from "../../../../components/Shared/Lottie/EmptyResult";
import SearchingLoader from "../../../../components/Shared/Lottie/SearchingLoader";

const CatalogueSearchResult = ({
  isBusy = false,
  searchResult = [],
  page = 1,
  size = 10,
  totalItems = 0,
}) => {
  const [_, setSearchParams] = useSearchParams();

  const _catalogueSearchParams = useGetUrlParams();

  if (isBusy) return <SearchingLoader />;

  if (searchResult?.length <= 0) return <EmptyResult />;

  return (
    <>
      <div className="flex flex-col space-y-6">
        {searchResult?.map((course) => (
          <CatalogueCourseCard key={course?._id} course={course} />
        ))}
      </div>

      <Pagination
        shape="rounded"
        variant="outlined"
        page={parseInt(page)}
        count={Math.ceil(totalItems / parseInt(size))}
        boundaryCount={2}
        onChange={(_, val) =>
          setSearchParams({ ..._catalogueSearchParams, page: val })
        }
        className="mt-10"
      />
    </>
  );
};

export default CatalogueSearchResult;
