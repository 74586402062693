import React from "react";

import { Link } from "react-router-dom";
import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";

import { useGetDashboardStatsQuery } from "../../rtkStore/services/dashboardApi";

import getMuiIcon from "../../utils/getMuiIcon";

const StatsCard = ({ title, count, iconName }) => {
  return (
    <Paper elevation={0} variant="outlined">
      <div className="flex space-x-8 p-8">
        <div
          className={`grid h-14 w-14 place-content-center rounded-full bg-blue-50`}
        >
          <div className="text-blue-400">{getMuiIcon(iconName)}</div>
        </div>
        <div className="flex flex-col space-y-1">
          <div className="text-2xl font-semibold">{count}</div>
          <div className="text-xs font-medium text-gray-400">{title}</div>
        </div>
      </div>
    </Paper>
  );
};

const StatsCardSkeleton = () => {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Skeleton variant="circle" width="100%" height={100} />
    </Box>
  );
};

const DashboardScreen = () => {
  // const colorAccents = ["blue", "indigo", "blue"];

  // const cardColorAccents = colorAccents.map((accent) => ({
  //   iconBg: `bg-${accent}-100`,
  //   iconColor: `text-${accent}-500`,
  // }));

  // RTK Query
  const { data: statsData, isLoading } = useGetDashboardStatsQuery();

  return (
    <>
      <Typography variant="h6">Analytic Overview</Typography>

      <br />

      {!isLoading ? (
        <Grid container spacing={4}>
          <Grid item md={3}>
            <StatsCard
              iconName="BookmarkAddOutlined"
              title="Open Leads"
              count={
                <div className="flex items-baseline space-x-2">
                  <div className="text-2xl">
                    {statsData?.leadStats?.stats?.find(
                      (el) => el?._id === "Open"
                    )?.count ?? 0}
                  </div>
                  <div>/</div>
                  <div className="text-xxl">{statsData?.leadStats?.total}</div>
                </div>
              }
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="GroupOutlined"
              title="Open Clients"
              count={
                <div className="flex items-baseline space-x-2">
                  <div className="text-2xl">
                    {statsData?.clientStats?.stats?.find(
                      (el) => el?._id === "Open"
                    )?.count ?? 0}
                  </div>
                  <div>/</div>
                  <div className="text-xxl">
                    {statsData?.clientStats?.total}
                  </div>
                </div>
              }
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="ReceiptLongOutlined"
              title="Open Transactions"
              count={
                <div className="flex items-baseline space-x-2">
                  <div className="text-2xl">
                    {statsData?.transactionStats?.stats?.find(
                      (el) => el?._id === "Open"
                    )?.count ?? 0}
                  </div>
                  <div>/</div>
                  <div className="text-xxl">
                    {statsData?.transactionStats?.total}
                  </div>
                </div>
              }
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="AddTask"
              title="Total Tasks"
              count={statsData?.totalTasks ?? 0}
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="AccountBalanceOutlined"
              title="Total Universities"
              count={statsData?.totalUniversities ?? 0}
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="MenuBookOutlined"
              title="Total Courses"
              count={statsData?.totalCourses ?? 0}
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="DriveFileRenameOutline"
              title="Total Applications"
              count={statsData?.totalApplications ?? 0}
            />
          </Grid>

          <Grid item md={3}>
            <StatsCard
              iconName="AccountTreeOutlined"
              title="Total Workflows"
              count={statsData?.totalWorkflows ?? 0}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          {new Array(8).fill(null)?.map((_, index) => (
            <Grid key={index} item md={3}>
              <StatsCardSkeleton />
            </Grid>
          ))}
        </Grid>
      )}

      <br />

      <br />

      <div className="flex justify-center text-center">
        <div className="w-2/3 tracking-wider text-gray-600">
          <span>
            Welcome to{" "}
            <span className="font-medium">First Source World(Alpha)</span>. The
            system is under developing phase, so the final look and features of
            the system might vary from the current one.
          </span>
        </div>
      </div>

      <br />

      <div className="flex flex-col items-center space-y-2">
        <div className="font-medium">
          Need some features, or have some issues? Make sure to provide
          feedback.
        </div>

        <Link to="/app/feedback/create">
          <Button variant="outlined">New Feedback</Button>
        </Link>
      </div>

      {/* <Box display="flex" alignItems="center" gap={1} marginBottom={2}>
        <NewReleasesOutlined color="primary" />

        <Typography variant="h6">What's New?</Typography>
      </Box>

      <Paper elevation={0} variant="outlined">
        <Box padding={3}>
          <ul className="flex flex-col space-y-3">
            <Typography>
              A quick glance of the new features & improvements:
            </Typography>
            {recentChanges.map((change, index) => (
              <li key={index} className="list-inside list-disc">
                <span className="font-medium tracking-wider text-blue-600">
                  {change}
                </span>
              </li>
            ))}
          </ul>
        </Box>
      </Paper> */}
    </>
  );
};

export default DashboardScreen;
