import React from "react";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Box, Button, Grid, TextField } from "@mui/material";

import WithHeader from "../../../../layouts/WithHeader";

import { useAuth } from "../../../../hooks/useAuth";
import { useEditProfilePasswordMutation } from "../../../../rtkStore/services/userApi";

const ChangePasswordScreen = () => {
  const navigate = useNavigate();

  const { loggedInUser } = useAuth();

  const breadcrumbs = [
    {
      title: "My Profile",
      path: "/app/user/me",
    },
    { title: "Change Password" },
  ];

  // RTK Query
  const [updatePassword, { isLoading: updatingPassword }] =
    useEditProfilePasswordMutation();

  // react form hook
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = React.useRef({});
  password.current = watch("password", "");

  // methods
  const handleUpdatePassword = async (data) => {
    try {
      await updatePassword({
        id: loggedInUser?._id,
        previousPassword: data.previousPassword,
        password: data.password,
      }).unwrap();
      toast.success("Password successfully updated!");
      navigate(breadcrumbs[0]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update password!");
    }
    console.log(data);
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={updatingPassword}>
      <Box padding={3}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <TextField
              {...register("previousPassword", {
                required: "Current Password is required",
              })}
              fullWidth
              label="Current Password"
              placeholder="Enter current password"
              size="small"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            {errors.previousPassword && (
              <p className="error">{errors.previousPassword.message}</p>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <TextField
              {...register("password", {
                required: "New Password is required",
              })}
              fullWidth
              label="New Password"
              placeholder="Enter new password"
              size="small"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <TextField
              {...register("confirmPassword", {
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
              fullWidth
              label="Confirm Password"
              placeholder="Confirm new password"
              size="small"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button
          // disabled={updatingUserProfile || loadingUserProfile}
          onClick={() => navigate(breadcrumbs[0]?.path)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          // disabled={updatingUserProfile || loadingUserProfile}
          onClick={handleSubmit((data) => handleUpdatePassword(data))}
        >
          Change Password
        </Button>
      </Box>
    </WithHeader>
  );
};

export default ChangePasswordScreen;
