import React from 'react';
import { components } from 'react-select';

//  NOTE: callback methods are passed into Select from the Forms

//  Components for react-select

//  component: Menu
export const ReactSelectAddMenuComponent = ({ children, innerProps, ...props }) => {
  const { selectProps } = props;
  const { optionAdd, buttonName } = selectProps;

  return (
    <components.Menu {...props} innerProps={innerProps}>
      {/* children - options passed in */}
      <div>{children}</div>

      {/* optionAdd - action that will be called once button is clicked; buttonName - dynamic button name; */}
      <div>
        <button className='react-select-add-button' onClick={optionAdd}>
          {buttonName}
        </button>
      </div>
    </components.Menu>
  );
};
