import React from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import Select from "react-select";

import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AuthorIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { useAuth } from "../../../../hooks/useAuth";
import { DeleteDialog } from "../../../../components/Shared/DeleteDialog";
import { AddTagModal } from "../../../../components/Shared/AddTagModal";
import { useSearchParams } from "react-router-dom";
import { useKnowledgebaseContext } from "../../../../context/KBContext";
import { useAppContext } from "../../../../context/AppContext";
import { useBasicEditKnowledgebaseMutation, useEditQMSMentionsMutation } from "../../../../rtkStore/services/knowledgebaseApi";
import { useListListQuery } from "../../../../rtkStore/services/listService";
import { formatDate } from "../../../../utils/handleFormatDate";
import { CONTEXT_CONSTANTS } from "../../../../constants/contextConstants";
import { ReactSelectAddMenuComponent } from "../KBComponents/ReactSelectAddOption";
import { LoadingButton } from "../KBComponents/LoadingButton";
import { KBDocumentMentionModal } from "./KBDocumentMentionModal";
export const KBDetailMeta = ({ onKBFilterChange }) => {
  // states

  const [selectedTags, setSelectedTags] = React.useState([]);
  const [isAddTagModalActive, setIsAddTagModalActive] = React.useState(false);
  const [isDeleteTagModalActive, setIsDeleteTagModalActive] =
    React.useState(false);
  const [selectedTagToDelete, setSelectedTagToDelete] = React.useState(null);
  const [isPublishDocumentModalActive, setIsPublishDocumentModalActive] =
    React.useState(false);
  const [addTagAnchorEl, setAddtagAnchorEl] = React.useState(null);
  const [selectedDocumentMentionToDelete, setSelectedDocumentMentionToDelete] =
    React.useState(null);
  const [
    isDeleteCaseMentionModalActive,
    setIsDeleteDocumentMentionModalActive,
  ] = React.useState(false);
  const [isDocumentMentionModalActive, setIsDocumentMentionModalActive] =
    React.useState(false);
  const tagAnchorPopperOpen = Boolean(addTagAnchorEl);

  // hooks
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const { state: articleState } = useKnowledgebaseContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const selectedArticle = articleState?.selectedArticle;
  const activeTag = searchParams.get("tag") || "";

  // rtk queries
  const [basicEditKnowledgebase, { isLoading: isEditingArticle }] =
    useBasicEditKnowledgebaseMutation();
  const [editQMSMention, { isLoading: isEditingQMSMentions }] =
    useEditQMSMentionsMutation();
  const { isLoading: isLoadingTags, data: tags = [] } = useListListQuery({
    type: "Knowledgebase Tags",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const isChangePublishStatusBtnVisible = () => {
    if (selectedArticle?.publishStatus === "Published") {
      return false;
    } else if (selectedArticle?.publishStatus === "Draft") {
      return (
        user?.role === "Admin" ||
        selectedArticle?.author?._id === user?._id?.toString() ||
        selectedArticle?.approver?._id === user?._id?.toString() ||
        selectedArticle?.publisher?._id === user?._id?.toString()
      );
    } else if (selectedArticle?.publishStatus === "Submitted") {
      return selectedArticle?.approver?._id === user?._id?.toString();
    } else if (selectedArticle?.publishStatus === "Approved") {
      return selectedArticle?.publisher?._id === user?._id?.toString();
    } else {
      return false;
    }
  };

  const getPublishStatusFields = () => {
    let primaryBtnText = "Submit Document";
    let title = "Submit Document";
    let subtitle = "Are you sure you want to submit this document?";
    let loadingText = "Submitting Document...";
    let payload = {
      publishStatus: "Submitted",
    };

    if (selectedArticle.publishStatus === "Submitted") {
      primaryBtnText = "Approve Document";
      title = "Approve Document";
      subtitle = "Are you sure you want to approve this document?";
      loadingText = "Approving Document...";
      payload = {
        publishStatus: "Approved",
      };
    } else if (selectedArticle.publishStatus === "Approved") {
      primaryBtnText = "Publish Document";
      title = "Publish Document";
      subtitle = "Are you sure you want to publish this document?";
      loadingText = "Publishing Document...";
      payload = {
        publishStatus: "Published",
        publishedBy: user._id,
        publishedDate: new Date(),
      };
    }

    return {
      primaryBtnText,
      title,
      subtitle,
      loadingText,
      payload,
    };
  };

  const handleBasicEditArticle = async (data) => {
    if (isEditingArticle) return;

    try {
      const payload = {
        id: selectedArticle._id,
        ...selectedArticle,
        ...data,
      };

      await basicEditKnowledgebase(payload).unwrap();
      setIsPublishDocumentModalActive(false);
      setAddtagAnchorEl(null);
      setIsDeleteTagModalActive(false);
      setSelectedTagToDelete(null);
    } catch (err) {
      const errorMessage = err?.data?.message || "Couldn't edit knowledgebase";
      toast.error(errorMessage);
    }
  };

  const handleEditQMSMention = async () => {
    try {
      if (isEditingQMSMentions) return;

      const payload = {
        id: selectedArticle?._id,
        mentions: selectedArticle?.mentions
          ?.filter((g) => g._id !== selectedDocumentMentionToDelete?._id)
          ?.map((x) => x?._id),
      };

      await editQMSMention(payload).unwrap();
      toast.success("Document mentions updated successfully");

      setIsDeleteDocumentMentionModalActive(false);
      setSelectedDocumentMentionToDelete(null);
    } catch (error) {
      const errorMessage =
        error?.data?.message || "Couldn't update document mentions";
      toast.error(errorMessage);
    }
  };

  if (!selectedArticle)
    return (
      <Box
        // className="case-detail-meta-pane expanded"
        minHeight="calc(100vh - 200px)"
        className={`case-detail-meta-pane ${appState?.isArticleDetailMetaPaneExpanded && "expanded"
          }`}
        // minWidth={"400px"}
        position="relative"
        borderRight="1px solid #f1f1f1"
        sx={{
          backgroundColor: "#fff",
        }}
      ></Box>
    );

  return (
    <Box
      minHeight="calc(100vh - 200px)"
      className={`case-detail-meta-pane ${appState?.isArticleDetailMetaPaneExpanded && "expanded"
        }`}
      position="relative"
      borderRight="1px solid #f1f1f1"
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Box
        height={"70px"}
        paddingX={2}
        paddingY={1}
        position="sticky"
        top={0}
        left={0}
        zIndex={10}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: "#fff",
          overflowY: "auto",
          overflowX: "hidden",
          borderBottom: "1px solid #f1f1f1",
          overflow: "hidden",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ gap: "8px", color: "#435883", marginTop: "4px" }}
            >
              <AuthorIcon />

              <Box marginTop={-1}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "400", fontSize: 14 }}
                >
                  {selectedArticle?.author?.name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="text-secondary"
                  style={{
                    fontSize: 12
                  }}
                >
                  {selectedArticle?.author?.email}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: 14,
                color: "#435883",
              }}
            >
              {formatDate(selectedArticle?.publishedDate, DateTime.DATE_MED, {
                includeTime: false,
              })}
            </Typography>
          </Box>

          {appState.isArticleDetailMetaPaneExpanded && (
            <IconButton
              style={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                zIndex: 20,
              }}
              onClick={() =>
                appDispatch({
                  type: CONTEXT_CONSTANTS.TOGGLE_ARTICLE_DETAIL_META_PANE,
                  payload: {
                    isArticleDetailMetaPaneExpanded: false,
                  },
                })
              }
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box paddingX={1} paddingY={1}>
        <Box
          marginBottom={2}
          display="flex"
          alignItems="center"
          sx={{ gap: "4px" }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",

              color: "#435883",
            }}
          >
            {selectedArticle?.version}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "#435883",
            }}
          >
            total version{selectedArticle?.version === 1 ? "" : "s"} available
          </Typography>
        </Box>

        <Tooltip
          title={`Article written on ${formatDate(
            selectedArticle?.createdAt,
            DateTime.DATE_MED,
            {
              includeTime: false,
            }
          )}`}
        >
          <Box
            marginBottom={3}
            display="flex"
            alignItems="center"
            sx={{ gap: "8px", color: "#435883" }}
          >
            <CalendarMonthIcon className="fs-14" />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "500",
                fontSize: 14,
              }}
            >
              {formatDate(selectedArticle?.createdAt, DateTime.DATE_MED, {
                includeTime: false,
              })}
            </Typography>
          </Box>
        </Tooltip>

        <Tooltip
          title={`Article published on ${formatDate(
            selectedArticle?.publishedDate,
            DateTime.DATE_MED,
            {
              includeTime: false,
            }
          )}`}
        >
          <Box
            marginBottom={3}
            display="flex"
            alignItems="center"
            sx={{ gap: "8px", color: "#435883" }}
          >
            <CalendarMonthIcon className="fs-14" />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "500",
                fontSize: 14,
              }}
            >
              {formatDate(selectedArticle?.publishedDate, DateTime.DATE_MED, {
                includeTime: false,
              }) || "N/A"}
            </Typography>
          </Box>
        </Tooltip>

        <Box
          marginBottom={3}
          display="flex"
          alignItems="center"
          sx={{ gap: "8px", color: "#435883" }}
        >
          <VisibilityIcon className="fs-14" />
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
            }}
          >
            Visible to
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {selectedArticle?.visibility || "Everyone"}
          </Typography>
        </Box>

        <Box
          marginBottom={2}
          display="flex"
          alignItems="center"
          sx={{ gap: "8px", color: "#435883" }}
        >
          {/* <i className="fa-solid fa-feather"></i> */}
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
            }}
          >
            Author
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {selectedArticle?.author?.name || "_"}
          </Typography>
        </Box>

        <Box
          marginBottom={2}
          display="flex"
          alignItems="center"
          sx={{ gap: "8px", color: "#435883" }}
        >
          {/* <i className="fa-solid fa-user-graduate"></i> */}

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
            }}
          >
            Responsible User
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {selectedArticle?.responsibleInfo?.name || "_"}
          </Typography>
        </Box>

        {(selectedArticle?.publishStatus === "Approved" ||
          selectedArticle?.publishStatus === "Published") && (
            <Box
              marginBottom={2}
              display="flex"
              alignItems="center"
              sx={{ gap: "8px", color: "#435883" }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                Approved By
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {selectedArticle?.approver?.name || "_"}
              </Typography>
            </Box>
          )}

        {selectedArticle?.publishStatus === "Published" && (
          <Box
            marginBottom={2}
            display="flex"
            alignItems="center"
            sx={{ gap: "8px", color: "#435883" }}
          >
            {/* <i className="fa-solid fa-user-graduate"></i> */}

            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: 14,
              }}
            >
              Published By
            </Typography>

            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "500",
                fontSize: 14,
              }}
            >
              {selectedArticle?.publisher?.name || "_"}
            </Typography>
          </Box>
        )}

        <Box
          marginBottom={2}
          display="flex"
          alignItems="center"
          sx={{ gap: "8px", color: "#435883" }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "400",
              fontSize: 14,
            }}
          >
            Publish Status
          </Typography>

          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {selectedArticle?.publishStatus}
          </Typography>
        </Box>

        <Box marginBottom={2}>
          <label
            htmlFor="responsibleFor"
            className="form-label"
            style={{ marginBottom: "-8px" }}
          >
            Mentions
          </label>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ gap: "8px" }}
            marginTop={0.5}
          >
            {selectedArticle?.mentions
              ?.filter((mention) => mention?.name)
              ?.map((mention) => (
                <Box
                  key={`tag-${mention?._id}`}
                  paddingX={1}
                  borderRadius="5px"
                  border="1px solid #435883"
                  display="flex"
                  alignItems="center"
                  sx={{ gap: "6px" }}
                  className={`knowledgebase-tag `}
                  onClick={() => {
                    // if (group?._id === activeCaseGroup) {
                    //   onCaseFilterChange({ filter: "caseGroup", value: "" });
                    // } else {
                    //   onCaseFilterChange({
                    //     filter: "caseGroup",
                    //     value: group._id,
                    //   });
                    // }
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "400",
                      fontSize: 14,
                      color: "#435883",
                      cursor: "pointer",
                    }}
                  >
                    @{mention?.name}
                  </Typography>

                  <Tooltip title="Delete Mention">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDocumentMentionToDelete(mention);
                        setIsDeleteDocumentMentionModalActive(true);
                      }}>
                      <DeleteIcon style={{ fontSize: 14 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}

            {!selectedArticle?.mentions?.length > 0 && (
              <Typography
                variant="subtitle1"
                // className="label-color"
                style={{ fontSize: 14, fontStyle: "italic", width: "100%" }}
              >
                No Mentions Available
              </Typography>
            )}

            <Tooltip title="Add Mention">
              <IconButton
                className="add-tag-btn"
                size="small"
                onClick={() => {
                  setIsDocumentMentionModalActive(true);
                }}
                style={{
                  color: "#435883",
                  borderRadius: "5px",
                  border: "1px solid #435883",
                  padding: "4px 6px",
                }}
              >
                <AddIcon
                  className="add-tag-btn"
                  style={{ fontSize: 14 }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box marginTop={2} sx={{ gap: "8px" }}>
          <label
            htmlFor="tags"
            className="form-label"
            style={{ marginBottom: "-8px" }}
          >
            Tags
          </label>

          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{ gap: "8px" }}
            marginTop={0.5}
          >
            {selectedArticle?.tags?.map((tag) => (
              <Box
                key={`tag-${tag?._id}`}
                paddingX={1}
                borderRadius="5px"
                border="1px solid #435883"
                display="flex"
                alignItems="center"
                sx={{ gap: "6px" }}
                className={`knowledgebase-tag ${activeTag === tag?.name && "active"
                  }`}
                onClick={() => {
                  if (tag?.name === activeTag) {
                    onKBFilterChange({ filter: "tag", value: "" });
                  } else {
                    onKBFilterChange({ filter: "tag", value: tag.name });
                  }
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "#435883",
                    cursor: "pointer",
                  }}
                >
                  {tag?.name}
                </Typography>

                <Tooltip title="Delete tag">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedTagToDelete(tag);
                      setIsDeleteTagModalActive(true);
                    }}>
                    <DeleteIcon style={{ fontSize: 14 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}

            {!selectedArticle?.tags?.length && (
              <Box>
                <Typography
                  className="form-label form-font"
                  style={{ fontSize: 14 }}
                >
                  No tags available
                </Typography>
              </Box>
            )}

            <ClickAwayListener
              onClickAway={(e) => {
                try {
                  const arr = Array.from(e?.target?.classList) || [];
                  if (arr?.includes("add-tag-btn")) return;
                  setAddtagAnchorEl(null);
                  setSelectedTags(null);
                } catch (err) { }
              }}
            >
              <Box>
                <IconButton
                  className="add-tag-btn"
                  size="small"
                  onClick={(e) => {
                    console.log("clicked...");
                    setSelectedTags(
                      !selectedArticle?.tags
                        ? []
                        : selectedArticle?.tags?.map((t) => ({
                          label: t.name,
                          value: t._id,
                        }))
                    );
                    setAddtagAnchorEl(addTagAnchorEl ? null : e.currentTarget);
                  }}
                  style={{
                    color: "#3F51B5",
                    borderRadius: "5px",
                    border: "1px solid #435883",
                    padding: "4px 6px",
                  }}
                >
                  <AddIcon
                    style={{ fontSize: 14 }}
                  />
                </IconButton>
                <Popper
                  placement="bottom"
                  style={{
                    top: "6.5rem",
                    backgroundColor: "white",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    borderRadius: "5px",
                    zIndex: 999999,
                  }}
                  open={tagAnchorPopperOpen}
                  anchorEl={addTagAnchorEl}
                >
                  <Box
                    paddingY={2}
                    paddingX={2}
                    minHeight="360px"
                    maxHeight="360px"
                    minWidth="300px"
                    maxWidth="300px"
                  >
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 999999 }),
                      }}
                      className="form-font"
                      placeholder="Select tags..."
                      isLoading={isLoadingTags}
                      isMulti
                      value={selectedTags}
                      onChange={(data) => {
                        setSelectedTags(data);
                      }}
                      options={tags.map((t) => ({
                        label: t.name,
                        value: t._id,
                      }))}
                      components={{ Menu: ReactSelectAddMenuComponent }}
                      buttonName="Add another tag"
                      optionAdd={() => {
                        setIsAddTagModalActive(true);
                      }}
                    />

                    <Box marginTop={2}>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          handleBasicEditArticle({
                            tags: selectedTags.map((t) => t.value),
                          });
                        }}
                        startIcon={
                          <SaveIcon
                            style={{ color: "#fff", fontSize: 14 }}
                          />
                        }
                        loadingText="Saving Tags..."
                        loading={isEditingArticle}
                      >
                        Save Tags
                      </LoadingButton>
                    </Box>
                  </Box>
                </Popper>
              </Box>
            </ClickAwayListener>



          </Box>
        </Box>

        {isChangePublishStatusBtnVisible() && (
          <Box marginTop={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ThumbUpIcon />}
              onClick={() => setIsPublishDocumentModalActive(true)}
            >
              {getPublishStatusFields().title}
            </Button>
          </Box>
        )}
      </Box>

      {/* clear flag status modal */}
      {
        Boolean(isPublishDocumentModalActive) && (
          <DeleteDialog
            open={Boolean(isPublishDocumentModalActive)}
            isDeleteDialog={false}
            primaryBtnText={getPublishStatusFields().primaryBtnText}
            title={getPublishStatusFields().title}
            subtitle={getPublishStatusFields().subtitle}
            onDelete={() => {
              handleBasicEditArticle({
                ...getPublishStatusFields().payload,
              });
            }}
            isLoading={isEditingArticle}
            loadingText={getPublishStatusFields().loadingText}
            onCancel={() => {
              setIsPublishDocumentModalActive(false);
            }}
          ></DeleteDialog>
        )
      }

      {/* delete tag modal */}
      {
        Boolean(isDeleteTagModalActive) && (
          <DeleteDialog
            open={Boolean(isDeleteTagModalActive)}
            primaryBtnText="Delete Tag"
            title="Delete Tag?"
            subtitle=" Are you sure you want to delete this tag from the article?"
            onDelete={() => {
              if (selectedTagToDelete?.name === activeTag) {
                onKBFilterChange({ filter: "tag", value: "" });
              }
              handleBasicEditArticle({
                tags: selectedArticle?.tags.filter(
                  (t) => t?._id !== selectedTagToDelete?._id
                ),
              });
            }}
            isLoading={isEditingArticle}
            loadingText="Deleting tag..."
            onCancel={() => {
              setIsDeleteTagModalActive(false);
              setSelectedTagToDelete(null);
            }}
          ></DeleteDialog>
        )
      }

      {
        isAddTagModalActive && (
          <AddTagModal
            type="Knowledgebase Tags"
            isOpen={isAddTagModalActive}
            onCloseModal={() => {
              setIsAddTagModalActive(false);
            }}
          />
        )
      }

      {
        isDocumentMentionModalActive && (
          <KBDocumentMentionModal
            isOpen={isDocumentMentionModalActive}
            onCloseModal={() => {
              setIsDocumentMentionModalActive(false);
            }}
          />
        )
      }

      {/* delete mention modal */}
      {
        Boolean(isDeleteCaseMentionModalActive) && (
          <DeleteDialog
            open={Boolean(isDeleteCaseMentionModalActive)}
            primaryBtnText="Delete Mention"
            title="Delete Document Mention"
            subtitle=" Are you sure you want to delete this user from this document's mention list?"
            onDelete={() => {
              handleEditQMSMention();
            }}
            isLoading={isEditingQMSMentions}
            loadingText="Deleting mention..."
            onCancel={() => {
              setIsDeleteDocumentMentionModalActive(false);
              setSelectedDocumentMentionToDelete(null);
            }}
          ></DeleteDialog>
        )
      }
    </Box >
  );
};
