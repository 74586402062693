import React from "react"
import Banner from "../../components/Banner"
import image from "../assets/images/australiaLocation.png"
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  // Divider,
  Grid,
  Tabs,
  Typography,
} from "@mui/material"

import Tab from "@mui/material/Tab"

import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk"

const FSW_STATIC_LOCATIONS = [
  {
    country: "AUSTRALIA",
    locations: [
      {
        name: "Sydney Head Office",
        address: "27/2 O'Connell St, Parramatta NSW 2150",
        phone: "+61 (0)2 9639 4415",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d106129.99447658859!2d150.8632624!3d-33.7719741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a3fe17c14ed5%3A0xbb77abc560bb8c46!2sFSW%20Global%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1712275216571!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
      // {
      //   name: " Rooty Hill NSW",
      //   address: "39 Nicolaidis Cr, Rooty Hill NSW",
      //   phone: "+61 426 992 880",
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.360097713145!2d150.83519291580166!3d-33.777198021743025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129a6b34405cf1%3A0xef71fe5d4620290f!2s39%20Nicolaidis%20Cres%2C%20Rooty%20Hill%20NSW%202766%2C%20Australia!5e0!3m2!1sen!2snp!4v1677050044966!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: 'Belrose NSW',
      //   address: '4 Oates Place, Belrose NSW',
      //   phone: '+61 432 657 233',
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.391997771298!2d151.21745851580044!3d-33.72467831902582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d561cf1d194a9%3A0x3df82deb22678126!2s4%20Oates%20Pl%2C%20Belrose%20NSW%202085%2C%20Australia!5e0!3m2!1sen!2snp!4v1677050574947!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1657.4087492997246!2d150.9980756129162!3d-33.81702246224117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a2df98447663%3A0xc8bdf35e67b92de5!2s2%20O&#39;Connell%20St%2C%20Parramatta%20NSW%202150%2C%20Australia!5e0!3m2!1sen!2snp!4v1677044906922!5m2!1sen!2snp",
  },
  {
    country: "Bangladesh",
    locations: [
      {
        name: "Dhaka Office",
        address: "GreenBay Kazi Morning Glory, Plot-15, Road-03, Block-A, Section-11, Mirpur, Dhaka-1216, Bangladesh",
        phone: "+880 1781-997419",
        WhatsAppOnly: true,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d31843.669997561185!2d90.35756033921774!3d23.815147067092305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDQ5JzAwLjkiTiA5MMKwMjEnNTguMyJF!5e0!3m2!1sen!2sau!4v1712224213341!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Bhutan ",
    locations: [
      {
        name: "Thimpu Office - Staff Only",
        address: "Thimpu Bhutan",
        phone: "+97577364421",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113271.10287116347!2d89.56408097057006!3d27.477913996752008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e19419962037b7%3A0x7c01ffe2660560d1!2sThimphu%2C%20Bhutan!5e0!3m2!1sen!2sau!4v1712224957886!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Cambodia ",
    locations: [
      {
        name: "Phnom Penh Office - Staff Only",
        address: "Phnom Penh Cambodia",
        phone: "+855968601810",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250151.16277568956!2d104.72537731990248!3d11.57965400376811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3109513dc76a6be3%3A0x9c010ee85ab525bb!2sPhnom%20Penh%2C%20Cambodia!5e0!3m2!1sen!2sau!4v1712224848295!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Fiji ",
    locations: [
      {
        name: "Suva Office",
        address: "Unit 6, Meetex Building, Corner of Mark Street, Suva City ",
        phone: "+679 8055230",
        WhatsAppOnly: true,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3791.5756595847765!2d178.4263694!3d-18.1373768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6e1bdc27413a1533%3A0x1d95835aab25d591!2sMarks%20St%2C%20Suva%2C%20Fiji!5e0!3m2!1sen!2sau!4v1712224061547!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },
  {
    country: "Indonesia",
    locations: [
      {
        name: "Jakarta Office",
        address: "Setiabudi Atrium Unit 702, Jl. H.R. Rasuna Said Kav. 62, Jakarta, Indonesia, Jakarta",
        phone: "+62 877-6481-4836 ",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23094.267211285463!2d106.81327241906023!3d-6.219039837084244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f52369dc32a7%3A0x75d5c2b7ab32a27!2sFSW%20Indonesia%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1712223687233!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },
  {
    country: "India",
    locations: [
      {
        name: "New Delhi Office",
        address: "NEHRU PLACE-2, Nehru Place, New Delhi, Delhi 110019, India",
        phone: "+91 9045557600",
        WhatsAppOnly: true,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.6674276812482!2d77.25007939999999!3d28.5497144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3cf0e5d824d%3A0x9aba31c2770cc0f4!2sCHIRANJIV%20TOWER%2C%20NEHRU%20PLACE-2%2C%20Nehru%20Place%2C%20New%20Delhi%2C%20Delhi%20110019%2C%20India!5e0!3m2!1sen!2sau!4v1712224387187!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Nepal",
    locations: [
      {
        name: "Kathmandu office",
        address: "Munibhairab Marg, Tinkune, Kathmandu",
        phone: "+97715199596",
        WhatsAppOnly: true,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.063270428662!2d85.3477463!3d27.684439299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fea8d04eaf%3A0xd5ff8fbe8d604727!2sFirst%20Source%20World%20-%20Educational%20Consultancy!5e0!3m2!1sen!2sau!4v1712224522951!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  {
    country: "Philippines",
    locations: [
      {
        name: "Manila Office",
        address:
          "1701-02 17th Floor, OMM-Citra Bldg., 39 San Miguel Avenue, Ortigas Center, Pasig City 1605",
        phone: "+63 2 5310 4590",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15445.20684883053!2d121.060337!3d14.5818761!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c908394d4643%3A0x716abd9a4d9819ea!2sFSW%20Philippines%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1699345162833!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        ),
      },
      // {
      //   name: "GMA, Cavite",
      //   address: "San Lorenzo St., San Gabriel, GMA, Cavite 4117",
      //   phone: "+63 936 990 4560",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30928.190259097846!2d121.00017590507483!3d14.310050993329957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d65be8392e2b%3A0xdadd291f12b09cb4!2sGeneral%20Mariano%20Alvarez%2C%20Cavite%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063785437!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Baguio City",
      //   address: "Pinsao Proper, Baguio City 2600",
      //   phone: "+63 961 831 1990",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30619.983930964143!2d120.57126560593441!3d16.399515745065035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3391a16879def13f%3A0x8edef534be3a75c0!2sBaguio%2C%20Benguet%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063872948!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Balanga City, Bataan",
      //   address: "676 Maluya St., Central, Balanga City, Bataan 2100",
      //   phone: "+63 998 443 1681",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61757.849049416836!2d120.46175074084!3d14.663569615470808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33964009381ef663%3A0xe135d5b451871d25!2sCity%20of%20Balanga%2C%20Bataan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063912904!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Mariveles, Bataan",
      //   address: "268 Tibag St., Balon Anito Mariveles, Bataan 2105",
      //   phone: "+63 968 579 3107",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123631.04710331548!2d120.42994954672989!3d14.4576673309243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x339618ec08e4d1f1%3A0x287c14acd0973948!2sMariveles%2C%20Bataan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063952810!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Legazpi City, Albay",
      //   address: "0327 Purok 3, Cruzada, Legazpi City, Albay 4500",
      //   phone: "+63 947 373 0208",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124340.9396537795!2d123.70313423888933!3d13.121068970550278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a101687e9bf8a7%3A0x305252e78d14537a!2sLegazpi%20City%2C%20Albay%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677063984529!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Guinobatan, Albay",
      //   address: "Inamnan Pequeño, Guinobatan, Albay 4503",
      //   phone: "+63 995 254 6869",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124313.14259590059!2d123.51104513918696!3d13.175907246441557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a108974cf38a9f%3A0x43adeba52c590d5!2sGuinobatan%2C%20Albay%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064026795!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Tandag City, Surigao Del Sur",
      //   address:
      //     "Globle Meadows Subd., Rosario, Tandag City, Surigao Del Sur 8300",
      //   phone: "+63 946 101 0834",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126069.77589318984!2d126.06206202267772!3d9.092842229203159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3302373d1fdee1bf%3A0x9f84ea6460e30dd3!2sTandag%2C%20Surigao%20del%20Sur%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064059900!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: "Gozanga, Cagayan",
      //   address: "Purok Saranay, Cabiraoan, Gonzaga, Cagayan 3513",
      //   phone: "+63 961 831 1990",
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121235.63704910535!2d122.03482177579761!3d18.273524013466233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x338672cb198139f5%3A0x3fe9c9c3b9026523!2sGonzaga%2C%20Cagayan%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064092490!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
      // {
      //   name: 'Valenzuela City, Metro Manila',
      //   address: 'La Huerta Subd., Marulas, Valenzuela City, NCR 1440',
      //   phone: '+63 927 829 1425',
      //   WhatsAppOnly: false,
      //   embeddedMap: () => (
      //     <iframe
      //       src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61744.43805215936!2d120.94011214091427!3d14.711043839711492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b3f2c352666b%3A0x9af8d2dfa797d58!2sValenzuela%2C%20Metro%20Manila%2C%20Philippines!5e0!3m2!1sen!2snp!4v1677064124608!5m2!1sen!2snp'
      //       width='100%'
      //       height='100%'
      //       allowFullScreen=''
      //       loading='lazy'
      //       referrerPolicy='no-referrer-when-downgrade'
      //     />
      //   ),
      // },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
  },

  {
    country: "Sri Lanka",
    locations: [
      {
        name: "Colombo Office - Staff Only",
        address: "Colombo Sri Lanka",
        phone: "+94713266820",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63371.80453495061!2d79.77826514870617!3d6.921917519567244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae253d10f7a7003%3A0x320b2e4d32d3838d!2sColombo%2C%20Sri%20Lanka!5e0!3m2!1sen!2sau!4v1712225036507!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },
  // {
  //   country: "Nepal",
  //   locations: [
  //     {
  //       name: "Kathmandu",
  //       address: "51, Muni Bhairab Marg Tinkune, Kathmandu 44600",
  //       phone: "+977 1-5199596",
  //       embeddedMap: () => (
  //         <iframe
  //           src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.0717877982233!2d85.34638481547772!3d27.684176033115754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19fea8d04eaf%3A0xd5ff8fbe8d604727!2sFirst%20Source%20World%20-%20Educational%20Consultancy!5e0!3m2!1sen!2snp!4v1677064182692!5m2!1sen!2snp'
  //           width='100%'
  //           height='100%'
  //           allowFullScreen=''
  //           loading='lazy'
  //           referrerPolicy='no-referrer-when-downgrade'
  //         />
  //       ),
  //     },
  //   ],
  //   contactEmail: "fsw.contact@firstsourceworld.com",
  //   mapLink:
  //     "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  // },
  

  {
    country: "Vietnam ",
    locations: [
      {
        name: "Hanoi Office",
        address: " Đ. Nguyễn Chí Thanh Ward, Street, Đống Đa, Hà Nội 11513, Vietnam",
        phone: "+84 702 334 787",
        WhatsAppOnly: false,
        embeddedMap: () => (
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d567802.9844014653!2d105.49600036193686!3d20.974850391082516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab836e97cdbb%3A0xcf7d7a6590572f4b!2sFSW%20Vietnam%20Education%20%26%20Visa%20Services!5e0!3m2!1sen!2sau!4v1712223809858!5m2!1sen!2sau'
            width='100%'
            height='100%'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          />
        ),
      },
    ],
    contactEmail: "fsw.marketing@firstsourceworld.com",
    mapLink:
      "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  },

  

  


  

  

  

  
  // {
  //   country: "UAE",
  //   locations: [
  //     {
  //       name: "Abu Dhabi",
  //       address: "Abu Dhabi, UAE",
  //       phone: "+61 450 468 622",
  //       WhatsAppOnly: true,
  //       embeddedMap: () => (
  //         <iframe
  //           src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d232565.41897018426!2d54.39381162558035!3d24.387054129048508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e440f723ef2b9%3A0xc7cc2e9341971108!2sAbu%20Dhabi%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491355208!5m2!1sen!2sau%22'
  //           width='100%'
  //           height='100%'
  //           allowFullScreen=''
  //           loading='lazy'
  //           referrerPolicy='no-referrer-when-downgrade'
  //         />
  //       ),
  //     },

  //     {
  //       name: "Dubai",
  //       address: "Dubai, UAE",
  //       phone: "+61 450 468 622",
  //       WhatsAppOnly: true,
  //       embeddedMap: () => (
  //         <iframe
  //           src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81714.1828726183!2d55.19438959190715!3d25.15965690516927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491388761!5m2!1sen!2sau%22'
  //           width='100%'
  //           height='100%'
  //           allowFullScreen=''
  //           loading='lazy'
  //           referrerPolicy='no-referrer-when-downgrade'
  //         />
  //       ),
  //     },

  //     {
  //       name: "Sharjah",
  //       address: "Sharjah, UAE",
  //       phone: "+61 450 468 622",
  //       WhatsAppOnly: true,
  //       embeddedMap: () => (
  //         <iframe
  //           src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115409.13273028829!2d55.46303810014148!3d25.319809170782897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5f5fede7964b%3A0x2a830aa19c1f6d89!2sSharjah%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sau!4v1689491415841!5m2!1sen!2sau%22'
  //           width='100%'
  //           height='100%'
  //           allowFullScreen=''
  //           loading='lazy'
  //           referrerPolicy='no-referrer-when-downgrade'
  //         />
  //       ),
  //     },
  //   ],

  //   contactEmail: "fsw.contact@firstsourceworld.com",
  //   mapLink:
  //     "https://www.google.com/maps/place/First+Source+World+-+Educational+Consultancy/@27.6853338,85.3407459,16z/data=!4m9!1m2!2m1!1sMunibhairab+Marg,+Tinkune+KMC-32!3m5!1s0x39eb19fea8d04eaf:0xd5ff8fbe8d604727!8m2!3d27.6841652!4d85.3485827!15sCiFNdW5pIEJoYWlyYWIgTWFyZywgVGlua3VuZSBLTUMtMzKSARZlZHVjYXRpb25hbF9jb25zdWx0YW504AEA",
  // },
]

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Locations = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Banner image={image} title={"Our Service Locations"} />

      <Container style={{ marginBottom: "50px", marginTop: "5rem", maxWidth:"1300px" }}>
        {/* <Typography align='center'></Typography>
        <OurLocation addresses={FSW_STATIC_LOCATIONS} /> */}

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              inkBarStyle={{ backgroundColor: "#e77600" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#eb2428",
                },
              }}
              aria-label='basic tabs example'
            >
              {FSW_STATIC_LOCATIONS?.map((address, index) => (
                <Tab
                  key={index}
                  label={address?.country}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          <br />

          {/* <pre>{JSON.stringify(FSW_STATIC_LOCATIONS[value], null, 2)}</pre> */}
          <Grid container spacing={2}>
            {FSW_STATIC_LOCATIONS[value]?.locations?.map((loc, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} gap={2}>
                <Card style={{ height: "100%", padding: "5px 5px" }}>
                  <CardMedia style={{ height: "200px" }}>
                    {loc?.embeddedMap && <loc.embeddedMap />}
                  </CardMedia>

                  <CardContent style={{ height: "100%", padding: "5px 5px" }}>
                    <div className='py-1.5 text-center  font-medium'>
                      {loc?.name}
                    </div>

                    <div className='flex items-start space-x-4 py-1.5'>
                      <LocationOnIcon className='redColor' fontSize='small' />
                      <div className='flex-wrap text-sm'>{loc?.address}</div>
                    </div>

                    <div className='flex items-start space-x-4 py-1.5'>
                      <MailOutlineIcon className='redColor' fontSize='small' />
                      <div className='flex-wrap'>
                        <a
                          href={`mailto:${FSW_STATIC_LOCATIONS[value].contactEmail}`}
                          className='text-sm'
                        >
                          {FSW_STATIC_LOCATIONS[value].contactEmail}
                        </a>
                      </div>
                    </div>

                    {loc && loc.WhatsAppOnly ? (
                      <div className='flex items-start space-x-4 py-1.5'>
                        <WhatsAppIcon className='redColor' fontSize='small' />
                        <div className='flex-wrap text-sm'>{loc?.phone}</div>
                      </div>
                    ) : (
                      <div className='flex items-start space-x-4 py-1.5'>
                        <PhoneInTalkIcon
                          className='redColor'
                          fontSize='small'
                        />
                        <div className='flex-wrap text-sm'>{loc?.phone}</div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/*<CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel> */}
        </Box>
      </Container>
    </div>
  )
}

export default Locations
