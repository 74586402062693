import React from "react";

import { useAuth } from "../../../hooks/useAuth";

const UserGreeting = () => {
  const {
    loggedInUser: { name },
  } = useAuth();

  const date = new Date();
  const time = date.getHours();

  let greeting = "";

  if (time < 12) greeting = "Good Morning";
  if (time >= 12) greeting = "Good Afternoon";
  if (time >= 18) greeting = "Good Evening";

  return (
    <div className="text-gray-700">
      <span className="text-gray-500">{`${greeting},`}</span>{" "}
      <span className="font-medium">{name}</span>
    </div>
  );
};

export default UserGreeting;
