import React from "react"
import { Container, Typography } from "@mui/material"
import TestimonialsCard from "./TestimonialsCard"

const Testimonials = () => {
  return (
    <div className='step4Background'>
      <Container
        maxWidth='xl'
        style={{ marginTop: "4rem", marginBottom: "4rem" }}
      >
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          align='center'
          mt={3}
          mb={3}
          sx={{ fontSize: 28, fontWeight: 700 }}
        >
          We transform the lives of our students.
        </Typography>

        <TestimonialsCard />
      </Container>
    </div>
  )
}

export default Testimonials
