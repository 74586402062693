import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import UniversityForm from "./universityComponents/UniversityForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetUniversityDetailQuery,
  useUpdateUniversityMutation,
} from "../../../rtkStore/services/universityApi";

const UniversityEditScreen = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const breadcrumbs = [
    { title: "Universities", path: "/app/university/list" },
    { title: "Edit University" },
  ];

  // RTK Query
  const {
    data: universityDetail,
    isLoading: loadingUniversityDetail,
    error,
  } = useGetUniversityDetailQuery(id);

  const [updateUniversity, { isLoading: updatingUniversity }] =
    useUpdateUniversityMutation();

  // methods
  const handleUniversityUpdate = async (data) => {
    try {
      await updateUniversity(data).unwrap();
      toast.success("University successfull updated!");
      navigate(`/app/university/${id}/detail`);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update university");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingUniversityDetail || updatingUniversity}
      error={error}
    >
      <UniversityForm
        universityDetail={universityDetail}
        isBusy={loadingUniversityDetail || updatingUniversity}
        onSubmit={handleUniversityUpdate}
      />
    </WithHeader>
  );
};

export default UniversityEditScreen;
