import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import WithHeader from "../../../layouts/WithHeader";
import ClientForm from "./clientComponents/ClientForm";

import { useCreateClientMutation } from "../../../rtkStore/services/clientApi";

const ClientCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Clients",
      path: "/app/client/list",
    },

    { title: "New Client" },
  ];

  // RTK Query
  const [createClient, { isLoading: creatingClient, error }] =
    useCreateClientMutation();

  // methods
  const handleCreateClient = async (data) => {
    try {
      const { _id } = await createClient({ ...data }).unwrap();
      navigate(`/app/client/${_id}/detail`);
      toast.success("Client successfully created!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create Client!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} error={error} isBusy={creatingClient}>
      <ClientForm isBusy={creatingClient} onSubmit={handleCreateClient} />
    </WithHeader>
  );
};

export default ClientCreateScreen;
