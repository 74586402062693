import React from "react";
import { CONTEXT_CONSTANTS } from "../constants/contextConstants";

const AppContext = React.createContext();

const initialState = {
  isMobileSidebarOn: false,
  isCaseDetailMetaPaneExpanded: true,
  isArticleDetailMetaPaneExpanded: true,
};

const appReducer = (state, action) => {
  switch (action.type) {
    case CONTEXT_CONSTANTS.TOGGLE_NOTIFICATION_SIDEBAR:
      return {
        ...state,
        isNotificationSidebarOpen:
          !action.payload?.isNotificationSidebarOpen ||
            action.payload?.isNotificationSidebarOpen === undefined
            ? !state.isNotificationSidebarOpen
            : action.payload.isNotificationSidebarOpen,
      };

    case CONTEXT_CONSTANTS.TOGGLE_CASE_DETAIL_META_PANE:
      return {
        ...state,
        isCaseDetailMetaPaneExpanded:
          !action.payload?.isCaseDetailMetaPaneExpanded ||
            action.payload?.isCaseDetailMetaPaneExpanded === undefined
            ? !state.isCaseDetailMetaPaneExpanded
            : action.payload.isCaseDetailMetaPaneExpanded,
      };

    case CONTEXT_CONSTANTS.TOGGLE_ARTICLE_DETAIL_META_PANE:
      return {
        ...state,
        isArticleDetailMetaPaneExpanded:
          !action.payload?.isArticleDetailMetaPaneExpanded ||
            action.payload?.isArticleDetailMetaPaneExpanded === undefined
            ? !state.isArticleDetailMetaPaneExpanded
            : action.payload.isArticleDetailMetaPaneExpanded,
      };

    default:
      return {
        ...state,
      };
  }
};

const useAppContext = () => {
  const ctx = React.useContext(AppContext);
  return ctx;
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(appReducer, initialState);
  const value = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppProvider, useAppContext };
