import React from "react";
import { CONTEXT_CONSTANTS } from "../constants/contextConstants";

const KnowledgebaseContext = React.createContext();

const initialState = {
  detailedArticleInformation: null, // information for the tabs like history, details etc
  hasUpdatedArticleInformation: false, //used to sync updated article information in article detail component and article sidebar component
  selectedArticle: null,
};

const knowledgebaseReducer = (state, action) => {
  switch (action.type) {
    case CONTEXT_CONSTANTS.SET_HAS_UPDATED_ARTICLE_INFORMATION:
      return {
        ...state,
        hasUpdatedArticleInformation:
          action.payload?.hasUpdatedArticleInformation === undefined
            ? !state.hasUpdatedArticleInformation
            : action.payload.hasUpdatedArticleInformation,
      };

    case CONTEXT_CONSTANTS.SET_SELECTED_ARTICLE:
      return {
        ...state,
        selectedArticle: action.payload?.selectedArticle,
      };

    case CONTEXT_CONSTANTS.SET_DETAILED_ARTICLE_INFORMATION:
      return {
        ...state,
        detailedArticleInformation: action.payload?.detailedArticleInformation,
      };
    default:
      return {
        ...state,
      };
  }
};

const useKnowledgebaseContext = () => {
  const ctx = React.useContext(KnowledgebaseContext);
  return ctx;
};

const KnowledgebaseProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    knowledgebaseReducer,
    initialState
  );

  const prepareArticleDataForLocalUpdate = (data) => {
    dispatch({
      type: CONTEXT_CONSTANTS.SET_DETAILED_ARTICLE_INFORMATION,
      payload: {
        detailedArticleInformation: {
          ...data,
        },
      },
    });
    dispatch({
      type: CONTEXT_CONSTANTS.SET_HAS_UPDATED_ARTICLE_INFORMATION,
    });
  };

  const value = {
    state,
    dispatch,
    prepareArticleDataForLocalUpdate,
  };

  return (
    <KnowledgebaseContext.Provider value={value}>
      {children}
    </KnowledgebaseContext.Provider>
  );
};

export { KnowledgebaseProvider, useKnowledgebaseContext };
