import React from "react";

import { Route, Routes } from "react-router-dom";

import UniversityCreateScreen from "../screens/Admin/University/UniversityCreateScreen";
import UniversityDetailsScreen from "../screens/Admin/University/UniversityDetailsScreen";
import UniversityEditScreen from "../screens/Admin/University/UniversityEditScreen";
import UniversityListScreen from "../screens/Admin/University/UniversityListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const UniversityRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<UniversityCreateScreen />} />
      <Route path=":id/detail" element={<UniversityDetailsScreen />} />
      <Route path=":id/edit" element={<UniversityEditScreen />} />
      <Route path="list" element={<UniversityListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default UniversityRouter;
