import React from "react";
import { Box, Button, FormControl, Typography, Modal } from "@mui/material";

import { AttachmentItem } from "./AttachmentItem";

export const ManageAttachment = (props) => {
  const {
    attachmentToEditId,
    attachments,
    attachmentDescription,
    setAttachmentDescription,
    getRootProps,
    getInputProps,
    handleDeleteAttachment,
    onSelectAttachmentForEdit,
    onCancelUpdateAttachmentInformation,
    onUpdateAttachmentInformation,
    showEditDescriptionButton = true,
    canUploadFiles = true,
    canDeleteFiles = true,
  } = props;

  return (
    <Box>
      {canUploadFiles && (
        <Box
          width="100%"
          border="1px dashed #878383"
          paddingX={2}
          paddingY={6}
          borderRadius="5px"
          textAlign="center"
          sx={{ cursor: "pointer", backgroundColor: "white" }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" gap={1} marginTop={4}>
        {attachments &&
          Array.isArray(attachments) &&
          attachments.map((att, idx) => {
            if (!att?._id) {
              return (
                <AttachmentItem
                  key={`attachment-file-${att?.name}-${idx}`}
                  file={att}
                  idx={idx}
                  handleDeleteTicketAttachment={handleDeleteAttachment}
                  onSelectAttachmentForEdit={onSelectAttachmentForEdit}
                  showEditDescriptionButton={showEditDescriptionButton}
                  canDeleteFiles={canDeleteFiles}
                />
              );

            } else {
              return att?.files?.map((file, fileIdx) => (
                <AttachmentItem
                  key={`attachment-file-${file?.name}-${fileIdx}`}
                  file={file}
                  idx={fileIdx}
                  handleDeleteTicketAttachment={handleDeleteAttachment}
                  onSelectAttachmentForEdit={onSelectAttachmentForEdit}
                  showEditDescriptionButton={showEditDescriptionButton}
                  canDeleteFiles={canDeleteFiles}
                />
              ));

            }
          })}
      </Box>

      {attachmentToEditId !== null && (
        <Modal
          open={attachmentToEditId !== null}
          onClose={onCancelUpdateAttachmentInformation}
        >
          <Modal.Header>Update Attachment Information</Modal.Header>

          <Modal.Body>
            <Box marginBottom={2} marginTop={2}>
              <Typography variant="subtitle1">
                Attachment Description
                {/* (max 50 words) */}
              </Typography>

              <FormControl fullWidth>
                <textarea
                  rows="4"
                  name={`note`}
                  className="form-control form-font"
                  placeholder="Enter a note"
                  value={attachmentDescription}
                  onChange={(e) => setAttachmentDescription(e.target.value)}
                  style={{ marginTop: "8px" }}
                />
              </FormControl>
            </Box>

            <Box
              paddingY={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
              sx={{
                marginTop: 8,
                backgroundColor: "#f1f1f150",
              }}
            >
              <Button
                type="submit"
                onClick={() => onCancelUpdateAttachmentInformation()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  onUpdateAttachmentInformation();
                }}
              >
                Update Information Locally
              </Button>
            </Box>
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
};
