import { Container, Grid, Typography } from '@mui/material'
import React from 'react'

export const ClientVideo = () => {
  return (
    <div className='step4Background'>
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={5}
          direction='row'
          justifyContent='center'
          alignItems='center'
          className='GlobalBranchContainer'
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <div className='video-responsive'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/j3TTNP-lTxI?si=LwAa77tUsanYWwXD'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen
              ></iframe>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={1}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              mt={3}
              sx={{ fontSize: 30, fontWeight: 700, marginBottom: 3 }}
            >
              Making your dreams come true
            </Typography>

            <Typography>
              <p className='GlobalBranchesDescription'>
                At FSW Education, we understand the importance of choosing the
                right course and school for your academic journey. That's why we
                are fully dedicated to guiding and supporting international
                students in their pursuit of the best educational opportunities.
                Our mission is to ensure that every student receives
                comprehensive assistance and expert advice to make informed
                decisions about their academic future.
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
