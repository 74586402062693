import australiaBanner from "../../screens/assets/images/banners/australiaBanner.png"
import canadaBanner from "../../screens/assets/images/banners/canadaBanner.png"
import ukBanner from "../../screens/assets/images/banners/ukBanner.png"
import usaBanner from "../../screens/assets/images/banners/usaBanner.png"

import australiaMap from "../../screens/assets/images/maps/australiaMap.png"
import canadaMap from "../../screens/assets/images/maps/canadaMap.png"
import ukMap from "../../screens/assets/images/maps/ukMap.png"
import usaMap from "../../screens/assets/images/maps/usaMap.png"

import adelaide from "../../screens/assets/images/cities/adelaide2.png"
import brisbane from "../../screens/assets/images/cities/brisbane2.png"
import canberra from "../../screens/assets/images/cities/canberra2.png"
import darwin from "../../screens/assets/images/cities/darwin2.png"
import goldCoast from "../../screens/assets/images/cities/goldCoast2.png"
import hobart from "../../screens/assets/images/cities/hobart2.png"
import melbourne from "../../screens/assets/images/cities/melbourne2.png"
import perth from "../../screens/assets/images/cities/perth2.png"
import sydney from "../../screens/assets/images/cities/sydney2.png"

import london from "../../screens/assets/images/cities/london.png"
import birmingham from "../../screens/assets/images/cities/birmingham.png"
import edinburgh from "../../screens/assets/images/cities/edinburgh.png"
import manchester from "../../screens/assets/images/cities/manchester.png"
import bristol from "../../screens/assets/images/cities/bristol.png"
import liverpool from "../../screens/assets/images/cities/liverpool.png"

import newYork from "../../screens/assets/images/cities/newYork.png"
import boston from "../../screens/assets/images/cities/boston.png"
import losAngeles from "../../screens/assets/images/cities/losAngeles.png"
import sanFrancisco from "../../screens/assets/images/cities/sanFrancisco.png"
import chicago from "../../screens/assets/images/cities/chicago.png"
import washingtonDC from "../../screens/assets/images/cities/washingtonDC.png"

import ottawa from "../../screens/assets/images/cities/ottawa.png"
import toronto from "../../screens/assets/images/cities/toronto.png"
import vancouver from "../../screens/assets/images/cities/vancouver.png"

import sydneyImage from "../../screens/assets/images/cities/sydney.png"
import SydneyMap from "../../screens/assets/images/sydneyMap.png"

import adelaideImage from "../../screens/assets/images/cities/adelaide.png"
import adelaideMap from "../../screens/assets/images/cities/adelaideMap.png"

import brisbaneImage from "../../screens/assets/images/cities/brisbane.png"
import brisbaneMap from "../../screens/assets/images/cities/brisbaneMap.png"

import canberraImage from "../../screens/assets/images/cities/canberra.png"
import canberraMap from "../../screens/assets/images/cities/canberraMap.png"

import darwinImage from "../../screens/assets/images/cities/darwin.png"
import darwinMap from "../../screens/assets/images/cities/darwinMap.png"

import goldCoastImage from "../../screens/assets/images/cities/goldCoast.png"
import goldCoastMap from "../../screens/assets/images/cities/goldCoastMap.png"

import hobartImage from "../../screens/assets/images/cities/hobart.png"
import hobartMap from "../../screens/assets/images/cities/hobartMap.png"

import melbourneImage from "../../screens/assets/images/cities/melbourne.png"
import melbourneMap from "../../screens/assets/images/cities/melbourneMap.png"

import perthImage from "../../screens/assets/images/cities/perth.png"
import perthMap from "../../screens/assets/images/cities/perthMap.png"

// USA images
import newYorkBanner from "../../screens/assets/images/banners/newYorkBanner.png"
import newYorkMap from "../../screens/assets/images/maps/newYorkMap.png"

import bostonBanner from "../../screens/assets/images/banners/bostonBanner.png"
import bostonMap from "../../screens/assets/images/maps/bostonMap.png"

import washingtonDCBanner from "../../screens/assets/images/banners/washingtonDCBanner.png"
import washingtonDCMap from "../../screens/assets/images/maps/washingtonDCMap.png"

import losAngelesBanner from "../../screens/assets/images/banners/losAngelesBanner.png"
import losAngelesMap from "../../screens/assets/images/maps/losAngelesMap.png"

import sanFranciscoBanner from "../../screens/assets/images/banners/sanFranciscoBanner.png"
import sanFranciscoMap from "../../screens/assets/images/maps/sanFranciscoMap.png"

import chicagoBanner from "../../screens/assets/images/banners/chicagoBanner.png"
import chicagoMap from "../../screens/assets/images/maps/chicagoMap.png"

// UK images
import londonBanner from "../../screens/assets/images/banners/londonBanner.png"
import londonMap from "../../screens/assets/images/maps/londonMap.png"

import birminghamBanner from "../../screens/assets/images/banners/birminghamBanner.png"
import birminghamMap from "../../screens/assets/images/maps/birminghamMap.png"

import edinburghBanner from "../../screens/assets/images/banners/edinburghBanner.png"
import edinburghMap from "../../screens/assets/images/maps/edinburghMap.png"

import manchesterBanner from "../../screens/assets/images/banners/manchesterBanner.png"
import manchesterMap from "../../screens/assets/images/maps/manchesterMap.png"

import bristolBanner from "../../screens/assets/images/banners/bristolBanner.png"
import bristolMap from "../../screens/assets/images/maps/bristolMap.png"

import liverpoolBanner from "../../screens/assets/images/banners/liverpoolBanner.png"
import liverpoolMap from "../../screens/assets/images/maps/liverpoolMap.png"

// Canada images
import ottawaBanner from "../../screens/assets/images/banners/ottawaBanner.png"
import ottawaMap from "../../screens/assets/images/maps/ottawaMap.png"

import torontoBanner from "../../screens/assets/images/banners/torontoBanner.png"
import torontoMap from "../../screens/assets/images/maps/torontoMap.png"

import vancouverBanner from "../../screens/assets/images/banners/vancouverBanner.png"
import vancouverMap from "../../screens/assets/images/maps/vancouverMap.png"

export const PopularCountries = [
  {
    country: "Australia",
    content:
      "Australia, the world's sixth-largest country and a continent in its own right, is a captivating blend of vibrant cities, vast outback landscapes, stunning coastlines, and unique wildlife. From the iconic Sydney Opera House to the mystical allure of the Australian Outback, this extraordinary country offers a rich tapestry of experiences for travelers and explorers alike.<br/><br/> Whether you're drawn to the bustling urban life, the untouched wilderness, or the stories of ancient Aboriginal cultures, Australia promises an adventure that will leave an indelible mark on your soul.<br/><br/> Welcome to the Land Down Under, a land of contrasts and diversity unlike any other.",
    image: australiaBanner,
    countryMap: australiaMap,
    cities: [
      {
        name: "Adelaide",
        image: adelaide,
      },
      {
        name: "Brisbane",
        image: brisbane,
      },
      {
        name: "Canberra",
        image: canberra,
      },
      {
        name: "Darwin",
        image: darwin,
      },
      {
        name: "Gold Coast",
        name2: "Gold_Coast",
        image: goldCoast,
      },
      {
        name: "Hobart",
        image: hobart,
      },
      {
        name: "Melbourne",
        image: melbourne,
      },
      {
        name: "Perth",
        image: perth,
      },
      {
        name: "Sydney",
        image: sydney,
      },
    ],
  },
  {
    country: "Canada",
    content:
      "Canada, the second-largest country on Earth, stretches from the Atlantic to the Pacific and northward into the Arctic, encompassing a vast expanse of natural beauty and urban sophistication.<br/><br/> With its iconic maple leaf flag and reputation for politeness, Canada is a nation known for its inclusivity, stunning wilderness, and thriving cities.<br/><br/> Welcome to the Great White North, a land of breathtaking landscapes, diverse cultures, and boundless opportunities.",
    image: canadaBanner,
    countryMap: canadaMap,
    cities: [
      {
        name: "Ottawa",
        image: ottawa,
      },
      {
        name: "Toronto",
        image: toronto,
      },
      {
        name: "Vancouver",
        image: vancouver,
      },
    ],
  },
  {
    country: "UK",
    content:
      "A nation steeped in history, culture, and tradition. Nestled off the northwest coast of mainland Europe, the UK is a sovereign country composed of four distinct nations: England, Scotland, Wales, and Northern Ireland. From its iconic landmarks to its rich literary heritage, the United Kingdom offers a captivating blend of old-world charm and modern innovation.<br/><br/> As you explore the United Kingdom, you'll find a blend of history and modernity, tradition and progress, all coexisting in a dynamic and ever-evolving society. Whether you're captivated by the medieval charm of Edinburgh, the vibrant energy of London, or the serene landscapes of the countryside, the UK offers a wealth of experiences waiting to be discovered.",
    image: ukBanner,
    countryMap: ukMap,
    cities: [
      {
        name: "London",
        image: london,
      },
      {
        name: "Birmingham",
        image: birmingham,
      },
      {
        name: "Edinburgh",
        image: edinburgh,
      },
      {
        name: "Manchester",
        image: manchester,
      },
      {
        name: "Bristol",
        image: bristol,
      },
      {
        name: "Liverpool",
        image: liverpool,
      },
    ],
  },
  {
    country: "USA",
    content:
      "Land of diverse landscapes, cultures, and opportunities. Stretching across North America, the USA is a country renowned for its iconic cities, stunning natural wonders, and rich history. With a population that reflects a mosaic of backgrounds, the United States has long been a melting pot of cultures, ideas, and innovations.<br/><br/> As a global economic and cultural powerhouse, the United States has played a significant role in shaping the modern world, from technological breakthroughs to advancements in the arts and sciences.<br/><br/> As you delve deeper into the United States, you'll uncover a complex and fascinating nation that continually evolves and adapts to new challenges and opportunities. Whether you're interested in history, technology, nature, or culture, the USA offers a vast realm of experiences waiting to be discovered.",
    image: usaBanner,
    countryMap: usaMap,
    cities: [
      {
        name: "New York",
        name2: "New_York",
        image: newYork,
      },
      {
        name: "Boston",
        image: boston,
      },
      {
        name: "Los Angeles",
        name2: "Los_Angeles",
        image: losAngeles,
      },
      {
        name: "San Francisco",
        name2: "San_Francisco",
        image: sanFrancisco,
      },
      {
        name: "Chicago",
        image: chicago,
      },
      {
        name: "Washington DC",
        name2: "Washington_DC",
        image: washingtonDC,
      },
    ],
  },
]

export const PopularCities = [
  {
    city: "Sydney",
    content:
      "Sydney is the largest and most populous city in Australia, located on the southeastern coast of the country.<br/><br/> It is known for its iconic landmarks, stunning harbor, vibrant culture, and diverse population.<br/><br/>The city boasts a stunning natural landscape, with beautiful sandy beaches such as Bondi, Manly, and Coogee.<br/><br/>Overall, Sydney is a city that seamlessly blends natural beauty, cultural richness, and a dynamic urban lifestyle. Sydney offers a diverse range of experiences that cater to every taste.",
    image: sydneyImage,
    cityMap: SydneyMap,
    cityId: "63982863a0844ae27fc158fb",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Brisbane",
    content:
      "Brisbane offers a perfect blend of modernity, natural beauty, and a laid-back atmosphere that captivates both locals and visitors alike.<br/><br/>Nestled along the banks of the winding Brisbane River, the city boasts an array of picturesque vistas. A vibrant cultural precinct, offers a delightful oasis with lush gardens, sandy beaches, and an inviting lagoon perfect for leisurely strolls or picnics with family and friends.<br/><br/>With its warm and welcoming atmosphere, Brisbane offers a perfect balance of city living and natural escapes. Brisbane promises an enriching and unforgettable experience that will leave you enamored with this sunny gem of the Australian east coast.",
    image: brisbaneImage,
    cityMap: brisbaneMap,
    cityId: "63982e7f04ae67cd5e73ac9d",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Melbourne",
    content:
      "Located on the southeastern coast of Australia, Melbourne is the capital city of the state of Victoria and stands as a beacon of diversity, creativity, and innovation. Melbourne has rightfully earned its reputation as one of the most livable cities in the world.<br/><br/>The city hosts numerous festivals, exhibitions, and performances throughout the year, celebrating everything from contemporary art to traditional Aboriginal culture.<br/><br/>Whether you're looking to indulge in culinary delights, immerse yourself in art and culture, cheer for your favorite sports team, or simply explore the charming neighborhoods, Melbourne promises an unforgettable experience.",
    image: melbourneImage,
    cityMap: melbourneMap,
    cityId: "63982868a0844ae27fc1590a",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Perth",
    content:
      "As the capital city of Western Australia, Perth exudes a laid-back charm and a sense of adventure, offering a unique blend of natural beauty, cultural diversity, and thriving urban life.<br/><br/> The city's pristine beaches  are iconic destinations where locals and visitors alike come to soak up the sun, surf the waves, and enjoy leisurely picnics against breathtaking backdrops.<br/><br/> As you discover the wonders of Perth, you'll be captivated by its relaxed ambiance, friendly locals, and the sense of endless possibilities. Whether you're seeking relaxation on pristine beaches, immersing yourself in culture and history, or embracing the thrill of urban exploration, Perth promises an unforgettable journey that will leave you enchanted and longing to return.",
    image: perthImage,
    cityMap: perthMap,
    cityId: "63982e8c04ae67cd5e73acbb",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Adelaide",
    content:
      "Adelaide, a city that epitomizes elegance, culture, and a vibrant arts scene, nestled amidst the picturesque landscapes of South Australia.<br/><br/>Adelaide's relaxed pace of life, friendly locals, and beautiful surroundings make it an inviting destination for both leisure and business travelers.<br/><br/>The city's easy accessibility allows you to explore its attractions at your own pace, whether you're wandering through its leafy parks, savoring delectable cuisine, or uncovering the secrets of its vibrant arts scene.",
    image: adelaideImage,
    cityMap: adelaideMap,
    cityId: "63982e8404ae67cd5e73acac",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Canberra",
    content:
      "Canberra is a purpose-built city, carefully planned to serve as the center of governance and administration for the nation.<br/><br/> As the political heart of Australia, Canberra is home to many important institutions, including the Australian Parliament House, where the nation's laws are debated and passed.<br/><br/> With its rich history, political significance, natural beauty, and thriving cultural scene, Canberra promises a captivating and enriching experience for anyone who ventures into this unique city.<br/><br/> So, welcome to Canberra, a city that showcases the best of Australia's past, present, and future in a captivating and harmonious setting.",
    image: canberraImage,
    cityMap: canberraMap,
    cityId: "63985377ae66c85cb3cd7074",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Darwin",
    content:
      "Darwin is blessed with a tropical climate, warm waters, and stunning sunsets that cast a mesmerizing glow over the city's picturesque landscapes.<br/><br/> Adventure seekers will find plenty to do in Darwin, with opportunities for fishing, crocodile cruises, and water sports.<br/><br/>Darwin beckons travelers to embrace its unique charms and warm hospitality. Whether you're exploring ancient landscapes, delving into Indigenous culture, or simply relaxing by the sea, Darwin promises an unforgettable journey that will leave you with cherished memories of the Top End's tropical haven.",
    image: darwinImage,
    cityMap: darwinMap,
    cityId: "63982e9004ae67cd5e73acca",
    countryId: "63b519fc7ea9abe78bd16097",
  },
  {
    city: "Gold Coast",
    content:
      "Located on the southeastern coast of Queensland, the Gold Coast is renowned for its stunning golden beaches, vibrant entertainment scene, and an abundance of exciting activities that make it a favorite destination for travelers from all over the world.<br/><br/> With its stunning coastal landscapes, thrilling attractions, and an endless array of activities, the Gold Coast is a destination that promises an unforgettable experience.<br/><br/>The Gold Coast invites you to dive into an adventure that will leave you with cherished memories of this sparkling gem of Australia's east coast. ",
    image: goldCoastImage,
    cityMap: goldCoastMap,
    cityId: "",
    countryId: "63b519fc7ea9abe78bd16097",
  },

  {
    city: "Hobart",
    content:
      "Nestled along the scenic shores of the Derwent River and surrounded by majestic mountains, Hobart is a city that effortlessly combines natural beauty with a rich cultural heritage.<br/><br/>Beyond the city's historic charm, Hobart is a gateway to some of Tasmania's most breathtaking natural wonders.<br/><br/>With its warm and welcoming atmosphere, rich history, and stunning natural landscapes, Hobart offers a delightful escape for those seeking a harmonious blend of urban delights and natural wonders. ",
    image: hobartImage,
    cityMap: hobartMap,
    cityId: "63982e9504ae67cd5e73acd9",
    countryId: "63b519fc7ea9abe78bd16097",
  },
  // USA Cities
  {
    city: "New York",
    content:
      "The city that never sleeps and a global icon of culture, commerce, and diversity. Nestled on the northeastern coast of the United States, New York City is a bustling metropolis that captures the imagination with its towering skyscrapers, vibrant neighborhoods, and endless array of experiences. <br /> <br/>New York City offers an unparalleled mix of experiences that will captivate and inspire you. As you traverse its avenues and alleyways, you'll discover the heartbeat of a city that thrives on its diversity and unrelenting spirit.<br/> <br/>New York's influence extends far beyond its borders. It's a hub of international diplomacy, a center of global commerce, and a backdrop for countless movies, TV shows, and cultural touchstones.",
    image: newYorkBanner,
    cityMap: newYorkMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Boston",
    content:
      "A city rich in history, culture, and intellectual vitality. Nestled in the northeastern corner of the United States, Boston is a city that seamlessly blends its colonial past with a modern, innovative spirit. As one of the oldest cities in the United States, Boston exudes an unmistakable charm that draws visitors and residents alike. <br/> <br/>Boston's commitment to progress is evident in its numerous tech startups, medical institutions, and research centers. The city's innovation district has become a breeding ground for entrepreneurship and technological advancements.<br/> <br/>  As you delve into the fabric of Boston, you'll discover a city that values its past while eagerly embracing the future.",
    image: bostonBanner,
    cityMap: bostonMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Los Angeles",
    content:
      "The entertainment capital of the world and a sprawling metropolis of creativity, diversity, and endless possibilities. Located on the west coast of the United States, Los Angeles, often referred to simply as LA, is a city that embodies the glamour of Hollywood, the cultural vibrancy of its neighborhoods, and the relaxed Southern California lifestyle. <br/> <br/> Renowned for its sunny weather and diverse landscapes, LA offers an incredible range of experiences. From the iconic Hollywood Hills and the picturesque beaches of Santa Monica and Venice to the bustling streets of Downtown LA, the city presents a mosaic of cultures, lifestyles, and attractions.",
    image: losAngelesBanner,
    cityMap: losAngelesMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "San Francisco",
    content:
      "A city of innovation, iconic landmarks, and a unique blend of cultures. Nestled on the picturesque northern California coast, San Francisco is a city that captivates with its stunning vistas, progressive ideals, and rich history.<br/> <br/> San Francisco's history is deeply intertwined with the Gold Rush era, and remnants of its past are evident in the preserved Victorian and Edwardian architecture found throughout the city. Alcatraz Island, once home to a notorious prison, is now a popular historical attraction accessible by ferry.<br/> <br/> As you explore San Francisco, you'll be immersed in a city that embraces both its storied past and its forward-thinking spirit.",
    image: sanFranciscoBanner,
    cityMap: sanFranciscoMap,
    cityId: "",
    countryId: "",
  },
  {
    city: "Chicago",
    content:
      "The Windy City, a bustling metropolis that sits at the crossroads of culture, commerce, and creativity. Nestled along the shores of Lake Michigan in the heart of the United States, Chicago is a city that embodies the spirit of innovation, architecture, and diverse communities.<br/> <br/> Chicago is a melting pot of cultures, evident in its neighborhoods, each with its own distinct flavor. The Loop is the city central business district, while neighborhoods like Wicker Park, Logan Square, and Pilsen showcase the city artistic and ethnic diversity.<br/> <br/> As you explore Chicago, you ll discover a city that thrives on its distinctive architecture, rich cultural tapestry, and innovative spirit.",
    image: chicagoBanner,
    cityMap: chicagoMap,
    cityId: "",
    countryId: "",
  },
  {
    city: "Washington DC",
    content:
      "The political and cultural heart of the United States. Nestled along the banks of the Potomac River, this iconic city is a hub of history, power, and national significance. From its grand monuments and museums to its government institutions and vibrant neighborhoods, Washington, D.C. offers a captivating blend of tradition and modernity.<br/> <br/> As the capital of the United States, Washington, D.C. serves as the epicenter of the nation's governance. The city is home to the White House, the official residence of the President, and the U.S. Capitol, where the legislative branches of the government convene.<br/> <br/> It is a city that invites you to engage with history, politics, and culture in a way that few other places can.",
    image: washingtonDCBanner,
    cityMap: washingtonDCMap,
    cityId: "",
    countryId: "",
  },

  // UK Cities
  {
    city: "London",
    content:
      "London, often referred to as the capital of the United Kingdom (UK), is one of the world's most iconic and culturally rich cities. With a history spanning thousands of years, it has evolved into a global hub for finance, culture, fashion, art, and diversity. Its unique blend of modernity and tradition, along with its countless landmarks, make it a truly captivating destination for both residents and visitors alike.<br/><br/> London's rich history, diverse culture, architectural marvels, and global influence make it a city unlike any other. Whether you're interested in history, art, finance, or simply experiencing the vibrant energy of a bustling metropolis, London has something to offer for everyone.",
    image: londonBanner,
    cityMap: londonMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Birmingham",
    content:
      "Birmingham, often referred to as the Second City of the United Kingdom, is a dynamic and culturally diverse city located in the West Midlands region of England. It is known for its rich industrial heritage, vibrant cultural scene, and its pivotal role in shaping the country's history and development.<br/><br/> Birmingham boasts several universities, including the University of Birmingham, which is internationally recognized for its research and academic excellence. The city's commitment to education and research has contributed to its reputation as a hub of innovation.",
    image: birminghamBanner,
    cityMap: birminghamMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Edinburgh",
    content:
      "Edinburgh, the capital city of Scotland, is a captivating blend of history, culture, and stunning natural landscapes. Perched on rocky hills and overlooking the North Sea, Edinburgh is a city of contrasts, where ancient architecture meets modern creativity and innovation. With its rich heritage, world-famous festivals, and iconic landmarks, Edinburgh has rightfully earned its place as one of Europe's most cherished destinations.<br/><br/> Edinburgh's reputation for education and enlightenment is upheld by its prestigious universities, including the University of Edinburgh. The city's history as a hub of intellectual and literary pursuits is evident in its libraries, historic buildings, and literary tours.",
    image: edinburghBanner,
    cityMap: edinburghMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Manchester",
    content:
      "Manchester, often referred to as the Capital of the North, is a dynamic city in the northwest of England with a rich industrial heritage, vibrant cultural scene, and a reputation for innovation. From its pivotal role in the Industrial Revolution to its thriving arts, music, and sports culture, Manchester has carved out a unique identity that resonates with both residents and visitors.<br/><br/> The presence of prestigious universities, including the University of Manchester, has contributed to the city's reputation as a hub of education and research. <br/><br/> Manchester is known for its friendly and welcoming atmosphere. Its diverse communities contribute to a sense of inclusivity and openness that resonates with both residents and visitors.",
    image: manchesterBanner,
    cityMap: manchesterMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Bristol",
    content:
      "Bristol, a historic city located in the southwest of England, is a vibrant and culturally rich destination known for its maritime heritage, innovative spirit, and artistic contributions. With a blend of historic landmarks, modern creativity, and a bustling harbor, Bristol offers a unique and captivating experience for both residents and visitors.<br/><br/> Bristol's two universities, the University of Bristol and the University of the West of England (UWE Bristol), contribute to the city's reputation for academic excellence and research advancements.<br/><br/> Bristol offers an enriching and captivating experience that captures the essence of a vibrant British city.",
    image: bristolBanner,
    cityMap: bristolMap,
    cityId: "",
    countryId: "",
  },

  {
    city: "Liverpool",
    content:
      "Liverpool, a city located in the northwest of England along the River Mersey, is celebrated for its rich maritime history, cultural influence, and enduring musical legacy. As a UNESCO World Heritage Site and the birthplace of The Beatles, Liverpool has become an iconic destination that harmoniously blends its historic past with contemporary creativity.<br/><br/>  Liverpool's most famous export, The Beatles, changed the landscape of music forever. The city's connection to the Fab Four is celebrated through various attractions, including The Beatles Story museum and landmarks such as the Cavern Club, where the band often performed in their early years.",
    image: liverpoolBanner,
    cityMap: liverpoolMap,
    cityId: "",
    countryId: "",
  },

  // canada cities/
  {
    city: "Ottawa",
    content:
      "Nestled on the banks of the majestic Ottawa River, this vibrant metropolis seamlessly blends history, culture, and natural beauty into a unique tapestry. As the political and administrative heart of the nation, Ottawa boasts iconic landmarks, world-class museums, and a charming blend of English and French influences.<br/><br/> Ottawa promises an unforgettable experience for visitors and residents alike.<br/><br/> Welcome to Ottawa, the picturesque capital city of Canada!  ",
    image: ottawaBanner,
    cityMap: ottawaMap,
    cityId: "",
    countryId: "63b51a047ea9abe78bd160a6",
  },
  {
    city: "Toronto",
    content:
      "Toronto is the capital city of the province of Ontario in Canada. It's not only the largest city in Canada but also one of the most diverse and vibrant cities in the world. Known for its blend of cultures, impressive skyline, and a wide range of attractions, Toronto is a city that offers something for everyone.<br/><br/>Toronto is a diverse and dynamic metropolis that celebrates its multicultural identity while embracing innovation and progress. With its mix of cultural experiences, landmarks, arts, and thriving neighborhoods, it's a city that leaves a lasting impression on both residents and visitors alike.",
    image: torontoBanner,
    cityMap: torontoMap,
    cityId: "639af99f5b58bdba941a15d8",
    countryId: "63b51a047ea9abe78bd160a6",
  },
  {
    city: "Vancouver",
    content:
      "Vancouver is a vibrant coastal city located in the province of British Columbia, Canada. Known for its stunning natural landscapes, diverse culture, and outdoor recreational opportunities, Vancouver is often considered one of the most livable cities in the world.<br/><br/> The city places a strong emphasis on environmental sustainability. Vancouver is committed to reducing its carbon footprint and is often considered a leader in green initiatives.<br/><br/> Vancouver is a city that seamlessly combines urban living with natural beauty. Its stunning landscapes, diverse culture, and emphasis on outdoor activities make it a destination that appeals to both residents and visitors who are seeking a unique and fulfilling experience.",
    image: vancouverBanner,
    cityMap: vancouverMap,
    cityId: "63982e9504ae67cd5e73acd9",
    countryId: "639af9f95b58bdba941a345d",
  },
]
