import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ClientForm from "./clientComponents/ClientForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetClientDetailQuery,
  useUpdateClientMutation,
} from "../../../rtkStore/services/clientApi";

const ClientEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Clients",
      path: "/app/client/list",
    },

    { title: "Edit Client" },
  ];

  // RTK Query
  const {
    data: clientDetail,
    isLoading: loadingClientDetail,
    error,
  } = useGetClientDetailQuery(id);

  const [updateClient, { isLoading: updatingClient }] =
    useUpdateClientMutation();

  // methods
  const handleUpdateClient = async (data) => {
    try {
      await updateClient({ ...data }).unwrap();
      navigate(`/app/client/${id}/detail`);
      toast.success("Client successfully updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update Client!");
    }
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      error={error}
      isBusy={loadingClientDetail || updatingClient}
    >
      <ClientForm
        isBusy={loadingClientDetail || updatingClient}
        clientDetail={clientDetail}
        onSubmit={handleUpdateClient}
      />
    </WithHeader>
  );
};

export default ClientEditScreen;
