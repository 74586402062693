import { useMemo } from "react";

import { useSelector } from "react-redux";

import { selectLoggedInUser } from "../rtkStore/authSlice";

export const useAuth = () => {
  const loggedInUser = useSelector(selectLoggedInUser);

  return useMemo(() => {
    if (!loggedInUser) return;

    const viewEntity = loggedInUser && (loggedInUser?.viewEntity ?? null);

    const isAccountAdmin = !!(
      loggedInUser?.viewEntity?.authorization?.level === "Account" &&
      loggedInUser?.viewEntity?.authorization?.type === "Admin"
    );

    const isSystemAdmin = !!(
      loggedInUser?.viewEntity?.authorization?.level === "System" &&
      loggedInUser?.viewEntity?.authorization?.type === "Admin"
    );

    return {
      loggedInUser,
      viewEntity,
      isAccountAdmin,
      isSystemAdmin,
      user: loggedInUser,
    };
  }, [loggedInUser]);
};
