import React from "react";
import { Box, Skeleton } from "@mui/material";

const RowsSkeleton = ({ numberOfRows = 3, rowGap = 3 }) => {
  return (
    <Box padding={3} display="flex" flexDirection="column" gap={rowGap}>
      {new Array(numberOfRows).fill(null).map((_, index) => (
        <Skeleton key={index} variant="rect" height={70} />
      ))}
    </Box>
  );
};

export default RowsSkeleton;
