import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";

import RichTextEditor from "../Shared/RichTextEditor";

import { useListListQuery } from "../../rtkStore/services/listService";

const INITIAL_STATE = {
  content: "",
  type: "Note",
};

const NextActionNoteForm = ({
  noteDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const noteTypes = ["Note", "Call", "Meeting", "Email", "Other"];

  //   RTK Query
  const { data: nextActionTypes } = useListListQuery({
    type: "Next Action Types",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      content: noteDetail?.content ?? "",
      type: noteDetail?.type ?? "Note",
    },
  });

  React.useEffect(() => {
    if (!noteDetail) return reset({ ...INITIAL_STATE });

    reset({ ...noteDetail });
  }, [noteDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Controller
              name="content"
              control={control}
              rules={{ required: "Note content is required" }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  initialValue={
                    noteDetail?.content
                      ? noteDetail.content
                      : "<p>Enter Note Content.</p>"
                  }
                  onChange={onChange}
                  height={170}
                />
              )}
            />
            {errors.content && (
              <p className="error">{errors.content.message}</p>
            )}
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-note">Note Type</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-note"
                        label="Note Type"
                        placeholder="Select Type"
                        size="small"
                        id="role"
                        value={value ? value : "Note"}
                        {...field}
                      >
                        {noteTypes?.map((note) => (
                          <MenuItem key={note} value={note}>
                            {note}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              {!noteDetail && (
                <>
                  <Grid item md={12}>
                    <Controller
                      name="nextAction"
                      control={control}
                      render={({ field, value }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel id="select-next-action">
                            Next Action
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="select-next-action"
                            label="Next Action"
                            placeholder="Select Next Action"
                            size="small"
                            id="role"
                            value={value ? value : ""}
                            {...field}
                          >
                            {nextActionTypes?.map((action) => (
                              <MenuItem key={action._id} value={action._id}>
                                {action?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors.nextAction && (
                      <p className="error">{errors.nextAction.message}</p>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <Controller
                      name="nextActionDate"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Next Action Date"
                          type="date"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={moment(field.value).format("YYYY-MM-DD")}
                          onChange={(e) =>
                            setValue("nextActionDate", e.target.value)
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {noteDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default NextActionNoteForm;
