import { Grid, Typography } from '@mui/material'
import React from 'react'

const Banner = ({ image, title, description }) => {
  return (
    <>
      <Grid
        className='CountryScreenBannerContainer banner-background-filter'
        container
      >
        <Grid item xs={12}>
          <img
            src={image}
            alt='studyInAus'
            className='bannerBackgroundImage'
          ></img>
        </Grid>
      </Grid>
      <Typography variant='h2' className='CountryScreenBannerTitle'>
        {title}
      </Typography>
    </>
  )
}

export default Banner
