import React from "react";

import { Controller, useForm } from "react-hook-form";
import { Box, Button, Grid, TextField } from "@mui/material";
import moment from "moment";

const INITIAL_STATE = {
  title: "",
  scholarshipProvider: "",
  openDate: "",
  finalApplyDate: "",
  applyUrl: "",
  value: "",
  eligibility: "",
  description: "",
};

const CourseScholarshipForm = ({
  scholarshipDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  React.useEffect(() => {
    if (!scholarshipDetail) return reset({ ...INITIAL_STATE });

    reset({ ...scholarshipDetail });
  }, [scholarshipDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Scholarship Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="scholarshipProvider"
              control={control}
              rules={{ required: "Scholarship Provider is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Scholarship Provider"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.scholarshipProvider && (
              <p className="error">{errors.scholarshipProvider.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="openDate"
              control={control}
              rules={{ required: "Open Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Open Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("openDate", e.target.value)}
                />
              )}
            />
            {errors.openDate && (
              <p className="error">{errors.openDate.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="finalApplyDate"
              control={control}
              rules={{ required: "Final Apply Date is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Final Apply Date"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={moment(field.value).format("YYYY-MM-DD")}
                  onChange={(e) => setValue("finalApplyDate", e.target.value)}
                />
              )}
            />
            {errors.finalApplyDate && (
              <p className="error">{errors.finalApplyDate.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="applyUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Apply URL"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6}></Grid>

          <Grid item md={6}>
            <Controller
              name="value"
              control={control}
              rules={{ required: "Scholarship Value is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Scholarship Value"
                  type="number"
                  size="small"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
            {errors.value && <p className="error">{errors.value.message}</p>}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="eligibility"
              control={control}
              rules={{ required: "Eligibility is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Eligibility Description"
                  type="text"
                  size="small"
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
            {errors.eligibility && (
              <p className="error">{errors.eligibility.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  multiline
                  rows={4}
                  size="small"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {scholarshipDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default CourseScholarshipForm;
