import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { Add, StickyNote2Outlined } from "@mui/icons-material";

import BasicNoteForm from "../../../../components/Note/BasicNoteForm";

import {
  useAddTransactionNoteMutation,
  useUpdateTransactionNoteMutation,
} from "../../../../rtkStore/services/transactionApi";
import NoteList from "../../../../components/Note/NoteList";

import { useTransactionDetail } from "../TransactionDetailScreen";

const TransactionNoteTab = () => {
  const [showNoteForm, setShowNoteForm] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const { id } = useParams();

  const {
    transactionDetail: { notes },
  } = useTransactionDetail();

  // RTK Query
  const [addTransactionNote, { isLoading: addingNote }] =
    useAddTransactionNoteMutation();

  const [updateTransactionNote, { isLoading: updatingNote }] =
    useUpdateTransactionNoteMutation();

  // methods
  const handleCancel = () => {
    setShowNoteForm(false);
    setSelectedNote(null);
  };

  const handleAddNote = async (data) => {
    setSelectedNote({ ...data });
    try {
      await addTransactionNote({ ...data, transactionId: id }).unwrap();
      toast.success("Note successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add note");
    }
  };

  const handleNoteEdit = (note) => {
    setShowNoteForm(true);
    setSelectedNote(note);
  };

  const handleUpdateNote = async (data) => {
    try {
      await updateTransactionNote({ ...data, transactionId: id }).unwrap();
      toast.success("Note successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update note");
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <StickyNote2Outlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Notes</Typography>
        </Box>

        {!showNoteForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowNoteForm(true)}
          >
            Note
          </Button>
        )}
      </Box>

      <Collapse in={showNoteForm}>
        <BasicNoteForm
          noteDetail={selectedNote}
          isBusy={addingNote || updatingNote}
          onSubmit={selectedNote ? handleUpdateNote : handleAddNote}
          onCancel={handleCancel}
        />
      </Collapse>

      <Divider />

      <NoteList notes={notes} onNoteEdit={handleNoteEdit} />
    </Box>
  );
};

export default TransactionNoteTab;
