import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectLoggedInUser } from "../../rtkStore/authSlice";

const AuthenticatedRoute = () => {
  const loggedInUser = useSelector(selectLoggedInUser);

  if (!loggedInUser) return <Navigate to="/sign-in" />;

  return <Outlet />;
};

export default AuthenticatedRoute;
