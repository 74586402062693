import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add,
  ArticleOutlined,
  EditOutlined,
  FiberManualRecord,
} from "@mui/icons-material";

import BasicDocumentForm from "../../../../components/Document/BasicDocumentForm";

import {
  useAddApplicationDocumentMutation,
  useUpdateApplicationDocumentMutation,
} from "../../../../rtkStore/services/applicationApi";

import { useApplicationDetail } from "../ApplicationDetailScreen";

const ApplicationDocumentsTab = () => {
  const [showDocumentForm, setShowDocumentForm] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState(null);

  const { id: applicationId } = useParams();

  const { applicationDetail } = useApplicationDetail();

  // RTK Query
  const [addDocument, { isLoading: addingDocument }] =
    useAddApplicationDocumentMutation();

  const [updateDocument, { isLoading: updatingDocument }] =
    useUpdateApplicationDocumentMutation();

  // methods
  const handleCancel = () => {
    setSelectedDocument(null);
    setShowDocumentForm(false);
  };

  const generateDocumentPayload = (data) => {
    const { title, url, documentType, documentCategory, workflow, step } = data;

    const payload = {
      title,
      url,
      documentType,
      documentCategory,
      received: !!url,
      ...(documentCategory === "Workflow" ? { workflow, step } : ""),
    };

    return payload;
  };

  const handleAddDocument = async (data) => {
    setSelectedDocument({ ...data });
    try {
      const payload = generateDocumentPayload(data);
      await addDocument({ ...payload, applicationId }).unwrap();
      toast.success("Document successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add document!");
    }
  };

  const handleEditDocument = (doc) => {
    setSelectedDocument(doc);
    setShowDocumentForm(true);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleUpdateDocument = async (data) => {
    try {
      const payload = generateDocumentPayload(data);
      await updateDocument({
        ...payload,
        _id: data._id,
        applicationId,
      }).unwrap();
      toast.success("Document successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update document!");
    }
  };

  const getDocumentWorkflow = (workflow) => {
    return applicationDetail?.workflows?.find((w) => w._id === workflow);
  };

  const getDocumentWorkflowStep = (workflow, step) => {
    const documentWorkflow = getDocumentWorkflow(workflow);
    return documentWorkflow?.steps?.find((s) => s._id === step);
  };

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <ArticleOutlined style={{ color: "gray" }} />

            <Typography style={{ fontWeight: 500 }}>Documents</Typography>
          </Box>

          {!showDocumentForm && (
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() => setShowDocumentForm(true)}
            >
              Document
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Collapse in={showDocumentForm}>
        <BasicDocumentForm
          documentDetail={selectedDocument}
          isBusy={addingDocument || updatingDocument}
          onSubmit={selectedDocument ? handleUpdateDocument : handleAddDocument}
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <Box paddingX={3}>
        {applicationDetail?.documents.length ? (
          applicationDetail?.documents?.map((document, index) => (
            <React.Fragment key={document._id}>
              <Box paddingY={3}>
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                  gap={2}
                >
                  <ArticleOutlined
                    color="primary"
                    fontSize="small"
                    style={{ marginTop: 2 }}
                  />

                  <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography> {document?.title}</Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography className="text-blue-500">
                        {document?.documentType?.name}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={2}>
                      {document?.workflow && (
                        <>
                          <Typography>
                            {getDocumentWorkflow(document?.workflow)?.title ??
                              "N/A"}
                          </Typography>

                          <FiberManualRecord
                            style={{ color: "gray", fontSize: 10 }}
                          />
                        </>
                      )}

                      {document?.step && (
                        <Typography>
                          {getDocumentWorkflowStep(
                            document?.workflow,
                            document?.step
                          )?.title ?? "N/A"}
                        </Typography>
                      )}
                    </Box>

                    <Box>
                      {document?.url ? (
                        <a href={document.url} target="_blank" rel="noreferrer">
                          <span className="text-blue-800">{document.url}</span>
                        </a>
                      ) : (
                        <div className="text-red-500">
                          Document not fulfilled!
                        </div>
                      )}
                    </Box>
                  </Box>

                  <IconButton onClick={() => handleEditDocument(document)}>
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>

              {index !== applicationDetail?.documents?.length - 1 && (
                <Divider />
              )}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>
            <Typography>No Documents.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ApplicationDocumentsTab;
