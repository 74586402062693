import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import moment from "moment";
import {
  AddCircleOutline,
  EditOutlined,
  FiberManualRecord,
  TaskAlt,
} from "@mui/icons-material";

import RichTextEditor from "../Shared/RichTextEditor";

import { useListListQuery } from "../../rtkStore/services/listService";

const INITIAL_TASK_STATE = {
  title: "",
  category: "",
  dueDate: "",
  description: "",
};

const INITIAL_STEP_STATE = {
  title: "",
  content: "",
  isActive: true,
  requiredDocuments: [],
  tasks: [],
};

const AddTaskForm = ({ stepDetail, workflowType, onAddTask = () => {} }) => {
  const [taskList, setTaskList] = React.useState([]);
  const [showTaskForm, setShowTaskForm] = React.useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = React.useState(null);

  const categoryEnum = [
    "Remainder",
    "Call",
    "Follow Up",
    "Email",
    "Meeting",
    "Others",
  ];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { ...INITIAL_TASK_STATE, assigneeSource: workflowType },
  });

  // methods
  const handleCancelTask = () => {
    reset({ ...INITIAL_TASK_STATE, assigneeSource: workflowType });
    setShowTaskForm(false);
    setSelectedTaskIndex(null);
  };

  const handleAddTask = (payload) => {
    const newTaskList = [...taskList, payload];
    setTaskList(newTaskList);
    onAddTask(newTaskList);

    handleCancelTask();
  };

  const handleEditTask = (taskIndex) => {
    setSelectedTaskIndex(taskIndex);
    setShowTaskForm(true);
  };

  const handleUpdateTask = (updatedTask) => {
    const updatedTaskList = taskList.map((task, index) =>
      index === selectedTaskIndex ? updatedTask : task
    );
    setTaskList(updatedTaskList);
    onAddTask(updatedTaskList);

    handleCancelTask();
  };

  React.useEffect(() => {
    if (!stepDetail) return setTaskList([]);
    setTaskList([...stepDetail.tasks]);
  }, [stepDetail, reset]);

  React.useEffect(() => {
    if (!taskList.length || selectedTaskIndex === null) return;

    const selectedTask = taskList[selectedTaskIndex];
    reset({
      ...selectedTask,
      dueDate: moment(selectedTask?.dueDate).format("YYYY-MM-DD"),
    });

    return () => {
      reset({ ...INITIAL_TASK_STATE });
    };
  }, [selectedTaskIndex, taskList, reset]);

  React.useEffect(() => {
    if (!workflowType) return;

    reset({ ...INITIAL_TASK_STATE, assigneeSource: workflowType });
  }, [reset, workflowType]);

  return (
    <div>
      <Box
        paddingX={3}
        paddingY={1.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="font-medium">{taskList.length} Step Tasks</div>

        <IconButton
          variant="contained"
          size="small"
          onClick={() => setShowTaskForm(true)}
        >
          <AddCircleOutline color="primary" />
        </IconButton>
      </Box>

      <Collapse in={showTaskForm}>
        <Divider />

        <Box padding={3} className="expanded-form">
          <Grid container rowSpacing={4} columnSpacing={6}>
            <Grid item md={4}>
              <Controller
                name="title"
                control={control}
                rules={{ required: "Task title is required" }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Task Title"
                    type="text"
                    size="small"
                    {...field}
                  />
                )}
              />
              {errors.title && <p className="error">{errors.title.message}</p>}
            </Grid>

            <Grid item md={4}>
              <Controller
                name="category"
                control={control}
                rules={{ required: "Please select a category" }}
                render={({ field, value }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-category">Category</InputLabel>
                    <Select
                      fullWidth
                      labelId="select-category"
                      label="Category"
                      placeholder="Select Category"
                      id="category"
                      value={value ? value : ""}
                      {...field}
                    >
                      {categoryEnum?.map((category) => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {errors.category && (
                <p className="error">{errors.category.message}</p>
              )}
            </Grid>

            <Grid item md={4}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Due Date"
                    type="date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={moment(field.value).format("YYYY-MM-DD")}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item md={8}>
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required" }}
                render={({ field: { onChange } }) => (
                  <RichTextEditor
                    onChange={onChange}
                    initialValue={
                      taskList[selectedTaskIndex]?.description ??
                      "<p>Enter Task Detail.</p>"
                    }
                    height={150}
                  />
                )}
              />
              {errors.description && (
                <p className="error">{errors.description.message}</p>
              )}
            </Grid>

            <Grid item md={4}>
              <div className="flex h-full w-full items-end justify-end space-x-4">
                <Button size="small" onClick={handleCancelTask}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSubmit((data) =>
                    selectedTaskIndex
                      ? handleUpdateTask(data)
                      : handleAddTask(data)
                  )}
                >
                  {selectedTaskIndex ? "Update Task" : "New Task"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      {!!taskList.length && (
        <>
          <Divider />

          <Box padding={3}>
            <Grid container spacing={4}>
              {taskList.map((task, index) => (
                <Grid item md={4} key={index}>
                  <div
                    className={`flex items-start space-x-3 rounded border-2 border-dashed border-blue-300 bg-white p-3 ${
                      selectedTaskIndex === index && "bg-blue-100"
                    }`}
                  >
                    <TaskAlt
                      color="primary"
                      fontSize="small"
                      style={{ marginTop: 4 }}
                    />

                    <div className="flex flex-grow flex-col space-y-1">
                      <div className="flex items-center justify-between">
                        <div className="truncate font-medium">{task.title}</div>

                        <IconButton
                          size="small"
                          onClick={() => handleEditTask(index)}
                        >
                          <EditOutlined
                            style={{ color: "gray", fontSize: 20 }}
                          />
                        </IconButton>
                      </div>

                      <div className="flex items-center space-x-2 text-gray-400">
                        <div> {task.category} </div>

                        {task.dueDate && (
                          <>
                            <FiberManualRecord style={{ fontSize: 10 }} />

                            <div>
                              {moment(task.dueDate).format("DD MMM, YYYY")}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

const WorkflowStepForm = ({
  stepDetail,
  isBusy,
  workflowType = "Client",
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STEP_STATE,
    },
  });

  // RTK Query
  const { data: documentTypes, isLoading: loadingDocumentTypes } =
    useListListQuery({
      type: "Document Types",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedDocumentTypes = React.useMemo(() => {
    return (
      documentTypes?.map((el) => ({
        label: el?.name,
        value: el?._id,
      })) ?? []
    );
  }, [documentTypes]);

  // methods
  const handleAddStepTask = (tasks) => {
    setValue("tasks", tasks);
  };

  React.useEffect(() => {
    if (!stepDetail) return;

    reset({
      ...stepDetail,
      requiredDocuments: stepDetail?.requiredDocuments?.map((doc) => ({
        label: doc?.name,
        value: doc?._id,
      })),
    });

    return () => {
      reset({ ...INITIAL_STEP_STATE });
    };
  }, [stepDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container rowSpacing={4} columnSpacing={6}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Step title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Step Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={8}>
            <Controller
              name="requiredDocuments"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  id="documents"
                  multiple
                  onBlur={onBlur}
                  loading={loadingDocumentTypes}
                  loadingText="Loading document types..."
                  options={mappedDocumentTypes}
                  getOptionLabel={(option) => option?.label}
                  isOptionEqualToValue={(option, value) =>
                    option?.label === value?.label
                  }
                  value={field?.value ?? []}
                  onChange={(_, data) => setValue("requiredDocuments", data)}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        label={option?.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Required Document"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={8}>
            <Controller
              name="content"
              control={control}
              rules={{ required: "Content is required" }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  onChange={onChange}
                  initialValue={
                    stepDetail?.content ?? "<p>Enter Step Detail.</p>"
                  }
                  height={150}
                />
              )}
            />
            {errors.content && (
              <p className="error">{errors.content.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="isActive"
              control={control}
              render={({ field, value }) => (
                <FormControlLabel
                  control={
                    <Switch
                      name="isActive"
                      color="primary"
                      checked={field.value}
                      value={value}
                      {...field}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <AddTaskForm
        workflowType={workflowType}
        stepDetail={stepDetail}
        onAddTask={handleAddStepTask}
      />

      <Box
        padding={3}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              requiredDocuments:
                data?.requiredDocuments?.map((el) => el?.value) ?? [],
            })
          )}
        >
          {!stepDetail ? "Add" : "Update"}
        </Button>
      </Box>
      <Divider />
    </>
  );
};

export default WorkflowStepForm;
