import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import UploadDocument from "../../../../../components/Shared/UploadDocument";

import { useListListQuery } from "../../../../../rtkStore/services/listService";

const INITIAL_STATE = {
  title: "",
  url: "",
  documentCategory: "Transaction",
  documentType: "",
};

const DocumentForm = ({
  documentDetail,
  workflowList,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const clientDocumentCategories = ["Transaction", "Workflow"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedCategory = React.useRef({});
  selectedCategory.current = watch("documentCategory");

  const selectedWorkflow = React.useRef({});
  selectedWorkflow.current = watch("workflow");

  const workflowSteps =
    workflowList?.find((wf) => wf._id === selectedWorkflow.current)?.steps ??
    [];

  // RTK Query
  const { data: documentTypes } = useListListQuery({
    type: "Document Types",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  React.useEffect(() => {
    if (!documentDetail) return reset({ ...INITIAL_STATE });

    reset({
      ...documentDetail,
      documentType: documentDetail?.documentType?._id ?? "",
      workflow: documentDetail?.workflow ?? "",
      step: documentDetail?.step ?? "",
    });
  }, [documentDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  size="small"
                  fullWidth
                  placeholder="Enter document title"
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="documentType"
              control={control}
              rules={{ required: "Please select a document type" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-doc-cat">Document Type</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-doc-cat"
                    label="Document Category"
                    placeholder="Select Category"
                    size="small"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {documentTypes?.map((type) => (
                      <MenuItem key={type._id} value={type._id}>
                        {type?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.documentType && (
              <p className="error">{errors.documentType.message}</p>
            )}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="documentCategory"
              control={control}
              rules={{ required: "Please select a category" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-doc-cat">Document Category</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-doc-cat"
                    label="Document Category"
                    placeholder="Select Category"
                    size="small"
                    id="role"
                    value={value ? value : clientDocumentCategories[0]}
                    {...field}
                  >
                    {clientDocumentCategories?.map((note) => (
                      <MenuItem key={note} value={note}>
                        {note}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.documentCategory && (
              <p className="error">{errors.documentCategory.message}</p>
            )}
          </Grid>
          {selectedCategory.current === "Workflow" &&
            (workflowList.length ? (
              <>
                <Grid item md={4}>
                  <Controller
                    name="workflow"
                    control={control}
                    render={({ field, value }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="select-workflow">Workflow</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-workflow"
                          label="Workflow"
                          placeholder="Select Workflow"
                          size="small"
                          id="role"
                          value={value}
                          {...field}
                        >
                          {workflowList?.map((workflow) => (
                            <MenuItem key={workflow?._id} value={workflow?._id}>
                              {workflow?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={4}>
                  <Controller
                    name="step"
                    control={control}
                    render={({ field, value }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="select-step">Step</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-step"
                          label="Step"
                          placeholder="Select Step"
                          size="small"
                          id="role"
                          value={value ? value : ""}
                          {...field}
                        >
                          {workflowSteps?.map((step) => (
                            <MenuItem key={step?._id} value={step?._id}>
                              {step?.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </>
            ) : (
              <Grid item md={4}>
                No workflow available
              </Grid>
            ))}
          <Grid item md={12}>
            <Controller
              name="url"
              control={control}
              rules={{ required: "Please select a file" }}
              render={({ field }) => (
                <UploadDocument
                  fileUrl={field?.value}
                  onFileUpload={(url) => setValue("url", url)}
                />
              )}
            />
            {errors.url && <p className="error">{errors.url.message}</p>}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {documentDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default DocumentForm;
