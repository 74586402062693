import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  PriorityHighOutlined,
  DonutLargeOutlined,
  EventOutlined,
  AccountCircleOutlined,
  MenuBookOutlined,
  SourceOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import moment from "moment";

import InformationTile from "../../../../components/Shared/InformationTile";
import InfoGridSkeleton from "../../../../components/Shared/Skeleton/InfoGridSkeleton";
import OpenInNewTab from "../../../../components/Shared/OpenInNewTab";

import { useApplicationDetail } from "../ApplicationDetailScreen";

const ApplicationBasicDetail = () => {
  const { loadingApplication, applicationDetail } = useApplicationDetail();

  return !loadingApplication ? (
    <Box padding={3}>
      <Typography variant="h6">{applicationDetail?.title}</Typography>

      <br />

      <Grid container spacing={4}>
        <Grid item md={3}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Client"
          >
            {applicationDetail?.client ? (
              <OpenInNewTab
                label={applicationDetail?.client?.fullName}
                link={`/app/client/${applicationDetail?.client?._id}/detail`}
              />
            ) : (
              "N/A"
            )}
          </InformationTile>
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <PriorityHighOutlined color="primary" />}
            title="Priority"
            subtitle={applicationDetail?.priority}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <DonutLargeOutlined color="primary" />}
            title="Stage"
            subtitle={applicationDetail?.stage?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EventOutlined color="error" />}
            title="Due Date"
            subtitle={moment(applicationDetail?.dueDate).format("YYYY/MM/DD")}
          />
        </Grid>

        {applicationDetail?.course ? (
          <Grid item md={3}>
            <InformationTile
              icon={() => <MenuBookOutlined color="primary" />}
              title="Preferred Course"
              subtitle={applicationDetail?.course?.title}
            />
          </Grid>
        ) : (
          <Grid item md={3}>
            <InformationTile
              icon={() => <SourceOutlined color="primary" />}
              title="Provider"
              subtitle={applicationDetail?.providerName}
            />
          </Grid>
        )}

        <Grid item md={3}>
          <InformationTile
            icon={() => <InfoOutlined color="primary" />}
            title="Status"
          >
            <div
              className={`uppercase ${
                applicationDetail?.isActive ? "text-green-600" : "text-red-600"
              }`}
            >
              {applicationDetail?.isActive ? "Active" : "Inactive"}
            </div>
          </InformationTile>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <InfoGridSkeleton />
  );
};

export default ApplicationBasicDetail;
