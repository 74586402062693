import React from "react";
import { toast } from "react-toastify";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import ApplicationBasicDetail from "./applicationComponents/ApplicationBasicDetail";
import ApplicationDocumentsTab from "./applicationComponents/ApplicationDocumentsTab";
import ApplicationNotesTab from "./applicationComponents/ApplicationNotesTab";
import ApplicationTasksTab from "./applicationComponents/ApplicationTasksTab";
import ApplicationWorkflowsTab from "./applicationComponents/ApplicationWorkflowsTab";
import CustomTabs from "../../../components/Shared/CustomTabs";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import RowsSkeleton from "../../../components/Shared/Skeleton/RowsSkeleton";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetApplicationDetailQuery,
  useUpdateApplicationMutation,
} from "../../../rtkStore/services/applicationApi";

const ApplicationDetailContext = React.createContext(null);

export const useApplicationDetail = () =>
  React.useContext(ApplicationDetailContext);

const ApplicationDetailScreen = () => {
  const [activeTab, setActiveTab] = React.useState("notes");

  const breadcrumbs = [
    {
      title: "Applications",
      path: "/app/application/list",
    },
    {
      title: "Application Detail",
    },
  ];

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  // RTK Query
  const {
    data: applicationDetail,
    isLoading: loadingApplication,
    error,
  } = useGetApplicationDetailQuery(id);

  const [updateApplication, { isLoading: updatingApplication }] =
    useUpdateApplicationMutation();

  const applicationDetailTabs = [
    {
      label: "Notes",
      value: "notes",
      badge: true,
      badgeCount: applicationDetail?.notes?.length,
    },
    {
      label: "Documents",
      value: "documents",
      badge: true,
      badgeCount: applicationDetail?.documents?.length,
    },
    {
      label: "Workflows",
      value: "workflows",
      badge: true,
      badgeCount: applicationDetail?.workflows?.length,
    },
    {
      label: "Tasks",
      value: "tasks",
    },
  ];

  // methods
  const handleUpdateApplicationStatus = async () => {
    try {
      await updateApplication({
        ...applicationDetail,
        isActive: !applicationDetail?.isActive,
      }).unwrap();
      toast.info(
        `Application successfully ${
          applicationDetail?.isActive ? "Disabled" : "Enabled"
        }!`
      );
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update application status!");
    }
  };

  const PageActions = () => (
    <div className="flex space-x-4">
      <Button
        variant="outlined"
        color={applicationDetail?.isActive ? "error" : "success"}
        onClick={handleUpdateApplicationStatus}
      >
        {applicationDetail?.isActive
          ? "Disable Application"
          : "Enable Application"}
      </Button>

      <Link to={`/app/application/${id}/edit`}>
        <Button variant="contained" startIcon={<EditOutlined />}>
          Edit
        </Button>
      </Link>
    </div>
  );

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "notes");
  }, [searchParams]);

  return (
    <ApplicationDetailContext.Provider
      value={{ applicationDetail, loadingApplication }}
    >
      <WithHeader
        breadcrumbs={breadcrumbs}
        isBusy={loadingApplication || updatingApplication}
        error={error}
        hasActions
        headerActions={<PageActions />}
      >
        <ApplicationBasicDetail
          applicationDetail={applicationDetail}
          isBusy={loadingApplication}
        />

        <Divider style={{ marginTop: "-1px" }} />

        <CustomTabs tabList={applicationDetailTabs} activeTab={activeTab} />

        {!loadingApplication ? (
          <>
            <CustomTabPanel activeTab={activeTab} value="notes">
              <ApplicationNotesTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="documents">
              <ApplicationDocumentsTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="workflows">
              <ApplicationWorkflowsTab />
            </CustomTabPanel>

            <CustomTabPanel activeTab={activeTab} value="tasks">
              <ApplicationTasksTab />
            </CustomTabPanel>
          </>
        ) : (
          <RowsSkeleton />
        )}
      </WithHeader>
    </ApplicationDetailContext.Provider>
  );
};

export default ApplicationDetailScreen;
