import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import { Button, CircularProgress } from "@mui/material";
import React from "react";

import useCloudinaryUpload from "../../hooks/useCloudinaryUpload";

const UploadDocument = ({ fileUrl, onFileUpload }) => {
  const [uploadedFileUrl, setUploadedFileUrl] = React.useState("");

  const { uploadToCloudinary, isUploading } = useCloudinaryUpload();

  const handleDocumentUpload = async (e) => {
    const {
      data: { secure_url },
    } = await uploadToCloudinary(e.target.files[0]);

    onFileUpload(secure_url);

    setUploadedFileUrl(secure_url);
  };

  React.useEffect(() => {
    if (!fileUrl) return;
    setUploadedFileUrl(fileUrl);

    return () => {
      setUploadedFileUrl("");
    };
  }, [fileUrl]);

  return (
    <div className="flex items-center">
      <input
        accept="image/*, application/pdf"
        className="hidden"
        id="upload"
        multiple
        type="file"
        onChange={handleDocumentUpload}
      />
      <label htmlFor="upload">
        <Button
          variant="outlined"
          color="primary"
          component="span"
          disabled={isUploading}
          startIcon={
            isUploading ? (
              <CircularProgress size={14} />
            ) : (
              <FileUploadOutlined />
            )
          }
        >
          {isUploading ? "Uploading..." : "Upload"}
        </Button>
      </label>

      <div className="ml-4 text-sm text-blue-800">{uploadedFileUrl ?? ""}</div>
    </div>
  );
};

export default UploadDocument;
