import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";
import UploadDocument from "../../../../components/Shared/UploadDocument";

import { useListAccountEntityUserQuery } from "../../../../rtkStore/services/userApi";
import { useAuth } from "../../../../hooks/useAuth";

const INITIAL_VALUE = {
  title: "",
  priority: "",
  stage: "Open",
  description: "",
  responsible: "",
  attachmentUrl: "",
};

const FeedbackForm = ({
  feedbackDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const navigate = useNavigate();

  const priorityEnums = ["Low", "Medium", "High", "Critical"];
  const feedbackStageEnums = [
    "Open",
    "In Progress",
    "Verification",
    "Closed",
    "Discarded",
  ];

  const { viewEntity } = useAuth();

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_VALUE,
    },
  });

  // RTK QUERY
  const { data: userList, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  React.useEffect(() => {
    if (!feedbackDetail) return;

    reset({ ...feedbackDetail, responsible: feedbackDetail?.responsible?._id });

    return () => {
      reset({ ...INITIAL_VALUE });
    };
  }, [feedbackDetail, reset]);

  return (
    <LoadingWrapper loading={isBusy || loadingUsers}>
      <Box padding={3}>
        <Grid container spacing={6}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Title"
                  type="text"
                  size="small"
                  {...field}
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="priority"
              control={control}
              rules={{ required: "Please select a priority" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-priority">Priority</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-priority"
                    label="Priority"
                    placeholder="Select Priority"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {priorityEnums?.map((priority) => (
                      <MenuItem key={priority} value={priority}>
                        {priority}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.priority && (
              <p className="error">{errors.priority.message}</p>
            )}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="stage"
              control={control}
              rules={{ required: "Please select a stage" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-stage">Stage</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-stage"
                    label="stage"
                    placeholder="Select stage"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {feedbackStageEnums?.map((stage) => (
                      <MenuItem key={stage} value={stage}>
                        {stage}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.stage && <p className="error">{errors.stage.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="responsible"
              control={control}
              rules={{ required: "Please select a responsile user" }}
              render={({ field, value }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-responsible">Responsible</InputLabel>
                  <Select
                    fullWidth
                    labelId="select-responsible"
                    label="Responsible"
                    placeholder="Select Responsible User"
                    id="role"
                    value={value ? value : ""}
                    {...field}
                  >
                    {userList?.map((user) => (
                      <MenuItem key={user?._id} value={user?._id}>
                        {user?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.responsible && (
              <p className="error">{errors.responsible.message}</p>
            )}
          </Grid>

          <Grid item md={6}>
            <Controller
              name="attachmentUrl"
              control={control}
              render={({ field }) => (
                <UploadDocument
                  fileUrl={field?.value}
                  onFileUpload={(url) => setValue("attachmentUrl", url)}
                />
              )}
            />
          </Grid>

          <Grid item md={8}>
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  type="text"
                  size="small"
                  multiline
                  rows={6}
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className="error">{errors.description.message}</p>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {feedbackDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default FeedbackForm;
