import React from "react";

import { Link, Outlet, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import EditOutlined from "@mui/icons-material/EditOutlined";

import ClientDetailNavigation from "./clientComponents/ClientDetailNavigation";
import StagePathwayBar from "../../../components/Shared/StagePathwayBar";
import WithHeader from "../../../layouts/WithHeader";

import { useListListQuery } from "../../../rtkStore/services/listService";
import {
  useGetClientDetailQuery,
  useUpdateClientMutation,
} from "../../../rtkStore/services/clientApi";
import AppDialog from "../../../components/Shared/AppDialog";

const ClientDetailsScreen = () => {
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const { id } = useParams();

  const breadcrumbs = [
    {
      title: "Clients",
      path: "/app/client/list",
    },
    { title: "Client Detail" },
  ];

  // RTK Query
  const {
    data: clientDetail,
    isLoading: loadingClientDetail,
    error,
  } = useGetClientDetailQuery(id);

  const [updateClient, { isLoading: updatingClient }] =
    useUpdateClientMutation();

  const { data: clientStages = [] } = useListListQuery({
    type: "Client Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const PageActions = () => (
    <div className="flex space-x-4">
      <Button
        variant="outlined"
        color={clientDetail?.isActive ? "error" : "success"}
        onClick={handleUpdateClientStatus}
      >
        {clientDetail?.isActive ? "Disable Client" : "Enable Client"}
      </Button>

      {(clientDetail?.nextAction || clientDetail?.nextActionDate) && (
        <Button variant="outlined" onClick={() => setShowConfirmDialog(true)}>
          Clear Next Action
        </Button>
      )}

      <Link to={`/app/client/${id}/edit`}>
        <Button variant="contained" startIcon={<EditOutlined />}>
          Edit
        </Button>
      </Link>
    </div>
  );

  // methods
  const handleClientStageUpdate = async ({ _id: stage }) => {
    try {
      await updateClient({ ...clientDetail, stage }).unwrap();
      toast.info("Client's stage updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update client's stage!");
    }
  };

  const handleClearClientNextAction = async () => {
    try {
      await updateClient({
        ...clientDetail,
        nextAction: null,
        nextActionDate: null,
      }).unwrap();
      toast.info("Client's next action cleared!");
      setShowConfirmDialog(false);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't clear client's next action!");
    }
  };

  const handleUpdateClientStatus = async () => {
    try {
      await updateClient({
        ...clientDetail,
        isActive: !clientDetail?.isActive,
      }).unwrap();
      toast.info(
        `Client successfully ${
          clientDetail?.isActive ? "Disabled" : "Enabled"
        }!`
      );
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update client status!");
    }
  };

  return (
    <>
      <WithHeader
        breadcrumbs={breadcrumbs}
        disablePaper
        error={error}
        isBusy={loadingClientDetail || updatingClient}
        toggleKey="CLIENT_DETAIL"
        hasActions
        headerActions={<PageActions />}
      >
        {!!clientDetail?.stage && (
          <>
            <StagePathwayBar
              stages={clientStages}
              currentStage={clientDetail?.stage?.name}
              onStageChange={handleClientStageUpdate}
            />
            <br />
          </>
        )}

        <ClientDetailNavigation />

        <Outlet />
      </WithHeader>

      <AppDialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleClearClientNextAction}
        isBusy={updatingClient}
        title="Clear Next Action?"
      >
        Are you sure you want to clear <strong>{clientDetail?.fullName}</strong>{" "}
        Next Action?
      </AppDialog>
    </>
  );
};

export default ClientDetailsScreen;
