import React from "react";

import { NavLink, useParams } from "react-router-dom";
import { Box, Paper } from "@mui/material";

const ClientDetailNavigation = () => {
  const { id } = useParams();

  const activeTabStyle = {
    color: "var(--primary",
    fontWeight: 500,
  };

  const inactiveTabStyle = {
    color: "darkgray",
    fontWeight: 500,
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      style={{ borderBottom: "none", marginBottom: -2 }}
    >
      <Box display="flex" gap={5} paddingX={1.75} paddingY={2}>
        <NavLink
          to={`/app/client/${id}/detail`}
          style={({ isActive }) =>
            isActive ? activeTabStyle : inactiveTabStyle
          }
        >
          Basic Information
        </NavLink>

        <NavLink
          to={`/app/client/${id}/detail/transactions`}
          style={({ isActive }) =>
            isActive ? activeTabStyle : inactiveTabStyle
          }
        >
          Transactions
        </NavLink>

        <NavLink
          to={`/app/client/${id}/detail/notes`}
          style={({ isActive }) =>
            isActive ? activeTabStyle : inactiveTabStyle
          }
        >
          All Notes
        </NavLink>
      </Box>
    </Paper>
  );
};

export default ClientDetailNavigation;
