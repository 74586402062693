import React from "react";

import { Box, Divider } from "@mui/material";

import TaskListItem from "./TaskListItem";

const TaskList = ({ tasks = [] }) => {
  return (
    <Box paddingX={3}>
      {tasks?.length ? (
        tasks.map((task, index) => (
          <React.Fragment key={index}>
            <TaskListItem taskItem={task} />
            {index !== tasks.length - 1 && <Divider />}
          </React.Fragment>
        ))
      ) : (
        <Box paddingY={3}>No task assigned.</Box>
      )}
    </Box>
  );
};

export default TaskList;
