import { Container, Grid, Typography } from '@mui/material'
import React from 'react'

export const PartnershipVideo = () => {
  return (
    <div className='step4Background'>
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={5}
          direction='row'
          justifyContent='center'
          alignItems='center'
          className='GlobalBranchContainer'
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            data-aos='fade-right'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              mt={3}
              sx={{ fontSize: 30, fontWeight: 700, marginBottom: 3 }}
            >
              Trusted Partner for International Student Recruitment
            </Typography>

            <Typography>
              <p className='GlobalBranchesDescription'>
                FSW Education has been helping individuals pursue their dreams
                of studying, working, settling, and living abroad, sfor the past
                seven years.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            data-aos='fade-left'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='2000'
          >
            <div className='video-responsive'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/cvq8umMPXuA'
                title='FSW Education: Your Trusted Partner for International Student Recruitment in Australia!'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
