import { baseApi } from "./baseApi";

const STUDY_OPTION_BASE_URL = "/study-options";

export const studyOptionApi = baseApi.injectEndpoints({
  tagTypes: ["StudyOption"],

  endpoints: (builder) => ({
    generateAssigneeOptions: builder.mutation({
      query: (payload) => ({
        url: `${STUDY_OPTION_BASE_URL}/generate`,
        method: "POST",
        body: payload,
      }),
    }),

    generateAssigneeStudyOptions: builder.mutation({
      query: ({ type, ...body }) => ({
        url: `${STUDY_OPTION_BASE_URL}/${type}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGenerateAssigneeOptionsMutation,
  useGenerateAssigneeStudyOptionsMutation,
} = studyOptionApi;
