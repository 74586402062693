import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  SchoolOutlined,
  EventOutlined,
  FiberManualRecord,
  DeleteOutline,
  EditOutlined,
  Add,
} from "@mui/icons-material";

import AppDialog from "../../../../../components/Shared/AppDialog";
import EducationForm from "./EducationForm";

import {
  useAddEducationDetailMutation,
  useRemoveEducationDetailMutation,
  useUpdateEducationDetailMutation,
} from "../../../../../rtkStore/services/clientApi";

import { useClientDetail } from "../ClientBasicInformationTab";

const EducationDetailTab = () => {
  const [showAddEducation, setShowAddEduction] = React.useState(false);
  const [selectedEducation, setSelectedEducation] = React.useState(null);
  const [showAppDialog, setShowAppDialog] = React.useState(false);

  const { id: clientId } = useParams();

  const {
    clientDetail: { educations },
  } = useClientDetail();

  // RTK Query
  const [addEducationDetail, { isLoading: addingEducationDetail }] =
    useAddEducationDetailMutation();

  const [updateEducationDetail, { isLoading: updatingEducationDetail }] =
    useUpdateEducationDetailMutation();

  const [removeEducationDetail, { isLoading: removingEducationalDetail }] =
    useRemoveEducationDetailMutation();

  // methods
  const handleCancelEducation = () => {
    setSelectedEducation(null);
    setShowAddEduction(false);
  };

  const handleEducationDetailEdit = (educationDetail) => {
    setSelectedEducation(educationDetail);
    setShowAddEduction(true);

    window && window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAddEductionDetail = async (data) => {
    setSelectedEducation({ ...data });
    try {
      await addEducationDetail({ ...data, clientId }).unwrap();
      toast.success("Education Information updated!");
      handleCancelEducation();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add education detail!");
    }
  };

  const handleUpdateEducationDetail = async (data) => {
    try {
      await updateEducationDetail({ ...data, clientId }).unwrap();
      toast.success("Education Information updated!");
      handleCancelEducation();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update education detail!");
    }
  };

  const handleRemoveEducationDetail = () => {
    if (!selectedEducation) return;

    removeEducationDetail({ ...selectedEducation, clientId })
      .unwrap()
      .then(() => {
        toast.info("Education Information removed!");
        setShowAppDialog(false);
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={3}
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <SchoolOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>
            Educational Institutions
          </Typography>
        </Box>

        {!showAddEducation && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => {
              setSelectedEducation(null);
              setShowAddEduction(true);
            }}
          >
            Education Detail
          </Button>
        )}
      </Box>

      <Collapse in={showAddEducation}>
        <EducationForm
          educationDetail={selectedEducation}
          isBusy={addingEducationDetail || updatingEducationDetail}
          onCancel={handleCancelEducation}
          onSubmit={
            !selectedEducation
              ? handleAddEductionDetail
              : handleUpdateEducationDetail
          }
        />
      </Collapse>

      <AppDialog
        title="Confirm Removal!"
        open={showAppDialog}
        isBusy={removingEducationalDetail}
        onConfirm={handleRemoveEducationDetail}
        onClose={() => setShowAppDialog(false)}
      >
        Are you sure you want to remove {selectedEducation?.institutionName}?
      </AppDialog>

      <Divider />

      <Box paddingX={3}>
        {!!educations.length ? (
          educations.map((educationDetail, index) => (
            <React.Fragment key={educationDetail._id}>
              <Box paddingY={3} display="flex" gap={2}>
                <SchoolOutlined
                  color="primary"
                  fontSize="small"
                  style={{ marginTop: 2 }}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography className="max-w-3xl">
                        {educationDetail.institutionName}
                      </Typography>

                      <FiberManualRecord
                        style={{ color: "gray", fontSize: 10 }}
                      />

                      <Typography className="flex-shrink-0">
                        {educationDetail?.level?.name}{" "}
                      </Typography>

                      {educationDetail?.major && (
                        <>
                          <FiberManualRecord
                            style={{ color: "gray", fontSize: 10 }}
                          />

                          <Typography className="flex-shrink-0">
                            {educationDetail.major}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box display="flex" alignItems="center" gap={4}>
                      <Tooltip title="Start Date" arrow placement="top">
                        <Box display="flex" gap={1}>
                          <EventOutlined fontSize="small" color="success" />
                          <Typography style={{ fontSize: 14 }}>
                            {moment(educationDetail.startDate).format(
                              "YYYY/MM/DD"
                            )}
                          </Typography>
                        </Box>
                      </Tooltip>

                      <Tooltip title="End Date" arrow placement="top">
                        <Box display="flex" gap={1}>
                          <EventOutlined fontSize="small" color="error" />
                          <Typography style={{ fontSize: 14 }}>
                            {moment(educationDetail.endDate).format(
                              "YYYY/MM/DD"
                            )}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>

                    <Box style={{ color: "gray" }}>
                      {educationDetail.remark}
                    </Box>
                  </Box>

                  <IconButton
                    color="error"
                    onClick={() => {
                      setSelectedEducation(educationDetail);
                      setShowAppDialog(true);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>

                  <IconButton
                    onClick={() => handleEducationDetailEdit(educationDetail)}
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </Box>

              {index !== educations.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Education Institutions</Box>
        )}
      </Box>
    </>
  );
};

export default EducationDetailTab;
