import React from "react";
import moment from "moment";

const AppTableDateTime = ({ date }) => {
  return (
    <div className="text-center">
      <div className="text-sm"> {moment(date)?.format("MMM DD, YYYY")}</div>
      <div className="text-xs text-gray-500">
        {new Date(date).toLocaleTimeString()}
      </div>
    </div>
  );
};

export default AppTableDateTime;
