import React from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import { Add, ReviewsOutlined } from "@mui/icons-material";

import ReviewForm from "../../../../components/Review/ReviewForm";
import ReviewList from "../../../../components/Review/ReviewList";

import {
  useAddCourseReviewMutation,
  useUpdateCourseReviewMutation,
} from "../../../../rtkStore/services/courseApi";

const CourseReviewsTab = ({ courseReviews = [] }) => {
  const [showReviewForm, setShowReviewForm] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const { id: courseId } = useParams();

  // RTK Query
  const [addCourseReview, { isLoading: addingCourseReview }] =
    useAddCourseReviewMutation();

  const [updateCourseReview, { isLoading: updatingCourseReview }] =
    useUpdateCourseReviewMutation();

  // methods
  const handleCancel = () => {
    setShowReviewForm(false);
    setSelectedReview(null);
  };

  const handleEditReview = (review) => {
    setSelectedReview(review);
    setShowReviewForm(true);
  };

  const handleAddCourseReview = async (data) => {
    try {
      await addCourseReview({ courseId, ...data }).unwrap();
      toast.success("Review successfully added!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't add review!");
    }
  };

  const handleUpdateCourseReview = async (data) => {
    try {
      await updateCourseReview({ courseId, ...data }).unwrap();
      toast.success("Review successfully updated!");
      handleCancel();
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update review!");
    }
  };

  return (
    <>
      <Box
        padding={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="inline-flex" alignItems="center" gap={1.5}>
          <ReviewsOutlined style={{ color: "gray" }} />

          <Typography style={{ fontWeight: 500 }}>Course Reviews</Typography>
        </Box>

        {!showReviewForm && (
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setShowReviewForm(true)}
          >
            Review
          </Button>
        )}
      </Box>

      <Divider />

      <Collapse in={showReviewForm}>
        <ReviewForm
          reviewDetail={selectedReview}
          isBusy={addingCourseReview || updatingCourseReview}
          onSubmit={
            !selectedReview ? handleAddCourseReview : handleUpdateCourseReview
          }
          onCancel={handleCancel}
        />

        <Divider />
      </Collapse>

      <ReviewList reviews={courseReviews} onReviewSelect={handleEditReview} />
    </>
  );
};

export default CourseReviewsTab;
