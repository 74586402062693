import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import WorkflowForm from "./workflowComponents/WorkflowForm";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetWorkflowDetailQuery,
  useUpdateWorkflowMutation,
} from "../../../rtkStore/services/workflowApi";

const WorkflowEditScreen = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Workflows",
      path: "/app/workflow/list",
    },
    { title: "Edit Workflow" },
  ];

  //   RTK Query
  const {
    data: workflowDetail,
    isLoading: loadingWorkflowDetail,
    error,
  } = useGetWorkflowDetailQuery(id);

  const [updateWorkflow, { isLoading: updatingWorkflow }] =
    useUpdateWorkflowMutation();

  //   methods
  const handleWorkflowUpdate = (data) => {
    updateWorkflow(data)
      .unwrap()
      .then(() => {
        navigate(breadcrumbs[0].path);
        toast.success("Workflow successfully updated!");
      })
      .catch(() => toast.error("Something went wrong!"));
  };

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingWorkflowDetail || updatingWorkflow}
      error={error}
    >
      <WorkflowForm
        workflowDetail={workflowDetail}
        isBusy={loadingWorkflowDetail || updatingWorkflow}
        onSubmit={handleWorkflowUpdate}
      />
    </WithHeader>
  );
};

export default WorkflowEditScreen;
