// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const baseApi = createApi({
  tagTypes: [
    "Admin",
    "Application",
    "Authorization",
    "Blog",
    "Client",
    "Course",
    "Dashboard",
    "Entity",
    "Feedback",
    "GLOBAL_LIST",
    "Knowledgebase",
    "Lead",
    "List",
    "Public",
    "Report",
    "Search",
    "Staff",
    "Status",
    "Step",
    "StudyOption",
    "Task",
    "Team",
    "Transaction",
    "University",
    "User",
    "Workflow",
    "WorkflowTransaction",
  ],

  reducerPath: "baseApi",

  baseQuery: fetchBaseQuery({
    baseUrl: "/api",

    prepareHeaders: (headers, { getState }) => {
      const user = getState().auth.user;

      if (user)
        headers.set("authorization", [
          `Bearer ${user.token}`,
          user.viewEntity && `viewEntity ${user.viewEntity.entity._id}`,
          user.viewEntity && `currentRole ${user.viewEntity.authorization._id}`,
        ]);

      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/users/login",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation } = baseApi;
