import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import UploadDocument from "../Shared/UploadDocument";

import { useListListQuery } from "../../rtkStore/services/listService";

const INITIAL_STATE = {
  title: "",
  url: "",
  documentCategory: "",
  documentType: "",
};

const BasicDocumentForm = ({
  documentDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const selectedCategory = React.useRef({});
  selectedCategory.current = watch("documentCategory");

  const selectedWorkflow = React.useRef({});
  selectedWorkflow.current = watch("workflow");

  // RTK Query
  const { data: documentTypes, loading: loadingDocumentTypes } =
    useListListQuery({
      type: "Document Types",
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const mappedDocumentTYpes = documentTypes?.map((type) => ({
    label: type?.name,
    value: type?._id,
  }));

  React.useEffect(() => {
    if (!documentDetail) return;

    reset({
      ...documentDetail,
      documentType: documentDetail?.documentType
        ? {
            label: documentDetail?.documentType?.name,
            value: documentDetail?.documentType?._id,
          }
        : "",
      workflow: documentDetail?.workflow ?? "",
      step: documentDetail?.step ?? "",
    });

    return () => {
      reset({ ...INITIAL_STATE });
    };
  }, [documentDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  size="small"
                  fullWidth
                  placeholder="Enter document title"
                />
              )}
            />
            {errors.title && <p className="error">{errors.title.message}</p>}
          </Grid>

          <Grid item md={4}>
            <Controller
              name="documentType"
              control={control}
              render={({ field, onBlur }) => (
                <Autocomplete
                  autoHighlight
                  onBlur={onBlur}
                  loading={loadingDocumentTypes}
                  loadingText="Loading Document Types..."
                  options={mappedDocumentTYpes ?? []}
                  value={field?.value}
                  onChange={(e, data) => setValue("documentType", data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Document Type"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      option && (
                        <MenuItem {...props} key={option?.value}>
                          {option?.label}
                        </MenuItem>
                      )
                    );
                  }}
                />
              )}
            />
            {errors.documentType && (
              <p className="error">{errors.documentType.message}</p>
            )}
          </Grid>

          <Grid item md={12}>
            <Controller
              name="url"
              control={control}
              rules={{ required: "Please select a file" }}
              render={({ field }) => (
                <UploadDocument
                  fileUrl={field?.value}
                  onFileUpload={(url) => setValue("url", url)}
                />
              )}
            />
            {errors.url && <p className="error">{errors.url.message}</p>}
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) =>
            onSubmit({
              ...data,
              documentType: data?.documentType?.value ?? null,
            })
          )}
        >
          {documentDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default BasicDocumentForm;
