import React from "react";

import { Route, Routes } from "react-router-dom";

import CourseCreateScreen from "../screens/Admin/Course/CourseCreateScreen";
import CourseDetailsScreen from "../screens/Admin/Course/CourseDetailsScreen";
import CourseEditScreen from "../screens/Admin/Course/CourseEditScreen";
import CourseListScreen from "../screens/Admin/Course/CourseListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const CourseRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CourseCreateScreen />} />
      <Route path=":id/edit" element={<CourseEditScreen />} />
      <Route path=":id/detail" element={<CourseDetailsScreen />} />
      <Route path="list" element={<CourseListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default CourseRouter;
