import React from "react";

import { Route, Routes } from "react-router-dom";

import ApplicationListScreen from "../screens/Admin/Application/ApplicationListScreen";
import ApplicationCreateScreen from "../screens/Admin/Application/ApplicationCreateScreen";
import ApplicationDetailScreen from "../screens/Admin/Application/ApplicationDetailScreen";
import ApplicationEditScreen from "../screens/Admin/Application/ApplicationEditScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const ApplicationRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<ApplicationCreateScreen />} />
      <Route path=":id/detail" element={<ApplicationDetailScreen />} />
      <Route path=":id/edit" element={<ApplicationEditScreen />} />
      <Route path="list" element={<ApplicationListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default ApplicationRouter;
