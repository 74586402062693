import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";

import AddToFavourite from "./courseComponents/AddToFavourite";
import CustomTabs from "../../../components/Shared/CustomTabs";
import CourseBasicDetailTab from "./courseComponents/CourseBasicDetailTab";
import CourseDatesTab from "./courseComponents/CourseDatesTab";
import CourseDetailSkeleton from "./courseComponents/CourseDetailSkeleton";
import CourseReviewsTab from "./courseComponents/CourseReviewsTab";
import CourseScholarshipsTab from "./courseComponents/CourseScholarshipsTab";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import WithHeader from "../../../layouts/WithHeader";

import { useGetCourseDetailQuery } from "../../../rtkStore/services/courseApi";

const CourseDetailsScreen = () => {
  const [activeTab, setActiveTab] = React.useState("info");

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const breadcrumbs = [
    { title: "Courses", path: "/app/course/list" },
    { title: "Course Detail" },
  ];

  // RTK Query
  const {
    data: courseDetail,
    isLoading: loadingCourseDetail,
    error,
  } = useGetCourseDetailQuery(id);

  const PageActions = () => (
    <div className="flex space-x-4">
      <AddToFavourite course={courseDetail} />

      <Link to={`/app/course/${id}/edit`}>
        <Button variant="contained" startIcon={<EditOutlined />}>
          Edit
        </Button>
      </Link>
    </div>
  );

  const courseDetailTabs = [
    { label: "Basic Information", value: "info" },
    {
      label: "Course Dates",
      value: "dates",
      badge: true,
      badgeCount: courseDetail?.courseDates?.length,
    },
    {
      label: "Scholarship Details",
      value: "scholarships",
      badge: true,
      badgeCount: courseDetail?.scholarships?.length,
    },
    {
      label: "Reviews",
      value: "reviews",
      badge: true,
      badgeCount: courseDetail?.reviews?.length,
    },
  ];

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "info");
  }, [searchParams]);

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingCourseDetail}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      <CustomTabs tabList={courseDetailTabs} activeTab={activeTab} />

      {!loadingCourseDetail ? (
        <>
          <CustomTabPanel activeTab={activeTab} value="info">
            <CourseBasicDetailTab courseDetail={courseDetail} />
          </CustomTabPanel>

          <CustomTabPanel activeTab={activeTab} value="dates">
            <CourseDatesTab courseDates={courseDetail?.courseDates} />
          </CustomTabPanel>

          <CustomTabPanel activeTab={activeTab} value="scholarships">
            <CourseScholarshipsTab
              courseScholarships={courseDetail?.scholarships}
            />
          </CustomTabPanel>

          <CustomTabPanel activeTab={activeTab} value="reviews">
            <CourseReviewsTab courseReviews={courseDetail?.reviews} />
          </CustomTabPanel>
        </>
      ) : (
        <CourseDetailSkeleton />
      )}
    </WithHeader>
  );
};

export default CourseDetailsScreen;
