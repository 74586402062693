import React from "react";

import { Link, useParams } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import EditOutlined from "@mui/icons-material/EditOutlined";

import TaskDetail from "./taskComponents/TaskDetail";
import TaskDetailSkeleton from "./taskComponents/TaskDetailSkeleton";
import TaskResponse from "./taskComponents/TaskResponse";
import WithHeader from "../../../layouts/WithHeader";

import { useGetTaskDetailQuery } from "../../../rtkStore/services/taskApi";

const TaskDetailScreen = () => {
  const { id } = useParams();

  const breadcrumbs = [
    { title: "Tasks", path: "/app/task/list" },
    {
      title: "Task Detail",
    },
  ];

  // RTK Query
  const {
    data: taskDetail,
    isLoading: loadingTaskDetail,
    error,
  } = useGetTaskDetailQuery(id);

  const PageActions = () => (
    <Link to={`/app/task/${id}/edit`}>
      <Button variant="contained" startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingTaskDetail}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      {!loadingTaskDetail ? (
        <>
          <TaskDetail taskDetail={taskDetail} />

          <Divider />

          <TaskResponse responses={taskDetail?.responses} />
        </>
      ) : (
        <TaskDetailSkeleton />
      )}
    </WithHeader>
  );
};

export default TaskDetailScreen;
