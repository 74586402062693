const _collatorInstance = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

const sortListAlphaNumerically = ({ sortableList, sortableKey }) => {
  const _alphaNumericSorted =
    sortableList?.sort((a, b) => {
      return _collatorInstance.compare(a[sortableKey], b[sortableKey]) > 0
        ? 1
        : -1;
    }) ?? [];

  return _alphaNumericSorted;
};

export default sortListAlphaNumerically;
