import React from "react";

import { Route, Routes } from "react-router-dom";

import AllListScreen from "../screens/Admin/List/AllListScreen";
import ListDetailScreen from "../screens/Admin/List/ListDetailScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const ListRouter = () => {
  return (
    <Routes>
      <Route path="all" element={<AllListScreen />} />
      <Route path="detail/:id" element={<ListDetailScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default ListRouter;
