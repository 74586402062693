import React from "react";
import { CircularProgress, IconButton } from "@mui/material";
import {
  CampaignOutlined,
  StarBorderRounded,
  StarRounded,
} from "@mui/icons-material";

import OpenInNewTab from "../Shared/OpenInNewTab";

const StudyOptionCourseCard = ({
  course,
  isCourseSelected = false,
  isBusy = false,
  onSelectCourse = () => {},
}) => {
  return (
    <div className="group relative mr-4 flex flex-col space-y-2 rounded border border-blue-100 px-4 py-3 hover:bg-blue-50">
      <div className="flex items-center justify-between">
        <div className="flex flex-grow items-center space-x-2 pr-4">
          <OpenInNewTab
            label={course?.title}
            link={`/app/course/${course?._id}/detail`}
            className="flex-grow text-sm line-clamp-1"
          />

          {!!course?.agentPromotions?.length && (
            <div
              title={`${course?.agentPromotions
                ?.map((el) => el?.name)
                ?.join(", ")}`}
              className="grid h-6  w-6 flex-shrink-0 place-content-center overflow-hidden rounded-full border"
            >
              <CampaignOutlined color="primary" style={{ fontSize: 18 }} />
            </div>
          )}
        </div>

        <div
          title={isCourseSelected ? "Selected Course" : "Mark as selected"}
          className="absolute -top-2 -right-2 grid h-8 w-8 flex-shrink-0 place-content-center rounded-full border  bg-white"
        >
          {!isBusy ? (
            <IconButton size="small" disabled={isBusy} onClick={onSelectCourse}>
              {isCourseSelected ? (
                <StarRounded className="text-yellow-400" />
              ) : (
                <StarBorderRounded />
              )}
            </IconButton>
          ) : (
            <CircularProgress size={24} />
          )}
        </div>
      </div>

      <div
        title={`${course?.currency?.symbol} ${course?.tuitionFee} (${course?.currency?.code}) Per ${course?.feeType}`}
        className="flex-shrink-0 text-sm line-clamp-1"
      >
        {`${course?.currency?.symbol} ${course?.tuitionFee} (${course?.currency?.code}) Per ${course?.feeType}`}
      </div>
    </div>
  );
};

export default StudyOptionCourseCard;
