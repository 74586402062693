import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Divider, Grid } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import ReactHtmlParser from "html-react-parser";
import DonutLargeOutlined from "@mui/icons-material/DonutLargeOutlined";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";

import GridSkeleton from "../../../components/Shared/Skeleton/GridSkeleton";
import InformationTile from "../../../components/Shared/InformationTile";
import ManageWorkflowSteps from "./workflowComponents/ManageWorkflowSteps";
import RowsSkeleton from "../../../components/Shared/Skeleton/RowsSkeleton";
import WithHeader from "../../../layouts/WithHeader";

import { useGetWorkflowDetailQuery } from "../../../rtkStore/services/workflowApi";

const WorkflowDetailScreen = () => {
  const { id } = useParams();

  const breadcrumbs = [
    {
      title: "Workflows",
      path: "/app/workflow/list",
    },
    { title: "Workflow Detail" },
  ];

  //   RTK Query
  const {
    data: workflowDetail,
    isLoading: loadingWorkflowDetail,
    error,
  } = useGetWorkflowDetailQuery(id);

  const PageActions = () => (
    <Link to={`/app/workflow/${id}/edit`}>
      <Button variant="contained" startIcon={<EditOutlined />}>
        Edit
      </Button>
    </Link>
  );

  return (
    <WithHeader
      breadcrumbs={breadcrumbs}
      isBusy={loadingWorkflowDetail}
      error={error}
      hasActions
      headerActions={<PageActions />}
    >
      {!loadingWorkflowDetail ? (
        <>
          <Box padding={3}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <InformationTile
                  icon={() => <AccountTreeOutlined color="primary" />}
                  title="Title"
                  subtitle={workflowDetail?.title}
                />
              </Grid>

              <Grid item md={4}>
                <InformationTile
                  icon={() => <DonutLargeOutlined color="primary" />}
                  title="Type"
                  subtitle={workflowDetail?.workflowType}
                />
              </Grid>

              <Grid item md={4}>
                <InformationTile
                  icon={() => <InfoOutlined color="primary" />}
                  title="Status"
                >
                  <div
                    className={`uppercase ${
                      workflowDetail?.isActive
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {workflowDetail?.isActive ? "Active" : "Inactive"}
                  </div>
                </InformationTile>
              </Grid>
            </Grid>

            {workflowDetail?.content && (
              <Box marginTop={3}>
                {ReactHtmlParser(workflowDetail?.content)}
              </Box>
            )}
          </Box>

          <Divider />

          <ManageWorkflowSteps workflowDetail={workflowDetail} />
        </>
      ) : (
        <>
          <GridSkeleton />

          <RowsSkeleton />
        </>
      )}
    </WithHeader>
  );
};

export default WorkflowDetailScreen;
