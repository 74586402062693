import React from "react";

import { Box, Grid, Skeleton } from "@mui/material";

const CourseDetailSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4} padding={3}>
      <Skeleton variant="rect" height={50} />
      <Grid container rowSpacing={4} columnSpacing={6}>
        {new Array(6).fill(null).map((_, index) => (
          <Grid key={index} item md={4}>
            <Skeleton variant="rect" height={60} />
          </Grid>
        ))}

        {new Array(2).fill(null).map((_, index) => (
          <Grid key={index} item md={12}>
            <Skeleton variant="rect" height={60} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseDetailSkeleton;
