import React from "react";

import { Route, Routes } from "react-router-dom";

import TransactionCreateScreen from "../screens/Admin/Transaction/TransactionCreateScreen";
import TransactionDetailScreen from "../screens/Admin/Transaction/TransactionDetailScreen";
import TransactionEditScreen from "../screens/Admin/Transaction/TransactionEditScreen";
import TransactionListScreen from "../screens/Admin/Transaction/TransactionListScreen";
import PageScreen404 from "../screens/ErrorPages/404PageScreen";

const TransactionRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<TransactionCreateScreen />} />
      <Route path=":id/edit" element={<TransactionEditScreen />} />
      <Route path=":id/detail" element={<TransactionDetailScreen />} />
      <Route path="list" element={<TransactionListScreen />} />
      <Route path="*" element={<PageScreen404 />} />
    </Routes>
  );
};

export default TransactionRouter;
