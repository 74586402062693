import React from "react";
import {
  Autocomplete,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import AppDialog from "../Shared/AppDialog";

import { useAuth } from "../../hooks/useAuth";
import { useListAccountEntityUserQuery } from "../../rtkStore/services/userApi";

const INITIAL_STATE = {
  responsible: "",
  generatedDate: moment().format("YYYY-MM-DD"),
};

const GenerateStudyOptionsForm = ({
  show = false,
  onClose = () => {},
  isBusy = false,
  onAddStudyOption = () => {},
}) => {
  const { viewEntity } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  // RTK QUERY
  const { data: userList, isLoading: loadingUsers } =
    useListAccountEntityUserQuery({
      entityId: viewEntity?.entity?._id,
      status: "Active",
    });

  // methods
  const handleReset = () => {
    reset({ ...INITIAL_STATE });
    onClose();
  };

  return (
    <AppDialog
      maxWidth="xs"
      fullWidth
      hideIcon
      title="Generate Study Options"
      open={show}
      isBusy={loadingUsers || isBusy}
      onClose={handleReset}
      onConfirm={handleSubmit((data) => {
        onAddStudyOption({ ...data, responsible: data?.responsible?.id });
      })}
    >
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Controller
            name="generatedDate"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Generation Date"
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(field.value).format("YYYY-MM-DD") ?? ""}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item md={12}>
          <Controller
            name="responsible"
            control={control}
            rules={{ required: "Please select a responsible user" }}
            render={({ field, value }) => (
              <Controller
                name="responsible"
                control={control}
                rules={{ required: "Responsible user is required" }}
                render={({ field, onBlur }) => (
                  <Autocomplete
                    id="responsible"
                    autoHighlight
                    onBlur={onBlur}
                    loading={loadingUsers}
                    loadingText="Loading Responsible Users..."
                    options={
                      userList?.map((el) => ({
                        label: el?.name,
                        id: el?._id,
                      })) ?? []
                    }
                    value={field?.value}
                    onChange={(e, data) => setValue("responsible", data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Responsible"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        option && (
                          <MenuItem {...props} key={option?.value}>
                            {option?.label}
                          </MenuItem>
                        )
                      );
                    }}
                  />
                )}
              />
            )}
          />
          {errors?.responsible && (
            <FormHelperText error={errors.responsible}>
              {errors.responsible.message}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default GenerateStudyOptionsForm;
