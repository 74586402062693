import { baseApi } from "./baseApi";

const DASHBOARD_BASE_URL = "/dashboard";

export const dashboardApi = baseApi.injectEndpoints({
  tagTypes: ["Dashboard"],

  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: () => `${DASHBOARD_BASE_URL}/stats`,
    }),
  }),
});

export const { useGetDashboardStatsQuery } = dashboardApi;
