import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import RichTextEditor from "../../../../components/Shared/RichTextEditor";
import LoadingWrapper from "../../../../components/Shared/LoadingWrapper";
import UploadImage from "../../../../components/Shared/UploadImage";

import { useListListQuery } from "../../../../rtkStore/services/listService";
import { useListGlobalListQuery } from "../../../../rtkStore/services/globalListApi";
import { LIST_CONSTANTS } from "../../../../utils/staticData/listOptions";
import { GLOBAL_LIST_CONSTANTS } from "../../../../utils/staticData/globalListOptions";

const INITIAL_STATE = {
  logo: "",
  title: "",
  institutionOverview: "",
  website: "",
  type: "",
  ownership: "",
  headOfficeAddress: "",
  country: null,
  location: "",
  assessmentLevel: "",
  priority: "",
  accommodation: "",
  isActive: true,
};

const UniversityForm = ({
  universityDetail = null,
  isBusy = false,
  onSubmit = () => {},
}) => {
  const navigate = useNavigate();

  const ownershipTypes = ["Public", "Private"];

  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  // RTK Query
  const { data: institutionTypes, isLoading: loadingInstitutionTypes } =
    useListListQuery({
      type: LIST_CONSTANTS.INSTITUTION_TYPES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const { data: locationOptions, isLoading: loadingLocationOptions } =
    useListGlobalListQuery({
      type: GLOBAL_LIST_CONSTANTS.LOCATIONS,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  const {
    data: universityAssessmentLevels,
    isLoading: loadingUniversityAssessmentLevels,
  } = useListListQuery({
    type: LIST_CONSTANTS.UNIVERSITY_ASSESSMENT_LEVELS,
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const { data: universityPriorities, isLoading: loadingUniversityPriorities } =
    useListListQuery({
      type: LIST_CONSTANTS.UNIVERSITY_PRIORITIES,
      isSubType: false,
      includeChildren: true,
      status: "Active",
    });

  //   methods
  const handleLogoUrl = (imageUrl) => {
    const currentValues = getValues();
    reset({ ...currentValues, logo: imageUrl });
  };

  React.useEffect(() => {
    if (!universityDetail) return;

    reset({
      ...universityDetail,
      type: universityDetail?.type?._id ?? "",
      location: universityDetail?.location?._id ?? "",
      assessmentLevel: universityDetail?.assessmentLevel?._id ?? "",
      priority: universityDetail?.priority?._id ?? "",
    });

    return () => {
      reset({ ...INITIAL_STATE });
    };
  }, [universityDetail, reset]);

  return (
    <LoadingWrapper
      loading={
        isBusy ||
        loadingInstitutionTypes ||
        loadingLocationOptions ||
        loadingUniversityAssessmentLevels ||
        loadingUniversityPriorities
      }
    >
      <Box padding={2}>
        <Grid container spacing={4}>
          <Grid item md={2}>
            <Controller
              name="logo"
              control={control}
              render={() => (
                <UploadImage
                  imageUrl={universityDetail ? universityDetail.logo : null}
                  onUploadSuccess={handleLogoUrl}
                />
              )}
            />
          </Grid>

          <Grid item md={10}>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Institution Name is required." }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Institution Name"
                      type="text"
                      size="small"
                      error={!!errors?.title}
                      helperText={errors?.title?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: "Institution type is required." }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-type">Institution Type</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-type"
                        label="Course Level"
                        placeholder="Select Type"
                        size="small"
                        id="role"
                        helperText={"Helper text"}
                        value={value ? value : ""}
                        {...field}
                      >
                        {institutionTypes?.map((type) => (
                          <MenuItem key={type._id} value={type._id}>
                            {type?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors?.type}>
                        {errors.type && errors.type.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="assessmentLevel"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-assessmentLevel">
                        University Assessment Level
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-assessmentLevel"
                        label="University Assessment Level"
                        placeholder="Select Type"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {universityAssessmentLevels?.map((assessmentLevel) => (
                          <MenuItem
                            key={assessmentLevel._id}
                            value={assessmentLevel._id}
                          >
                            {assessmentLevel?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="ownership"
                  control={control}
                  rules={{ required: "Please select a ownership type" }}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-ownership">
                        Institution Ownership
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-ownership"
                        label="Institute Ownership"
                        placeholder="Select Role"
                        id="role"
                        value={value ? value : "Public"}
                        {...field}
                      >
                        {ownershipTypes?.map((ot) => (
                          <MenuItem key={ot} value={ot}>
                            {ot}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors?.ownership}>
                        {errors.ownership && errors.ownership.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="location"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-location">Location</InputLabel>
                      <Select
                        fullWidth
                        labelId="select-location"
                        label="Location"
                        placeholder="Select Location"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {locationOptions?.map((loc) => (
                          <MenuItem key={loc._id} value={loc._id}>
                            {loc?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="headOfficeAddress"
                  control={control}
                  rules={{ required: "Head office address is required." }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Head Office Address"
                      type="text"
                      size="small"
                      error={!!errors?.headOfficeAddress}
                      helperText={errors?.headOfficeAddress?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="priority"
                  control={control}
                  render={({ field, value }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-priority">
                        University Priority
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="select-priority"
                        label="University Priority"
                        placeholder="Select Type"
                        size="small"
                        id="role"
                        value={value ? value : ""}
                        {...field}
                      >
                        {universityPriorities?.map((priority) => (
                          <MenuItem key={priority._id} value={priority._id}>
                            {priority?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="website"
                  control={control}
                  rules={{ required: "Website is required." }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Website"
                      type="text"
                      size="small"
                      error={!!errors?.website}
                      helperText={errors?.website?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4}>
                <Controller
                  name="providerCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Provider Code"
                      type="text"
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4}>
            <Controller
              name="isActive"
              control={control}
              render={({ field, value }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      name="isActive"
                      color="primary"
                      checked={field.value}
                      value={value}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <div
              style={{ color: "gray", fontSize: 15, marginBottom: ".75rem" }}
            >
              Institution Overview
            </div>

            <Controller
              name="institutionOverview"
              control={control}
              rules={{ required: "Overview is required." }}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  onChange={onChange}
                  initialValue={
                    universityDetail?.institutionOverview
                      ? universityDetail.institutionOverview
                      : "<p>Write your college overview.</p>"
                  }
                />
              )}
            />
            <FormHelperText error={!!errors?.institutionOverview}>
              {errors.institutionOverview && errors.institutionOverview.message}
            </FormHelperText>
          </Grid>

          <Grid item md={12}>
            <div
              style={{ color: "gray", fontSize: 15, marginBottom: ".75rem" }}
            >
              Accommodation Details
            </div>
            <Controller
              name="accommodation"
              control={control}
              render={({ field: { onChange } }) => (
                <RichTextEditor
                  onChange={onChange}
                  initialValue={
                    universityDetail?.accommodation
                      ? universityDetail.accommodation
                      : "<p>Write your college accommodation detail.</p>"
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={() => navigate(-1)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={isBusy}
          onClick={handleSubmit((data) => {
            const payload = {
              ...data,
              location: data?.location?.length ? data?.location : null,
              assessmentLevel: data?.assessmentLevel?.length
                ? data?.assessmentLevel
                : null,
              priority: data?.priority?.length ? data?.priority : null,
            };
            delete payload.affiliatedCourses;
            delete payload.campusAreas;
            delete payload.reviews;

            onSubmit(payload);
          })}
        >
          {universityDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </LoadingWrapper>
  );
};

export default UniversityForm;
