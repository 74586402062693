import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import WithHeader from "../../../layouts/WithHeader";
import CourseForm from "./courseComponents/CourseForm";

import { useCreateCourseMutation } from "../../../rtkStore/services/courseApi";

const CourseCreateScreen = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Courses",
      path: "/app/course/list",
    },
    { title: "New Course" },
  ];

  // RTK Query
  const [createCourse, { isLoading: creatingCourse, error }] =
    useCreateCourseMutation();

  // methods
  const handleCreateCourse = async (data) => {
    try {
      const { _id } = await createCourse(data).unwrap();
      navigate(`/app/course/${_id}/detail`);
      toast.success("Course successfully created!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't create course");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} isBusy={creatingCourse} error={error}>
      <CourseForm onSubmit={handleCreateCourse} />
    </WithHeader>
  );
};

export default CourseCreateScreen;
