import React from "react";
import { toast } from "react-toastify";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Button, Divider, Paper } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

import CustomTabs from "../../../components/Shared/CustomTabs";
import CustomTabPanel from "../../../components/Shared/CustomTabPanel";
import RowsSkeleton from "../../../components/Shared/Skeleton/RowsSkeleton";
import StagePathwayBar from "../../../components/Shared/StagePathwayBar";
import TransactionBasicDetail from "./transactionComponents/TransactionBasicDetail";
import TransactionNoteTab from "./transactionComponents/TransactionNoteTab";
import TransactionWorkflowsTab from "./transactionComponents/TransactionWorkflowsTab";
import TransactionDocumentsTab from "./transactionComponents/TransactionDocumentsTab";
import TransactionTasksTab from "./transactionComponents/TransactionTasksTab";
import TransactionApplicationsTab from "./transactionComponents/TransactionApplicationsTab";
import WithHeader from "../../../layouts/WithHeader";

import {
  useGetTransactionDetailQuery,
  useUpdateTransactionMutation,
} from "../../../rtkStore/services/transactionApi";
import { useListListQuery } from "../../../rtkStore/services/listService";

const TransactionDetailContext = React.createContext(null);

export const useTransactionDetail = () =>
  React.useContext(TransactionDetailContext);

const TransactionDetailScreen = () => {
  const [activeTab, setActiveTab] = React.useState("notes");

  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const breadcrumbs = [
    { title: "Transactions", path: "/app/transaction/list" },
    { title: "Transaction Detail" },
  ];

  // RTK Query
  const {
    data: transactionDetail,
    isLoading: loadingTransaction,
    error,
  } = useGetTransactionDetailQuery(id);

  const [updateTransaction, { isLoading: updatingTransaction }] =
    useUpdateTransactionMutation();

  const { data: transactionStages = [] } = useListListQuery({
    type: "General Stages",
    isSubType: false,
    includeChildren: true,
    status: "Active",
  });

  const transactionDetailTabs = [
    {
      label: "Notes",
      value: "notes",
      badge: true,
      badgeCount: transactionDetail?.notes?.length,
    },
    {
      label: "Documents",
      value: "documents",
      badge: true,
      badgeCount: transactionDetail?.documents?.length,
    },
    {
      label: "Workflows",
      value: "workflows",
      badge: true,
      badgeCount: transactionDetail?.workflows?.length,
    },
    {
      label: "Applications",
      value: "applications",
    },
    {
      label: "Tasks",
      value: "tasks",
    },
  ];

  //   methods
  const handleTransactionStageUpdate = async ({ _id: stage }) => {
    try {
      await updateTransaction({ ...transactionDetail, stage }).unwrap();
      toast.info("Transaction's stage updated!");
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update transaction's stage!");
    }
  };

  const handleUpdateTransactionStatus = async () => {
    try {
      await updateTransaction({
        ...transactionDetail,
        isActive: !transactionDetail?.isActive,
      });
      toast.info(
        `Transaction successfully ${
          transactionDetail?.isActive ? "Disabled" : "Enabled"
        }!`
      );
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't update transaction's status!"
      );
    }
  };

  const PageActions = () => (
    <div className="flex space-x-4">
      <Button
        variant="outlined"
        color={transactionDetail?.isActive ? "error" : "success"}
        onClick={handleUpdateTransactionStatus}
      >
        {transactionDetail?.isActive
          ? "Disable Transaction"
          : "Enable Transaction"}
      </Button>

      <Link to={`/app/transaction/${id}/edit`}>
        <Button variant="contained" startIcon={<EditOutlined />}>
          Edit
        </Button>
      </Link>
    </div>
  );

  React.useEffect(() => {
    const tab = searchParams.get("tab");
    setActiveTab(tab ?? "notes");
  }, [searchParams]);

  return (
    <TransactionDetailContext.Provider
      value={{ transactionDetail, loadingTransaction }}
    >
      <WithHeader
        breadcrumbs={breadcrumbs}
        isBusy={loadingTransaction || updatingTransaction}
        error={error}
        disablePaper
        hasActions
        headerActions={<PageActions />}
      >
        {!!transactionDetail?.stage && (
          <>
            <StagePathwayBar
              stages={transactionStages}
              currentStage={transactionDetail?.stage?.name}
              onStageChange={handleTransactionStageUpdate}
            />
            <br />
          </>
        )}

        <Paper elevation={0} variant="outlined">
          <TransactionBasicDetail
            transactionDetail={transactionDetail}
            isBusy={loadingTransaction}
          />

          <Divider />

          <CustomTabs tabList={transactionDetailTabs} activeTab={activeTab} />

          {!loadingTransaction ? (
            <>
              <CustomTabPanel activeTab={activeTab} value="notes">
                <TransactionNoteTab />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="documents">
                <TransactionDocumentsTab />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="workflows">
                <TransactionWorkflowsTab />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="applications">
                <TransactionApplicationsTab />
              </CustomTabPanel>

              <CustomTabPanel activeTab={activeTab} value="tasks">
                <TransactionTasksTab />
              </CustomTabPanel>
            </>
          ) : (
            <RowsSkeleton />
          )}
        </Paper>
      </WithHeader>
    </TransactionDetailContext.Provider>
  );
};

export default TransactionDetailScreen;
