import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";

import AppDialog from "../../../../components/Shared/AppDialog";
import StudyOptionCard from "../../../../components/StudyOption/StudyOptionCard";
import StudyOptionCourseCard from "../../../../components/StudyOption/StudyOptionCourseCard";

import {
  useRemoveLeadStudyOptionMutation,
  useUpdateLeadSelectedStudyOptionMutation,
} from "../../../../rtkStore/services/leadApi";

import { useLeadDetail } from "../LeadDetailScreen";

const LeadStudyOptionsTab = () => {
  const [selectedStudyOption, setSelectedStudyOption] = React.useState(null);

  const { id: leadId } = useParams();

  const {
    leadDetail: { studyOptions },
  } = useLeadDetail();

  // RTK Query
  const [removeLeadStudyOption, { isLoading }] =
    useRemoveLeadStudyOptionMutation();

  // methods
  const handleDeleteStudyOption = async () => {
    if (!selectedStudyOption) return;
    setSelectedStudyOption(selectedStudyOption);

    try {
      await removeLeadStudyOption({
        leadId,
        studyOption: selectedStudyOption,
      }).unwrap();
      toast.info("Study option successfully removed!");
      setSelectedStudyOption(null);
    } catch (err) {
      toast.err(err?.data?.message ?? "Couldn't remove study option!");
    }
  };

  return (
    <>
      <Box paddingX={3}>
        {studyOptions?.length ? (
          studyOptions?.map((el, index) => (
            <React.Fragment key={index}>
              <StudyOptionCard
                studyOption={el}
                studyOptionsLink={`/study-options/${el?._id}/leads/${leadId}`}
                courseCardComponent={(props) => (
                  <LeadStudyOptionCourseCard {...props} />
                )}
                onDelete={(studyOption) => setSelectedStudyOption(studyOption)}
              />
              {index !== studyOptions.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Box paddingY={3}>No Study Options Generated</Box>
        )}
      </Box>

      <AppDialog
        title="Confirm Deletion!"
        maxWidth="sm"
        open={!!selectedStudyOption}
        onClose={() => setSelectedStudyOption(null)}
        onConfirm={handleDeleteStudyOption}
        isBusy={isLoading}
      >
        Are you sure you want to delete the study option?
      </AppDialog>
    </>
  );
};

export default LeadStudyOptionsTab;

export const LeadStudyOptionCourseCard = ({ course, studyOption }) => {
  const { id: leadId } = useParams();

  const courseIsSelected = studyOption?.selectedCourse === course?._id;

  // RTK Query
  const [
    updateSelectedStudyOption,
    { isLoading: updatingSelectedStudyOption },
  ] = useUpdateLeadSelectedStudyOptionMutation();

  // methods
  const handleUpdateSelectedStudyOption = async () => {
    try {
      await updateSelectedStudyOption({
        leadId,
        studyOptionId: studyOption?._id,
        selectedCourse: course?._id,
      }).unwrap();
      toast.info("Study option selected course updated!");
    } catch (err) {
      toast.error(
        err?.data?.message ?? "Couldn't update selected study option course!"
      );
    }
  };

  return (
    <StudyOptionCourseCard
      isBusy={updatingSelectedStudyOption}
      course={course}
      isCourseSelected={courseIsSelected}
      onSelectCourse={handleUpdateSelectedStudyOption}
    />
  );
};
