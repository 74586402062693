import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import MenuItem from "@mui/material/MenuItem";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
// import ListItemText from "@mui/material/ListItemText";
// import MenuList from "@mui/material/MenuList";
// import ListItemIcon from "@mui/material/ListItemIcon";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function OutLocation({ addresses }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return addresses?.map((address) => (
    <Accordion
      key={address?.country}
      expanded={expanded === address?.country}
      onChange={handleChange(address?.country)}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{address?.country}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          {address?.locations?.map((loc, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card style={{ height: "100%", padding: "5px 5px" }}>
                <CardMedia style={{ height: "200px" }}>
                  {loc?.embeddedMap && <loc.embeddedMap />}
                </CardMedia>

                <CardContent style={{ height: "100%", padding: "5px 5px" }}>
                  <div className="py-1.5 text-center  font-medium">
                    {loc?.name}
                  </div>

                  <div className="flex items-start space-x-4 py-1.5">
                    <LocationOnIcon className="redColor" fontSize="small" />
                    <div className="flex-wrap text-sm">{loc?.address}</div>
                  </div>

                  <div className="flex items-start space-x-4 py-1.5">
                    <MailOutlineIcon className="redColor" fontSize="small" />
                    <div className="flex-wrap">
                      <a
                        href={`mailto:${address?.contactEmail}`}
                        className="text-sm"
                      >
                        {address?.contactEmail}
                      </a>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4 py-1.5">
                    <PhoneInTalkIcon className="redColor" fontSize="small" />
                    <div className="flex-wrap text-sm">{loc?.phone}</div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ));
}
