import { baseApi } from "./baseApi";

const APPLICATION_BASE_URL = "/applications";

const __generateApiUrl = (path) => {
  return APPLICATION_BASE_URL + path;
};

export const applicationApi = baseApi.injectEndpoints({
  tagTypes: ["Application"],

  endpoints: (builder) => ({
    listApplication: builder.query({
      query: (params) => ({ url: __generateApiUrl("/list"), params }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Application", _id })),
              { type: "Application", id: "LIST" },
            ]
          : [{ type: "Application", id: "LIST" }],
    }),

    addApplication: builder.mutation({
      query: (payload) => ({
        url: __generateApiUrl("/add"),
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", id: "LIST" },
      ],
    }),

    getApplicationDetail: builder.query({
      query: (id) => __generateApiUrl(`/${id}/detail`),
      providesTags: (result, error, id) => [
        { type: "Application", id },
        { type: "Application", id: "LIST" },
      ],
    }),

    updateApplication: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: __generateApiUrl(`/${_id}/edit`),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
        { type: "Application", id: "LIST" },
        { type: "Report", id: "APPLICATION" },
      ],
    }),

    listClientApplication: builder.query({
      query: (id) => __generateApiUrl(`/clients/${id}`),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Application", _id })),
              { type: "Application", id: "LIST" },
            ]
          : [{ type: "Application", id: "LIST" }],
    }),

    listTransactionApplication: builder.query({
      query: (id) => __generateApiUrl(`/transactions/${id}`),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Application", _id })),
              { type: "Application", id: "LIST" },
            ]
          : [{ type: "Application", id: "LIST" }],
    }),

    /** application notes */
    addApplicationNote: builder.mutation({
      query: (payload) => {
        const { applicationId, ...body } = payload;
        return {
          url: __generateApiUrl(`/${applicationId}/notes/add`),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
        { type: "Application", id: "LIST" },
      ],
    }),

    updateApplicationNote: builder.mutation({
      query: (payload) => {
        const { applicationId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${applicationId}/notes/edit`),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
        { type: "Application", id: "LIST" },
      ],
    }),

    /** application documents */
    addApplicationDocument: builder.mutation({
      query: (payload) => {
        const { applicationId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${applicationId}/documents/add`),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
        { type: "Application", id: "LIST" },
      ],
    }),

    updateApplicationDocument: builder.mutation({
      query: (payload) => {
        const { applicationId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${applicationId}/documents/edit`),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
        { type: "Application", id: "LIST" },
      ],
    }),

    /** application workflows */
    addApplicationWorkflow: builder.mutation({
      query: (payload) => {
        const { applicationId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${applicationId}/workflows/add`),
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", _id },
      ],
    }),

    addApplicationWorkflowStep: builder.mutation({
      query: (payload) => {
        const { documentId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${documentId}/workflows/steps/add`),
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", id: "LIST" },
        { type: "Application", _id },
        { type: "Task", id: "LIST" },
      ],
    }),

    reOrderApplicationWorkflowSteps: builder.mutation({
      query: (payload) => {
        const { documentId, ...body } = payload;

        return {
          url: __generateApiUrl(`/${documentId}/workflows/steps/re-order`),
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Application", id: "LIST" },
        { type: "Application", id: _id },
        { type: "Task", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useListApplicationQuery,
  useLazyListApplicationQuery,
  useAddApplicationMutation,
  useGetApplicationDetailQuery,
  useListClientApplicationQuery,
  useListTransactionApplicationQuery,
  useUpdateApplicationMutation,

  useAddApplicationNoteMutation,
  useUpdateApplicationNoteMutation,

  useAddApplicationDocumentMutation,
  useUpdateApplicationDocumentMutation,

  useAddApplicationWorkflowMutation,
  useAddApplicationWorkflowStepMutation,
  useReOrderApplicationWorkflowStepsMutation,
} = applicationApi;
