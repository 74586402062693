import React from "react";

import { Route, Routes } from "react-router-dom";

import PrivateRoute from "../routes/middlewares/PrivateRoute";

import DashboardScreen from "../screens/Admin/DashboardScreen";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route index element={<PrivateRoute component={DashboardScreen} />} />
    </Routes>
  );
};

export default DashboardRouter;
