import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import {
  AccountCircleOutlined,
  PersonOutlineOutlined,
  ReceiptLongOutlined,
  InfoOutlined,
  StairsOutlined,
  EventOutlined,
} from "@mui/icons-material";

import InformationTile from "../../../../components/Shared/InformationTile";
import InfoGridSkeleton from "../../../../components/Shared/Skeleton/InfoGridSkeleton";
import OpenInNewTab from "../../../../components/Shared/OpenInNewTab";
import moment from "moment";

import { useTransactionDetail } from "../TransactionDetailScreen";

const TransactionBasicDetail = () => {
  const { loadingTransaction, transactionDetail } = useTransactionDetail();

  return !loadingTransaction ? (
    <Box padding={3}>
      <Typography variant="h6"> {transactionDetail?.title} </Typography>

      <br />

      <Grid container spacing={4}>
        <Grid item md={3}>
          <InformationTile
            icon={() => <PersonOutlineOutlined color="primary" />}
            title="Client"
          >
            {transactionDetail?.client ? (
              <div className="flex items-center space-x-2">
                <OpenInNewTab
                  label={transactionDetail?.client?.fullName}
                  link={`/app/client/${transactionDetail?.client?._id}/detail`}
                />

                <Tooltip
                  title={`Responsible: ${transactionDetail?.client?.responsible?.name}`}
                  arrow
                  placement="top"
                >
                  <InfoOutlined fontSize="small" className="text-gray-500" />
                </Tooltip>
              </div>
            ) : (
              "N/A"
            )}
          </InformationTile>
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <ReceiptLongOutlined color="primary" />}
            title="Transaction Type"
            subtitle={transactionDetail?.type?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <StairsOutlined color="primary" />}
            title="Stage"
            subtitle={transactionDetail?.stage?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <AccountCircleOutlined color="primary" />}
            title="Responsible"
            subtitle={transactionDetail?.responsible?.name}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <EventOutlined color="primary" />}
            title="Created on"
            subtitle={moment(transactionDetail?.createdAt)?.format(
              "MMM DD, YYYY"
            )}
          />
        </Grid>

        <Grid item md={3}>
          <InformationTile
            icon={() => <InfoOutlined color="primary" />}
            title="Status"
          >
            <div
              className={`uppercase ${
                transactionDetail?.isActive ? "text-green-600" : "text-red-600"
              }`}
            >
              {transactionDetail?.isActive ? "Active" : "Inactive"}
            </div>
          </InformationTile>
        </Grid>
      </Grid>

      {transactionDetail?.description && (
        <>
          <br />

          <div className="text-gray-500">{transactionDetail?.description}</div>
        </>
      )}
    </Box>
  ) : (
    <InfoGridSkeleton items={5} />
  );
};

export default TransactionBasicDetail;
