import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import { removeUser, selectLoggedInUser } from "../../../rtkStore/authSlice";
import { baseApi } from "../../../rtkStore/services/baseApi";
import UserGreeting from "./UserGreeting";

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opened = Boolean(anchorEl);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loggedInUser = useSelector(selectLoggedInUser);

  // methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(removeUser());
    dispatch(baseApi.util.resetApiState());
    navigate("/");
  };

  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <UserGreeting />

      <>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={opened ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opened ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {loggedInUser?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={opened}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Link to="/app/user/me">
            <MenuItem>
              <ListItemIcon>
                <AccountCircleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              My Profile
            </MenuItem>
          </Link>

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    </Box>
  );
};

export default AccountMenu;
