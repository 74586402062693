import { baseApi } from "./baseApi";

const FEEDBACK_BASE_URL = "/feedbacks";

export const feedbackApi = baseApi.injectEndpoints({
  tagTypes: ["Feedback"],

  endpoints: (builder) => ({
    getFeedbackList: builder.query({
      query: () => `${FEEDBACK_BASE_URL}/list`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Feedback", _id })),
              { type: "Feedback", id: "LIST" },
            ]
          : [{ type: "Feedback", id: "LIST" }],
    }),

    getFeedbackDetail: builder.query({
      query: (id) => `${FEEDBACK_BASE_URL}/${id}/detail`,
      providesTags: (result, error, id) => [
        { type: "Feedback", id },
        { type: "Feedback", id: "LIST" },
      ],
    }),

    createFeedback: builder.mutation({
      query: (payload) => ({
        url: `${FEEDBACK_BASE_URL}/add`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Feedback", id: _id },
        { type: "Feedback", id: "LIST" },
      ],
    }),

    updateFeedback: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${FEEDBACK_BASE_URL}/${_id}/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Feedback", _id },
        { type: "Feedback", id: "LIST" },
      ],
    }),

    // feedback responses
    addFeedbackResponse: builder.mutation({
      query: (payload) => {
        const { feedbackId, ...body } = payload;

        return {
          url: `${FEEDBACK_BASE_URL}/${feedbackId}/responses/add`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Feedback", id: _id },
        { type: "Feedback", id: "LIST" },
      ],
    }),

    updateFeedbackResponse: builder.mutation({
      query: (payload) => {
        const { feedbackId, ...body } = payload;

        return {
          url: `${FEEDBACK_BASE_URL}/${feedbackId}/responses/edit`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Feedback", id: _id },
        { type: "Feedback", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetFeedbackDetailQuery,
  useGetFeedbackListQuery,
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,

  useAddFeedbackResponseMutation,
  useUpdateFeedbackResponseMutation,
} = feedbackApi;
