import { useSearchParams } from "react-router-dom";

export const useGetURLParams = () => {
  // hooks
  const [searchParams] = useSearchParams();

  const params = {};
  for (const entry of searchParams.entries()) {
    params[entry[0]] = entry[1];
  }
  return params;

}; 