import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";

const INITIAL_STATE = { name: "", address: "", isActive: true };

const AddCampusForm = ({
  campusDetail,
  isBusy = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  // react form hook
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
      ...campusDetail,
    },
  });

  React.useEffect(() => {
    if (!campusDetail) return;

    reset({ ...campusDetail });

    return () => {
      reset({ ...INITIAL_STATE });
      clearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campusDetail, reset]);

  return (
    <>
      <Box padding={3} className="expanded-form">
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Campus name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Name"
                  size="small"
                  type="text"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="address"
              control={control}
              rules={{ required: "Campus Address is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Address"
                  size="small"
                  type="text"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="isActive"
              control={control}
              render={({ field, value }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      name="isActive"
                      color="primary"
                      checked={field.value}
                      value={value}
                    />
                  }
                  label="Is Active?"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        paddingX={3}
        paddingY={2}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#fafafa",
        }}
      >
        <Button disabled={isBusy} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          disabled={isBusy}
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {campusDetail ? "Update" : "Add"}
        </Button>
      </Box>
    </>
  );
};

export default AddCampusForm;
