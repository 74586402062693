import React from "react";

const PriorityBadge = ({ level }) => {
  const __getPriorityIndicator = () => {
    switch (true) {
      case level <= 3:
        return "border-red-200 text-red-500 bg-red-50";

      case level > 3 && level <= 7:
        return "border-yellow-200 text-yellow-500 bg-yellow-50";

      case level > 7 && level <= 10:
        return "border-green-200 text-green-500 bg-green-50";

      default:
        return "border-blue-200 text-blue-500 bg-blue-50";
    }
  };

  return (
    <div
      className={`flex flex-shrink-0 space-x-2 rounded border px-2 py-1 font-medium ${__getPriorityIndicator()}`}
    >
      <span className="text-xs">Priority Level</span>{" "}
      <span className="text-xs">{level}</span>
    </div>
  );
};

export default PriorityBadge;
