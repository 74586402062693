import { baseApi } from "./baseApi";

const USER_BASE_URL = "/users";

export const userApi = baseApi.injectEndpoints({
  tagTypes: ["Admin"],

  endpoints: (builder) => ({
    // Legacy *************************
    getAdminList: builder.query({
      query: () => `${USER_BASE_URL}/app-list`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ _id }) => ({ type: "Admin", _id })),
            { type: "Admin", id: "LIST" },
          ]
          : [{ type: "Admin", id: "LIST" }],
    }),

    getAdminDetail: builder.query({
      query: (id) => `${USER_BASE_URL}/${id}`,
      providesTags: (result, error, id) => [
        { type: "Admin", id },
        { type: "Admin", id: "LIST" },
      ],
    }),

    createAdmin: builder.mutation({
      query: (payload) => ({
        url: `${USER_BASE_URL}/app-register`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, { _id }) => [
        { type: "Admin", id: "LIST" },
      ],
    }),

    updateAdmin: builder.mutation({
      query: (payload) => {
        const { _id, ...body } = payload;

        return {
          url: `${USER_BASE_URL}/app-edit/${_id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "Admin", _id },
        { type: "Admin", id: "LIST" },
      ],
    }),
    // ******************************

    listUser: builder.query({
      query: () => `${USER_BASE_URL}/list`,
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Users` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
          [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'LIST' }` is invalidated
          [{ type: "User", id: "LIST" }],
    }),
    listAssignmentUsers: builder.query({
      query: (params) => {
        return {
          url: `${USER_BASE_URL}/assignment-users`,
          params,
        };
      },
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Users` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
          [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'LIST' }` is invalidated
          [{ type: "User", id: "LIST" }],
    }),

    registerUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/register`,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created user could show up in any lists.
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    addUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/add`,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created user could show up in any lists.
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    detailUser: builder.query({
      query: (id) => `${USER_BASE_URL}/detail/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),

    profileUser: builder.query({
      query: (id) => `${USER_BASE_URL}/profile/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),

    //post request but used as query as needed
    detailUserProfile: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/profile/${id}`,
          method: "POST",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editUserProfile: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/change-profile/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editProfilePassword: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/change-profile-password/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    editDetailPassword: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/change-detail-password/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    deactivateUser: builder.mutation({
      query(id) {
        return {
          url: `${USER_BASE_URL}/${id}/inactive`,
          method: "PUT",
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    activateUser: builder.mutation({
      query(id) {
        return {
          url: `${USER_BASE_URL}/${id}/active`,
          method: "PUT",
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    addEntityToUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/add-entity/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    changeSelectedEntity: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/change-entity/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    changeEntityDefaultRole: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/change-role/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for Entity-Level Admins
    addEntityUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/add/entity`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserByEntity: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/add-entity/${id}/entity`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listEntityUser: builder.query({
      query: (status) => `${USER_BASE_URL}/list/entity?status=${status}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : [{ type: "User", id: "LIST" }],
    }),

    editEntityUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/edit/${id}/entity`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for Account-Level Admins
    addAccountUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/add/account`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserByAccount: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/add-entity/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listAccountUser: builder.query({
      query: () => `${USER_BASE_URL}/list/account`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : [{ type: "User", id: "LIST" }],
    }),

    listAccountEntityUser: builder.query({
      query: (queryParams) => {
        return {
          url: `${USER_BASE_URL}/list/account/entity`,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : [{ type: "User", id: "LIST" }],
    }),

    editAccountUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/edit/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editAccountUserRole: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/edit-role/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for System-Level Admins
    addSystemUser: builder.mutation({
      query(body) {
        return {
          url: `${USER_BASE_URL}/add/system`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserBySystem: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/add-entity/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listSystemUser: builder.query({
      query: () => `${USER_BASE_URL}/list/system`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "User", id })),
            { type: "User", id: "LIST" },
          ]
          : [{ type: "User", id: "LIST" }],
    }),

    editSystemUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/edit/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editSystemUserRole: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `${USER_BASE_URL}/edit-role/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAdminDetailQuery,
  useGetAdminListQuery,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  // ******************************
  useListUserQuery,
  useListAssignmentUsersQuery,
  useRegisterUserMutation,
  useAddUserMutation,
  useDetailUserQuery,
  useProfileUserQuery,
  useLazyProfileUserQuery,
  useDetailUserProfileMutation, //used in setCredentials
  useEditUserProfileMutation,
  useEditProfilePasswordMutation,
  useEditDetailPasswordMutation,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useAddEntityToUserMutation,
  useChangeSelectedEntityMutation,
  useChangeEntityDefaultRoleMutation,
  useAddEntityUserMutation,
  useAddEntityToUserByEntityMutation,
  useListEntityUserQuery,
  useEditEntityUserMutation,
  useAddAccountUserMutation,
  useAddEntityToUserByAccountMutation,
  useListAccountUserQuery,
  useListAccountEntityUserQuery,
  useLazyListAccountEntityUserQuery,
  useEditAccountUserMutation,
  useEditAccountUserRoleMutation,
  useAddSystemUserMutation,
  useAddEntityToUserBySystemMutation,
  useListSystemUserQuery,
  useEditSystemUserMutation,
  useEditSystemUserRoleMutation,
} = userApi;
