import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import { formatRelativeTime } from "../../../../utils/handleFormatDate";
import { LoadingButton } from "./LoadingButton";

export const KnowledgebaseComment = ({
  comment,
  isAuthor = false,
  onCancelReply,
  onClickReply,
  isCommenting,
  onSubmitComment,
  isReplyTextActive,
  replyComment,
  onChangeReplyComment,
  isReply = false,
  hasReply = false,
  isUpdateCommenTextActive,
  onClickEditComment,
  onCancelUpdateComment,
  onUpdateComment,
  isUpdatingComment,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickMenu = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      key={`comment-${comment._id}`}
      marginTop={isReply ? 0 : 3}
      marginBottom={hasReply ? 0 : 3}
      borderRadius="5px"
      sx={{
        backgroundColor: "#fff",
        cursor: "pointer",
        padding: "1rem",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
        borderLeft: comment?.isForArticleFlagForReview
          ? "4px solid #e33333"
          : "",
        // border: "1px solid #f1f1f1",
        // "&:hover": {
        //   boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
        // },
      }}
    >
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" sx={{ gap: "1rem" }}>
            <Avatar
              style={{ width: 36, height: 36 }}
              src={`https://ui-avatars.com/api/?rounded=true&name=${comment?.author?.name}&background=3F51B5&color=fff`}
              alt={comment?.author?.name}
            />

            <Box>
              <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
                <Typography variant="subtitle1">
                  {comment?.author?.name}
                </Typography>

                {
                  //   articleState?.detailedArticleInformation?.author?._id ===
                  //     comment?.author?._id
                  isAuthor && (
                    <Box
                      paddingX={1}
                      paddingY={0.2}
                      borderRadius="5px"
                      marginLeft={1}
                      sx={{
                        color: "#0a58ca",
                        backgroundColor: "rgba(6,100,239,0.2)",
                        fontSize: 12,
                      }}
                    >
                      Author
                    </Box>
                  )
                }
                {comment?.isUpdated && (
                  <Typography
                    style={{
                      fontStyle: "italic",
                      fontSize: 13,
                      marginLeft: "8px",
                    }}
                    className="label-color"
                  >
                    Edited
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: 14 }}
              >
                {formatRelativeTime(comment?.createdAt)}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
            {comment?.replies?.length === 0 && !isReplyTextActive && (
              <Button
                size="small"
                variant="text"
                className="label-color"
                style={{ textTransform: "none" }}
                onClick={() => onClickReply(comment._id)}
              >
                Reply
              </Button>
            )}

            <Box>
              {isAuthor && (
                <ClickAwayListener
                  onClickAway={(e) => {
                    try {
                      if (
                        Array.from(e?.target?.classList)?.includes(
                          "menu-button"
                        )
                      )
                        return;
                    } catch (error) {
                      return;
                    }
                    setAnchorEl(null);
                  }}
                >
                  <IconButton className="menu-button" onClick={onClickMenu}>
                    <i
                      className="fa-solid fa-ellipsis-vertical label-color menu-button"
                      style={{ fontSize: 13 }}
                    ></i>
                  </IconButton>
                </ClickAwayListener>
              )}
            </Box>

            {isAuthor && (
              <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                style={{
                  padding: "4px",
                  border: "1px solid #dedede80",
                  borderRadius: "5px",
                  zIndex: "10000 !important",
                }}
              >
                <Box>
                  <Button
                    size="small"
                    variant="text"
                    className="label-color"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      onClickEditComment(comment);
                      setAnchorEl(null);
                    }}
                  >
                    Edit {isReply ? "Reply" : "Comment"}
                  </Button>
                </Box>
              </Popper>
            )}
          </Box>
        </Box>
        <Box marginTop={2}>
          {/* UI to edit comment */}
          {isUpdateCommenTextActive ? (
            <Box>
              <textarea
                rows={4}
                placeholder="Enter comment"
                className="form-control form-font"
                value={replyComment}
                onChange={(e) => onChangeReplyComment(e.target.value)}
              />

              <Box
                marginTop={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ gap: "1rem" }}
              >
                <Button
                  size="small"
                  className="label-color"
                  onClick={() => {
                    onCancelUpdateComment();
                    setAnchorEl(null);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loadingText="Updating..."
                  variant="outlined"
                  color="primary"
                  size="small"
                  loading={isUpdatingComment}
                  onClick={() => onUpdateComment()}
                >
                  Update
                </LoadingButton>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="subtitle1"
              className="label-color"
              style={{ fontSize: 15 }}
            >
              {comment?.comment}
            </Typography>
          )}
        </Box>
      </Box>

      {isReplyTextActive && (
        <Box marginTop={3}>
          <textarea
            rows="4"
            name={`comment`}
            placeholder="Enter your reply"
            className="form-control form-font"
            value={replyComment}
            onChange={(e) => onChangeReplyComment(e.target.value)}
          />

          <Box
            marginTop={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ gap: "1rem" }}
          >
            <Button
              size="small"
              className="label-color"
              onClick={onCancelReply}
            >
              Cancel
            </Button>
            <LoadingButton
              loadingText="Replying..."
              variant="outlined"
              color="primary"
              size="small"
              loading={isCommenting}
              onClick={() => onSubmitComment()}
            >
              Reply
            </LoadingButton>

            {comment?.isForArticleFlagForReview && (
              <LoadingButton
                loadingText="Replying..."
                variant="contained"
                color="primary"
                size="small"
                loading={isCommenting}
                onClick={() => onSubmitComment({ unflagArticle: true })}
              >
                Reply and Unflag Article
              </LoadingButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
