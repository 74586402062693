import React from "react";
import { Link } from "react-router-dom";

const CatalogueCourseCard = ({ course }) => {
  const universityHasLogo = course?.university?.logo?.length;

  if (!course) return null;

  return (
    <Link to={`/v2/courses-catalogue/${course?._id}`}>
      <div className="relative flex w-full cursor-pointer items-center space-x-6 rounded border bg-white p-4 hover:bg-blue-50">
        <img
          src={universityHasLogo ? course?.university?.logo : ""}
          alt={course?.university?.title ?? "Logo"}
          className={
            "flex aspect-square h-full w-28 flex-shrink-0 items-center rounded bg-gray-100 object-contain text-center text-sm transition duration-100 ease-in-out group-hover:-rotate-2"
          }
        />

        <div className="flex flex-col space-y-2">
          <div className="font-medium line-clamp-2 group-hover:text-blue-600">
            {course?.title}
          </div>

          <div className="inline-flex flex-wrap items-center space-x-2">
            <div className="text-sm text-blue-600">
              {course?.university?.title}
            </div>

            {/* {course?.university?.assessmentLevel && (
              <div className="text-xs text-gray-500">
                ({course?.university?.assessmentLevel?.name})
              </div>
            )} */}
          </div>

          {!!course?.location && (
            <div
              className="inline-flex flex-wrap items-center space-x-2"
              title="Locations"
            >
              {!!course?.location && (
                <div className="text-sm">{course?.location?.name}</div>
              )}

              {!!course?.subLocations?.length &&
                course?.subLocations?.map((el) => (
                  <React.Fragment key={el?._id}>
                    <span className="text-sm">•</span>
                    <div className="text-sm ">{el?.name}</div>
                  </React.Fragment>
                ))}
            </div>
          )}

          <div className="flex space-x-2">
            {course?.duration && (
              <div className="text-sm">{course?.duration?.name}</div>
            )}

            {course?.level && (
              <div className="text-sm">{course?.level?.name} Level </div>
            )}
          </div>

          <div className="flex items-center space-x-2">
            <div className="text-sm text-green-600">
              <span> {course?.currency?.symbol ?? ""}</span>{" "}
              <span className="text-sm">{course?.tuitionFee}</span>
            </div>

            <div className="text-sm">
              {course?.currency ? `(${course?.currency?.code})` : ""}
            </div>

            <div className="text-sm">/ {course?.feeType}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CatalogueCourseCard;
