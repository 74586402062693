import React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import GeneratedStudyOptions from "../../../components/Shared/GeneratedStudyOptions";
import PageLayout from "../../../layouts/PageLayout";

import { useGenerateAssigneeStudyOptionsMutation } from "../../../rtkStore/services/studyOptionApi";

const ClientStudyOptionsScreen = () => {
  const { id, clientId } = useParams();

  const [generateStudyOptions, { data: clientDetail, isLoading, error }] =
    useGenerateAssigneeStudyOptionsMutation();

  React.useEffect(() => {
    generateStudyOptions({
      type: "Client",
      assignee: clientId,
      studyOption: id,
    });
  }, [generateStudyOptions, clientId, id]);

  return (
    <PageLayout>
      {error && (
        <Alert variant="outlined" severity="error">
          {error?.data?.message ?? "Something went wrong!"}
        </Alert>
      )}

      {!isLoading ? (
        <>
          <GeneratedStudyOptions assignee={clientDetail} />
        </>
      ) : (
        <div className="grid h-screen place-content-center">
          Please wait till the data loads...
        </div>
      )}
    </PageLayout>
  );
};

export default ClientStudyOptionsScreen;
