import React from "react";
import { Avatar, Box, Tooltip, Typography, Modal, Chip } from "@mui/material";
import { KBAttachentModalBlog } from "./KBComponents/KBAttachentModalBlog";
import { useKnowledgebaseContext } from "../../../context/KBContext";
import { formatDate } from "../../../utils/handleFormatDate";
import { CustomModal, ModalBody, ModalHeader } from "../../../components/Shared/CustomModal";

import AttachFileIcon from '@mui/icons-material/AttachFile';

export const KBAttachmentVersionScreen = () => {
  // states
  const [isAttachmentModalActive, setIsAttachmentModalActive] =
    React.useState(false);
  const [selectedAttachmentVersion, setSelectedAttachmentVersion] =
    React.useState(null);

  // hooks
  const { state: articleState } = useKnowledgebaseContext();

  const { detailedArticleInformation } = articleState;

  if (!detailedArticleInformation) return <></>;

  return (
    <div>
      {detailedArticleInformation?.attachments?.length === 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            color: "#435883",
            maxWidth: "100%",
            minHeight: "200px",
            backgroundColor: "white",
            borderRadius: "5px",
            marginBottom: "12px",
            cursor: "pointer",
            padding: "1rem",
            border: "1px solid #f1f1f1",
            "&:hover": {
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
            },
          }}
        >
          <Box textAlign="center">
            <i
              className="fa-solid fa-clock-rotate-left"
              style={{ fontSize: 20 }}
            ></i>
            <Typography variant="subtitle1" style={{ fontSize: 18 }}>
              No attachment versions available
            </Typography>
          </Box>
        </Box>
      )}

      {detailedArticleInformation?.attachments &&
        [...detailedArticleInformation?.attachments.map((x) => ({ ...x }))]
          ?.sort((a, b) => b.version - a.version)
          ?.map((attachment) => (
            <Box
              key={`article-${attachment._id}`}
              sx={{
                maxWidth: "100%",
                backgroundColor:
                  attachment?.status === "Inactive" ? "#f1f1f1" : "white",
                borderRadius: "5px",
                marginBottom: "12px",
                cursor: "pointer",
                padding: "1rem",
                border:
                  attachment?.status === "Inactive"
                    ? "1px solid #dedede"
                    : "1px solid #f1f1f1",
                "&:hover": {
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 4px",
                },
              }}
              onClick={() => {
                setSelectedAttachmentVersion(attachment);
                setIsAttachmentModalActive(true);
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  style={{ width: 36, height: 36, marginRight: "1rem" }}
                  // src="https://images6.alphacoders.com/771/thumb-1920-771840.jpg"
                  src={`https://ui-avatars.com/api/?rounded=true&name=${attachment?.owner?.name}&background=3F51B5&color=fff`}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    marginRight: "6px",
                  }}
                >
                  {attachment?.updatedBy?.name || attachment?.owner?.name}
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "#435883",
                  }}
                >
                  created the attachment on &nbsp;
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    color: "#435883",
                  }}
                >
                  {formatDate(attachment?.createdAt)}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                marginTop={1}
                marginBottom={1}
                sx={{ gap: "8px" }}
              >
                <Tooltip title={`${attachment?.files?.length || 0} files`}>
                  <Box display="flex" alignItems="center" sx={{ gap: "4px" }}>
                    <AttachFileIcon className="fs-14" />
                    <Typography
                      variant="subtitle1"
                      className="label-color"
                      style={{ fontSize: 16 }}
                    >
                      {attachment?.files?.length}
                    </Typography>
                  </Box>
                </Tooltip>

                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "gray",
                  }}
                >
                  &bull;
                </Typography>

                {/* <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: 14,
                    color:
                      attachment?.status === "Active"
                        ? "#435883"
                        : attachment?.status === "Inactive"
                          ? "red"
                          : "#E0E0E0",
                    fontWeight: 400,
                  }}
                >
                  {attachment?.status}
                </Typography> */}

                <Chip size="small" label={attachment?.status} color={attachment?.status === 'Active' ? 'primary' : "error"} />


                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "gray",
                  }}
                >
                  &bull;
                </Typography>

                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                    color: "#3F51B5",
                  }}
                >
                  V{attachment.version}
                </Typography>
              </Box>

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 16,
                  color: "#435883",
                }}
              >
                {attachment?.title}
              </Typography>
            </Box>
          ))}

      <CustomModal
        open={isAttachmentModalActive}
        onClose={() => {
          setIsAttachmentModalActive(false);
        }}
        className="article-version-modal auto-height"
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
            <Avatar
              style={{ width: 36, height: 36 }}
              src={`https://ui-avatars.com/api/?rounded=true&name=${detailedArticleInformation?.author?.name}&background=3F51B5&color=fff`}
            />

            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 16,
                  marginRight: "6px",
                }}
              >
                {detailedArticleInformation?.author?.name}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  fontSize: 13,
                  color: "#435883",
                }}
              >
                {formatDate(
                  detailedArticleInformation?.versions[0]?.createdAt
                )}
              </Typography>
            </Box>
          </Box>
        </ModalHeader>

        <ModalBody>
          <KBAttachentModalBlog
            article={selectedAttachmentVersion}
            showAuthorInfo={false}

          />
        </ModalBody>
      </CustomModal>
    </div>
  );
};
