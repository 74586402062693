import React from "react";

import { useAuth } from "../../../hooks/useAuth";

const AppLogo = () => {
  const { viewEntity } = useAuth();

  return (
    <div className="relative flex-grow py-2">
      <div className="text-center text-lg font-medium text-blue-900 line-clamp-1">
        {viewEntity?.entity?.account?.displayName ?? "EduXGateway"}
      </div>
    </div>
  );
};

export default AppLogo;
