import React from "react";

import { Box, Grid, Skeleton } from "@mui/material";

const UniversityDetailSkeleton = () => {
  return (
    <Box padding={3}>
      <Grid container spacing={6}>
        <Grid item md={2}>
          <Skeleton variant="rect" height={150} width={150} />
        </Grid>

        <Grid item md={10}>
          <Grid container spacing={6}>
            {new Array(6).fill(null).map((_, index) => (
              <Grid key={index} item md={4}>
                <Skeleton variant="rect" height={60} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {new Array(2).fill(null).map((_, index) => (
          <Grid key={index} item md={12}>
            <Skeleton variant="rect" height={100} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UniversityDetailSkeleton;
