import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import WithHeader from "../../../../layouts/WithHeader";
import UserEditForm from "../userComponents/UserEditForm";

import {
  useDetailUserQuery,
  useEditAccountUserMutation,
} from "../../../../rtkStore/services/userApi";

const AccountUserEditScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  // RTK Query
  const {
    data: userDetail,
    isLoading: loadingUserDetail,
    error,
  } = useDetailUserQuery(id);
  const [updateAccountUser, { isLoading: updatingUser }] =
    useEditAccountUserMutation();

  const breadcrumbs = [
    { title: "Users", path: "/app/user/account/list" },
    {
      title: userDetail ? userDetail?.name : "User Detail",
      path: `/app/user/account/${id}/detail`,
    },
    { title: "Edit User" },
  ];

  // methods
  const handleUserUpdate = async (data) => {
    try {
      await updateAccountUser({
        id: userDetail?._id,
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        designation: data?.designation,
      }).unwrap();
      toast.success("User successfully updated!");
      navigate(breadcrumbs[1]?.path);
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't update user!");
    }
  };

  return (
    <WithHeader breadcrumbs={breadcrumbs} error={error}>
      <UserEditForm
        userDetail={userDetail}
        onSubmit={handleUserUpdate}
        onCancel={() => navigate(breadcrumbs[1]?.path)}
        isBusy={loadingUserDetail || updatingUser}
      />
    </WithHeader>
  );
};

export default AccountUserEditScreen;
