import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Box, Button, Divider, Typography } from "@mui/material";
import { Add, PlaylistAddCheckOutlined } from "@mui/icons-material";

import RowsSkeleton from "../../../../components/Shared/Skeleton/RowsSkeleton";
import TaskList from "../../../../components/Task/TaskList";

import { useGetTaskByAssigneeQuery } from "../../../../rtkStore/services/taskApi";

const TransactionTasksTab = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  // RTK Query
  const { data: taskList = [], isLoading: loadingTasks } =
    useGetTaskByAssigneeQuery(id);

  return (
    <>
      <Box padding={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="inline-flex" alignItems="center" gap={1.5}>
            <Badge badgeContent={taskList?.length} color="primary" showZero>
              <PlaylistAddCheckOutlined style={{ color: "gray" }} />
            </Badge>

            <Typography style={{ fontWeight: 500 }}>Tasks</Typography>
          </Box>

          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => navigate(`/app/task/create?selectedClient=${id}`)}
          >
            Task
          </Button>
        </Box>
      </Box>

      <Divider />

      {!loadingTasks ? <TaskList tasks={taskList} /> : <RowsSkeleton />}
    </>
  );
};

export default TransactionTasksTab;
